export const DispositionConstants = {
  FOLLOW_UP: 'FLUP',
  SOLD: 'SOLD',
  INTERESTED: 'INTD',
  // DATETIME: 'datetime',
  DATETIME: 'dateTime',
  // TEXTAREA: 'textarea',
  TEXTAREA: 'textArea',
  // STRING: 'string',
  STRING: 'text',
  DROPDOWN: 'dropdown',
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
  NUMBER: 'number',
  BUYER: 'CC_BUYER',
  SELLER: 'CC_SELLER',
  CC_INBOUND_BUYER: 'CC_IB_BUYER',
  CC_OUTBOUND_BUYER: 'CC_OB_BUYER',
  INELIGIBLE: 'INELIGIBLE',
  CCOB_ASSISTED_BOOKING: 'BOOKING_ASSIST_BUYER', 
  PRICE: 'price',
  SELLER_LIST_PRICE_CC_DISPOSITION_ID: 19, // This will remain same across environments and needed to check listing price <= Max                                             Listing Price for seller.
  BUYER_OFFER_PRICE_CC_DISPOSITION_ID: 15, // This will remain same across environments and needed to check offer price >=                                                   c24quote for buyer.
  INBOUND_BUYER_SHORT_CALL_ID: 250,
  CC_MISSED_CALL: 'CC_MISSED_CALL',
  CAR_AVAILABILITY_STATUS: 'Active',
  CC_OB_INDEPENDENT: 'CC_OB_INDEPENDENT',
};

export const questionConstants = {
  SOURCE_OF_INCOME: 'sourceOfIncome',
  OCCUPATION: 'occupation',
  CO_APPLICANT: 'coApplicant'
};

export const DELIVERY_MODES = {
  PICK_UP_FREE : 'PICK_UP_FREE',
  PICK_UP : 'PICK_UP',
  HOME_DELIVERY: 'HOME_DELIVERY'
};

export const ccDeliveryModes  = {
  PICK_UP_FREE : "Hub Pickup - Free",
  PICK_UP_PAID : "Hub Pickup - Paid",
  HOME_DELIVERY: "Home Delivery"
};

export const UNAUTHORISED = 401;
export const FORBIDDEN = 403;
export const SIMPLER_SOURCE = {
  LMS: "LMS",
  WEBAPP: "WebApp"
};

export const VALID_NAME_REGEX = "^[a-zA-Z.' ]*[a-zA-Z.']+[a-zA-Z.' ]*$";

export const STRIP_COMMA_FROM_NUMBER = /,/g;

export const FILE_UPLOAD = {
  CSV: 'csv',
  XLSX: 'xlsx',
  MAX_SIZE: '5242880'
};

export const ALLOCATIONS_LIST = [
  { value: 'FRSH', label: 'Fresh Allocations' },
  { value: 'REAL', label: 'Re-Allocations' },
  { value: 'SELLER_REALLOCATION', label: 'Seller Re-allocations' }
];
export const PUBLISH_LIST = [
  { value: 'PUBLISH', label: 'Publish' },
  { value: 'PUBLISH_DEALER', label: 'Publish-Dealer' },
  { value: 'WAIVER', label: 'Waiver' },
  { value: 'HOLDBACK_RELEASE', label: 'Holdback Release' },
  { value: 'BUDDY_RECOVERY_RECREDIT', label: 'Buddy Recovery / Recredit' }
];

export const getUploadAllocationEndpoint = (allocationType) => {
  switch (allocationType) {
    case 'FRSH':
      return { path: 'secure/fresh-allocations', method: 'post' };
    case 'REAL':
      return { path: 'secure/buddy-reallocations', method: 'post' };
    case 'PUBLISH':
      return { path: 'secure/publish-cars', method: 'post' };
    case 'PUBLISH_DEALER':
      return { path: 'car/publish-cars/b2b', method: 'post' };
    case 'WAIVER':
      return { path: 'rc/uploader/waiver', method: 'post' };
    case 'HOLDBACK_RELEASE':
      return { path: 'rc/uploader/holdback-release', method: 'post' };
    case 'BUDDY_RECOVERY_RECREDIT':
      return { path: 'rc/uploader/buddy-recovery', method: 'post' };
    case 'SELLER_REALLOCATION':
      return { path: 'secure/allocate-seller-leads', method: 'put' };
    default:
      return { path: 'secure/publish-cars', method: 'post' };
  }
};

export const CAMPAIGN_TYPE = {
  PostBooking: 'post_booking',
  BookingAssist: 'booking_assist'
};

export const UPLOAD_PATH = {
  ALLOCATIONS: 'allocations',
  PUBLISH: 'publish'
};

export const ALLOCATIONS_ACCESS_ROLES = [
  'C2C_LMS_TM',
  'C2C_LMS_RM',
  'C2C_LMS_GM',
  'C2C_LMS_ADMIN'
];

export const PUBLISH_CARS_ROLES = ['C2C_LMS_GM', 'C2C_LMS_ADMIN'];

export const ROLE = 'role';
export const USER_ID = 'userId';
export const EXCLUDE_USER_STATUS = 'RESIGNED';
export const ROLE_TYPE = 'APP';
export const PUBLISH_NEW_TYPE = 'PUBLISH_DEALER';
export const ADMIN_UPLOADS = [
  'WAIVER',
  'HOLDBACK_RELEASE',
  'BUDDY_RECOVERY_RECREDIT',
];

export const FORGET_PASSWORD_LINK = 'https://portal.24c.in/reset-password.php';

export const RESTRICTED_PHONE = [
  '1244302560',
  '2050561000',
  '8047112413',
  '2250561000'
];
export const PINCODE_LENGTH = 6;
export const PANCARD_LENGTH = 10;
export const CF_SUCCESS_MESSAGE = "Your request is processed successfully.";
export const PROCESSING_FEE = "Processing Fee";
export const SHIPPING_CHARGE_KEY = "shipping_charge";
export const DEFAULT_PROCESSING_FEE = 0.0236;
export const NINETEEN_SEVENTY=1970;
export const EIGHTEEN=18;
export const LOAN_INSURANCE="Loan Insurance";
export const EMPLOYMENT_TYPE = {
  SALARIED: "SALARIED",
  SELF_EMPLOYED: "SELF_EMPLOYED"
};
export const INCOMEPROOF_TYPE = {
  YES: "Yes",
  NO: "No"
};
export const HOUSE_TYPE = {
  OWNED: "OWNED",
  RENTED: "RENTED"
};
export const CANCEL_ACCESS_USER_ROLE = [
  "C2C_ADMIN", 
  "C2C_MIS",
  "C2C_FINANCE"
];
export const VALIDATION_REQUIRED_SUBDISPOSITIONS = [
  "Bank Statement & Order Link Shared",
  "Order Link Shared"
];

export const CAMPAIGN_TABS = {
  CUSTOMER_DETAILS: 'Customer Details',
  DELIVERY_DETAILS: 'Delivery Details',
  BOOKING_DETAILS: 'Booking Details',
  RETURN: 'return',
  SERVICE: 'service',
  OTHER: 'other',
  CF_DETAILS: 'CF_DETAILS',
  SALES_PANEL: 'SALES_PANEL',
};

export const POST_DELIVERY_DOC_TYPES = {
  INVOICE: "INVOICE",
  INSURANCE: "INSURANCE",
  WARRANTY: "WARRANTY",
  E_RECEIPT: "E_RECEIPT",
  BUYER_INSURANCE: "BUYER_INSURANCE",
  FORM_29: "FORM_29",
  FORM_30: "FORM_30",
  SELLER_RC: "SELLER_RC"
};

export const DELIVERY_BOOKING_DETAILS_COLUMN_MAPPING = {
  [DispositionConstants.CC_MISSED_CALL] : {
    uid: {
      label: "UID"
    },
    bookingId: {
      label: "Booking Id"
    },
    registrationNo: {
      label: "Registration No."
    },
    makeAndModel: {
      label: "Make & Model"
    },
    deliveryDate: {
      type: "date",
      label: "Delivery Date"
    },
    orderStatus: {
      label: "Order Status"
    }
  },
  [DispositionConstants.CC_OUTBOUND_BUYER] : {
    bookingId: {
      label: "Booking Id"
    },
    bookingStatus: {
      label: "Booking Status"
    },
    visitStatus: {
      label: "Visit Status"
    },
    initiationDate: {
      type: "date",
      label: "Initiation Date"
    },
    updatedAt:{
      type: "date",
      label: "Last Updated At"
    },
    bookingDate: {
      type: "date",
      label: "Booking Date"
    },
    expiryDate: {
      type: "date",
      label: "Booking Expiry Date"
    },
    makeAndModel: {
      label: "Make & Model"
    },
    carAvailability: {
      label: "Car Availability"
    },
    listingPrice: {
      label: "Listing Price"
    }, 
    source: {
      label: "Source"
    }
  },
  [DispositionConstants.CCOB_ASSISTED_BOOKING] : {
    bookingId: {
      label: "Booking Id"
    },
    bookingStatus: {
      label: "Booking Status"
    },
    bookingDate: {
      type: "date",
      label: "Booking Date"
    },
    initiationDate: {
      type: "date",
      label: "Initiation Date"
    },
    updatedAt:{
      type: "date",
      label: "Last Updated At"
    },
    makeAndModel: {
      label: "Make & Model"
    },
    carAvailability: {
      label: "Car Availability"
    },
    listingPrice: {
      label: "Listing Price"
    },
    source: {
      label: "Source"
    }
  }
};

export const ZENDESKS_RETURN_COLUMN_MAPPING = {
  id: {
    type: "link",
    label: "Id",
    linkField: "url"
  },
  registrationNumber: {
    label: "Registration Number"
  },
  makeAndModel: {
    label: "Make & Model"
  },
  createdDate: {
    type: "date",
    label: "Created Date"
  },
  updatedDate: {
    type: "date",
    label: "Updated Date"
  },
  status: {
    label: "Status"
  },
  returnStage: {
    label: "Stage"
  }
};

export const ZENDESKS_SERVICE_COLUMN_MAPPING = {
  id: {
    type: "link",
    label: "Id",
    linkField: "url"
  },
  registrationNumber: {
    label: "Registration Number"
  },
  makeAndModel: {
    label: "Make & Model"
  },
  createdDate: {
    type: "date",
    label: "Created Date"
  },
  updatedDate: {
    type: "date",
    label: "Updated Date"
  },
  status: {
    label: "Status"
  },
  repairStage: {
    label: "Stage"
  }
};

export const ZENDESKS_OTHER_COLUMN_MAPPING = {
  id: {
    type: "link",
    label: "Id",
    linkField: "url"
  },
  registrationNumber: {
    label: "Registration Number"
  },
  makeAndModel: {
    label: "Make & Model"
  },
  createdDate: {
    type: "date",
    label: "Created Date"
  },
  updatedDate: {
    type: "date",
    label: "Updated Date"
  },
  status: {
    label: "Status"
  }
};

export const PRE_BOOKING_FORM_VALIDATION_RULES = {
  'Interested in Booking': {
    name: {
      required: true,
      editable: true,
      tabName: 'Customer Details'
    },
    phone: {
      required: true,
      editable: false
    },
    alternatePhone: {
      required: false,
      editable: true
    },
    email: {
      required: true,
      editable: true,
      tabName: 'Customer Details'
    },
    deliveryMode: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    deliveryPlace: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    deliveryTime: {
      required: true,
      editable: true
    },
    paymentMode: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    deliveryDate: {
      required: true,
      editable: true
    },
    deliveryState: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    deliveryCity: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    deliveryPincode: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    deliveryAddress1: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    deliveryAddress2: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    city: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    state: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    pincode: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    address1: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    address2: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    addressProof: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    nameOnRc: {
      required: true,
      editable: true
    },
    visitTime: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    visitDate: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    buyerPincode: {
      required: true,
      editable: true,
      tabName: 'Customer Details'
    }
  }
};

export const POST_BOOKING_FORM_VALIDATION_RULES = {
  'Booking Modification': {
    name: {
      required: true,
      editable: true,
      tabName: 'Customer Details'
    },
    phone: {
      required: true,
      editable: false
    },
    alternatePhone: {
      required: false,
      editable: true,
      tabName: 'Customer Details'
    },
    email: {
      required: true,
      editable: true,
      tabName: 'Customer Details'
    },
    deliveryMode: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    deliveryPlace: {
      required: false,
      editable: true,
      tabName: 'Booking Details'
    },
    deliveryTime: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    visitTime: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    visitDate: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    paymentMode: {
      required: false,
      editable: true,
      tabName: 'Booking Details'
    },
    deliveryDate: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    deliveryState: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    deliveryCity: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    deliveryPincode: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    deliveryAddress1: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    deliveryAddress2: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    city: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    state: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    pincode: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    address1: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    address2: {
      required: true,
      editable: true,
      tabName: 'Booking Details'
    },
    addressProof: {
      required: false,
      editable: true,
      tabName: 'Booking Details'
    },
    nameOnRc: {
      required: false,
      editable: true,
      tabName: 'Booking Details'
    },
    buyerPincode: {
      required: true,
      editable: true,
      tabName: 'Customer Details'
    }
  }
};

export const CF_OFFER_TYPE = [
  'SYSTEM',
  'CUSTOMISE',
  'CUSTOMISED'
];

export const YES_NO = {
  YES: "Yes",
  NO: "No"
};

export const ReimbursementOptedValue = {
  YES: "yes_-_reimbursement_opted_by_customer",
  NO: "no_-_reimbursement_not_opted_by_customer"
};

export const ReimbursementDoneValue = {
  YES: "yes_-_reimbursement_done_for_customer",
  NO: "no_-_reimbursement_not_done_for_customer"
};

export const ReimbursementOptedList = [
  {label: YES_NO.YES, value: ReimbursementOptedValue.YES },
  {label: YES_NO.NO, value: ReimbursementOptedValue.NO},
];

export const ReimbursementDoneList = [
  {label: YES_NO.YES, value: ReimbursementDoneValue.YES},
  {label: YES_NO.NO, value: ReimbursementDoneValue.NO},
];
export const COUNTRY_LIST = [
  { value: 'IN', label: 'India' },
  { value: 'AU', label: 'Australia' },
  { value: 'AE', label: 'UAE' }
];

export const VEHICLE_TYPES = [
  { value: 'CAR', label: 'Car' },
  { value: 'BIKE', label: 'Bike' }
];

export const LANGUAGE_TYPES = [
  { value: 'Hindi', label: 'Hindi' },
  { value: 'English', label: 'English' },
  { value: 'Tamil', label: 'Tamil' },
  { value: 'Telugu', label: 'Telugu' },
  { value: 'Kannada', label: 'Kannada' },
  { value: 'Malayalam', label: 'Malayalam' },
  { value: 'Bengali', label: 'Bengali' },
  { value: 'Gujarati', label: 'Gujarati' }
];

export const PREFERRED_LANGUAGE_TYPES = [
  { value: 'Hindi', label: 'Hindi' },
  { value: 'English', label: 'English' },
  { value: 'Tamil', label: 'Tamil' },
  { value: 'Telugu', label: 'Telugu' },
  { value: 'Kannada', label: 'Kannada' },
  { value: 'Malayalam', label: 'Malayalam' },
  { value: 'Bengali', label: 'Bengali' },
  { value: 'Gujarati', label: 'Gujarati' }
];

export const FORM_VALIDATION_FIELDS = [
  'name',
  'email',
  'buyerPincode',
  'deliveryPincode',
  'deliveryMode',
  'deliveryAddress1',
  'deliveryAddress2',
  'deliveryState',
  'deliveryCity',
  'deliveryPlace',
  'visitTime',
  'visitDate'
];

export const IGNORE_VALIDATIONS_FIELDS = {
  [DELIVERY_MODES.PICK_UP]: [
    'deliveryState',
    'deliveryCity',
    'deliveryAddress1',
    'deliveryAddress2',
    'visitTime'
  ],
  [DELIVERY_MODES.HOME_DELIVERY]: [
    'deliveryPlace',
  ],
  '' : []
};

export const FORM_VALIDATION_RULES = {
  name: {
    required: true,
    editable: true,
    tabName: 'Customer Details'
  },
  email: {
    required: true,
    editable: true,
    tabName: 'Customer Details'
  },
  buyerPincode: {
    required: true,
    editable: true,
    tabName: 'Customer Details'
  },
  deliveryPincode: {
    required: true,
    editable: true,
    tabName: 'Delivery Details'
  },
  deliveryMode: {
    required: true,
    editable: true,
    tabName: 'Delivery Details'
  }, 
  deliveryAddress1: {
    required: true,
    editable: true,
    tabName: 'Delivery Details'
  },
  deliveryAddress2: {
    required: true,
    editable: true,
    tabName: 'Delivery Details'
  },
  deliveryState: {
    required: true,
    editable: true,
    tabName: 'Delivery Details'
  },
  deliveryCity: {
    required: true,
    editable: true,
    tabName: 'Delivery Details'
  },
  deliveryPlace: {
    required: true,
    editable: true,
    tabName: 'Delivery Details'
  },
  deliveryTime: {
    required: true,
    editable: true,
    tabName: 'Delivery Details'
  },
  paymentMode: {
    required: true,
    editable: true,
    tabName: 'Payment Details'
  },
  visitTime: {
    required: true,
    editable: true,
    tabName: 'Delivery Details'
  },
  visitDate: {
    required: true,
    editable: true,
    tabName: 'Delivery Details'
  },
};

export const ZENDESK_OPEN_TICKET_TYPES = [
  "NEW", 
  "OPEN", 
  "PENDING",
  "HOLD"
];

export const BOOKING_STATUSES = {
  CREATED : "CREATED",
  BOOKED: "BOOKED",
  EXPIRED: "EXPIRED", 
  CANCEL: "CANCEL",  
  DELIVERED: "DELIVERED", 
  RETURNED: "RETURNED"
};

export const ACTIVE_BOOKING_TYPES = [
  BOOKING_STATUSES.BOOKED,
  BOOKING_STATUSES.CREATED
];

export const LEAD_TYPE = {
  PostBooking: 'Post_Booking',
  PreBooking: 'Pre_Booking'
};
export const DERIVED_LOAN_STATUS_KEYS = [
  "LoanApplicationReceived"
];

export const FINANCE_STATUS = {
  DONTKNOW: "Don’t Know",
  INCOMPLETE: "Form Incomplete",
  FILLED: "Form Filled",
  NON_SERVICE: "Non-Serviceable CF Location",
  LOAN_NOT_AVAILABLE: "Loan Not Available",
  OFFER_BANKING_COMPLETE: "Offer Generated - Banking completed",
  OFFER_BANKING_INCOMPLETE: "Offer Generated - Banking Incomplete",
  OFFER_REJECTED: "Offer Rejected",
  COMPLETED: "Completed"
};

export const DONT_CALL_DS_OFFER = [
  FINANCE_STATUS.DONTKNOW,
  FINANCE_STATUS.INCOMPLETE,
  FINANCE_STATUS.OFFER_REJECTED,
  FINANCE_STATUS.NON_SERVICE,
  FINANCE_STATUS.LOAN_NOT_AVAILABLE
];

export const BANK_STATEMENT_STATUS = {
  COMPLETED: 'Completed', 
  PENDING_INCOMPLETE_UPLOAD: 'Pending - Incomplete Upload',
  PENDING_NOT_UPLOADED: 'Pending - Not Uploaded',
  REJECTED: 'Rejected'
};

export const TICKET_TYPES = {
  REPAIR: "Repair", 
  RETURN: "Return"
};

export const DISCOUNT_STATUS = {
  DONTKNOW: "Don't Know", 
  NOTAVAILABLE: "Not Available",
  AVAILABLE: "Available"
};

export const ticketTypeList = [
  {label: TICKET_TYPES.REPAIR, value: TICKET_TYPES.REPAIR},
  {label: TICKET_TYPES.RETURN, value: TICKET_TYPES.RETURN},
];

export const docTypeList = [
  {value: POST_DELIVERY_DOC_TYPES.INVOICE, label: "Invoice"},
  {value: POST_DELIVERY_DOC_TYPES.INSURANCE, label: "Insurance"},
  {value: POST_DELIVERY_DOC_TYPES.WARRANTY, label: "Warranty"},
  {value: POST_DELIVERY_DOC_TYPES.E_RECEIPT, label: "eReceipt"},
  {value: POST_DELIVERY_DOC_TYPES.BUYER_INSURANCE, label: "Buyer Insurance"},
  {value: POST_DELIVERY_DOC_TYPES.FORM_29, label: "FORM 29"},
  {value: POST_DELIVERY_DOC_TYPES.FORM_30, label: "FORM 30"},
  {value: POST_DELIVERY_DOC_TYPES.SELLER_RC, label: "Seller RC"},
];

export const CAMPAIGN_LIST = [
  { value: DispositionConstants.CCOB_ASSISTED_BOOKING, label: 'Pre Booking' },
  { value: DispositionConstants.CC_OUTBOUND_BUYER, label: 'Post Booking' },
  { value: DispositionConstants.CC_MISSED_CALL, label: 'Post Delivery' }
];

export const SEARCH_BY = {
  PHONE: 'PHONE',
  BOOKING_ID: 'BOOKING_ID',
  UID: 'UID'
};

export const crmPaymentModesValues = {
  CARS24_FINANCE: "CARS24_FINANCE",
  PAY_ON_DELIVERY: "PAY_ON_DELIVERY",
};

export const crmPaymentModes = {
  [crmPaymentModesValues.CARS24_FINANCE]: "Yes",
  [crmPaymentModesValues.PAY_ON_DELIVERY]: "No",
};

export const FINAL_OFFER_CHANNELS = [
  'GreenChannelQualified', 
  'GreenChannelQualified-Cars24', 
  'GreenChannelQualified-Bajaj PA', 
  'GreenChannelQualified-Bajaj PQ'
];

export const CAR_SOURCE = {
  CARS24: "Cars24",
  CAR_TRADE: "CarTrade"
};
