import React, { useState } from 'react'

const DelayReasonStatusDropdown = ({
  delayReasonId,
  value: initialValue,
  options,
  onChange
}) => {
  const [value, setValue] = useState(initialValue || "");

  const handleStatusSave = (updatedValue) => {
    setValue(updatedValue);
  }

  const handleChange = (e) => {
    const { value } = e.target;
    const callback = () => handleStatusSave(value);

    onChange(delayReasonId, value, callback);
  }

  return (
    <td>
      <select
        optionsList={options}
        isClearable={false}
        value={value}
        onChange={handleChange}
      >
        <option value="" disabled>Select</option>
        {options.map(({ label, value }) => <option value={value}>{label}</option>)}
      </select>
    </td>
  )
}

export default DelayReasonStatusDropdown;