export const RC_VERIFY_COLUMNS = [
    {
        value: 'category_name',
        label: 'RC Fields Check',
        className: 'col-2',
        type: 'title',
    },
    {
        value: 'expected_value',
        label: 'Konnect',
        className: 'col-2',
        type: 'text-readonly',
    },
    {
        value: 'vahan_value',
        label: 'From Vaahan',
        className: 'col-3',
        type: 'text-readonly',
    },
    {
        value: 'status_value',
        label: 'Status',
        className: 'col-2',
        type: 'select',
    },
    {
        value: 'remarks',
        label: 'Remarks',
        type: 'textv2',
        className: 'col-3',
    },
];

export const RC_VERIFICATION_STATUSES = [
    {value: "PENDING", label: "Pending" },
    {value: "APPROVED", label: "Approved" },
    {value: "TO_DISCUSS", label: "To Discuss" },
    {value: "REJECTED", label: "Rejected" },
];