import React, { useState } from 'react';
import Pricing from '../pricing';
import StoreForm from '../store-form';
import { ENTITY_TYPES } from './constants';
import { withRouter } from 'react-router-dom';
const C2cStoreView = ({ match, isAdd }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleOnClick = (selectedIndex) => {
    setSelectedIndex(selectedIndex);
  };
  const tabsList = () => {
    let tabs = [ENTITY_TYPES.STORE];

    if (!isAdd) {
      tabs.push([ENTITY_TYPES.PRICING]);
    }

    return tabs;
  };
  const tabs = () => {
    return (
      <ul className='nav nav-tabs'>
        {tabsList().map((item, index) => {
          return (
            <li
              key={index}
              className={selectedIndex === index ? 'active' : ''}
              onClick={() => handleOnClick(index)}
            >
              <a>
                <b>{item}</b>
              </a>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div className='col-md-12'>
      <div className='nav-tabs-custom no-box-shadow '>
        {tabs()}
        <div className='tab-content'>
          {selectedIndex === 0 && (
            <div
              className={selectedIndex === 0 ? 'tab-pane active' : 'tab-pane'}
            >
              <StoreForm />
            </div>
          )}
          {selectedIndex === 1 && (
            <div
              className={selectedIndex === 1 ? 'tab-pane active' : 'tab-pane'}
            >
              <Pricing id={match.params.id} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(C2cStoreView);
