import { useEffect, useState } from "react";
import { ulipService } from "../../../services"

export const useUlip = () => {
  const {
    fetchUlipConfig,
    fetchUlipData,
    updateUlipData,
  } = ulipService;
  const [config, setConfig] = useState({
    stage: [],
    days: [],
  });
  const [ulipData, setUlipData] = useState(null);

  const getUlipConfig = () => {
    fetchUlipConfig()
      .then((resp) => {
        setConfig(resp);
      })
  };

  const getUlipData = () => {
    fetchUlipData()
      .then((resp) => {
        setUlipData(resp);
      })
  }
  useEffect(() => {
    getUlipConfig();
    getUlipData();
  }, []);

  return {
    config,
    ulipData,
    getUlipData,
    updateUlipData,
  }
}