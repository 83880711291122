/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import RcDocument from '../../rc-tracker-document';
import { withRouter } from 'react-router-dom';
import { rcManagementService } from '../../../../services';
import { BUYER, OWNER } from './constant';
import PaymentStatus from '../../payment-status';
import LoaderWithAccessControl from '../../../../shared/components/loader-with-access-control';
import CustomDatePicker from '../../../../shared/components/date-picker/component';
import { SelectDropdown } from '../../../../client/components/shared';
import RcDocumentsRemarks from '../../rc-documents-remarks';
import { YES } from '../../../../shared/utils/constants';
import globalStyle from '../../../utils/global.module.css';
import style from './styles.module.css';
import { 
  onChangeDelayCategory,
  onChangeDelaySubCategory, 
} from "../../../viewModels/rc-management";
import { getDelaySubCategoryList, RC_OTHERS_DELAY_REASONS_KEY, getDelayCategoryList } from '../constants';

const file_closure_delay_key = 'file_closer_delay';

function RcDocuments({
  fetchRcDocumentDetail,
  fetchOthersRcDocument,
  match,
  dealInfo, 
  addRc,
  editRc,
  delayReasons,
}) {
  const [error, setError] = useState();
  const [rcTrackerAllData, setRcTrackerAlldata] = useState();
  const [rcTrackerAllDataOriginal, setRcTrackerAlldataOriginal] = useState();
  const [fileClosuereCategories, setFileClosuereCategories] = useState([]);
  const [fileClosuereSubCategories, setFileClosuereSubCategories] = useState();
  const dealId = match.params.id;

  const { addressOnRc='', fathersName='' } = dealInfo || {};
  const handleSave = () => {
    setError("");
    if (rcTrackerAllData && rcTrackerAllData[file_closure_delay_key] && rcTrackerAllData[file_closure_delay_key].category && !rcTrackerAllData[file_closure_delay_key].subCategory) {
      setError(`Required: Sub Delay Reason for File Closure Delay`);
      return;
    }

    let data =
      rcTrackerAllData &&
      rcTrackerAllData.documents &&
      rcTrackerAllData.documents.filter((item) => (item.id ? true : false));

    let body = {
      ...rcTrackerAllData,
      rc_application_id: match.params.id
    };

    if (data && data.length > 0) {
      editRc(body, true).then(() => {
        fetchDetail(match.params.id);
      });
    } else {
      addRc(body, true).then(() => {
        fetchDetail(match.params.id);
      });
    }
  };
  const handleCheckboxChange = (key, type) => {
    let _rcTrackerAllData = { ...rcTrackerAllData };
    _rcTrackerAllData[type][key]['isAvailable'] = !rcTrackerAllData[type][key]
      .isAvailable;
    setRcTrackerAlldata(_rcTrackerAllData);
  };
  const handleOptionChange = (index, e) => {
    let _rcTrackerAllData = { ...rcTrackerAllData };
    _rcTrackerAllData['documents'][index]['file_status'] = e.target.value;

    setRcTrackerAlldata(_rcTrackerAllData);
  };
  const updateDocument = (index, key, value) => {
    let _rcTrackerAllData = { ...rcTrackerAllData };
    _rcTrackerAllData['documents'][index][key] = value;
    setRcTrackerAlldata(_rcTrackerAllData);
  };
  const handlePaymentStatusChange = (e, type) => {
    let _rcTrackerAllData = { ...rcTrackerAllData };
    if (type === 'checkbox') {
      _rcTrackerAllData[e.target.name] = e.target.checked;
    } else {
      _rcTrackerAllData[e.target.name] = e.target.value;
    }
    setRcTrackerAlldata(_rcTrackerAllData);
  };
  const deleteFile = (fileId, index) => {
    rcManagementService
      .deleteFile(fileId, true)
      .then((res) => {
        let tempObj = { ...rcTrackerAllData };
        const fileStatus = tempObj['documents'][index].file_status;
        tempObj['documents'][index].file_name = null;
        tempObj['documents'][index].id = null;
        tempObj['documents'][index].file_status =
          fileStatus === YES ? null : fileStatus;
        setRcTrackerAlldata(tempObj);
        setRcTrackerAlldataOriginal(tempObj);
      })
      .catch((err) => {});
  };

  const getRcDocuments = (id) => {
    return fetchOthersRcDocument(id);
  }

  const fetchDetail = (id) => {
    getRcDocuments(id).then((data) => {
      setRcTrackerAlldata(data && data);
      setRcTrackerAlldataOriginal(data && data);
    });
  };
  useEffect(() => {
    fetchDetail(match.params.id);
  }, [match.params.id]);

  useEffect(() => {
    setFileClosuereCategories(getDelayCategoryList(delayReasons, RC_OTHERS_DELAY_REASONS_KEY.FILE_CLOSURE_DELAY));
    if (rcTrackerAllData && rcTrackerAllData[file_closure_delay_key] && rcTrackerAllData[file_closure_delay_key].category) {
      setFileClosuereSubCategories(getDelaySubCategoryList(delayReasons, RC_OTHERS_DELAY_REASONS_KEY.FILE_CLOSURE_DELAY, rcTrackerAllData[file_closure_delay_key].category))
    }
  }, [delayReasons, rcTrackerAllData]);

  const handleDateChange = (date, keyname) => {
    setRcTrackerAlldata({
      ...rcTrackerAllData,
      [keyname]: date
    });
  };

  return (
    <LoaderWithAccessControl>
      {rcTrackerAllData && (
        <div className='row'>
          <div className='col-3 mb20'>
            <div className='row'>
              <div
                className={`col-6 d-flex align-items-center ${
                  globalStyle.fs14
                }`}
              >
                File received date from sales
              </div>
              <div
                className={`datepicker_custom ${
                  style.rcDocumentDatepicker
                } col-6`}
              >
                <CustomDatePicker
                  name='file_received_date_from_sales'
                  selected={rcTrackerAllData['file_received_date_from_sales']}
                  onDateChange={handleDateChange}
                />
              </div>
            </div>
          </div>
          <div className='col-5 mb20'>
            <div className='row'>
              <div
                className={`col-2 d-flex align-items-center ${
                  globalStyle.fs14
                }`}
              >
                File Closure Delay
              </div>
              <div
                className={`col-4`}
              >
                <SelectDropdown
                  name={`${file_closure_delay_key}_category`}
                  optionsList={fileClosuereCategories}
                  value={rcTrackerAllData[file_closure_delay_key] ? rcTrackerAllData[file_closure_delay_key].category : null}
                  onChange={(value)=> onChangeDelayCategory({key: file_closure_delay_key, data: value, trackerData: rcTrackerAllData, updateTrackerData: setRcTrackerAlldata})}
                  isEmptyDefaultValue={rcTrackerAllDataOriginal && rcTrackerAllDataOriginal[file_closure_delay_key] && rcTrackerAllDataOriginal[file_closure_delay_key].category ? false : true}
                  isSelectRequired={false}
                />
              </div>
              <div
                className={`col-6`}
              >
                <SelectDropdown
                  name={`${file_closure_delay_key}_sub_category`}
                  optionsList={fileClosuereSubCategories}
                  value={rcTrackerAllData[file_closure_delay_key] ? rcTrackerAllData[file_closure_delay_key].subCategory : null}
                  onChange={(value)=> onChangeDelaySubCategory({key: file_closure_delay_key, data: value, trackerData: rcTrackerAllData, updateTrackerData: setRcTrackerAlldata})}
                  isEmptyDefaultValue={true}
                  isSelectRequired={false}
                />
              </div>
            </div>
          </div>
          <div className='col-4 mb20'>
            <div className='row'>
              <div
                className={`col-4 d-flex align-items-center ${
                  globalStyle.fs14
                }`}
              >
                Disbursement Date
              </div>
              <div
                className={`datepicker_custom ${
                  style.rcDocumentDatepicker
                } col-8`}
              >
                <CustomDatePicker
                  name='disbursementDate'
                  selected={rcTrackerAllData['disbursementDate']}
                  onDateChange={handleDateChange}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {(!fathersName || !addressOnRc) && <div className='row'>
        <div className='wrapperAgentDetails errorText p15'>
          <strong> Note: “Address on RC”, “Father’s name” is mandatory for file closure. Please enter these in the Deal Information tab.</strong>
        </div>
      </div>}
      <RcDocument
        dealId={dealId}
        type={BUYER}
        documents={rcTrackerAllData && rcTrackerAllData.documents}
        id={match.params.id}
        updateDocument={updateDocument}
        handleCheckboxChange={handleCheckboxChange}
        fetchRcDocumentDetail={fetchRcDocumentDetail}
        deleteFile={deleteFile}
        handleOptionChange={handleOptionChange}
        // labelName={labelName}
        labels={
          rcTrackerAllData && rcTrackerAllData.labels
            ? rcTrackerAllData.labels
            : []
        }
      />
      <RcDocument
        dealId={dealId}
        type={OWNER}
        documents={rcTrackerAllData && rcTrackerAllData.documents}
        id={match.params.id}
        updateDocument={updateDocument}
        handleCheckboxChange={handleCheckboxChange}
        fetchRcDocumentDetail={fetchRcDocumentDetail}
        deleteFile={deleteFile}
        handleOptionChange={handleOptionChange}
        // labelName={labelName}
        labels={
          rcTrackerAllData && rcTrackerAllData.labels
            ? rcTrackerAllData.labels
            : []
        }
      />
      <PaymentStatus
        paymentStatus={rcTrackerAllData && rcTrackerAllData.payment_received}
        data={rcTrackerAllData}
        payment_received_disabled={
          !!rcTrackerAllData ? rcTrackerAllData.payment_received_enabled : false
        }
        handlePaymentStatusChange={handlePaymentStatusChange}
      />

      <div className='row'>
        <div className='form-group col-md-1'>
          <button onClick={handleSave} className='btn btn-primary '>
            Save
          </button>
        </div>
        <div className='form-group col-md-6'>
          {!!error && <div className={`${style.customError}`}>{error}</div>}
        </div>
      </div>
      <div className='wrapperAgentDetails mt-20 p15'>
        <RcDocumentsRemarks remarksId={dealId} remarksType='DOCUMENT' />
      </div>
    </LoaderWithAccessControl>
  );
}
export default withRouter(RcDocuments);
