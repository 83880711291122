import * as Yup from 'yup';
import * as Schema from '../../helper/validations';
import { PAYMENT_MODES } from '../finance-payment-tab/constants';
//import { VALIDATION } from '../components/finance-payment-tab/constants';

export const validationSchema = Yup.object().shape({
  bank_name: Yup.string().when('payment_mode_id', {
    is: (paymentMode) =>
      (paymentMode && paymentMode.value === PAYMENT_MODES.CASH) ||
      (paymentMode && paymentMode.value === PAYMENT_MODES.CHEQUE),
    then: Schema.validationMessage('Bank Name Required'),
    otherwise: Schema.string
  }),
  utr: Yup.string().when('payment_mode_id', {
    is: (paymentMode) =>
      (paymentMode && paymentMode.value === PAYMENT_MODES.UPI) ||
      (paymentMode && paymentMode.value === PAYMENT_MODES.FUND_TRANSFER) ||
      (paymentMode && paymentMode.value === PAYMENT_MODES.WALLET) ||
      (paymentMode && paymentMode.value === PAYMENT_MODES.CARD),
    then: Schema.validationMessage('Utr Required'),
    otherwise: Schema.string
  }),
  payment_mode_id: Schema.payment_mode,

  amount: Schema.amount
});
