import { getUploadAllocationEndpoint } from '../../client/components/shared/Constants';
import { showToastMessages } from '../../shared/utils/helper';
export default (api) => {
  const uploadFile = (params, allocationType) => {
    const endPoint = getUploadAllocationEndpoint(allocationType);
    let headers = { 'Content-Type': 'multipart/form-data' };
    return api[endPoint.method](endPoint.path, params, { headers: headers })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        showToastMessages(error, false);
        return error;
      });
  };
  return {
    uploadFile
  };
};
