import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    FETCH_ORDER_IMAGE_INIT
    FETCH_ORDER_IMAGE_SUCCESS
    FETCH_ORDER_IMAGE_FAILURE
    UPDATE_ORDER_IMAGE_SUCCESS
    APPROVE_ORDER_IMAGE_SUCCESS
`,
  {
    prefix: 'carimages/'
  }
);
