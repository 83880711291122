import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  message: null,
  rcManagement: {},
  orders: {},
  stores: {},
  dealers: {},
  rto: {},
  zones: {},
  city: {},
  users: {},
  agents: {},
  documentsRegionList: {},
  taskManagment: {},
  documentsList: {},
  regionAllocation: {},
  rcManagementOthers: {},
};

export const setGridFilterConnected = (state = INITIAL_STATE, action) => {
  const {
    uniqueGridId,
    //filterName,
    filterData,
    filterRaw,
    model
  } = action.payload;

  const gridParams = {
    ...state[uniqueGridId],
    filter: { ...state[uniqueGridId]['filter'], ...filterData },
    raw: { ...state[uniqueGridId]['raw'], ...filterRaw },
    model: { ...state[uniqueGridId]['model'], ...model }
  };
  return { ...state, [uniqueGridId]: gridParams };
};

export const setGridModelConnected = (state = INITIAL_STATE, action) => {
  const { uniqueGridId, model, filter } = action.payload;
  const gridParams = {
    ...state[uniqueGridId],
    model: { ...state[uniqueGridId]['model'], ...model },
    filter: { ...state[uniqueGridId]['filter'], ...filter }
  };
  return { ...state, [uniqueGridId]: gridParams };
};

// sprint 9 todo dummy fn
export const resetGridModelConnected = (state = INITIAL_STATE, action) => {
  return { ...state };
};

export const resetAllGridModelConnected = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    rcManagement: {},
    orders: {},
    stores: {},
    dealers: {},
    rto: {},
    zones: {},
    city: {},
    users: {},
    documentsRegionList: {},
    taskManagment: {},
    regionAllocation: {},
    rcManagementOthers: {},
  };
};

// sprint 9 todo dummy fn

export const updateGridFilterConnected = (state = INITIAL_STATE) => {
  return {
    ...state
  };
};

export const HANDLERS = {
  [Types.SET_GRID_FILTER]: setGridFilterConnected,
  [Types.RESET_ALL_GRID_MODEL]: resetAllGridModelConnected,
  [Types.SET_GRID_MODEL]: setGridModelConnected,
  [Types.RESET_GRID_MODEL]: resetGridModelConnected,
  [Types.UPDATE_GRID_FILTER]: updateGridFilterConnected
};

export default createReducer(INITIAL_STATE, HANDLERS);
