import store from '../store';
import {
  setAccessLoaderConnected,
  resetAccessLoaderConnected,
  setForbiddenConnected,
  setErrorConnected
} from '../shared/components/loader-with-access-control/actions';
import { FORBIDDEN } from '../client/components/shared/Constants';

export const dispatchSetLoaderConnected = (withLoader = true) => {
  withLoader && store.dispatch(setAccessLoaderConnected());
};

export const dispatchResetLoaderConnected = () => {
  store.dispatch(resetAccessLoaderConnected());
};

export const dispatchForbiddenConnected = () => {
  store.dispatch(setForbiddenConnected());
};

export const dispatchSetErrorConnected = (error) => {
  store.dispatch(setErrorConnected(error));
};

export const handleHttpStatusError = (error, setError = false) => {
  if (error.status) {
    if (error.status === FORBIDDEN) {
      dispatchForbiddenConnected();
    } else if (setError) {
      dispatchSetErrorConnected(error);
    } else {
      dispatchResetLoaderConnected();
    }
  } else {
    dispatchSetErrorConnected({ message: error });
  }
};

export const buildQuery = (obj) => {
  // If the obj is already a string, return it as-is
  if (typeof obj === 'string') return obj;

  // Create a query array to hold the key/value pairs
  var queryArr = [];

  // Loop through the obj object
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Encode each key and value, concatenate them into a string, and push them to the array
      if (Array.isArray(obj[key]) && obj[key].length > 0) {
        const encodedValues = obj[key].map((item) => encodeURIComponent(item));
        queryArr.push(encodeURIComponent(key) + '=' + encodedValues.join(','));
      } else if (obj[key] !== '' && obj[key] !== null) {
        queryArr.push(
          encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
        );
      }
    }
  }

  // Join each item in the array with a `&` and return the resulting string
  return queryArr.join('&');
};
