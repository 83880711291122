import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RcTracker from './component';
import {
  StoreActions,
  StateActions,
  CityActions,
  ZoneActions
} from '../../actions';
import {
  setPageNumberConnected,
  fetchRcManagementGridInfoConnected,
  fetchOthersRcList
} from './actions';
const mapStateToProps = (state) => {
  const {
    detail,
    rcManagementInfo: { pageNumber }
  } = state;
  return {
    detail,
    pageNumber
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchStoreDetail: (storeId) => StoreActions.fetchStoreDetail(storeId),
      addZone: (data) => ZoneActions.addZone(data),
      fetchStates: () => StateActions.fetchStates(),
      fetchCities: (params) => CityActions.fetchCities(params),
      setPageNumberConnected,
      fetchRcManagementGridInfoConnected,
      fetchOthersRcListConnect: fetchOthersRcList
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RcTracker);
