import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  OrderActions,
  DealerActions,
  StateActions,
  CityActions
} from "./../../actions";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import withAdminLayout from "../../../shared/hoc/admin-layout";
import { withAuthorization } from "../../../shared/utils/firebase";

class AddB2c extends Component {
  state = {
    appointmentId: "",
    make: "",
    model: "",
    variant: "",
    carId: "",
    carCode: "",
    storeId: "",
    b2cDealerListPrice: "",
    b2cAppointmentId: "",
    b2cCarId: "",
    b2cCarCode: "",
    b2cStoreId: "",
    dealerList: [],
    parsedDealerList: [],
    b2cOrderSubmit: false,
    redirect: false,
    cityId: "",
    stateId: "",
    cities: [],
    states: [],
    dealerId: "",
    hvb: ""
  };

  //handle input changes
  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value
      },
      async function() {
        if (name === "stateId") {
          this.setState({ selectedDealerOption: "" });
          let cities = await this.props.fetchCities({
            stateId: this.state.stateId
          });
          if (cities && cities.length > 0) {
            this.setState({ cities: cities });
          }
        }
        if (name === "cityId") {
          this.setState({ selectedDealerOption: "" });
          //fetch dealers list on the basis of city
          this.props.fetchDealerList({
            cityId: this.state.cityId
          });
        }
      }
    );
  };

  handleDealerChange = selectedDealerOption => {
    this.setState({
      b2cStoreId: selectedDealerOption.value,
      selectedDealerOption: selectedDealerOption
    });
  };

  //handle form submit
  handleSubmit = async e => {
    e.preventDefault();
    const data = {
      refAppointmentId: this.state.appointmentId,
      carId: this.state.b2cCarId,
      carCode: this.state.b2cCarCode,
      storeId: this.state.b2cStoreId,
      dealerListPrice: this.state.b2cDealerListPrice,
      appointmentId: this.state.b2cAppointmentId
    };
    console.log("add b2c order req");
    console.log(data);
    let resp = await this.props.addB2cOrder(data);
    console.log("add b2c order res");
    console.log(resp);
    if (typeof resp === "object" && resp.status == 201) {
      let dat = this;
      setTimeout(function() {
        dat.setState({ redirect: true });
      }, 600);
    }
  };

  fetchOrder = async e => {
    e.preventDefault();
    let appointmentId = this.state.appointmentId;
    this.props.getC2bOrder(appointmentId);
  };

  fetchSellerInspectionDetails = async e => {
    if (this.state.b2cAppointmentId) {
      e.preventDefault();
      let b2cAppointmentId = this.state.b2cAppointmentId;
      let sellerInspectionDetail = await this.props.fetchSellerInspectionDetails(
        b2cAppointmentId
      );
      if (sellerInspectionDetail) {
        this.setState({
          b2cCarId: sellerInspectionDetail.carId,
          b2cCarCode: sellerInspectionDetail.carCode
        });
      }
    } else {
      alert("Please enter B2C Appointment Id!");
    }
  };

  async componentDidMount() {
    this.props.resetC2bOrderFields();
    if (this.state.states.length == 0) {
      const states = await this.props.fetchStates();
      this.setState({ states: states });
      if (this.state.stateId) {
        let cities = await this.props.fetchCities({
          stateId: this.state.stateId
        });
        if (cities && cities.length > 0) {
          this.setState({ cities: cities });
        }
      }
    }
  }

  componentWillReceiveProps({ c2bOrder, dealerList }) {
    if (c2bOrder) {
      let state = {
        appointmentId: this.state.appointmentId,
        make: c2bOrder.make,
        model: c2bOrder.model,
        variant: c2bOrder.variant,
        carId: c2bOrder.carId,
        carCode: c2bOrder.carCode,
        storeId: c2bOrder.storeId,
        dealerList: dealerList,
        b2cOrderSubmit: c2bOrder.orderId ? true : false,
        dealerId: c2bOrder.dealerId,
        hvb: c2bOrder.hvb
      };
      this.setState(state);
    } else {
      let state = {
        appointmentId: this.state.appointmentId,
        make: "",
        model: "",
        variant: "",
        carId: "",
        carCode: "",
        storeId: "",
        b2cDealerListPrice: "",
        b2cAppointmentId: "",
        b2cStoreId: "",
        dealerList: this.state.dealerList,
        b2cOrderSubmit: false,
        dealerId: "",
        hvb: ""
      };

      this.setState(state);
    }
  }

  render() {
    const { redirect, selectedDealerOption } = this.state;
    if (redirect) {
      return <Redirect to="/orders" />;
    }

    let dealers = this.state.dealerList;
    let parsedDealerList = [];
    if (dealers && dealers.length > 0) {
      parsedDealerList = dealers.map(dealer => {
        return {
          value: dealer.dealerId,
          label: dealer.name
        };
      });
    }

    const states = this.state.states;
    let statesList = "";
    if (states && states.length > 0) {
      statesList = states.map(state => {
        return (
          <option key={state.stateId} value={state.stateId}>
            {state.stateName}
          </option>
        );
      });
    }

    const cities = this.state.cities;
    let citiesList = "";
    if (cities && cities.length > 0) {
      citiesList = cities.map(city => {
        return (
          <option key={city.cityId} value={city.cityId}>
            {city.cityName}
          </option>
        );
      });
    }

    return (
      <Fragment>
        <div className="boxs">
          <form
            name="addB2cForm"
            method="POST"
            autoComplete="off"
            onSubmit={e => this.handleSubmit(e)}
          >
            <div className="box-header">
              <h3 className="box-title">Add B2C Car</h3>
            </div>
            <div className="box-body col-md-12">
              <div className="d-inline-block form-group col-md-3">
                <input
                  name="appointmentId"
                  type="text"
                  maxLength="10"
                  placeholder="C2B Appointment ID"
                  className="form-control"
                  value={
                    this.state.appointmentId ? this.state.appointmentId : ""
                  }
                  onChange={e => this.handleInputChange(e)}
                />
              </div>
              <div className="d-inline-block form-group col-md-5">
                <button
                  onClick={e => this.fetchOrder(e)}
                  type="button"
                  className="btn btn-primary"
                >
                  Search
                </button>
              </div>
            </div>
            <div className="box-body col-md-12">
              <div className="d-inline-block form-group col-md-3">
                <label>Make</label>
                <input
                  type="text"
                  className="form-control"
                  name="make"
                  value={this.state.make ? this.state.make : ""}
                  readOnly={true}
                />
              </div>
              <div className="d-inline-block form-group col-md-3">
                <label>Model</label>
                <input
                  type="text"
                  className="form-control"
                  name="model"
                  value={this.state.model ? this.state.model : ""}
                  readOnly={true}
                />
              </div>
              <div className="d-inline-block form-group col-md-3">
                <label>Variant</label>
                <input
                  type="text"
                  className="form-control"
                  name="variant"
                  value={this.state.variant ? this.state.variant : ""}
                  readOnly={true}
                />
              </div>
              <div className="d-inline-block form-group col-md-3">
                <label>Car Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="carCode"
                  value={this.state.carCode ? this.state.carCode : ""}
                  readOnly={true}
                />
              </div>
              <div className="d-inline-block form-group col-md-3">
                <label>Car Id</label>
                <input
                  type="text"
                  className="form-control"
                  name="carId"
                  value={this.state.carId ? this.state.carId : ""}
                  readOnly={true}
                />
              </div>
              <div className="d-inline-block form-group col-md-3">
                <label>Dealer Id</label>
                <input
                  type="text"
                  className="form-control"
                  name="dealerId"
                  value={this.state.dealerId ? this.state.dealerId : ""}
                  readOnly={true}
                />
              </div>
              <div className="d-inline-block form-group col-md-3">
                <label>HVB</label>
                <input
                  type="text"
                  className="form-control"
                  name="hvb"
                  value={this.state.hvb ? this.state.hvb : ""}
                  readOnly={true}
                />
              </div>
            </div>

            <div className="box box-body col-md-12">
              <h4>B2C Appointment Id</h4>
              <div className="d-inline-block form-group col-md-3">
                <input
                  type="text"
                  maxLength="10"
                  className="form-control"
                  name="b2cAppointmentId"
                  placeholder="B2C Appointment ID"
                  required={true}
                  value={this.state.b2cAppointmentId}
                  onChange={e => this.handleInputChange(e)}
                />
              </div>
              <div className="d-inline-block form-group col-md-1">
                <button
                  onClick={e => this.fetchSellerInspectionDetails(e)}
                  type="button"
                  className="btn btn-primary"
                >
                  Search
                </button>
              </div>
              <div className="clearfix"></div>
              <div className="d-inline-block form-group col-md-3">
                <label>Car Code</label>
                <input
                  type="text"
                  maxLength="10"
                  className="form-control"
                  name="b2cCarCode"
                  required={true}
                  value={this.state.b2cCarCode}
                  onChange={e => this.handleInputChange(e)}
                />
              </div>
              <div className="d-inline-block form-group col-md-3">
                <label>Car Id</label>
                <input
                  type="text"
                  maxLength="10"
                  className="form-control"
                  name="b2cCarId"
                  required={true}
                  value={this.state.b2cCarId}
                  onChange={e => this.handleInputChange(e)}
                />
              </div>
              <div className="d-inline-block form-group col-md-3">
                <label>State</label>
                <select
                  className="form-control"
                  name="stateId"
                  id="stateId"
                  onChange={e => this.handleInputChange(e)}
                  required
                  value={this.state.stateId}
                >
                  <option value="">--Select State--</option>
                  {statesList}
                </select>
              </div>
              <div className="d-inline-block form-group col-md-3">
                <label>City</label>
                <select
                  className="form-control"
                  name="cityId"
                  id="cityId"
                  onChange={e => this.handleInputChange(e)}
                  required
                  value={this.state.cityId}
                >
                  <option value="">--Select City--</option>
                  {citiesList}
                </select>
              </div>
              <div className="d-inline-block form-group col-md-3">
                <label>Dealer</label>
                <Select
                  name="b2cStoreId"
                  value={selectedDealerOption}
                  onChange={this.handleDealerChange}
                  options={parsedDealerList}
                />
              </div>
              <div className="d-inline-block form-group col-md-3">
                <label>Dealer List Price</label>
                <input
                  type="number"
                  className="form-control"
                  maxLength="10"
                  required={true}
                  name="b2cDealerListPrice"
                  value={this.state.b2cDealerListPrice}
                  onChange={e => this.handleInputChange(e)}
                />
              </div>
              {this.state.b2cOrderSubmit && (
                <div className="d-inline-block form-group col-md-3">
                  <div className="rows">&nbsp;</div>
                  <div className="form-group col-md-5">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

AddB2c.propTypes = {};
const mapStateToProps = ({ order: { c2bOrder }, dealer: { dealerList } }) => {
  return {
    c2bOrder,
    dealerList
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addB2cOrder: data => OrderActions.addB2cOrder(data),
      getC2bOrder: apppointmentId =>
        OrderActions.fetchC2bOrderDetail(apppointmentId),
      fetchDealerList: params => DealerActions.fetchC2cDealerList(params),
      resetC2bOrderFields: () => OrderActions.resetC2bOrderFields(),
      fetchStates: () => StateActions.fetchStates(),
      fetchCities: params => CityActions.fetchCities(params),
      fetchSellerInspectionDetails: appointmentId =>
        OrderActions.fetchSellerInspectionDetail(appointmentId)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAdminLayout(withAuthorization(()=>true)(AddB2c)));
