/* eslint-disable no-magic-numbers */
export default (pr, rt, tr) => {
    const p = Number(pr);
    let r = Number(rt);
    const n = Number(tr);
    if (p === 0 || r === 0 || n === 0) {
        return 0;
    }
    r /= (12 * 100);
    // n *= 12; // Use while sending years
    return p * r * Math.pow((1 + r), n) / (Math.pow((1 + r), n) - 1);
};

export const emiToLoanAmount = (emi, rt, tr) => {
    const e = Number(emi);
    let r = Number(rt);
    const n = Number(tr);

    if (e === 0 || r === 0 || n === 0) {
        return 0;
    }
    r /= (12 * 100);
    // n *= 12; // Use while sending years
    return (e * (Math.pow((1 + r), n) - 1)) / (r * Math.pow((1 + r), n));
};

export const getTenureData = (min, max, rate) => {
    const tenureLength = ((max - min) / rate + 1) || 0;
    return Array(tenureLength).fill(min).map((elem, index) => {
        const tenure = elem + (index * rate);
        return {
            year: tenure / rate,
            tenure
        };
    });
};
