import { createTypes } from 'reduxsauce';

export default createTypes(
  `

  `,
  {
    prefix: 'finance-service-tab/'
  }
);
