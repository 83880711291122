import { useEffect, useState } from "react";
import { rcManagementService } from "../../../services"

export const useRcOthersTrackerForm = () => {
  const getRcOthersTrackerConfig = rcManagementService.getRcOthersTrackerConfig;

  const [trackerConfig, setTrackerConfig] = useState({
    serviceStatus: [],
    aadharChangeStatus: [],
    physicalDocHandoverMethod: [],
    physicalDocHandoverStatus: [],
    dispatchLink: [],
  });

  const fetchTrackerConfig = () => {
    getRcOthersTrackerConfig()
      .then((resp) => {
        setTrackerConfig(resp);
      })
  };

  useEffect(() => {
    fetchTrackerConfig();
  }, [])

  return { trackerConfig, fetchTrackerConfig };
}