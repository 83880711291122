import React from 'react';
import { Label, Input } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './style/style.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../../../images/preloader.gif';

const CarDetails = ({ car, loadCarData }) => {
  if (loadCarData) {
    return (
      <div className='container text-center loader'>
        <img src={Loader} alt='Loading' className='center-block' />
      </div>
    );
  }

  return (
    <div>
      {
        <div className='car_container block'>
          <h2 className='title'>Car Details</h2>
          <div className='row'>
            <div className='col-6'>
              <div className='form-group d-flex main-box'>
                <div className='group'>
                  <Input
                    type='text'
                    className='txtbox'
                    disabled
                    name='make'
                    value={car ? car.make : ''}
                  />
                  <span className='highlight' />
                  <Label for='make' className='label'>
                    Make
                  </Label>
                </div>
              </div>
            </div>
            <div className='col-6'>
              <div className='form-group d-flex main-box'>
                <div className='group'>
                  <Input
                    type='text'
                    className='txtbox'
                    disabled
                    name='model'
                    value={car ? car.model : ''}
                  />
                  <span className='highlight' />
                  <Label for='model' className='label'>
                    Model
                  </Label>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-6'>
              <div className='form-group d-flex main-box'>
                <div className='group'>
                  <Input
                    type='text'
                    className='txtbox'
                    disabled
                    name='variant'
                    value={car ? car.variant : ''}
                  />
                  <span className='highlight' />
                  <Label for='variant' className='label'>
                    Variant
                  </Label>
                </div>
              </div>
            </div>
            <div className='col-6'>
              <div className='form-group d-flex main-box'>
                <div className='group'>
                  <Input
                    type='text'
                    className='txtbox'
                    disabled
                    name='kmsDriven'
                    value={car ? car.kmsDriven : ''}
                  />
                  <span className='highlight' />
                  <Label for='kmsDriven' className='label'>
                    Kms Driven
                  </Label>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-6'>
              <div className='form-group d-flex main-box'>
                <div className='group'>
                  <Input
                    type='text'
                    className='txtbox'
                    disabled
                    name='modelYear'
                    value={car ? car.modelYear : ''}
                  />
                  <span className='highlight' />
                  <Label for='modelYear' className='label'>
                    Model Year
                  </Label>
                </div>
              </div>
            </div>
            <div className='col-6'>
              <div className='form-group d-flex main-box'>
                <div className='group'>
                  <Input
                    type='text'
                    className='txtbox'
                    disabled
                    name='numberOfOwners'
                    value={car ? car.numberOfOwners : ''}
                  />
                  <span className='highlight' />
                  <Label for='numberOfOwners' className='label'>
                    No. Of Owners
                  </Label>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-6'>
              <div className='form-group d-flex main-box'>
                <Label for='isInsuranceAvailable' className='lbl'>
                  Insurance Available
                </Label>
                <br />
                <label className='radio_label'>
                  <input
                    type='radio'
                    className='radio-btn'
                    name='optradio'
                    readOnly
                    checked={car && car.isInsuranceAvailable ? true : false}
                  />{' '}
                  Yes
                </label>
                <label className='radio_label'>
                  <input
                    type='radio'
                    className='radio-btn'
                    name='optradio'
                    readOnly
                    checked={car && !car.isInsuranceAvailable ? true : false}
                  />{' '}
                  No
                </label>
              </div>
            </div>
            <div className='col-6'>
              <div className='form-group d-flex main-box'>
                <div className='group'>
                  <Input
                    type='text'
                    className='txtbox'
                    disabled
                    name='fuelType'
                    value={car ? car.fuelType : ''}
                  />
                  <span className='highlight' />
                  <Label for='fuelType' className='label'>
                    Fuel Type
                  </Label>
                </div>
              </div>
            </div>
          </div>
          {car && car.isInsuranceAvailable && (
            <div className='row'>
              <div className='col-6'>
                <div className='form-group d-flex main-box'>
                  <div className='group'>
                    <DatePicker
                      calendarClassName='datepicker-container'
                      selected={
                        car.insuranceExpiry
                          ? new Date(car.insuranceExpiry)
                          : null
                      }
                      dateFormat='d MMMM yyyy'
                      disabled={true}
                      className='picker'
                    />
                    <span className='highlight' />
                    <Label for='insuranceExpiry' className='label'>
                      Insurance Expiry
                    </Label>
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <div className='form-group d-flex main-box'>
                  <div className='group'>
                    <Input
                      type='text'
                      className='txtbox'
                      disabled
                      name='insuranceType'
                      value={car ? car.insuranceType : ''}
                    />
                    <span className='highlight' />
                    <Label for='insuranceType' className='label'>
                      Insurance Type
                    </Label>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className='row'>
            <div className='col-6'>
              <div className='form-group d-flex main-box'>
                <div className='group'>
                  <Input
                    type='text'
                    className='txtbox'
                    disabled
                    name='listPrice'
                    value={car ? car.listPrice : ''}
                  />
                  <span className='highlight' />
                  <Label for='listPrice' className='label'>
                    Listed Price
                  </Label>
                </div>
              </div>
            </div>
            <div className='col-6'>
              {!window.location.pathname.includes('cc-inbound-buyer') && (
                <div className='form-group d-flex main-box'>
                  <div className='group'>
                    <Input
                      type='text'
                      className='txtbox'
                      disabled
                      name='c24Quote'
                      value={car ? car.c24Quote : ''}
                    />
                    <span className='highlight' />
                    <Label for='c24Quote' className='label'>
                      C24 Quote:
                    </Label>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row'>
            {!window.location.pathname.includes('cc-inbound-buyer') && (
              <div className='col-6'>
                <div className='form-group d-flex main-box'>
                  <div className='group'>
                    <Input
                      type='text'
                      className='txtbox'
                      disabled
                      name='discountedPrice'
                      value={car ? car.discountedPrice : ''}
                    />
                    <span className='highlight' />
                    <Label for='discountedPrice' className='label'>
                      Discounted Price
                    </Label>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      }
    </div>
  );
};

export default CarDetails;
