import { ATTRIBUTE_KEYS } from "../../config/document-configs-columns";

export const getAddConfigState = (attributes) => {
  if(!attributes.documents.length) return {};
  let data = {};
  
  for(let key in attributes) {
    let item = attributes[key].find(item => item.default === true);
    let _key = key === 'documents' ? ATTRIBUTE_KEYS.DOCUMENT_ID : key;
    data[_key] = item.value;
  }
  data[ATTRIBUTE_KEYS.ALTERNATIVE_DOCS] = [];
  return data;
}