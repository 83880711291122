import React from 'react';
import withAdminLayout from '../../../shared/hoc/admin-layout';
import ErrorBoundary from '../../components/common/errorBoundary';
import { withAuthorization } from '../../../shared/utils/firebase';
import TaskManagementGrid from '../../components/task-management-grid';
import { AgentStatusToggle } from '../../components/agent-status-toggle/component';

const TaskManagementGridPage = () => {
  return (
    <div>
      <ErrorBoundary>
        <AgentStatusToggle />
        <TaskManagementGrid />
      </ErrorBoundary>
    </div>
  );
};

export default withAdminLayout(withAuthorization(() => true)(TaskManagementGridPage));
