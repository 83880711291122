import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  message: null,
  carDetails: {},
  order: {},
  despositions: {},
  makeList: {},
  modelList: {},
  variantList: {}
};

export const fetchCarDetailInit = (state = INITIAL_STATE) => {
  return {
    ...state,
    carDetails: {},
    order: {},
    despositions: {},
    isLoading: true,
    isError: false,
    message: null
  };
};

export const fetchCarDetailSuccess = (state = INITIAL_STATE, action) => {
  const { order, despositions } = action.payload;
  return { ...state, isLoading: false, isError: false, order, despositions };
};

export const fetchCarMakeSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    isLoading: false,
    isError: false,
    makeList: data,
    modelList: {},
    variantList: {}
  };
};
export const fetchCarModelSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    isLoading: false,
    isError: false,
    modelList: data,
    variantList: {}
  };
};
export const fetchCarVariantSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return { ...state, isLoading: false, isError: false, variantList: data };
};
export const fetchCarDetailFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    isError: true,
    message: 'Something failed'
  };
};

export const HANDLERS = {
  [Types.FETCH_ORDER_CAR_INIT]: fetchCarDetailInit,
  [Types.FETCH_ORDER_CAR_SUCCESS]: fetchCarDetailSuccess,
  [Types.FETCH_ORDER_CAR_FAILURE]: fetchCarDetailFailure,
  [Types.FETCH_ORDER_MAKE_LIST]: fetchCarMakeSuccess,
  [Types.FETCH_ORDER_MODEL_LIST]: fetchCarModelSuccess,
  [Types.FETCH_ORDER_VARIANT_LIST]: fetchCarVariantSuccess
};

export default createReducer(INITIAL_STATE, HANDLERS);
