import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GridView from './component';
import {
  setGridModelConnected,
  setGridFilterConnected,
  resetAllGridModelConnected
} from './actions';

const mapStateToProps = (state) => {
  const { gridInfo } = state;
  return { gridInfo };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setGridModelConnected,
      setGridFilterConnected,
      resetAllGridModelConnected
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GridView);
