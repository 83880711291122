import React from 'react';
import { Spinner } from 'reactstrap';
// import DownloadIcon from './images/download-icon.svg';
import DownloadIcon from '../../utils/images/download-icon.svg';
import ReplaceIcon from '../../utils/images/replace.svg';
import UploadIcon from '../../utils/images/upload-icon.svg';

const IMAGE_MAPPING = {
  download: DownloadIcon,
  replace: ReplaceIcon,
  upload: UploadIcon
};

const CustomButton = ({
  label = '',
  btnIcon = null,
  btnIconName = null,
  iconAlt = 'default',
  onClick = undefined,
  type = 'button',
  className = '',
  disabled = false,
  isLoading = false
}) => {
  return (
    <button
      className={`btn-primary btn ${className}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {label + ' '}
      {(btnIcon || IMAGE_MAPPING[btnIconName]) && (
        <i className='ml10'>
          <img
            src={btnIcon ? btnIcon : IMAGE_MAPPING[btnIconName]}
            alt={iconAlt}
          />
        </i>
      )}
      {isLoading && <Spinner size='sm' color='light' />}
    </button>
  );
};

export default CustomButton;
