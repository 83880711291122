// import Types from './types';
import { financeService } from '../../../services';
import { showToastMessages } from '../../../shared/utils/helper';

const submitPaymentProofConnected = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    financeService
      .submitPaymentProof(data)
      .then((resp) => {
        resolve(resp);
        showToastMessages(resp.data.message);
      })
      .catch((error) => {
        error.errors &&
          error.errors[0] &&
          error.errors[0]['message'] &&
          showToastMessages(error.errors[0]['message'], false);
        reject(error);
      });
  });
};

export { submitPaymentProofConnected };
