import Types from './types';
import { orderService } from '../../../services';

// Global actions
import {
  setAccessLoaderConnected,
  resetAccessLoaderConnected,
  setForbiddenConnected
} from '../../../shared/components/loader-with-access-control/actions';
import { handleHttpStatusError } from '../../../services/helper';

//Fetch order details
const fetchDealerDetailInit = () => ({
  type: Types.FETCH_ORDER_DEALER_INIT
});

const fetchDealerDetailSuccess = data => ({
  type: Types.FETCH_ORDER_DEALER_SUCCESS,
  payload: data
});

const fetchDealerDetailFailure = error => ({
  type: Types.FETCH_ORDER_DEALER_FAILURE,
  error
});

const fetchDealerDetailConnected = (appointmentId = 1005065197) => dispatch => {
  dispatch(setAccessLoaderConnected());
  dispatch(fetchDealerDetailInit());
  return new Promise((resolve, reject) => {
    orderService
      .fetchDealerDetail(appointmentId)
      .then(resp => {
        resolve(resp);
        dispatch(fetchDealerDetailSuccess(resp));
        dispatch(resetAccessLoaderConnected());
      })
      .catch(error => {
        dispatch(fetchDealerDetailFailure(error));
        handleHttpStatusError(error);
        reject(error);
      });
  });
};

export { fetchDealerDetailConnected };
