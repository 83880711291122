import { StateActions, CityActions } from '../../../admin-app/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CCOBAssistedBooking from './component';

const mapStateToProps = ({ dealerInformation: { detail } }) => {
  return {
    detail
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchStates: StateActions.fetchStates,
      fetchCities: CityActions.fetchCities
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CCOBAssistedBooking);
