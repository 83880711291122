import React, { useState } from 'react';
import { Label, Input } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './style/style.css';
const SellerPricingDetails = ({ seller }) => {
  const [sellerName, setSellerName] = useState(seller.name || '');
  const onSellerNameChange = (e) => {
    const { target: { value } } = e;
    setSellerName(value);
    seller.name = value;
  }

  return (
    <div>
      <div className="seller_section block">
        <h2 className="title lbl" htmlFor="name">Seller & Price Details
        </h2>
        <div className="row">
          {/* <div className="col-6 form-group seller-box">
            <Label for="name" className="lbl">Seller Name:</Label>
          </div> */}
          <div className="col-12 form-group seller-box">
            <div className="group">
              <Input type="text" className="txtbox" name="name" value={sellerName}
                onChange={onSellerNameChange} />
              <span className="highlight"></span>
              <Label for="name" className="label">Seller Name</Label>
            </div>
            {/* <Input type="text" className="txtbox" disabled name="name" value={seller.name} /> */}
          </div>
        </div>
        <div className="row">
          {/* <div className="col-6 form-group seller-box">
            <Label for="inspectedCity" className="lbl">Inspected At - City:</Label>
          </div> */}
          <div className="col-12 form-group seller-box">
            <div className="group">
              <Input type="text" className="txtbox" disabled name="inspectedCity" value={seller.inspectedCity} />
              <span className="highlight"></span>
              <Label for="inspectedCity" className="label">Inspected At - City</Label>
            </div>
            {/* <Input type="text" className="txtbox" disabled name="inspectedCity" value={seller.inspectedCity} /> */}
          </div>
        </div>
        <div className="row">
          {/* <div className="col-6 form-group seller-box">
            <Label for="inspectedStore" className="lbl">Inspected At - Store:</Label>
          </div> */}
          <div className="col-12 form-group seller-box">
            <div className="group">
              <Input type="text" className="txtbox" disabled name="inspectedStore" value={seller.inspectedStore} />
              <span className="highlight"></span>
              <Label for="inspectedStore" className="label">Inspected At - Store</Label>
            </div>
            {/* <Input type="text" className="txtbox" disabled name="inspectedStore" value={seller.inspectedStore} /> */}
          </div>
        </div>
        <div className="row">
          {/* <div className="col-6 form-group seller-box">
            <Label for="c24Quote" className="lbl">C24 Quote:</Label>
          </div> */}
          <div className="col-12 form-group seller-box">
            <div className="group">
              <Input type="text" className="txtbox" disabled name="c24Quote" value={seller.c24Quote} />
              <span className="highlight"></span>
              <Label for="c24Quote" className="label">C24 Quote</Label>
            </div>
            {/* <Input type="text" className="txtbox" disabled name="c24Quote" value={seller.c24Quote} /> */}
          </div>
        </div>
        <div className="row">
          {/* <div className="col-6 form-group seller-box">
            <Label for="maxListingPrice" className="lbl">Max Listing Price:</Label>
          </div> */}
          <div className="col-12 form-group seller-box">
            <div className="group">
              <Input type="text" className="txtbox" disabled name="maxListingPrice" value={seller.maxListingPrice} />
              <span className="highlight"></span>
              <Label for="maxListingPrice" className="label">Max Listing Price</Label>
            </div>
            {/* <Input type="text" className="txtbox" disabled name="maxListingPrice" value={seller.maxListingPrice} /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SellerPricingDetails;