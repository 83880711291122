import React from "react";
import "./styles.css";

export const ToggleButtonView = ({ isOn, onSelect }) => {
  return (
    <div
      className={`p-1 ${
        isOn ? "bg-success" : "bg-danger"
      } toggle-container`}
    >
      <div className="flex flex-row">
        <button
          className={`p-1 ${
            !isOn ? "bg-white" : "text-white"
          } toggle-btn btn`}
          onClick={() => onSelect(false)}
        >
          No
        </button>
        <button
          className={`p-1 ${
            isOn ? "bg-white" : "text-white"
          } toggle-btn btn`}
          onClick={() => onSelect(true)}
        >
          Yes
        </button>
      </div>
    </div>
  );
};