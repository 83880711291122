import React from 'react';
//import { SelectComponent } from '../../client/components/shared';
import { SelectComponent } from '../../../client/components/shared';
import InputText from '../input-text';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../../shared/components/date-picker/component';
import { CheckBox } from '../../../client/components/shared';
import { SelectDropdown } from '../../../client/components/shared';
const InputSwitch = ({
  param,
  item,
  name,
  value,
  onChange,
  list,
  readOnly = false,
  disabled,
  required,
  max,
  min,
  isActive,
  onChangeChechbox,
  maxLength,
  pattern
}) => {
  switch (param) {
    case 'date':
      return (
        <div className='datepicker_custom'>
          <CustomDatePicker
            readOnly={readOnly}
            disabled={readOnly}
            selected={value}
            onDateChange={(date) => onChange(name, date)}
          />
        </div>
      );

    case 'text':
      return (
        <InputText
          type='text'
          className='form-control'
          name={name}
          value={value}
          onChange={(e) => onChange(name, e.target.value)}
          readOnly={readOnly}
          disabled={disabled}
          required={required}
        />
      );
    case 'textv2':
      return (
        <InputText
          type='text'
          className='form-control'
          name={name}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          readOnly={readOnly}
          disabled={disabled}
          required={required}
        />
      );
    case 'dropDown':
      return (
        <SelectComponent
          key={value}
          name={name}
          optionsList={list(item.listName)}
          value={value}
          defaultValue={value}
          version='v2'
          placeholder={value}
          onChange={(value) => onChange(name, value)}
          isDisabled={disabled}
          isRequired={required}
          isClearable={false}
          hideSelectedOptions={false}
        />
      );
    case 'number':
      return (
        <InputText
          maxLength={maxLength}
          pattern={pattern}
          max={max}
          min={min}
          type='number'
          className='form-control'
          name={name}
          value={value}
          onChange={(e) => onChange(name, e.target.value)}
          readOnly={readOnly}
          disabled={disabled}
          required={required}
        />
      );
    case 'checkBox':
      return (
        <CheckBox
          name='isActive'
          onClickCallback={(e) => onChangeChechbox(e)}
          checkedStatus={value}
          disabled={disabled}
        />
      );
    case 'selectBox':
      return (
        <SelectDropdown
          name={name}
          optionsList={list(item.listName)}
          value={value}
          onChange={(value) => onChange(name, value)}
          isDisabled={disabled}
          required={required}
        />
      );
    case 'select':
      return (
        <SelectComponent
          key={value}
          name={name}
          optionsList={list}
          value={value}
          defaultValue={value}
          version='v2'
          placeholder={value}
          onChange={(value) => onChange(value)}
          isDisabled={disabled}
          isRequired={required}
          isClearable={false}
          hideSelectedOptions={false}
        />
      );
    case 'textArea':
      return (
        <textarea
          className='form-control'
          type='text'
          cols='50'
          rows='3'
          name={name}
          value={value || ''}
          required={required}
          onChange={(e) => onChange(name, e.target.value)}
          disabled={disabled}
        />
      );
    case 'title':
      return (
        <label className='justify-content-start ' htmlFor={name}>
          <strong>{value}</strong>
        </label>
      );
    case 'text-readonly':
      return (
        <label className='justify-content-start ' htmlFor={name}>
          <span>{value}</span>
        </label>
      );
    default:
      return null;
  }
};

export default InputSwitch;
