/* eslint-disable no-console */
import axios from 'axios';
import { UNAUTHORISED, FORBIDDEN } from '../client/components/shared/Constants';
import { showToastMessages } from '../shared/utils/helper';
import { getSessionToken } from '@descope/react-sdk';

const DEBUG = process.env.NODE_ENV === 'development';

let BASE_API_URL = 'https://sales-product-gateway.qac24svc.dev/c2c-admin/';
if (typeof window !== 'undefined') {
  switch (process.env.REACT_APP_ENV) {
    case 'local':
      BASE_API_URL = 'https://sales-product-gateway.qac24svc.dev/c2c-admin/';
      break;
    case 'qa':
      BASE_API_URL = 'https://sales-product-gateway.qac24svc.dev/c2c-admin/';
      break;
    case 'stage':
      BASE_API_URL = 'https://stage-sales-product-gateway.qac24svc.dev/c2c-admin/';
      break;
    case 'prod':
      BASE_API_URL = 'https://sales-product-gateway.c24.tech/c2c-admin/';
      break;
    default:
      break;
  }
}

/* Create axios instance */
const api = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  // withCredentials: true
});

api.interceptors.request.use(
  (config) => {
    /** In dev, intercepts request and logs it into console for dev */
    if (DEBUG) {
      //console.info('✉️ ', config);
    }
    config.headers["Authorization"] = `Bearer ${getSessionToken()}`;
    return config;
  },
  (error) => {
    if (DEBUG) {
      //console.error('✉️ ', error);
    }
    return Promise.reject(error);
  }
);

/**
 * Passes response.data to services.
 * In dev, intercepts response and logs it into console for dev
 */
api.interceptors.response.use(
  (response) => {
    if (DEBUG) {
      //console.info('📩 ', response);
    }

    return response;
  },
  (error) => {
    if (error.response) {
      if (UNAUTHORISED === error.response.status) {
        showToastMessages('Unable to validate user', false, false);
        let return_url = encodeURIComponent(
          window.location.pathname + window.location.search
        );
        return (window.location = `${
          window.origin
        }/unauthorised?return_url=${return_url}`);
      } else if (FORBIDDEN === error.response.status) {
        const message = error.response.data.message
          ? error.response.data.message
          : error.message;
        // sticky toast in case for forbidden 403 status
        showToastMessages(message, false, false);
      } else {
        const message = error.response.data.message
          ? error.response.data.message
          : error.message;
        showToastMessages(message, false);
      }
      return Promise.reject(error.response.data);
    }
    if (DEBUG) {
      //console.error('📩 ', error);
    }
    return Promise.reject(error.message);
  }
);
export { BASE_API_URL };
export default api;
