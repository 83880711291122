export const FIELDS = {
  minLeadCap: {
    label: 'Min Lead',
    keyName: 'minLeadCap',
    type: 'text',
    required: false,
    elementType: 'input',
    pattern: '[A-Za-z ]{1,32}',
    title: 'Invalid Name',
    isReadOnly: false
  },
  maxLeadCap: {
    label: 'Max Lead',
    keyName: 'maxLeadCap',
    type: 'text',
    required: false,
    elementType: 'input',
    pattern: '[A-Za-z ]{1,32}',
    title: 'Invalid Name',
    isReadOnly: false
  },

  daysForBuddyScore: {
    label: 'Buddy Scoring Days',
    keyName: 'daysForBuddyScore',
    type: 'text',
    required: false,
    elementType: 'input',
    pattern: '[A-Za-z ]{1,32}',
    title: 'Invalid Name',
    isReadOnly: false
  },
  isEPaymentEnabled: {
    label: 'E-Payments',
    keyName: 'isEPaymentEnabled',
    type: 'checkbox',
    required: false,
    elementType: 'input',
    title: 'Invalid Name',
    isReadOnly: false
  },
  isESignActive: {
    label: 'Delivery Form',
    keyName: 'isESignActive',
    type: 'checkbox',
    required: false,
    elementType: 'input',
    // title: 'Invalid Name',
    isReadOnly: false
  },
  isFavouriteCarsHidden: {
    label: 'Hide Fav Cars',
    keyName: 'isFavouriteCarsHidden',
    type: 'checkbox',
    required: false,
    elementType: 'input',
    // title: 'Invalid Name',
    isReadOnly: false
  },
  isGSEnabled: {
    label: 'GS inventory',
    keyName: 'isGSEnabled',
    type: 'checkbox',
    required: false,
    elementType: 'input',
    // title: 'Invalid Name',
    isReadOnly: false
  },
  isInventoryEnabled: {
    label: 'Open Inventory',
    keyName: 'isInventoryEnabled',
    type: 'checkbox',
    required: false,
    elementType: 'input',
    // title: 'Invalid Name',
    isReadOnly: false
  },
  isCallToSellerEnabled: {
    label: 'Call Seller Inventory',
    keyName: 'isCallToSellerEnabled',
    type: 'checkbox',
    required: false,
    elementType: 'input',
    // title: 'Invalid Name',
    isReadOnly: false
  },
  isDsAllocationEnabled: {
    label: 'DS Allocation',
    keyName: 'isDsAllocationEnabled',
    type: 'checkbox',
    required: false,
    elementType: 'input',
    // title: 'Invalid Name',
    isReadOnly: false
  },
  buddyAllocationActive: {
    label: 'Buyer Allocation',
    keyName: 'buddyAllocationActive',
    type: 'checkbox',
    required: false,
    elementType: 'input',
    // title: 'Invalid Name',
    isReadOnly: false
  },
  sellerBuddyAllocationActive: {
    label: 'Seller Allocation',
    keyName: 'sellerBuddyAllocationActive',
    type: 'checkbox',
    required: false,
    elementType: 'input',
    // title: 'Invalid Name',
    isReadOnly: false
  },
  explicitOfferThresholdScore: {
    label: 'Default buyer score',
    keyName: 'explicitOfferThresholdScore',
    type: 'number',
    required: false,
    elementType: 'input',
    // title: 'Invalid Name',
    isReadOnly: false
  },
  highPriorityThresholdScore: {
    label: 'Priority buyer score',
    keyName: 'highPriorityThresholdScore',
    type: 'number',
    required: false,
    elementType: 'input',
    title: 'Invalid Name',
    isReadOnly: false
  },
  highPriorityVisible: {
    label: 'Buyer Section',
    keyName: 'highPriorityVisible',
    type: 'checkbox',
    required: false,
    elementType: 'input',
    // title: 'Invalid Name',
    isReadOnly: false
  }
};

//
// Call Seller Inventory
// DS Allocation
// Buyer Allocation
// Seller Allocation
// Default buyer score
// Priority buyer score
// Buyer Section
