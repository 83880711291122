import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom';
import GridView from '../common/grid-view';
import { columns } from './constant';
import { DOCUMENTS_GRID_ID } from '../../constants/constants';

const DocumentsList = ({
  documentsList,
  fetchDocumentsListConnected,
  history
}) => {

  useEffect(() => {
    if(!documentsList.allIds.length) {
      fetchDocumentsListConnected();
    }
  }, []);

  const handleRedirect = rowData => {
    history.push(`/documents/add-rejection-reasons/${rowData.id}`);
  };

  return (
    <div>
      <h5>Documents</h5>
      <GridView
        uniqueGridId={DOCUMENTS_GRID_ID.DOCUMENTS}
        columnDefs={columns}
        rowData={Object.values(documentsList.byId)}
        onRedirect={handleRedirect}
      />
    </div>
  )
}

export default withRouter(DocumentsList);