import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  message: null
};

export const updateOrderInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};
export const updateOrderSuccess = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

export const updateOrderFail = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

export const HANDLERS = {
  [Types.UPDATE_ORDER_STATE_INIT]: updateOrderInit,
  [Types.UPDATE_ORDER_STATE_SUCCESS]: updateOrderSuccess,
  [Types.UPDATE_ORDER_STATE_FAIL]: updateOrderFail
};

export default createReducer(INITIAL_STATE, HANDLERS);
