import React, { useState, useEffect } from 'react';
import InputSwitch from '../../../shared/components/input-switch';
import './styles.css';
import { INPUT_BOX_DETAIL } from './constants';
import { withRouter } from 'react-router-dom';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';
import Button from '../../../shared/components/button';
import { STORE_TYPE_OPTIONS } from '../../../shared/utils/constants/inventory';

const StoreForm = ({
  fetchStates,
  fetchCities,
  fetchZones,
  match,
  fetchStoreInfoByIdConnected,
  detail,
  addStoreConnected,
  removeStoreDetailConnected,
  history
}) => {
  const [storeData, setstoreData] = useState();
  const [states, setStateList] = useState();
  const [cities, setCityList] = useState();
  const [zones, setZonesList] = useState();
  const [isYardNameInvalid, setIsYardNameInvalid] = useState(false);
  const storeId = match.params.id;
  const submit = (e) => {
    e.preventDefault();
    const data = {
      id: storeId,
      zoneId: storeData.zoneId
    };
    addStoreConnected(data);
  };
  useEffect(() => {
    if (storeId) {
      fetchStoreInfoByIdConnected(storeId);
    } else {
      removeStoreDetailConnected();
    }
  }, [storeId]);
  useEffect(() => {
    if (detail) {
      setstoreData(detail);
    }
  }, [detail]);

  async function fetchData() {
    const stateList = await fetchStates();
    setStateList(stateList);
    const zoneList = await fetchZones();
    setZonesList(zoneList);
  }
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    async function fetchData() {
      let stateId = storeData && storeData.stateId;
      if (stateId) {
        const list = await fetchCities({ stateId });
        setCityList(list);
      }
    }
    fetchData();
  }, [storeData && storeData.stateId]);
  const onChangeText = (name, value) => {
    let details = { ...storeData };
    if (name === 'stateId' || name === 'cityId' || name === 'zoneId' || name === 'locationType') {
      onChangeDropdown(name, value);
    } else {
        if (name === 'storeName' && value.toLowerCase().startsWith("cars24")===false){
          setIsYardNameInvalid(true);
        }else
        {
          setIsYardNameInvalid(false);
        }
        details[name] = value;
        setstoreData(details);
    }
  };
  const showInvalidStoreNameError = () => {
    return (
      isYardNameInvalid ? <p className='error'>{'Yard name should start with Cars24'}</p> : ''
    );
  };
  const onChangeDropdown = (name, value) => {
    const valueMap = {
      stateId: {
        key: 'stateId',
        values: states,
        nameKey: 'stateName'
      },
      cityId: {
        key: 'cityId',
        values: cities,
        nameKey: 'cityame'
      },
      zoneId: {
        key: 'zoneId',
        values: zones,
        nameKey: 'zoneId'
      },
      locationType: {
        key: 'locationType',
        values: STORE_TYPE_OPTIONS,
        nameKey: 'locationType'
      }
    };
    let details = { ...storeData };

    setstoreData({
      ...details,
      [valueMap[name].nameKey]: value.label,
      [valueMap[name].key]: value.value
    });
  };

  const getList = (type) => {
    switch (type) {
      case 'stateId':
        return stateList();
      case 'cityId':
        return cityList();
      case 'zoneId':
        return zoneList();
      case 'locationType':
        return STORE_TYPE_OPTIONS;  
      default:
        return [];
    }
  };
  const stateList = () => {
    const list =
      states &&
      states.map((option, index) => {
        return { value: option.stateId, label: option.stateName };
      });
    return list || [];
  };
  const cityList = () => {
    const list =
      cities &&
      cities.map((option, index) => {
        return { value: option.cityId, label: option.cityName };
      });
    return list || [];
  };
  const zoneList = () => {
    const list =
      zones &&
      zones.data &&
      zones.data.data.map((option, index) => {
        return { value: option.id, label: option.zone };
      });
    return list || [];
  };

  const onChangeChechbox = (e, name) => {
    const details = { ...storeData };
    details[name] = e.target.checked;
    setstoreData({ ...details });
  };

  const onShowFormData = (item) => {
    let value = storeData && storeData[item['value']];

    let list = getList(item.value);
    
    if (
      item.value === 'zoneId'
    ) {
      return filterData(list, storeData && storeData[item.value], item.value);;
    } else if (
      item.value === 'cityId' ||
      item.value === 'stateId' ||
      item.value === 'locationType'
    ) {
      const listItem = filterData(list, storeData && storeData[item.value], item.value)[0];
      const {label} =listItem|| {};
      return label;
    } 
    else {
      return value;
    }
  };
  const filterData = (list, id, type) => {
    if (type === 'stateId' || type === 'cityId') {
      const result = list.filter((item) => item.value === parseInt(id));
      return result;
    } else if (type === 'zoneId' || type === 'locationType') {
      const result = list.filter((item) => item.value === id);
      return result;
    }
  };
  return (
    <>
      <LoaderWithAccessControl>
        <form onSubmit={(e) => submit(e)}>
          <div className='boxs'>
            <div className='row'>
              <div className='box-body col-md-12 formWrapper'>
                {INPUT_BOX_DETAIL.map((item, index) => {
                  return (
                    <div
                      className='float-left form-group col-md-6 dealer-wrapper'
                      key={index}
                    >
                      <label className='font-weight-bold'>
                        <span>{item.label}</span>
                        {/* <span>{item.required ? ' *' : ''}</span> */}
                      </label>
                      <InputSwitch
                        maxLength={item.maxLength}
                        min={item.min}
                        max={item.max}
                        type={item.type}
                        param={item.type}
                        item={item}
                        name={item['value']}
                        disabled={item.disabled}
                        value={onShowFormData(item)}
                        onChange={onChangeText}
                        list={getList}
                        required={item.required}
                        onChangeChechbox={(e) =>
                          onChangeChechbox(e, item['value'])
                        }
                      />
                      {item['value'] === 'storeName' && showInvalidStoreNameError()}
                    </div>
                  );
                })}
              </div>
            </div>
            {/* <Button label='Save' type={submit} /> */}
          </div>
        </form>
      </LoaderWithAccessControl>
    </>
  );
};

export default withRouter(StoreForm);
