import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    FETCH_ORDER_SELLER_INIT
    FETCH_ORDER_SELLER_SUCCESS
    FETCH_ORDER_SELLER_FAILURE

    UPDATE_CAR_SOURCE_INIT
    UPDATE_CAR_SOURCE_SUCCESS
    UPDATE_CAR_SOURCE_FAIL

    UPDATE_SELLER_INIT
    UPDATE_SELLER_SUCCESS
    UPDATE_SELLER_FAIL
`,
  {
    prefix: 'order-seller-tab/'
  }
);
