import React, { useState, useEffect } from 'react';
import columnDefs from './columnConfig';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { DataService } from '../../../client/service';
import GridView from '../common/grid-view';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';

const ZoneGrid = function(props) {
  const [rowData, setRowData] = useState();

  useEffect(() => {
    props.fetchZonesListConnected();
  }, []);

  useEffect(() => {
    props.zoneInfo.data &&
      Array.isArray(props.zoneInfo.data) &&
      setRowData(props.zoneInfo.data);
  }, [props.zoneInfo]);

  const onRouteId = (data) => {
    let route = '/zones/';
    props.history.push(`${route}${data.id}`);
  };

  return (
    <LoaderWithAccessControl>
      {rowData && rowData.length > 0 && (
        <div>
          <div className='row'>&nbsp;</div>
          <div className='row'>
            <div className='col-md-12 text-right'>
              <Link to='/zones/add'>
                <button className='btn btn-success' type='button'>
                  Add
                </button>
              </Link>
            </div>
            <br />
          </div>
          <div className='row'>&nbsp;</div>
          <div className='ag-theme-balham ag-grid-outer-wrapper'>
            <GridView
              columnDefs={columnDefs}
              rowData={rowData && rowData}
              onRedirect={onRouteId}
              uniqueIdName={'id'}
              uniqueGridId={'zones'} // uniqueGridId is used as reducer key for GRID component
            />
          </div>
        </div>
      )}
    </LoaderWithAccessControl>
  );
};

export default withRouter(ZoneGrid);
