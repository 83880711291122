const getSlabNumber = serviceMarkUps => {
    let count =0;
    for(let key in serviceMarkUps)
    {
      if(key.substring(0,4).includes("slab"))
      {
        count++
      }
    }

    return count;
}
export default getSlabNumber