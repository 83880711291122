import { config } from '../config/local';

export default (api) => {
  const fetchUlipConfig = () => {
    const url = config.api.c2c.ulip.getConfig.path;
    return api
      .get(url)
      .then((resp) => {
        return resp.data.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  const fetchUlipData = () => {
    const url = config.api.c2c.ulip.getUlipData.path;
    return api
      .get(url)
      .then((resp) => {
        return resp.data.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  const updateUlipData = (body) => {
    return new Promise((resolve, reject) => {
      const url = config.api.c2c.ulip.updateUlipData.path;
      api.post(url, body)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          })
    });
  };

  return {
    fetchUlipConfig,
    fetchUlipData,
    updateUlipData,
  };
};