export const TAB_LIST = [
  {
    label: 'User',
    visible: true, // default value, this can change as module logic
    disabled: false,
    key: 'user',
    id: 0
  },
  {
    label: 'RC Access',
    visible: true,
    disabled: false,
    key: 'rcAccess',
    id: 1
  }
];

export const ENTITY_TYPES = {
  USER: 'User',
  RCACCESS: 'RC Access'
};
