import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  SET_USER_SUCCESS
  RESET_USER_DETAILS
`,
  {
    prefix: 'users-form/'
  }
);
