import React, { useEffect, useMemo, useState } from 'react'
import { withRouter } from 'react-router-dom';
import { columns } from '../../config/region-allocation-columns';
import { useRegions } from '../../custom-hooks/regions-hook';
import { showToastMessages } from '../../../shared/utils/helper';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { filterBy } from "@progress/kendo-data-query";

const initialFilter = {
  logic: "and",
  filters: [],
};

const RegionAllocation = ({
  fetchAgentsConnect,
  updateAgentRegionsConnect,
}) => {
  const { isRegionsFetched, regions } = useRegions();
  const [agents, setAgents] = useState([]);
  const [filter, setFilter] = React.useState(initialFilter);
  const regionDropdownOptions = regions.map(({ regionName, id }) => ({ value: id, label: regionName }));

  const handleMultiSelectChange = (attributeKey, data, agentData) => {
    let value = Array.isArray(data) ? data.map(val => val.value) : [data.value];
    const agentIndex = agents.findIndex(({ emailId }) => emailId === agentData.id);
    if (agentIndex > -1) {
      const updatedAgents = [...agents];
      updatedAgents[agentIndex][attributeKey] = value;
      setAgents(updatedAgents);
    }
  };

  const handleCtaClick = (data) => {
    updateAgentRegionsConnect(data)
      .then((resp) => {
        showToastMessages(resp.message);
      })
  };

  useEffect(() => {
    fetchAgentsConnect()
      .then((resp) => {
        setAgents(resp.map((row) => ({ ...row, id: row.emailId })));
      })
  }, []);

  let columnDefs = useMemo(() => columns({ regionDropdownOptions, handleMultiSelectChange, handleCtaClick }), [isRegionsFetched, handleMultiSelectChange, handleCtaClick]);

  return (
    <>
      <h5>Region Allocation</h5>
      <div className="mt-3 mb-3">
        <Grid
          pageable={false}
          data={filterBy(agents, filter)}
          filterable={true}
          filter={filter}
          onFilterChange={(e) => setFilter(e.filter)}
        >
          {columnDefs.map((column) => {
            return (
              <GridColumn {...column} />
            )
          })}
        </Grid>
      </div>
    </>
  )
}

export default withRouter(RegionAllocation);