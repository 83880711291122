import { toast } from 'react-toastify';
import { showToastMessages } from '../../shared/utils/helper';
export default (api) => {
  const getsystemReallocation = (id) => {
    return api
      .get(`secure/zone-config/${id}`)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        showToastMessages(error.message, false);
        return error;
      });
  };
  const submitsystemReallocation = (id, data) => {
    return api
      .put(`secure/zone-config/${id}/`, data)
      .then((resp) => {
        showToastMessages(resp.data.message);
        return resp.data;
      })
      .catch((error) => {
        showToastMessages(error.message, false);
        return error;
      });
  };
  return {
    getsystemReallocation,
    submitsystemReallocation
  };
};
