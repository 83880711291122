import React, { useState, useEffect } from 'react';
import './styles.css';
import ArrowIcon from '../../../shared/arrow';
import Accordion from '../../../shared/accordion';
import { systemReallocationApi } from '../../../client/service';
import { withRouter } from 'react-router-dom';

const ZonesSystemReallocation = ({
  systemReallocation,
  onChangeReallocation,
  match
}) => {
  const [systemReallocationData, setsystemReallocationData] = useState([{}]);
  const id = match.params.id;

  useEffect(() => {
    systemReallocationApi
      .getsystemReallocation(id)
      .then(res => {
        setsystemReallocationData(res.data);
      })
      .catch(err => {});
  }, []);
  const onChange = (e, index, key, subKey = null) => {
    if (subKey) {
      const buddyScoringClone = [...systemReallocationData];
      buddyScoringClone[index][e.target.name][subKey] = e.target.value;
      setsystemReallocationData(buddyScoringClone);
    } else {
      const buddyScoringClone = [...systemReallocationData];
      buddyScoringClone[index][e.target.name] = e.target.value;
      setsystemReallocationData(buddyScoringClone);
    }
  };
  const handleSave = () => {
    systemReallocationApi.submitsystemReallocation(id, systemReallocationData);
  };

  return (
    <Accordion totalItems={1}>
      {({ onClick, currentVisibleStates }) => {
        return (
          <Accordion.Content>
            <div className={'borderWrapper'}>
              <Accordion.Heading>
                <h2
                  className='font-weight-bold p0'
                  onClick={() => onClick({ index: 0 })}
                >
                  System Reallocation
                  <ArrowIcon
                    grey={'GreyArrow'}
                    rotateBy={currentVisibleStates[0] ? 180 : 90}
                  />
                </h2>
              </Accordion.Heading>
              <Accordion.Body>
                {systemReallocationData ? (
                  <div
                    className={
                      currentVisibleStates[0]
                        ? 'accordionShow table mt20'
                        : 'accordionHide'
                    }
                  >
                    <table className='table'>
                      <thead>
                        <tr>
                          <th>Buyer Disposition</th>
                          <th>Days Before Reallocation</th>
                          <th># of reallocation</th>
                        </tr>
                      </thead>
                      {systemReallocationData &&
                        systemReallocationData.map((data, index) => (
                          <tbody>
                            <tr key={index}>
                              <td>{data.dispositionStatus}</td>
                              <td>
                                <input type='hidden' name='id' />
                                <input
                                  required
                                  min='0'
                                  className='form-control'
                                  value={data.reallocationDays || ''}
                                  type='number'
                                  name='reallocationDays'
                                  //defaultValue={data.reallocationDays}
                                  onChange={e => onChange(e, index)}
                                />
                              </td>
                              <td>
                                <input type='hidden' name='id' />
                                <input
                                  min='0'
                                  className='form-control'
                                  value={data.reallocationCount || ''}
                                  type='number'
                                  name='reallocationCount'
                                  //defaultValue={data.reallocationCount}
                                  onChange={e => onChange(e, index)}
                                  required
                                />
                              </td>
                            </tr>
                          </tbody>
                        ))}
                    </table>
                    <div className='form-group col-md-12 text-right'>
                      <button onClick={handleSave} className='btn btn-primary'>
                        Save
                      </button>
                    </div>
                  </div>
                ) : (
                  <p>No Details </p>
                )}
              </Accordion.Body>
            </div>
          </Accordion.Content>
        );
      }}
    </Accordion>
  );
};

export default withRouter(ZonesSystemReallocation);
