import {
  dealStatus,
  validationStatus,
  paymentStatus
} from '../../constants/constants';
import { carSourceOptions } from '../../../shared/utils/constants/inventory';
import { getformattedDate } from '../../../shared/utils/datesHelper';
export default [
  {
    headerName: 'UID',
    width: 120,
    resizable: false,
    field: 'deal_identifier',
    renderField: 'deal_id',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    cellRenderer: 'LinkRenderer',
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Appt. ID',
    width: 110,
    field: 'appointment_id',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    suppressSizeToFit: false,
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Booking ID',
    width: 130,
    field: 'booking_id',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    suppressSizeToFit: false,
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Registration Number',
    width: 140,
    valueFormatter: 'value.toUpperCase()',
    field: 'registration_number',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },

    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Buddy Name',

    field: 'buddy_name',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Deal Status',
    width: 120,
    field: 'deal_status',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'deal_status',
      options: Object.values(dealStatus)
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: Object.values(dealStatus)
    },
    suppressMenu: true
  },
  {
    headerName: 'Car Source',
    //width: 200,
    field: 'car_source',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],

      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'car_source',
      options: Object.values(carSourceOptions)
    },
    // cellRenderer: 'LabelRenderer',
    // cellRendererParams: {
    //   options: Object.values(carSourceOptions)
    // },
    valueFormatter: function(params) {
      return params.data.display_car_source;
    },
    suppressMenu: true
  },
  {
    headerName: 'Token Date',
    //width: 200,
    field: 'token_date',
    filter: 'agDateColumnFilter',
    floatingFilter: false,
    valueFormatter: function(params) {
      return getformattedDate(params.value);
    },
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      color: 'red'
    },
    filterParams: {
      // comparator: function(filterLocalDateAtMidnight, cellValue) {
      //   if (cellValue === null) return -1;
      //   let cellDate = new Date(cellValue);
      //   if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      //     return 0;
      //   }
      //   if (cellDate < filterLocalDateAtMidnight) {
      //     return -1;
      //   }
      //   if (cellDate > filterLocalDateAtMidnight) {
      //     return 1;
      //   }
      // },
      filterOptions: ['equals'],
      browserDatePicker: true,
      applyButton: true,
      clearButton: true,
      suppressAndOrCondition: true,
      newRowsAction: 'keep'
    },
    suppressMenu: true
  },
  {
    headerName: 'Buyer Validation',
    width: 160,
    field: 'buyer_validation',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'buyer_validation',
      options: Object.values(validationStatus)
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: Object.values(validationStatus)
    },
    suppressMenu: true
  },
  {
    headerName: 'Seller Validation',
    width: 160,
    field: 'seller_validation',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'seller_validation',
      options: Object.values(validationStatus)
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: Object.values(validationStatus)
    },
    suppressMenu: true
  },
  {
    headerName: 'Payment Status',
    //width: 160,
    field: 'payment_status',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'payment_status',
      options: Object.values(paymentStatus)
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: Object.values(paymentStatus)
    },
    suppressMenu: true
  }
];
