import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DocumentsList from "./component";
import { fetchDocumentsList } from "./actions";

const mapStateToProps = ({
  documentsList,
}) => ({
  documentsList
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchDocumentsListConnected: fetchDocumentsList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsList);