export const FIELDS = [
  // noOfOwners: '',
  // insuranceType: '',
  // hypothecation: '',
  // transmissionType: '',
  // bodyType: '',
  // color: '',

  {
    label: 'No. of Owner',
    fieldName: 'noOfOwners',
    type: 'select',
    autoComplete: 'off',
    valueKey: 'noOfOwners',
    optionsKey: 'owner',
    readOnly: false
  },
  {
    label: 'Insurance Type',
    fieldName: 'insuranceType',
    type: 'select',
    autoComplete: 'off',
    valueKey: 'insuranceType',
    readOnly: false
  },
  {
    label: 'Under Hypothecation',
    fieldName: 'hypothecation',
    type: 'select',
    autoComplete: 'off',
    valueKey: 'hypothecation',
    readOnly: false
  },
  {
    label: 'Transmission type',
    fieldName: 'transmissionType',
    type: 'select',
    autoComplete: 'off',
    valueKey: 'transmissionType',
    readOnly: false
  },
  {
    label: 'Body Type',
    fieldName: 'bodyType',
    type: 'select',
    autoComplete: 'off',
    valueKey: 'bodyType',
    readOnly: false
  },
  {
    label: 'Color',
    fieldName: 'color',
    type: 'text',
    autoComplete: 'off',
    valueKey: 'color',
    readOnly: false
  },
  {
    label: 'Warranty Type',
    fieldName: 'warrantyType',
    type: 'select',
    autoComplete: 'off',
    valueKey: 'warrantyType',
    readOnly: false
  },
  {
    label: 'CNG / LPG Fitment',
    fieldName: 'cngLpgFitment',
    type: 'select',
    autoComplete: 'off',
    valueKey: 'cngLpgFitment',
    readOnly: false
  },

  {
    label: 'Is Top Selling',
    fieldName: 'isTopSelling',
    type: 'checkbox',
    autoComplete: 'off',
    valueKey: 'isTopSelling',
    readOnly: false
  },
  {
    label: 'Buy Back Guarantee',
    fieldName: 'isC24Assured',
    type: 'checkbox',
    autoComplete: 'off',
    valueKey: 'isC24Assured',
    readOnly: false
  },
  {
    label: 'Consumer Finance? ',
    fieldName: 'isCfAvailable',
    type: 'checkbox',
    valueKey: 'isCfAvailable',
    readOnly: false
  }
];
