import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  isArrayEmpty,
  getMultiSelectedOption,
  showToastMessages
} from '../../../shared/utils/helper';
import { SelectComponent } from '../../../client/components/shared';
import {
  USER_STATUS,
  FIELD_SETS,
  FIELDS,
  ADD_USER,
  EDIT_USER
} from './constants';
import InputText from '../../../shared/components/input-text';
import { USER_ROLES_OPTIONS, ROLES, USER_LMS_ROLES_OPTIONS} from '../../../shared/utils/constants';
import { Spinner } from 'reactstrap';
import { validationSchema } from './validationSchema';
import { withRouter } from 'react-router-dom';
import Button from '../../../shared/components/button';

const Wrapper = ({ item, children }) => {
  const { label, keyName, required = false } = item;
  return (
    <div className='col-6 d-flex'>
      <label className='col-4 justify-content-start' for={keyName}>
        <span>{label}</span>
        <span>{required ? ' *' : ''}</span>
      </label>
      <div className='col-8'>{children}</div>
    </div>
  );
};
const INITIAL_VALUES = {
  adminPanelRole: null,
  status: null,
  emailId: '',
  name: '',
  phone: '',
  role: ''
};

const UserForm = (props) => {
  const [options, setOptions] = useState({});
  const [userId] = useState(props.match.params.id);
  const [userValues, setUserValues] = useState(INITIAL_VALUES);
  const userDetailConnected = props && props.userInfo && props.userInfo.userDetails;
  useEffect(()=>{
    const isUserDetailConnected = Object.keys(userDetailConnected).length !== 0;
    if (isUserDetailConnected) {
      const {userId} = userDetailConnected;
      userId && props.fetchUserConnected(userId).then(resp=> {
        const updateUserValues = {...INITIAL_VALUES,
          adminPanelRole: USER_ROLES_OPTIONS[resp.data.c2cAdminPanelRole] || USER_ROLES_OPTIONS[userDetailConnected.status] || '',
          status: USER_STATUS[resp.data.status] || USER_STATUS[userDetailConnected.status] || '',
          emailId: resp.data.emailId || userDetailConnected.emailId || '',
          name: resp.data.name || userDetailConnected.name || '',
          phone: resp.data.phone || userDetailConnected.phone || '',
          role: USER_LMS_ROLES_OPTIONS[resp.data.role] || USER_LMS_ROLES_OPTIONS[userDetailConnected.role] || ''
        };
        setUserValues(updateUserValues);
      }).catch(err => {
        console.log(err);
      });
    }
  },[userDetailConnected])
  const onSubmit = (values) => {
    if (!!values.name && values.name.length > 89) {
      showToastMessages("Please provide valid name",false, 5000);
      return;
    }
    if (!!values.emailId && values.emailId.length > 74) {
      showToastMessages("Please provide valid email",false, 5000);
      return;
    }
    const payload = {
      c2cAdminPanelRole: values.adminPanelRole ? values.adminPanelRole.value : null,
      status: values.status.value,
      emailId: values.emailId,
      name: values.name.trim(),
      phone: values.phone,
      role: values.role.value
    };
    if (userId) {
      props.editUserConnected(userId, payload);
    } else {
      props.addUserConnected(payload);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: userValues,
    onSubmit,
    validationSchema
  });
  const onChangeSelect = (selected, param) => {
    if (selected) {
      formik.setFieldValue(param, selected);
    } else {
      formik.setFieldValue(param, null);
    }
  };

  useEffect(() => {
    setOptions({
      ...options,
      [FIELDS.role.keyName]: Object.values(USER_LMS_ROLES_OPTIONS),
      [FIELDS.status.keyName]: Object.values(USER_STATUS),
      [FIELDS.adminPanelRole.keyName]: Object.values(USER_ROLES_OPTIONS)
    });
  }, []);

  useEffect(() => {
    if (userId) {
      let users = props && props.UserGridInfo && props.UserGridInfo.userData;
      let userHashed =
        props && props.UserGridInfo && props.UserGridInfo.userHashed;
      if (users.length > 0) {
        userHashed[userId] &&
          props.setUserInfoByIndexConnected(userHashed[userId]);
      } else {
        props.history.push('/users');
      }
    } else {
      props.resetUserInfoByIndexConnected();
    }
  }, [userId]);

  const onBlurCallback = (e) => {
    e.preventDefault();
    const { name } = e.target;
    formik.setFieldTouched(name, true, true);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='col-12'>
        <h3>{userId ? EDIT_USER : ADD_USER}</h3>

        {FIELD_SETS.map((set, setIndex) => {
          return (
            <div key={`outer_${setIndex}`} className='form-inline mb20'>
              {set.map((fieldItem, fieldItemIndex) => {
                const {
                  type,
                  label,
                  keyName,
                  required,
                  elementType,
                  isMulti,
                  maxLength,
                  ...restProps
                } = fieldItem;
                switch (elementType) {
                  case 'input':
                    return (
                      <Wrapper item={fieldItem}>
                        <InputText
                          id={keyName}
                          name={keyName}
                          type={type}
                          readOnly={userId ? fieldItem.isReadOnly : false}
                          onChange={formik.handleChange}
                          value={formik.values[keyName] || ''}
                          maxLength={maxLength}
                          onBlurCallback={onBlurCallback}
                          isError={
                            formik.touched[keyName] && formik.errors[keyName]
                              ? true
                              : false
                          }
                          errorMessage={
                            formik.touched[keyName] && formik.errors[keyName]
                              ? formik.errors[keyName]
                              : null
                          }
                          version='v2'
                          style={{ width: '100%' }}
                        />
                      </Wrapper>
                    );

                  case 'select':
                    return (
                      <Wrapper item={fieldItem}>
                        {options[keyName] ? (
                          <SelectComponent
                            key={formik.values[keyName]}
                            id={keyName}
                            name={keyName}
                            optionsList={options[keyName]}
                            placeholder={formik.values[keyName]}
                            defaultValue={formik.values[keyName] || null}
                            value={formik.values[keyName] || null}
                            onChange={onChangeSelect}
                            // isDisabled={userId ? fieldItem.isReadOnly : false}
                            className='form-control inputSpace col-6'
                            isMulti={isMulti || false}
                            errorMessage={
                              formik.touched[keyName] && formik.errors[keyName]
                                ? formik.errors[keyName]
                                : null
                            }
                            version='v2'
                          />
                        ) : (
                          <Spinner
                            variant='primary'
                            animation='border'
                            size='sm'
                          />
                        )}
                      </Wrapper>
                    );

                  default:
                    return null;
                }
              })}
            </div>
          );
        })}

        <Button label='Save' type='submit' />
      </div>
    </form>
  );
};

export default withRouter(UserForm);
