import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  UPDATE_ADD_REJECTION_REASONS_CONFIG_DATA
  FETCH_DOCUMENT_REJECTION_REASONS_CONFIG_SUCCESS
  RESET_REJECTION_REASONS_CONFIG_LIST
  UPDATE_DOCUMENT_REJECTION_REASONS
`,
  {
    prefix: 'documentRuleEngine/'
  }
);
