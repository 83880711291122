import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DocumentsRegionList from "./component";
import { fetchRegionList } from "./actions";

const mapStateToProps = ({
  documentsRegionList: regionList
}) => ({
  regionList
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchRegionListConnected: fetchRegionList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsRegionList);
