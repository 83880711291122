import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  RESET_ALL_GRID_MODEL
  RESET_GRID_MODEL
  SET_GRID_MODEL
  UPDATE_GRID_FILTER

  SET_GRID_FILTER
  RESET_GRID_FILTER
  
`,
  {
    prefix: 'grid/'
  }
);
