import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './styles.css';
import InputText from '../../../shared/components/input-text';
import Popup from '../../../shared/popup/component';
import loaderWithAccessControl from '../../../shared/components/loader-with-access-control';
import { financeService } from '../../../services';
// import { getRefunds } from '../../utils/mock-data';
import FinanceServicesRefundPopup from '../../Popups/finance-services-refund-popup';
import { roundTo2Decimal } from '../../../client/components/shared/utility';
import FinanceDealInfoBuyBackPopup from '../../Popups/finance-dealinfo-buyback-popup'
import { REFUNDS_CATEGORIES, REFUND_ACTIONS, LABELS, BUYBACK_HEADER, POST_DELIVERY_CHARGES_HEADER } from './constants';
import CustomButton from '../../../shared/components/button';
import { BUTTON_ICON } from '../../../shared/utils/constants/appConstants';
import { openUrlTab } from '../../../shared/utils/helper';

const FinanceRefunds = (props) => {
  const [dealId] = useState(props.match.params.id);
  const [uiRules, setUiRules] = useState({});
  const [processing, setProcessing] = useState(false);
  const [refundData, setRefundData] = useState();
  const [logsData, setLogsData] = useState();
  const [selectedRefund, setSelectedRefund] = useState();
  const [isBuyBackInitiatePopupOpen, setIsBuyBackInitiatePopupOpen] = useState(false);
  const [isRequestPayoutPopupOpen, setRequestPayoutPopupOpen] = useState(false);
  const [postDeliveryCharges, setPostDeliveryCharges] = useState([]);
  const [buybackCharges, setBuybackCharges] = useState([]);
  const [returnFeeInvoiceUrl, setReturnFeeInvoiceUrl] = useState('sdfds');

  const refreshLogs = async (dealId) => {
    const logs = await financeService.fetchRefundLogs(dealId);
    setLogsData(logs);
  };

  const refreshRefundsCharges = async (dealId) => {
    let refundModels = await financeService.fetchDealRefundsCharges(dealId);
    setRefundData(refundModels.data);
  };

  const refreshAll = async (dealId) => {
    await refreshRefundsCharges(dealId);
    await refreshLogs(dealId);
    await fetchUiRules(dealId);
  };

  useEffect(()=>{
    if (refundData) {
      const pdc = refundData.filter((item)=> item.category === REFUNDS_CATEGORIES.POST_DELIVERY);
      const bc = refundData.filter((item)=> item.category === REFUNDS_CATEGORIES.BUYBACK);
      setPostDeliveryCharges(pdc);
      setBuybackCharges(bc);
    }
  },[refundData]);

  useEffect(() => {
    (async () => {
      setProcessing(true);
      await refreshAll(dealId);
      setProcessing(false);
    })();
  }, [dealId]);

  const fetchUiRules = async (suppliedDealId) => {
    setUiRules(await financeService.getUiRulesForDeal(suppliedDealId));
  }

  const doInitiatePostDeliveryRefunds = async () => {
    setProcessing(true);
    try {
      await financeService.initiatePostDeliveryRefunds(dealId);
      refreshAll(dealId);
      setProcessing(false);
    } catch(err) {
      setProcessing(false);
      throw err;
    }
  };

  const doInitiateBuyback = async (payload) => {
    setProcessing(true);
    try {
      await financeService.initiateBuyback(dealId, payload);
      refreshAll(dealId);
      setProcessing(false);
    } catch(err) {
      setProcessing(false);
      throw err;
    }
  };

  const doInitiatePayout = async (refundId, amount) => {
    setProcessing(true);
    try {
      await financeService.initiateRefundPayout(dealId, refundId, amount);
      refreshAll(dealId);
      setProcessing(false);
    } catch(err) {
      setProcessing(false);
      throw err;
    }
  };

  const takeAction = async (payoutId, action) => {
    setProcessing(true);
    try {
      await financeService.takeActionOnRefundPayout(dealId, payoutId, action);
      refreshAll(dealId);
      setProcessing(false);
    } catch(err) {
      setProcessing(false);
      throw err;
    }
  }

  const setModelAndOpenInitiateRefundPopup = (model) => {
    setSelectedRefund(model);
    setRequestPayoutPopupOpen(true);
  }

  const getLabel = (value, type) => {
    return LABELS[type][value] || value.replace("_", " ").toLowerCase();
  };

  const getRowHeader = (key) => {
    let header = key === "pdc" ? POST_DELIVERY_CHARGES_HEADER : BUYBACK_HEADER;
    let headerDom = header.map((item, i) => <div key={item} className={i === header.length - 1 ? "col" : "col-2"}>{item}</div>);
    return <div className='row' key={key}>{headerDom}</div>;
  };

  const getRowItem = (model) => {
    const initiatePayout = model.allowedActions.find((item)=> item.action === REFUND_ACTIONS.INITIATE_PAYOUT);
    return (
      <div className="row" key={`${model.id}`}>
        <div className='col-2'>{`${model.displayKey}`}</div>
        <div className='col-2 mb-3'>
          <InputText value={roundTo2Decimal(model.amountDue)} disabled={true} />
        </div>
        {model.category === 'POST_DELIVERY' && (
          <div className='col-2 mb-3'>
            <InputText value={roundTo2Decimal(model.refundableAmount || 0)} disabled={true} />
          </div>
        )}
        <div className='col-2 mb-3'>
          <InputText value={roundTo2Decimal(model.amountRefunded)} disabled={true} />
        </div>
        {<div className='col-4'>
          {!!model.activePayout && <div className="row">
            <div className="col-4">
              <InputText value={roundTo2Decimal(model.activePayout.amount)} disabled={true} />
            </div>
            <div className="col-6">
              {model.activePayout && model.activePayout.allowedActions && model.activePayout.allowedActions.map((payoutAction, idx) => {
                return (
                  <button disabled={processing} type="button" class="btn btn-primary mr-3" onClick={() => takeAction(model.activePayout.payoutId, payoutAction.action)}>{payoutAction.label}</button>
                );
              })}
            </div>
          </div>}
          {!!initiatePayout && (<button disabled={processing} type="button" class="btn btn-outline-dark mr-3" onClick={() => setModelAndOpenInitiateRefundPopup(model)}>{initiatePayout.label}</button>)}
        </div>}
      </div>
    );
  };

  const onInvoiceDownload = () => {
    openUrlTab(returnFeeInvoiceUrl);
  };

  useEffect(() => {
    financeService.fetchReturnFeeInvoiceDetails(dealId)
      .then((resp) => {
        setReturnFeeInvoiceUrl(resp.returnFeeInvoiceUrl)
      })
  }, []);

  return (
    <loaderWithAccessControl>
      <div className={styles.financeRefunds}>
        <u><h2 className='d-flex fwb justify-content-between m0'>{`Post Delivery Charges`}</h2></u>
        <div className="mt-3"></div>
        {
          uiRules.initiatePostDeliveryRefunds ? (
            <>
              <div className="mt-4 mb-3">
                <button disabled={processing} onClick={doInitiatePostDeliveryRefunds} className='btn btn-primary'>
                  Initiate Refunds
                </button>
              </div>
            </>
          ) : (
            postDeliveryCharges && postDeliveryCharges.length > 0 ? (
              <>
                {getRowHeader('pdc')}
                <div className="mt-3"></div>
                {postDeliveryCharges.map((model) => {
                  return getRowItem(model);
                })
                }
              </>
            ) : (
              <div className="mb-3">
                <i>No records</i>
              </div>
            )
          )
        }
      </div>
      <div className={styles.financeRefunds}>
        <u><h2 className='d-flex fwb justify-content-between m0 mb-3'>{`Post Delivery Cancellation (Buyback)`}</h2></u>
        <div className="mt-3"></div>
        {
          
        }
        {
          uiRules.initiateBuybackRefunds ? (
            <>
              <div className="mt-4 mb-3">
                <button disabled={processing} onClick={() => setIsBuyBackInitiatePopupOpen(true)} className='btn btn-primary'>
                  Initiate Buyback
                </button>
              </div>
            </>
          ) : (
            buybackCharges && buybackCharges.length > 0 ? (
              <>
                {getRowHeader('buyback')}
                <div className="mt-3"></div>
                {buybackCharges.map((model) => {
                  return getRowItem(model);
                })
                }
              </>
            ) : (
              <div className="mb-3">
                <i>No records</i>
              </div>
            )
          )
        }
      </div>
      {!!returnFeeInvoiceUrl ? (
        <CustomButton
          label={'Return Fee Invoice'}
          onClick={onInvoiceDownload}
          btnIconName={BUTTON_ICON.download}
        />
      ) : null}
      <div className={styles.financeRefunds}>
        <u><h2 className='d-flex fwb justify-content-between m0 mt-2'>{`User Logs`}</h2></u>
        <div className="mt-3"></div>
        <table class="table publish-log">
          <thead>
            <tr>
              <th>Category</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Refunded</th>
              <th>Status</th>
              <th>Updated At</th>
              <th>Updated By</th>
              <th>Refund Against UTR</th>
              <th>UTR Amount</th>
              <th>Error Msg</th>
            </tr>
          </thead>
          <tbody>
            {logsData && logsData.map((log, index) => {
              return (
                <tr>
                  <td>{getLabel(log.category, "category")}</td>
                  <td>{getLabel(log.type, "type")}</td>
                  <td>{roundTo2Decimal(log.amount)}</td>
                  <td>{roundTo2Decimal(log.refunded)}</td>
                  <td>{getLabel(log.label, "label")}</td>
                  <td>{log.updatedAt}</td>
                  <td>{log.updatedBy}</td>
                  <td>{log.utr}</td>
                  <td>{roundTo2Decimal(log.utrAmount)}</td>
                  <td>{log.error}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {
          logsData && logsData.length === 0 && (
            <div className="mb-3">
              <i>No records</i>
            </div>
          )
        }
      </div>
      <Popup isOpen={isRequestPayoutPopupOpen} close={() => setRequestPayoutPopupOpen(false)} closeOnOutside={false} >
        <FinanceServicesRefundPopup
          refund={selectedRefund}
          onSubmit={doInitiatePayout}
          onClose={() => setRequestPayoutPopupOpen(false)} />
      </Popup>
      <Popup isOpen={isBuyBackInitiatePopupOpen} close={() => setIsBuyBackInitiatePopupOpen(false)}>
        <FinanceDealInfoBuyBackPopup
          isCngDeal={uiRules.cngDeal}
          returnFees={uiRules.returnFees}
          onSubmit={(payload) => {
            doInitiateBuyback(payload);
          }}
          onClose={() => setIsBuyBackInitiatePopupOpen(false)}
        />
      </Popup>
    </loaderWithAccessControl>
  );
};
export default withRouter(FinanceRefunds);
