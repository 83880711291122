import { createTypes } from "reduxsauce";

const ActionTypes = createTypes(
  `
    ACTION_LOGIN
    ACTION_LOGIN_SUCCESS
    ACTION_LOGIN_FAILURE
    ACTION_LOGOUT
    ACTION_TOGGLE_SIDEBAR
    ACTION_TOGGLE_PROFILE
    ACTION_UPDATE_LOGGEDIN_STATUS
    ACTION_UPDATE_LOGGEDIN_USER
    ACTION_FETCH_ORDER_DETAIL
    ACTION_FETCH_ORDER_DETAIL_INIT
    ACTION_FETCH_ORDER_DETAIL_SUCCESS
    ACTION_FETCH_ORDER_DETAIL_FAIL
    ACTION_UPDATE_ORDER_STATE
    ACTION_UPDATE_ORDER_STATE_INIT
    ACTION_UPDATE_ORDER_STATE_SUCCESS
    ACTION_UPDATE_ORDER_STATE_FAIL
    ACTION_UPDATE_ORDER
    ACTION_UPDATE_ORDER_INIT
    ACTION_UPDATE_ORDER_SUCCESS
    ACTION_UPDATE_ORDER_FAIL
    ACTION_UPDATE_HEADING
    ACTION_FETCH_ORDER_INSPECTION_DETAIL_INIT
    ACTION_FETCH_ORDER_INSPECTION_DETAIL_SUCCESS
    ACTION_FETCH_ORDER_INSPECTION_DETAIL_FAIL
    ACTION_SET_USER
    ACTION_FETCH_STORE_INIT
    ACTION_FETCH_STORE_SUCCESS
    ACTION_FETCH_STORE_FAIL
    ACTION_ADD_STORE_INIT
    ACTION_ADD_STORE_SUCCESS
    ACTION_ADD_STORE_FAIL
    ACTION_FETCH_CITIES_INIT
    ACTION_FETCH_CITIES_SUCCESS
    ACTION_FETCH_CITIES_FAIL
    ACTION_FETCH_DEALER_INIT
    ACTION_FETCH_DEALER_SUCCESS
    ACTION_FETCH_DEALER_FAIL
    ACTION_ADD_DEALER_INIT
    ACTION_ADD_DEALER_SUCCESS
    ACTION_ADD_DEALER_FAIL
    ACTION_FETCH_ALL_STORES_INIT
    ACTION_FETCH_ALL_STORES_SUCCESS
    ACTION_FETCH_ALL_STORES_FAIL
    ACTION_FETCH_STATES_INIT
    ACTION_FETCH_STATES_SUCCESS
    ACTION_FETCH_STATES_FAIL
    ACTION_FETCH_DEALER_FORM_INIT
    ACTION_FETCH_DEALER_FORM_SUCCESS
    ACTION_FETCH_DEALER_FORM_FAIL
    ACTION_ADD_AGENT_INIT
    ACTION_ADD_AGENT_SUCCESS
    ACTION_ADD_AGENT_FAIL
    ACTION_FETCH_AGENT_DETAIL_INIT
    ACTION_FETCH_AGENT_DETAIL_SUCCESS
    ACTION_FETCH_AGENT_DETAIL_FAIL
    ACTION_FILE_UPLOAD_INIT
    ACTION_FILE_UPLOAD_SUCCESS
    ACTION_FILE_UPLOAD_FAIL
    ACTION_AGENT_STATUS_UPDATE_INIT
    ACTION_AGENT_STATUS_UPDATE_SUCCESS
    ACTION_AGENT_STATUS_UPDATE_FAIL
    ACTION_INVENTORY_UPDATE_INIT
    ACTION_INVENTORY_UPDATE_SUCCESS
    ACTION_INVENTORY_UPDATE_FAIL
    ACTION_INVENTORY_LOG_INIT
    ACTION_INVENTORY_LOG_SUCCESS
    ACTION_INVENTORY_LOG_FAIL
    ACTION_ADD_B2C_ORDER_INIT
    ACTION_ADD_B2C_ORDER_SUCCESS
    ACTION_ADD_B2C_ORDER_FAIL
    ACTION_FETCH_C2B_ORDER_DETAIL_INIT
    ACTION_FETCH_C2B_ORDER_DETAIL_SUCCESS
    ACTION_FETCH_C2B_ORDER_DETAIL_FAIL
    ACTION_FETCH_DEALER_LIST_INIT
    ACTION_FETCH_DEALER_LIST_SUCCESS
    ACTION_FETCH_DEALER_LIST_FAIL
    ACTION_RESER_C2B_ORDER_FIELDS
    ACTION_FETCH_SELLER_INSPECTION_DETAIL_INIT
    ACTION_FETCH_SELLER_INSPECTION_DETAIL_SUCCESS
    ACTION_FETCH_SELLER_INSPECTION_DETAIL_FAIL
    ACTION_FETCH_ZONE_INIT
    ACTION_FETCH_ZONE_SUCCESS
    ACTION_FETCH_ZONE_FAIL
    ACTION_ADD_ZONE_INIT
    ACTION_ADD_ZONE_SUCCESS
    ACTION_ADD_ZONE_FAIL
    ACTION_FETCH_RCTRACKER_SUCCESS
    ACTION_UPDATE_INDEX
    ACTION_LANGAUGE_INIT
    ACTION_LANGAUGE_SUCCESS
    ACTION_LANGAUGE_FAIL
  `
);

export default ActionTypes;
