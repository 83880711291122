import app from 'firebase/app';
import 'firebase/auth';

import { FirebaseContext, withFirebase } from '../../hoc/with-firebase';
import withAuthorization from '../../hoc/with-authorization';
import withAdminLayout from '../../hoc/admin-layout';

const config_dev = {
  apiKey: 'AIzaSyDiTBAhot0cBvEdhQvIaWvmKmLlw3k62D8',
  authDomain: 'c2c-dev-383d6.firebaseapp.com',
  databaseURL: 'https://c2c-dev-383d6.firebaseio.com',
  projectId: 'c2c-dev-383d6',
  storageBucket: 'c2c-dev-383d6.appspot.com',
  messagingSenderId: '114457882658',
  appId: '1:114457882658:web:6b0aa9a3df2c0490bc1a7b'
};

const config_prod = {
  apiKey: 'AIzaSyBhNhV9VXAftvw9pzcmTwOhIfxvbw8YyJs',
  authDomain: 'c2c-prod.firebaseapp.com',
  databaseURL: 'https://c2c-prod.firebaseio.com',
  projectId: 'c2c-prod',
  storageBucket: 'c2c-prod.appspot.com',
  messagingSenderId: '739053259046',
  appId: '1:739053259046:web:c661a872b29478c9b359e7'
};

const config = process.env.REACT_APP_ENV === 'prod' ? config_prod : config_dev;

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.provider = new app.auth.GoogleAuthProvider();
  }

  signInWithPopup = () => {
    return this.auth.signInWithPopup(this.provider);
  };

  signOutGoogle = () => {
    return this.auth.signOut();
  };
}

export default Firebase;

export { FirebaseContext, withFirebase, withAuthorization, withAdminLayout };
