import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DocumentRejectionReasonsConfigList from "./component";
import { deleteDocumentRejectionReasonsConfig, fetchDocumentRejectionReasonsConfig, resetRejectionReasonsConfigList, updateDocumentRejectionReasonsConfig, updateDocumentRejectionsReasonData } from "../actions";

const mapStateToProps = ({
  documentRejectionReasonsConfig: {
    documentsRrConfigs,
    documentsRrConfigs: {
      configsUpdated
    }
  }
}) => ({
  documentsRrConfigs,
  configsUpdated
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchDocumentRejectionReasonsConfigConnect: fetchDocumentRejectionReasonsConfig,
  updateDocumentRejectionReasonsConfigConnect: updateDocumentRejectionReasonsConfig,
  updateDocumentRejectionsReasonDataConnect: updateDocumentRejectionsReasonData,
  resetRejectionReasonsConfigListConnect: resetRejectionReasonsConfigList,
  deleteDocumentRejectionReasonsConfigConnect: deleteDocumentRejectionReasonsConfig,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentRejectionReasonsConfigList);
