import React from 'react'
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { COLUMNS } from './constants';
import { useLogs } from '../hooks';

const LogsGrid = ({ dealId }) => {
  const { logs, pagination, setPagination } = useLogs({ dealId });

  const handlePageChange = (e) => {
    const { skip, take } = e.page;
    setPagination({ skip, take });
  }

  return (
    <div className="mt-3 mb-3">
      <Grid
        data={logs}
        onPageChange={handlePageChange}
        pageable
        {...pagination}
      >
        {COLUMNS.map((column) => <GridColumn key={column.title} {...column} />)}
      </Grid>
    </div>
  )
}

export default LogsGrid;