import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AdminSidebar from './component';
import { updateItemIndex } from './actions';
import { resetAllGridModelConnected } from '../../../admin-app/components/common/grid-view/actions';

const mapStateToProps = ({ SideBar: { selectedItemIndex } }) => {
  return {
    selectedItemIndex
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateItemIndex: updateItemIndex,
      resetAllGridModelConnected
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AdminSidebar);
