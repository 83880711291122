import { connect } from 'react-redux';
import RcManagementOthersDetail from './component';

const mapStateToProps = ({ rcOthersDealInfo }) => {
  const { dealInfo: dealInfoData } = rcOthersDealInfo;
  return { dealInfoData };
};

export default connect(
  mapStateToProps,
  null
)(RcManagementOthersDetail);
