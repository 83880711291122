import Types from './types';

import {
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected,
  handleHttpStatusError
} from '../../../services/helper';
import { financeService } from '../../../services';
const setPageNumberConnected = (pageNo) => ({
  type: Types.SET_PAGE_NUMBER,
  payload: pageNo
});
const fetchFinanceGridInfoConnected = (params) => (dispatch) => {
  dispatchSetLoaderConnected();
  return financeService
    .fetchFinanceGridInfo(params)
    .then((resp) => {
      dispatchResetLoaderConnected();
      if (resp.data && resp.data.data) {
        return {
          ...resp.data.data,
          response: (resp.data.data.response || []).map(i => ({
            ...i, 
            ...(i.car_source === "CUSTOMER_CARE" || i.car_source === "MANUAL_UPLOAD" ? {car_source: i.display_car_source} : {})
          }))
        };
      } else {
        return {
          page: 0,
          totalPages: 0,
          totalRecords: 0,
          response: []
        };
      }
    })
    .catch((error) => {
      handleHttpStatusError(error, true);
      throw error;
    });
};

export { setPageNumberConnected, fetchFinanceGridInfoConnected };
