export const getAddConfigState = (attributes) => {
  let data = {};
  
  for(let key in attributes) {
    let item = attributes[key].find(item => item.default === true);
    if (!item) return; 
    let _key = key;
    data[_key] = item.value;
  }

  return data;
}