import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PublishRemarksModel from './component';

const mapStateToProps = (state) => {
  const {
    publishRemarksInfo: { data, firstDataSet }
  } = state;
  return {
    data,
    firstDataSet
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublishRemarksModel);
