import { showToastMessages } from '../../shared/utils/helper';
import {
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected,
  handleHttpStatusError,
  buildQuery
} from '../../services/helper';
const HTTP_STATUS_OK = 200;

export default (api) => {
  const getDealInfoById = (id, withLoader = true) => {
    withLoader && dispatchSetLoaderConnected();
    return api
      .get(`/rc/deal-info/${id}/`)
      .then((resp) => {
        dispatchResetLoaderConnected();
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        handleHttpStatusError(error);
        return Promise.reject(error);
      });
  };
  const submitDeliveryDate = (data) => {
    // return api.post(`secure/buyer/appointments/${params.appId}`, params).then(resp => {
    return api
      .put(`/rc/deal-info`, data)
      .then((resp) => {
        // const { data: { data } } = resp;
        // return data;
        showToastMessages('Added successfully');
        return resp.data;
      })
      .catch((error) => {
        showToastMessages(error.message, false);
        return error;
      });
  };

  const updateDealInfo = (id, data) => {
    // return api.post(`secure/buyer/appointments/${params.appId}`, params).then(resp => {
    return api
      .put(`/rc/deal-info?dealId=${id}`, data)
      .then((resp) => {
        // const { data: { data } } = resp;
        // return data;
        showToastMessages('Added successfully');
        return resp.data;
      })
      .catch((error) => {
        showToastMessages(error.message, false);
        return error;
      });
  };

  const updateOthersRcData = (id, data) => {
    // return api.post(`secure/buyer/appointments/${params.appId}`, params).then(resp => {
    return api
      .put(`/rc/others?rcApplicationId=${id}`, data)
      .then((resp) => {
        // const { data: { data } } = resp;
        // return data;
        showToastMessages('Added successfully');
        return resp.data;
      })
      .catch((error) => {
        showToastMessages(error.message, false);
        return error;
      });
  };

  const fetchDealHistoryById = (id) => {
    dispatchSetLoaderConnected();
    return api
      .get(`/rc/deal-info-history?dealId=${id}`)
      .then((resp) => {
        dispatchResetLoaderConnected();
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        handleHttpStatusError(error);
        return Promise.reject(error);
      });
  };

  const fetchOthersDealHistoryById = (id) => {
    dispatchSetLoaderConnected();
    return api
      .get(`/rc/others/deal-info-history?rcApplicationId=${id}`)
      .then((resp) => {
        dispatchResetLoaderConnected();
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        handleHttpStatusError(error);
        return Promise.reject(error);
      });
  };

  const getDealDeliveryChecklist = (Id) => {
    dispatchSetLoaderConnected();
    return new Promise((resolve, reject) => {
      api.get(`delivery-checklist/list?dealId=${Id}`)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          }).finally(()=>{
            dispatchResetLoaderConnected();
          });
    });
  };

  const saveDealDeliveryChecklist = (Id, params) => {
    dispatchSetLoaderConnected();
    return new Promise((resolve, reject) => {
      api.post(`delivery-checklist/save?dealId=${Id}`, params)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          }).finally(()=>{
            dispatchResetLoaderConnected();
          });
    });
  };

  const getDealDeliveryChecklistLogs = (Id) => {
    dispatchSetLoaderConnected();
    return new Promise((resolve, reject) => {
      api.get(`delivery-checklist/logs?dealId=${Id}`)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          }).finally(()=>{
            dispatchResetLoaderConnected();
          });
    });
  };

  const getRcOthersDetail = (id, withLoader = true) => {
    withLoader && dispatchSetLoaderConnected();
    return api
      .get(`/rc/others?rcApplicationId=${id}`)
      .then((resp) => {
        dispatchResetLoaderConnected();
        const {
          data
        } = resp;
        return data;
      })
      .catch((error) => {
        handleHttpStatusError(error);
        return Promise.reject(error);
      });
  };

  const deleteSecondKeyImageFile = (dealId) => {
    dispatchSetLoaderConnected();
    const params = new FormData();
    params.append("dealId", dealId);
    params.append("fileType", "SECOND_KEY");
    return new Promise((resolve, reject) => {
      api.delete(`/delivery-checklist/file`, {data: params})
        .then(response => {
          if (response && response.status === HTTP_STATUS_OK) {
            showToastMessages(response.message);
            resolve(response.data);
          } else {
            resolve([]);
          } 
        })
        .catch(error => {
            reject(error);
        }).finally(()=>{
          dispatchResetLoaderConnected();
        });
    });
  };

  const uploadSecondKeyImageFile = (dealId, file) => {
    dispatchSetLoaderConnected();
    const formData = new FormData();
    formData.append("file", file);
    const params = {
      dealId,
      fileType: "SECOND_KEY"
    };
    return new Promise((resolve, reject) => {
      api.post(`/delivery-checklist/file?${buildQuery(params)}`, formData)
        .then(response => {
          if (response && response.status === HTTP_STATUS_OK) {
            showToastMessages(response.message);
            resolve(response.data);
          } else {
            resolve([]);
          } 
        })
        .catch(error => {
            reject(error);
        }).finally(()=>{
          dispatchResetLoaderConnected();
        });
    });
  };
  return {
    deleteSecondKeyImageFile,
    uploadSecondKeyImageFile,
    getDealDeliveryChecklistLogs,
    saveDealDeliveryChecklist,
    getDealDeliveryChecklist,
    getDealInfoById,
    submitDeliveryDate,
    fetchDealHistoryById,
    updateDealInfo,
    getRcOthersDetail,
    fetchOthersDealHistoryById,
    updateOthersRcData
  };
};
