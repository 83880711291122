import * as httpService from './httpService';
import { config } from './../config/local';

import { IS_ENV_DEV, IS_MOCK } from '../../shared/utils/constants';
import { buildQuery } from '../../services/helper';

const HTTP_STATUS_OK = 200;

export default (api) => {
  const fetchStores = () => {
    const path = config.api.c2c.storesAll.path;
    const url = path;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };
  const fetchStoreDetails = (storeId) => {
    const path = config.api.c2c.stores.path;
    const url = `${path}/${storeId}`;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };
  const getStores = (params = { page: 0, size: 10 }) => {
    const url = config.api.c2c.stores.path;
    const queryStr = params ? `?${buildQuery(params)}` : '';
    return api
      .get(url + queryStr)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        if (data) {
          return data;
        } else {
          return {
            page: 0,
            totalPages: 0,
            totalRecords: 0,
            response: []
          };
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  return {
    fetchStoreDetails,
    getStores,
    fetchStores
  };
};

//Get c2c stores from API
export async function getStores(params = {}, page = 0, size = 10) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.stores.path;
  const url = host.concat(path);
  if (!params.page) {
    params.page = page;
    params.size = size;
  }
  const auth = false;
  const response = await httpService.get(url, params, auth);
  if (response && response.status === HTTP_STATUS_OK) {
    return response.data ? response.data : [];
  } else {
    return [];
  }
}

//Get c2c store details from API
export async function getStoreDetails(storeId) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.stores.path;
  const url = host
    .concat(path)
    .concat('/')
    .concat(storeId);
  const response = await httpService.get(url, {});
  if (response && response.status === HTTP_STATUS_OK) {
    return response.data;
  } else {
    return [];
  }
}

//add store
export async function addStore(data) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.stores.path;
  const url = host.concat(path);
  const response = await httpService.post(url, data);
  if (response && response.status === HTTP_STATUS_OK) {
    // toast.success("Store has been updated successfully");
    return response;
  } else {
    return [];
  }
}
