export const userStateConstants = {
  PRESENT: 'PRESENT',
  ABSENT: 'ABSENT',
  ON_TRAINING: 'ON_TRAINING',
  RESIGNED: 'RESIGNED'
};
export const USER_STATE_OPTIONS = {
  PRESENT: { key: userStateConstants.PRESENT, value: 'Present' },
  ABSENT: { key: userStateConstants.ABSENT, value: 'Absent' },
  ON_TRAINING: { key: userStateConstants.ON_TRAINING, value: 'On Training' },
  RESIGNED: { key: userStateConstants.RESIGNED, value: 'Resigned' }
};
