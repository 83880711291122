import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  detail: {},
  isLoading: false
};

export const fetchCityInspectionStoreDetailInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const fetchCityInspectionStoreDetailSuccess = (
  state = INITIAL_STATE,
  { data: detail }
) => {
  return {
    ...state,
    detail,
    isLoading: false
  };
};

export const fetchCityInspectionStoreDetailFail = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

export const HANDLERS = {
  [Types.FETCH_CITY_INSPECTION_STORE_INIT]: fetchCityInspectionStoreDetailInit,
  [Types.FETCH_CITY_INSPECTION_STORE_SUCCESS]: fetchCityInspectionStoreDetailSuccess,
  [Types.FETCH_CITY_INSPECTION_STORE_FAIL]: fetchCityInspectionStoreDetailFail
};

export default createReducer(INITIAL_STATE, HANDLERS);
