import Types from './types';
import { orderService } from '../../../services';

// Global actions
import {
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected,
  handleHttpStatusError
} from '../../../services/helper';
import { downloadBlob, showToastMessages } from '../../../shared/utils/helper';

//Fetch order details
const fetchImageDetailInit = () => ({
  type: Types.FETCH_ORDER_IMAGE_INIT
});

const fetchImageDetailSuccess = (data) => ({
  type: Types.FETCH_ORDER_IMAGE_SUCCESS,
  payload: { data }
});

const fetchImageDetailFailure = (error) => ({
  type: Types.FETCH_ORDER_IMAGE_FAILURE,
  error
});

const updateImageDetailSuccess = (imageUrl, id) => ({
  type: Types.UPDATE_ORDER_IMAGE_SUCCESS,
  payload: { imageUrl, id }
});
const approveImageDetailSuccess = (is_to_be_published, id) => ({
  type: Types.APPROVE_ORDER_IMAGE_SUCCESS,
  payload: { is_to_be_published, id }
});
const fetchImageDetailConnected = (orderId) => (dispatch) => {
  dispatchSetLoaderConnected();
  dispatch(fetchImageDetailInit());
  return new Promise((resolve, reject) => {
    orderService
      .fetchImageDetail(orderId)
      .then((resp) => {
        dispatch(fetchImageDetailSuccess(resp.data));
        dispatchResetLoaderConnected();
      })
      .catch((error) => {
        handleHttpStatusError(error);
        dispatch(fetchImageDetailFailure(error));
        reject(error);
      });
  });
};
const replaceImageConnected = (formData, id, imageDetail) => (dispatch) => {
  return new Promise((resolve, reject) => {
    orderService
      .replaceImage(formData)
      .then((resp) => {
        resolve(resp);
        let url = `${resp.data.data.image_url}`;
        dispatch(updateImageDetailSuccess(url, id));
        showToastMessages(resp.data.message);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const approveImageConnected = (data, orderid) => (dispatch) => {
  let imagedata = {
    images: [{ ...data }],
    order_id: orderid
  };

  return new Promise((resolve, reject) => {
    orderService
      .approveImage(imagedata)
      .then((resp) => {
        const is_to_be_published = data.is_to_be_published;
        const id = data.id;
        resolve(resp);
        showToastMessages(resp.data.message);
        dispatch(approveImageDetailSuccess(is_to_be_published, id));
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const downloadImageConnected = (id, filename) => (dispatch) => {
  return new Promise((resolve, reject) => {
    orderService
      .downloadImage(id)
      .then((resp) => {
        /* Window.open also works */
        // const path = config.api.c2c.downloadImage.path;
        // window.open(`${BASE_API_URL}${path}/id`);
        const { data } = resp;
        downloadBlob(data, filename);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export {
  fetchImageDetailConnected,
  replaceImageConnected,
  approveImageConnected,
  downloadImageConnected
};
