import React from 'react';
import InputText from '../../../shared/components/input-text';
const DealerMarkupslabs = ({ dealerData, handleSlabChange }) => {
  return (
    <div>
      <div className='col-md-12'>
        <h3>Mark Up Slabs</h3>
      </div>
      {dealerData &&
        dealerData.dealerMarkupSlabs &&
        dealerData.dealerMarkupSlabs.map((slab, index) => {
          return (
            <div key={index} className='float-left form-group col-md-3'>
              <label className='font-weight-bold'>
                {slab.start}-{slab.end}
              </label>
              <div>
                <InputText
                  type='text'
                  className='form-control'
                  name='markUpValue'
                  value={slab.markUpValue ? slab.markUpValue : 0}
                  onChange={(e) => handleSlabChange(e, slab)}
                  required={true}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default DealerMarkupslabs;
