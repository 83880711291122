import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ZonesDetailConfig from './component';

const mapStateToProps = (store) => {
  const {
    loaderInfo: { isLoading, isForbidden, isError, message }
  } = store;
  return { isLoading, isForbidden, isError, message };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ZonesDetailConfig);
