import React, { useState, useEffect } from 'react';
import HeaderLogo from './images/konnect-logo.svg';
import './style/style.css';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { getEmailFromLocalStorage, removeDataFromLocalStorage } from '../../utils/helper';
import qs from 'qs';
import { useDescope } from '@descope/react-sdk';
import { withRouter } from 'react-router-dom';

const Header = (props) => {
  const { logout } = useDescope();
  const dialerData = qs.parse(window.location.search.substring(1));
  const [userName] = useState(getEmailFromLocalStorage());
  const [isOpen, setIsOpen] = useState(false);
  const [agentName, setAgentName] = useState();

  useEffect(() => {
    if (dialerData.agentName) {
      setAgentName(dialerData.agentName);
    }
  }, [dialerData]);

  const handleLogout = async () => {
    await logout();
    removeDataFromLocalStorage();
    props.history.push("/login");
  }

  return (
    <div className="nav-wrap">
      <Navbar color="light" light expand="md">
        <NavbarBrand href="#">
          <img src={HeaderLogo} alt='Konnect Logo' className="img-cursor-dflt" />
        </NavbarBrand>
        <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {agentName && (
              <NavItem className="agent">
                <NavLink href="#">Agent Name: {agentName}</NavLink>
              </NavItem>
            )}
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <div className="admin">login: {userName}</div>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={handleLogout}>
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default withRouter(Header);