import React, { useState, useEffect } from 'react';
import RcCasetypeRtotransfer from './rc-others-casetype-rtotransfer';
import RcCasetypeQuestions from './rc-others-casetype-questions';
import RcCasetypeCost from './rc-others-casetype-cost';
import { rcCaseTypeService } from '../../../../services';
import { withRouter } from 'react-router-dom';
import LoaderWithAccessControl from '../../../../shared/components/loader-with-access-control';
import './style.css';
import { QUESTIONS } from './constants';
import RemarksRcCaseType from './rc-others-casetype-remarks';
import { SERVICE_TYPE_KEYS } from '../rc-others-segment-form/constants';

const REQUIRED_FIELDS = {
  rc_cost_buyer: 'RC Cost from buyer',
  rc_cost_seller: 'RC Cost from seller',
  buyer_holdback: 'Holdback from buyer',
  seller_holdback: 'Holdback from seller'
};

const RcCaseTypeOthers = (props) => {
  const { match, dealInfoData } = props;
  const [quesObj, setQuesObj] = useState({});
  const [id] = useState(match.params.id);
  const [caseTypeData, setCaseTypeData] = useState();
  const [totalQues, setTotalQues] = useState(0);
  const [error, setError] = useState(false);
  const [bankNameIndex, setBankNameIndex] = useState();

  useEffect(() => {
  getCaseTypeData();
  }, []);

  const getRcCaseTypeData = (id, reload) => {
    return rcCaseTypeService.getOthersRcQuestion(id, reload);
  }

  const getCaseTypeData = (reload = true) => {
    getRcCaseTypeData(id, reload)
      .then((res) => {
        setCaseTypeData(res ? res : null);
        let quesCount = 0,
          tempQuesObj = {};
        res.questionnaire.forEach((item, index) => {
          quesCount =
            item.answer_button_type === 'radioButton'
              ? quesCount + 1
              : quesCount;
          if (item.actual_answer && item.answer_button_type === 'radioButton') {
            tempQuesObj[index] = true;
          }
          if (item.question_text === QUESTIONS.BANK_NAME) {
            setBankNameIndex(index);
          }
        });
        setTotalQues(quesCount);
        setQuesObj(tempQuesObj);
      })
      .catch((err) => {});
  };

  const validate = () => {
    setError(false);
    if (Object.keys(quesObj).length === totalQues && totalQues > 0) {
      Object.keys(REQUIRED_FIELDS).every((key) => {
        if (caseTypeData[key] >= 0) {
          setError(false);
          return true;
        } else {
          setError(REQUIRED_FIELDS[key]);
          return false;
        }
      });
      if (error) return false;
      else {
        setError(false);
        return true;
      }
    } else {
      return true;
    }
  };

  const submitRcAllCaseType = (method, payload) => {
    return rcCaseTypeService.submitOthersRcCaseData(method, payload);
  }

  const handleSave = (e) => {
    e.preventDefault();
    if (!validate() && Object.keys(quesObj).length === totalQues) return false;
    // Validate RC Case type
    if (
      !caseTypeData['rc_case_type'] &&
      Object.keys(quesObj).length === totalQues
    ) {
      setError('RC Case Type');
      return false;
    } else if (isRequiredRtoTransferTo(dealInfoData) && !caseTypeData['rto_transfer_to']) {
      setError('RTO Transfer to');
      return false;
    } else {
      setError(false);
    }
    if (caseTypeData.deal_id) {
      submitRcAllCaseType('put', { ...caseTypeData, deal_id: id})
        .then((res) => {
          setTimeout(()=> {
            getCaseTypeData();
          }, 2000);
        });
    } else {
      submitRcAllCaseType('post', { ...caseTypeData, deal_id: id})
        .then((res) => {
          setTimeout(()=> {
            getCaseTypeData();
          }, 2000);
        });
    }
  };

  const onChangeAnswer = (e, index) => {
    if (bankNameIndex !== index) {
      setQuesObj({
        ...quesObj,
        [index]: true
      });
    }
    let rcCaseTYpe = { ...caseTypeData };
    rcCaseTYpe.questionnaire[index]['actual_answer'] = e.target.value;
    if (
      bankNameIndex &&
      rcCaseTYpe.questionnaire[index]['question_text'] ===
        QUESTIONS.BUYER_LOAN_INTEREST
    ) {
      rcCaseTYpe.questionnaire[bankNameIndex]['actual_answer'] = '';
    }
    setCaseTypeData({ ...rcCaseTYpe });
  };

  const onChangeSelect = (selected, param) => {
    let rcCaseTYpe = { ...caseTypeData };
    if(param === 'rc_case_type'){
      const updatedValue = !!selected && selected.map((item) => {return(item.value)});
      rcCaseTYpe[param] = !!updatedValue ? updatedValue : null;
    } else {
      rcCaseTYpe[param] = selected ? selected.value : null;
    }
    setCaseTypeData({ ...rcCaseTYpe });
  };

  const onChangeCost = (e) => {
    let rcCaseTYpe = { ...caseTypeData };
    if (e.target.name === "party_peshi_applicable") {
      rcCaseTYpe[e.target.name] = e.target.checked;
    } else {
      const { value } = e.target;
      rcCaseTYpe[e.target.name] = value;
    }
    
    setCaseTypeData({ ...rcCaseTYpe });
  };

  const releaseBlockHoldback = (payload, updatedData) => {
    rcCaseTypeService.releaseBlockHoldback(payload).then((response) => {
      setCaseTypeData(updatedData);
      const reload = false;
      getCaseTypeData(reload);
    });
  };

  const isRequiredRtoTransferTo = () => {
    if (dealInfoData && dealInfoData.serviceType === SERVICE_TYPE_KEYS.RC_TRANSFER_SERVICE) return true;
  }

  return (
    <>
      <LoaderWithAccessControl>
        <form onSubmit={(e) => handleSave(e)}>
          <div className='row'>
            <RcCasetypeRtotransfer
              caseTypeData={caseTypeData}
              onChangeSelect={onChangeSelect}
              isRequiredRtoTransferTo={isRequiredRtoTransferTo}
            />
            <RcCasetypeQuestions
              questionnaire={caseTypeData && caseTypeData.questionnaire}
              onChangeAnswer={onChangeAnswer}
              possibleAnswersType={
                caseTypeData && caseTypeData.possible_answer_type
                  ? caseTypeData.possible_answer_type
                  : []
              }
            />
          </div>
          <RcCasetypeCost
            caseTypeData={caseTypeData && caseTypeData}
            onChangeSelect={onChangeSelect}
            onChangeCost={onChangeCost}
            numberOfQuestionAnswered={quesObj && Object.keys(quesObj).length}
            numberOfQuestions={totalQues}
            setCaseTypeData={setCaseTypeData}
            releaseBlockHoldback={releaseBlockHoldback}
            dealId={id}
            dealInfoData={dealInfoData}
          />
          <div className='row'>
            <div className='col-12'>
              <button className='btn btn-primary'>Save</button>
            </div>
          </div>
          {error && <font color='red'> Required field : {error}</font>}
        </form>
        <div className='wrapperAgentDetails mt-20 p15'>
          <RemarksRcCaseType remarksId={id} remarksType='CASETYPE' />
        </div>
      </LoaderWithAccessControl>
    </>
  );
};
export default withRouter(RcCaseTypeOthers);
