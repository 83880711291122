export default (api) => {

  const fetchInspectionReportLink = (carId) => {
    return api.get(`v2/image/${carId}`).then(resp => {
      const { data: { data } } = resp;
      return data;
    }).catch(error => {
      console.log({ error });
    });
  }

  return {
    fetchInspectionReportLink
  };
};