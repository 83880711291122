import React from 'react';
import { TABLE_HEADERS } from './constants';
import './style.css';
import { AGENT_STATUS } from '../AgentDetail/constants';
import { getformattedDate } from '../../../shared/utils/datesHelper';

const AgentLog = ({ label = '', logKey, logs }) => {
  return (
    <>
      <p>
        <b>{label}</b>
      </p>
      <table className='table remarksLog'>
        <thead>
          <tr>
            {TABLE_HEADERS[logKey].map((item, index) => {
              return (
                <th
                  key={`logs_header_${index}`}
                  className={item.colWidth ? item.colWidth : ''}
                >
                  {item.label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(logs) &&
            logs.map((log, index) => {
              return (
                <tr key={`logs_row_${index}`}>
                  {TABLE_HEADERS[logKey].map((item, itemIndex) => {
                    if (item.headerKey === 'agent_status') {
                      return (
                        <td
                          key={`log_${itemIndex}`}
                          className={item.colWidth ? item.colWidth : ''}
                        >
                          {AGENT_STATUS[log[item.headerKey]]['label']}
                        </td>
                      );
                    } else {
                      return (
                        <td
                          key={`log_${itemIndex}`}
                          className={item.colWidth ? item.colWidth : ''}
                        >
                          {item.dataType === 'datetime'
                            ? getformattedDate(
                                log[item.headerKey],
                                item.dataType
                              )
                            : log[item.headerKey]}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};

export default AgentLog;
