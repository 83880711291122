import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getLocalDatetime } from '../../utils/datesHelper';
import './styles.css';

const CustomDatePicker = ({
  name = null,
  selected,
  onDateChange,
  isLocal = true,
  ...restOfProps
}) => {
  const onChange = (date) => {
    const dateValue = isLocal ? getLocalDatetime(date) : date;
    onDateChange(dateValue, name);
  };
  return (
    <div>
      <DatePicker
        selected={selected ? new Date(selected) : null}
        onChange={onChange}
        onChangeRaw={(e) => {
          e.preventDefault();
        }}
        dateFormat='dd/MM/yyyy'
        {...restOfProps}
      />
    </div>
  );
};

export default CustomDatePicker;
