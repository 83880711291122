import { getFromLocalStorage } from "../helper";

export const ROLE = 'role';
export const ADMIN_ROLE = 'admin_role';
export const USER_ID = 'userId';
export const EXCLUDE_USER_STATUS = 'RESIGNED';
export const ROLE_TYPE = 'APP';
export const EMAIL = 'email';
export const APP_SOURCE = 'app_source';
export const BUYER_PRIMARY_PHONE = 'buyer_phone';
export const IS_LOGGED_IN = 'isLoggedIn';
export const YES = 'Yes';
export const NO = 'No';
export const NA = 'NA';
export const NA_TEXT = 'N/A';
export const IS_ENV_DEV = process.env.NODE_ENV === 'development';
export const REACT_APP_ENV = process.env.REACT_APP_ENV;
export const IS_MOCK = false;
export const USER_ROLES = 'userRoles';

export const FORGET_PASSWORD_LINK = 'https://portal.24c.in/reset-password.php';
export const FUEL_OPTIONS = [
  {
    value: 'CNG',
    label: 'CNG'
  },
  {
    value: 'Petrol',
    label: 'Petrol'
  },
  {
    value: 'Diesel',
    label: 'Diesel'
  },
  {
    value: 'LPG',
    label: 'LPG'
  },
  {
    value: 'Electric',
    label: 'Electric'
  },
  {
    value: 'Hybrid',
    label: 'Hybrid'
  }
];

export const PAGE_SIZE = {
  DEFAULT: 10,
  '20': 20
};
export const CAR_SOURCE = {
  CUSTOMER_CARE: 'CUSTOMER_CARE',
  B2C: 'B2C',
  GS_CAR: 'GS_CAR',
  MANUAL_UPLOAD: 'MANUAL_UPLOAD',
  C2C_DEALER_CAR: 'C2C_DEALER_CAR'
};

export const APP_SOURCE_TYPE = {
  CC : 'CC',
  INDEPENDENT: 'INDEPENDENT'
};

export const CLASSIFIED_CAR = 'CLASSIFIED_CAR';

//admin-role: authorization control, role checks and action visibility
export const ROLES = {
  C2C_ADMIN_PANEL: 'C2C_ADMIN_PANEL',
  GM_SALES: 'GM_SALES',
  C2C_LMS_ADMIN: 'C2C_LMS_ADMIN',
  C2C_ADMIN: 'C2C_ADMIN',
  C2C_MIS: 'C2C_MIS',
  C2C_OPS_RC: 'C2C_OPS_RC',
  C2C_OPS_RC_DOCS: 'C2C_OPS_RC_DOCS',
  C2C_OPS_RC_TRACKER: 'C2C_OPS_RC_TRACKER',
  GM_OPS: 'GM_OPS',
  YARD_MANAGER: 'YARD_MANAGER',
  C2C_FINANCE: 'C2C_FINANCE',
  SELL_24: 'SELL_24',
  C2C_QC: 'C2C_QC',
  C2C_OPS_BUDDY: 'C2C_OPS_BUDDY',
  C2C_OPS_CASE_TRACKER: 'C2C_OPS_CASE_TRACKER',
  C2C_OPS_DOCS_TRACKER: 'C2C_OPS_DOCS_TRACKER',
  C2C_OPS_DOCS_CASE: 'C2C_OPS_DOCS_CASE',
  C2C_VIEW_ONLY: 'C2C_VIEW_ONLY',
  INSURANCE: 'INSURANCE',
  HYPOTHECATION_NBFC_OPS: 'HYPOTHECATION_NBFC_OPS',
  HYPOTHECATION_RC_OPS: 'HYPOTHECATION_RC_OPS',
  C2C_RC_VERIFICATION: 'C2C_RC_VERIFICATION'
};

// prettier-ignore
export const USER_ROLES_OPTIONS = {
  [ROLES.GM_SALES]: { label: 'GM SALES', value: ROLES.GM_SALES },
  [ROLES.C2C_ADMIN]: { label: 'C2C ADMIN', value: ROLES.C2C_ADMIN },
  [ROLES.C2C_MIS]: { label: 'C2C MIS', value: ROLES.C2C_MIS },
  [ROLES.C2C_OPS_RC]: { label: 'C2C OPS RC', value: ROLES.C2C_OPS_RC },
  [ROLES.C2C_OPS_RC_DOCS]: { label: 'C2C OPS RC DOCS', value: ROLES.C2C_OPS_RC_DOCS },
  [ROLES.C2C_OPS_RC_TRACKER]: {label: 'C2C OPS RC TRACKER',value: ROLES.C2C_OPS_RC_TRACKER },
  [ROLES.GM_OPS]: { label: 'GM OPS', value:ROLES.GM_OPS },
  [ROLES.YARD_MANAGER]: { label: 'YARD MANAGER', value: ROLES.YARD_MANAGER },
  [ROLES.C2C_FINANCE]: { label: 'C2C FINANCE', value: ROLES.C2C_FINANCE },
  // [ROLES.SELL_24]: { label: 'SELL 24', value: ROLES.SELL_24 },
  [ROLES.C2C_QC]: { label: 'C2C QC', value: ROLES.C2C_QC },
  [ROLES.C2C_OPS_BUDDY]: { label: 'C2C OPS BUDDY', value: ROLES.C2C_OPS_BUDDY },
  [ROLES.C2C_OPS_CASE_TRACKER]: { label: 'C2C OPS CASE TRACKER', value: ROLES.C2C_OPS_CASE_TRACKER },
  [ROLES.C2C_OPS_DOCS_TRACKER]: { label: 'C2C OPS DOCS TRACKER', value: ROLES.C2C_OPS_DOCS_TRACKER },
  [ROLES.C2C_OPS_DOCS_CASE]: { label: 'C2C OPS DOCS CASE', value: ROLES.C2C_OPS_DOCS_CASE },
  [ROLES.C2C_VIEW_ONLY]: { label: 'C2C VIEW ONLY', value: ROLES.C2C_VIEW_ONLY },
  [ROLES.INSURANCE]: { label: 'INSURANCE', value: ROLES.INSURANCE },
  [ROLES.HYPOTHECATION_NBFC_OPS]: { label: 'Hypothecation NBFS OPS', value: ROLES.HYPOTHECATION_NBFC_OPS },
  [ROLES.HYPOTHECATION_RC_OPS]: { label: 'Hypothecation RC OPS', value: ROLES.HYPOTHECATION_RC_OPS },
  [ROLES.C2C_RC_VERIFICATION]: { label: 'C2C RC VERIFICATION', value: ROLES.C2C_RC_VERIFICATION}, 
};

export const TOAST_TYPES = {
  INFO: 'info',
  WARNING: 'warning',
  DEFAULT: 'default'
  // add more from docs react-toastify
};

//role: sidebar access
export const C2C_LMS_ROLES = {
  C2C_LMS_RM: 'C2C_LMS_RM',
  C2C_LMS_TM: 'C2C_LMS_TM',
  C2C_LMS_GM: 'C2C_LMS_GM',
  C2C_LMS_ADMIN: 'C2C_LMS_ADMIN',
  CC_AGENT_FOR_C2C: 'CC_AGENT_FOR_C2C',
  C2C_ADMIN_PANEL:'C2C_ADMIN_PANEL',
  DSA_AGENT_FOR_C2C: 'DSA_AGENT_FOR_C2C'
}

export const USER_LMS_ROLES_OPTIONS = {
  [C2C_LMS_ROLES.C2C_LMS_RM]: { label: C2C_LMS_ROLES.C2C_LMS_RM, value: C2C_LMS_ROLES.C2C_LMS_RM },
  [C2C_LMS_ROLES.C2C_LMS_TM]: { label: C2C_LMS_ROLES.C2C_LMS_TM, value: C2C_LMS_ROLES.C2C_LMS_TM },
  [C2C_LMS_ROLES.C2C_LMS_GM]: { label: C2C_LMS_ROLES.C2C_LMS_GM, value: C2C_LMS_ROLES.C2C_LMS_GM },
  [C2C_LMS_ROLES.C2C_LMS_ADMIN]: { label: C2C_LMS_ROLES.C2C_LMS_ADMIN, value: C2C_LMS_ROLES.C2C_LMS_ADMIN },
  [C2C_LMS_ROLES.CC_AGENT_FOR_C2C]: { label: C2C_LMS_ROLES.CC_AGENT_FOR_C2C, value: C2C_LMS_ROLES.CC_AGENT_FOR_C2C },
  [C2C_LMS_ROLES.C2C_ADMIN_PANEL]: { label: C2C_LMS_ROLES.C2C_ADMIN_PANEL, value: C2C_LMS_ROLES.C2C_ADMIN_PANEL },
  [C2C_LMS_ROLES.DSA_AGENT_FOR_C2C]: { label: C2C_LMS_ROLES.DSA_AGENT_FOR_C2C, value: C2C_LMS_ROLES.DSA_AGENT_FOR_C2C },
}

/**
 * Checks if the user has any of the specified roles.
 *
 * This function retrieves the user's roles from local storage and checks
 * if any of the roles in the provided list are present in the user's roles.
 *
 * @param {string[]} roleList - An array of roles to check against the user's roles.
 * @returns {string|undefined} The first matching role if found, otherwise undefined.
 */

export const checkForUserRoles = (roleList) => {
  const userRoles = getFromLocalStorage(USER_ROLES);

  if(!userRoles) return;

  const userRolesList = userRoles.split(",");
  const roles = userRolesList.filter(role => roleList.includes(role));
  
  return roles[0];
}