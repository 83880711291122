import React from 'react';
import withAdminLayout from '../../../shared/hoc/admin-layout';
import ErrorBoundary from '../../components/common/errorBoundary';
import { withAuthorization } from '../../../shared/utils/firebase';
import DocumentsList from '../../components/documents-list';

const DocumentsListPage = (props) => {
  return (
    <div>
      <ErrorBoundary>
        <DocumentsList />
      </ErrorBoundary>
    </div>
  );
};

export default withAdminLayout(withAuthorization(() => true)(DocumentsListPage));
