import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchAllServiceCharges, submitServiceChargeSlab } from './actions';
import ServiceCharges from './component';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAllServiceChargesConnect: fetchAllServiceCharges,
      submitServiceChargeSlabConnect: submitServiceChargeSlab
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceCharges);
