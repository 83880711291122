import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FinanceServiceCharge from './component';
import { fetchServiceChargeConnected } from './actions';

const mapStateToProps = (state) => {
  const { paymentInfo } = state;

  return {
    paymentInfo
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchServiceChargeConnected
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinanceServiceCharge);
