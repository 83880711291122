import {
  carSourceConstants,
  backgroundCheckStatusConstants,
  orderStateConstants,
  inventoryStatusConstants
} from '../constants/constants';

export default [
  {
    headerName: 'ArId',
    width: 160,
    field: 'arId',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    cellRenderer: "LinkRenderer",
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Ar Name',
    width: 160,
    field: 'name',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },

    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Ar Number',
    width: 160,
    field: 'contactNumber',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },

    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Address',
    width: 260,
    field: 'shopAddress',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },

    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'City ',
    width: 160,
    field: 'city',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },

    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Pincode',
    width: 160,
    field: 'pincode',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },

    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Status',
    width: 160,
    field: 'status',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },

    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  //   {
  //     headerName: 'Action',
  //     width: 160,
  //      field: 'Edit',
  //      filter: 'agTextColumnFilter',
  //     filterParams: {
  //       filterOptions: ['contains'],
  //       // debounceMs: 0,
  //       caseSensitive: false,
  //       suppressAndOrCondition: true,
  //       applyButton: true,
  //       clearButton: true
  //     },

  //      floatingFilterComponentParams: { suppressFilterButton: true }
  //   },

];