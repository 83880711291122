import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  message: null,
  imageData: {},
};

export const fetchImageDetailInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true, isError: false, message: null };
};

export const fetchImageDetailSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return { ...state, isLoading: false, isError: false, imageData: data };
};
export const updateImageDetailSuccess = (state = INITIAL_STATE, action) => {
  const { imageUrl, id } = action.payload;
  const imageList = state.imageData.data.images.map((item, index) => {
    if (item.id === id) {
      item['url'] = imageUrl;
    }
    return item;
  });
  let imageData = { ...state.imageData };
  imageData.data.images = imageList;
  return {
    ...state,
    isLoading: false,
    isError: false,
    imageData,
  };
};
const approveImageDetailSuccess = (state = INITIAL_STATE, action) => {
  const { is_to_be_published, id } = action.payload;
  const imageList = state.imageData.data.images.map((item, index) => {
    if (item.id === id) {
      item['is_to_be_published'] = is_to_be_published;
    }
    return item;
  });
  let imageData = { ...state.imageData };
  imageData.data.images = imageList;
  return {
    ...state,
    isLoading: false,
    isError: false,
    imageData,
  };
};
export const fetchImageDetailFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    isError: true,
    message: 'Something failed',
    imageData: {},
  };
};

export const HANDLERS = {
  [Types.FETCH_ORDER_IMAGE_INIT]: fetchImageDetailInit,
  [Types.FETCH_ORDER_IMAGE_SUCCESS]: fetchImageDetailSuccess,
  [Types.FETCH_ORDER_IMAGE_FAILURE]: fetchImageDetailFailure,
  [Types.UPDATE_ORDER_IMAGE_SUCCESS]: updateImageDetailSuccess,
  [Types.APPROVE_ORDER_IMAGE_SUCCESS]: approveImageDetailSuccess,
};

export default createReducer(INITIAL_STATE, HANDLERS);
