import { useEffect, useState } from "react";
import { rcManagementService } from "../../../services"

export const useRcOthers = () => {
  const getDelayReasons = rcManagementService.getRcOthersDelayReasons;

  const [delayReasons, setDelayReasons] = useState([]);

  const fetchDelayReasons = () => {
    getDelayReasons()
      .then((resp) => {
        if (resp) {
          setDelayReasons(resp);
        }
      })
  };

  useEffect(() => {
    fetchDelayReasons();
  }, []);

  return { delayReasons };
}