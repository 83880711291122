import { PROCESSING_FEE, DEFAULT_PROCESSING_FEE, NINETEEN_SEVENTY, SHIPPING_CHARGE_KEY, LOAN_INSURANCE, DispositionConstants } from "./Constants";

export const getUpdatedProcessingFee = (processingFee, chargeList) => {
    const item = chargeList.find(x => x.name === PROCESSING_FEE);
    const otherListItem = chargeList.filter(x => x.name !== PROCESSING_FEE);
    const updatedItem = {...item, amount: processingFee};
    const data =  [
        updatedItem,
        ...otherListItem
    ];
    return data;
};

export const getUpdatedProcessingFeeV2 = (processingFee, chargeList) => {
    const item = chargeList.find(x => x.label === PROCESSING_FEE);
    const otherListItem = chargeList.filter(x => x.label !== PROCESSING_FEE);
    const otherItems = otherListItem.map(x => { return {...x, name: x.label, amount: x.value}});
    const updatedItem = {...item, amount: processingFee, name: item.label};
    const data =  [
        updatedItem,
        ...otherItems
    ];
    return data;
};


export const getAdditionalChargeAmount = (list, key) => {
    const item = !!list && list.find(x => x.key === key);
    if (item) {
        return item.amount;
    }
    return 0;
};

export const getCreateChargeDetails = (processingFee, chargeList) => {
    let chargeDetails = chargeList.map((item) => {
        return { amount: item.value, description: item.label, name: item.label };
    });
    const item = chargeDetails.find(x => x.name === PROCESSING_FEE);
    const otherListItem = chargeDetails.filter(x => x.name !== PROCESSING_FEE);
    const updatedItem = {...item, amount: processingFee};
    const data =  [
        updatedItem,
        ...otherListItem
    ];
    return data;
};

export const getUpdatedLoadInsuranceInChargeList = (loanInsurance, chargeList) => {
    const updatedItem = { name: LOAN_INSURANCE, amount: loanInsurance, key: LOAN_INSURANCE, description: LOAN_INSURANCE};
    const data = [
        ...chargeList,
        updatedItem
    ];
    return data;
};

export const updatedLoadInsuranceInChargeList = (loanInsurance, chargeList) => {
    const item = chargeList.find(x => x.name === LOAN_INSURANCE);
    const otherListItem = chargeList.filter(x => x.name !== LOAN_INSURANCE);
    const updatedItem = {...item, amount: loanInsurance};
    const data =  [
        ...otherListItem,
        updatedItem
    ];
    return data;
};
  
export const getProcesingFeeValueFromList = (chargeList) => {
    const item = (chargeList || []).find(x => x.label === PROCESSING_FEE);
    return item ? item.value : DEFAULT_PROCESSING_FEE;
};

export const getTotalCharges = (list) => {
    if (!list) {
        return 0;
    }
    return list.reduce((acc, curr) => {
        if (curr.operation) {
            return acc + Math.round(+curr.operation);
        }
        return 0;
    }, 0);
};
  
export const getTotalChargesAmount = (list) => {
    if (!list) {
        return 0;
    }
    const otherListItem = list.filter(x => x.name !== LOAN_INSURANCE);
    return otherListItem.reduce((acc, curr) => {
        if (curr.amount) {
            return acc + Math.round(+curr.amount);
        }
        return 0;
    }, 0);
};

export const sumChargesAmount = (list) => {
    if (!list) {
        return 0;
    }
    return list.reduce((acc, curr) => {
        if (curr.amount) {
            return acc + Math.round(+curr.amount);
        }
        return 0;
    }, 0);
};

export const calculateProcessingFee = (vehiclePrice, downPayment, processingFee) => {
    const price = +vehiclePrice - +downPayment;
    const fee = processingFee * price;
    return Math.round(fee);
};

export const calculateProcessingV2 = (vehiclePrice, downPayment, processingFee) => {
    const price = +vehiclePrice - +downPayment;
    const stepOne = 1 - processingFee;
    const stepTwo = processingFee / stepOne;
    const fee = price * stepTwo;
    return Math.round(fee);
};

export const getLoanAmountWithCharges = (totalCharges, vehiclePrice, downPayment) => {
    return Math.round((+vehiclePrice - +downPayment) + +totalCharges);
};

export const calculateLoanInsurance = (loadInsurance, vehiclePrice, downPayment, fixedCharges) => {
    const maxLoanAmount = vehiclePrice - downPayment;
    const liValue = (loadInsurance) * ((maxLoanAmount + fixedCharges) / (1 - loadInsurance));
    return liValue;
};

export const calculateDLA = (carPrice, downPayment) => {
    const DLA = carPrice - downPayment;
    return DLA;
};

export const calculateCLA = (DLA, PF) => {
    /*
    * DLA - Disbursal Loan Amount
    * PF - Processing Fee
    */
    const CLA = DLA * PF / (1 - PF);
    return CLA;
};

export const getTotalLoanAmt = (CLA, FC, LI) => {
    /*
    * CLA - Car loan amount
    * FC - Fixed Charges(CIBIL + Documentation + Stamp + Valuation + Car health monitor)
    * LI - loan insurance
    */
    const TLA = (CLA + FC) / (1 - LI);
    return TLA;
};

export const calculateOverallTotalLoan = (carPrice, downPayment, processingFee, charges, loanInsurance) => {
    const DLA = calculateDLA(carPrice, downPayment);
    const CLA = calculateCLA(DLA, processingFee);
    const totalaLoan = getTotalLoanAmt(CLA, charges, loanInsurance);
    return totalaLoan;
};

export const getDownpaymentRange = (creditScoreList, interestRate, listingPrice) => {
    let applicableROI = interestRate;
    if (interestRate < 14) {
        applicableROI = 12;
    } else if (interestRate > 13 && interestRate < 16) {
        applicableROI = 14;
    } else if (interestRate > 15 && interestRate < 24) {
        applicableROI = 16;
    } else {
        applicableROI = 24;
    }
    const item = creditScoreList.find(x => x.roi === applicableROI);
    const {minDownPayment, maxDownPayment} = item;
    const minValue = Math.round(minDownPayment*0.01*listingPrice);
    const maxValue = Math.round(maxDownPayment*0.01*listingPrice);
    return {minDownPayment : minValue, maxDownPayment: maxValue};
};

export const calculateAge = (birthday) => { // birthday is a date
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - NINETEEN_SEVENTY);
};

export const getShippingCharge = (charges) => { // birthday is a date
    const item = (charges || []).find(x => x.key === SHIPPING_CHARGE_KEY);
    return Number(!!item ? item.amount : 0);
};

export const getCarAppointmentId = (url) => {
    const carUrlArray = url.trim() ? url.split('-') : [];
    if (carUrlArray.length > 0) {
        const apntmntId = carUrlArray[carUrlArray.length - 1].replace('/', '');
        const aptId = apntmntId.includes("?") ? apntmntId.substring(0, apntmntId.indexOf("?")) : apntmntId;
        return aptId;
    }
    return 0;
};

export const removeArrayItem = (array, in_Item) => {
    const itemIndex = array.findIndex((item) => item === in_Item);
    if (itemIndex > -1) {
        array.splice(itemIndex, 1);
    }
};

export const getLeadType = () => {
    let leadType = DispositionConstants.BUYER;
    if (window.location.pathname.includes('seller')) {
        leadType = DispositionConstants.SELLER;
    } else if (window.location.pathname.includes('cc-inbound-buyer')) {
        leadType = DispositionConstants.CC_INBOUND_BUYER;
    } else if (window.location.pathname.includes('cc-outbound-missedcall-buyer')) {
        leadType = DispositionConstants.CC_MISSED_CALL;
    } else if (window.location.pathname.includes('cc-outbound-buyer') || window.location.pathname.includes('ccob-post-booking')) {
        leadType = DispositionConstants.CC_OUTBOUND_BUYER;
    } else if (window.location.pathname.includes('ccob-assisted-booking') || window.location.pathname.includes('ccob-pre-booking')) {
        leadType = DispositionConstants.CCOB_ASSISTED_BOOKING;
    } else if (window.location.pathname.includes('ccob-post-delivery')) {
        leadType = DispositionConstants.CC_MISSED_CALL;
    } else if (window.location.pathname.includes('ccob-lku-post-booking')) {
        leadType = DispositionConstants.CC_OUTBOUND_BUYER;
    } else if (window.location.pathname.includes('ccob-lku-pre-booking')) {
        leadType = DispositionConstants.CCOB_ASSISTED_BOOKING;
    } 
    return leadType;
};

export const getLocalDateTime = (value) => {
    if (!value)
        return value;
    return new Date(value).toLocaleString();
};

export const getDiscountPrice = (orderReward) => {
    let discount = 0;
    if (!!orderReward) {
        const {orderPrice=0, finalPrice=0} = orderReward;
        discount = orderPrice*1.0-finalPrice*1.0;
    }
    return discount * 1.0;
};

export const roundTo2Decimal = (amount) => {
    if (!amount) return amount;
    return Math.round(amount * 100) / 100;
};

export const isNumeric = (value) => {
    return !isNaN(value) && !isNaN(parseFloat(value));
};
