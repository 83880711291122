import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  isLoading: false,
  isUpdating: false,
  isError: false,
  message: null,
  sellerData: {}
};

export const fetchOrderDetailInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true, isError: false, message: null };
};

export const fetchOrderDetailSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return { ...state, isLoading: false, isError: false, sellerData: data };
};

export const fetchOrderDetailFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    isLoading: false,
    isError: true
    // message: error.data.message
  };
};

export const updateCarSourceInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};
export const updateCarSourceSuccess = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

export const updateCarSourceFail = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

export const updateSellerInit = (state = INITIAL_STATE) => {
  return { ...state, isUpdating: true };
};
export const updateSellerSuccess = (state = INITIAL_STATE) => {
  return { ...state, isUpdating: false };
};

export const updateSellerFail = (state = INITIAL_STATE) => {
  return { ...state, isUpdating: false };
};

export const HANDLERS = {
  [Types.FETCH_ORDER_SELLER_INIT]: fetchOrderDetailInit,
  [Types.FETCH_ORDER_SELLER_SUCCESS]: fetchOrderDetailSuccess,
  [Types.FETCH_ORDER_SELLER_FAILURE]: fetchOrderDetailFailure,

  [Types.UPDATE_CAR_SOURCE_INIT]: updateCarSourceInit,
  [Types.UPDATE_CAR_SOURCE_SUCCESS]: updateCarSourceSuccess,
  [Types.UPDATE_CAR_SOURCE_FAIL]: updateCarSourceFail,

  [Types.UPDATE_SELLER_INIT]: updateSellerInit,
  [Types.UPDATE_SELLER_SUCCESS]: updateSellerSuccess,
  [Types.UPDATE_SELLER_FAIL]: updateSellerFail
};

export default createReducer(INITIAL_STATE, HANDLERS);
