import React, { Fragment } from 'react';
import './style.css';
import { getformattedDate } from '../../../shared/utils/datesHelper';
import CustomButton from '../../../shared/components/button';
import FinanceRefundCancelledModal from '../finance-cancel-refund-modal';

const Logs = ({
  label = '',
  logs,
  headers,
  showCancelButton,
  dealId,
  userType
}) => {
  return (
    <>
      {label && (
        <p>
          <b>{label}</b>
        </p>
      )}
      <table className='table remarksLog publish-log'>
        <thead>
          <tr>
            {headers.map((item, index) => {
              return (
                <th
                  key={`logs_header_${index}`}
                  className={item.colWidth ? item.colWidth : ''}
                >
                  {item.label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(logs) &&
            logs.map((log, index) => {
              return (
                <Fragment key={`logs_row_${index}`}>
                  <tr >
                    {headers.map((item, itemIndex) => {
                      const value = log[item.headerKey];
                      switch (item.dataType) {
                        case 'button':
                          return <CustomButton label='Cancel' />;
                        case 'datetime':
                        case 'date':
                          return <td
                          key={`log_${itemIndex}`}
                          className={item.colWidth ? item.colWidth : ''}
                        >
                          {getformattedDate(value, item.dataType)}
                        </td>
                        
                        default:
                          return (
                            <td
                              key={`log_${itemIndex}`}
                              className={item.colWidth ? item.colWidth : ''}
                            >
                              {item.headerKey === 'amount' ? 
                              <span className={log['trx_type'] === 'credit' ? 'credit': 'debit'}>
                                {parseFloat(value) ? parseFloat(value).toLocaleString("hi-IN") : ''}
                              </span>
                               : value}
                            </td>
                          );
                      }
                    })}
                    {log.trx_mode === 'Refund' && !log.is_refunded && !log.delivered ? (
                      <td className='col-2 cancel-refund text-center'>
                        <FinanceRefundCancelledModal
                          id={log.id}
                          dealId={dealId}
                          userType={userType}
                        />
                      </td>
                    ) : null}
                  </tr>
                </Fragment>
              );
            })}
        </tbody>
      </table>
    </>
  );
};

export default Logs;
