import React from 'react';

export const CInputLabelField = ({
  className = 'form-control',
  label,
  type = 'text',
  name,
  value,
  onChange,
  required,
}) => {
  return (
    <>
      {!!label ? <label className='font-weight-bold'>{label}</label> : null}
      <input
        className={className}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
      />
    </>
  )
}