import React from 'react';
import { Spinner } from 'reactstrap';
import './styles.css';

const Wrapper = ({
  item,
  isLoading = false,
  children,
  containerClass = 'row'
}) => {
  const { label, keyName, required = false } = item;
  return (
    <div className={containerClass}>
      <label className='col-5 justify-content-start' htmlFor={keyName}>
        <div className='seller-label'>
          {label} {required ? ' *' : ''}
        </div>
      </label>
      <div className='col-7'>
        {isLoading ? (
          <Spinner variant='primary' animation='border' size='sm' />
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export { Wrapper };
