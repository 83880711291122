import { config } from '../config/local';
import { getFromLocalStorage } from '../../shared/utils/helper';
import { buildQuery } from '../../services/helper';
import { IS_MOCK, IS_ENV_DEV } from '../../shared/utils/constants';

export default (api) => {
  const addUser = (payload) => {
    const path = config.api.c2c.addUser.path;
    return api.post(path, payload, {
      headers: { 'User-Id': getFromLocalStorage('userId') }
    });
  };

  const editUser = (id, params) => {
    const path = config.api.c2c.userEdit.path;
    const url = `${path}/${id}`;
    return api.put(url, params);
  };

  const fetchUserById = (id) => {
    return api.get(`${config.api.c2c.user.path}/${id}`);
  };


  const fetchUserGridInfo = () => {
    const url = config.api.c2c.allUser.path;
    return api.get(url);
  };

  const updateUsers = (fileObject) => {
    const url = config.api.c2c.updateUsers.path;
    return api.put(url, fileObject);
  };

  return {
    fetchUserById,
    addUser,
    editUser,
    fetchUserGridInfo,
    updateUsers
  };
};
