import Types from '../constants/actionTypes';
import { storeApi } from './../service/api';
import { DataService } from '../../client/service';
import { showToastMessages } from '../../shared/utils/helper';
import {
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected,
  handleHttpStatusError
} from '../../services/helper';
import { storeService } from '../../services';

//Fetch store details

const fetchStoreDetailSuccess = (data) => ({
  type: Types.ACTION_FETCH_STORE_SUCCESS,
  data
});

const fetchStoreDetailFail = (error) => ({
  type: Types.ACTION_FETCH_STORE_FAIL,
  error
});

const fetchStoreDetail = (storeId) => (dispatch) => {
  // dispatch(fetchStoreDetailInit());
  dispatchSetLoaderConnected();
  return new Promise((resolve, reject) => {
    storeService
      .fetchStoreDetails(storeId)
      .then((resp) => {
        resolve(resp.data.data);
        dispatchResetLoaderConnected();
        dispatch(fetchStoreDetailSuccess(resp.data.data));
      })
      .catch((error) => {
        handleHttpStatusError(error, true);
        dispatch(fetchStoreDetailFail(error));
        reject(error);
      });
  });
};

const addStoreInit = () => ({
  type: Types.ACTION_ADD_STORE_INIT
});

const addStoreSuccess = (data) => ({
  type: Types.ACTION_ADD_STORE_SUCCESS,
  data
});

const addStoreFail = (error) => ({
  type: Types.ACTION_ADD_STORE_FAIL,
  error
});

const addStore = (data) => (dispatch) => {
  dispatch(addStoreInit());
  return new Promise((resolve, reject) => {
    storeApi
      .addStore(data)
      .then((resp) => {
        resolve(resp);
        showToastMessages(resp.message);
        dispatch(addStoreSuccess(resp));
      })
      .catch((error) => {
        dispatch(addStoreFail(error));
        reject(error);
      });
  });
};

const fetchAllStoresInit = () => ({
  type: Types.ACTION_FETCH_ALL_STORES_INIT
});

const fetchAllStoresSuccess = (data) => ({
  type: Types.ACTION_FETCH_ALL_STORES_SUCCESS,
  data
});

const fetchAllStoresFail = (error) => ({
  type: Types.ACTION_FETCH_ALL_STORES_FAIL,
  error
});

const fetchAllStores = (data) => (dispatch) => {
  dispatch(fetchAllStoresInit());
  return new Promise((resolve, reject) => {
    storeApi
      .getStores(data)
      .then((resp) => {
        resolve(resp);
        dispatch(fetchAllStoresSuccess(resp));
      })
      .catch((error) => {
        dispatch(fetchAllStoresFail(error));
        reject(error);
      });
  });
};
const fetchAllZones = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    DataService.fetchZonesList()
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fetchStores = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    storeApi.default.fetchStores(data)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//export all usable actions
export {
  fetchStoreDetail,
  addStore,
  fetchAllStores,
  fetchAllZones,
  fetchStores
};
