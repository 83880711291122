
import React, { useState } from "react";
import InputText from "../../../../shared/components/input-text";
import styles from "./styles.module.css";
import Button from "../../../../shared/components/button";
import { DataService } from '../../../service';
import { SelectComponent } from '../../shared';
import {showToastMessages} from '../../../../shared/utils/helper';
import { getCarAppointmentId } from "../utility";
const BookingAssistDetails = ({ buyerData, bookingData, setSelectBookingItem, selectBookingItem, form, orderData, setShowLoader
  , isCCOBAssitBooking, isFormDisabled }) => {
    const { state, dispatch } = form;
    const {userId, userLocation} = orderData || {};
    const bookingList = !!bookingData && bookingData.map((item) => { return ({ value: item.bookingId, label: `${item.bookingId} - ${item.status}` }) });
    const { carUrl, carAvailability, bookingDate, visitStatus, make, model, listingPrice, carCity, currentYardLocation
      , carTransitStatus = '', carLastLocation = ''} = buyerData;
    // const { carUrl, carAvailability, bookingDate, visitStatus, make, model, listingPrice, carCity, currentYardLocation
    //   , carTransitStatus = '', carCurrentLocation = '', driverAllocationStatus = '' } = buyerData;
    let defaultObj = !!selectBookingItem && {label: `${selectBookingItem.bookingId} - ${selectBookingItem.status}` , value: selectBookingItem.bookingId};
    const [carURL, setCarURL] = useState("");
    const onClickOpenLink = () => window.open(carUrl);

    const onClickShareURLLink = () => {
      const {phone} = buyerData || {};
      DataService.shareLinkURL(carURL, phone || state.buyerPhone);
      const carAppointmentId = getCarAppointmentId(carURL);
      if (!state.sharedCarLink.includes(carURL)) {
        dispatch({
          type: "sharedCarLink",
          sharedCarLink: [...state.sharedCarLink, carURL]
        });
      }
      if (carAppointmentId > 0 && !state.sharedAppIds.includes(carAppointmentId)) {
        dispatch({
          type: "sharedAppIds",
          sharedAppIds: [...state.sharedAppIds, carAppointmentId]
        });
      }
    };

    const onAppointmentInitiateBooking = () => {
      const carUrlArray = carURL.trim() ? carURL.split('-') : [];
      if (carUrlArray.length > 0) {
        const apntmntId = carUrlArray[carUrlArray.length - 1].replace('/', '');
        const aptId = apntmntId.includes("?") ? apntmntId.substring(0, apntmntId.indexOf("?")) : apntmntId;
        if (!bookingData && !state.ppUserId) {
          DataService.createUser(state.buyerPhone, state.name, state.email).then((resp) => {
            dispatch(
              {
                type: 'ppUserId',
                ppUserId: resp.uid
              }
            )
            bookingInitiate(aptId, resp.uid, state.buyerPincode);
          });
        } else {
          bookingInitiate(aptId, userId || state.ppUserId, userLocation || state.buyerPincode);
        }
      } else {
        showToastMessages("Invalid URL: ", carURL);
      }
    };

    const bookingInitiate = (aptId, buyerId, buyerLocation) => {
      if (!!aptId && !!buyerId && !!buyerLocation) {
        const payload = {
          "appointmentId": aptId,
          "userId": buyerId,
          "userLocation": buyerLocation,
          "phone": state.buyerPhone
        };
        setShowLoader(false);
        DataService.postAppointmentInitiateBooking(payload)
          .then((resp) => {
            const {data: {orderId}} = resp || {};
            setTimeout(()=>{
              const currentBookingItem = !!bookingData && bookingData.find((item) => item.bookingId === orderId)
              if (!!currentBookingItem) {
                setSelectBookingItem(currentBookingItem);
              } else {
                setSelectBookingItem(null);
              }
              setShowLoader(true);
            }, 2000);
          })
          .catch(err => {
            console.log("err", err);
          });
      } else {
        showToastMessages("Either car URL or Buyer Pincode is missing");
      }
    };

    const onChangeCarURL = (e) => {
      e.preventDefault();
      const { value } = e.target;
      setCarURL(value);
    };
    const onBookingIdChange = (item) => {
      const currentBookingItem = bookingData.find(x => x.bookingId === item);
      setSelectBookingItem(currentBookingItem);
    };
    return (
      <React.Fragment>
        <div className='row'>
          <h2 className={styles.title}>Booking Details</h2>
        </div>
        <div className='row'>
          <div className='col-2 form-group disp-box d-flex  align-items-center'>
            <label for='availability' className='lbl'>
              Booking Date :
            </label>
          </div>
          <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
          <InputText
              type='text'
              className='form-control'
              name={'availability'}
              value={bookingDate}
              disabled={true}
            />
          </div>
          <div className='col-2 form-group disp-box d-flex  align-items-center'>
            <label for='availability' className='lbl'>
              Booking Id :
            </label>
          </div>
          <div className={`col-4 form-group disp-box`}>
            {!!selectBookingItem && <SelectComponent 
              key={JSON.stringify(selectBookingItem.bookingId)}
              name={JSON.stringify(selectBookingItem.bookingId)}
              value={selectBookingItem.bookingId}
              defaultValue={defaultObj}
              onChange={onBookingIdChange}
              optionsList={bookingList}
              isClearable={false}
              hideSelectedOptions={false}
            />}
          </div>
        </div>
        {!isCCOBAssitBooking && <div className="row">
          <div className='col-2 form-group disp-box d-flex  align-items-center'>
            <label for='bookingStatus' className='lbl'>
              Booking Status :
            </label>
          </div>
          <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
            <InputText
                type='text'
                className='form-control'
                name={'bookingStatus'}
                value={state.bookingStatus}
                disabled={true}
              />
          </div>
          <div className='col-2 form-group disp-box d-flex  align-items-center'>
            <label for='visitStatus' className='lbl'>
              Visit Status :
            </label>
          </div>
          <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
          <InputText
              type='text'
              className='form-control'
              name={'visitStatus'}
              value={visitStatus}
              disabled={true}
            />
          </div>
        </div>}
        <div className="row">
          <div className='col-2 form-group disp-box d-flex  align-items-center'>
            <label for='availability' className='lbl'>
              Make :
            </label>
          </div>
          <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
            <InputText
                type='text'
                className='form-control'
                name={'availability'}
                value={make}
                disabled={true}
              />
          </div>
          <div className='col-2 form-group disp-box d-flex  align-items-center'>
            <label for='availability' className='lbl'>
              Model :
            </label>
          </div>
          <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
          <InputText
              type='text'
              className='form-control'
              name={'availability'}
              value={model}
              disabled={true}
            />
          </div>
        </div>
        {!isCCOBAssitBooking && <div className="row">
          <div className='col-2 form-group disp-box d-flex  align-items-center'>
            <label for='carTransitStatus' className='lbl'>
              Car Transit Status :
            </label>
          </div>
          <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
            <InputText
                type='text'
                className='form-control'
                name={'carTransitStatus'}
                value={carTransitStatus}
                disabled={true}
              />
          </div>
          <div className='col-2 form-group disp-box d-flex  align-items-center'>
            <label for='carLastLocation' className='lbl'>
              Car Current Location :
            </label>
          </div>
          <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
            <InputText
                type='text'
                className='form-control'
                name={'carLastLocation'}
                value={carLastLocation}
                disabled={true}
              />
          </div>
        </div>}
        <div className="row">
          <div className='col-2 form-group disp-box d-flex  align-items-center'>
            <label for='availability' className='lbl'>
              Listing Price :
            </label>
          </div>
          <div className={`col-4 form-group disp-box d-flex align-items-center inputWrapperSign ${styles.inputWrapper}`}>
            <InputText
                type='text'
                className='form-control'
                name={'availability'}
                value={listingPrice}
                disabled={true}
              />
          </div>
          <div className='col-2 form-group disp-box d-flex  align-items-center'>
            <label for='availability' className='lbl'>
              Car City :
            </label>
          </div>
          <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
            <InputText
                type='text'
                className='form-control'
                name={'availability'}
                value={carCity}
                disabled={true}
              />
          </div>
        </div>
        <div className="row">
          <div className='col-2 form-group disp-box d-flex align-items-center'>
            <label for='url' className='lbl'>
              Car Listing URL :
            </label>
          </div>
          <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
            <Button label={"Open Link"} onClick={onClickOpenLink} />
          </div>
          <div className='col-2 form-group disp-box d-flex  align-items-center'>
            <label for='availability' className='lbl'>
              Car Availability :
            </label>
          </div>
          <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
            <InputText
                type='text'
                className='form-control'
                name={'availability'}
                value={carAvailability}
                disabled={true}
              />
          </div>
        </div>
        {isCCOBAssitBooking && <div className="row">
          <div className='col-2 form-group disp-box d-flex align-items-center'>
            <label for='url' className='lbl'>
              Car URL :
            </label>
          </div>
          <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
            <InputText
                type='text'
                className='form-control'
                name={'shareLinkURL'}
                onChange={onChangeCarURL}
                value={carURL}
                disabled={isFormDisabled}
              />
          </div>
          <div className={`col-6 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
            <Button label={"Share Link"} onClick={onClickShareURLLink} disabled={isFormDisabled}/>
            <Button label={"Initiate Booking"} onClick={onAppointmentInitiateBooking} disabled={isFormDisabled}/>
          </div>
        </div>}
        <div className="row">
          <div className='col-2 form-group disp-box d-flex  align-items-center'>
            <label for='currentYardLocation' className='lbl'>
            Current Yard Location :
            </label>
          </div>
          <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
            <InputText
                type='text'
                className='form-control'
                name={'currentYardLocation'}
                value={currentYardLocation}
                disabled={true}
              />
          </div>
          {/* {!isCCOBAssitBooking && <div className='col-2 form-group disp-box d-flex  align-items-center'>
            <label for='driverAllocationStatus' className='lbl'>
              Driver Allocation Status :
            </label>
          </div>}
          {!isCCOBAssitBooking && <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
            <InputText
                type='text'
                className='form-control'
                name={'driverAllocationStatus'}
                value={driverAllocationStatus}
                disabled={true}
              />
          </div>} */}
        </div>
      </React.Fragment>
    );
};

export default BookingAssistDetails;
