import {
  getUploadAllocationEndpoint,
  EXCLUDE_USER_STATUS,
  ROLE_TYPE,
  DELIVERY_MODES
} from '../components/shared/Constants';
import {
  showToastMessages,
  catchErrorMessage,
  getFromLocalStorage
} from '../../shared/utils/helper';
import {
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected,
  handleHttpStatusError
} from '../../services/helper';

import qs from 'querystring';
import { APP_SOURCE } from '../../shared/utils/constants';

export default (api) => {
  const fetchZonesList = (params = {}) => {
    return api.get('secure/zone', params);
  };

  const fetchSpecificZone = (zoneId, params = {}) =>
    api.get(`secure/zone/${zoneId}`, params);

  const addZone = (params) => api.post('secure/zone', params);

  const fetchSellerData = (params) => {
    const queryString = `?sessionId=${params.sessionId}&campaignId=${params.campaignId}&crtObjectId=${params.crtObjectId}&userCrtObjectId=${params.userCrtObjectId}&userId=${params.userId}&customerId=${params.customerId}&phone=${params.phone}&agentName=${params.agentName}&dispositionCode=${params.dispositionCode}&dispositionAttr=${params.dispositionAttr}&lms_lead_id=${params.lms_lead_id}`;
    return api
      .get(`secure/seller/appointments/${params.appointment_id}${queryString}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        console.log({ error });
        return error;
      });
  };

  const fetchBuyerData = (params) => {
    const queryString = `?sessionId=${params.sessionId}&campaignId=${params.campaignId}&crtObjectId=${params.crtObjectId}&userCrtObjectId=${params.userCrtObjectId}&userId=${params.userId}&customerId=${params.customerId}&phone=${params.phone}&agentName=${params.agentName}&dispositionCode=${params.dispositionCode}&dispositionAttr=${params.dispositionAttr}&lms_lead_id=${params.lms_lead_id}`;
    return api
      .get(`secure/buyer/appointments/${params.buyer_id}${queryString}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        console.log({ error });
        return error;
      });
  };

  const fetchInboundBuyerData = (buyerPhone) => {
    return api
      .get(`/secure/customer/phone/${buyerPhone}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        console.log({ error });
        return error;
      });
  };

  const fetchCCOBBookingData = (bookingId) => {
    return api
      .get(`/secure/booking-details/${bookingId}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        console.log({ error });
        return error;
      });
  };

  const fetchCCOBCustomerBookings = (customerPhone, campaignType) => {
    return api
      .get(`/secure/bookings/${customerPhone}?campaignType=${campaignType}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        console.log({ error });
        return error;
      });
  };
  
  const fetchLeadInteractionHistory = (customerPhone) => {
    return api
      .get(`/secure/lead-interaction/${customerPhone}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        console.log({ error });
        return error;
      });
  };

  const fetchCityStateByPinCode = (pincode) => {
    return api
      .get(`/secure/city-state/${pincode}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        console.log({ error });
        return error;
      });
  };

  const updateCCOBBookingData = (params) => {
    return api
      .put(`/secure/booking-details`, params)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        console.log({ error });
        return error;
      });
  };

  const updateCCOBBooking = (params) => {
    return api
      .put(`/secure/booking-details`, params)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error;
      });
  };

  const fetchCCOBBookingOrderSummary = (bookingId) => {
    return new Promise((resolve, reject) => {
      api
      .get(`/secure/order-summary/${bookingId}`)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
    });
  };

  const fetchPincodeData = (appointmentId, pincode) => {
    return api
      .get(`/secure/locations/${appointmentId}?pincode=${pincode}`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error;
      });
  };

  const fetchInboundBuyerDisposition = (buyerPhone) => {
    return api
      .get(`/secure/cc-ib-state`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        console.log({ error });
        throw error;
      });
  };

  const fetchOutboundMissedCallBuyerDisposition = (buyerPhone) => {
    return api
      .get(`/secure/cc-ob-missedcall-state`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        console.log({ error });
        throw error;
      });
  };

  const fetchOutboundBuyerDisposition = (buyerPhone) => {
    return api
      .get(`/secure/cc-ob-state`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        console.log({ error });
        throw error;
      });
  };

  const fetchCCOBAssistBookingState = () => {
    return api
      .get(`/secure/cc-ob-assist-state`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        console.log({ error });
        throw error;
      });
  };

  const fetchDispositions = (leadType) => {
    return api
      .get(`/secure/dispositions/${leadType}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        console.log({ error });
        throw error;
      });
  };

  const shareLinkBookingAssistBuyer = (id, name, phone) => {
    return api
      .put(`secure/share-link/${id}?customerPhone=${phone}&name=${name}&interactionSource=${getFromLocalStorage(APP_SOURCE)}`)
      .then((resp) => {
        showToastMessages(resp.data.message, true, 5000);
        return resp.data;
      })
      .catch((error) => {
        showToastMessages(error.message, false, 5000);
        catchErrorMessage(error);
        return error;
      });
  };

  const fetchCarData = (appointmentId) => {
    return api
      .get(`secure/car-details/${appointmentId}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        console.log({ error });
        return error;
      });
  };

  const shareLinkURL = (currentCarURL, phone) => {
    const carUrlArray = currentCarURL && currentCarURL.trim() ? currentCarURL.split('-') : [];
    if (carUrlArray && carUrlArray.length) {
      const apntmntId = carUrlArray[carUrlArray.length - 1].replace('/', '');
      const aptId = apntmntId.includes("?") ? apntmntId.substring(0, apntmntId.indexOf("?")) : apntmntId;
      // console.log("car, apntmntId", apntmntId, ", ", aptId);
      if (aptId > 0) { 
        fetchCarData(aptId).then(resp => {
          // console.log("Car data:", resp);
          const {make, model, modelYear, listPrice} = resp || {};
           return api
            .put(`secure/share-car-link?customerPhone=${phone}&make=${make}&model=${model}&year=${modelYear}&listingPrice=${listPrice}&carUrl=${currentCarURL}&interactionSource=${getFromLocalStorage(APP_SOURCE)}`)
            .then((resp) => {
              showToastMessages(resp.data.message, true, 5000);
              return resp.data;
            })
            .catch((error) => {
              showToastMessages(error.message, false, 5000);
              catchErrorMessage(error);
              return error;
            });
        });
      } else {
        // console.log("Invalid URL:", currentCarURL);
        showToastMessages("Invalid URL", false);
      }
    } else {
      // console.log("Invalid URL:", currentCarURL);
      showToastMessages("Invalid URL", false);
    }
  };
  const shareLinkURLV2 = (aptId, currentCarURL, phone, leadType, setIsCarShared) => {
    return new Promise((resolve, reject)=>{
      fetchCarData(aptId).then(resp => {
        const {make, model, modelYear, listPrice} = resp || {};
        if (make && model && modelYear && listPrice) {
          api.put(`secure/share-car-link?customerPhone=${phone}&make=${make}&model=${model}&year=${modelYear}&listingPrice=${listPrice}&carUrl=${currentCarURL}&leadStateType=${leadType}&interactionSource=${getFromLocalStorage(APP_SOURCE)}`)
          .then((shareResponse) => {
            resolve(shareResponse.data);
          }).catch((error) => {
            reject(error);
          });;
        } 
      }).catch((error)=>{
        reject(error);
      });
    });
  };

  const submitSellerData = (params) => {
    return api
      .post(`secure/seller/appointments/${params.appId}`, params)
      .then((resp) => {
        // const { data: { data } } = resp;
        // return data;
        console.log('api seller post response', resp);
        return resp.data;
      })
      .catch((error) => {
        console.log({ error });
        return error;
      });
  };

  const submitCustomerEligibility = (bookingId, params) => {
    return new Promise((resolve, reject)=>{
      api.post(`secure/finance-offer/${bookingId}`, params)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
    });
  };

  const validatePancard = (panCard) => {
    return api
      .post(`secure/validate/${panCard}`)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        const {message} = error;
        throw new Error(message);
      });
  };

  const submitCompletedBooking = (bookingId) =>  {
    return new Promise((resolve, reject) => {
      api.post(`secure/complete-booking/${bookingId}?interactionSource=${getFromLocalStorage(APP_SOURCE)}`).then((response) => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
    });
  };

  const submitBuyerData = (params) => {
    // return api.post(`secure/buyer/appointments/${params.appId}`, params).then(resp => {
    return api
      .post(`secure/buyer/appointments`, params)
      .then((resp) => {
        // const { data: { data } } = resp;
        // return data;
        console.log('api buyer post response', resp);
        return resp.data;
      })
      .catch((error) => {
        console.log({ error });
        return error;
      });
  };

  const submitInboundBuyerData = (params) => {
    return api
      .post(`secure/cc-ib/buyer`, params)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log({ error });
        return error;
      });
  };

  const submitOutboundBuyerData = (params) => {
    return api
      .post(`/secure/cc-ob`, params)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log({ error });
        return error;
      });
  };

  const submitCCOBBookingAssistData = (params) => {
    return api
      .post(`/secure/cc-ob-assist`, params)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log({ error });
        return error;
      });
  };

  const submitCCOBPostDelivery = (params) => {
    return api
      .post(`/secure/post-delivery`, params)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log({ error });
        return error;
      });
  };

  const postAppointmentInitiateBooking = (params) => {
    return api
      .post(`/secure/initiate-booking?interactionSource=${getFromLocalStorage(APP_SOURCE)}`, params)
      .then((resp) => {
        showToastMessages(resp.data.message, true, 5000);
        return resp.data;
      })
      .catch((error) => {
        showToastMessages(error.message, false, 5000);
        // console.log({ error });
        return error;
      });
  };

  const sendMessage = (params) => {
    return api.post(`secure/cc-buyer/send-sms`, params).then((resp) => {
      return resp.data;
    });
  };

  const uploadFile = (params, allocationType) => {
    const endPoint = getUploadAllocationEndpoint(allocationType);
    let headers = { 'Content-Type': 'multipart/form-data' };
    return api[endPoint.method](endPoint.path, params, { headers: headers })
      .then((resp) => {
        console.log('api upload post response', resp);
        return resp.data;
      })
      .catch((error) => {
        console.log({ error });
        return error;
      });
  };
  const fetchPerformance = (userId) => {
    return api
      .get(`secure/performance/reports?userId=${userId}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        console.log({ error });
      });
  };
  const fetchPerformanceUser = (role, userId) => {
    return api
      .get(
        `secure/reporters?User-Id=${userId}&role=${role}&excludeUserStatus=${EXCLUDE_USER_STATUS}&roleType=${ROLE_TYPE}&isIncludeSelf=true`
      )
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const fetchZoneId = (id) => {
    dispatchSetLoaderConnected();
    return api
      .get(`secure/zone/${id}`)
      .then((resp) => {
        dispatchResetLoaderConnected();
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        handleHttpStatusError(error, true);
      });
  };

  const editZone = (id, data) => {
    return api
      .put(`secure/zone/${id}/`, data)
      .then((resp) => {
        showToastMessages(resp.data.message);
        return resp.data;
      })
      .catch((error) => {
        showToastMessages(error.message, false);
        catchErrorMessage(error);
        return error;
      });
  };

  const fetchOutboundBuyerData = (params = {}) => {
    const url = `secure/cc-ob?${qs.stringify(params)}`;
    return api
      .get(url)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        console.log({ error });
        return error;
      });
  };

  const fetchBookingDeliverySlots = (bookingId, pinCode, hubId, deliveryMode) => {
    let params = `secure/delivery-slots/${bookingId}?deliveryPincode=${pinCode}&deliveryMode=${deliveryMode}`;
    if (deliveryMode === DELIVERY_MODES.PICK_UP && !!hubId) {
      params = params + `&hubId=${hubId}`
    } 
    return api
      .get(params)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const fetchBankStatementStatus = (userId) => {
    return new Promise((resolve, reject) =>{
      api.get(`secure/bank-statement-status/${userId}`)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        console.log({ error });
        reject(error);
      });
    });
  };

  const postShareBankStatementLink = (userId) => {
    return new Promise((resolve, reject)=> {
      api
      .post(`/secure/bank-statement/${userId}?interactionSource=${getFromLocalStorage(APP_SOURCE)}`)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
    });
  };

  const createUser = (phone, name, email) => {
    return api
      .post(`secure/create/user/${phone}?name=${name}&email=${email}`)
        .then((resp) => {
          const {
            data: { data }
          } = resp;
          return data;
        })
      .catch((error) => {
        return error;
      });
  };

  const cancelDeal = (dealId, data) => {
    return api
      .put(`/secure/cancel/deal/${dealId}`, data)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        showToastMessages("Deal is cancelled successfully.");
        return data;
      })
      .catch((error) => {
        showToastMessages(error.message, false);
        return error;
      })
    };

  const whatsAppOpted = (phone, isOpted) => {
    return api
      .put(`/secure/whatsapp-opt-in/${phone}?optIn=${isOpted}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        showToastMessages(error.message, false, 5000);
        return error;
      })
    };
  
  const fetchCFFormDetails = (userId) => {
    return api
      .get(`secure/cf-form-details/${userId}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        console.log({ error });
        return error;
      });
  };

  const fetchCustomerDetails = (customerPhone, leadType) => {
    return api
    .get(`/secure/customer-details/${customerPhone}?leadStateType=${leadType}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        console.log({ error });
        return error;
      });
  };


  const fetchPostDeliveryBookings = (phone) => {
    return api.get(`secure/post-delivery-bookings/${phone}`).then(response => {
      return response.data.data;
    }).catch(error => {
      console.log({ error });
      return error;
    });
  };

  const fetchPostDeliveryZendeskTickets = (phone) => {
    return api.get(`secure/zendesk-tickets/${phone}`).then(response => {
      return response.data;
    }).catch(error => {
      console.log({ error });
      return error;
    });
  };

  const createZendesksTicket = (bookingId, type) => {
    return api
      .post(`secure/zendesk-request/${bookingId}?type=${type}`)
        .then((resp) => {
          const {
            data: { data }
          } = resp;
          return data;
        })
      .catch((error) => {
        showToastMessages(error.message, false, 5000);
        return error;
      });
  };
  const fetchDeliveryBookings = (customerPhone, leadType) => {
    return api
    .get(`/secure/booking-list/${leadType}?phone=${customerPhone}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        console.log({ error });
        return error;
      });
  };

  const onCreateZendesksTicket = (bookingId, type) => {
    return api.post(`/secure/zendesk-request/${bookingId}?type=${type}`).then(response => {
      return response.data;
    }).catch(error => {
      console.log({ error });
      return error;
    })
  };

  const fetchPostCreditDetails = (userId, appointmentId, listingPrice) => {
    return api.get(`/secure/post-credit-offer/${userId}?appointmentId=${appointmentId}&vehiclePrice=${listingPrice}`).then(response => {
      return response.data.data;
    }).catch(error => {
      console.log({ error });
      return error;
    })
  };

  const shareDocs = (id, docType, url, email, appointmentId) => {
    return api.post(`/secure/share/${id}?docType=${docType}&url=${encodeURIComponent(url)}&emailId=${email}&appointmentId=${appointmentId}`).then(response => {
      return response.data;
    }).catch(error => {
      console.log({ error });
      return error;
    });
  };

  const updateRepairTickets = (id, params)=> {
    return new Promise((resolve, reject) => {
      api.put(`/secure/zendesk-update-repair-ticket/${id}`, params)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
    });
  };

  const getBuyerProfile = (customerPhone, userId, leadType) => {
    return api
    .get(`/secure/buyer-flags/${customerPhone}?leadStateType=${leadType}&userId=${userId}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        return error;
      });
  };

  const getIndependentCampaign = (value, type) => {
    return api.get(`/secure/campaign/${encodeURIComponent(value)}?type=${type}`).then(response =>{
      return response.data;
    }).catch(error => {
      return error;
    })
  };
  
  const getRepairTicketsFieldsData = () => {
    return new Promise((resolve, reject) => {
      api.get(`/secure/zendesk/ticket-field-values`)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          });
    });
  };

  const getOperationalCity = (pincode, appointmentId) => {
    return new Promise((resolve, reject) => {
      api.get(`/secure/operational-city/${pincode}?appointmentId=${appointmentId}`)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          });
    });
  };

  const getRepairTicketDetails = (Id) => {
    return new Promise((resolve, reject) => {
      api.get(`/secure/zendesk-ticket/${Id}`)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          });
    });
  };

  const saveRepairTicket = (bookingId, params) => {
    return new Promise((resolve, reject) => {
      api.post(`/secure/zendesk-repair-request/${bookingId}`, params)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          });
    });
  };

  const shareOrderLink = (id, name, phone, leadType) => {
    return api.put(`/secure/share-booking-link?bookingId=${id}&name=${name}&phone=${phone}&leadStateType=${leadType}&interactionSource=${getFromLocalStorage(APP_SOURCE)}`).then(response => {
      return response.data;
    }).catch(error => {
      console.log({ error });
      return error;
    });
  };
  
  const sharePostDeliveryLink = (id, name, phone, leadType) => {
    return api.put(`/secure/share-post-delivery-link?bookingId=${id}&name=${name}&phone=${phone}&leadStateType=${leadType}&interactionSource=${getFromLocalStorage(APP_SOURCE)}`).then(response => {
      return response.data;
    }).catch(error => {
      return error;
    });
  };

  const saveIndependentCCUI = (params) => {
    return api
      .post(`/secure/independent-cc-ui/save`, params)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        return error;
      });
  };

  const getDSOffer = (params) => {
    return new Promise((resolve, reject) => {
      api.post(`/secure/ds-offer`, params).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  };

  const preBookingOffer = (params) => {
    return new Promise((resolve, reject) => {
      api.post(`secure/pre-approved-offer`, params)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const cancelBookingOTP = (id) => {
    return new Promise((resolve, reject)=> {
      api.put(`/secure/cancel-booking-otp/${id}`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  };

  const cancelBookingTokenRequest = (id, reason) => {
    const params = {
      optionKey: reason.key,
      title: reason.title,
      userInput: reason.userInput
    };
    return new Promise((resolve, reject) => {
      api.put(`/secure/token/cancel/${id}?interactionSource=${getFromLocalStorage(APP_SOURCE)}`, params).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  };

  const cancelBooking = (id, otp, reason) => {
    const params = {
      optionKey: reason.key,
      title: reason.title,
      userInput: reason.userInput
    };
    return new Promise((resolve, reject) => {
      api.put(`/secure/cancel/booking/${id}?otp=${otp}&interactionSource=${getFromLocalStorage(APP_SOURCE)}`, params).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  };

  const getCancelBookingReasons = (bookingId, isToken) => {
    if (isToken) {
      return new Promise((resolve, reject) => {
        api.get(`/secure/token-cancel-reasons/${bookingId}`).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    } else {
      return new Promise((resolve, reject) => {
        api.get(`/secure/cancel-booking-reasons/${bookingId}`).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    }
  };

  const fetchPostDeliveryDetails = (Id, appointmentId) => {
    return new Promise((resolve, reject) => {
      api.get(`/secure/post-delivery-details/${Id}?appointmentId=${appointmentId}`).then(response => {
        resolve(response.data.data);
      }).catch(error => {
        reject(error);
      });
    });
  };

  const onIndependentRequestCallBack = (phone, language) => {
    return new Promise((resolve, reject)=> {
      api.post(`/secure/webhook/lead?primaryPhone=${phone}&preferredLanguage=${language}&sourceLocation=cc_independent`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  };
  
  const savePostDeliveryData = (Id, params) => {
    return new Promise((resolve, reject) => {
      api.put(`/secure/post-delivery-details/${Id}`, params).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  };
  // secure/trx-logs/ZSQX20BFVU
  const getBookingTransactions = (bookingId) => {
    return new Promise((resolve, reject) => {
      api.get(`/secure/trx-logs/${bookingId}`).then(response => {
        resolve(response.data.data);
      }).catch(error => {
        reject(error);
      });
    });
  };


  const getRecommendCars = (phone, type) => {
    return new Promise((resolve, reject) => {
      api.get(`/secure/recommended-cars/${phone}?campaign=${type}`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      })
    });
  };

  // secure/buyer-insurance/sms/
  const shareInsuranceImage = (id) => {
    return api.post(`/secure/buyer-insurance/sms/${id}`).then(response => {
      return response.data;
    }).catch(error => {
      console.log({ error });
      return error;
    });
  };

  // /secure/recommended-cars-interaction/
  const saveInteraction = (phone,appId) => {
    return api.post(`/secure/recommended-cars-interaction/${phone}?recommendedCarAppId=${appId}&interactionSource=${getFromLocalStorage(APP_SOURCE)}`).then(response => {
      return response.data;
    }).catch(error => {
      console.log({ error });
      return error;
    });
  };

  const fetchLoanStatus = (userId) => {
    return new Promise((resolve, reject) => {
      api.get(`/secure/loan-status?userId=${userId}`).then(response => {
        resolve(response.data.data);
      }).catch(error => {
        reject(error);
      });
    });
  };

  const fetchUserDiscounts = (userId) => {
    return new Promise((resolve, reject) => {
      api.get(`/secure/discount/${userId}`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  };

  const applyDiscount = (bookingId, code, category) => {
    return new Promise((resolve, reject) => {
      api.put(`/secure/apply-discount/${bookingId}/${code}/${category}?interactionSource=${getFromLocalStorage(APP_SOURCE)}`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  };

  const uploadBankStatement = (userId, selectedBankId, passwordRequired, password, params) => {
    let headers = { 'Content-Type': 'multipart/form-data' };
    return new Promise((resolve, reject) => {
      api.post(`/secure/upload-bank-statement/${userId}?passwordRequired=${passwordRequired}&password=${password}&bankId=${selectedBankId}&interactionSource=${getFromLocalStorage(APP_SOURCE)}`, params, { headers: headers }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
  const fetchFinanceDetails = (userId) => {
    return new Promise((resolve, reject) => {
      api.get(`/secure/finance-details/${userId}`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  };
  const updateOrderPickupChargeType = (bookingId, chargeType) => {
    return new Promise((resolve, reject) => {
      api.put(`/secure/reservation-option/${bookingId}?chargeType=${chargeType}`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  };

  const uploadLeadFile = (params) => {
    return new Promise((resolve, reject) => {
      api.post(`/secure/upload-lead-file/`,params).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  };

  const getBankList = () => {
    return new Promise((resolve, reject) => {
      api.get(`/secure/bank-list`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  };

  const updateBookingDeliveryAddress = (payload)=> {
    return new Promise((resolve, reject) => {
      api.put(`/secure/booking-address`, payload)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
    });
  };

  const getVehiclePrice = (appointmentId) => {
    return new Promise((resolve, reject) => {
      api.get(`/secure/get-vehicle-price/${appointmentId}`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  };

  const onShareCFFormLink = (mobileNumber) => {
    return new Promise((resolve, reject) => {
      api.put(`secure/share-cf-form-link/${mobileNumber}`)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const fetchDispositionsByLmsLeadId = (lmsLeadId) => {
    return api
      .get(`/secure/disposition-rule/dispositions/${lmsLeadId}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        throw error;
      });
  };

  return {
    onShareCFFormLink,
    getVehiclePrice,
    updateBookingDeliveryAddress,
    updateOrderPickupChargeType,
    getBankList,
    uploadBankStatement,
    fetchFinanceDetails,
    applyDiscount,
    saveInteraction,
    getBookingTransactions,
    fetchLoanStatus,
    onIndependentRequestCallBack,
    savePostDeliveryData,
    shareInsuranceImage,
    getRecommendCars,
    saveIndependentCCUI,
    sharePostDeliveryLink, 
    getDSOffer,
    preBookingOffer,
    getCancelBookingReasons,
    cancelBookingOTP,
    cancelBooking,
    getRepairTicketDetails,
    saveRepairTicket, 
    getOperationalCity,
    getRepairTicketsFieldsData,
    getBuyerProfile, 
    updateRepairTickets,
    getIndependentCampaign,
    updateCCOBBooking,
    shareOrderLink,
    shareLinkURLV2,
    submitCCOBPostDelivery,
    fetchDeliveryBookings,
    createZendesksTicket,
    fetchPostDeliveryZendeskTickets,
    whatsAppOpted,
    fetchDispositions,
    fetchCustomerDetails,
    fetchCFFormDetails,
    createUser,
    cancelDeal,
    validatePancard,
    fetchBankStatementStatus,
    postShareBankStatementLink,
    fetchSellerData,
    submitSellerData,
    submitBuyerData,
    fetchBuyerData,
    fetchCarData,
    sendMessage,
    uploadFile,
    fetchPerformance,
    fetchPerformanceUser,
    fetchZonesList,
    addZone,
    fetchSpecificZone,
    fetchZoneId,
    editZone,
    fetchInboundBuyerData,
    fetchInboundBuyerDisposition,
    submitInboundBuyerData,
    fetchOutboundMissedCallBuyerDisposition,
    fetchOutboundBuyerData,
    submitOutboundBuyerData,
    fetchOutboundBuyerDisposition,
    fetchCCOBBookingData,
    fetchCCOBAssistBookingState,
    shareLinkBookingAssistBuyer,
    shareLinkURL,
    submitCCOBBookingAssistData,
    fetchCCOBBookingOrderSummary,
    submitCustomerEligibility,
    fetchPincodeData,
    updateCCOBBookingData,
    fetchCCOBCustomerBookings,
    fetchLeadInteractionHistory,
    postAppointmentInitiateBooking,
    fetchCityStateByPinCode,
    fetchBookingDeliverySlots,
    fetchPostDeliveryBookings,
    fetchPostDeliveryDetails,
    onCreateZendesksTicket,
    fetchPostCreditDetails,
    shareDocs,
    fetchUserDiscounts,
    submitCompletedBooking,
    uploadLeadFile,
    cancelBookingTokenRequest,
    fetchDispositionsByLmsLeadId,
  };
};
