export const AGENT_FIELDS = [
    {
        inputType: 'select',
        label: 'Agent Name',
        defaultValue: null,
        disabled: false
    },
    {
        inputType: 'text',
        label: 'Agent Cost',
        defaultValue: null,
        disabled: false
    },
    {
        inputType: 'select',
        label: 'Agent Status',
        defaultValue: null,
        disabled: false
    }
]

export const FILE_STATUS = [
    { label: 'File Received', value: 'File Received' },
    { label: 'Handed over to the Agent', value: 'Handed over to the Agent' },
    { label: 'E-Receipt Issued', value: 'E-Receipt Issued' },
    { label: 'RC transfer completed', value: 'RC transfer completed' },
    { label: 'Handed over to Buyer', value: 'Handed over to Buyer' }
]

export const SPEED_POST_DELIVERY_STATUS = [
    { label: 'Dispatch Pending', value: 'Dispatch Pending' },
    { label: 'Dispatched', value: 'Dispatched' }
]

export const AGENT_STATUS = {
    ACTIVE: { label: 'Active', value: 'ACTIVE' },
    RETURNED: { label: 'Returned', value: 'RETURNED' },
    TRANSFERRED: { label: 'Transferred', value: 'TRANSFERRED' },
    PENDENCY_SELLER_CHALLAN: { label: 'Pendency - Seller Challan', value: 'PENDENCY_SELLER_CHALLAN' },
    PENDENCY_BUYER_CHALLAN: { label: 'Pendency - Buyer Challan', value: 'PENDENCY_BUYER_CHALLAN' },
    PENDENCY_BLACKLISTED: { label: 'Pendency - Blacklisted', value: 'PENDENCY_BLACKLISTED' },
    PENDENCY_DOCUMENTATION: { label: 'Pendency - Documentation', value: 'PENDENCY_DOCUMENTATION' },
    PARTIALLY_COMPLETED: { label: 'Partially Completed', value: 'PARTIALLY_COMPLETED' },
    FULLY_COMPLETED: { label: 'Fully Completed', value: 'FULLY_COMPLETED' }
}







export const AGENT_STATUS_TRANSFERRED = 'TRANSFERRED'



