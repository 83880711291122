import { createReducer } from 'reduxsauce';
import Types from './types';
import { getAddConfigState } from './utils';

export const INITIAL_STATE = {
  attributes: {
    ownershipType: [],
    nameCheck: [],
    stateCheck: [],
    districtCheck: [],
    isIntraStateRequired: [],
    caseType: [],
    isCaCallingRequired: []
  },
  isAttributesFetched: false,
  addConfig: {},
  caseTypeConfigs: {
    configsUpdated: [],
    byId: {},
    allIds: []
  }
};

export const fetchAttributesDataSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    attributes: {
      ...state.attributes,
      ...data,
      alternativeDocs: data.documents
    },
    isAttributesFetched: true,
    addConfig: getAddConfigState(data)
  };
};

export const updateAddConfigData = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    addConfig: {
      ...state.addConfig,
      [data.key]: data.value
    }
  };
};

const fetchCaseTypeConfigSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    caseTypeConfigs: {
      ...state.caseTypeConfigs,
      byId: data.byId,
      allIds: data.allIds
    }
  };
};

const updateCaseTypeData = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    caseTypeConfigs: {
      ...state.caseTypeConfigs,
      byId: {
        ...state.caseTypeConfigs.byId,
        [data.id]: {
          ...state.caseTypeConfigs.byId[data.id],
          [data.key]: data.value
        }
      },
      configsUpdated: [
        ...state.caseTypeConfigs.configsUpdated,
        ...(state.caseTypeConfigs.configsUpdated.indexOf(data.id) === -1 ? [data.id]: [])
      ]
    }
  };
};

const resetConfigList = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    caseTypeConfigs: {
      configsUpdated: [],
      byId: {},
      allIds: []
    }
  };
};

export const HANDLERS = {
  [Types.FETCH_ATTRIBUTES_DATA_SUCCESS]: fetchAttributesDataSuccess,
  [Types.UPDATE_ADD_CONFIG_DATA]: updateAddConfigData,
  [Types.FETCH_CASE_TYPE_CONFIG_SUCCESS]: fetchCaseTypeConfigSuccess,
  [Types.UPDATE_CASE_TYPE]: updateCaseTypeData,
  [Types.RESET_CONFIG_LIST]: resetConfigList
};

export default createReducer(INITIAL_STATE, HANDLERS);
