export const CAR_DETAILS = [
  {
    label: 'Appointment Id',
    key: 'appointment_id',
    type: 'text'
  },
  {
    label: 'Registration Number',
    key: 'registration_number',
    type: 'text'
  },
  {
    label: 'Deal Status',
    key: 'deal_status',
    type: 'text'
  },
  {
    label: 'Deal City	',
    key: 'buddy_city',
    type: 'text'
  },
  {
    label: 'Agreed Sales Price',
    key: 'agreed_sales_price',
    type: 'text'
  }
  // {
  //   label: 'Token Date',
  //   key: 'token_date',
  //   type: 'date'
  // }
];

export const BUYER_DETAILS = [
  {
    label: 'Name',
    key: 'buyer_name',
    type: 'text'
  },
  {
    label: 'Primary Number',
    key: 'buyer_number',
    type: 'text'
  },
  {
    label: 'Primary Email',
    key: 'buyer_email',
    type: 'text'
  }
  // {
  //   label: 'Secondary Number',
  //   key: 'buyer_secondary_number',
  //   type: 'text'
  // },
  // {
  //   label: 'Secondary Email',
  //   key: 'buyer_secondary_email',
  //   type: 'text'
  // }
];

export const SELLER_DETAILS = [
  {
    label: 'Name',
    key: 'seller_name',
    type: 'text'
  },
  {
    label: 'Primary Number',
    key: 'seller_number',
    type: 'text'
  },
  {
    label: 'Primary Email',
    key: 'seller_email',
    type: 'text'
  }
  // {
  //   label: 'Secondary Number',
  //   key: 'seller_secondary_number',
  //   type: 'text'
  // },
  // {
  //   label: 'Secondary Email',
  //   key: 'seller_secondary_email',
  //   type: 'text'
  // }
];

export const BUYER_PAYMENT_DETAILS = [
  {
    label: 'Total Expected',
    key: 'total_amount_buyer',
    type: 'text'
  },
  {
    label: 'Total Collected',
    key: 'total_collected_buyer',
    type: 'text'
  },

  {
    label: 'Total Waiver',
    key: 'total_waiver_buyer',
    type: 'text'
  },
  {
    label: 'Loan Amount',
    key: 'buyer_loan_amount',
    type: 'text'
  },
  {
    label: 'Balance',
    key: 'buyer_display_balance',
    type: 'text',
    color: true
  }
];

export const SELLER_PAYMENT_DETAILS = [
  {
    label: 'Total Expected',
    key: 'total_amount_seller',
    type: 'text'
  },
  {
    label: 'Total Collected',
    key: 'total_collected_seller',
    type: 'text'
  },

  {
    label: 'Total Waiver',
    key: 'total_waiver_seller',
    type: 'text'
  },
  {
    label: 'Balance',
    key: 'balance_seller',
    type: 'text',
    color: true
  }
];

export const INPUT_BOX_DETAIL = [
  {
    label: 'Appointment Id',
    key: 'appointment_id',
    type: 'text'
  },
  {
    label: 'Deal Identifier',
    key: 'deal_identifier',
    type: 'text'
  },
  {
    label: 'Registration Number',
    key: 'registration_number',
    type: 'text'
  },
  {
    label: 'Car Source',
    key: 'car_source',
    type: 'text'
  },
  {
    label: 'Buyer Name',
    key: 'buyer_name',
    type: 'text'
  },
  {
    label: 'Buyer Number',
    key: 'buyer_number',
    type: 'text'
  },
  {
    label: 'Buyer Email',
    key: 'buyer_email',
    type: 'text'
  },
  {
    label: 'Total Amount Buyer',
    key: 'total_amount_buyer',
    type: 'text'
  },
  {
    label: 'Total Collected Buyer',
    key: 'total_collected_buyer',
    type: 'text'
  },

  {
    label: 'Total Waiver Buyer',
    key: 'total_waiver_buyer',
    type: 'text'
  },
  {
    label: 'Balance Buyer',
    key: 'balance_buyer',
    type: 'text'
  },
  {
    label: 'Token Date',
    key: 'token_date',
    type: 'date'
  },
  {
    label: 'Buddy Name',
    key: 'buddy_name',
    type: 'text'
  },
  {
    label: 'Buddy Number',
    key: 'buddy_number',
    type: 'text'
  },
  {
    label: 'Expected Delivery Date',
    key: 'expected_delivery_date',
    type: 'date'
  },
  {
    label: 'Actual Delivery Date',
    key: 'actual_delivery_date',
    type: 'date'
  },
  {
    label: 'Agreed Sales Price',
    key: 'agreed_sales_price',
    type: 'text'
  },
  {
    label: 'Deal Status',
    key: 'deal_status',
    type: 'text'
  },
  {
    label: 'TM Email Id',
    key: 'tm_email_id',
    type: 'text'
  },
  {
    label: 'Buddy City',
    key: 'buddy_city',
    type: 'text'
  },
  {
    label: 'Seller Name',
    key: 'seller_name',
    type: 'text'
  },
  {
    label: 'Seller Number',
    key: 'seller_number',
    type: 'text'
  },
  {
    label: 'Seller Email',
    key: 'seller_email',
    type: 'text'
  },
  {
    label: 'Total Amount Seller',
    key: 'total_amount_seller',
    type: 'text'
  },
  {
    label: 'Total Collected Seller',
    key: 'total_collected_seller',
    type: 'text'
  },

  {
    label: 'Total Waiver Seller',
    key: 'total_waiver_seller',
    type: 'text'
  },
  {
    label: 'Balance Seller',
    key: 'balance_seller',
    type: 'text'
  }

  // {
  //   label: 'Seller Validation',
  //   key: 'seller_validation',
  //   type: 'text'
  // },

  // {
  //   label: 'Buyer Validation',
  //   key: 'buyer_validation',
  //   type: 'text'
  // },

  // {
  //   label: 'Payment Status',
  //   key: 'payment_status',
  //   type: 'text'
  // }
];
