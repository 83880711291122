import React from 'react';
import RcTrackerOthersDetail from '../../components/rc-others/rc-management-others-detail';
import withAdminLayout from '../../../shared/hoc/admin-layout';
import ErrorBoundary from '../../components/common/errorBoundary';
import { withAuthorization } from '../../../shared/utils/firebase';

const RcTrackerOthersDetailPage = () => {
  return (
    <div>
      <ErrorBoundary>
        <RcTrackerOthersDetail />
      </ErrorBoundary>
    </div>
  );
};
export default withAdminLayout(
  withAuthorization(() => true)(RcTrackerOthersDetailPage)
);
