import { toast } from 'react-toastify';
import { showToastMessages } from '../../shared/utils/helper';
export default (api) => {
  const getBuddyScoring = (id) => {
    return api
      .get(`secure/scoring/metric/${id}`)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        showToastMessages(error.message, false);
        return error;
      });
  };
  const getBuddyScoringLead = (id) => {
    return api
      .get(`secure/zone/configuration/${id}`)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        showToastMessages(error.message, false);
        return error;
      });
  };
  const submitgetBuddyScoring = (id, data) => {
    return api
      .put(`secure/scoring/metric/${id}/`, data)
      .then((resp) => {
        showToastMessages(resp.data.message);
        return resp.data;
      })
      .catch((error) => {
        showToastMessages(error.message, false);
        return error;
      });
  };
  const submitgetBuddyScoringLead = (id, data) => {
    return api
      .put(`secure/zone/configuration/${id}/`, data)
      .then((resp) => {
        showToastMessages(resp.data.message);
        return resp.data;
      })
      .catch((error) => {
        showToastMessages(error.message, false);
        return error;
      });
  };
  return {
    submitgetBuddyScoring,
    getBuddyScoring,
    getBuddyScoringLead,
    submitgetBuddyScoringLead
  };
};
