import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  message: null,
  userDetails: {}
};
export const setUserDetailSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    isError: false,
    userDetails: action.payload
  };
};
export const resetUserData = (state = INITIAL_STATE) => {
  return { ...state, userDetails: {} };
};

export const HANDLERS = {
  [Types.SET_USER_SUCCESS]: setUserDetailSuccess,
  [Types.RESET_USER_DETAILS]: resetUserData
};

export default createReducer(INITIAL_STATE, HANDLERS);
