export const TABLE_HEADERS = [
  {
    label: 'Date',
    headerKey: 'updated_on',
    colWidth: 'col-2',
    dataType: 'datetime'
  },
  { 
    label: 'User Email', 
    headerKey: 'user_email', 
    colWidth: 'col-2' 
  },
  {
    label: 'Listing Price',
    headerKey: 'listing_price',
    colWidth: 'col-2'
  }
];
