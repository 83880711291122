import { config } from './../config/local';
import { buildQuery } from '../../services/helper';
import { IS_ENV_DEV, IS_MOCK } from '../../shared/utils/constants';
export default (api) => {
  const fetchCityGridInfo = (params = { page: 0, size: 10 }) => {
    const path = config.api.c2c.cityConfig.path;
    const queryStr = params ? `?${buildQuery(params)}` : '';
    const url = path + queryStr;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };

  const fetchCityInspectionStoreInfoById = (params) => {
    const path = config.api.c2c.inspectionStore.path;
    const queryStr = params ? `?${buildQuery(params)}` : '';
    const url = path + queryStr;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };
  const editInspectionStore = (data) => {
    const path = config.api.c2c.inspectionStore.path;
    const url = `${path}`;
    return api.put(url, data);
  };

  const updateSellerToCC = (data) => {
    const path = config.api.c2c.updateSellerToCC.path;
    const url = `${path}`;
    return api.put(url, data);
  };

  const fetchStates = () => {
    return api.get(`states`);
  }

  const fetchCities = (stateId) => {
    return api.get(`cities?stateId=${stateId}`);
  }

  return {
    fetchCityGridInfo,
    fetchCityInspectionStoreInfoById,
    editInspectionStore,
    updateSellerToCC,
    fetchStates,
  fetchCities
  };
};
