import adminApi from './adminApi';
import { baseApi } from '../client/service';
import uploadServiceApi from '../admin-app/service/uploadPublishApi';
import rtoApi from '../admin-app/service/rto';
import storePricingApi from '../admin-app/service/storePricingApi';
import rcCaseTypeService from '../admin-app/service/rcCaseTypeService';
import rcManagementService from '../admin-app/service/rcManagementService';
import dealInfoService from '../admin-app/service/dealInfoService';
import orderService from '../admin-app/service/orderService';
import dealerService from '../admin-app/service/dealerService';
import dataService from '../client/service/dataService';
import storeService from '../admin-app/service/storeService';
import userService from '../admin-app/service/userService';
import financeService from '../admin-app/service/financeService';
import cityService from '../admin-app/service/cityService';
import userRcAccessService from '../admin-app/service/userRcAccessService';
import languageService from "../admin-app/service/languageService";
import hypothecationService from "../admin-app/service/hypothecationService"
import documentRuleEngineService from "../admin-app/service/documentRuleEngineService";
import rtoDelayReasonsService from '../admin-app/service/rtoDelayReasonsService';
import caseTypeRuleEngineService from '../admin-app/service/caseTypeRuleEngineService';
import docketManagementService from '../admin-app/service/docketManagementService';
import taskManagementService from '../admin-app/service/taskManagementService';
import agentAvailabilityService from '../admin-app/service/agentAvailabilityService';
import regionAllocationService from '../admin-app/service/regionAllocationService';
import ulipService from '../admin-app/service/ulipService';
//import authApi from './authService';

const upload = uploadServiceApi(adminApi);
const rto = rtoApi(adminApi);
const storePricing = storePricingApi(adminApi);
const rcCaseType = rcCaseTypeService(adminApi);
const rcManagement = rcManagementService(adminApi);
const dealInfo = dealInfoService(adminApi);
const orders = orderService(adminApi);
const dealer = dealerService(adminApi);
//const data = dataApi(baseApi);
const data = dataService(baseApi);
const stores = storeService(adminApi);
const user = userService(baseApi);
const finance = financeService(adminApi);
const cityConfig = cityService(adminApi);
const userRcAccess = userRcAccessService(adminApi);
const languages = languageService(adminApi);
const hypothecation =hypothecationService(adminApi);
const documentRuleEngine = documentRuleEngineService(adminApi);
const rtoDelayReasons = rtoDelayReasonsService(adminApi);
const caseTypeRuleEngine = caseTypeRuleEngineService(adminApi);
const docketManagement = docketManagementService(adminApi);
const taskManagement = taskManagementService(adminApi);
const agentAvailability = agentAvailabilityService(adminApi);
const regionAllocation = regionAllocationService(adminApi);
const ulip = ulipService(adminApi);

export {
  adminApi,
  upload as uploadServiceApi,
  rto as rtoApi,
  storePricing as storePricingApi,
  dealInfo as dealInfoService,
  rcCaseType as rcCaseTypeService,
  rcManagement as rcManagementService,
  orders as orderService,
  dealer as dealerService,
  data as dataService,
  user as userService,
  finance as financeService,
  stores as storeService,
  cityConfig as cityService,
  userRcAccess as userRcAccessService,
  languages as languageService,
  hypothecation as HypothecationService,
  documentRuleEngine as documentRuleEngineService,
  rtoDelayReasons as rtoDelayReasonsService,
  caseTypeRuleEngine as caseTypeRuleEngineService,
  docketManagement as docketManagementService,
  taskManagement as taskManagementService,
  agentAvailability as agentAvailabilityService,
  regionAllocation as regionAllocationService,
  ulip as ulipService,
};
