import { createTypes } from "reduxsauce";

export default createTypes(
    `
    SET_RC_DEAL_INFO
`,
    {
        prefix: "rctrackerdealinfo/"
    }
);
