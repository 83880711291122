import { 
  CITIES,
  CORRECTION_SEGMENTS, 
  SEGEMENT_TYPES, 
  SEGMENT_TYPE_KEYS, 
  SERVICE_TYPE_KEYS, 
  YES_NO_DROPDOWN 
} from "./constants";

export const SERVICE_TYPE_INDEX = 10;

const withLimitNumber = (e, callback, limit) => {
  const { value } = e.target;
  if(value.length > limit) {
    return false
  } else {
    callback(e);
  }
}

export const SEGMENT_FORM_KEYS = {
  SEGMENT_TYPE: "segment",
  IS_IT_A_C2B_CAR: "c2bCar",
  APPOINTMENT_ID: "appointmentId",
  REQUESTER_EMAIL_ID: "requesterEmailId",
  IS_IT_AN_EMPANELLED_CAR: "empanelledCar",
  DEALER_CODE: "dealerCode",
  DEALER_NAME: "dealerName",
  DEALER_CONTACT_NO: "dealerContactNo",
  B2B_PARTNER_NAME: "b2bPartnerName",
  SERVICE_TYPE: "serviceType",
  SERVICE_DETAILS: "serviceDetail",
  REGISTRATION_NUMBER: "registration",
  OWNER_NAME: "ownerName",
  OWNER_NO: "ownerNumber",
  OWNER_EMAIL_ID: "ownerEmailId",
  APPLICANT_NAME: "applicantName",
  APPLICANT_NO: "applicantNo",
  APPLICANT_EMAIL: "applicantEmail",
  BUYER_NAME: "buyerName",
  BUYER_NO: "buyerNumber",
  BUYER_EMAIL_ID: "buyerEmailId",
  CURRENT_HP_BANK_NAME: "currentHPBankName",
  NEW_HP_BANK_NAME: "newHPBankName",
  BOOKING_ID: "bookingId",
  CITY: "rcTransferCity",
  CHALLAN_NUMBER: "challanNumber",
  CHALLAN_AMOUNT: "challanAmount",
  CORRECTION_SEGMENT: "correctionSegment",
  UID: "uid",
  LOAN_ID: "loanId",
};

export const SEGMENT_FORM_METADATA = ({
  serviceTypes,
  getServiceDetailsByServiceType,
}) => [
  {
    key: [SEGMENT_FORM_KEYS.SEGMENT_TYPE],
    label: "Segment Type",
    fieldType: "dropdown",
    getDropdownOptions: () => SEGEMENT_TYPES,
    isVisible: () => true,
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.IS_IT_A_C2B_CAR],
    label: "Is it a C2B Car?",
    fieldType: "dropdown",
    getDropdownOptions: () => YES_NO_DROPDOWN,
    isVisible: (formValues) => {
      const segmentType = formValues[SEGMENT_FORM_KEYS.SEGMENT_TYPE];
      if ([SEGMENT_TYPE_KEYS.C2B, SEGMENT_TYPE_KEYS.DEALER_CF, SEGMENT_TYPE_KEYS.OUTSIDE_DEALER].includes(segmentType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.APPOINTMENT_ID],
    label: "Appointment ID",
    fieldType: "text",
    fieldSubType: "number",
    maxLength: 12,
    isVisible: (formValues) => {
      const isItAC2bCar = formValues[SEGMENT_FORM_KEYS.IS_IT_A_C2B_CAR];
      if (isItAC2bCar && isItAC2bCar === YES_NO_DROPDOWN[0].value) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
    validateOnChange: (e, cb) => withLimitNumber(e, cb, 12),
  },
  {
    key: [SEGMENT_FORM_KEYS.REQUESTER_EMAIL_ID],
    label: "Requester Email ID",
    fieldType: "text",
    isVisible: () => true,
    isValid: () => true,
    fieldSubType: "email",
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.IS_IT_AN_EMPANELLED_CAR],
    label: "Is It an empanelled car?",
    fieldType: "dropdown",
    getDropdownOptions: () => YES_NO_DROPDOWN,
    isVisible: (formValues) => {
      const segmentType = formValues[SEGMENT_FORM_KEYS.SEGMENT_TYPE];
      if ([SEGMENT_TYPE_KEYS.C2B, SEGMENT_TYPE_KEYS.DEALER_CF, SEGMENT_TYPE_KEYS.OUTSIDE_DEALER].includes(segmentType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.DEALER_CODE],
    label: "Dealer Code",
    fieldType: "text",
    isVisible: (formValues) => {
      const isItAnEmpanelledCar = formValues[SEGMENT_FORM_KEYS.IS_IT_AN_EMPANELLED_CAR];
      if (isItAnEmpanelledCar && isItAnEmpanelledCar === YES_NO_DROPDOWN[0].value) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.DEALER_NAME],
    label: "Dealer Name",
    fieldType: "text",
    isVisible: (formValues) => {
      const isItAnEmpanelledCar = formValues[SEGMENT_FORM_KEYS.IS_IT_AN_EMPANELLED_CAR];
      if (isItAnEmpanelledCar && isItAnEmpanelledCar === YES_NO_DROPDOWN[1].value) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.DEALER_CONTACT_NO],
    label: "Dealer Contact No.",
    fieldType: "text",
    fieldSubType: "number",
    maxLength: 14,
    isVisible: (formValues) => {
      const isItAnEmpanelledCar = formValues[SEGMENT_FORM_KEYS.IS_IT_AN_EMPANELLED_CAR];
      if (isItAnEmpanelledCar && isItAnEmpanelledCar === YES_NO_DROPDOWN[1].value) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
    validateOnChange: (e, cb) => withLimitNumber(e, cb, 14),
  },
  {
    key: [SEGMENT_FORM_KEYS.B2B_PARTNER_NAME],
    label: "B2B Partner Name",
    fieldType: "text",
    isVisible: (formValues) => {
      const segmentType = formValues[SEGMENT_FORM_KEYS.SEGMENT_TYPE];
      if ([SEGMENT_TYPE_KEYS.OUTSIDE_B2B].includes(segmentType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.LOAN_ID],
    label: "Loan Id",
    fieldType: "text",
    isVisible: (formValues) => {
      const segmentType = formValues[SEGMENT_FORM_KEYS.SEGMENT_TYPE];
      if ([SEGMENT_TYPE_KEYS.LOAN_AGAINST_CAR, SEGMENT_TYPE_KEYS.DEALER_CF, SEGMENT_TYPE_KEYS.SUBROGATION_CASES, SEGMENT_TYPE_KEYS.UNCONFIRMED_BUYER].includes(segmentType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.SERVICE_TYPE],
    label: "Service Type",
    fieldType: "dropdown",
    getDropdownOptions: () => serviceTypes,
    isVisible: () => true,
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.SERVICE_DETAILS],
    label: "Service Details",
    fieldType: "dropdown",
    getDropdownOptions: (formValues) => {
      const serviceType = formValues[SEGMENT_FORM_KEYS.SERVICE_TYPE];
      return getServiceDetailsByServiceType(serviceType);
    },
    isVisible: () => true,
    isValid: () => true,
    isRequired: (formValues) => {
      const serviceType = formValues[SEGMENT_FORM_KEYS.SERVICE_TYPE];
      if (serviceType === SERVICE_TYPE_KEYS.RC_TRANSFER_SERVICE) {
        return false;
      }
      return true;
    },
  },
  {},
  {
    key: [SEGMENT_FORM_KEYS.REGISTRATION_NUMBER],
    label: "Registration Number",
    fieldType: "text",
    isVisible: (formValues) => {
      const serviceType = formValues[SEGMENT_FORM_KEYS.SERVICE_TYPE];
      const {
        RC_TRANSFER_SERVICE,
        VEHICLE_RELATED_SERVICE,
        CHALLAN,
        VALUE_ADDED_SERVICES,
        CORRECTION_REQUIRED,
      } = SERVICE_TYPE_KEYS;
      if ([RC_TRANSFER_SERVICE, VEHICLE_RELATED_SERVICE, CHALLAN, VALUE_ADDED_SERVICES, CORRECTION_REQUIRED].includes(serviceType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.OWNER_NAME],
    label: "Owner Name",
    fieldType: "text",
    isVisible: (formValues) => {
      const serviceType = formValues[SEGMENT_FORM_KEYS.SERVICE_TYPE];
      const {
        RC_TRANSFER_SERVICE,
        VEHICLE_RELATED_SERVICE,
        CHALLAN,
        VALUE_ADDED_SERVICES,
        CORRECTION_REQUIRED,
      } = SERVICE_TYPE_KEYS;
      if ([RC_TRANSFER_SERVICE, VEHICLE_RELATED_SERVICE, CHALLAN, VALUE_ADDED_SERVICES, CORRECTION_REQUIRED].includes(serviceType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.OWNER_NO],
    label: "Owner No.",
    fieldType: "text",
    fieldSubType: "number",
    maxLength: 14,
    isVisible: (formValues) => {
      const serviceType = formValues[SEGMENT_FORM_KEYS.SERVICE_TYPE];
      const {
        RC_TRANSFER_SERVICE,
        VEHICLE_RELATED_SERVICE,
        CHALLAN,
        VALUE_ADDED_SERVICES,
        CORRECTION_REQUIRED,
      } = SERVICE_TYPE_KEYS;
      if ([RC_TRANSFER_SERVICE, VEHICLE_RELATED_SERVICE, CHALLAN, VALUE_ADDED_SERVICES, CORRECTION_REQUIRED].includes(serviceType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
    validateOnChange: (e, cb) => withLimitNumber(e, cb, 14),
  },
  {
    key: [SEGMENT_FORM_KEYS.OWNER_EMAIL_ID],
    label: "Owner Email ID",
    fieldType: "text",
    fieldSubType: "email",
    isVisible: (formValues) => {
      const serviceType = formValues[SEGMENT_FORM_KEYS.SERVICE_TYPE];
      const {
        RC_TRANSFER_SERVICE,
        VEHICLE_RELATED_SERVICE,
        CHALLAN,
        VALUE_ADDED_SERVICES,
        CORRECTION_REQUIRED,
      } = SERVICE_TYPE_KEYS;
      if ([RC_TRANSFER_SERVICE, VEHICLE_RELATED_SERVICE, CHALLAN, VALUE_ADDED_SERVICES, CORRECTION_REQUIRED].includes(serviceType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.APPLICANT_NAME],
    label: "Applicant Name",
    fieldType: "text",
    isVisible: (formValues) => {
      const serviceType = formValues[SEGMENT_FORM_KEYS.SERVICE_TYPE];
      const {
        DRIVING_LICENCE
      } = SERVICE_TYPE_KEYS;
      if ([DRIVING_LICENCE].includes(serviceType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.APPLICANT_NO],
    label: "Applicant No.",
    fieldType: "text",
    fieldSubType: "number",
    maxLength: 14,
    isVisible: (formValues) => {
      const serviceType = formValues[SEGMENT_FORM_KEYS.SERVICE_TYPE];
      const {
        DRIVING_LICENCE
      } = SERVICE_TYPE_KEYS;
      if ([DRIVING_LICENCE].includes(serviceType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
    validateOnChange: (e, cb) => withLimitNumber(e, cb, 14),
  },
  {
    key: [SEGMENT_FORM_KEYS.APPLICANT_EMAIL],
    label: "Applicant Email",
    fieldType: "text",
    fieldSubType: "email",
    isVisible: (formValues) => {
      const serviceType = formValues[SEGMENT_FORM_KEYS.SERVICE_TYPE];
      const {
        DRIVING_LICENCE
      } = SERVICE_TYPE_KEYS;
      if ([DRIVING_LICENCE].includes(serviceType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.BUYER_NAME],
    label: "Buyer Name",
    fieldType: "text",
    isVisible: (formValues) => {
      const serviceType = formValues[SEGMENT_FORM_KEYS.SERVICE_TYPE];
      const {
        RC_TRANSFER_SERVICE
      } = SERVICE_TYPE_KEYS;
      if ([RC_TRANSFER_SERVICE].includes(serviceType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.BUYER_NO],
    label: "Buyer No.",
    fieldType: "text",
    fieldSubType: "number",
    maxLength: 14,
    isVisible: (formValues) => {
      const serviceType = formValues[SEGMENT_FORM_KEYS.SERVICE_TYPE];
      const {
        RC_TRANSFER_SERVICE
      } = SERVICE_TYPE_KEYS;
      if ([RC_TRANSFER_SERVICE].includes(serviceType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.BUYER_EMAIL_ID],
    label: "Buyer Email",
    fieldType: "text",
    fieldSubType: "email",
    isVisible: (formValues) => {
      const serviceType = formValues[SEGMENT_FORM_KEYS.SERVICE_TYPE];
      const {
        RC_TRANSFER_SERVICE
      } = SERVICE_TYPE_KEYS;
      if ([RC_TRANSFER_SERVICE].includes(serviceType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.CURRENT_HP_BANK_NAME],
    label: "Current HP Bank Name",
    fieldType: "text",
    isVisible: (formValues) => {
      const serviceType = formValues[SEGMENT_FORM_KEYS.SERVICE_TYPE];
      const {
        RC_TRANSFER_SERVICE,
        VEHICLE_RELATED_SERVICE,
        CORRECTION_REQUIRED,
      } = SERVICE_TYPE_KEYS;
      if ([RC_TRANSFER_SERVICE, VEHICLE_RELATED_SERVICE, CORRECTION_REQUIRED].includes(serviceType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.NEW_HP_BANK_NAME],
    label: "New HP Bank Name",
    fieldType: "text",
    isVisible: (formValues) => {
      const serviceType = formValues[SEGMENT_FORM_KEYS.SERVICE_TYPE];
      const {
        RC_TRANSFER_SERVICE,
        VEHICLE_RELATED_SERVICE,
        CORRECTION_REQUIRED,
      } = SERVICE_TYPE_KEYS;
      if ([RC_TRANSFER_SERVICE, VEHICLE_RELATED_SERVICE, CORRECTION_REQUIRED].includes(serviceType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.BOOKING_ID],
    label: "Booking ID",
    fieldType: "text",
    isVisible: (formValues) => {
      const serviceType = formValues[SEGMENT_FORM_KEYS.SERVICE_TYPE];
      const {
        RC_TRANSFER_SERVICE,
        VEHICLE_RELATED_SERVICE,
        DRIVING_LICENCE,
        CHALLAN,
        VALUE_ADDED_SERVICES,
        CORRECTION_REQUIRED,
      } = SERVICE_TYPE_KEYS;
      if ([RC_TRANSFER_SERVICE, VEHICLE_RELATED_SERVICE, DRIVING_LICENCE, CHALLAN, VALUE_ADDED_SERVICES, CORRECTION_REQUIRED].includes(serviceType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.CITY],
    label: "City",
    fieldType: "dropdown",
    getDropdownOptions: () => CITIES,
    isVisible: (formValues) => {
      const serviceType = formValues[SEGMENT_FORM_KEYS.SERVICE_TYPE];
      const {
        RC_TRANSFER_SERVICE,
        VEHICLE_RELATED_SERVICE,
        DRIVING_LICENCE,
        CHALLAN,
        VALUE_ADDED_SERVICES,
        CORRECTION_REQUIRED,
      } = SERVICE_TYPE_KEYS;
      if ([RC_TRANSFER_SERVICE, VEHICLE_RELATED_SERVICE, DRIVING_LICENCE, CHALLAN, VALUE_ADDED_SERVICES, CORRECTION_REQUIRED].includes(serviceType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.CHALLAN_NUMBER],
    label: "Challan Number",
    fieldType: "text",
    isVisible: (formValues) => {
      const serviceType = formValues[SEGMENT_FORM_KEYS.SERVICE_TYPE];
      const {
        CHALLAN,
      } = SERVICE_TYPE_KEYS;
      if ([CHALLAN].includes(serviceType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  }, // DOUBT should it be number type?
  {
    key: [SEGMENT_FORM_KEYS.CHALLAN_AMOUNT],
    label: "Challan Amount",
    fieldType: "text",
    fieldSubType: "number",
    isVisible: (formValues) => {
      const serviceType = formValues[SEGMENT_FORM_KEYS.SERVICE_TYPE];
      const {
        CHALLAN,
      } = SERVICE_TYPE_KEYS;
      if ([CHALLAN].includes(serviceType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.CORRECTION_SEGMENT],
    label: "Correction Segment",
    fieldType: "dropdown",
    getDropdownOptions: () => CORRECTION_SEGMENTS,
    isVisible: (formValues) => {
      const serviceType = formValues[SEGMENT_FORM_KEYS.SERVICE_TYPE];
      const {
        CORRECTION_REQUIRED,
      } = SERVICE_TYPE_KEYS;
      if ([CORRECTION_REQUIRED].includes(serviceType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  },
  {
    key: [SEGMENT_FORM_KEYS.UID],
    label: "UID",
    fieldType: "text",
    isVisible: (formValues) => {
      const serviceType = formValues[SEGMENT_FORM_KEYS.SERVICE_TYPE];
      const {
        CORRECTION_REQUIRED,
      } = SERVICE_TYPE_KEYS;
      if ([CORRECTION_REQUIRED].includes(serviceType)) {
        return true;
      }
      return false;
    },
    isValid: () => true,
    isRequired: () => true,
  },
]