import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import './style.css';
import InputText from '../../../shared/components/input-text';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';
import { FIELDS } from './constants';
import CustomButton from '../../../shared/components/button';
import CTextArea from '../../../shared/components/custom-textarea';
import CustomCheckBox from '../../../shared/components/custom-checkbox';
import { SelectComponent } from '../../../client/components/shared';
import {
  console_in_dev,
  getParsedSelectOptions
} from '../../../shared/utils/helper';

const INIT_DATA = {
  appointmentId: '',
  noOfOwners: '',
  insuranceType: '',
  hypothecation: '',
  transmissionType: '',
  bodyType: '',
  color: '',
  isTopSelling: false,
  warrantyType: '',
  isC24Assured: false,
  isCfAvailable: false,
  cngLpgFitment: ''
};

const OrderSeller = function (props) {
  const [orderData, setOrderData] = useState({ ...INIT_DATA });
  const [fields] = useState([...FIELDS]);
  useEffect(() => {
    if (props.orderDetails.detail) {
      let defaultWarranty = null;
      if (props.orderDetails.warrantyType) {
        const tempWarrantyArr = props.orderDetails.warrantyType.filter(
          (item) => {
            return item.value === props.orderDetails.detail.warrantyType;
          }
        );
        defaultWarranty = tempWarrantyArr[0];
      }

      let updatedOrderData = {};
      Object.keys(orderData).forEach((item) => {
        updatedOrderData[item] = props.orderDetails.detail[item];
      });

      if (defaultWarranty) {
        defaultWarranty['label'] = defaultWarranty['display_text'];
        setOrderData({ ...updatedOrderData, warrantyType: defaultWarranty });
      } else {
        setOrderData(updatedOrderData);
      }
    }
  }, []);

  const getOptionsList = (keyname, parentKey) => {
    // Logic changed because of contract change in Sprint 12
    if (parentKey === 'warrantyType') {
      const warrantyTypeList = props.orderDetails.warrantyType || [];
      const params = {
        labelKey: 'display_text',
        valueKey: 'value'
      };

      const warrantyTypeListParsed = getParsedSelectOptions(
        warrantyTypeList,
        params
      );
      return warrantyTypeListParsed;
    } else {
      const metaData = props.orderDetails.updatableAndAcceptedValues;
      try {
        const optionList =
          metaData[keyname] &&
          metaData[keyname].map((item) => {
            return { label: item, value: item };
          });
        return optionList || [];
      } catch (error) {
        console_in_dev({ error, keyname, metaData }, 'error');
        return [];
      }
    }
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value, checked, type } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setOrderData({ ...orderData, [name]: newValue });
  };

  const handleSelectChange = (selected, itemKey) => {
    if (selected) {
      if (itemKey in orderData) {
        setOrderData({ ...orderData, [itemKey]: selected });
      } else {
        console.warn({ orderData, itemKey }, `check ${itemKey} in orderData`);
      }
    }
  };

  const getSelectValueByKey = (key) => {
    const source = orderData;
    try {
      if (source && source[key]) {
        if (source[key].value) {
          return source[key].value || null;
        } else {
          return source[key] || null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console_in_dev({ error, source, key });
      return null;
    }
  };

  const getSelectionByKey = (key) => {
    const source = orderData;
    try {
      if (source && source[key]) {
        if (source[key].value) {
          return source[key] || null;
        } else {
          return source[key]
            ? { label: source[key], value: source[key] }
            : null;
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };
  
  /* Form submit handler */
  const handleSubmit = (e) => {
    e.preventDefault();
    const payloadData = {
      ...orderData,
      bodyType: getSelectValueByKey('bodyType'),
      cngLpgFitment: getSelectValueByKey('cngLpgFitment'),
      hypothecation: {value: getSelectValueByKey('hypothecation') || ''}, // payload sent as per api request on 19 aug hotfix.
      insuranceType: getSelectValueByKey('insuranceType'),
      noOfOwners: getSelectValueByKey('noOfOwners'),
      transmissionType: getSelectValueByKey('transmissionType'),
      warrantyType: getSelectValueByKey('warrantyType'),
      isTopSelling: orderData.isTopSelling || false,
      isC24Assured: orderData.isC24Assured || false,
      isCfAvailable: orderData.isCfAvailable || false
    };
    props.updateOrderConnected(payloadData);
  };
  return (
    <LoaderWithAccessControl>
      {props.orderDetails && (
        <form autoComplete='off' onSubmit={handleSubmit}>
          <div className='clearfix' />
          <div className='row'>
            {Array.isArray(fields) &&
              fields.map((fieldItem, index) => {
                const {
                  autoComplete,
                  label,
                  fieldName,
                  type,
                  valueKey,
                  optionsKey,
                  readOnly
                } = fieldItem;
                switch (type) {
                  case 'number':
                  case 'text':
                    return (
                      <div
                        key={`${fieldName}_basic`}
                        className='float-left form-group col-md-5'
                      >
                        <label className='font-weight-bold'>{label}</label>
                        <InputText
                          name={fieldName}
                          type={type}
                          className='form-control'
                          autoComplete={autoComplete}
                          value={orderData[valueKey] || ''}
                          onChange={handleInputChange}
                          readOnly={readOnly}
                        />
                      </div>
                    );
                  case 'textarea':
                    return (
                      <div
                        key={`${fieldName}_basic`}
                        className='float-left form-group col-md-5'
                      >
                        <label className='font-weight-bold'>{label}</label>
                        <CTextArea
                          name={fieldName}
                          autoComplete={autoComplete}
                          className='form-control'
                          value={orderData[valueKey] || ''}
                          onChange={handleInputChange}
                          readOnly={readOnly}
                        />
                      </div>
                    );
                  case 'select':
                    const currentValue = getSelectValueByKey(valueKey);
                    const selectKey = currentValue || `select_${index}`;
                    return (
                      <div
                        key={`${fieldName}_basic`}
                        className='float-left form-group col-md-5'
                      >
                        <label className='font-weight-bold'>{label}</label>
                        <SelectComponent
                          key={selectKey}
                          name={fieldName}
                          optionsList={getOptionsList(
                            optionsKey || valueKey,
                            valueKey
                          )}
                          value={getSelectionByKey(valueKey)}
                          defaultValue={getSelectionByKey(valueKey)}
                          placeholder={`Select ${label}`}
                          version='v2'
                          onChange={handleSelectChange}
                        />
                      </div>
                    );
                  case 'checkbox':
                    return (
                      <div
                        key={`${fieldName}_basic`}
                        className='float-left form-group col-md-5'
                      >
                        <label className='font-weight-bold'>{label}</label>
                        <CustomCheckBox
                          key={`fieldName_${orderData[valueKey]}`}
                          name={fieldName}
                          value={orderData[valueKey]}
                          onClickCallback={handleInputChange}
                          checkedStatus={orderData[valueKey]}
                        />
                      </div>
                    );
                  default:
                    return (
                      <div
                        key={`${fieldName}_basic`}
                        className='float-left form-group col-md-5'
                      >
                        <label className='font-weight-bold'>{label}</label>
                        <InputText
                          name={fieldName}
                          type={type}
                          className='form-control'
                          autoComplete={autoComplete}
                          value={orderData[valueKey] || ''}
                          onChange={handleInputChange}
                          readOnly={readOnly}
                        />
                      </div>
                    );
                }
              })}
            <div className='float-left form-group col-md-8'>
              <CustomButton
                label={props.isUpdating ? 'Saving ...' : 'Save'}
                type='submit'
              />
            </div>
          </div>
        </form>
      )}
    </LoaderWithAccessControl>
  );
};

export default withRouter(OrderSeller);
