import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  isArrayEmpty,
  getMultiSelectedOption,
  getObjectFromArraySelect,
  getMappedData,
  getParsedSelectOptions
} from '../../../shared/utils/helper';
import { SelectComponent, CheckBox } from '../../../client/components/shared';
import { FIELD_SETS, FIELDS } from './constants';
import InputText from '../../../shared/components/input-text';
import { Spinner } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Button from '../../../shared/components/button';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';
const Wrapper = ({ item, children }) => {
  const { label, keyName, required = false } = item;
  return (
    <div className='col-6 d-flex'>
      <label className='col-4 justify-content-start' for={keyName}>
        <span>{label}</span>
        <span>{required ? ' *' : ''}</span>
      </label>
      <div className='col-8'>{children}</div>
    </div>
  );
};

const UserRcAccess = (props) => {
  const [rcAccessFilterData, setRcAccessFilterData] = useState();
  const [yardData, setYardData] = useState();

  useEffect(() => {
    setRcAccessFilterData(
      props.userRcAccessInfo &&
        props.userRcAccessInfo.rcAccessDetails &&
        props.userRcAccessInfo.rcAccessDetails
    );
  }, [props.userRcAccessInfo]);

  useEffect(() => {
    props.fetchStores().then((response) => {
      setYardData(response.storeOptions);
    });
  }, []);

  const initialValues = {
    city_ops:
      rcAccessFilterData && rcAccessFilterData.city_ops
        ? rcAccessFilterData.city_ops
        : false,
    car_source: rcAccessFilterData
      ? getMultiSelectedOption(
          props.userRcAccessInfo && props.userRcAccessInfo.lists.car_source,
          rcAccessFilterData.user_filter_data &&
            rcAccessFilterData.user_filter_data.car_source,
          'value',
          'display_text'
        )
      : null,
    buddy_email: rcAccessFilterData
      ? getObjectFromArraySelect(
          rcAccessFilterData.user_filter_data &&
            rcAccessFilterData.user_filter_data.buddy_email
        )
      : null,
    city_id: rcAccessFilterData
      ? getMultiSelectedOption(
          props.userRcAccessInfo && props.userRcAccessInfo.lists.city_list,
          rcAccessFilterData.user_filter_data &&
            rcAccessFilterData.user_filter_data.city_id,
          'cityId',
          'cityName'
        )
      : null,
    parking_yard_id: yardData
      ? getMultiSelectedOption(
          yardData,
          rcAccessFilterData.user_filter_data &&
            rcAccessFilterData.user_filter_data.parking_yard_id,
          'value',
          'label'
        )
      : null
  };

  const [options, setOptions] = useState({});

  const onSubmit = (values) => {
    const payload = {
      user_filter_data: {
        car_source: getMappedData(values.car_source, 'value'),
        buddy_email: getMappedData(values.buddy_email, 'value'),
        city_id: getMappedData(values.city_id, 'value'),

        parking_yard_id: getMappedData(values.parking_yard_id, 'value')
      },
      city_ops: values.city_ops || false,
      id: rcAccessFilterData.id,

      user_email: props.userInfo.userDetails.emailId
    };

    if (rcAccessFilterData.id) {
      props.editUserRcAccessConnected(payload);
    } else {
      props.addUserRcAccessConnected(payload).then((res) => {
        let rcAccessData = { ...rcAccessFilterData };
        rcAccessData.id = res.data.data;
        setRcAccessFilterData(rcAccessData);
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit
  });
  const onChangeSelect = (selected, param) => {
    if (selected) {
      formik.setFieldValue(param, selected);
    } else {
      formik.setFieldValue(param, []);
    }
  };
  const onChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    formik.setFieldValue(name, checked);
  };

  useEffect(() => {
    if (!isArrayEmpty(props.UserGridInfo.userData)) {
      const emailOptions = props.UserGridInfo.userData.map((item) => {
        return { label: item.emailId, value: item.emailId };
      });

      const { city_list = [], car_source = [] } =
        props.userRcAccessInfo && props.userRcAccessInfo.lists;

      const cityOptions = getParsedSelectOptions(city_list, {
        labelKey: 'cityName',
        valueKey: 'cityId'
      });
      const carSourceOption = getParsedSelectOptions(car_source, {
        labelKey: 'display_text',
        valueKey: 'value'
      });
      const yardOption = getParsedSelectOptions(yardData, {
        labelKey: 'label',
        valueKey: 'value'
      });
      setOptions({
        ...options,
        [FIELDS.buddy_email.keyName]: emailOptions,
        [FIELDS.city_id.keyName]: cityOptions,
        [FIELDS.car_source.keyName]: carSourceOption,
        [FIELDS.parking_yard_id.keyName]: yardOption
      });
    } else {
      setOptions({
        ...options,
        [FIELDS.buddy_email.keyName]: [],
        [FIELDS.city_id.keyName]: [],
        [FIELDS.car_source.keyName]: [],
        [FIELDS.parking_yard_id.keyName]: []
      });
    }
  }, [props.userRcAccessInfo.lists]);

  useEffect(() => {
    props.fetchRcManagemetFilterDataConnected();
  }, []);
  useEffect(() => {
    if (props.userInfo.userDetails.emailId) {
      props.fetchUserRcAccessConnected(props.userInfo.userDetails.emailId);
    }
  }, [props.userInfo.userDetails.emailId]);
  return (
    <LoaderWithAccessControl>
      <form onSubmit={formik.handleSubmit}>
        <div className='col-12'>
          {FIELD_SETS.map((set, setIndex) => {
            return (
              <div key={`outer_${setIndex}`} className='form-inline mb20'>
                {set.map((fieldItem, fieldItemIndex) => {
                  const {
                    type,
                    label,
                    keyName,
                    required,
                    elementType,
                    isMulti,
                    maxLength,
                    ...restProps
                  } = fieldItem;
                  switch (elementType) {
                    case 'input':
                      return (
                        <Wrapper item={fieldItem}>
                          <InputText
                            id={keyName}
                            name={keyName}
                            type={type}
                            readOnly={false}
                            className='form-control wd100'
                            onChange={formik.handleChange}
                            value={formik.values[keyName]}
                            onBlur={formik.handleBlur}
                            maxLength={maxLength}
                            isTouch={formik.touched[keyName]}
                            {...restProps}
                          />
                        </Wrapper>
                      );

                    case 'select':
                      return (
                        <Wrapper item={fieldItem}>
                          {options[keyName] ? (
                            <SelectComponent
                              key={formik.values[keyName] || keyName}
                              id={keyName}
                              name={keyName}
                              optionsList={options[keyName]}
                              defaultValue={formik.values[keyName] || null}
                              value={formik.values[keyName] || null}
                              onChange={onChangeSelect}
                              isDisabled={false}
                              className='form-control inputSpace col-6'
                              isMulti={true}
                              version='v2'
                            />
                          ) : (
                            <Spinner
                              variant='primary'
                              animation='border'
                              size='sm'
                            />
                          )}
                        </Wrapper>
                      );

                    case 'checkbox':
                      return (
                        <Wrapper item={fieldItem}>
                          <CheckBox
                            id={keyName}
                            name={keyName}
                            onClickCallback={onChangeCheckbox}
                            checkedStatus={
                              formik.values[keyName] ? true : false
                            }
                          />
                        </Wrapper>
                      );
                    default:
                      return null;
                  }
                })}
              </div>
            );
          })}

          <Button label='Save' type='submit' />
        </div>
      </form>
    </LoaderWithAccessControl>
  );
};

export default withRouter(UserRcAccess);
