import React, { useState } from 'react';

import Header from '../../components/admin-header';
import Footer from '../../components/admin-footer';
import Sidebar from '../../components/admin-sidebar';
import ErrorBoundary from '../../../admin-app/components/common/errorBoundary';

function withAdminLayout(
  WrappedComponent,
  sidebar = true,
  header = true,
  footer = true
) {
  return (props) => {
    return (
      <>
        {header && <Header />}
        {sidebar && <Sidebar handleHeading={'C2C Admin'} />}
        <section className='content-wrapper main-content-wrapper overlay-wrapper'>
          <section className='content flex'>
            <div className='box box-success'>
              <section className='content full-height'>
                <ErrorBoundary>
                  <WrappedComponent {...props} />
                </ErrorBoundary>
              </section>
            </div>
          </section>
        </section>

        {footer && <Footer />}
        <div className='control-sidebar-bg' />
      </>
    );
  };
}

export default withAdminLayout;
