
import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import Arrow from "./images/arrow.svg";
import { paymentModesValues, deliveryModeValues } from "../../../../admin-app/constants/constants";
import priceFormatter from "../../../utils/price-formatter";
import { getTotalChargesAmount, getTotalCharges, getAdditionalChargeAmount } from "../utility";
import Button from "../../../../shared/components/button";
import { DataService } from '../../../service';
const OrderSummaryDetails = ({ 
  orderData,
  form,
  pincodeData,
  selectedPickupLocationIndex = 0,
  isCCOBAssitBooking = false,
  selectBookingItem
  }) => {
    const {status} = selectBookingItem || {};
    const { state, dispatch } = form;
    const financeOpted = !!state.paymentMode && state.paymentMode === paymentModesValues.CARS24_FINANCE ? true : false;
    const [showDropDownView, setShowDropDownView] = useState(financeOpted ? true : false);
    const [showBookingAmountError, setShowBookingAmountError] = useState(false);
    const {vehiclePrice, bookingAmount} = orderData || {};
    const {chargeDetails : addtitionalCharges } = state || {};
    const {chargeDetails, downPayment, emiAmount, tenure} = state.financeTerms || {}
    const totalProcessingFee = getTotalChargesAmount(chargeDetails);
    const {homeDelivery, interState, pickup} = pincodeData || {};
    const addlShippingCharge = getAdditionalChargeAmount(addtitionalCharges, "shipping_charge");
    const addlRCCharge = getAdditionalChargeAmount(addtitionalCharges, "rc_charge");
    let homeDeliveryShippingAmount = getTotalCharges(!!homeDelivery && homeDelivery.length > 0 ? homeDelivery[0].charges : []);
    let interStateRCTransferAmount = getTotalCharges(!!interState ? interState.charges : []);
    const pickupChargesAmount = !!pickup && pickup.length > 0 && getTotalCharges(!!pickup[selectedPickupLocationIndex] ? pickup[selectedPickupLocationIndex].charges : []);
    interStateRCTransferAmount = interStateRCTransferAmount > 0 ? interStateRCTransferAmount : addlRCCharge;
    let shippingCharge = state.deliveryMode === deliveryModeValues.HOME_DELIVERY ? homeDeliveryShippingAmount : pickupChargesAmount;
    shippingCharge = shippingCharge > 0 ? shippingCharge : addlShippingCharge;
    const finalAmountPayable = financeOpted ? vehiclePrice + totalProcessingFee + shippingCharge + interStateRCTransferAmount : vehiclePrice + shippingCharge + interStateRCTransferAmount ;
    
    useEffect(() => {
      return () => {
          document.removeEventListener("click", handleClickOutside, false);
      };
    }, []);

    const handleClickOutside = event => {
        setShowDropDownView(false);
    };

    useEffect(()=> {
      if (financeOpted) {
        setShowDropDownView(true);
      } else {
        setShowDropDownView(false);
      }
    },[financeOpted]);

    const getBookingAmount = () => {
      let isValidPaymentMode = false;
      let isValidDeliveryMode = false;
      if (state.paymentMode === paymentModesValues.CARS24_FINANCE && !!state.financeTerms) {
        isValidPaymentMode = true; 
      }
      if (state.paymentMode === paymentModesValues.PAY_ON_DELIVERY) {
        isValidPaymentMode = true; 
      }

      if (state.deliveryMode === deliveryModeValues.HOME_DELIVERY && state.deliveryPincode && state.deliveryAddress1 && state.deliveryAddress2 &&
        state.deliveryState && state.deliveryCity) {
          isValidDeliveryMode = true;
      }

      if (state.deliveryMode === deliveryModeValues.HUB_PICKUP && state.deliveryPincode && state.deliveryPlace) {
        isValidDeliveryMode = true;
      } 

      if (isValidDeliveryMode && isValidPaymentMode) {
        DataService.updateCCOBBookingData(state).then(resp => {
          setShowBookingAmountError(false);
          const {bookingAmount} = resp;
          dispatch({
            type: "bookingAmount",
            bookingAmount
          });
        }).catch(err => {
          setShowBookingAmountError(true);
          console.log(err);
        });
      }
      else if (isValidDeliveryMode || isValidPaymentMode) {
        setShowBookingAmountError(true);
      }
    };
    return (
      <>
        <div className={styles.ordersummary}>
          <h2 className={styles.title}>Order Summary</h2>
          <div styleName={styles.orderSummaryUnder}>
            <div className={styles.orderSummaryWrapper}>
              {/* <strong styleName={styles.price}>{"Final amount payable"}
                  {!financeOpted && <small styleName={styles.subText}>No financing selected</small> }
              </strong>
              <strong styleName={styles.price}>Rs 2,00,000</strong> */}
              <div className={`${styles.finalAmountHeaderWrapper} ${showDropDownView ? styles.active : ''}`}>
                <div className={styles.finalAmountHeader}>
                  <div className={styles.arrowWrapper}>
                    <p>Final amount payable</p>
                    {financeOpted && <img src={Arrow} alt="arrow" onClick={() => setShowDropDownView(!showDropDownView)} />}
                  </div>
                  <span>Rs. {priceFormatter(finalAmountPayable)}</span>
                </div>
                <p className={styles.text}>{financeOpted ? `financing @ Rs. ${priceFormatter(emiAmount)} per month for ${tenure / 12} yrs` : `No financing selected`}</p>

                <div className={styles.finalAmountHeaderContent}>
                  <div className={styles.priceList}>
                    <div className={styles.servicesAmount}>
                        <p>Car Price</p>
                        <span>Rs. {priceFormatter(vehiclePrice)}</span>
                    </div>
                    <ul>
                      <li>
                        <p>Down Payment</p>
                        <span>Rs. {priceFormatter(downPayment)}</span>
                      </li>
                      <li>
                        <p>Load Amount with Charges</p>
                        <span>Rs. {priceFormatter(vehiclePrice - downPayment + totalProcessingFee)}</span>
                      </li>
                    </ul>
                  </div>

                  <div className={styles.priceList}>
                    <div className={styles.servicesAmount}>
                        <p>Financing Charges</p>
                        <span>Rs. {priceFormatter(totalProcessingFee)}</span>
                    </div>
                    <ul>
                      {
                        !!chargeDetails && chargeDetails.map((charge, index) => {
                            return (
                                <li key={index}>
                                    <p>{charge.description}</p>
                                    <span>Rs. {priceFormatter(charge.amount)}</span>
                                </li>
                            );
                        })
                      }
                    </ul>
                  </div>
                </div>
              </div>
              

              <div className={styles.finalAmount}>
                <p>6 months warranty</p>
                <span>FREE</span>
              </div>
              <p className={styles.text}>@ Rs. 599 per month</p>

              <div className={styles.servicesAmount}>
                <p>Insurance</p>
                <span>FREE</span>
              </div>
              <p className={styles.text}>3rd party insurance with validity is inculuded</p>

              <div className={styles.servicesAmount}>
                <p>Shipping charges</p>
                <span>{shippingCharge > 0 ? `Rs. ${priceFormatter(shippingCharge)}` : "FREE"}</span>
              </div>
              <p className={styles.text}>Home delivery charges to your doorstep</p>

              <div className={styles.servicesAmount}>
                <p>RC transfer</p>
                <span>{interStateRCTransferAmount > 0 ? `Rs. ${priceFormatter(interStateRCTransferAmount)}` :  "FREE"}</span>
              </div>
              <p className={styles.text}>CARS24 will facilitate RC Transfer of the car</p>

              <div className={styles.servicesAmount}>
                <p>Services Charges</p>
                <span>NEVER</span>
              </div>
              <p className={styles.text}>No serveice charges</p>

              <div className={styles.payAble}>
                  <p>{status === "Completed" ? "Paid Already" : "Payable Now"}</p>
                  <span>Rs. {priceFormatter(status === "Completed" ?  bookingAmount : state.bookingAmount)}</span>  
                  {(isCCOBAssitBooking && status === "Pending" )&& <Button label="View Booking Amount" onClick={()=> getBookingAmount()}></Button>}
              </div>
              {isCCOBAssitBooking && status === "Pending" && (showBookingAmountError ? <p className={styles.errortext}>{`Please fill checkout detail first.`}</p> : <p className={styles.text}>Reserve your car for 48 hrs</p>)}
            </div>
          </div>
        </div>
      </>
    );
};

export default OrderSummaryDetails;
