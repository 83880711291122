import React from 'react';
import Accordion from '../../../shared/accordion';
import ArrowIcon from '../../../shared/arrow';
import { formatDateTime } from '../../../shared/utils/helper';
import styles from './styles.module.css';

const DeChecklistLogs = ({data}) => {
  return (
    <Accordion totalItems={1}>
      {({ onClick, currentVisibleStates }) => {
        return (
          <Accordion.Content>
            <Accordion.Heading>
              <h2
                className='d-flex fwb justify-content-between m0'
                onClick={() => onClick({ index: 0 })}
              >
                Delivery Checklist Logs
                <ArrowIcon
                  grey={'GreyArrow'}
                  rotateBy={currentVisibleStates[0] ? 180 : 90}
                />
              </h2>
            </Accordion.Heading>
            <Accordion.Body>
              <div
                className={
                  currentVisibleStates[0] ? 'accordionShow mt10 top-border' : 'accordionHide'
                }
              >
               <div className={`row ${styles.delayReasonsTable}`}>
                 <table cellPadding="0" cellSpacing="0" width="100%">
                   <thead>
                     <tr>
                       <th>{"Submit Date & Time"}</th>
                       <th>User</th>
                       <th>Handover list</th>
                       <th>2nd Key status</th>
                       <th>Handover Method</th>
                       <th>Tracking ID</th>
                       <th>Remarks</th>
                     </tr>
                   </thead>
                   <tbody>
                     {data.length > 0 && data.map((item, index)=> {
                      return (
                        <tr>
                          <td> {formatDateTime(item.createdAt)}
                          </td>
                          <td> {item.createdBy}
                          </td>
                          <td> {item.handoverItem}
                          </td>
                          <td> {item.secondKeyStatus}
                          </td>
                          <td> {item.handoverMethod}
                          </td>
                          <td> {item.trackingId}
                          </td>
                          <td> {item.remarks}
                          </td>
                        </tr>
                      );
                     })
                    }
                   </tbody>
                 </table>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Content>
        );
      }}
    </Accordion>
  );
};

export default DeChecklistLogs;
