import React, { useState, useEffect, useReducer } from 'react';
import {
  InboundBuyerDisposition as DispositionDetails,
  InteractionJourney
} from '../../components/shared';
import { Label } from 'reactstrap';
import { DataService } from '../../service';
import NoResult from '../../images/no-result.png';
import qs from 'qs';
import { Redirect } from 'react-router-dom';
import Loader from '../../images/preloader.gif';
import { StateActions, CityActions } from '../../../admin-app/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IS_ENV_DEV } from '../../../shared/utils/constants';
import { CF_SUCCESS_MESSAGE, CAMPAIGN_TYPE, DispositionConstants } from '../../components/shared/Constants';
import OutBoundBuyerDetails from '../../components/shared/OutBoundBuyerDetails/component';
import { buyerMockData } from './data';
import { cityService } from '../../../services';
import { paymentModesValues, deliveryModeValues } from '../../../admin-app/constants/constants';
import styles from './style.module.css';
import ConsumerFinanceService from "../../service/consumer-finance-service";
import { getProcesingFeeValueFromList, calculateProcessingFee, getCreateChargeDetails, getShippingCharge } from '../../components/shared/utility';
import OrderSummaryDetails from '../../components/shared/OutBoundBuyerDetails/order-summary-details';
import LeadInteractionHistory from "../../components/shared/OutBoundBuyerDetails/lead-interaction-history";

const CCOutboundBuyer = (props) => {
  if (!window.location.search) {
    return <Redirect to='/error' />;
  }
  const dialerData = qs.parse(window.location.search.substring(1));

  let customerInfo = {};

  try {
    customerInfo = JSON.parse(dialerData.customerInfo);
  } catch (e) {
    customerInfo = {};
  }
  
  const getParams = {
    buyerId: customerInfo.buyer_id ? customerInfo.buyer_id : null,
    sessionId: dialerData.sessionId,
    campaignId: dialerData.campaignId,
    crtObjectId: dialerData.crtObjectId,
    userCrtObjectId: dialerData.userCrtObjectId,
    userId: dialerData.userId,
    customerId: dialerData.customerId,
    phone: dialerData.phone,
    referenceId: customerInfo.referenceid,
    agentName: dialerData.agentName,
    dispositionCode: dialerData.dispositionCode,
    dispositionAttr: dialerData.dispositionAttr
  };

  const validationRules = {
    'Visit Scheduled': {
      name: {
        required: true,
        editable: true
      },
      phone: {
        required: true,
        editable: false
      },
      alternatePhone: {
        required: false,
        editable: true
      },
      email: {
        required: true,
        editable: true
      },
      deliveryMode: {
        required: true,
        editable: true
      },
      deliveryPlace: {
        required: false,
        editable: true
      },
      deliveryTime: {
        required: true,
        editable: true
      },
      visitTime: {
        required: true,
        editable: true
      },
      visitDate: {
        required: true,
        editable: true
      },
      paymentMode: {
        required: false,
        editable: true
      },
      deliveryDate: {
        required: true,
        editable: true
      },
      deliveryState: {
        required: true,
        editable: true
      },
      deliveryCity: {
        required: true,
        editable: true
      },
      deliveryPincode: {
        required: true,
        editable: true
      },
      deliveryAddress1: {
        required: true,
        editable: true
      },
      deliveryAddress2: {
        required: true,
        editable: true
      },
      city: {
        required: true,
        editable: true
      },
      state: {
        required: true,
        editable: true
      },
      pincode: {
        required: true,
        editable: true
      },
      address1: {
        required: true,
        editable: true
      },
      address2: {
        required: true,
        editable: true
      },
      addressProof: {
        required: false,
        editable: true
      },
      nameOnRc: {
        required: false,
        editable: true
      }
    }
  };

  const [states, setStates] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [showError] = !getParams.phone ? useState(true) : useState(false);
  const [deliverySlots, setDeliverySlots] = useState({});
  const [buyerData, setBuyerData] = useState({});
  const [orderData, setOrderData] = useState({});
  const [car, setCar] = useState();
  const [carUrl, setCarUrl] = useState();
  const [refreshCar, setRefreshCar] = useState(false);
  const [loadCarData, setLoadCarData] = useState(false);
  const [appointmentId, setAppointmentId] = useState();
  const [dispositionData, setDispositionData] = useState();
  const [selectedCcDispositionId, setSelectedCcDispositionId] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isBuyerCFEligible, setIsBuyerCFEligible] = useState(false);
  const [isFinanceCustomization, setIsFinanceCustomization] = useState(false);
  const [buyer, setBuyer] = useState();
  const [isValidBuyer, setIsValidBuyer] = useState(false);
  const [disposition, setDisposition] = useState();
  const [defaultDisposition, setDefaultDisposition] = useState();
  const [subDispositionStatus, setSubDispositionStatus] = useState();
  const [consumerFinanceData, setConsumerFinanceData] = useState({});
  const [isValidDisposition, setIsValidDisposition] = useState(false);
  const [selectedPickupLocationIndex, setSelectedPickupLocationIndex] = useState(0);
  const [postData, setPostData] = useState(false);
  const [submitPostData, setSubmitPostData] = useState(false);
  const [redirectToThankYou, setRedirectToThankYou] = useState(false);
  const [submitErrors, setSubmitErrors] = useState();
  const [showSubmitErrors, setShowSubmitErrors] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [sendMessageError, setSendMessageError] = useState();
  const [leadInteractions, setLeadInteractions] = useState({});
  const [buyerType, setBuyerType] = useState('');
  // new state data
  const [bookingData, setBookingData] = useState();
  const [selectBookingItem, setSelectBookingItem] = useState();
  const [pincodeData, setPincodeData] = useState();
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [formValidationRules, setFormValidationRules] = useState({});
  const [customerDetails, setCustomerDetails] = useState({});

  const initialState = {
    name: '',
    alternatePhone: '',
    email: '',
    deliveryMode: '',
    deliveryAddress1: '',
    deliveryAddress2: '',
    deliveryState: null,
    deliveryCity: null,
    deliveryPincode: null,
    chargeDetails: null,
    financeOpted: null,
    paymentMode: '',
    financeTerms: null,
    bookingAmount: null,
    sharedCarLink: [],
    pickupTime: '',
    deliveryTime: '',
    bookingStatus: '',
    visitStatus: '',
    bankStatementStatus: '',
    sharedAppIds: [],
    bankStatementShared: false,
    buyerPhone: Number(getParams.phone),
    visitDate: '',
    visitTime: ''
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'name':
        return { ...state, name: action.name };
      case 'alternatePhone':
        return { ...state, alternatePhone: action.alternatePhone };
      case 'email':
        return { ...state, email: action.email };
      case 'deliveryMode':
        return {
          ...state,
          deliveryMode: action.deliveryMode
          // deliveryPlace: null,
          // deliveryAddress1: null,
          // deliveryAddress2: null,
          // deliveryCity: null,
          // deliveryPincode: null,
          // deliveryDate: null,
          // deliveryTime: null
        };
      case 'deliveryAddress1':
        return { ...state, deliveryAddress1: action.deliveryAddress1 };
      case 'deliveryAddress2':
        return { ...state, deliveryAddress2: action.deliveryAddress2 };
      case 'deliveryState':
        return {
          ...state,
          deliveryState: action.deliveryState,
          deliveryCity: null
        };
      case 'deliveryCity':
        return { ...state, deliveryCity: action.deliveryCity };
      case 'deliveryPincode':
        return { ...state, deliveryPincode: action.deliveryPincode };
      case 'deliveryDate':
        return { ...state, deliveryDate: action.deliveryDate };
      case 'deliveryTime':
        return { ...state, deliveryTime: action.deliveryTime };
      case 'visitDate':
        return { ...state, visitDate: action.visitDate };
      case 'visitTime':
        return { ...state, visitTime: action.visitTime };
      case 'deliveryPlace':
        return { ...state, deliveryPlace: action.deliveryPlace };
      case 'paymentMode':
        return { ...state, paymentMode: action.paymentMode };
      case 'nameOnRc':
        return { ...state, nameOnRc: action.nameOnRc };
      case 'addressProof':
        return { ...state, addressProof: action.addressProof };
      case 'address1':
        return { ...state, address1: action.address1 };
      case 'address2':
        return { ...state, address2: action.address2 };
      case 'state':
        return { ...state, state: action.state, city: null };
      case 'city':
        return { ...state, city: action.city };
      case 'pincode':
        return { ...state, pincode: action.pincode };
      case 'chargeDetails':
          return  {...state, chargeDetails: action.chargeDetails}
      case 'financeOpted':
        return  {...state, financeOpted: action.financeOpted}
      case 'financeTerms':
        return  {...state, financeTerms: action.financeTerms}
      case 'bookingAmount':
        return  {...state, bookingAmount: action.bookingAmount}
      case 'bookingStatus':
        return { ...state, bookingStatus: action.bookingStatus };
      case 'sharedCarLink':
        return  {...state, sharedCarLink: action.sharedCarLink}
      case 'pickupTime':
        return  {...state, pickupTime: action.pickupTime}
      case 'bankStatementStatus':
        return { ...state, bankStatementStatus: action.bankStatementStatus };
      case 'sharedAppIds':
        return  {...state, sharedAppIds: action.sharedAppIds}
      case 'bankStatementShared':
        return  {...state, bankStatementShared: action.bankStatementShared}
      case 'restore':
        return { ...initialState, ...action.data };
      default:
        throw state;
    }
  }

  const [formState, formDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    cityService.fetchStates().then((resp) => {
      const statesArr = (
        (resp.data || []).data || []
      ).map(({ stateName, stateId }) => ({ label: stateName, value: stateId }));
      setStates(statesArr);
    });
    // Fetch Dispositions
    DataService.fetchOutboundBuyerDisposition()
      .then((resp) => {
        setDispositionData(resp);
      })
      .catch((error) => {
        setDispositionData(null);
      });

    DataService.fetchLeadInteractionHistory(getParams.phone)
      .then((leadResponse) => {
        setLeadInteractions(leadResponse);
      })
      .catch(err => {
        console.log("lead api error", err);
      });
    DataService.fetchCustomerDetails(getParams.phone, DispositionConstants.CC_OUTBOUND_BUYER)
      .then((cdResponse) => {
        setCustomerDetails(cdResponse);
      })
      .catch(err => {
        console.log("customer-details api error", err);
      });
  }, []);

  useEffect(()=> {
    if (showLoader) {
      DataService.fetchCCOBCustomerBookings(getParams.phone, CAMPAIGN_TYPE.PostBooking)
        .then((bookings) => {
          if (!!bookings && bookings.length > 0) {
            setBookingData(bookings);
            !selectBookingItem && setSelectBookingItem(bookings[0]);
          }
        })
        .catch(err => {
          console.log("fetchCCOBCustomerBookings, err", err);
      });
    }
  },[showLoader]);

  useEffect(() => {
    !!selectBookingItem && DataService.fetchCCOBBookingData(selectBookingItem.bookingId)
      .then((resp)=> {
        const {appointmentId, deliveryPincode, deliveryPlace : selectedDeliveryPlace} = resp;
        setBuyerData(resp);
        const formData = { ...resp };
        delete formData.stores;
        formDispatch({
          type: 'restore',
          data: {
            ...resp
          }
        });
        // pin code data
        DataService.fetchPincodeData(appointmentId, deliveryPincode)
          .then((pincodeResponse)=> {
            const {data : {data}} = pincodeResponse;
            setPincodeData(data);
            const {pickup} = data || {};
            let itemIndex = !!data && !!pickup && pickup.findIndex(x => x.locationId.toString() === selectedDeliveryPlace);
            if (itemIndex > -1) {
              setSelectedPickupLocationIndex(itemIndex);
              if (formState.deliveryMode === deliveryModeValues.HUB_PICKUP) {
                formDispatch(
                {
                  type: 'deliveryAddress1',
                  deliveryAddress1 : pickup[itemIndex].location
                });
  
                formDispatch(
                {
                  type: 'deliveryAddress2',
                  deliveryAddress2 : pickup[itemIndex].address
                });

                formDispatch(
                {
                  type: 'deliveryPlace',
                  deliveryPlace : pickup[itemIndex].locationId
                });

                formDispatch(
                {
                  type: 'pickupTime',
                  pickupTime : pickup[itemIndex].availabilityDate
                });
              }
            }
          })
        //Order summary
        !!selectBookingItem && DataService.fetchCCOBBookingOrderSummary(selectBookingItem.bookingId)
          .then((orderResponse)=> {
            const {data : {data, message}} = orderResponse || {};
            const {financeOpted, financeTerms, chargeDetails, bookingAmount, pincodeServiceable, status, deliveryTime, vehiclePrice, userId} = data || {};
            if (status === "CREATED" || status === "BOOKED") {
              setIsFormDisabled(false)
            } else {
              setIsFormDisabled(true);
            }
            if (financeOpted && pincodeServiceable && !!financeTerms ) {
              formDispatch(
                {
                  type: 'paymentMode',
                  paymentMode: paymentModesValues.CARS24_FINANCE
                }
              )
            } else {
                formDispatch(
                  {
                    type: 'paymentMode',
                    paymentMode: paymentModesValues.PAY_ON_DELIVERY
                  }
                )  
              }
            //Rest order data
            formDispatch(
              {
                type: 'financeOpted',
                financeOpted
              }
            )
            formDispatch(
              {
                type: 'financeTerms',
                financeTerms
              }
            )
            formDispatch(
              {
                type: 'chargeDetails',
                chargeDetails
              }
            )
            formDispatch(
              {
                type: 'bookingAmount',
                bookingAmount
              }
            )
            formDispatch(
              {
              type: 'bookingStatus',
              bookingStatus: status
              }
            )
            formDispatch(
              {
                type: 'deliveryTime',
                deliveryTime
              }
            )
            if (message.toUpperCase() === CF_SUCCESS_MESSAGE.toUpperCase()) {
              setIsBuyerCFEligible(true);
            } else {
              setIsBuyerCFEligible(false);
            }
            setOrderData(data);
            // Bank statement status
            if (!!userId) {
              let bsStatus = "Pending";
              DataService.fetchBankStatementStatus(userId)
                .then(bsResponse =>{
                  const {uploaded} = bsResponse || {};
                  if (uploaded) {
                    bsStatus= "Completed";
                  }
                  formDispatch(
                    {
                      type: 'bankStatementStatus',
                      bankStatementStatus : bsStatus
                    })
                })
              }
            //Delivery Slots
            const interCity = getShippingCharge(chargeDetails) > 0 ? true : false;
            if (!!deliveryPincode) {
              DataService.fetchBookingDeliverySlots(selectBookingItem.bookingId, deliveryPincode, interCity)
              .then((dsResp)=>{
                setDeliverySlots(dsResp);
              })
              .catch((err)=> {
                setDeliverySlots(null);
                console.log("Booking Delivery slots API err:", err);
              })
            } else {
              setDeliverySlots(null);
            }
            if (!pincodeServiceable && financeOpted) {
              setIsFinanceCustomization(true);
              setIsBuyerCFEligible(false);
            }
            if (vehiclePrice > 0) {
              ConsumerFinanceService().fetchFinanceDetail(data.vehiclePrice).then((cfResponse)=>{
                const {data} = cfResponse || {};
                setConsumerFinanceData(data); 
              });
            }
          })
      })
      setShowLoader(false);
  }, [selectBookingItem]);

useEffect(()=> {
  setFormValidationRules();
  const dispositionStatus = disposition && disposition.dispositionStatus;
  if (!!dispositionStatus && dispositionStatus === "Visit Scheduled") {
    setFormValidationRules(validationRules[dispositionStatus]);
  }
}, [disposition, subDispositionStatus]);

useEffect(() => {
  const dispositionStatus = !!disposition && disposition.dispositionStatus;
  let hasError = false;
  if ((!!dispositionStatus && dispositionStatus === "Visit Scheduled")) {
    let ignore = {
      [deliveryModeValues.HUB_PICKUP]: [
        'deliveryAddress1',
        'deliveryAddress2',
        'deliveryState',
        'deliveryCity',
        'deliveryPincode',
        'visitTime'
      ],
      [deliveryModeValues.HOME_DELIVERY]: [
        'deliveryPlace', 
        'alternatePhone']
    };
    if (!!formState.deliveryMode) {
      const allFields = [
        'name',
        'email',
        'deliveryMode',
        'deliveryAddress1',
        'deliveryAddress2',
        'deliveryState',
        'deliveryCity',
        'deliveryPincode',
        'deliveryPlace',
        'visitDate',
        'visitTime'
      ].filter((val) => {
        return ignore[formState.deliveryMode].indexOf(val) === -1;
      });
      // 'deliveryTime',
      const fieldsRequired = allFields.filter(
        (val) => {
          return validationRules[dispositionStatus] && validationRules[dispositionStatus][val] ? validationRules[dispositionStatus][val].required : false
        }
      );
      hasError =
        fieldsRequired.filter((val) => {
          return !formState[val];
        }).length > 0;
    } else {
      hasError = true;
    }
  }
  if (postData && !hasError) {
    setSubmitPostData(true);
  } else {
    setPostData(false);
  }
}, [postData]);

useEffect(() => {
  if (submitPostData) {
    setSubmitErrors();
    setShowSubmitErrors(false);
    // if (isValidDisposition && isValidBuyer && car) {
    if (isValidDisposition) {

      const formData = {
        ...dialerData,
        customerInfo,
        ...buyerData,
        ...formState,
        dispositionId: disposition.dispositionId,
        additionalFields: disposition.addtionalFields,
        deliveryDate: new Date(formState.deliveryDate)
      };
      if (formState.deliveryMode === deliveryModeValues.HUB_PICKUP) {
        delete formData.deliveryAddress1;
        delete formData.deliveryAddress2;
        delete formData.deliveryState;
        delete formData.deliveryCity;
        delete formData.deliveryPincode;
      }
      if (formState.deliveryMode === deliveryModeValues.HOME_DELIVERY) {
        delete formData.deliveryPlace;
      }

      delete formData.stores;
      delete formData.bookingDate;

      setIsSubmit(true);
      DataService.submitOutboundBuyerData(formData).then((response) => {
        if (response.errors && response.errors.length > 0) {
          setSubmitErrors(response.errors);
          setShowSubmitErrors(true);
          setPostData(false);
          setSubmitPostData(false);
          setIsSubmit(false);
        } else {
          reset();
          setRedirectToThankYou(true);
          setSubmitErrors();
          setShowSubmitErrors(false);
        }
      });
    } else {
      setPostData(false);
      setSubmitPostData(false);
    }
  }
}, [submitPostData]);

  const reset = () => {
    setDisposition();
    setPostData(false);
    setSubmitPostData(false);
    setIsValidDisposition(false);
    setIsValidBuyer(false);
    setIsSubmit(false);
  };

  if (redirectToThankYou) {
    return <Redirect to='/thank-you' />;
  }

  if (showLoader) {
    return (
      <div className='container text-center loader'>
        <img src={Loader} alt='Loading' className='center-block' />
      </div>
    );
  }

  if (showError) {
    return (
      <div className='no-results container-fluid'>
        <img src={NoResult} alt='no record' />
      </div>
    );
  }
  return (
    <>
      <div className='container-fluid'>
        <div className='row mt-3'>
          <div className='col-8'>
            <div className={`main_section_disp block ${styles.outboundBlock}`}>
              {!showLoader && <OutBoundBuyerDetails
                bookingData = {bookingData}
                buyerData={buyerData}
                states={states}
                form={{ state: formState, dispatch: formDispatch }}
                orderData={orderData}
                setOrderData={setOrderData}
                pincodeData={pincodeData}
                setPincodeData={setPincodeData}
                selectedPickupLocationIndex={selectedPickupLocationIndex}
                setSelectedPickupLocationIndex={setSelectedPickupLocationIndex}
                setShowLoader={setShowLoader}
                rules={formValidationRules}
                isBuyerCFEligible={isBuyerCFEligible}
                isFinanceCustomization={isFinanceCustomization}
                setIsBuyerCFEligible={setIsBuyerCFEligible}
                consumerFinanceData={consumerFinanceData}
                setSelectBookingItem={setSelectBookingItem}
                selectBookingItem={selectBookingItem}
                deliverySlots={deliverySlots}
                setDeliverySlots={setDeliverySlots}
                isFormDisabled={isFormDisabled}
                setIsFinanceCustomization={setIsFinanceCustomization}
                customerDetails={customerDetails}
              />}
            </div>
          </div>
          <div className='col-4'>
            {dispositionData && (
              <DispositionDetails
                dispositionData={dispositionData}
                setDisposition={setDisposition}
                defaultDisposition={defaultDisposition}
                setSubDispositionStatus={setSubDispositionStatus}
                subDispositionStatus={subDispositionStatus}
                isSubmit={isSubmit}
                setIsValidDisposition={setIsValidDisposition}
                setPostData={setPostData}
                submitErrors={submitErrors}
                showSubmitErrors={showSubmitErrors}
                setShowSubmitErrors={setShowSubmitErrors}
                car={car}
                buyerDiscountedPrice={car ? car.discountedPrice : 0}
                selectedCcDispositionId={selectedCcDispositionId}
                setSelectedCcDispositionId={setSelectedCcDispositionId}
                ctaText={'Submit & Dispose'}
              />
            )}
            <OrderSummaryDetails
                buyerData={buyerData}
                form={{ state: formState, dispatch: formDispatch }}
                orderData={orderData}
                pincodeData={pincodeData}
                selectedPickupLocationIndex={selectedPickupLocationIndex}
                selectBookingItem={selectBookingItem}
              />
          </div>
        </div>
        <div className='row mb-3'>
            {!showLoader && <LeadInteractionHistory leadInteractions={leadInteractions}/>}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ dealerInformation: { detail } }) => {
  return {
    detail
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchStates: StateActions.fetchStates,
      fetchCities: CityActions.fetchCities
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CCOutboundBuyer);
