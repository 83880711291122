import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserForm from './component';
import { fetchZonesListConnected } from '../zones-grid/actions';
import {
  fetchUser,
  addUserConnected,
  setUserInfoByIndexConnected,
  editUserConnected,
  resetUserInfoByIndexConnected
} from './actions';

const mapStateToProps = (state) => {
  const { zoneInfo, userInfo, UserGridInfo } = state;
  return { zoneInfo, userInfo, UserGridInfo };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUserConnected: fetchUser,
      addUserConnected,
      fetchZonesListConnected,
      setUserInfoByIndexConnected,
      editUserConnected,
      resetUserInfoByIndexConnected
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
