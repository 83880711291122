import baseApi from './baseApi';
import dataApi from './dataService';
import authApi from './authService';
import reportBaseApi from './reportBaseApi';
import reportApi from './reportService';
import systemReallocationApi from './systemReallocationService'
import buddyScoringApi from './buddyScoringService'

const auth = authApi(baseApi);
const data = dataApi(baseApi);
const report = reportApi(reportBaseApi)
const systemReallocation=systemReallocationApi(baseApi)
const buddyScoring=buddyScoringApi(baseApi)

export {
  baseApi,
  auth as AuthService,
  data as DataService,
  report as ReportService,
  systemReallocation as systemReallocationApi,
  buddyScoring as buddyScoringApi
};