export const RC_FILE_STATUS = [
  {
    label: 'RC Transfer Status',
    apiKey: 'rc_transfer_status',
    type: 'dropdown',
    dropDownName: 'FILE_STATUS'
  }, 
  {},
  {
    label: 'Expected RC Completion Date',
    apiKey: 'expected_rc_completion_date',
    type: 'date',
    disabled: false
  },
  {
    label: 'File to RC Team Date',
    apiKey: 'file_rc_team_date',
    type: 'date',
    disabled: false
  },
  {
    label: 'Handed Over to First Agent Date',
    apiKey: 'handover_to_first_agent',
    type: 'date',
    disabled: true
  },
  {
    label: 'Agent Handover Delay',
    apiKey: 'agent_handover_delay',
    type: 'delay_dropdown',
  },
  {
    label: 'Seller Party Peshi Scheduled Date',
    apiKey: 'seller_party_peshi_scheduled',
    type: 'date',
    disabled: false
  },
  { 
  },
  {
    label: 'Seller Party Peshi Completion Date',
    apiKey: 'seller_party_peshi_completed', 
    type: 'date',
    disabled: false
  },
  {
    label: 'Seller Party Peshi Delay',
    apiKey: 'seller_party_peshi_delay',
    type: 'delay_dropdown',
  },
  {
    label: 'Buyer Party Peshi Scheduled Date',
    apiKey: 'buyer_party_peshi_scheduled',
    type: 'date',
    disabled: false
  },
  { 
  },
  {
    label: 'Buyer Party Peshi Completion Date',
    apiKey: 'buyer_party_peshi_completed', 
    type: 'date',
    disabled: false
  },
  
  {
    label: 'Buyer Party Peshi Delay',
    apiKey: 'buyer_party_peshi_delay',
    type: 'delay_dropdown',
  },
  {
    label: 'Aadhaar Status',
    apiKey: 'aadhaarStatus',
    type: 'dropdown',
    dropDownName: 'AADHAAR_STATUSES'
  },
  {},
  {
    label: 'Aadhaar Change Completion Date',
    apiKey: 'aadhar_change_completion_date', 
    type: 'date',
    disabled: false
  },
  
  {
    label: 'Aadhaar Change Delay',
    apiKey: 'aadhar_change_delay',
    type: 'delay_dropdown',
  },
  {
    label: 'RTO NOC Issue Copy',
    apiKey: 'rto_noc_id',
    fileType: 'rto_noc',
    type: 'upload',
    dependentKey: 'rto_noc_issue_date'
  },
  {},
  {
    label: 'RTO NOC Issue Date',
    apiKey: 'rto_noc_issue_date',
    type: 'date',
    disabled: false
  },
  {
    label: 'RTO NOC Issue Delay',
    apiKey: 'rto_noc_issue_delay',
    type: 'delay_dropdown',
  },
  {
    label: 'E-receipt',
    apiKey: 'e_receipt_id',
    fileType: 'e_receipt',
    type: 'upload',
    dependentKey: 'e_receipt_issue_date'
  },
  {},
  {
    label: 'E-receipt Issue Date',
    apiKey: 'e_receipt_issue_date',
    type: 'date',
    disabled: false,
    dependentKey: 'e_receipt_available'
  },
  {
    label: 'E-receipt Issue Delay',
    apiKey: 'e_receipt_delay',
    type: 'delay_dropdown',
  },
  
  {
    label: 'Buyer RC',
    apiKey: 'buyer_rc_id',
    fileType: 'buyer_rc',
    type: 'upload',
    dependentKey: 'rc_transfer_completion_date'
  },
  {},
  {
    label: 'RC Transfer Completion Date',
    apiKey: 'rc_transfer_completion_date',
    type: 'date',
    disabled: true
  },
  {
    label: 'RC Transfer Delay',
    apiKey: 'rc_transfer_delay',
    type: 'delay_dropdown',
  },
  {
    label: 'Physical RC',
    apiKey: 'physical_rc_id',
    fileType: 'physical_rc',
    type: 'upload',
    dependentKey: 'physical_rc_receiving_date'
  }, 
  {},
  {
    label: 'Physical RC Receiving Date',
    apiKey: 'physical_rc_receiving_date',
    type: 'date',
    disabled: true
  }, 
  {
    label: 'Physical RC Delay',
    apiKey: 'physical_rc_delay',
    type: 'delay_dropdown',
  },
  {
    label: 'RC Courier address',
    apiKey: 'courierAddress',
    type: 'textarea',
    disabled: true
  },
  {
    label: 'RC Handover Method',
    apiKey: 'rc_handover_buyer_method',
    type: 'dropdown',
    dropDownName: 'HANDOVER_METHODS_BUYER'
  },
  {
    label: 'Delivery Status',
    apiKey: 'speedpost_delivery_status',
    type: 'dropdown',
    dropDownName: 'SPEED_POST_DELIVERY_STATUS'
  },
  {},
  {
    label: 'RC Dispatch Date',
    apiKey: 'speedpost_dispatch_date',
    type: 'date',
    disabled: false
  },
  {
    label: 'RC Handover Delay',
    apiKey: 'rc_handover_delay',
    type: 'delay_dropdown',
  },
  {
    label: 'Dispatch Link',
    apiKey: 'speedpost_dispatch_link',
    type: 'dropdown',
    dropDownName: 'DISPATCH_LINK'
  },
  {
    label: 'Tracking Id',
    apiKey: 'speedpost_tracking_id',
    type: 'text'
  },
];

export const FILE_STATUS = [
  { label: 'File Received', value: 'File Received' },
  { label: 'Handed over to the Agent', value: 'Handed over to the Agent' },
  { label: 'E-Receipt Issued', value: 'E-Receipt Issued' },
  { label: 'RC Verification Pending', value: 'RC Verification Pending'},
  { label: 'RC Verification To Discuss', value: 'RC Verification To Discuss'},
  { label: 'RC Verification Rejected', value: 'RC Verification Rejected'},
  { label: 'RC transfer completed', value: 'RC transfer completed' },
  { label: 'Handed over to Buyer', value: 'Handed over to Buyer' },
  { label: 'Physical RC received', value: 'Physical RC received' },

];
export const SPEED_POST_DELIVERY_STATUS = [
  { label: 'Dispatch Pending', value: 'Dispatch Pending' },
  { label: 'Dispatched', value: 'Dispatched' }
];

export const HANDOVER_METHODS_BUYER_VALUES = {
  DIRECT_RTO: 'Direct RTO',
  SPEED_POST: 'Speed post',
  SALES: 'Delivered to Sales',
  NBFC: 'Delivered to NBFC',
  TAKEN_BY_CUSTOMER: 'Delivery taken by Customer'
};
export const HANDOVER_METHODS_BUYER = [
  { label: 'Direct RTO', value: HANDOVER_METHODS_BUYER_VALUES.DIRECT_RTO },
  { label: 'Delivery by speed post/ courier', value: HANDOVER_METHODS_BUYER_VALUES.SPEED_POST },
  { label: 'Delivered to Sales', value: HANDOVER_METHODS_BUYER_VALUES.SALES },
  { label: 'Delivered to NBFC', value: HANDOVER_METHODS_BUYER_VALUES.NBFC },
  { label: 'Delivery taken by Customer', value: HANDOVER_METHODS_BUYER_VALUES.TAKEN_BY_CUSTOMER }
];

export const CHALLAN_STATUS = [
  { label: 'Pending at Seller', value: 'Pending at Seller' },
  { label: 'Pending at Buyer', value: 'Pending at Buyer' },
  { label: 'Pending at C2B', value: 'Pending at C2B' },
  { label: 'Pending at C2C', value: 'Pending at C2C' },
  { label: 'Cleared', value: 'Cleared' },
  { label: 'Not Applicable', value: 'Not Applicable' }
];

export const SELLER_PARTY_PESHI_DELAY_STATUS = [
  { label: 'Not Contactable', value: 'Not Contactable' },
  { label: 'Not Available for PP', value: 'Not Available for PP' },
  { label: 'Seller shifted outstation', value: 'Seller shifted outstation' },
  { label: 'Not willing to come', value: 'Not willing to come' },
  { label: 'Demanding Pickup and drop', value: 'Demanding Pickup and drop' },
  { label: 'Medical Issues/ Senior Citizen', value: 'Medical Issues/ Senior Citizen' },
  { label: 'Lockdown', value: 'Lockdown' },
  { label: 'Holdback for PP already released', value: 'Holdback for PP already released' },
  { label: 'Processing without PP', value: 'Processing without PP' },
  { label: 'Seller asking to reschedule PP', value: 'Seller asking to reschedule PP' },
  { label: 'No prior communication for PP requirement', value: 'No prior communication for PP requirement' },
  { label: 'PP already done but PP Documents pending from C2B', value: 'PP already done but PP Documents pending from C2B' }
];

export const BUYER_PARTY_PESHI_DELAY_STATUS = [
  { label: 'Not Contactable', value: 'Not Contactable' },
  { label: 'Not Available for PP', value: 'Not Available for PP' },
  { label: 'Buyer shifted outstation', value: 'Buyer shifted outstation' },
  { label: 'Not willing to come', value: 'Not willing to come' },
  { label: 'Demanding Pickup and drop', value: 'Demanding Pickup and drop' },
  { label: 'Medical Issues/ Senior Citizen', value: 'Medical Issues/ Senior Citizen' },
  { label: 'Lockdown', value: 'Lockdown' },
  { label: 'Processing without PP', value: 'Processing without PP' },
  { label: 'Buyer asking to reschedule PP', value: 'Buyer asking to reschedule PP' },
  { label: 'No prior communication for PP requirement', value: 'No prior communication for PP requirement' },
];

export const AADHAAR_STATUSES = [
  {label: 'Aadhar Initiated', value: 'Aadhar Initiated'},
  {label: 'Aadhar Change Complete', value: 'Aadhar Change Complete'},
  {label: 'Reverse Aadhar Change Initiated', value: 'Reverse Aadhar Change Initiated'},
  {label: 'Reverse Aadhar Change Completed', value: 'Reverse Aadhar Change Completed'},
];

export const DISPATCH_LINK = [
  {label: 'DTDC', value: 'DTDC'},
  {label: 'Bluedart', value: 'Bluedart'},
  {label: 'Indian Speed Post', value: 'Indian Speed Post'},
  {label: 'Professional Courier', value: 'Professional Courier'}
];

export const OPTIONS = {
  FILE_STATUS: FILE_STATUS,
  HANDOVER_METHODS_BUYER: HANDOVER_METHODS_BUYER,
  SPEED_POST_DELIVERY_STATUS: SPEED_POST_DELIVERY_STATUS,
  CHALLAN_STATUS: CHALLAN_STATUS,
  DISPATCH_LINK: DISPATCH_LINK,
  SELLER_PARTY_PESHI_DELAY_STATUS: SELLER_PARTY_PESHI_DELAY_STATUS,
  BUYER_PARTY_PESHI_DELAY_STATUS: BUYER_PARTY_PESHI_DELAY_STATUS,
  AADHAAR_STATUSES: AADHAAR_STATUSES
};
