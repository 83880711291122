export const TRX_HEADERS = [
  {
    label: 'User Email',
    headerKey: 'user_email',
    colWidth: 'col-2'
  },
  {
    label: 'Date & Time',
    headerKey: 'date',
    colWidth: 'col-2',
    dataType: 'datetime'
  },
  {
    label: 'Trx Mode',
    headerKey: 'trx_mode',
    colWidth: 'col-1'
  },
  {
    label: 'Trx Date',
    headerKey: 'trx_date',
    colWidth: 'col-1',
    dataType: 'date' //todo:sprint-11 Ask backend to correct date format
  },
  {
    label: 'ARN Number',
    headerKey: 'ref_no',
    colWidth: 'col-2',
  },
  {
    label: 'Bank',
    headerKey: 'bank_name',
    colWidth: 'col-1'
  },
  {
    label: 'UTR',
    headerKey: 'utr',
    colWidth: 'col-1'
  },
  {
    label: 'Trx Type',
    headerKey: 'trx_type',
    colWidth: 'col-1'
  },
  {
    label: 'Amount',
    headerKey: 'amount',
    colWidth: 'col-1'
  }
  //   ,
  //   {
  //     label: 'Action',
  //     // headerKey: 'stockOutStoreLabel',
  //     colWidth: 'col-4',
  //     dataType: 'button'
  //   }
];

//TODO :-This Would be drive from meta Data
export const PAYMENT_MODES = {
  CASH: 1,
  CHEQUE: 2,
  UPI: 4,
  FUND_TRANSFER: 3,
  WALLET: 5,
  CARD: 6,
  NBFC: 7
};
