export const SIDEBAR_GROUP = {
  ORDER_MANAGEMENT: { key: 'orderManagement', label: 'Order Management' },
  FINANCE_MANAGEMENT: { key: 'financeManagement', label: 'Finance Management' },
  DEALER_MANAGEMENT: { key: 'dealerManagement', label: 'Dealer Management' },
  CONFIGURATION: { key: 'configuration', label: 'Configuration' }
};

export const getSidebarList = (adminRole) => ([
  { key: 'login', label: 'Login', path: '/login', isVisible: () => false },
  { key: 'thankYou', label: 'Thank You', path: '/thank-you', isVisible: () => false },
  { key: 'error', label: 'Error', path: '/error', isVisible: () => false },
  { key: 'home', label: 'Home', path: '/', isVisible: () => false },
  {
    key: 'publish',
    label: 'Publish',
    path: '/upload/publish',
    isVisible: () => true,
    group: SIDEBAR_GROUP.CONFIGURATION.key
  },
  {
    key: 'stores',
    label: 'C2C Stores',
    path: '/stores',
    isVisible: () => true,
    group: SIDEBAR_GROUP.CONFIGURATION.key
  },
  {
    key: 'stores-add',
    label: 'C2C Stores',
    path: '/store/add',
    isVisible: () => false,
    group: SIDEBAR_GROUP.CONFIGURATION.key
  },
  {
    key: 'stores-details',
    label: 'C2C Stores',
    path: '/store/:id',
    isVisible: () => false,
    group: SIDEBAR_GROUP.CONFIGURATION.key
  },
  {
    key: 'zones-details',
    label: 'ZoneDetail',
    path: '/zones/:id',
    isVisible: () => false,
    group: SIDEBAR_GROUP.CONFIGURATION.key
  },
  {
    key: 'zones-add',
    label: 'AddZone',
    path: '/zones/add',
    isVisible: () => false,
    group: SIDEBAR_GROUP.CONFIGURATION.key
  },
  {
    key: 'rto',
    label: 'RTO',
    path: '/rto',
    isVisible: () => true,
    group: SIDEBAR_GROUP.CONFIGURATION.key
  },
  {
    key: 'rto-details',
    label: 'RTO',
    path: '/rto/:id',
    isVisible: () => false,
    group: SIDEBAR_GROUP.CONFIGURATION.key
  },
  {
    key: 'orders',
    label: ' C2C Orders',
    path: '/orders',
    isVisible: () => true,
    group: SIDEBAR_GROUP.ORDER_MANAGEMENT.key
  },
  {
    key: 'orders-add',
    label: ' C2C Orders',
    path: '/orders/add',
    isVisible: () => false,
    group: SIDEBAR_GROUP.ORDER_MANAGEMENT.key
  },
  {
    key: 'orders-stock-details',
    label: ' C2C Orders',
    path: '/order/stock/:id',
    isVisible: () => false,
    group: SIDEBAR_GROUP.ORDER_MANAGEMENT.key
  },
  {
    key: 'rc-tracker',
    label: 'RC Management',
    path: '/rc-tracker',
    isVisible: () => true,
    group: SIDEBAR_GROUP.ORDER_MANAGEMENT.key
  },
  {
    key: 'rc-tracker-details',
    label: 'RC Management',
    path: '/rc-tracker/:id',
    isVisible: () => false,
    group: SIDEBAR_GROUP.ORDER_MANAGEMENT.key
  },
  {
    key: 'rc-tracker-others',
    label: 'RC Management (Others)',
    path: '/rc-tracker-others',
    isVisible: () => true,
    group: SIDEBAR_GROUP.ORDER_MANAGEMENT.key
  },
  {
    key: 'rc-tracker-others-details',
    label: 'RC Management (Others)',
    path: '/rc-tracker-others/:id',
    isVisible: () => false,
    group: SIDEBAR_GROUP.ORDER_MANAGEMENT.key
  },
  {
    key: 'rc-tracker-others-details',
    label: 'RC Management (Others)',
    path: '/rc-tracker-others-details/add',
    isVisible: () => false,
    group: SIDEBAR_GROUP.ORDER_MANAGEMENT.key
  },
  {
    key: 'hp-management',
    label: 'HP Management',
    path: '/hp-management',
    isVisible: () => true,
    group: SIDEBAR_GROUP.ORDER_MANAGEMENT.key
  },
  {
    key: 'hp-management-add',
    label: 'HP Management',
    path: '/hp-management/add',
    isVisible: () => false,
    group: SIDEBAR_GROUP.ORDER_MANAGEMENT.key
  },
  {
    key: 'hp-management-details',
    label: 'HP Management',
    path: '/hp-management/:id',
    isVisible: () => false,
    group: SIDEBAR_GROUP.ORDER_MANAGEMENT.key
  },
  {
    key: 'dealers',
    label: 'Dealers',
    path: '/dealers',
    isVisible: () => true,
    group: SIDEBAR_GROUP.DEALER_MANAGEMENT.key
  },
  {
    key: 'task-management',
    label: 'Task Management',
    path: '/task-management',
    isVisible: () => true,
    group: SIDEBAR_GROUP.ORDER_MANAGEMENT.key
  },
  {
    key: 'dealer-details',
    label: 'Dealers',
    path: '/dealer/:id',
    isVisible: () => false
  },
  { key: 'dealer-add', label: 'Dealers', path: '/dealer/add', isVisible: () => false },
  { key: 'ardealer', label: 'ArDealers', path: '/ardealer', isVisible: () => false },
  {
    key: 'ardealer-add',
    label: 'ArDealers',
    path: '/ardealer-add/:dealerId',
    isVisible: () => false
  },
  {
    key: 'ardealer-addId',
    label: 'ArDealers',
    path: '/ardealer-add/:dealerId?/:arId?',
    isVisible: () => false
  },
  {
    key: 'finance-grid',
    label: 'Finance',
    path: '/finance',
    isVisible: () => true,
    group: SIDEBAR_GROUP.FINANCE_MANAGEMENT.key
  },
  {
    key: 'finance-refunds',
    label: 'Auto Refunds',
    path: '/finance/auto-refunds',
    isVisible: () => true,
    group: SIDEBAR_GROUP.FINANCE_MANAGEMENT.key
  },
  {
    key: 'finance-upload',
    label: 'Finance Upload',
    path: '/finance/upload',
    isVisible: () => true,
    group: SIDEBAR_GROUP.FINANCE_MANAGEMENT.key
  },
  {
    key: 'finance-tracker',
    label: 'Finance Tracker',
    path: '/finance-tracker/:id',
    isVisible: () => false
  },
  {
    key: 'city',
    label: 'City',
    path: '/city',
    isVisible: () => true,
    group: SIDEBAR_GROUP.CONFIGURATION.key
  },
  {
    key: 'city-edit',
    label: 'City',
    path: '/city/:id',
    isVisible: () => false,
    group: SIDEBAR_GROUP.CONFIGURATION.key
  },
  {
    key: 'agent-grid',
    label: 'Agents',
    path: '/agents',
    isVisible: () => true,
    group: SIDEBAR_GROUP.CONFIGURATION.key
  },
  {
    key: 'documents',
    label: 'Documents',
    path: '/documents',
    isVisible: () => true,
    group: SIDEBAR_GROUP.CONFIGURATION.key
  },
  {
    key: 'documents-region-rules',
    label: 'Document Region Rules',
    path: '/documents/:regionId',
    isVisible: () => false
  },
  {
    key: 'documents-list',
    label: 'Documents Grid',
    path: '/documents/list',
    isVisible: () => false
  },
  {
    key: 'document-rejection-reasons',
    label: 'Document Rejection Reasons',
    path: '/documents/add-rejection-reasons/:documentId',
    isVisible: () => false
  },
  {
    key: 'agent-add',
    label: 'Agents',
    path: '/agent/add'
  },
  {
    key: 'agent-edit',
    label: 'Agents',
    path: '/agent/:id',
    isVisible: () => false
  },
  {
    key: 'rto-delay-updates',
    label: 'RTO Delay Updates',
    path: '/rto-delay-updates',
    isVisible: () => true,
    group: SIDEBAR_GROUP.CONFIGURATION.key
  },
  {
    key: 'case-types-rules',
    label: 'Case Types',
    path: '/case-types',
    isVisible: () => true,
    group: SIDEBAR_GROUP.CONFIGURATION.key
  },
  {
    key: 'agent-region-allocation',
    label: 'Region Allocation',
    path: '/agent-region-allocation',
    isVisible: () => true,
    group: SIDEBAR_GROUP.CONFIGURATION.key
  },
  {
    key: 'vahan-api-frequency',
    label: 'Vahan API Frequency',
    path: '/vahan-api-frequency',
    isVisible: () => true,
    group: SIDEBAR_GROUP.CONFIGURATION.key
  },
]);

const ALL_ACCESS = [
  'login',
  'thankYou',
  'error',
  'orders',
  'orders-add',
  'orders-stock-details',
  'stores',
  'stores-add',
  'stores-details',
  'rto',
  'rto-details',
  'rc-tracker',
  'rc-tracker-details',
  'rc-tracker-others',
  'rc-tracker-others-details',
  'hp-management',
  'hp-management-details',
  'dealers',
  'dealer-add',
  'dealer-details',
  'task-management',
  'publish',
  'zones-details',
  'zones-add',
  'ardealer',
  'ardealer-add',
  'ardealer-addId',
  'user-add',
  'user-edit',
  'users-grid',
  'finance-grid',
  'finance-upload',
  'finance-refunds',
  'finance-tracker',
  'city',
  'city-edit',
  'agent-grid',
  'agent-add',
  'agent-edit',
  'documents',
  'documents-region-rules',
  'rto-delay-updates',
  'case-types-rules',
  'documents-list',
  'document-rejection-reasons',
  'agent-region-allocation',
  'vahan-api-frequency',
];

export const ROLE_ROUTES_MAPPING = {
  DEFAULT: ALL_ACCESS,
  CC_AGENT_FOR_C2C: ['login', 'thankYou', 'error'],
  DSA_AGENT_FOR_C2C: ['login', 'thankYou', 'error']
};
