import React, { useState } from 'react';
import styles from './styles.module.css';
//import useToggle from "../../../hooks/use-toggle";
import PropTypes from 'prop-types';
const MAX_LENGTH = 255;

const CTextArea = ({
  value = '',
  id = '',
  label = '',
  onChange,
  isAutoFocused = false,
  onBlurCallback = () => {},
  maxLength = MAX_LENGTH,
  validator = () => {},
  errorMessage = 'Maximum Characters Exceeded',
  inputRef,
  placeholder = null,
  onFocusCallback = () => {},
  readOnly,
  disabled,
  required,
  type,
  max,
  min,
  name = '',
  version = null
}) => {
  const [isTouched, setIsTouched] = useState(false);
  //const [isFocused, setIsFocused] = useToggle(isAutoFocused);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(errorMessage);

  const onChangeHandler = (e) => {
    const inputValue = e.target.value;
    if (validator(inputValue)) {
      setHasError(false);
      setError('');
    }
    if (inputValue && inputValue.length >= MAX_LENGTH) {
      setHasError(true);
      setError('Maximum Characters Exceeded');
    } else {
      setHasError(false);
      setError('');
    }
    onChange(e);
  };

  const onFocus = () => {
    // setIsFocused();
    setIsTouched(true);
    onFocusCallback();
  };

  const onBlur = (e) => {
    //setIsFocused();
    onBlurCallback(e.target.value);
  };

  return (
    <div>
      <textarea
        ref={inputRef}
        name={name}
        id={id}
        type={type}
        max={max}
        min={min}
        className='form-control'
        value={value}
        maxLength={maxLength}
        onChange={onChangeHandler}
        onFocus={(e) => onFocus(e)}
        onBlur={(e) => onBlur(e)}
        autoComplete='off'
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
        required={required}
      />
      <label styleName={`${!!value ? 'active' : ''}`}>{label}</label>
      {isTouched && hasError && <small className={styles.error}>{error}</small>}
    </div>
  );
};

CTextArea.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string,
  text: PropTypes.string,
  onChange: PropTypes.func,
  onBlurCallback: PropTypes.func,
  maxLength: PropTypes.number,
  validator: PropTypes.func,
  errorMessage: PropTypes.string,
  inputRef: PropTypes.object,
  onFocusCallback: PropTypes.func,
  placeholder: PropTypes.string,
  isAutoFocused: PropTypes.bool
};
export default CTextArea;
