import React from 'react';
import withAdminLayout from '../../../shared/hoc/admin-layout';
import ErrorBoundary from '../../components/common/errorBoundary';
import { withAuthorization } from '../../../shared/utils/firebase';
import DocumentsConfig from '../../components/documents-config';

const DocumentRules = (props) => {
  return (
    <div>
      <ErrorBoundary>
        <DocumentsConfig />
      </ErrorBoundary>
    </div>
  );
};
export default withAdminLayout(withAuthorization(() => true)(DocumentRules));
