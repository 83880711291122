import React from "react";
import CityDetailConfig from "../../components/city-detail-config";
import withAdminLayout from "../../../shared/hoc/admin-layout";
import ErrorBoundary from "../../components/common/errorBoundary"

const CityPage = (props) => {
    return (
        <div>
            <ErrorBoundary>
                <CityDetailConfig {...props}/>
            </ErrorBoundary>
        </div>
    );
};
export default withAdminLayout(CityPage);
