import Types from './types';
import { storeService } from '../../../services';
import {
  setAccessLoaderConnected,
  resetAccessLoaderConnected,
  setForbiddenConnected
} from '../../../shared/components/loader-with-access-control/actions';
import { handleHttpStatusError } from '../../../services/helper';
import { showToastMessages } from '../../../shared/utils/helper';
//Fetch store details

const fetchStoreInfoByIdSuccess = (data) => ({
  type: Types.FETCH_STORE_SUCCESS,
  data
});

const fetchStoreInfoByIdFail = (error) => ({
  type: Types.FETCH_STORE_FAIL,
  error
});

const fetchStoreInfoByIdConnected = (storeId) => (dispatch) => {
  dispatch(setAccessLoaderConnected());

  return new Promise((resolve, reject) => {
    storeService
      .fetchStoreDetails(storeId)
      .then((resp) => {
        resolve(resp);
        dispatch(fetchStoreInfoByIdSuccess(resp.data.data));
        dispatch(resetAccessLoaderConnected());
      })
      .catch((error) => {
        dispatch(fetchStoreInfoByIdFail(error));
        handleHttpStatusError(error);
        reject(error);
      });
  });
};

const addStoreInit = () => ({
  type: Types.ADD_STORE_INIT
});

const addStoreSuccess = (data) => ({
  type: Types.ADD_STORE_SUCCESS,
  data
});

const addStoreFail = (error) => ({
  type: Types.ADD_STORE_FAIL,
  error
});

const removeStoreDetailConnected = () => {
  return {
    type: Types.REMOVE_STORE_DATA
  };
};

const addStoreConnected = (data) => (dispatch) => {
  dispatch(addStoreInit());
  return new Promise((resolve, reject) => {
    storeService
      .addStore(data)
      .then((resp) => {
        resolve(resp);
        showToastMessages(resp.data.message);
      })
      .catch((error) => {
        error.errors &&
          error.errors[0] &&
          error.errors[0]['message'] &&
          showToastMessages(error.errors[0]['message'], false);
        dispatch(addStoreFail(error));
      });
  });
};

//export all usable actions
export {
  fetchStoreInfoByIdConnected,
  addStoreConnected,
  removeStoreDetailConnected
};
