import React, { useEffect, useMemo, useState } from "react";
import { withRouter } from 'react-router-dom';
import { CInputLabelField } from "../../../../shared/components/c-input-label/c-input-label";
import { SEGMENT_FORM_KEYS, SEGMENT_FORM_METADATA, SERVICE_TYPE_INDEX } from "./meta-data";
import { SelectDropdown } from "../../../../client/components/shared";
import { showToastMessages } from '../../../../shared/utils/helper';
import { useRcOthersSegmentForm } from "../../../hooks/custom-hooks/rc-others-segment-form-hook";
import DatePicker from "react-datepicker";
import { getLocalDatetime } from "../../../../shared/utils/datesHelper";

const RcOthersSegmentForm = (props) => {
  const { serviceTypes, fetchServiceConfig, getServiceDetailsByServiceType } = useRcOthersSegmentForm();
  const fieldsData = useMemo(() => SEGMENT_FORM_METADATA({
    serviceTypes,
    getServiceDetailsByServiceType,
  }), [serviceTypes, getServiceDetailsByServiceType]);

  const [form, setForm] = useState({
    values: {},
    errors: {} 
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    const updatedForm = {
      ...form,
      values: {
        ...form.values,
        [name]: value,
      }
    }
    // special case as dropdowns are dependant on selected service type
    if (name === SEGMENT_FORM_KEYS.SERVICE_TYPE) {
      delete updatedForm.values[SEGMENT_FORM_KEYS.SERVICE_DETAILS];
      delete updatedForm.values[SEGMENT_FORM_KEYS.CITY];
    }
    setForm(updatedForm);
  };

  const handleDateChange = (selectedDate, key) => {
    const updatedForm = {
      ...form,
      values: {
        ...form.values,
        [key]: getLocalDatetime(selectedDate, true),
      }
    }
    setForm(updatedForm);
  };

  const handleSave = (e) => {
    e.preventDefault();

    props.addOthersRcDetailsConnect(form.values).then(() => {
      showToastMessages("Created Successfully");
      props.history.push("/rc-tracker-others");
    }).catch((error) => {
      showToastMessages(error.message);
    });
  }

  useEffect(() => {
    fetchServiceConfig();
  }, []);

  const renderSingleField = (fieldData) => {
    if (Object.keys(fieldData).length === 0) {
      return <div className="col-4"></div>
    }

    const { 
      key: fieldKey, 
      label, 
      fieldType,
      isVisible, 
      fieldSubType,
      getDropdownOptions,
      isRequired,
      validateOnChange,
      disabled,
    } = fieldData;

    const isFieldVisible = isVisible(form.values);

    if (!isFieldVisible) {
      delete form.values[fieldKey];
      delete form.errors[fieldKey];
      return null;
    };

    const fieldValue = form.values[fieldKey];
    const formValues = form.values;

    let fieldElement = null;

    if (fieldType === "text") {
      fieldElement = (
        <CInputLabelField
          type={fieldSubType || "text"}
          name={fieldKey}
          label={label}
          value={fieldValue}
          onChange={(e) => validateOnChange ? validateOnChange(e, handleChange) : handleChange(e)}
          required={isRequired()}
        />
      )
    } else if (fieldType === "dropdown") {
      fieldElement = (
        <div>
          <label className='font-weight-bold'>{label}</label>
          <SelectDropdown
            name={fieldKey}
            value={fieldValue}
            optionsList={getDropdownOptions(formValues)}
            onChange={handleChange}
            required={isRequired(formValues)}
          />
        </div>
      )
    } else if (fieldType === "date") {
      fieldElement = (
        <div className="datepicker_custom">
          <label className='font-weight-bold'>{label}</label>
          <DatePicker
            name={fieldKey}
            selected={
              fieldValue
                ? new Date(fieldValue)
                : null
            }
            onChange={(date) => handleDateChange(date, fieldKey, false)}
            onChangeRaw={(e) => {
              e.preventDefault();
            }}
            isClearable={disabled ? false : true}
            disabled={disabled}
            dateFormat='dd/MM/yyyy'
            required={isRequired()}
          />
        </div>
      )
    }

    const fieldError = form.errors[fieldKey];

    return (
      <div className="col-4 mt10">
        {fieldElement}
        {fieldError && <div className="text-danger">{fieldError}</div>}
      </div>
    )
  };

  return (
    <form onSubmit={handleSave}>
      <div className="row">
        {fieldsData.slice(0, SERVICE_TYPE_INDEX).map((fieldData) => {
          return renderSingleField(fieldData);
        })}
      </div>
      <div className="row">
        {fieldsData.slice(SERVICE_TYPE_INDEX).map((fieldData) => {
          return renderSingleField(fieldData);
        })}
      </div>
      <div className='row mt20'>
        <div className='col-md-6'>
          <button type='submit' className='btn btn-primary'>
            {'Save'}
          </button>
        </div>
      </div>
    </form>
  )
}

export default withRouter(RcOthersSegmentForm);