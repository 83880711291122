import React, { useState, useEffect } from 'react';
import { rtoApi } from '../../../../../services';
import {
  SelectComponent
} from '../../../../../client/components/shared';

const RcOthersCasetypeRtotransfer = ({ caseTypeData, onChangeSelect, isRequiredRtoTransferTo }) => {
  const [rtoList, SetRtoList] = useState([]);

  useEffect(() => {
    let mounted = true;
    rtoApi
      .getAllRto()
      .then((response) => {
        if (mounted) {
          SetRtoList(response && response);
        }
      })
      .catch((err) => {});
    return () => {
      mounted = false;
    };
  }, []);

  const rto = () => {
    const list =
      Array.isArray(rtoList) &&
      rtoList.map((option, index) => {
        return {
          value: option && option.rtoCode,
          label:
            option &&
            `${option.city_name ? option.city_name + ' -' : ''} ${
              option.rtoCode
            }`
        };
      });
    return list;
  };
  return (
    <>
      <div className='col-4 d-flex justify-content-between mb20'>
        <label className='col-form-label'>Current RTO :- </label>
        <div className='col-6'>
          <SelectComponent
            name='rto_transfer_from'
            optionsList={rto()}
            value={caseTypeData && caseTypeData.rto_transfer_from}
            placeholder={caseTypeData && caseTypeData.rto_transfer_from}
            onChange={onChangeSelect}
            version='v2'
          />
        </div>
      </div>
      {isRequiredRtoTransferTo() && (
        <div className='col-4 d-flex justify-content-between mb20'>
          <label className='col-form-label'>RTO Transfer to :- </label>
          <div className='col-6'>
            <SelectComponent
              name='rto_transfer_to'
              placeholder={caseTypeData && caseTypeData.rto_transfer_to}
              optionsList={rto()}
              value={caseTypeData && caseTypeData.rto_transfer_to}
              onChange={onChangeSelect}
              version='v2'
            />
          </div>
        </div>
      )}
    </>
  );
};

export default RcOthersCasetypeRtotransfer;
