import React, { useRef } from 'react'

export const FileUploadCta = ({
  name,
  label,
  size,
  accept,
  buttonClassName = 'btn btn-sm btn-primary',
  onChange,
}) => {
  const $fileInput = useRef();

  const handleClick = (e) => {
    $fileInput.current.click();
  }

  const handleFileInputChange = (e) => {
    if (e.target && e.target.files[0]) {
      const file = e.target.files[0];
      $fileInput.current.value = "";
      if (onChange) {
        onChange(file, name);
      }
    } 
  }

  return (
    <div>
      <button className={buttonClassName} onClick={handleClick}>
        <input
          type="file"
          name={name}
          className="hide"
          ref={$fileInput}
          size={size}
          accept={accept}
          onChange={handleFileInputChange}
        />
        {label}
      </button>
    </div>
  )
}