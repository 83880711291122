const exterior = [
  { label: 'Bumpers', dataKey: 'bumpers', readOnly: true },
  { label: 'Bonnet Hood', dataKey: 'bonnetHood', readOnly: true },
  { label: 'Bonnet Patti', dataKey: 'bonnetPatti', readOnly: true },
  { label: 'Roof', dataKey: 'roof', readOnly: true },
  { label: 'Fenders', dataKey: 'fenders', readOnly: true },
  { label: 'Doors', dataKey: 'doors', readOnly: true },
  { label: 'Lights', dataKey: 'lights', readOnly: true },
  {
    label: 'Dicky Door / Boot Door',
    dataKey: 'dickyDoorBootDoor',
    readOnly: true
  },
  { label: 'ORVM - Manual / Electrical', dataKey: 'orvm', readOnly: true },
  { label: 'Windshield', dataKey: 'windshield', readOnly: true }
];

const interior = [
  { label: 'Power windows', dataKey: 'powerWindows', readOnly: true },
  { label: 'Airbag feature', dataKey: 'airbagFeature', readOnly: true },
  { label: 'Music System', dataKey: 'musicSystem', readOnly: true },
  { label: 'Leather Seats', dataKey: 'leatherSeats', readOnly: true },
  { label: 'Fabric Seats', dataKey: 'fabricSeats', readOnly: true },
  { label: 'Sunroof', dataKey: 'sunroof', readOnly: true },
  {
    label: 'Steering Mounted Audio',
    dataKey: 'steeringMountedAudioControls',
    readOnly: true
  },
  { label: 'ABS', dataKey: 'abs', readOnly: true },
  { label: 'AC Cooling', dataKey: 'acCooling', readOnly: true },
  { label: 'Heater', dataKey: 'heater', readOnly: true },
  { label: 'Climate Control AC', dataKey: 'climateControlAc', readOnly: true },
  { label: 'Rear Defogger', dataKey: 'rearDefogger', readOnly: true },
  { label: 'Reverse Camera', dataKey: 'reverseCamera', readOnly: true }
];

const engine = [
  { label: 'Engine', dataKey: 'engine', readOnly: true },
  { label: 'Battery', dataKey: 'battery', readOnly: true },
  { label: 'Coolant', dataKey: 'coolant', readOnly: true },
  { label: 'Engine Mounting', dataKey: 'engineMounting', readOnly: true },
  { label: 'Engine Sound', dataKey: 'engineSound', readOnly: true },
  { label: 'Clutch', dataKey: 'clutch', readOnly: true },
  { label: 'Exhaust Smoke', dataKey: 'exhaustSmoke', readOnly: true },
  { label: 'Gear Shifting', dataKey: 'gearShifting', readOnly: true },
  { label: 'CNG / LPG Fitment', dataKey: 'cngLpgFitment', readOnly: true }
];

const others = [
  { label: 'Steering', dataKey: 'steering', readOnly: true },
  { label: 'Suspension', dataKey: 'suspension', readOnly: true },
  { label: 'Brake', dataKey: 'brakes', readOnly: true },
  { label: 'Registration City', dataKey: 'registrationCity', readOnly: true },
  { label: 'Registration State', dataKey: 'registrationState', readOnly: true }
];

export default {
  exterior,
  interior,
  engine,
  others
};
