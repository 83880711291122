import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DocumentsConfigDataTransfer from "./component";
import { downloadConfigRules, uploadConfigRules } from "../documents-region-list/actions";

const mapStateToProps = ({
  documentsRegionList: {
    isUploading
  }
}) => ({
  isUploading
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
  downloadConfigRulesConnect: downloadConfigRules,
  uploadConfigRulesConnect: uploadConfigRules
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsConfigDataTransfer);
