import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FinancePaymentForm from './component';
import { submitPaymentProofConnected } from './actions';
import { fetchTransactionLogsConnected } from '../finance-payment-tab/actions';
import { fetchPaymentInfoConnected } from '../finance-payment-tab/actions';
import { fetchFinanceDealInfoByIdConnected } from '../finance-dealInfo/actions';

const mapStateToProps = (state) => {
  const { zoneInfo } = state;
  return { zoneInfo };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      submitPaymentProofConnected,
      fetchTransactionLogsConnected,
      fetchPaymentInfoConnected,
      fetchFinanceDealInfoByIdConnected
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinancePaymentForm);
