/* eslint-disable no-console */
import axios from 'axios';

const DEBUG = process.env.NODE_ENV === 'development';

let BASE_API_URL = 'https://ins-api.24c.in/';
if (typeof window !== "undefined") {
    switch (process.env.REACT_APP_ENV) {
        case 'local':
            BASE_API_URL = 'https://ins-api.24c.in/';
            break;
        case 'qa':
            BASE_API_URL = 'https://ins-api.24c.in/';
            break;
        case 'stage':
            BASE_API_URL = '/';
            break;
        case 'prod':
            BASE_API_URL = '/';
            break;
        default: break;
    }
}

/* Create axios instance */
const api = axios.create({
    baseURL: BASE_API_URL,
    headers: {
        'Content-Type': 'application/json'
    },
    // withCredentials: true,
});

api.interceptors.request.use((config) => {
    /** In dev, intercepts request and logs it into console for dev */
    if (DEBUG) { console.info('✉️ ', config); }
    return config;
}, (error) => {
    if (DEBUG) { console.error('✉️ ', error); }
    return Promise.reject(error);
});

/**
 * Passes response.data to services.
 * In dev, intercepts response and logs it into console for dev
 */
api.interceptors.response.use((response) => {
    if (DEBUG) { console.info('📩 ', response); }
    return response;
}, (error) => {
    console.log('axios report error', error);
    if (error.response && error.response.data) {
        if (DEBUG) { console.error('Error: ', error.response.data); }
        return Promise.reject(error.response.data);
    }
    if (DEBUG) { console.error('📩 ', error); }
    return Promise.reject(error.message);
});

export default api;
