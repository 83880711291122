export const isDateValid = (startDate, endDate) => {
  const _startDate = new Date(startDate);
  const _endDate = new Date(endDate);

  const startDateDateOnly = new Date(_startDate.getFullYear(), _startDate.getMonth(), _startDate.getDate());
  const endDateDateOnly = new Date(_endDate.getFullYear(), _endDate.getMonth(), _endDate.getDate());
  if (startDateDateOnly >= endDateDateOnly) return false;
  return true;

}

export const isCustomDelayReasonSelected = (delayReason) => {
  return delayReason === "Custom";
};