import React, { useState, useEffect } from 'react';
import Header from '../header';
import Footer from '../footer';
// import Sidebar from "../sidebar";
import styles from './layout.module.css';
import { ToastContainer } from 'react-toastify';
import { saveToLocalStorage } from '../../utils/helper';
import { BUYER_PRIMARY_PHONE, APP_SOURCE } from '../../utils/constants';

export default function withLayout(
  WrappedComponent,
  sidebar = true,
  header = true,
  footer = false
) {
  const Layout = (props) => {
    const [expandSidebar] = useState(false);

    // const openSidebar = () => {
    //     setExpandSidebar(true);
    // };

    // const hideSidebar = () => {
    //     setExpandSidebar(false);
    // };
    useEffect(()=>{
      saveToLocalStorage(BUYER_PRIMARY_PHONE,'');
      saveToLocalStorage(APP_SOURCE,'');
    },[]);

    return (
      <>
        {/* {sidebar && <Sidebar {...props} expandSidebar={expandSidebar} openSidebar={openSidebar} hideSidebar={hideSidebar} />} */}
        {header && <Header {...props} />}
        <div
          className={`${sidebar ? styles.wrapper : ''} ${
            expandSidebar ? styles.openMenu : ''
          }`}
        >
          <WrappedComponent {...props} />
        </div>
        {footer && <Footer {...props} />}
        <ToastContainer />
      </>
    );
  };
  return Layout;
}
