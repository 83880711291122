import Types from './types';
import { orderService } from '../../../services';

// Global actions
import {
  setAccessLoaderConnected,
  resetAccessLoaderConnected,
  setForbiddenConnected
} from '../../../shared/components/loader-with-access-control/actions';
import { handleHttpStatusError } from '../../../services/helper';
import { showToastMessages } from '../../../shared/utils/helper';

//Fetch order details
const fetchSellerDetailInit = () => ({
  type: Types.FETCH_ORDER_SELLER_INIT
});

const fetchSellerDetailSuccess = (data) => ({
  type: Types.FETCH_ORDER_SELLER_SUCCESS,
  payload: data
});

const fetchSellerDetailFailure = (error) => ({
  type: Types.FETCH_ORDER_SELLER_FAILURE,
  payload: error
});

const fetchSellerDetailConnected = (appointmentId) => (dispatch) => {
  dispatch(setAccessLoaderConnected());
  dispatch(fetchSellerDetailInit());
  return new Promise((resolve, reject) => {
    orderService
      .fetchSellerDetail(appointmentId)
      .then((resp) => {
        resolve(resp);
        dispatch(fetchSellerDetailSuccess(resp));
        dispatch(resetAccessLoaderConnected());
      })
      .catch((error) => {
        dispatch(fetchSellerDetailFailure(error));
        handleHttpStatusError(error);
        reject(error);
      });
  });
};
/* Update Seller data */

//update order detail
const updateSellerInit = () => ({
  type: Types.UPDATE_SELLER_INIT
});

const updateSellerSuccess = (data) => ({
  type: Types.UPDATE_SELLER_SUCCESS,
  data
});

const updateSellerFail = (error) => ({
  type: Types.UPDATE_SELLER_FAIL,
  error
});

const updateSellerConnected = (data) => (dispatch) => {
  dispatch(updateSellerInit());
  return orderService
    .updateOrder(data)
    .then((resp) => {
      showToastMessages(resp.data.message);
      dispatch(updateSellerSuccess(resp));
      return resp.data;
    })
    .catch((error) => {
      dispatch(updateSellerFail(error));
    });
};

/* COnvert to GS CAR  */
const updateCarSourceConnected = (data) => (dispatch) => {
  dispatch(updateCarSourceInit());
  return new Promise((resolve, reject) => {
    orderService
      .updateOnboardInventory(data)
      .then((resp) => {
        resolve(resp);
        showToastMessages(resp.data.message);
        dispatch(updateCarSourceSuccess(resp));
      })
      .catch((error) => {
        error.errors &&
          error.errors[0] &&
          error.errors[0]['message'] &&
          showToastMessages(error.errors[0]['message'], false);
        dispatch(updateCarSourceFail(error));
        reject(error);
      });
  });
};

const updateCarSourceInit = () => ({
  type: Types.UPDATE_CAR_SOURCE_INIT
});

const updateCarSourceSuccess = (data) => ({
  type: Types.UPDATE_CAR_SOURCE_SUCCESS,
  data
});

const updateCarSourceFail = (error) => ({
  type: Types.UPDATE_CAR_SOURCE_FAIL,
  error
});

export {
  fetchSellerDetailConnected,
  updateCarSourceConnected,
  updateSellerConnected
};
