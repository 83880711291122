import React, { useState, useEffect } from 'react';
import { rtoApi } from '../../../services';
import { withRouter } from 'react-router-dom';
import Accordion from '../../../shared/accordion';
import ArrowIcon from '../../../shared/arrow';
import { CheckBox, SelectDropdown } from '../../../client/components/shared';
import InputText from '../../../shared/components/input-text';
import { useRtoDetails } from '../../custom-hooks/rto-details-hook';
import ReactSelect from 'react-select';

const RtoDetails = ({ 
  match
}) => {
  const { stateDropdownOptions, regionsDropdownOptions, districtDropdownOptions, mandatoryDocsOptions } = useRtoDetails();
  const [rtoData, setRtoData] = useState({});
  const id = match.params.id;
  useEffect(() => {
    rtoApi
      .getRtoById(id)
      .then((res) => {
        setRtoData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const onChange = (e, index) => {
    const _rtoData = { ...rtoData };
    _rtoData.servicesDetails[index][e.target.name] = e.target.value;

    setRtoData(_rtoData);
  };
  const handleSave = () => {
    rtoApi.submitRtoData(id, rtoData);
  };
  const onChangeChechbox = (e) => {
    const _rtoData = { ...rtoData };
    _rtoData[e.target.name] = e.target.checked;
    setRtoData(_rtoData);
  };

  const onChangeDropdown = (e) => {
    const _rtoData = { ...rtoData };
    _rtoData[e.target.name] = e.target.value;
    setRtoData(_rtoData);
  };

  const handleChange = (e) => {
    const _rtoData = { ...rtoData };
    _rtoData[e.target.name] = e.target.value;
    setRtoData(_rtoData);
  };

  const handleMandatoryDocsChange = (options) => {
    const selectedIds = (options ? options : []).map(option => option.value);
    const _rtoData = { ...rtoData };

    _rtoData["mandatoryDocuments"] = selectedIds;
    setRtoData(_rtoData);
  };

  const mandatoryDocDropdownValue = mandatoryDocsOptions.filter(({ value }) => rtoData.mandatoryDocuments && rtoData.mandatoryDocuments.includes(value));

  return (
    <div>
      <div className='row'>
        <div className='col-3'>
          <label className='font-weight-bold'>RTO Code</label>
          <p>{rtoData && rtoData.rtoCode}</p>
        </div>
        <div className='col-3'>
          <label className='font-weight-bold'>RTO Cityname</label>
          <p>{rtoData && rtoData.cityName}</p>
        </div>
      </div>
      <div className='row'>
        <div className='col-3'>
          <label className='font-weight-bold'>Consumer Finance?</label>
          <CheckBox
            name='isCfAvailable'
            onClickCallback={(e) => onChangeChechbox(e)}
            checkedStatus={rtoData && rtoData.isCfAvailable}
          />
        </div>
        <div className='col-3'>
          <label className='font-weight-bold'>E-Receipt Available?</label>
          <CheckBox
            name='eReceiptAvailable'
            onClickCallback={(e) => onChangeChechbox(e)}
            checkedStatus={rtoData && rtoData.eReceiptAvailable}
          />
        </div>
        <div className='col-3'>
          <label className='font-weight-bold'>Is Party Peshi Applicable?</label>
          <CheckBox
            name='isPartyPeshiApplicable'
            onClickCallback={(e) => onChangeChechbox(e)}
            checkedStatus={rtoData && rtoData.isPartyPeshiApplicable}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-3'>
          <label className='font-weight-bold'>Is Direct Rto?</label>
          <CheckBox
            name='isDirectRto'
            onClickCallback={(e) => onChangeChechbox(e)}
            checkedStatus={rtoData && rtoData.isDirectRto}
          />
        </div>
        <div className='col-3'>
          <label className='font-weight-bold'>Is Rto TrackingId?</label>
          <CheckBox
            name='isRtoTrackingId'
            onClickCallback={(e) => onChangeChechbox(e)}
            checkedStatus={rtoData && rtoData.isRtoTrackingId}
          />
        </div>
        <div className='col-3'>
          <label className='font-weight-bold'>Is Intrastate NOC?</label>
          <CheckBox
            name='isInterStateNocApplicable'
            onClickCallback={(e) => onChangeChechbox(e)}
            checkedStatus={rtoData && rtoData.isInterStateNocApplicable}
          />
        </div>  
      </div>
      <div className='row mt-2'>
        <div className='col-3'>
          <label>State</label>
          <SelectDropdown
            name='stateCode'
            optionsList={stateDropdownOptions}
            value={rtoData && rtoData.stateCode}
            onChange={onChangeDropdown}
          />
        </div>
        <div className='col-3'>
          <label>District</label>
          <SelectDropdown
            name='districtCode'
            optionsList={districtDropdownOptions}
            value={rtoData && rtoData.districtCode}
            onChange={onChangeDropdown}
          />
        </div>
        <div className='col-3'>
          <label>Region</label>
          <SelectDropdown
            name='region'
            optionsList={regionsDropdownOptions}
            value={rtoData && rtoData.region}
            onChange={onChangeDropdown}
          />
        </div>
        <div className='col-3'>
          <label>RTO Link</label>
          <InputText
            type="text"
            name={"rtoLink"}
            value={rtoData && rtoData.rtoLink}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className='row mt-2'>
        <div className='col-3'>
          <label>Mandatory Documents</label>
          <ReactSelect
            classNamePrefix="dreMultiselect"
            isMulti
            menuPortalTarget={document.body}
            menuPlacement="auto"
            options={mandatoryDocsOptions}
            onChange={handleMandatoryDocsChange}
            value={mandatoryDocDropdownValue}
            styles={{ menuPortal: (provided) => ({ ...provided, zIndex: 10000000 }) }}
          />
        </div>
      </div>
      <Accordion>
        {({ onClick, currentVisibleStates }) => (
          <Accordion.Content>
            <div className='borderWrapper'>
              <Accordion.Heading>
                <h2
                  className='font-weight-bold p0'
                  onClick={() => onClick({ index: 0 })}
                >
                  RTO Services
                  <ArrowIcon
                    grey={'GreyArrow'}
                    rotateBy={currentVisibleStates[0] ? 180 : 90}
                  />
                </h2>
              </Accordion.Heading>
              <Accordion.Body>
                <div
                  className={
                    currentVisibleStates[0]
                      ? 'accordionHide'
                      : 'accordionShow table mt20'
                  }
                >
                  <div>
                    <table className='table'>
                      <tbody>
                        {rtoData &&
                          rtoData.servicesDetails &&
                          rtoData.servicesDetails.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{data.serviceName}</td>
                                <td>
                                  <div className='float-left form-group col-md-3'>
                                    <input
                                      name='amount'
                                      type='text'
                                      className='form-control'
                                      value={data && data.amount}
                                      onChange={(e) => onChange(e, index)}
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <div className='form-group col-md-12 text-right'>
                      <button onClick={handleSave} className='btn btn-primary'>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </div>
          </Accordion.Content>
        )}
      </Accordion>
    </div>
  );
};

export default withRouter(RtoDetails);
