import dayjs from "dayjs";
import { getformattedDate } from "../../../shared/utils/datesHelper";

const payoutStatuses = [{
  key: "INITIATED",
  value: "Initiated"
}, {
  key: "APPROVED",
  value: "Approved"
}, {
  key: "REJECTED",
  value: "Rejected"
}, {
  key: "CANCELLED",
  value: "Expired"
}, {
  key: "DONE",
  value: "Done"
}, {
  key: "ERROR",
  value: "Error"
}];

const refundTypes = [{
  key: "EXCESS",
  value: "Excess"
}, {
  key: "BUYBACK",
  value: "Buyback"
}, {
  key: "RTO_HOLDBACK",
  value: "RTO Holdback"
}, {
  key: "CHALLAN_HOLDBACK",
  value: "Challan Holdback"
}, {
  key: "OTHER_HOLDBACK",
  value: "Other Holdback"
}];
const refundCategories = [{
  key: "BUYBACK",
  value: "Buyback"
}, {
  key: "POST_DELIVERY",
  value: "Post Delivery"
}];

export default [
  {
    headerName: 'UID',
    width: 100,
    resizable: false,
    field: 'dealIdentifier',
    renderField: 'dealIdentifier',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    cellRenderer: 'LinkRenderer',
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Appt. ID',
    width: 110,
    field: 'appointmentId',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    suppressSizeToFit: false,
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Category',
    width: 120,
    field: 'category',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'category',
      options: refundCategories
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: refundCategories
    },
    suppressMenu: true
  },
  {
    headerName: 'Refund Type',
    width: 120,
    field: 'type',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'type',
      options: refundTypes
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: refundTypes
    },
    suppressMenu: true
  },
  {
    headerName: 'Amount',
    width: 110,
    field: 'amount'
  },
  {
    headerName: 'Refunded',
    width: 110,
    field: 'refunded'
  },
  {
    headerName: 'Status',
    width: 120,
    field: 'status',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'status',
      options: payoutStatuses
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: payoutStatuses
    },
    suppressMenu: true
  },
  {
    headerName: 'Auto Approval',
    //width: 200,
    field: 'expectedActionAt',
    filter: 'agDateColumnFilter',
    floatingFilter: false,
    valueFormatter: function (params) {
      if (params.value) {
        return dayjs(params.value).format('DD MMM YYYY');
      } else {
        return "-";
      }
    },
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      color: 'red'
    },
    filterParams: {
      filterOptions: ['lessThanOrEqual'],
      browserDatePicker: true,
      applyButton: true,
      clearButton: true,
      suppressAndOrCondition: true,
      newRowsAction: 'keep'
    },
    suppressMenu: true
  },
  {
    headerName: 'Expiry',
    //width: 200,
    field: 'validity',
    filter: 'agDateColumnFilter',
    floatingFilter: false,
    valueFormatter: function (params) {
      if (params.value) {
        return dayjs(params.value).format('DD MMM YYYY');
      } else {
        return "-";
      }
    },
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      color: 'red'
    },
    filterParams: {
      filterOptions: ['lessThanOrEqual'],
      browserDatePicker: true,
      applyButton: true,
      clearButton: true,
      suppressAndOrCondition: true,
      newRowsAction: 'keep'
    },
    suppressMenu: true
  },
  {
    headerName: 'Last action by',
    field: 'updatedBy'
  },
];
