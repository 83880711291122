import React from "react";
import { useAgentAvailability } from "../../custom-hooks/agent-availability-hook";
import { ToggleButtonView } from "../shared/toggle-button";

export const AgentStatusToggle = () => {
  const {
    isAgentAvailable,
    updateAgentAvailability,
  } = useAgentAvailability();

  return (
    <div className="float-right">
      <ToggleButtonView isOn={isAgentAvailable} onSelect={(isAvailable) => updateAgentAvailability(isAvailable)} />
    </div>
  )
}