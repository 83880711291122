import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import StoreGrid from './component';
import {
  StoreActions,
  StateActions,
  CityActions,
  ZoneActions
} from '../../actions';
import { setPageNumberConnected, fetchStoreGridInfoConnected } from './actions';

const mapStateToProps = (state) => {
  const {
    detail,
    storeInfo: { pageNumber }
  } = state;
  return {
    detail,
    pageNumber
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchStoreDetail: (storeId) => StoreActions.fetchStoreDetail(storeId),
      addZone: (data) => ZoneActions.addZone(data),
      fetchStates: () => StateActions.fetchStates(),
      fetchCities: (params) => CityActions.fetchCities(params),
      setPageNumberConnected,
      fetchStoreGridInfoConnected
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StoreGrid);
