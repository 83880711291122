import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DocumentConfigRejectionReasons from "./component";
import { fetchDocumentsList } from "../documents-list/actions";

const mapStateToProps = ({
  documentsList,
}) => ({
  documentsList
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchDocumentsListConnect: fetchDocumentsList,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentConfigRejectionReasons);
