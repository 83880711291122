import { dealerTypeConstants } from '../constants';
const columnDefs = [
  {
    headerName: 'Dealer ID',
    field: 'dealerId',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    cellRenderer: 'LinkRenderer',
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Dealer Name',
    field: 'name',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Dealer Code',
    field: 'dealerCode',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Email',
    field: 'email',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Dealer Type',
    field: 'dealerType',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'dealerType',
      options: [
        {
          key: dealerTypeConstants.DEALER_TYPE_GS_CAR,
          value: 'Guaranteed Sales Car'
        },
        {
          key: dealerTypeConstants.DEALER_TYPE_C2C_DEALER_CAR,
          value: 'Business To Customer'
        }
      ]
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: [
        {
          key: dealerTypeConstants.DEALER_TYPE_GS_CAR,
          value: 'Guaranteed Sales Car'
        },
        {
          key: dealerTypeConstants.DEALER_TYPE_C2C_DEALER_CAR,
          value: 'Business To Customer'
        }
      ]
    }
  }
];

export default columnDefs;
