import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    FETCH_DEALER_INIT
    FETCH_DEALER_SUCCESS
    FETCH_DEALER_FAIL
    ADD_DEALER_INIT
    ADD_DEALER_SUCCESS
    ADD_DEALER_FAIL
    FETCH_DEALER_FORM_INIT
    FETCH_DEALER_FORM_SUCCESS
    FETCH_DEALER_FORM_FAIL
    FETCH_DEALER_LIST_INIT
    FETCH_DEALER_LIST_SUCCESS
    FETCH_DEALER_LIST_FAIL
    REMOVE_DEALER_DATA

`,
  {
    prefix: 'dealerform/'
  }
);
