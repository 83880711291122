import React from "react";
import { getB2CLmsPanelUrl } from "./constants";

export const SalePanel = ({
  userId,
  lmsLeadId,
}) => {
  const baseUrl = getB2CLmsPanelUrl();
  return (
    <iframe
      id="sales-panel-iframe"
      title="sales-panel-iframe"
      src={`${baseUrl}/customer/${userId}?leadId=${lmsLeadId}`}
      className="w-100"
      style={{
        height: "100vh",
        backgroundColor: 'white'
      }}
    />
  )
}