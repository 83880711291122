import React, { useState, useEffect, useMemo } from 'react';
import { columns } from '../../config/task-management-columns';
import { withRouter } from 'react-router-dom';
import GridView from '../common/grid-view';
import CustomPagination from '../custom-pagination/component';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';
import useModal from 'react-hooks-use-modal';
import { AssignForm } from './assign-form/assign-form';
import { useRegions } from '../../custom-hooks/regions-hook';

const TaskManagementGrid = function ({
  fetchTaskManagementGridInfoConnect,
  updateAssignToConnect,
  fetchAgentsConnect,
  history,
}) {
  const { regions, isRegionsFetched } = useRegions();
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [recordsOnPage, setRecordsOnPage] = useState(0);
  const [totalRecords, SetTotalRecords] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [queryParams, setQueryParams] = useState({});
  const [loaderDisabled, setLoaderDisabled] = useState(false);
  const [assignToRowData, setAssignToRowData] = useState(null);
  const [sortBy, setSortBy] = useState('agentAssignmentTime');
  const [order, setOrder] = useState('dsc');
  const [Modal, open, close] = useModal();
  const REGIONS_OPTIONS = useMemo(() => regions.map(({ regionName }) => ({ key: regionName, value: regionName })), [regions]);
  const columnDefs = useMemo(() => columns({ REGIONS_OPTIONS }), [REGIONS_OPTIONS]);

  useEffect(() => {
    fetchTaskManagementGridInfoConnect({
      ...queryParams, 
      page, 
      size: 10, 
      sortBy,
      isAsc: order === 'asc' 
    })
      .then((response) => {
        setRowData(response.response.map((row) => {
          return {
            ...row,
            regionName: (regions.find(({ id }) => id === row.regionId) || {}).regionName
          }
        }));
        setPage(response.page);
        setTotalPages(response.totalPages);
        SetTotalRecords(response.totalRecords);
        setRecordsOnPage(response.response ? response.response.length : 0);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page, queryParams, sortBy, order, regions]);

  const onRedirect = (data) => {
    const route = `/rc-tracker/${data.uid}`;
    history.push(route, {
      openTabKey: 3,
    });
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const onFilterChanged = (params = {}) => {
    if (params.createdOn) {
      params["createdOnFilter"] = params.createdOn;
      delete params["createdOn"];
    } else {
      if (params["createdOnFilter"]) delete params["createdOnFilter"];
    }
    if (params.regionName) {
      params["regionId"] = regions.find(({ regionName }) => regionName === params.regionName[0]).id;
      delete params["regionName"];
    } else {
      if (params["regionId"]) delete params["regionId"];
    }
    setQueryParams(params);
  };

  const handleSortChange = (sortState) => {
    if (sortState && sortState.length) {
      const { colId, sort } = sortState[0];
      setSortBy(colId);
      setOrder(sort);
    }
  };

  const handleAssignClick = (rowData) => {
    setAssignToRowData(rowData);
    open();
  };

  const handleSubmitAssign = (row, assigneeEmail) => {
    close();
    const updatedRowData = [...rowData];
    const index = updatedRowData.findIndex(r => r.id === row.id);
    if (index !== -1) {
      updatedRowData[index].assigneeEmail = assigneeEmail;
      updatedRowData[index].iterationTag += 1;
      setRowData(updatedRowData);
    }
  };

  // To avoid re-rendering and grid abnormal behaviors
  if (!isRegionsFetched) return null;

  return (
    <>
      <Modal>
        <AssignForm
          data={assignToRowData}
          onSubmitSuccess={handleSubmitAssign}
          updateAssignToConnect={updateAssignToConnect}
          fetchAgentsConnect={fetchAgentsConnect}
        />
      </Modal>
      <LoaderWithAccessControl disabled={loaderDisabled}>
        {rowData && (
          <>
            <GridView
              columnDefs={columnDefs}
              rowData={rowData}
              onRedirect={onRedirect}
              context={{ handleTmAssignClick: handleAssignClick }}
              uniqueIdName={'taskManagment'}
              onFilterChanged={onFilterChanged}
              uniqueGridId={'taskManagment'}
              getRowHeight={() => 45}
              onSortChanged={handleSortChange}
            />
            <CustomPagination
              page={page}
              totalRecords={totalRecords}
              handlePageChange={handlePageChange}
              recordsOnPage={recordsOnPage}
              totalPages={totalPages}
            />
          </>
        )}
      </LoaderWithAccessControl>
    </>
  );
};

export default withRouter(TaskManagementGrid);
