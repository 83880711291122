import { createTypes } from "reduxsauce";

export default createTypes(
    `
    SET_RC_OTHERS_DEAL_INFO
`,
    {
        prefix: "rcOthersDealInfo/"
    }
);
