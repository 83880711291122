import React, { useState, useEffect } from 'react';
import { Label, Input } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import '../Buyer-Details/style/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { VALID_NAME_REGEX, DispositionConstants } from '../../shared/Constants';
import { SelectComponent } from '../../shared';
import { StateActions, CityActions } from '../../../../admin-app/actions';
import { isArrayEmpty, pattern } from '../../../../shared/utils/helper';
import Button from "../../../../shared/components/button";
import { DataService } from '../../../service';
const BuyerDetails = ({
  buyerData,
  car,
  carUrl,
  setCarUrl,
  postData,
  setBuyer,
  setIsValidBuyer,
  setSubmitPostData,
  setRefreshCar,
  selectedCcDispositionId,
  onSendMessage,
  isMessageSent,
  sendMessageError,
  setIsMessageSent,
  setSendMessageError,
  ...restProps
}) => {
  const [name, setName] = useState(buyerData.name || null);
  const [nameError, setNameError] = useState(false);
  const [phone, setPhone] = useState(buyerData.phone || null);
  const [phoneError, setPhoneError] = useState(false);
  const [buyerCity, setBuyerCity] = useState(buyerData.city || null);
  const [cityError, setCityError] = useState(false);
  const [buyerState, setBuyerState] = useState(null);
  const [stateError, setStateError] = useState(false);

  const [buyerEmail, setBuyerEmail] = useState(buyerData.emailId || '');

  const [carUrlError, setCarUrlError] = useState(false);

  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  useEffect(() => {
    fetchStates();
  }, []);

  useEffect(() => {
    if (
      selectedCcDispositionId ===
      DispositionConstants.INBOUND_BUYER_SHORT_CALL_ID
    ) {
      !phone && setPhone(buyerData.phoneOriginal);
      setNameError();
      setCityError();
      setStateError();
    } else {
      !phone && setPhone();
    }
  }, [selectedCcDispositionId]);

  useEffect(() => {
    async function fetchData() {
      if (buyerState && buyerState.value) {
        const transactionalCities =
          (buyerData.buyerCities &&
            buyerData.buyerCities.cities.map((item) => {
              return item.id;
            })) ||
          [];

        const list = await restProps.fetchCities({ stateId: buyerState.value });
        const cityOptions =
          !isArrayEmpty(list) &&
          list.map((item) => {
            const label = transactionalCities.includes(item.cityId)
              ? item.cityName
              : `${item.cityName} (Non-Transactional)`;
            return { ...item, label: label, value: item.cityName };
          });
        setCityOptions(cityOptions || []);
      }
    }
    fetchData();
  }, [buyerState]);

  const fetchStates = async () => {
    const stateList = await restProps.fetchStates();
    const stateOptions = stateList.map((item) => {
      return { ...item, label: item.stateName, value: item.stateId };
    });
    setStateOptions(stateOptions);
  };

  // todo:-sp14 check usage of default city from buyer object
  // useEffect(() => {
  //   if (buyerData.buyerCities && buyerData.buyerCities.cities) {
  //     const cityList = buyerData.buyerCities.cities;
  //     let defaultCity = cityList.find(
  //       (item) => item.id === buyerData.buyerCities.defaultCityId
  //     );
  //     defaultCity = defaultCity ? defaultCity.title : null;
  //     setBuyerCity(defaultCity);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (name) {
  //     name.match(VALID_NAME_REGEX) ? setNameError(false) : setNameError(true);
  //   } else {
  //     setNameError(true);
  //   }
  //   if (
  //     selectedCcDispositionId &&
  //     selectedCcDispositionId ===
  //       DispositionConstants.BUYER_OFFER_PRICE_CC_DISPOSITION_ID
  //   ) {
  //     carUrl ? setCarUrlError(false) : setCarUrlError(true);
  //   } else {
  //     setCarUrlError(false);
  //   }
  // }, [selectedCcDispositionId]);

  useEffect(() => {
    if (postData) {
      const isValid = validateBuyer();
      if (isValid) {
        const buyerObj = {
          name: name,
          phone: phone,
          carUrl: carUrl,
          city: buyerCity,
          state: buyerState ? buyerState.label : null,
          emailId: buyerEmail
        };
        setBuyer(buyerObj);
        setIsValidBuyer(true);
      } else {
        setBuyer();
        setIsValidBuyer(false);
      }
      setSubmitPostData(true);
    }
  }, [postData]);

  const onNameChange = (e) => {
    const nameVal = e.target.value;
    setName(nameVal);
    if (nameVal) {
      nameVal.match(VALID_NAME_REGEX)
        ? setNameError(false)
        : setNameError(true);
    } else {
      setNameError(true);
    }
  };
  const onPhoneChange = (e) => {
    const { value } = e.target;
    const phoneVal = value ? value.replace(/[^0-9]/g, '') : '';
    setPhone(phoneVal);
    if (phoneVal) {
      phoneVal.match(pattern.mobile)
        ? setPhoneError(false)
        : setPhoneError(true);
    } else {
      setPhoneError(true);
    }
  };

  const onCarUrlChange = (e) => {
    const carUrlVal = e.target.value.trim();
    setCarUrl(carUrlVal);
    // if (
    //   selectedCcDispositionId &&
    //   selectedCcDispositionId ===
    //     DispositionConstants.BUYER_OFFER_PRICE_CC_DISPOSITION_ID
    // ) {
    //   carUrlVal ? setCarUrlError(false) : setCarUrlError(true);
    // } else {
    //   setCarUrlError(false);
    // }
  };

  const onChangeEmail = (e) => {
    const { name, value } = e.target;
    setBuyerEmail(value);
  };

  const onCarUrlBlur = (e) => {
    e.preventDefault();
    setRefreshCar(true);
    setIsMessageSent(false);
    setSendMessageError(false);
  };

  const validateBuyer = () => {
    let errors = 0;
    if (name) {
      name.match(VALID_NAME_REGEX)
        ? setNameError(false)
        : ++errors && setNameError(true);
    } else if (
      selectedCcDispositionId &&
      DispositionConstants.INBOUND_BUYER_SHORT_CALL_ID ===
        selectedCcDispositionId
    ) {
      setNameError(false);
    } else {
      ++errors && setNameError(true);
    }

    //City validation
    // if (
    //   selectedCcDispositionId &&
    //   DispositionConstants.INBOUND_BUYER_SHORT_CALL_ID ===
    //     selectedCcDispositionId
    // ) {
    //   setCityError(false);
    // } else if (!buyerCity) {
    //   ++errors && setCityError(true);
    // } else {
    //   setCityError(false);
    // }

    //State validation
    // if (
    //   selectedCcDispositionId &&
    //   DispositionConstants.INBOUND_BUYER_SHORT_CALL_ID ===
    //     selectedCcDispositionId
    // ) {
    //   setStateError(false);
    // } else if (!buyerState) {
    //   ++errors && setStateError(true);
    // } else {
    //   setStateError(false);
    // }

    /* Add car url validation if dialer disposition is interested. Currently validation via API
     */

    // if (
    //   selectedCcDispositionId &&
    //   selectedCcDispositionId ===
    //     DispositionConstants.BUYER_OFFER_PRICE_CC_DISPOSITION_ID
    // ) {
    //   carUrl ? setCarUrlError(false) : ++errors && setCarUrlError(true);
    // } else {
    //   setCarUrlError(false);
    // }

    if (errors === 0) return true;
    else return false;
  };

  const showBuyerCities = () => {
    const defaultCity =
      buyerCity && buyerData.city
        ? { value: buyerData.city, label: buyerData.city }
        : '';
    return (
      <div className='row'>
        <div className='col-12 seller-box pb20'>
          <div className='select buyer-city'>
            <SelectComponent
              key={buyerState ? `buyerCity_${buyerState.stateId}` : 'buyerCity'}
              name='buyerCity'
              defaultValue={defaultCity}
              value={buyerCity ? { value: buyerCity, label: buyerCity } : ''}
              onChange={(value) => {
                setBuyerCity(value);
                setCityError(false);
              }}
              optionsList={cityOptions}
              placeholder='Buyer City'
              isClearable={false}
              hideSelectedOptions={false}
            />
            {cityError && (
              <span className='text-error'>Valid City is required</span>
            )}
          </div>
        </div>
      </div>
    );
  };

  const showBuyerStates = () => {
    return (
      <div className='row'>
        <div className='col-12 seller-box pb20'>
          <div className='select buyer-city'>
            {/* <Label for='buyerState' className='label'>
              Buyer State
            </Label> */}
            <SelectComponent
              name='buyerState'
              defaultValue={null}
              onChange={(value) => {
                setBuyerState(value);
                setStateError(false);
                setBuyerCity(null);
              }}
              optionsList={stateOptions}
              placeholder='Buyer State'
              version='v2'
              isClearable={false}
            />
            {stateError && (
              <span className='text-error'>Valid State is required</span>
            )}
          </div>
        </div>
      </div>
    );
  };

  const onClickShareURLLink = () => {
    const {phone} = buyerData || {};
    DataService.shareLinkURL(carUrl, phone);
  };

  return (
    <div>
      <div className='main_section_buyer block'>
        <h2 className='title'>Buyer Details</h2>
        <div className='row'>
          <div className='col-12 seller-box'>
            <div className='group'>
              <Input
                type='text'
                className={'txtbox' + (nameError ? ' error' : '')}
                name='name'
                value={name || ''}
                autoComplete='off'
                onChange={(e) => onNameChange(e)}
              />
              {nameError && (
                <span className='text-error'>Valid name is required</span>
              )}
              <span className='highlight' />
              <Label for='name' className='label'>
                Buyer Name
              </Label>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 seller-box'>
            <div className='group'>
              <Input
                type='text'
                className={'txtbox'}
                name='phone'
                value={phone || ''}
                autoComplete='off'
                onChange={(e) => onPhoneChange(e)}
                maxLength={10}
                // disabled={
                //   selectedCcDispositionId ===
                //   DispositionConstants.INBOUND_BUYER_SHORT_CALL_ID
                // }
              />
              {phoneError && (
                <span className='text-error'>
                  Valid phone number is required
                </span>
              )}
              <span className='highlight' />
              <Label for='phone' className='label'>
                Buyer Phone
              </Label>
            </div>
          </div>
        </div>
        {showBuyerStates()}
        {showBuyerCities()}

        <div className='row'>
          <div className='col-12 seller-box'>
            <div className='group'>
              <Input
                type='text'
                className={'txtbox' + (carUrlError ? ' error' : '')}
                name='carUrl'
                value={carUrl || ''}
                autoComplete='off'
                onChange={onCarUrlChange}
                onBlur={onCarUrlBlur}
              />
              {carUrlError && (
                <span className='text-error'>Car url is required</span>
              )}
              <span className='highlight' />
              <Label for='carUrl' className='label'>
                Car URL
              </Label>

              {sendMessageError && (
                <span className={'error-message'}>{sendMessageError}</span>
              )}
              <div className='shareLink'>
                <Button label={"Share Link"} onClick={onClickShareURLLink}  />
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 seller-box'>
            <div className='group'>
              <Input
                type='text'
                className={'txtbox'}
                name='buyerEmail'
                value={buyerEmail || ''}
                autoComplete='off'
                onChange={onChangeEmail}
              />
              <span className='highlight' />
              <Label for='buyerEmail' className='label'>
                Buyer Email
              </Label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchStates: StateActions.fetchStates,
      fetchCities: CityActions.fetchCities
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyerDetails);
