import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    FETCH_PAYMENT_INFO_INIT
    FETCH_PAYMENT_INFO_SUCCESS
    FETCH_PAYMENT_INFO_FAILURE

    FETCH_TRANSACTION_LOGS_INIT
    FETCH_TRANSACTION_LOGS_SUCCESS
    FETCH_TRANSACTION_LOGS_FAILURE
    
    FETCH_PAYMENT_MODE_INIT
    FETCH_PAYMENT_MODE_SUCCESS
    FETCH_PAYMENT_MODE_FAILURE

  `,
  {
    prefix: 'finance-payment-tab/'
  }
);
