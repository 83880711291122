import React, { useEffect, useMemo, } from 'react'
import GridView from '../common/grid-view';
import { columns } from '../../config/case-type-configs-columns';
import { withRouter } from 'react-router-dom';
import { CASE_TYPES_GRID_ID } from '../../constants/constants';
import { showToastMessages } from '../../../shared/utils/helper';

const CaseTypesConfigList = ({
  attributes,
  isAttributesFetched,
  caseTypeConfigs,
  configsUpdated,
  fetchCaseTypeConfigConnect,
  updateCaseTypeConfigConnect,
  updateCaseTypeDataConnect,
  resetConfigListConnect,
  deleteCaseTypeConfigConnect,
}) => {
  const rowData = useMemo(() => Object.values(caseTypeConfigs.byId), [caseTypeConfigs.byId]);
  const columnDefs = useMemo(() => columns(attributes), [isAttributesFetched]);

  const fetchCaseTypesConfig = () => {
    fetchCaseTypeConfigConnect();
  }

  useEffect(() => {
    fetchCaseTypesConfig();
    return () => resetConfigListConnect();
  }, []);

  const handleMultiSelectChange = (attributeKey, data, documentId) => {
    let value = Array.isArray(data) ? data.map(val => val.value) : data.value;
    updateCaseTypeDataConnect({ id: documentId, key: attributeKey, value });
  }

  const handleConfigUpdate = () => {
    let data = configsUpdated.map(configId => ({
      ...caseTypeConfigs.byId[configId]
    }));

    updateCaseTypeConfigConnect({}, data).then((res) => {
      showToastMessages(res.message);
    }).finally(() => {
      fetchCaseTypesConfig();
    });
  }

  const handleConfigDelete = (id) => {
    deleteCaseTypeConfigConnect(id).then((res) => {
      showToastMessages(res.message);
    }).finally(() => {
      fetchCaseTypesConfig();
    });
  }

  const handleDeleteClick = (data) => {
    handleConfigDelete(data.id);
  }

  return (
    <>
      <h5>Case Type Rules</h5>
      <GridView
        className={CASE_TYPES_GRID_ID.CONFIG_LIST}
        uniqueGridId={CASE_TYPES_GRID_ID.CONFIG_LIST}
        columnDefs={columnDefs}
        rowData={rowData}
        context={{ handleMultiSelectChange, handleDeleteClick }}
        columnsToFit={true}
        floatingFilter={false}
        getRowHeight={() => 55}
      />
      <div className="text-right">
        <button
          className='btn btn-sm btn-success mt-neg-lg'
          onClick={handleConfigUpdate}
        >
          Update Rules
        </button>
      </div>
    </>
  )
}

export default withRouter(CaseTypesConfigList);