export const FIELDS = {
  name: {
    label: 'Name',
    keyName: 'name',
    type: 'text',
    required: true,
    elementType: 'input',
    pattern: '[A-Za-z ]{1,32}',
    title: 'Invalid Name',
    isReadOnly: true,
    maxLength: 90
  },
  phone: {
    label: 'Mobile No.',
    keyName: 'phone',
    type: 'number',
    maxLength: 10,
    required: true,
    elementType: 'input',
    pattern: '[6-9]{1}[0-9]{9}',
    title: '10 digit phone no. starting with 6/7/8/9',
    isReadOnly: false
  },
  emailId: {
    label: 'Email',
    keyName: 'emailId',
    type: 'email',
    required: true,
    elementType: 'input',
    isReadOnly: true,
    maxLength: 75
  },
  adminPanelRole: {
    label: 'Admin Role',
    keyName: 'adminPanelRole',
    type: 'select',
    isMulti: false,
    required: false,
    elementType: 'select',
    isReadOnly: false
  },
  role: {
    label: 'C2C LMS Role',
    keyName: 'role',
    type: 'select',
    isMulti: false,
    required: true,
    elementType: 'select',
    isReadOnly: false
  },
  status: {
    label: 'Status',
    keyName: 'status',
    type: 'select',
    isMulti: false,
    required: true,
    elementType: 'select',
    isReadOnly: false
  },
  zoneId: {
    label: 'Zone',
    keyName: 'zoneId',
    type: 'select',
    isMulti: false,
    required: true,
    elementType: 'select',
    isReadOnly: true
  }
};

export const _FIELD_SETS = [
  [FIELDS.name, FIELDS.phone],
  [FIELDS.emailId, FIELDS.adminPanelRole],
  [FIELDS.status, FIELDS.zoneId]
];

export const FIELD_SETS = [
  [FIELDS.name],
  [FIELDS.phone],
  [FIELDS.emailId],
  [FIELDS.adminPanelRole],
  [FIELDS.role],
  [FIELDS.status]
  // [FIELDS.zoneId]
];

export const USER_STATUS = {
  PRESENT: { label: 'Present', value: 'PRESENT' },
  RESIGNED: { label: 'Resigned', value: 'RESIGNED' },
  // ON_TRAINING: { label: 'On Training', value: 'ON_TRAINING' }
};
export const ADD_USER = 'Add User';
export const EDIT_USER = 'Edit User';
