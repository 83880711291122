import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import zonetype from '../../config/zone-type';
import { SelectDropdown } from '../../../client/components/shared';
import { CheckBox } from '../../../client/components/shared';
import { toast } from 'react-toastify';
import validate from '../../utils/validation/validation';
const AddZone = ({ fetchStates, fetchCities, addZone }) => {
  const [zoneFormData, setZoneFormData] = useState({
    zone: '',
    city: '',
    stateId: '',
    type: '',
    cityCode: '',
    isActive: false
  });
  const [validation, setValidation] = useState({});
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [zoneType, setZoneType] = useState(zonetype);
  const [err, setErr] = useState('');
  const { zone, city, stateId, type, isActive, cityCode } = zoneFormData;

  const onChange = (e) => {
    let { name, value } = e.target;
    if (name === 'cityCode') {
      value = value ? value.replace(/[0-9]+/g, '').toUpperCase() : '';
    }
    setZoneFormData({ ...zoneFormData, [name]: value });
  };
  useEffect(() => {
    fetchStates()
      .then((res) => {
        setStateList(res);
      })
      .catch(() => {});
  }, []);
  
  useEffect(() => {
    fetchCities({ stateId: stateId })
      .then((res) => {
        setCityList(res);
      })
      .catch(() => {});
  }, [stateId]);

  const cities = () => {
    const list = cityList;
    let optionsList = list.map((option) => {
      return { value: option.cityName, label: option.cityName };
    });
    return optionsList;
  };
  const states = () => {
    const list = stateList.map((option, index) => {
      return { value: option.stateId, label: option.stateName };
    });
    return list;
  };
  const zonetypes = () => {
    const zonetype = zoneType.map((option, index) => {
      return { value: option.type, label: option.value };
    });
    return zonetype;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationObj = validate({
      zone,
      stateId,
      city,
      type
    });
    setValidation(validationObj);
    const isValid = Object.values(validationObj).filter((x) => !x.valid);
    if (!isValid.length) {
      addZone(zoneFormData);
    }
  };
  const onChangeChechbox = (e) => {
    setZoneFormData({ ...zoneFormData, [e.target.name]: e.target.checked });
  };
  return (
    <>
      <form name='store' method='POST' onSubmit={(e) => handleSubmit(e)}>
        <div className='form-group row'>
          <div className='form-group col-md-3'>
            <input type='hidden' name='id' />
            <label>Zone Name</label>
            <input
              className='form-control'
              type='text'
              name='zone'
              value={zone}
              onChange={(e) => onChange(e)}
            />
            <span>
              {validation && validation.zone && validation.zone.message}
            </span>
          </div>
          <div className='form-group col-md-3'>
            <label>State</label>
            <SelectDropdown
              name='stateId'
              optionsList={states()}
              value={stateId}
              onChange={onChange}
            />
            <span>
              {validation && validation.stateId && validation.stateId.message}
            </span>
          </div>
          <div className='form-group col-md-3'>
            <label>City</label>
            <SelectDropdown
              name='city'
              optionsList={cities()}
              value={city}
              onChange={onChange}
            />
            <span>
              {validation && validation.city && validation.city.message}
            </span>
          </div>
          <div className='form-group col-md-3'>
            <label>Type</label>
            <SelectDropdown
              name='type'
              optionsList={zonetypes()}
              value={type}
              onChange={onChange}
            />
            <span>
              {validation && validation.type && validation.type.message}
            </span>
          </div>

          <div className='form-group col-md-3'>
            <input type='hidden' name='id' />
            <label>UID</label>
            <input
              className='form-control'
              type='text'
              name='cityCode'
              value={cityCode}
              onChange={(e) => onChange(e)}
            />
            <span>
              {validation && validation.zone && validation.zone.message}
            </span>
          </div>

          <div className='form-group col-md-3'>
            <label>Active</label>
            <CheckBox
              name='isActive'
              onClickCallback={(e) => onChangeChechbox(e)}
              checkedStatus={isActive}
            />
          </div>
        </div>

        <div className='form-group col-md-8'>
          <div className='rows'>&nbsp;</div>
          <div className='form-group col-md-5'>
            <button type='submit' className='btn btn-primary'>
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default withRouter(AddZone);
