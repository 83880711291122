import { buildQuery } from '../../services/helper';
import { config } from '../config/local';

export default (api) => {
  const fetchDocketManagementDocumentsConfig = () => {
    return new Promise((resolve, reject) => {
      const url = config.api.c2c.docketManagement.getDocumentConfig.path;
      return api
        .get(url)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch(error => {
          reject(error);
      })
    });
  };
  
  const fetchDocketManagementDocs = (params) => {
    return new Promise((resolve, reject) => {
      const url = `${config.api.c2c.docketManagement.fetchDocuments.path}?dealId=${params.dealId}`;
      return api
        .get(url)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch(error => {
          reject(error);
      })
    });
  };

  const fetchDocketManagementFormData = (params) => {
    return new Promise((resolve, reject) => {
      const url = `${config.api.c2c.docketManagement.fetchFormData.path}?dealId=${params.dealId}&docketStep=FORM_SUBMIT`;
      return api
        .get(url)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch(error => {
          reject(error);
      })
    });
  };

  const updateDocketManagementData = (params, body) => {
    return new Promise((resolve, reject) => {
      const url = `${config.api.c2c.docketManagement.updateData.path}?dealId=${params.dealId}`;
      api.post(url, body)
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            reject(error);
        })
    });
  }

  const uploadDocketManagementDiyDoc = (params, body) => {
    return new Promise((resolve, reject) => {
      const url = `${config.api.c2c.docketManagement.uploadDiyDoc.path}?${buildQuery(params)}`;
      api.post(url, body)
        .then(response => {
            resolve(response.data.data);
        })
        .catch(error => {
            reject(error);
        })
    });
  }

  const updateDocketDocVisibility = (params) => {
    return new Promise((resolve, reject) => {
      const url = `${config.api.c2c.docketManagement.updateDocketDocVisibility.path}?${buildQuery(params)}`;
      api.put(url)
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            reject(error);
        })
    });
  }

  const getDocketManagementDiyDoc = (id) => {
    return new Promise((resolve, reject) => {
      const url = `${config.api.c2c.docketManagement.getDiyDocument.path}/${id}`;
      api.get(url)
        .then(response => {
            resolve(response.data.data);
        })
        .catch(error => {
            reject(error);
        })
    });
  }

  return {
    fetchDocketManagementDocumentsConfig,
    fetchDocketManagementDocs,
    fetchDocketManagementFormData,
    updateDocketManagementData,
    uploadDocketManagementDiyDoc,
    updateDocketDocVisibility,
    getDocketManagementDiyDoc,
  };
};