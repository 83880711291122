import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import RcOthersTracker from './component';

const mapStateToProps = (state) => {
  const {
    rcOthersDealInfo: { dealInfo = {} },
    loaderInfo
  } = state;
  return {
    dealInfo,
    loaderInfo
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RcOthersTracker));
