import { config } from './../config/local';
export default (api) => {
  const fetchUserRcAccess = (email) => {
    const path = config.api.c2c.userRcAccess.path;
    const url = `${path}?user_email=${email}`;
    return api.get(url);
  };

  const addUserRcAccess = (data) => {
    const path = config.api.c2c.userRcAccess.path;
    const url = `${path}`;
    return api.post(url, data);
  };
  const editUserRcAccess = (data) => {
    const path = config.api.c2c.userRcAccess.path;
    const url = `${path}`;
    return api.put(url, data);
  };
  return {
    fetchUserRcAccess,
    addUserRcAccess,
    editUserRcAccess
  };
};
