import { STORE_TYPE_OPTIONS } from '../../../../shared/utils/constants/inventory';
import { storeStatusConstants } from '../constants';
const columnDefs = [
  {
    headerName: 'Store ID',
    width: 60,
    field: 'id',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    cellRenderer: 'LinkRenderer',
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Store Name',
    field: 'storeName',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    cellRenderer: 'LabelRenderer',
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Phone Number',
    width: 100,
    field: 'storePhoneNumber',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    cellRenderer: 'LabelRenderer',
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Status',
    field: 'status',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'status',
      options: [
        { key: storeStatusConstants.ACTIVE, value: 'Active' },
        { key: storeStatusConstants.INACTIVE, value: 'Inactive' }
      ]
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: [
        { key: storeStatusConstants.ACTIVE, value: 'Active' },
        { key: storeStatusConstants.INACTIVE, value: 'Inactive' }
      ]
    },
    suppressMenu: true
  },
  {
    headerName: 'Location Type',
    field: 'locationType',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'locationType',
      options: STORE_TYPE_OPTIONS.map(({ label, value }) => ({ key: value, value: label }))
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: STORE_TYPE_OPTIONS.map(({ label, value }) => ({ key: value, value: label }))
    },
    suppressMenu: true
  }
];

export default columnDefs;
