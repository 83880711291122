export const TABLE_HEADERS = [
  {
    label: 'Date',
    headerKey: 'created_at',
    colWidth: 'col-2',
    dataType: 'datetime'
  },
  { label: 'User Email', headerKey: 'created_by', colWidth: 'col-3' },
  {
    label: 'Remark',
    headerKey: 'remarks_text',
    colWidth: 'col-7'
  }
];
