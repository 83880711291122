import React from "react";
import Select from 'react-select';
const SelectComponent = ({ name, placeholder = "", defaultValue = "", hasError = false, isDisabled = false,
  isMulti = false, isSearchable = true, isClearable = false,
  onChange,
  optionsList,
  error = "" }) => {
  const options = optionsList.map((option) => {
      return (
          { value: option.value, label: option.label }
      );
  });
  return (
      <div className={""}>
          <Select
              key={name}
              name={name}
              placeholder={placeholder}
              defaultValue={defaultValue}
              isDisabled={isDisabled}
              isSearchable={isSearchable}
              isClearable={isClearable}
              isMulti={isMulti}
              options={options}
              classNamePrefix='react-select'
              onChange={(option) => onChange(option.value)} />
          {hasError &&
              <span>{error}</span>
          }
      </div>
  );
};

export default SelectComponent;
