import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CityStoreConfig from './component';
import { fetchZonesListConnected } from '../zones-grid/actions';
import { editInspectionStoreConnected } from './actions';

const mapStateToProps = (state) => {
  const { zoneInfo } = state;
  return { zoneInfo };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchZonesListConnected, editInspectionStoreConnected },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CityStoreConfig);
