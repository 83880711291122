import { useState } from "react";
import { rcManagementService } from "../../../services"

export const useRcOthersSegmentForm = () => {
  const getServiceConfig = rcManagementService.getRcOthersServiceConfig;

  const [serviceTypes, setServiceTypes] = useState([]);

  const fetchServiceConfig = () => {
    getServiceConfig()
      .then((resp) => {
        const { serviceDetailsConfig } = resp;
        setServiceTypes(serviceDetailsConfig);
      })
  };

  const getServiceDetailsByServiceType = (serviceType) => {
    const serviceTypeDetails = serviceTypes.find((s) => s.value === serviceType);
    return serviceTypeDetails && serviceTypeDetails.subDropDownList ? serviceTypeDetails.subDropDownList : [];
  };

  return { serviceTypes, fetchServiceConfig, getServiceDetailsByServiceType };
}