import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RcManagementGrid from './component';
import {
  setPageNumber,
  fetchOthersRcList
} from './actions';
const mapStateToProps = (state) => {
  const {
    rcManagementOthersGrid: { pageNumber }
  } = state;
  return {
    pageNumber
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPageNumberConnect: setPageNumber,
      fetchOthersRcListConnect: fetchOthersRcList
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RcManagementGrid);
