/* eslint-disable no-magic-numbers */
import React from "react";
import styles from  './styles.module.css';
import { DispositionConstants } from "../Constants";

const BuyerProfiles = ({data, leadType, financeStatus, params}) => {
    const buyerData = !!data ? Object.keys(data) : [];
    const buyer = !!data ? Object.values(data) : [];
    const {callType='', bookingId='', source=''} = params;
    return (
        <div className={styles.dataList}>
            <ul>
                <li key={'source'}>
                    <p>Source</p>
                    <span>{source}</span>
                </li>
                <li key={'callType'}>
                    <p>Call Type</p>
                    <span>{callType}</span>
                </li>
                {buyerData.map((key, index)=> {
                    return (
                        <li key={`key_${key}`}>
                            <p>{key}</p>
                            <span key={`skey_${key}`}>{buyer[index]}</span>
                        </li>
                    );
                })}
                {!!bookingId && <li key={'bookingId'}>
                    <p>Booking ID</p>
                    <span>{bookingId}</span>
                </li>}
                {leadType !== DispositionConstants.CC_MISSED_CALL && <li key='financeStatus'>
                    <p>{'Finance Status'}</p>
                    <span>{financeStatus}</span>
                </li>}
            </ul>
        </div>
    );
};

export default BuyerProfiles;
