import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InspectionTab from './component';
import { fetchInspectionDetailConnected } from './actions';

const mapStateToProps = (state) => {
  const {
    inspectionInfo: { data: inspectionInfo, isError }
  } = state;
  return { isError, inspectionInfo };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchInspectionDetailConnected }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InspectionTab);
