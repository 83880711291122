import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  message: null,
  dealerData: {}
};

export const fetchOrderDetailInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true, isError: false, message: null };
};

export const fetchOrderDetailSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return { ...state, isLoading: false, isError: false, dealerData: data };
};

export const fetchOrderDetailFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: false,
    isError: true,
    message: 'Something failed'
  };
};

export const HANDLERS = {
  [Types.FETCH_ORDER_DEALER_INIT]: fetchOrderDetailInit,
  [Types.FETCH_ORDER_DEALER_SUCCESS]: fetchOrderDetailSuccess,
  [Types.FETCH_ORDER_DEALER_FAILURE]: fetchOrderDetailFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
