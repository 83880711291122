import React from "react";
import AddZone from "../../components/add-zones";
import withAdminLayout from "../../../shared/hoc/admin-layout";
import ErrorBoundary from "../../components/common/errorBoundary"
import { withAuthorization } from "../../../shared/utils/firebase";
const ZonesPage = (props) => {
    return (
        <div>
            <ErrorBoundary>
                <AddZone {...props}/>
            </ErrorBoundary>
        </div>
    );
};
export default withAdminLayout(withAuthorization(()=>true)(ZonesPage));
