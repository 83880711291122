// import { resettableReducer } from 'reduxsauce';
import { combineReducers } from 'redux';
import { resettableReducer } from 'reduxsauce';

import commonReducer from '../admin-app/reducers/commonReducer';
import UserReducer from '../shared/components/admin-header/userReducer';
import HomeReducer from '../shared/components/admin-header/homeReducer';
//import OrderReducer from '../admin-app/reducers/orderReducer';
import StoreReducer from '../admin-app/reducers/storeReducer';
import LanguageReducer from '../admin-app/reducers/languageReducer';
import DealerReducer from '../admin-app/reducers/dealerReducer';
import AgentReducer from '../admin-app/reducers/agentReducer';
import UploadReducer from '../admin-app/reducers/uploadReducer';
import InventoryReducer from '../admin-app/reducers/inventoryReducer';
import SideBarReducer from '../shared/components/admin-sidebar/reducers';
import DealInfoReducer from '../admin-app/components/rc-tracker-deal-info/reducers';
import LoaderInfoReducer from '../shared/components/loader-with-access-control/reducers';
import SellerInfoReducer from '../admin-app/components/order-seller-tab/reducers';
import DealerInfoReducer from '../admin-app/components/order-dealer-tab/reducers';
import ImageInfoReducer from '../admin-app/components/car-images/reducers';
import CarInfoReducer from '../admin-app/components/orders-car-information/reducers';
import GridReducer from '../admin-app/components/common/grid-view/reducers';
import RcManagementReducer from '../admin-app/components/rc-Management-grid/reducers';
import OrdersReducer from '../admin-app/components/orders-grid/reducers';
import StoreGridReducer from '../admin-app/components/stores-grid/reducers';
import RcCaseTypeRemarksReducer from '../admin-app/components/rc-casetype-remarks/reducers';
import PublishRemarksReducer from '../admin-app/components/order-publish-remarks/reducers';
import PublishTabReducer from '../admin-app/components/order-publish-tab/reducers';
import BasicDetailInfoReducer from '../admin-app/components/order-update-tab/reducers';
import InspectionInfoReducer from '../admin-app/components/order-inspection-tab/reducers';
import ArDealerReducer from '../admin-app/components/ar-dealer-form/reducers';
import DealersReducer from '../admin-app/components/dealer-form/reducers';
import InventoryInfoReducer from '../admin-app/components/order-inventory-tab/reducers';
import ZoneInfoReducer from '../admin-app/components/zones-grid/reducers';
import UserInfoReducer from '../admin-app/components/user-form/reducers';
import UserGridInfoReducer from '../admin-app/components/user-grid/reducers';
import paymentInfoReducer from '../admin-app/components/finance-payment-tab/reducers';
import C2cStoreReducer from '../admin-app/components/store-form/reducers';
import FinanceDealInfoReducer from '../admin-app/components/finance-dealInfo/reducers';
import FinanceGridReducer from '../admin-app/components/finance-grid/reducers';
import CityConfigReducer from '../admin-app/components/city-detail-config/reducers';
import CityGridReducer from '../admin-app/components/city-grid/reducers';
import UserRcAccessReducer from '../admin-app/components/user-rc-access/reducers';
import RcDocumentRemarksReducer from '../admin-app/components/rc-documents-remarks/reducers';
import financeUploadReducer from '../admin-app/components/finance-bank-upload/reducers';
import pricingLogsReducer from '../admin-app/components/order-seller-pricing-logs/reducers';
import agentGridInfoReducer from '../admin-app/components/agent-grid/reducers';
import documentsRegionListReducer from '../admin-app/components/documents-region-list/reducer';
import documentsConfigReducer from '../admin-app/components/documents-config/reducer';
import caseTypeConfigReducer from '../admin-app/components/case-types-config/reducer';
import documentsListReducer from '../admin-app/components/documents-list/reducer';
import documentRejectionReasonsConfigReducer from '../admin-app/components/document-config-rejection-reason/reducer';
import DocketManagementReducer from '../admin-app/components/docket-management/reducer';
import rcManagementOthersGridReducer from '../admin-app/components/rc-others/rc-management-others-grid/reducers';
import RcOthersDealInfoReducer from '../admin-app/components/rc-others/rc-others-deal-info/reducers';
import RcOthersCaseTypeRemarksReducer from '../admin-app/components/rc-others/rc-others-casetype/rc-others-casetype-remarks/reducers';

const resettable = resettableReducer('RESET');

const rootReducer = combineReducers({
  common: resettable(commonReducer),
  home: resettable(HomeReducer),
  user: resettable(UserReducer),
  //order: resettable(OrderReducer), // to be deprecated (Akash)
  store: resettable(StoreReducer),
  dealer: resettable(DealerReducer),
  agent: resettable(AgentReducer),
  upload: resettable(UploadReducer),
  inventory: resettable(InventoryReducer), // deprecated
  SideBar: resettable(SideBarReducer),
  dealInfo: resettable(DealInfoReducer),
  loaderInfo: resettable(LoaderInfoReducer),
  sellerInfo: resettable(SellerInfoReducer),
  dealerInfo: resettable(DealerInfoReducer),
  imageInfo: resettable(ImageInfoReducer),
  order: resettable(CarInfoReducer),
  gridInfo: resettable(GridReducer),
  rcManagementInfo: resettable(RcManagementReducer),
  ordersInfo: resettable(OrdersReducer),
  storeInfo: resettable(StoreGridReducer),
  rcCaseTypeRemarksInfo: resettable(RcCaseTypeRemarksReducer),
  publishRemarksInfo: resettable(PublishRemarksReducer),
  publishInfo: resettable(PublishTabReducer),
  basicDetailInfo: resettable(BasicDetailInfoReducer),
  inspectionInfo: resettable(InspectionInfoReducer),
  arDealerInfo: resettable(ArDealerReducer),
  dealerInformation: resettable(DealersReducer),
  inventoryInfo: resettable(InventoryInfoReducer),
  zoneInfo: resettable(ZoneInfoReducer),
  userInfo: resettable(UserInfoReducer),
  UserGridInfo: resettable(UserGridInfoReducer),
  c2cStoreInfo: resettable(C2cStoreReducer),
  financeDealInfo: resettable(FinanceDealInfoReducer),
  financeGridInfo: resettable(FinanceGridReducer),
  paymentInfo: resettable(paymentInfoReducer),
  cityConfig: resettable(CityConfigReducer),
  cityGridInfo: resettable(CityGridReducer),
  userRcAccessInfo: resettable(UserRcAccessReducer),
  rcDocumentRemarksInfo: resettable(RcDocumentRemarksReducer),
  financeUploadInfo: resettable(financeUploadReducer),
  langaugeInfo: resettable(LanguageReducer),
  pricingLogs: resettable(pricingLogsReducer),
  agentGridInfo: resettable(agentGridInfoReducer),
  documentsRegionList: documentsRegionListReducer,
  documentsConfig: documentsConfigReducer,
  caseTypeConfig: caseTypeConfigReducer,
  documentsList: documentsListReducer,
  documentRejectionReasonsConfig: documentRejectionReasonsConfigReducer,
  docketManagement: DocketManagementReducer,
  rcManagementOthersGrid: resettable(rcManagementOthersGridReducer),
  rcOthersDealInfo: resettable(RcOthersDealInfoReducer),
  RcOthersCaseTypeRemarks: resettable(RcOthersCaseTypeRemarksReducer),
});

export { rootReducer };
