import { config } from '../config/local';

export default (api) => {  
  const fetchAttributesData = () => {
      const url = config.api.c2c.caseTypeRuleEngine.fetchAttributesData.path;
      return api
        .get(url)
        .then((resp) => {
          const {
            data: { data }
          } = resp;
          return data;
        })
        .catch((error) => {
          throw error;
        });
    };

  const fetchCaseTypeConfig = () => {
    return new Promise((resolve, reject) => {
      const url = config.api.c2c.caseTypeRuleEngine.fetchCaseTypeConfig.path;
      api.get(url)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          })
    });
  }

  const addCaseTypeConfig = (params, body) => {
    return new Promise((resolve, reject) => {
      const url = config.api.c2c.caseTypeRuleEngine.addCaseTypeConfig.path;
      api.post(url, body)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          })
    });
  }

  const updateCaseTypeConfig = (params, body) => {
    return new Promise((resolve, reject) => {
      const url = config.api.c2c.caseTypeRuleEngine.updateCaseTypeConfig.path;
      api.put(url, body)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          })
    });
  }

  const deleteCaseTypeConfig = (id) => {
    return new Promise((resolve, reject) => {
      const url = `${config.api.c2c.caseTypeRuleEngine.deleteCaseTypeConfig.path}/${id}`;
      api.delete(url)
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            reject(error);
        })
    });
  }

  return {
    fetchAttributesData,
    addCaseTypeConfig,
    updateCaseTypeConfig,
    fetchCaseTypeConfig,
    deleteCaseTypeConfig,
  };
};