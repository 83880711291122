import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FinanceRefundModal from './component';
import { refundPaymentInfoConnected } from './actions';
import { fetchTransactionLogsConnected } from '../finance-payment-tab/actions';

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { refundPaymentInfoConnected, fetchTransactionLogsConnected },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FinanceRefundModal);
