import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DealInformation from './component';
import { setRcOthersDealInfo } from './actions';
import { withRouter } from 'react-router-dom';
import { updateOthersRcStatus } from '../../../actions/rcManagementAction';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRcDealInfoConnect: (data) => setRcOthersDealInfo(data),
      updateOthersRcStatusConnect: updateOthersRcStatus
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(withRouter(DealInformation));
