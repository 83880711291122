import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import ZoneSystemReallocation from '../zones-system-reallocation';
import ZonesBuddyScoring from '../zones-buddy-scoring';
import { CheckBox } from '../../../client/components/shared';
import { dataService } from '../../../services';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';

import { console_in_dev } from '../../../shared/utils/helper';
import ZoneConfiguration from '../zone-configuration';
import { buddyScoringApi } from '../../../client/service';

const ZoneDetailConfig = ({
  fetchStates,
  fetchCities,
  addZone,
  match,
  ...restProps
}) => {
  const [zoneFormData, setZoneFormData] = useState(null);
  const [buddyScoringLead, setBuddyScoringLead] = useState({});
  const id = match.params.id;
  useEffect(() => {
    dataService
      .fetchZoneId(id)
      .then((res) => {
        setZoneFormData(res);
      })
      .catch((err) => {
        console_in_dev(err, 'err');
      });
  }, []);

  useEffect(() => {
    buddyScoringApi
      .getBuddyScoringLead(id)
      .then((res) => {
        setBuddyScoringLead(res.data);
      })
      .catch((err) => {});
  }, []);

  const onChangeChechbox = (e) => {
    const { name, checked } = e.target;
    setZoneFormData({ ...zoneFormData, [name]: checked });
  };

  const onChangeInput = (e) => {
    let { name, value } = e.target;
    if (name === 'cityCode') {
      value = value ? value.replace(/[0-9]+/g, '').toUpperCase() : '';
    }
    setZoneFormData({ ...zoneFormData, [name]: value });
  };
  const handleSave = () => {
    dataService.editZone(id, zoneFormData);
  };

  return (
    <LoaderWithAccessControl>
      {!restProps.isLoading && !restProps.isError && zoneFormData && (
        <>
          <form name='store' method='POST'>
            <div className='form-group row'>
              <div className='form-group col-md-4'>
                <input type='hidden' name='id' />
                <label className='font-weight-bold'>Zone Name</label>
                <input
                  className='form-control'
                  disabled
                  type='text'
                  name='name'
                  value={zoneFormData.zone || ''}
                  required
                />
              </div>
              <div className='form-group col-md-4'>
                <input type='hidden' name='id' />
                <label className='font-weight-bold'>City</label>
                <input
                  className='form-control'
                  name='city'
                  disabled
                  value={zoneFormData.city || ''}
                />
              </div>
              <div className='form-group col-md-4'>
                <label className='font-weight-bold'>Type</label>
                <input
                  className='form-control'
                  name='zoneType'
                  disabled
                  value={zoneFormData.type || ''}
                />
              </div>
              <div className='form-group col-md-4'>
                <input type='hidden' name='id' />
                <label className='font-weight-bold'>UID</label>
                <input
                  className='form-control'
                  type='text'
                  name='cityCode'
                  value={zoneFormData.cityCode || ''}
                  onChange={onChangeInput}
                  required
                />
              </div>
              <div className='form-group col-md-4'>
                <label className='font-weight-bold'>Active</label>
                <CheckBox
                  name='isActive'
                  onClickCallback={(e) => onChangeChechbox(e)}
                  checkedStatus={zoneFormData.isActive || ''}
                />
              </div>
            </div>
          </form>
          <div className='form-group col-md-12 text-left'>
            <button onClick={handleSave} className='btn btn-primary'>
              Save
            </button>
          </div>
        </>
      )}
      <ZoneSystemReallocation />
      <ZonesBuddyScoring />
      <ZoneConfiguration
        setBuddyScoringLead={setBuddyScoringLead}
        buddyScoringLead={buddyScoringLead}
        id={id}
      />
    </LoaderWithAccessControl>
  );
};

export default withRouter(ZoneDetailConfig);
