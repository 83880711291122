import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Rcdocuments from './component';
import { RcManagementAction } from '../../../actions';

const mapStateToProps = (state) => {
  const {
    rcOthersDealInfo: { dealInfo = {}}
  } = state;
  return {
    dealInfo,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchRcDocumentDetail: RcManagementAction.fetchRcDocumentDetail,
      addRc: (data, isOtherRcView) => RcManagementAction.addRc(data, isOtherRcView),
      editRc: (data, isOtherRcView) => RcManagementAction.editRc(data, isOtherRcView),
      fetchOthersRcDocument: RcManagementAction.fetchOthersRcDocument
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Rcdocuments);
