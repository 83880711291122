import React, { useState, useEffect } from 'react';
import { Button, Label, Input } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './style/style.css';
import { SelectComponent, DispositionConstants, CFDetails } from '../../shared';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { validateSellerListingPrice, validateBuyerOfferPrice } from "./AdditionalFieldValidation";
import { getPriceInWords, onNumberKeyPress } from '../../../../shared/utils/helper';
import { STRIP_COMMA_FROM_NUMBER, questionConstants } from '../../shared/Constants';
import { tvrQuestions } from '../CF-Details/cfConstants';

const DispositionDetails = ({ dispositionData, setDisposition, isSubmit, setIsValidDisposition, setPostData, sellerMaxListingPrice = 0, buyerDiscountedPrice = 0, submitErrors, showSubmitErrors, setShowSubmitErrors, car = null, selectedCcDispositionId, setSelectedCcDispositionId }) => {
  const dispState = window.location.pathname.includes('seller') ? DispositionConstants.SELLER : DispositionConstants.BUYER;
  // console.log('dispositionData', dispositionData);
  const [dispositionList, setDispositionList] = useState((dispositionData.filter((item) => item.state === dispState)).map((item) => { return ({ value: item.dispositionStatus, label: item.dispositionStatus }) }));
  // console.log('dispositionList', dispositionList);
  const [subDispositionList, setSubDispositionList] = useState([]);
  const [dispositionStatus, setDispositionStatus] = useState();
  const [dispositionStatusError, setDispositionStatusError] = useState(false);
  const [subDispositionStatus, setSubDispositionStatus] = useState();
  const [subDispositionStatusError, setSubDispositionStatusError] = useState(false);
  const [dialerDisposition, setDialerDisposition] = useState('');
  const [additionalFields, setAdditionalFields] = useState();
  const [additionalFieldsValues, setAdditionalFieldsValues] = useState();
  const [additionalFieldsErrors, setAdditionalFieldsErrors] = useState();
  const [showSellerListPriceError, setShowSellerListPriceError] = useState(false);
  const [showBuyerOfferPriceError, setShowBuyerOfferPriceError] = useState(false);
  const [age, setAge] = useState("");
  const [income, setIncome] = useState("");
  const [pincode, setPincode] = useState("");
  const [incomeSource, setIncomeSource] = useState("");
  const [profession, setProfession] = useState("");
  const [industry, setIndustry] = useState("");
  const [coApplicant, setCoApplicant] = useState("");

  useEffect(() => {
    if (dispositionList) {
      const uniqueDispositionList = [...new Set(dispositionList.map(item => JSON.stringify(item)))].map(item => JSON.parse(item))
      setDispositionList(uniqueDispositionList);
    }
  }, []);

  useEffect(() => {
    if (dispositionStatus) {
      setSubDispositionList((dispositionData.filter((item) => item.state === dispState && item.dispositionStatus === dispositionStatus)).map((item) => { return ({ value: item.subDispositionStatus, label: item.subDispositionStatus }) }));
      setSubDispositionStatus();
      setDialerDisposition('');
    }
  }, [dispositionStatus]);

  useEffect(() => {
    setShowSubmitErrors(false);
    const selectedData = dispositionData.find((item) => item.state === dispState && item.dispositionStatus === dispositionStatus && item.subDispositionStatus === subDispositionStatus);

    if (selectedData && selectedData.additionalFields) {
      setAdditionalFields(selectedData.additionalFields);
      setDialerDisposition(selectedData.diallerDisposition);
    } else {
      setAdditionalFields();
    }
    selectedData ? setSelectedCcDispositionId(selectedData.ccDispositionId) : setSelectedCcDispositionId();
  }, [dispositionStatus, subDispositionStatus]);

  useEffect(() => {
    if (additionalFields) {
      let newFieldsValues = {}, newFieldsErrors = {};
      additionalFields.forEach(item => {
        newFieldsValues[item.fieldName] = additionalFieldsValues && additionalFieldsValues.hasOwnProperty(item.fieldName) ? additionalFieldsValues[item.fieldName] : undefined;
        if (item.required) {
          newFieldsErrors[item.fieldName] = false;
        }
      });
      setAdditionalFieldsValues(newFieldsValues);
      if (!(Object.keys(newFieldsErrors).length === 0 && newFieldsValues.constructor === Object)) {
        setAdditionalFieldsErrors(newFieldsErrors);
      }
    } else {
      setAdditionalFieldsValues();
      setAdditionalFieldsErrors();
    }
  }, [additionalFields]);

  const createAdditionalFields = () => {
    return additionalFields.map((item) =>
      <div className="row" key={item.fieldName}>
        <div className="col-5 form-group disp-box">
          <Label for={item.fieldName} className="lbl">{`${item.label}${item.required ? '*' : ''}`}:</Label>
        </div>
        <div className="col-7 form-group disp-box">
          {
            item.type === DispositionConstants.STRING && item.children.length === 0 &&
            <Input type="text" className={"text" + (additionalFieldsErrors && additionalFieldsErrors.hasOwnProperty(item.fieldName) && additionalFieldsErrors[item.fieldName] ? ' error' : '')} name={item.fieldName} autoComplete="off" onChange={(e) => onAdditionalFieldChange(item.fieldName, item.type, e)} />
          }
          {
            item.type === DispositionConstants.NUMBER &&
            <>
              <Input type="text" className={"text" +
                (((additionalFieldsErrors && additionalFieldsErrors.hasOwnProperty(item.fieldName) && additionalFieldsErrors[item.fieldName]) ||
                  (dispState === DispositionConstants.SELLER && item.fieldName === DispositionConstants.PRICE && showSellerListPriceError && additionalFieldsErrors && additionalFieldsErrors.hasOwnProperty(item.fieldName) && !additionalFieldsErrors[item.fieldName] && selectedCcDispositionId === DispositionConstants.SELLER_LIST_PRICE_CC_DISPOSITION_ID) ||
                  (dispState === DispositionConstants.BUYER && item.fieldName === DispositionConstants.PRICE && showBuyerOfferPriceError && additionalFieldsErrors && additionalFieldsErrors.hasOwnProperty(item.fieldName) && !additionalFieldsErrors[item.fieldName] && selectedCcDispositionId === DispositionConstants.BUYER_OFFER_PRICE_CC_DISPOSITION_ID)) ? ' error' : '')} name={item.fieldName} autoComplete="off" onKeyPress={(e) => onNumberKeyPress(e)}
                onChange={(e) => { e.target.value = e.target.value && e.target.value.replace(STRIP_COMMA_FROM_NUMBER, '') ? Number(e.target.value.replace(STRIP_COMMA_FROM_NUMBER, '')).toLocaleString('en-IN') : ''; onAdditionalFieldChange(item.fieldName, item.type, e) }} />
              {
                additionalFieldsValues && additionalFieldsValues.hasOwnProperty(item.fieldName) && additionalFieldsValues[item.fieldName] > 0 &&
                <div className="price-text">
                  <span key={item.fieldName}>{getPriceInWords(additionalFieldsValues[item.fieldName])}</span>
                </div>
              }
            </>
          }
          {
            item.type === DispositionConstants.STRING && item.children && item.children.length > 0 &&
            <SelectComponent name={item.fieldName}
              hasError={additionalFieldsErrors && additionalFieldsErrors.hasOwnProperty(item.fieldName) && additionalFieldsErrors[item.fieldName] ? true : false}
              onChange={(value) => onAdditionalFieldChange(item.fieldName, item.type, value, true)}
              optionsList={item.children} />
          }
          {
            item.type === DispositionConstants.TEXTAREA &&
            <Input type="textarea" className={"textarea" + (additionalFieldsErrors && additionalFieldsErrors.hasOwnProperty(item.fieldName) && additionalFieldsErrors[item.fieldName] ? ' error' : '')} name={item.fieldName} autoComplete="off" onChange={(e) => onAdditionalFieldChange(item.fieldName, item.type, e)} />
          }
          {
            item.type === DispositionConstants.DATETIME &&
            <div className="datetime-picker">
              <DatePicker
                calendarClassName="datepicker-container"
                className={"adtnl-flds-dt" + (additionalFieldsErrors && additionalFieldsErrors.hasOwnProperty(item.fieldName) && additionalFieldsErrors[item.fieldName] ? ' error' : '')}
                selected={additionalFieldsValues && additionalFieldsValues.hasOwnProperty(item.fieldName) && additionalFieldsValues[item.fieldName] ? new Date(additionalFieldsValues[item.fieldName]) : null}
                onChange={(e) => { onAdditionalFieldChange(item.fieldName, item.type, e) }}
                onChangeRaw={(e) => e.preventDefault()}
                minDate={new Date()}
                disabledKeyboardNavigation
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="d MMMM yyyy h:mm aa"
                timeCaption="Time"
              />
            </div>
          }
          {
            item.required && additionalFieldsErrors && additionalFieldsErrors[item.fieldName] &&
            <span className="text-error">{item.label} is required</span>
          }
          {
            dispState === DispositionConstants.SELLER && item.fieldName === DispositionConstants.PRICE && showSellerListPriceError && additionalFieldsErrors && additionalFieldsErrors.hasOwnProperty(item.fieldName) && !additionalFieldsErrors[item.fieldName] && selectedCcDispositionId === DispositionConstants.SELLER_LIST_PRICE_CC_DISPOSITION_ID &&
            <div>
              <span className="text-error">{item.label} cannot be greater than max listing price</span>
            </div>
          }
          {
            dispState === DispositionConstants.BUYER && item.fieldName === DispositionConstants.PRICE && showBuyerOfferPriceError && additionalFieldsErrors && additionalFieldsErrors.hasOwnProperty(item.fieldName) && !additionalFieldsErrors[item.fieldName] && selectedCcDispositionId === DispositionConstants.BUYER_OFFER_PRICE_CC_DISPOSITION_ID &&
            <div>
              <span className="text-error">{item.label} cannot be less than discounted price</span>
            </div>
          }
        </div>
      </div>
    )
  }

  const onAdditionalFieldChange = (fieldName, type, e, children = false) => {
    if (children) {
      setAdditionalFieldsValues({ ...additionalFieldsValues, [fieldName]: e });
      if (additionalFieldsErrors && additionalFieldsErrors.hasOwnProperty(fieldName)) {
        setAdditionalFieldsErrors({ ...additionalFieldsErrors, [fieldName]: false });
      }
    } else {
      if (type === DispositionConstants.DATETIME) {
        setAdditionalFieldsValues({ ...additionalFieldsValues, [fieldName]: e.toISOString() });
        if (additionalFieldsErrors && additionalFieldsErrors.hasOwnProperty(fieldName)) {
          setAdditionalFieldsErrors({ ...additionalFieldsErrors, [fieldName]: false });
        }
      } else {
        if (type === DispositionConstants.NUMBER) {
          const val = e.target.value && e.target.value.replace(STRIP_COMMA_FROM_NUMBER, '') ? e.target.value.replace(STRIP_COMMA_FROM_NUMBER, '') : 0;
          setAdditionalFieldsValues({ ...additionalFieldsValues, [fieldName]: val });
        } else {
          setAdditionalFieldsValues({ ...additionalFieldsValues, [fieldName]: e.target.value.trim().replace(/\r?\n|\r/g, " ") });
        }
        if (additionalFieldsErrors && additionalFieldsErrors.hasOwnProperty(fieldName)) {
          e.target.value.trim() ? setAdditionalFieldsErrors({ ...additionalFieldsErrors, [fieldName]: false }) : setAdditionalFieldsErrors({ ...additionalFieldsErrors, [fieldName]: true });
        }
        if (dispState === DispositionConstants.SELLER && type === DispositionConstants.NUMBER && fieldName === DispositionConstants.PRICE && selectedCcDispositionId === DispositionConstants.SELLER_LIST_PRICE_CC_DISPOSITION_ID) {
          e.target.value.replace(STRIP_COMMA_FROM_NUMBER, '') > sellerMaxListingPrice ? setShowSellerListPriceError(true) : setShowSellerListPriceError(false);
        }
        if (dispState === DispositionConstants.BUYER && type === DispositionConstants.NUMBER && fieldName === DispositionConstants.PRICE && selectedCcDispositionId === DispositionConstants.BUYER_OFFER_PRICE_CC_DISPOSITION_ID) {
          e.target.value.replace(STRIP_COMMA_FROM_NUMBER, '') < buyerDiscountedPrice ? setShowBuyerOfferPriceError(true) : setShowBuyerOfferPriceError(false);
        }
      }
    }
  }

  const onSubmit = () => {
    const isValid = validateDisposition();
    // console.log('isDispositionDataValid', isValid);
    if (isValid) {
      const dispId = dispositionData.find((item) => item.state === dispState && item.dispositionStatus === dispositionStatus && item.subDispositionStatus === subDispositionStatus).id;

      const dispositionObj = {
        dispositionId: dispId,
        dialerDisposition,
        cfDetail: null
      };

      if (dispState === DispositionConstants.BUYER) {
        dispositionObj.cfDetail = {
          age,
          monthlyIncome: income,
          pincode,
          cfAnswers: {
            [tvrQuestions[questionConstants.SOURCE_OF_INCOME].questionId]: incomeSource,
            [tvrQuestions[questionConstants.OCCUPATION].questionId]: profession,
            [tvrQuestions[questionConstants.CO_APPLICANT].questionId]: coApplicant
          }
        }
      }

      if (additionalFields) {
        dispositionObj.addtionalFields = additionalFieldsValues;
      }
      setDisposition(dispositionObj);
      setIsValidDisposition(true);
    } else {
      setDisposition();
      setIsValidDisposition(false);
    }
    setPostData(true);
  }

  const validateDisposition = () => {
    let errors = 0;
    dispositionStatus ? setDispositionStatusError(false) : ++errors && setDispositionStatusError(true);
    subDispositionStatus ? setSubDispositionStatusError(false) : ++errors && setSubDispositionStatusError(true);
    const additionalFieldsArr = additionalFieldsValues ? Object.entries(additionalFieldsValues) : [];
    let tempArr = additionalFieldsErrors;
    additionalFieldsArr.forEach(item => {
      if (tempArr && tempArr.hasOwnProperty(item[0])) {
        if (item[1] && item[1].trim()) {
          tempArr = { ...tempArr, [item[0]]: false };
        } else {
          ++errors;
          tempArr = { ...tempArr, [item[0]]: true };
        }
      }
      setAdditionalFieldsErrors(tempArr);
    });

    if (errors === 0) {
      const ccDispositionId = dispositionData.find((item) => item.state === dispState && item.dispositionStatus === dispositionStatus && item.subDispositionStatus === subDispositionStatus).ccDispositionId;
      const validSellerListPrice = validateSellerListingPrice(
        dispState,
        additionalFieldsArr,
        ccDispositionId,
        sellerMaxListingPrice
      );
      const validBuyerOfferPrice = validateBuyerOfferPrice(
        dispState,
        additionalFieldsArr,
        ccDispositionId,
        buyerDiscountedPrice
      );
      validSellerListPrice ? setShowSellerListPriceError(false) : ++errors && setShowSellerListPriceError(true);
      validBuyerOfferPrice ? setShowBuyerOfferPriceError(false) : ++errors && setShowBuyerOfferPriceError(true);
    }

    if (errors === 0)
      return true;
    else
      return false;
  }

  return (
    <div className="main_section_disp block disposition-block">
      <h2 className="title">Disposition Details</h2>
      <div className="row">
        <div className="col-5 form-group disp-box">
          <Label for="dispositionStatus" className="lbl">Disposition*:</Label>
        </div>
        <div className="col-7 form-group disp-box">
          <SelectComponent name="dispositionStatus" hasError={dispositionStatusError ? true : false} onChange={(value) => { setDispositionStatus(value); setDispositionStatusError(false); }} optionsList={dispositionList} />
          {
            dispositionStatusError &&
            <span className="text-error">Disposition is required</span>
          }
        </div>
      </div>
      <div className="row">
        <div className="col-5 form-group disp-box">
          <Label for="subDispositionStatus" className="lbl">Sub-Disposition*:</Label>
        </div>
        <div className="col-7 form-group disp-box">
          <SelectComponent name="subDispositionStatus" hasError={subDispositionStatusError ? true : false} onChange={(value) => { setSubDispositionStatus(value); setSubDispositionStatusError(false); }} optionsList={subDispositionList} />
          {
            subDispositionStatusError &&
            <span className="text-error">Sub-Disposition is required</span>
          }
        </div>
      </div>
      {
        additionalFields && createAdditionalFields()
      }
      <div className="row">
        <div className="col-5 form-group disp-box">
          <Label for="dialerDisposition" className="lbl">Dialer Disposition:</Label>
        </div>
        <div className="col-7 form-group disp-box">
          <Input type="text" className="txtbox" disabled name="dialerDisposition" value={dialerDisposition} />
        </div>
      </div>

      {
        additionalFieldsValues && additionalFieldsValues.cfInterested && additionalFieldsValues.cfInterested.toLowerCase() === 'yes' &&
        <CFDetails
          {...{
            age, setAge,
            income, setIncome,
            pincode, setPincode,
            incomeSource, setIncomeSource,
            profession, setProfession,
            industry, setIndustry,
            coApplicant, setCoApplicant
          }}
        />
      }

      {
        showSubmitErrors && submitErrors &&
        <>
          {
            submitErrors.map((item, index) =>
              <span key={`err_${index}`} className="text-error">{item.message}</span>
            )
          }
        </>
      }
      {
        dispState === DispositionConstants.BUYER && selectedCcDispositionId === DispositionConstants.BUYER_OFFER_PRICE_CC_DISPOSITION_ID && !car &&
        <div>
          <span className="text-error">Car Details not available. Please check CarUrl.</span>
        </div>
      }
      <div className="form-group disp-box button-align disposition-btn">
        <Button className="d-block submit" onClick={onSubmit} disabled={isSubmit}>{isSubmit ? 'Processing' : 'Submit'}</Button>
      </div>
    </div>
  );
}

export default DispositionDetails;