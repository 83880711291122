import React from 'react';
import { CheckBox } from '../../../client/components/shared';
import ArrowIcon from '../../../shared/arrow';
import Accordion from '../../../shared/accordion';
import { markUpSlabTableHeading, slabs } from './constant';

const PricingMarkupslab = ({
  markUpDetails,
  onChangeSlab,
  onChangeSlabChechbox,
  reset
}) => {
  return (
    <Accordion totalItems={1}>
      {({ onClick, currentVisibleStates }) => {
        return (
          <div className="borderWrapper">
            <Accordion.Content>
              <Accordion.Heading>
                <h2
                  className='font-weight-bold p0'
                  onClick={() => onClick({ index: 0 })}
                >
                  Mark Up Slab
                  <ArrowIcon
                    grey={'GreyArrow'}
                    rotateBy={currentVisibleStates[0] ? 180 : 90}
                  />
                </h2>
              </Accordion.Heading>
              <Accordion.Body>
                <table
                  className={
                    currentVisibleStates[0]
                      ? 'accordionShow table mt20'
                      : 'pricingMode accordionHide'
                  }
                >
                  <thead>
                    <tr>
                      {markUpSlabTableHeading.map((heading, index) => {
                        return <th key={index}>{heading}</th>;
                      })}
                    </tr>
                  </thead>
                  {markUpDetails &&
                    markUpDetails.map((data, index) => {
                      return (
                        <React.Fragment>
                          {data.serviceMarkUps && (
                            <tbody>
                              <tr key={index}>
                                <td>{data.serviceMarkUps.serviceName}</td>
                                <td>
                                  <CheckBox
                                    name='isPercentage'
                                    value={data.serviceMarkUps.isPercentage || ''}
                                    onClickCallback={e =>
                                      onChangeSlabChechbox(e, index)
                                    }
                                    checkedStatus={
                                      data.serviceMarkUps.isPercentage
                                    }
                                  />
                                </td>
                                {slabs.map((slab, i) => {
                                  return (
                                    <td key={`${i}${index}`}>
                                      <input
                                        className='form-control'
                                        type='text'
                                        name={slab}
                                        min='0'
                                        value={data.serviceMarkUps[slab] || ''}
                                        onChange={e =>
                                          onChangeSlab(
                                            e,
                                            index,
                                            data.serviceMarkUps.isPercentage
                                          )
                                        }
                                        required
                                      />
                                    </td>
                                  );
                                })}

                                <td>
                                  <button
                                    type='button'
                                    onClick={e => reset(e, index)}
                                    className='btn btn-primary'
                                  >
                                    Reset
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </React.Fragment>
                      );
                    })}
                </table>
              </Accordion.Body>
            </Accordion.Content>
          </div>
        );
      }}
    </Accordion>
  );
};

export default PricingMarkupslab;
