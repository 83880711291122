import Types from './types';
import { orderService, storeService } from '../../../services';

// Global actions
import {
  setAccessLoaderConnected,
  resetAccessLoaderConnected
} from '../../../shared/components/loader-with-access-control/actions';
import { handleHttpStatusError } from '../../../services/helper';
import {
  showToastMessages,
  console_in_dev
} from '../../../shared/utils/helper';
import locale from '../../../shared/utils/locale';

const updateInventoryInit = () => ({
  type: Types.INVENTORY_UPDATE_INIT
});

const updateInventorySuccess = (data) => ({
  type: Types.INVENTORY_UPDATE_SUCCESS,
  data
});

const updateInventoryFail = (error) => ({
  type: Types.INVENTORY_UPDATE_FAIL,
  error
});

const updateInventoryConnected = (data, params) => (dispatch) => {
  dispatch(updateInventoryInit());
  return new Promise((resolve, reject) => {
    orderService
      .updateInventory(data, params)
      .then((resp) => {
        resolve(resp);
        showToastMessages(resp.data.message);
        dispatch(updateInventorySuccess(resp));
      })
      .catch((error) => {
        error.errors &&
          error.errors[0] &&
          error.errors[0]['message'] &&
          showToastMessages(error.errors[0]['message'], false);
        dispatch(updateInventoryFail(error));
        reject(error);
      });
  });
};

const fetchInventoryLogInit = () => ({
  type: Types.FETCH_INVENTORY_LOG_INIT
});

const fetchInventoryLogSuccess = (data) => ({
  type: Types.FETCH_INVENTORY_LOG_SUCCESS,
  payload: data
});

const fetchInventoryLogFail = (error) => ({
  type: Types.FETCH_INVENTORY_LOG_FAIL,
  payload: error
});

const fetchInventoryLogConnected = (appointmentId) => (dispatch) => {
  dispatch(fetchInventoryLogInit());
  orderService
    .fetchInventoryLog(appointmentId)
    .then((resp) => {
      dispatch(fetchInventoryLogSuccess(resp));

      return resp;
    })
    .catch((error) => {
      dispatch(fetchInventoryLogFail(error));
      throw error;
    });
};

const fetchStores = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    storeService
      .fetchStores()
      .then((resp) => {
        if (resp.data && resp.data.data) {
          let storeHashed = {};
          const storeOptions = resp.data.data.map((item) => {
            const resObj = { label: item.storeName, value: item.id };
            storeHashed[item.id] = resObj;
            return resObj;
          });
          resolve({ storeOptions, storeHashed });
        } else {
          reject(resp);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { updateInventoryConnected, fetchInventoryLogConnected, fetchStores };
