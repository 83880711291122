export const ATTRIBUTE_KEYS = {
  DOCUMENT_ID: 'documentId',
  OWNERSHIP_TYPE: 'ownershipType',
  CATEGORY: 'category',
  NECESSITY: 'necessity',
  DOCUMENT_TYPE: 'documentType',
  UPLOAD_TYPE: 'uploadType',
  ALTERNATIVE_DOCS: 'alternativeDocs',
  IS_SIGNATURE_REQUIRED: 'isSignatureRequired',
  IS_APPLICABLE_FOR_CUSTOMER: 'isApplicableForCustomer'
}

export const columns = (attributes) => [
  {
    headerName: 'Document',
    cellRenderer: 'MultiSelectRenderer',
    width: 200,
    cellRendererParams: {
      attributeKey: ATTRIBUTE_KEYS.DOCUMENT_ID,
      options: attributes.documents
   }
  },
  {
    headerName: 'Ownership Type',
    cellRenderer: 'MultiSelectRenderer',
    width: 200,
    cellRendererParams: {
      attributeKey: ATTRIBUTE_KEYS.OWNERSHIP_TYPE,
      options: attributes.ownershipType
   }
  },
  {
    headerName: 'Category',
    cellRenderer: 'MultiSelectRenderer',
    width: 150,
    cellRendererParams: {
      attributeKey: ATTRIBUTE_KEYS.CATEGORY,
      options: attributes.category
   }
  },
  {
    headerName: 'Necessity',
    cellRenderer: 'MultiSelectRenderer',
    width: 180,
    cellRendererParams: {
      attributeKey: ATTRIBUTE_KEYS.NECESSITY,
      options: attributes.necessity
   }
  },
  {
    headerName: 'Document Type',
    cellRenderer: 'MultiSelectRenderer',
    width: 180,
    cellRendererParams: {
      attributeKey: ATTRIBUTE_KEYS.DOCUMENT_TYPE,
      options: attributes.documentType
   }
  },
  {
    headerName: 'Upload Type',
    cellRenderer: 'MultiSelectRenderer',
    width: 180,
    cellRendererParams: {
      attributeKey: ATTRIBUTE_KEYS.UPLOAD_TYPE,
      options: attributes.uploadType,
   }
  },
  {
    headerName: 'Alternative Docs',
    cellRenderer: 'MultiSelectRenderer',
    width: 300,
    cellRendererParams: {
      attributeKey: ATTRIBUTE_KEYS.ALTERNATIVE_DOCS,
      options: attributes.alternativeDocs,
      isMulti: true
   }
  },
  {
    headerName: 'Is Signature Required',
    width: 250,
    cellRenderer: 'MultiSelectRenderer',
    cellRendererParams: {
      attributeKey: ATTRIBUTE_KEYS.IS_SIGNATURE_REQUIRED,
      options: attributes.isSignatureRequired
   }
  },
  {
    headerName: 'Is Applicable for Customer',
    width: 250,
    cellRenderer: 'MultiSelectRenderer',
    cellRendererParams: {
      attributeKey: ATTRIBUTE_KEYS.IS_APPLICABLE_FOR_CUSTOMER,
      options: attributes.isApplicableForCustomer
   }
  },
  {
    headerName: 'Delete',
    cellRenderer: 'DeleteIconRenderer',
  },
];
