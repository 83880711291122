//Api is not Paginated
import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  message: null,
  userData: [],
  userHashed: {}
};
export const fetchUsersGridDataInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true, isError: false, message: null };
};
export const fetchUsersGridDataSuccess = (state = INITIAL_STATE, action) => {
  const data = action.payload;
  return { ...state, userData: data.userList, userHashed: data.userHashed };
};

export const fetchUsersGridDataFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    isError: true,
    message: action.payload,
    userData: [],
    userHashed: {}
  };
};

export const HANDLERS = {
  [Types.FETCH_USER_GRID_DATA_INIT]: fetchUsersGridDataInit,
  [Types.FETCH_USER_GRID_DATA_SUCCESS]: fetchUsersGridDataSuccess,
  [Types.FETCH_USER_GRID_DATA_FAILURE]: fetchUsersGridDataFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
