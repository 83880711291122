import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    SET_CITY_GRID_DATA_INIT
    SET_CITY_GRID_DATA_SUCCESS
    SET_CITY_GRID_DATA_FAILURE
`,
  {
    prefix: 'citygrid/'
  }
);
