import { showToastMessages } from '../../shared/utils/helper';
import { IS_ENV_DEV, IS_MOCK } from '../../shared/utils/constants';
import { config } from './../config/local';
import {
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected,
  handleHttpStatusError,
  buildQuery
} from '../../services/helper';
import { httpConstants } from '../constants/httpConstants';

export default (api) => {
  const fetchRcManagementGridInfo = (params = { page: 0, size: 10 }) => {
    const path = config.api.c2c.rcManagement.path;
    const queryStr = params ? `?${buildQuery(params)}` : '';
    const url = path + queryStr;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };
  const fetchOtherRcList = (params = { page: 0, size: 10 }) => {
    const path = config.api.c2c.rcOthersList.path;
    const queryStr = params ? `?${buildQuery(params)}` : '';
    const url = path + queryStr;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };
  const getRcDocumentsById = (RcId) => {
    dispatchSetLoaderConnected();
    const url = config.api.c2c.rcDocument.path;
    return api
      .get(`${url}/${RcId}`)
      .then((resp) => {
        dispatchResetLoaderConnected();
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        handleHttpStatusError(error);
        return Promise.reject(error);
      });
  };
  const getOthersRcDocuments = (id) => {
    dispatchSetLoaderConnected();
    const url = config.api.c2c.rcOthersDocuments.path;
    return api
      .get(`${url}/${id}`)
      .then((resp) => {
        dispatchResetLoaderConnected();
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        handleHttpStatusError(error);
        return Promise.reject(error);
      });
  };
  const addRcDocumnet = (data, isOtherRcView) => {
    const url = isOtherRcView ? config.api.c2c.rcOthersDocumentDelete.path : config.api.c2c.rcDocument.path;
    return api.post(url, data).then((resp) => {
      const {
        data: { data, message }
      } = resp;
      showToastMessages(message);
      return data;
    });
  };
  const editRcDocument = (data, isOtherRcView) => {
    const url = isOtherRcView ? config.api.c2c.rcOthersDocumentDelete.path : config.api.c2c.rcDocument.path;
    return api.put(url, data).then((resp) => {
      const {
        data: { data, message }
      } = resp;
      showToastMessages(message);
      return data;
    });
  };

  const getRcDocumentViewFile = (id) => {
    const url = config.api.c2c.rcDocumentFile.path;
    return api
      .get(`${url}/${id}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        throw error;
      });
  };
  const getOthersRcDocumentViewFile = (id) => {
    const url = config.api.c2c.rcOthersDocumentView.path;
    return api
      .get(`${url}/${id}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        return error;
      });
  };
  const deleteFile = (id, isOtherRcView) => {
    const url = isOtherRcView ? config.api.c2c.rcOthersDocumentView.path : config.api.c2c.rcDocumentFile.path;
    return api
      .delete(`${url}/${id}/`)
      .then((resp) => {
        const {
          data: { data, message }
        } = resp;
        showToastMessages(message);
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
  const uploadFile = (
    params,
    fileObject,
  ) => {
    const url = config.api.c2c.rcDocumentFile.path;
    return api
      .post(`${url}?${buildQuery(params)}`, fileObject)
      .then((resp) => {
        const {
          data: { data, message }
        } = resp;
        showToastMessages(message);
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
        // return error;
      });
  };
  const uploadOtherRcDocument = ({id, fileType, fileUserType, fileObject}) => {
    const url = config.api.c2c.rcOthersDocuments.path;
    return api
      .post(`${url}/file?rcApplicationId=${id}&fileUserType=${fileUserType}&fileType=${fileType}`, fileObject)
      .then((resp) => {
        const {
          data: { data, message }
        } = resp;
        showToastMessages(message);
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
        // return error;
      });
  };
  const fetchAgents = () => {
    const url = config.api.c2c.rcTrackerAgent.path;
    return api
      .get(url)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        return error;
      });
  };
  const fetchOthersRcAgents = () => {
    const url = config.api.c2c.rcOthersTrackerAgent.path;
    return api
      .get(url)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        return error;
      });
  };
  const fetchDelayReasons = (dealId) => {
    const url = !!dealId ? `${config.api.c2c.rcTrackerDelayReasons.path}/${dealId}` : config.api.c2c.rcTrackerDelayReasons.path;
    return api
      .get(url)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        return error;
      });
  };
  const fetchOthersRcDelayReasons = (dealId) => {
    const url = !!dealId ? `${config.api.c2c.rcOthersDelayReasons.path}/${dealId}` : config.api.c2c.rcOthersDelayReasons.path;
    return api
      .get(url)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        return error;
      });
  };

  const fetchAgentHistory = (rcTrackerId) => {
    const url = config.api.c2c.rcTrackerAgentHistory.path;
    return api
      .get(`${url}/${rcTrackerId}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        return error;
      });
  };

  const fetchRcOthersAgentHistory = (rcTrackerId) => {
    const url = config.api.c2c.rcOthersTrackerAgentHistory.path;
    return api
      .get(`${url}/${rcTrackerId}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        return error;
      });
  };

  const fetchRcTrackerRemarks = (rcTrackerId, withOps = false, page = 0) => {
    const url = config.api.c2c.rcRemarks.path;
    if (!rcTrackerId) return false;
    return api
      .get(
        `${url}?remarks_type_id=${rcTrackerId}&remarks_type=TRACKER&city_ops=${withOps}&size=5&page=${page}`
      )
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        return error;
      });
  };

  const fetchRcOthersTrackerRemarks = (rcTrackerId, withOps = false, page = 0) => {
    const url = config.api.c2c.rcOthersRemarks.path;
    if (!rcTrackerId) return false;
    return api
      .get(
        `${url}?remarks_type_id=${rcTrackerId}&remarks_type=TRACKER&city_ops=${withOps}&size=5&page=${page}`
      )
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        return error;
      });
  };

  const saveRcTrackerRemarks = (data, isOtherRcView) => {
    const url = isOtherRcView ? config.api.c2c.rcOthersTrackerRemarks.path : config.api.c2c.trackerRemarks.path;
    return api
      .post(url, data)
      .then((resp) => {
        const {
          data: { data, message }
        } = resp;
        showToastMessages(message);
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
  const saveRcCasetypeRemarks = (data) => {
    const url = config.api.c2c.caseTypeRemarks.path;
    return api
      .post(url, data)
      .then((resp) => {
        const {
          data: { data, message }
        } = resp;
        showToastMessages(message);
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
  const saveOthersRcCasetypeRemarks = (data) => {
    const url = config.api.c2c.rcOthersCaseTypeRemarks.path;
    return api
      .post(url, data)
      .then((resp) => {
        const {
          data: { data, message }
        } = resp;
        showToastMessages(message);
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  const updateOthersRcStatus = (id, params) => {
    const url = `${config.api.c2c.rcOthersUpdateStatus.path}/${id}?${buildQuery(params)}`;
    return api
      .put(url)
      .then((resp) => {
        const {
          data: { data, message }
        } = resp;
        showToastMessages(message);
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  const saveRcDocumentsRemarks = (data) => {
    const url = config.api.c2c.rcDocumentsRemarks.path;

    return api
      .post(url, data)
      .then((resp) => {
        const {
          data: { data, message }
        } = resp;
        showToastMessages(message);
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
  const saveOthersRcDocumentsRemarks = (data) => {
    const url = config.api.c2c.saveOthersRcDocumentRemarks.path;

    return api
      .post(url, data)
      .then((resp) => {
        const {
          data: { data, message }
        } = resp;
        showToastMessages(message);
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
  const fetchRcTrackerData = (rcTrackerId, withLoader) => {
    withLoader && dispatchSetLoaderConnected();
    const url = config.api.c2c.rcTracker.path;
    return api
      .get(`${url}/${rcTrackerId}`)
      .then((resp) => {
        dispatchResetLoaderConnected();
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        handleHttpStatusError(error);
        return Promise.reject(error);
      });
  };

  const fetchRcOthersTrackerData = (rcTrackerId, withLoader) => {
    withLoader && dispatchSetLoaderConnected();
    const url = config.api.c2c.rcOthersTracker.path;
    return api
      .get(`${url}/${rcTrackerId}`)
      .then((resp) => {
        dispatchResetLoaderConnected();
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        handleHttpStatusError(error);
        return Promise.reject(error);
      });
  };

  const addRcTrackerData = (data) => {
    const url = config.api.c2c.rcTracker.path;
    return api
      .post(url, data)
      .then((resp) => {
        showToastMessages(resp.data.message);
        return resp;
      })
      .catch((error) => {
        return error;
      });
  };

  const updateRcTrackerData = (data) => {
    const url = config.api.c2c.rcTracker.path;
    return api
      .put(url, data)
      .then((resp) => {
        showToastMessages(resp.data.message);
        return resp;
      })
      .catch((error) => {
        return error;
      });
  };

  const updateOthersRcTrackerData = (data) => {
    const url = config.api.c2c.rcOthersTracker.path;
    return api
      .put(url, data)
      .then((resp) => {
        showToastMessages(resp.data.message);
        return resp.data;
      })
      .catch((error) => {
        return error;
      });
  };

  const saveFilters = (data, filterType, filterId) => {
    const method = filterId ? 'put' : 'post';
    return api[method](`/filters?filterType=${filterType}`, data)
      .then((resp) => {
        const {
          data: { data, message }
        } = resp;
        showToastMessages(message);

        return data;
      })
      .catch((error) => {
        return error;
      });
  };

  const fetchFilter = (filterType) => {
    return api
      .get(`filters?filterType=${filterType}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        // showToastMessages(resp.data.message);
        return data;
      })
      .catch((error) => {
        return error;
      });
  };

  const fetchRcCasetypeRemarks = (id, type, withOps, page = 0) => {
    const url = `${
      config.api.c2c.rcRemarks.path
    }?remarks_type_id=${id}&remarks_type=${type}&city_ops=${withOps}&size=5&page=${page}`;
    return api.get(url);
  };

  const fetchOthersRcCasetypeRemarks = (id, type, withOps, page = 0) => {
    const url = `${
      config.api.c2c.rcOthersRemarks.path
    }?remarks_type_id=${id}&remarks_type=${type}&city_ops=${withOps}&size=5&page=${page}`;
    return api.get(url);
  };

  const fetchRcDocumentRemarks = (id, type, withOps, page = 0) => {
    const url = `${
      config.api.c2c.rcRemarks.path
    }?remarks_type_id=${id}&remarks_type=${type}&city_ops=${withOps}&size=5&page=${page}`;
    return api.get(url);
  };

  const fetchOthersRcRemarks = (id, type, withOps, page = 0) => {
    const url = `${
      config.api.c2c.rcOthersRemarks.path
    }?remarks_type_id=${id}&remarks_type=${type}&city_ops=${withOps}&size=5&page=${page}`;
    return api.get(url);
  };

  const uploadFileRcTracker = (params, fileObject) => {
    const url = `${config.api.c2c.rcTrackerUpload.path}?${buildQuery(params)}`;
    return api
      .post(url, fileObject)
      .then((resp) => {
        const {
          data: { data, message }
        } = resp;
        showToastMessages(message);
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
        // return error;
      });
  };

  const uploadFileRcOthersTracker = (params, fileObject) => {
    const url = `${config.api.c2c.rcOthersTrackerUpload.path}?${buildQuery(params)}`;
    return api
      .post(url, fileObject)
      .then((resp) => {
        const {
          data: { data, message }
        } = resp;
        showToastMessages(message);
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  const viewFileRcTracker = (id) => {
    const url = `${config.api.c2c.rcTrackerUpload.path}/${id}`;
    return api.get(url);
  };

  const viewFileRcOthersTracker = (id) => {
    const url = `${config.api.c2c.rcOthersTrackerUpload.path}/${id}`;
    return api.get(url);
  };

  const deleteFileRcTracker = (id) => {
    const url = `${config.api.c2c.rcTrackerUpload.path}/${id}`;
    return api.delete(url);
  };

  const deleteFileRcOthersTracker = (id) => {
    const url = `${config.api.c2c.rcOthersTrackerUpload.path}/${id}`;
    return api.delete(url);
  };

  // ---------------- //
  // -------------------------- //

  const addAgent = (payload) => {
    const path = config.api.c2c.agent.path;
    return api.post(path, payload);
  };

  const editAgent = (payload) => {
    const path = config.api.c2c.agent.path;
    return api.put(path, payload);
  };

  const fetchAgentInfoById = (agentId) => {
    const path = config.api.c2c.agent.path;
    const url = `${path}/${agentId}`;
    return api.get(url);
  };

  // const fetchAgentGridInfo = (params) => {
  //   const url = config.api.c2c.allAgents.path;
  //   return api.get(url);
  // };

  const fetchAgentGridInfo = (params = { page: 0, size: 10 }) => {
    const path = config.api.c2c.allAgents.path;
    const queryStr = params ? `?${buildQuery(params)}` : '';
    const url = path + queryStr;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };
  
  const resolveDelayReason = (delayReasonId, isOtherRcView) => {
    let url = isOtherRcView ? `${config.api.c2c.rcOthersDelayReasonResolved.path}/${delayReasonId}` : `${config.api.c2c.rcTrackerDelayReasonResolved.path}/${delayReasonId}`;
    return api.put(url)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error;
      });
  };
  const addRcDetailsOthers = (data) => {
    const url = config.api.c2c.rcAddOthers.path;
    return api
      .post(url, data)
      .then((resp) => {
        // const {
        //   data: { data, message }
        // } = resp;
        // showToastMessages(message);
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  const FetchRcVerificationData = (id, isOthers=false) => {
    const path = isOthers ? 'rc/v3/get-rc-verification-details?rcApplicationId=' : 'rc/get-rc-verification-details?dealId=';
    return new Promise((resolve, reject) => {
      api.get(`${path}${id}`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      })
    });
  };

  const FetchRefreshRcVerificationData = (id, isOthers=false) => {
    const path = isOthers ? 'rc/v3/refresh-rc-verification-details?rcApplicationId=' : 'rc/refresh-rc-verification-details?dealId=';
    return new Promise((resolve, reject) => {
      api.put(`${path}${id}`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      })
    });
  };

  const SaveRcVerificationData = (params, isOthers=false) => {
    const path = isOthers ? 'rc/v3/save-rc-verification-details' : 'rc/save-rc-verification-details';
    return new Promise((resolve, reject) => {
      api.put(path, params).then(response => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      })
    });
  };

  async function getRcVerificationConfigs(isOthers=false) {
    const path = isOthers ? 'rc/v3/rc-tracker/rc-verification-configs' : 'rc/rc-tracker/rc-verification-configs';
    const response = await api.get(path);
    if (response && response.status === httpConstants.HTTP_STATUS_OK) {
      return response.data ? response.data : [];
    } else {
      return [];
    }
  };
  async function saveRcCorrectionReason(params, isOthers=false) {
    const path = isOthers ? 'rc/v3/rc-tracker/rc-correction-reason' : 'rc/rc-tracker/rc-correction-reason';
    const response = await api.put(path, params);
    if (response && response.status === httpConstants.HTTP_STATUS_OK) {
      return response.data;
    }
  };
  async function getRcCorrectionsById(rcId, isOthers=false) {
    const path = isOthers ? 'rc/v3/rc-tracker/rc-correction-reasons/' : 'rc/rc-tracker/rc-correction-reasons/';
    const response = await api.get(`${path}${rcId}`);
    if (response && response.status === httpConstants.HTTP_STATUS_OK) {
      return response.data ? response.data : [];
    } else {
      return [];
    }
  };
  async function getRcMgmtDelayReasons() {
    const response = await api.get(`rc/v2/rc-tracker/delay-reasons`);
    if (response && response.status === httpConstants.HTTP_STATUS_OK) {
      return response.data || [];
    }
    return [];
  };

  const getRcOthersServiceConfig = () => {
    const url = config.api.c2c.serviceTracker.getServiceConfig.path;
    return new Promise((resolve, reject) => {
      api.get(url).then(response => {
        resolve(response.data.data);
      }).catch(error => {
        reject(error);
      })
    });
  };

  const getRcOthersDealCancelReasons = () => {
    const url = config.api.c2c.serviceTracker.getCancelReasons.path;
    return new Promise((resolve, reject) => {
      api.get(url).then(response => {
        resolve(response.data.data);
      }).catch(error => {
        reject(error);
      })
    });
  };

  const getRcOthersDelayReasons = () => {
    const url = config.api.c2c.serviceTracker.getDelayReasons.path;
    return new Promise((resolve, reject) => {
      api.get(url).then(response => {
        resolve(response.data.data);
      }).catch(error => {
        reject(error);
      })
    });
  };

  const getRcOthersTrackerConfig = () => {
    const url = config.api.c2c.serviceTracker.getTrackerConfig.path;
    return new Promise((resolve, reject) => {
      api.get(url).then(response => {
        resolve(response.data.data);
      }).catch(error => {
        reject(error);
      })
    });
  };

  const fetchRcVerificationLogs = (params) => {
    const url = `${config.api.c2c.rcTracker.rcCorrectionLogs.logs.path}?${buildQuery(params)}`;
    return api
      .get(url)
      .then((resp) => {
        return resp.data.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  const fetchRcOthersVerificationLogs = (params) => {
    const url = `${config.api.c2c.rcTracker.rcOthersCorrectionLogs.logs.path}?${buildQuery(params)}`;
    return api
      .get(url)
      .then((resp) => {
        return resp.data.data;
      })
      .catch((error) => {
        throw error;
      });
    }
    
  const getRcTrackerConfig = () => {
    const url = config.api.c2c.rcTracker.rcTrackerConfig.path;
    return new Promise((resolve, reject) => {
      api.get(url).then(response => {
        resolve(response.data.data);
      }).catch(error => {
        reject(error);
      })
    });
  };

  const updateRcTrackerDelayReasonStatus = ({ payload }) => {
    const url = config.api.c2c.rcTracker.delayReason.path;
    return new Promise((resolve, reject) => {
      api.put(url, payload).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      })
    });
  };

  return {
    getRcMgmtDelayReasons,
    getRcVerificationConfigs,
    saveRcCorrectionReason,
    getRcCorrectionsById,
    SaveRcVerificationData,
    FetchRcVerificationData,
    FetchRefreshRcVerificationData,
    resolveDelayReason,
    fetchDelayReasons,
    deleteFile,
    fetchAgents,
    fetchAgentHistory,
    fetchRcOthersAgentHistory,
    fetchRcTrackerData,
    fetchRcOthersTrackerData,
    addRcTrackerData,
    updateRcTrackerData,
    fetchRcTrackerRemarks,
    saveRcTrackerRemarks,
    saveFilters,
    fetchFilter,
    getRcDocumentsById,
    addRcDocumnet,
    editRcDocument,
    getRcDocumentViewFile,
    uploadFile,
    fetchRcCasetypeRemarks,
    saveRcCasetypeRemarks,
    fetchRcManagementGridInfo,
    fetchOtherRcList,
    saveRcDocumentsRemarks,
    fetchRcDocumentRemarks,
    uploadFileRcTracker,
    viewFileRcTracker,
    deleteFileRcTracker,
    addAgent,
    editAgent,
    fetchAgentInfoById,
    fetchAgentGridInfo,
    addRcDetailsOthers,
    getOthersRcDocuments,
    uploadOtherRcDocument,
    getOthersRcDocumentViewFile,
    fetchOthersRcRemarks,
    saveOthersRcDocumentsRemarks,
    fetchOthersRcDelayReasons,
    fetchOthersRcAgents,
    updateOthersRcTrackerData,
    fetchOthersRcCasetypeRemarks,
    saveOthersRcCasetypeRemarks,
    updateOthersRcStatus,
    uploadFileRcOthersTracker,
    viewFileRcOthersTracker,
    deleteFileRcOthersTracker,
    fetchRcOthersTrackerRemarks,
    getRcOthersServiceConfig,
    getRcOthersDealCancelReasons,
    getRcOthersDelayReasons,
    getRcOthersTrackerConfig,
    fetchRcVerificationLogs,
    fetchRcOthersVerificationLogs,
    getRcTrackerConfig,
    updateRcTrackerDelayReasonStatus
  };
};
