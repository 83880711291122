import Types from './types';
import {
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected,
  handleHttpStatusError
} from '../../../services/helper';
import { rcManagementService } from '../../../services';
import parseRcOthersList from "../../helper/parseRcOthersList";

const setPageNumberConnected = (pageNo) => ({
  type: Types.SET_PAGE_NUMBER,
  payload: pageNo
});
const fetchRcManagementGridInfoConnected = (params) => (dispatch) => {
  dispatchSetLoaderConnected();
  return rcManagementService
    .fetchRcManagementGridInfo(params)
    .then((resp) => {
      dispatchResetLoaderConnected();
      if (resp.data && resp.data.data) {
        return resp.data.data;
      } else {
        return {
          page: 0,
          totalPages: 0,
          totalRecords: 0,
          response: []
        };
      }
    })
    .catch((error) => {
      handleHttpStatusError(error, true);
      throw error;
    });
};
const fetchOthersRcList = (params) => (dispatch) => {
  dispatchSetLoaderConnected();
  return rcManagementService
    .fetchOtherRcList(params)
    .then((resp) => {
      dispatchResetLoaderConnected();
      if (resp.data && resp.data.data) {
        return {
          ...resp.data.data,
          response: parseRcOthersList(resp.data.data.response)
        };
      } else {
        return {
          page: 0,
          totalPages: 0,
          totalRecords: 0,
          response: []
        };
      }
    })
    .catch((error) => {
      handleHttpStatusError(error, true);
      throw error;
    });
};

const addOthersRcDetails = (payload) => () => {
  dispatchSetLoaderConnected();
  return rcManagementService
    .addRcDetailsOthers(payload)
    .then((resp) => {
      dispatchResetLoaderConnected();
        return resp.data;
    })
    .catch((error) => {
      handleHttpStatusError(error, true);
      throw error;
    });
}
export { setPageNumberConnected, fetchRcManagementGridInfoConnected, fetchOthersRcList, addOthersRcDetails };
