import React, { useEffect, useState } from "react";
import { SelectComponent, SelectDropdown } from '..';
import InputText from "../../../../shared/components/input-text";
import styles from "./styles.module.css";
import { cityService} from '../../../../services';
import { deliveryModesDropdown, deliveryModeValues, timeSlots } from "../../../../admin-app/constants/constants";
import priceFormatter from "../../../utils/price-formatter";
import pincodeValidator from "../Validators/pincode-validator";
import { PINCODE_LENGTH } from "../Constants";
import { DataService } from '../../../service';
import { getTotalCharges, getAdditionalChargeAmount } from "../utility";

const getCharges = (shippingCharge, isRCTransferCharge) => {
    const defaultShippingCharge = {
        "name": "Shipping charges",
        "amount": shippingCharge,
        "key": "shipping_charge",
        "description": "Get your car delivered at selected location"
    };

    const defaultRcCharge =  {
        "name": "RC Transfer",
        "amount": isRCTransferCharge,
        "key": "rc_charge",
        "description": "CARS24 will facilitate the RC transfer of the car"
    };

    return [{...defaultShippingCharge},{...defaultRcCharge}];
};

const DeliveryDetails = ({
    states,
    form,
    buyerData,
    rules = {},
    pincodeData,
    setPincodeData,
    selectedPickupLocationIndex,
    setSelectedPickupLocationIndex,
    deliverySlots,
    setDeliverySlots,
    isFormDisabled
}) => {
    const { state, dispatch } = form;
    const {chargeDetails : addtitionalCharges, deliveryTime} = state || {};
    // const {date, slots} = deliveryTime || {};
    const [cities, setCities] = useState([]);
    const [isCityRestoredOnFirstLoad, setIsCityRestoredOnFirstLoad] = useState(false);
    const [showNotservicablePincodeError, setShowNotservicablePincodeError] = useState(false);
    const { stores, deliveryPlace: selectedDeliveryPlace, appointmentId, bookingId } = buyerData;
    let cityObj = {label:state.deliveryCity, value:""};
    const {homeDelivery, interState, pickup} = pincodeData || {};
    const homeDeliveryShippingAmount = !!pincodeData && !!homeDelivery && getTotalCharges(!!homeDelivery && homeDelivery.length > 0 ? homeDelivery[0].charges : []);
    let interStateRCTransferAmount = !!pincodeData && !!interState && getTotalCharges(!!interState ? interState.charges : []);
    let pickupChargesAmount = state.deliveryMode === deliveryModeValues.HUB_PICKUP && !!pincodeData && !!pickup && pickup.length > 0 && getTotalCharges(!!pickup[selectedPickupLocationIndex] ? pickup[selectedPickupLocationIndex].charges : []);
    let shippingCharge = state.deliveryMode === deliveryModeValues.HUB_PICKUP ? pickupChargesAmount : homeDeliveryShippingAmount;
    const addlShippingCharge = getAdditionalChargeAmount(addtitionalCharges, "shipping_charge");
    const addlRCCharge = getAdditionalChargeAmount(addtitionalCharges, "rc_charge");
    interStateRCTransferAmount = interStateRCTransferAmount > 0 ? interStateRCTransferAmount : addlRCCharge;
    shippingCharge = shippingCharge > 0 ? shippingCharge : addlShippingCharge;
    
    const [visitDateSlots, setVisitDateSlots] = useState([]);
    const [visitTimeSlots, setVisitTimeSlots] = useState([]);

    useEffect(()=> {
        if (!!deliveryTime && !!deliverySlots && deliverySlots.length > 0) {
            const dateSlots = deliverySlots.map((item) => ({ label: item.date, value: item.date}));
            setVisitDateSlots(dateSlots);
            const {date, slots } = state.deliveryTime;
            if (!!date) {
                let selectedDateSlots = deliverySlots.find((item) => item.date === date);
                !!selectedDateSlots && dispatch({
                    type: "visitDate",
                    visitDate: date
                });

                const timeSlots =  !!selectedDateSlots && !!selectedDateSlots.slots && selectedDateSlots.slots.length > 0 && selectedDateSlots.slots.map((item) => ({ label: item.display, value: item.display}));
                if (state.deliveryMode === deliveryModeValues.HOME_DELIVERY) {
                    setVisitTimeSlots(timeSlots);
                    if (!!slots[0]) {
                        dispatch({
                            type: "visitTime",
                            visitTime: slots[0].display
                        });
                    }
                }
            } 
        }
        if (!deliveryTime && !!deliverySlots && deliverySlots.length > 0) {
            const dateSlots = deliverySlots.map((item) => ({ label: item.date, value: item.date}));
            setVisitDateSlots(dateSlots);
        }
    }, [deliveryTime, deliverySlots]);

    const onChangeState = (stateId) => {
        cityService.fetchCities(stateId).then((resp) => {
            const citiesArr = ((resp.data || []).data || []).map(({ cityName }) => ({ label: cityName, value: cityName }));
            setCities(citiesArr);
        });
        setCities([]);

        const stateName = (states || []).filter(({ value }) => value === stateId )[0] || {};
        
        dispatch({
            type: "deliveryState",
            deliveryState: stateName.label
        });
    }
    const onChangeVisitDate = (e) => {
        const date = e.target.value;
        if (state.deliveryMode === deliveryModeValues.HOME_DELIVERY) {
            const selectedDateSlots = deliverySlots.find((item) => item.date === date);
            const timeSlots =  !!selectedDateSlots && !!selectedDateSlots.slots && selectedDateSlots.slots.map((item) => ({ label: item.display, value: item.display}));
            setVisitTimeSlots(timeSlots);
        }
        dispatch({
            type: "deliveryTime",
            deliveryTime: {
                "date": date,
                "slots": [{}]
            }
        });
        dispatch({
            type: "visitDate",
            visitDate: date
        });
    };

    const onChangeVisitTime = (e) => {
        const time = e.target.value;
        const selectedDateData = deliverySlots.find((item) => item.date === state.visitDate);
        const selectedVisitTimedata = !!selectedDateData && !!selectedDateData.slots && selectedDateData.slots.find((item) => item.display === time);
        dispatch({
            type: "deliveryTime",
            deliveryTime: {
                "date": state.visitDate,
                "slots": [selectedVisitTimedata]
            }
        });
        dispatch({
            type: "visitTime",
            visitTime: time
        });
    };

    useEffect(() => {
        if(!!state.deliveryState && !isCityRestoredOnFirstLoad) {
            const stateId = (states || []).filter(({ label }) => label === state.deliveryState )[0] || {};
            if(stateId.value) {
                cityService.fetchCities(stateId.value).then((resp) => {
                    const citiesArr = ((resp.data || []).data || []).map(({ cityName, cityId }) => ({ label: cityName, value: cityId }));
                    cityObj = !!state.deliveryCity && citiesArr.filter(item => item.label === state.deliveryCity)[0];
                    setCities(citiesArr);
                });
                setIsCityRestoredOnFirstLoad(true);
            }
        }
    }, [state.deliveryState]);

    const onChangeCity = (deliveryCity) => {
        dispatch({
            type: "deliveryCity",
            deliveryCity
        });
    };

    const onChangeDeliveryMode = (value) => {
        if (value === deliveryModeValues.HOME_DELIVERY && state.deliveryMode === deliveryModeValues.HUB_PICKUP) {
            dispatch(
            {
                  type: 'deliveryAddress1',
                  deliveryAddress1: ""
            });

            dispatch(
            {
                type: 'deliveryAddress2',
                deliveryAddress2: ""
            });
        } else {
            const {deliveryAddress1, deliveryAddress2, deliveryMode : prevDeliveryMode } = buyerData;
            if (value === prevDeliveryMode) {
                dispatch(
                    {
                            type: 'deliveryAddress1',
                            deliveryAddress1
                    });
        
                    dispatch(
                    {
                        type: 'deliveryAddress2',
                        deliveryAddress2
                    });
            }
        }

        dispatch({
            type: "deliveryMode",
            deliveryMode : value
        });

        if (value === deliveryModeValues.HOME_DELIVERY && state.visitDate) {
            const selectedDateSlots = deliverySlots.find((item) => item.date === state.visitDate);
            const timeSlots =  !!selectedDateSlots && !!selectedDateSlots.slots && selectedDateSlots.slots.map((item) => ({ label: item.display, value: item.display}));
            setVisitTimeSlots(timeSlots);
        }
    }

    const onChangeDeliveryAddress = (e) => {
        dispatch({
            type: e.target.name,
            [e.target.name]: e.target.value
        });
    }

    const onChangeDeliveryPincode = (e) => {
        setShowNotservicablePincodeError(false);
        dispatch({
            type: e.target.name,
            [e.target.name]: e.target.value
        });
    }

    const onLostFocusPincode = (e) => {
        if (state.deliveryPincode.length === PINCODE_LENGTH) {
            DataService.fetchPincodeData(appointmentId, state.deliveryPincode)
                .then(resp => {
                    const {data : {data, message}} = resp;
                    setPincodeData(data);
                    const {homeDelivery} = data;
                    if (state.deliveryMode === deliveryModeValues.HOME_DELIVERY && ((!!homeDelivery && homeDelivery.length < 1) || (homeDelivery === null || typeof homeDelivery === "undefined"))) {
                            setShowNotservicablePincodeError(true);
                        }
                }).catch(err => {
                    setShowNotservicablePincodeError(true);
                    const {message} = err;
                });
            //Delivery Slots
            const interCity = shippingCharge > 0 ? true : false;
            DataService.fetchBookingDeliverySlots(bookingId, state.deliveryPincode, interCity)
                .then((dsResp)=>{
                    setDeliverySlots(dsResp);
                })
                .catch((err)=> {
                    setDeliverySlots();
                });
        }
    };
    const setUpdateCharges = (index) => {
        let charges = null;
        if (state.deliveryMode === deliveryModeValues.HOME_DELIVERY) {
            charges = getCharges(Number(homeDeliveryShippingAmount), Number(interStateRCTransferAmount));
        } else {
            if (!!pickup && pickup.length > 0 && index !== -1) {
                pickupChargesAmount = getTotalCharges(pickup[index].charges);
                charges = getCharges(Number(pickupChargesAmount), Number(interStateRCTransferAmount));
            //     //set pickup location details
                dispatch({
                    type: "deliveryPlace",
                    deliveryPlace: pickup[index].locationId
                });

                dispatch({
                    type: "pickupTime",
                    pickupTime: pickup[index].availabilityDate
                });

                dispatch(
                {
                      type: 'deliveryAddress1',
                      deliveryAddress1 : pickup[index].location
                });

                dispatch(
                {
                    type: 'deliveryAddress2',
                    deliveryAddress2 : pickup[index].address
                });
            }
        }
        
        if (charges) {
            dispatch(
                {
                    type: 'chargeDetails',
                    chargeDetails : charges
                });
        }
    };

    const onPickupLocationChange = (index) => {
        setSelectedPickupLocationIndex(index);
    };

    const deliveryMode = deliveryModesDropdown.filter(({ value }) => value === state.deliveryMode)[0];
    const deliveryState = states.filter(({ value, label }) => value === state.deliveryState || label === state.deliveryState)[0];
    
    useEffect(()=> {
        selectedPickupLocationIndex > -1 && setUpdateCharges(selectedPickupLocationIndex);
    }, [state.deliveryPincode, selectedPickupLocationIndex, state.deliveryMode]);
    
    return (
      <React.Fragment>
        <h2 className={styles.title}>Delivery Details</h2>
        {showNotservicablePincodeError && state.deliveryMode === deliveryModeValues.HOME_DELIVERY && <p className={styles.error}>{`We are currently not delivering to ${state.deliveryPincode}. Choose to pick-up your vehicle from the nearest CARS24 hub`}</p>}
        <div className='row'>
            <div className='col-2 form-group disp-box d-flex align-items-center'>
                <label for='url' className='lbl'>
                Delivery Mode :
                </label>
            </div>
            <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.selectWrapper}`}>
                <SelectComponent
                    name={JSON.stringify(deliveryMode)}
                    hideSelectedOptions={false}
                    isClearable={false}
                    optionsList={deliveryModesDropdown}
                    onChange={onChangeDeliveryMode}
                    defaultValue={deliveryMode}
                    hasError={(rules["deliveryMode"] || {}).required && !form.state.deliveryMode}
                    errorMessage={(rules["deliveryMode"] || {}).required && !form.state.deliveryMode ? "Required" : undefined}
                    isDisabled={isFormDisabled}
                />
            </div>
            <div className='col-2 form-group disp-box d-flex align-items-center'>
                <label for='url' className='lbl'>
                    Pin Code :
                </label>
            </div>
            <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
                <InputText
                    type='number'
                    name={"deliveryPincode"}
                    onChange={onChangeDeliveryPincode}
                    value={state.deliveryPincode}
                    maxLength={PINCODE_LENGTH}
                    onBlurCallback={onLostFocusPincode}
                    validator={pincodeValidator}
                    errorMessage="Invalid Pin Code"
                    disabled={isFormDisabled}
                />
            </div>
        </div>
        {state.deliveryMode === deliveryModeValues.HOME_DELIVERY && <React.Fragment>
            <div className="row">
                <div className="col-2 form-group disp-box d-flex align-items-center">
                    <label for='place' className='lbl'>
                        Delivery Address -1 :
                    </label>
                </div>
                <div className={`col-10 form-group disp-box align-items-center ${styles.textareWrapper}`}>
                    <textarea 
                        name={"deliveryAddress1"} 
                        placeholder="Flat no, House Number, Building, Company apartment" 
                        onChange={onChangeDeliveryAddress} 
                        value={state.deliveryAddress1}
                        disabled={isFormDisabled ? isFormDisabled : showNotservicablePincodeError}
                        className={`${(rules["deliveryAddress1"] || {}).required && !form.state.deliveryAddress1 ? "textAreaError" : ""}`}
                    />
                    { ((rules["deliveryAddress1"] || {}).required && !form.state.deliveryAddress1) ? <small class="textareaErrorMessage">Required</small> : <React.Fragment /> }
                </div>
            </div>
            <div className="row">
                <div className="col-2 form-group disp-box d-flex align-items-center">
                    <label for='place' className='lbl'>
                        Delivery Address - 2 :
                    </label>
                </div>
                <div className={`col-10 form-group disp-box align-items-center ${styles.textareWrapper}`}>
                    <textarea  
                        name={"deliveryAddress2"} 
                        placeholder="Area, Colony, Street, Sector, Village"  
                        onChange={onChangeDeliveryAddress} 
                        value={state.deliveryAddress2}
                        disabled={isFormDisabled ? isFormDisabled : showNotservicablePincodeError}
                        className={`${(rules["deliveryAddress2"] || {}).required && !form.state.deliveryAddress2 ? "textAreaError" : ""}`}
                    />
                    { ((rules["deliveryAddress2"] || {}).required && !form.state.deliveryAddress2) ? <small class="textareaErrorMessage">Required</small> : <React.Fragment /> }
                </div>
            </div>
            <div className='row'>
                <div className='col-2 form-group disp-box d-flex  align-items-center'>
                    <label for='deliveryState' className='lbl'>
                        State :
                    </label>
                </div>
                <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.selectWrapper}`}>
                    <SelectComponent
                        key={deliveryState ? "deliveryState" : ""}
                        name='deliveryState'
                        hideSelectedOptions={false}
                        isClearable={false}
                        optionsList={states}
                        onChange={onChangeState}
                        isDisabled={isFormDisabled}
                        value={state.deliveryState && { value: state.deliveryState, label: state.deliveryState }}
                        defaultValue={deliveryState}
                        hasError={(rules["deliveryState"] || {}).required && !form.state.deliveryState}
                        errorMessage={(rules["deliveryState"] || {}).required && !form.state.deliveryState ? "Required" : undefined}
                    />
                </div>
                <div className='col-2 form-group disp-box d-flex align-items-center'>
                    <label for='url' className='lbl'>
                        City :
                    </label>
                </div>
                <div className={`col-4 form-group disp-box align-items-center ${styles.selectWrapper}`}>
                    <SelectComponent
                        name='deliveryCity'
                        key={
                            `sub_${state.deliveryCity || "deliveryCity"}`
                        }
                        hideSelectedOptions={false}
                        isClearable={false}
                        optionsList={cities}
                        onChange={onChangeCity}
                        isDisabled={isFormDisabled}
                        defaultValue={!!cityObj ? cityObj : ""}
                        hasError={(rules["deliveryCity"] || {}).required && !form.state.deliveryCity}
                        errorMessage={(rules["deliveryCity"] || {}).required && !form.state.deliveryCity ? "Required" : undefined}
                    />
                </div>
            </div>
        </React.Fragment> }
        <div className='row'>
            {<div className='col-2 form-group disp-box d-flex  align-items-center'>
                <label for='visitDate' className='lbl'>
                    Visit Date : 
                </label>
            </div>}
            <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.selectWrapper}`}>
                <SelectDropdown
                    name={'visitDate'}
                    optionsList={visitDateSlots}
                    onChange={onChangeVisitDate}
                    value={state.visitDate}
                    isDisabled={isFormDisabled}
                    className={(rules["visitDate"] || {}).required && !state.visitDate ? "form-control required" : "form-control"}
                    required={(rules["visitDate"] || {}).required && !state.visitDate}
                />
            </div>
            {state.deliveryMode === deliveryModeValues.HOME_DELIVERY && <div className='col-2 form-group disp-box d-flex  align-items-center'>
                <label for='visitTime' className='lbl'>
                    Visit Time : 
                </label>
            </div>}
            {state.deliveryMode === deliveryModeValues.HOME_DELIVERY && <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.selectWrapper}`}>
                <SelectDropdown
                    name={'visitTime'}
                    optionsList={visitTimeSlots}
                    onChange={onChangeVisitTime}
                    value={state.visitTime}
                    className={(rules["visitTime"] || {}).required && !state.visitTime ? "form-control required" : "form-control"}
                    required={(rules["visitTime"] || {}).required && !state.visitTime}
                    isDisabled={isFormDisabled}
                />
            </div>}
        </div>
        {state.deliveryMode === deliveryModeValues.HUB_PICKUP && <div className='row'>
            <div className='col-2 form-group disp-box d-flex align-items-center'>
                <label for='url' className='lbl'>
                    Delivery Place :
                </label>
            </div>
            <div className={styles.deliveryDetails}>
                <ul>
                    {
                        !!pickup && pickup.map((item, index) => {
                            return (
                                <li key={index} onClick={()=> onPickupLocationChange(index)} className={selectedPickupLocationIndex === index && !isFormDisabled ? styles.active : ''}>
                                    <div className={styles.location}>
                                        <p>{item.location}</p>
                                        <p className={styles.address}>{item.address}</p>
                                    </div>
                                    <p className={styles.pickup}>Earliest pickup : <span>{item.availability}</span></p>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        </div>}
        {<div className='row'>
            <div className='col-2 form-group disp-box d-flex align-items-center'>
                <label for='shippingCharge' className='lbl'>
                    Shipping Charge :
                </label>
            </div>
            <div className={`col-4 form-group disp-box d-flex align-items-center inputWrapperSign ${styles.inputWrapper}`}>
                <InputText
                    type='text'
                    name={"shippingCharge"}
                    value={priceFormatter(shippingCharge)}
                    disabled={true}
                />
            </div>
            <div className='col-2 form-group disp-box d-flex align-items-center'>
                <label for='rcTransferCharge' className='lbl'>
                    RC Transfer Charge : 
                </label>
            </div>
            <div className={`col-4 form-group disp-box d-flex align-items-center inputWrapperSign ${styles.inputWrapper}`}>
                <InputText
                    type='text'
                    name={"rcTransferCharge"}
                    value={priceFormatter(interStateRCTransferAmount)}
                    disabled={true}
                />
            </div>
        </div>}
        </React.Fragment>
    );
};

export default DeliveryDetails;
