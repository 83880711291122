import { useEffect, useState } from "react";
import { rcManagementService } from "../../../services"

export const useRcTracker = () => {
  const { getRcTrackerConfig } = rcManagementService;

  const [config, setConfig] = useState({
    delayReasonStatus: []
  });

  const fetchConfig = () => {
    getRcTrackerConfig()
    .then(({ delayReasonStatus }) => {
      setConfig({ ...config, delayReasonStatus });
    })
  };

  useEffect(() => {
    fetchConfig();
  }, [])

  return { config };
}