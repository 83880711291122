import React from 'react';
import RcTrackerDetail from '../../components/rc-management-detail';
import withAdminLayout from '../../../shared/hoc/admin-layout';
import ErrorBoundary from '../../components/common/errorBoundary';
import { withAuthorization } from '../../../shared/utils/firebase';

const RcTrackerDetailPage = () => {
  return (
    <div>
      <ErrorBoundary>
        <RcTrackerDetail />
      </ErrorBoundary>
    </div>
  );
};
export default withAdminLayout(
  withAuthorization(() => true)(RcTrackerDetailPage)
);
