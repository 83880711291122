export const FIELDS = {
  payment_mode_id: {
    label: 'Payment Mode',
    keyName: 'payment_mode_id',
    elementType: 'select',
    subType: 'select',
    maxLength: 255,
    required: false
  },
  bank_name: {
    label: 'Bank Name',
    keyName: 'bank_name',
    elementType: 'input',
    subType: 'text',
    maxLength: 255,
    required: false
  },
  utr: {
    label: 'UTR',
    keyName: 'utr',
    elementType: 'input',
    subType: 'text',
    maxLength: 255,
    required: false
  },

  amount: {
    label: 'Amount',
    keyName: 'amount',
    elementType: 'input',
    subType: 'number',
    maxLength: 255,
    required: false
  },
  payment_date: {
    label: 'Payment Date',
    keyName: 'payment_date',
    elementType: 'date',
    subType: 'text',
    maxLength: 255,
    required: false
  },
  reason: {
    label: 'Discard reason',
    keyName: 'reason',
    elementType: 'readable',
    subType: 'text'
  },
  remarks: {
    label: 'Discard remarks',
    keyName: 'remarks',
    elementType: 'readable',
    subType: 'text'
  }
};

export const getInitValues = () => {
  let initObj = {};
  Object.keys(FIELDS).forEach((item) => {
    initObj[item] = '';
  });
  return initObj;
};
