import React, { useState, useEffect } from 'react';
import { dealerService } from '../../../services';
import columnDefs from '../../config/arDealer-columns-definitions';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import GridView from '../common/grid-view';
import styles from './styles.css';

const ArDealerView = function ({ history, dealerId, match }) {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [recordsOnPage, setRecordsOnPage] = useState(0);
  const [totalRecords, SetTotalRecords] = useState(0);
  const [rowData, setRowData] = useState();
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    // In case of add dealer, dealerId is not present
    match.params.id &&
      dealerService
        .getArDealer(match.params.id)
        .then((response) => {
          if (response && response.length > 0) {
            response.forEach((item) => {
              item.status = item.status ? 'Active' : 'Inactive';
            });
          }
          setRowData(response);
          setPage(response.page);
          setTotalPages(response.totalPages);
          SetTotalRecords(response.totalRecords);
          setRecordsOnPage(response.response ? response.response.length : 0);
        })
        .catch((err) => {});
  }, []);
  const onRouteId = (data) => {
    let route = `/ardealer-add/${data.dealerId}`;
    history.push(`${route}/${data.arId}`);
  };
  const handlePageChange = (pageNumber, params) => {
    const qs = params ? params : queryParams;
    let query = { ...qs };
    query.page = pageNumber - 1;
    query.size = 10;
    dealerService
      .getArDealer(match.params.id, query)
      .then((response) => {
        setPage(pageNumber);
        setRowData(response.data.response);
        //setPage(response.page)
        setTotalPages(response.totalPages);
        SetTotalRecords(response.totalRecords);
        setRecordsOnPage(response.response ? response.response.length : 0);
      })
      .catch((err) => {});
  };

  const onFilterChanged = (params = {}) => {
    setQueryParams(params);
    handlePageChange(1, params);
  };
  const redirect = () => {
    let route = '/ardealer-add';
    history.push(`${route}/${dealerId}`);
  };
  return (
    <>
      <div className='col-md-12'>
        <h3>
          AR Representative
          <button
            className='btn btn-success pull-right'
            type='button'
            onClick={redirect}
          >
            Add
          </button>
        </h3>
        <div className='ag-theme-balham ag-grid-outer-wrapper'>
          <GridView
            columnDefs={columnDefs}
            rowData={rowData && rowData}
            onRedirect={onRouteId}
            uniqueIdName={'arId'}
            // onFilterChanged={onFilterChanged}
          />
          <div className='paginationWrapper'>
            {/* <Pagination
                  pageRangeDisplayed={1}
                  prevPageText={<button
                      className="btn-sm btn-default ml8"

                  >
                      Previous
                  </button>}
                  nextPageText={<button
                      className="btn-sm btn-default ml8"

                  >
                      Next
                  </button>}
                  firstPageText={<button
                      className="btn-sm btn-success ml8"
                  >
                      First
              </button>
                  }
                  lastPageText={<button
                      className="btn-sm btn-success ml8"
                  >
                      Last
                  </button>}
                  activePage={page}
                  itemsCountPerPage={10}
                  totalItemsCount={totalRecords}
                  onChange={handlePageChange}

              /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ArDealerView);
