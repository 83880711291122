import { createTypes } from "reduxsauce";

export default createTypes(
    `
    ACTION_UPDATE_INDEX
`,
    {
        prefix: "adminsidebar/"
    }
);
