import React, { useState, useEffect } from 'react';
import columnDefs from './columnConfig';
import { withRouter, Link } from 'react-router-dom';

import GridView from '../common/grid-view';
import CustomPagination from '../custom-pagination';
import { DEFAULT_PAGE_SIZE, dealStatusTypes } from '../../constants/constants';
import './style.css';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';

const AgentGrid = function(props) {
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [recordsOnPage, setRecordsOnPage] = useState(0);
  const [totalRecords, SetTotalRecords] = useState(0);
  const [rowData, setRowData] = useState();
  const [queryParams, setQueryParams] = useState({});
  const [resetFilter, setResetFilter] = useState(false);
  const [loaderDisabled, setLoaderDisabled] = useState(false);

  useEffect(() => {
    const query = {
      page: props.pageNumber || 0,
      size: DEFAULT_PAGE_SIZE
    };
    getData(query);
  }, []);

  const getData = (query) => {
    props
      .fetchAgentGridInfoConnected(query)
      .then((response) => {
        setRowData(response.response);
        setPage(response.page);
        props.setPageNumberConnected(response.page);
        setTotalPages(response.totalPages);
        SetTotalRecords(response.totalRecords);
        setRecordsOnPage(response.response ? response.response.length : 0);
      })
      .catch((err) => {});
  };
  const onRouteId = (data) => {
    const route = `/agent/${data.id}`;
    props.history.push(route);
  };

  const handlePageChange = (pageNumber, params) => {
    setLoaderDisabled(true);
    const qs = params ? params : queryParams;
    let query = { ...qs };
    query.page = pageNumber;
    query.size = DEFAULT_PAGE_SIZE;
    getData(query);
  };

  const onFilterChanged = (params = {}) => {
    setQueryParams(params);
    handlePageChange(0, params);
  };

  return (
    <LoaderWithAccessControl disabled={loaderDisabled}>
      {rowData && (
        <>
          <div className='row'>
            <div className='col-md-12 text-right'>
              <Link to='/agent/add'>
                <button className='btn btn-success' type='button'>
                  Add
                </button>
              </Link>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-1 pull-right' />
            <br />
          </div>
          <GridView
            columnDefs={columnDefs}
            rowData={rowData && rowData}
            onRedirect={onRouteId}
            uniqueIdName={'id'}
            onFilterChanged={onFilterChanged}
            columnsToFit={true}
            resetFilter={resetFilter}
            uniqueGridId={'agents'}
          />
          <CustomPagination
            page={page}
            totalRecords={totalRecords}
            handlePageChange={handlePageChange}
            recordsOnPage={recordsOnPage}
            totalPages={totalPages}
          />
        </>
      )}
    </LoaderWithAccessControl>
  );
};

export default withRouter(AgentGrid);
