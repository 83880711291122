
import React, { useState, useEffect, useCallback } from "react";
import InputText from "../../../../shared/components/input-text";
import styles from "./styles.module.css";
import Button from "../../../../shared/components/button";
import { SelectComponent } from '..';
import { assistBookingPaymentModesDropdown, GenderDropDown, paymentModesValues, houseTypeDropdown, employmentTypeDropdown, incomeProofStatusDropdown } from "../../../../admin-app/constants/constants";
import DatePicker from "react-datepicker";
import panValidator from "../Validators/pan-validator";
import pincodeValidator from "../Validators/pincode-validator";
import { PINCODE_LENGTH, PANCARD_LENGTH, CF_SUCCESS_MESSAGE, EIGHTEEN, EMPLOYMENT_TYPE, SIMPLER_SOURCE } from "../Constants";
import { DataService } from '../../../service';
import { getProcesingFeeValueFromList, getUpdatedProcessingFee, calculateProcessingFee, getTotalChargesAmount, getLoanAmountWithCharges, getDownpaymentRange, calculateAge } from "../utility";
import emiCalculator from "./emi-calculator";
import EmiSlider from "../emi-slider";
import { cityService} from '../../../../services';
import debounce from "./debounce";
const NonCFOfferType = [
  'SYSTEM',
  'CUSTOMISE',
  'CUSTOMISED'
];

const PaymentDetails = ({ 
  form,
  buyerData,
  orderData,
  setOrderData,
  isBuyerCFEligible,
  setIsBuyerCFEligible,
  consumerFinanceData,
  isFinanceCustomization,
  setIsFinanceCustomization,
  isFormDisabled
  }) => {
    const { state, dispatch } = form;
    const {bookingId, deliveryPincode, listingPrice, name, phone} = buyerData;
    const {downPayment, tenure, interestRate} = state.financeTerms || {};
    const {userId} = orderData || {};
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [selectedDateOfBirth, setSelectedDateOfBirth] = useState(null);
    const [panCard, setPanCard] = useState(null);
    const [cfUserData, setCfUserData] = useState(null);
    const [cfError, setCfError] = useState("");
    const [bankValidateStatus, setBankValidateStatus] = useState("");
    const [currentPinCode, setCurrentPincode] = useState(deliveryPincode);
    const [currentGender, setCurrentGender] = useState({});
    const [invalidPincode, setInvalidPincode] = useState(false);
    // const [invalidPancard, setInvalidPancard] = useState(false);
    const [panCardErrorMessage, setPanCardErrorMessage] = useState(null);
    const paymentMode = assistBookingPaymentModesDropdown.filter(({ value }) => value === state.paymentMode)[0];
    const {chargesList, creditScoreList, loanTenureList} = !!consumerFinanceData ? consumerFinanceData : {};
    const selectedTenure = !!loanTenureList && !!tenure && loanTenureList.filter(({value}) => value === tenure.toString())[0];
    const processingFeeValue = getProcesingFeeValueFromList(chargesList);
    const {minDownPayment, maxDownPayment} = !!creditScoreList && !!interestRate && getDownpaymentRange(creditScoreList, interestRate, listingPrice);
    const [houseType, setHouseType] = useState({});
    const [houseNo, setHouseNo] = useState("");
    const [locality, setLocality] = useState("");
    const [curState, setCurState] = useState("");
    const [curCity, setCurCity] = useState("");
    const [cities, setCities] = useState([]);
    const [employmentType, setEmploymentType] = useState({});
    const [monthlyIncome, setMonthlyIncome] = useState();
    const [incomeProof, setIncomeProof] = useState({});
    const [totalExistingEMI, setTotalExistingEMI] = useState();
    const [companyName, setCompanyName] = useState();
    const showCompanyName = employmentType === EMPLOYMENT_TYPE.SALARIED;
    
    const delayedQuery = useCallback(
      debounce((val) =>
        DataService.fetchCityStateByPinCode(val)
          .then((resp) => {
            const {cityName, stateName} = resp;
            if (!!cityName && !!stateName) {
              setInvalidPincode(false);
              setCurCity(cityName);
              setCurState(stateName);
            } else {
              setInvalidPincode(true);
            }
          }).catch((err) => {
            console.log("API error, ", err);
            setInvalidPincode(true);
          })
        , 3000),
      []
    );

    useEffect(()=> {
      !!deliveryPincode && setCurrentPincode(deliveryPincode);
    },[deliveryPincode]);

    useEffect(()=> {
      delayedQuery(currentPinCode);
    },[currentPinCode]);

    useEffect(() => {
      const selUserId = userId || state.ppUserId;
      !!selUserId && DataService.fetchCFFormDetails(selUserId).then((resp)=>{
        const {data} = resp || {};
        setCfUserData(data);
        const {dateOfBirth, gender,pancard, addressDetails = {},
        inComeDetails = {}} = data || {};
        const {houseNo, houseType, street} = addressDetails; 
        const {companyName, emiAmount, employmentType, incomeProof, monthlyIncome} = inComeDetails;
        if (dateOfBirth) {
          const d = new Date(dateOfBirth);
          const date = d.getDate();
          const month = d.getMonth() + 1;
          const currentDate = d.getFullYear() + '-' + (month < 10 ? `0${month}` : month) + '-' + (date < 10 ? `0${date}` : date);
          setSelectedDateOfBirth(d);
          setDateOfBirth(currentDate);
        }
        
        !!pancard && setPanCard(pancard);
        !!gender && setCurrentGender({label: gender, value: gender});
        !!houseNo && setHouseNo(houseNo);
        !!houseType && setHouseType({label: houseType, value: houseType});
        !!street && setLocality(street);
        !!monthlyIncome && setMonthlyIncome(monthlyIncome);
        !!emiAmount && setTotalExistingEMI(emiAmount);
        !!incomeProof && setIncomeProof({label: incomeProof, value: incomeProof});
        !!companyName && setCompanyName(companyName);
        !!employmentType && setEmploymentType({label: employmentType, value: employmentType});
      });
    }, [userId, state.ppUserId]);
    const onChangePaymentMode = (paymentMode) => {
      let financeMode = false;
      const {pincodeServiceable, financeTerms} = orderData;
      // Testing purpose
      // if (paymentMode === paymentModesValues.CARS24_FINANCE) {
      //   if ((!!financeTerms && !NonCFOfferType.includes(financeTerms.offerType)) || !financeTerms) {
      //     console.log("CF form required");
      //   } else {
      //     console.log("CF form not required");
      //   }
      // }
      if (paymentMode === paymentModesValues.CARS24_FINANCE && 
        ((!pincodeServiceable) || (!financeTerms)|| (!!financeTerms && !NonCFOfferType.includes(financeTerms.offerType)))) {
        setIsFinanceCustomization(true);
        setIsBuyerCFEligible(false);
      }

      dispatch({
        type: "paymentMode",
        paymentMode
      });

      if (paymentMode === paymentModesValues.CARS24_FINANCE) {
        financeMode = true;
      }
      dispatch({
        type: 'financeOpted',
        financeOpted: financeMode
      });
    };

    const onChangeHouseType = (e) => {
      setHouseType({label: e, value: e});
    };

    const onChangeDate = (d) => {
      if (!!d && d instanceof Date && !isNaN(d.valueOf())) {
        const date = d.getDate();
        const month = d.getMonth() + 1;
        const year = d.getFullYear();
        if (year <= (new Date()).getFullYear()) {
          const currentDate = d.getFullYear() + '-' + (month < 10 ? `0${month}` : month) + '-' + (date < 10 ? `0${date}` : date);
          setSelectedDateOfBirth(d);
          setDateOfBirth(currentDate);
        } else {
          setSelectedDateOfBirth();
          setDateOfBirth("");
        }
      } 
    }

    const onClickCheckEligility = () => {
      setCfError("");
      const age = calculateAge(selectedDateOfBirth);
      if (age < EIGHTEEN) {
        setCfError("Age is less than 18 years old")
        return;
      }
      if (!state.name) {
        setCfError("Customer name can't be empty.")
        return;
      } else if (!dateOfBirth) {
        setCfError("Customer date of birth can't be empty.")
        return;
      } else if (!!dateOfBirth && dateOfBirth.length !== 10) {
        setCfError("Customer date of birth is invalid.")
        return;
      }
      else if (!!dateOfBirth && !!panCard && !!currentGender && currentGender.value && !!houseNo && !!houseType && houseType.value && !!curCity 
        && !!curState && !!employmentType && employmentType.value && !!monthlyIncome && !!incomeProof && incomeProof.value && !!totalExistingEMI 
        && (employmentType === EMPLOYMENT_TYPE.SALARIED ? !!companyName : true)) {
        const payLoad = {
          "vehiclePrice": listingPrice,
          "name": state.name,
          "mobileNumber": phone,
          "gender": currentGender.value,
          "dateOfBirth": dateOfBirth,
          "pancard": panCard,
          "source": SIMPLER_SOURCE.LMS,
          "pinCode": currentPinCode,
          "addressDetails": {
            "pincode": currentPinCode,
            "state": curState,
            "city": curCity,
            "houseType": houseType.value,
            "houseNo": houseNo,
            "street": locality
          },
          "inComeDetails": {
            "employmentType": employmentType.value,
            "companyName": companyName,
            "monthlyIncome": monthlyIncome,
            "incomeProof": incomeProof.value,
            "emiAmount": totalExistingEMI
          },
          "submittedBy": localStorage.getItem('email'),
          "submitted": true,
        };
        DataService.submitCustomerEligibility(bookingId, payLoad)
          .then(resp => {
            const {data, message} = resp;
            if (typeof data === 'string') {
              setCfError(data);
            } else {
              const {financeOpted, financeTerms, chargeDetails, pincodeServiceable} = data || {};
              if (!pincodeServiceable) {
                setCfError("Sorry! We are not currently providing finance in this location. Please proceed with Pay on Delivery instead");
              } else {
                dispatch(
                  {
                    type: 'financeOpted',
                    financeOpted
                  }
                )
                dispatch(
                  {
                    type: 'financeTerms',
                    financeTerms
                  }
                )
                dispatch(
                  {
                    type: 'chargeDetails',
                    chargeDetails
                  }
                )
                if (message.toUpperCase() === CF_SUCCESS_MESSAGE.toUpperCase()) {
                  setIsBuyerCFEligible(true);
                } else {
                  setIsBuyerCFEligible(false);
                }
                setOrderData(data);
              }
            }
          }).catch(err => {
            const {message} = err;
            setCfError(message);
            setIsFinanceCustomization(true);
          });
      } else {
        setCfError("Please enter correct buyer details")
        setIsFinanceCustomization(true);
      }
    };

    const onChangePanCard = (e) => {
      setPanCardErrorMessage();
      // setInvalidPancard(false);
      const value = e.target.value.toUpperCase();
      const isValid = panValidator(value);
      setPanCard(value);
    };

    const onChangePinCode = (e) => {
      const value = e.target.value;
      setCurrentPincode(value);
      if (value && value.length === 6) {
        delayedQuery(value);
      }
    };

    const onChangeGender = (gender) => {
      setCurrentGender({label: gender, value: gender});
      // console.log("gender:", gender);
    };

    const onChangeHouseNo = (e) => {
      const value = e.target.value;
      setHouseNo(value);
    };

    const onChangeLocality = (e) => {
      const value = e.target.value;
      setLocality(value);
    };

    const onChangeEmploymentType = (type) => {
      setEmploymentType({label: type, value: type});
    };

    const onChangeMonthlyIncome = (e) => {
      const value = e.target.value;
      setMonthlyIncome(value);
    };

    const onChangeIncomeProof = (e) => {
      setIncomeProof({label: e, value: e});
    };

    const onChangeToatlExistingEMI = (e) => {
      const value = e.target.value;
      setTotalExistingEMI(value);
    };

    const onChangeCompanyName = (e) => {
      const value = e.target.value;
      setCompanyName(value);
    };

    const onLoanTenureChange = (item) => {
      const curTotalCharge = getTotalChargesAmount(state.financeTerms.chargeDetails);
      const curLoanAmount = getLoanAmountWithCharges(curTotalCharge, listingPrice, downPayment);
      const emiValue = emiCalculator(curLoanAmount, interestRate, item);
      const updateFinanceTerms = {...state.financeTerms, ["tenure"]: item, ["emiAmount"]: Math.ceil(emiValue)
        , ["offerType"]: "CUSTOMISED", ["loanAmount"]: curLoanAmount};
      dispatch(
        {
          type: 'financeTerms',
          financeTerms : updateFinanceTerms
        }
      )
    };

    const onSliderValueChanged = (val) => {
      const value = val[0];
      const curProcessingFee = calculateProcessingFee(listingPrice, value, processingFeeValue);
      const curChargeList = getUpdatedProcessingFee(curProcessingFee, state.financeTerms.chargeDetails);
      const curTotalCharge = getTotalChargesAmount(curChargeList);
      const curLoanAmount = getLoanAmountWithCharges(curTotalCharge, listingPrice, value);
      let emiValue = emiCalculator(curLoanAmount, state.financeTerms.interestRate, state.financeTerms.tenure);
      const updateFinanceTerms = {...state.financeTerms, ["downPayment"]: value,
        ["emiAmount"]: Math.ceil(emiValue), ["chargeDetails"]: curChargeList , ["offerType"]: "CUSTOMISED", ["loanAmount"]: curLoanAmount};
      dispatch(
        {
          type: 'financeTerms',
          financeTerms : updateFinanceTerms
        }
      )
    };
    const onValidateBankStatement = () => {
      if (!!userId) {
        let bsStatus = "Pending";
        DataService.fetchBankStatementStatus(userId)
          .then((resp)=>{
            const {uploaded} = resp || {};
            if (uploaded) {
              bsStatus= "Completed";
            }
            dispatch(
              {
                type: 'bankStatementStatus',
                bankStatementStatus : bsStatus
              
            })
          });
      }
    };
    const onShareBankStatementLink = () => {
      dispatch(
        {
          type: 'bankStatementShared',
          bankStatementShared : true
        }
      )
      if (!!userId) {
        DataService.postShareBankStatementLink(userId)
        .then((resp)=>{
          console.log("postShareBankStatementLink:", resp);
        })
        .catch((err)=>{
          console.log("postShareBankStatementLink err:", err);
        });
      }
    };

    // const onPanCardLostFocus = (e) => {
    //   let {value } = e.target;
    //   !!value && DataService.validatePancard(value)
    //     .then((resp)=>{
    //       const {data:{status}} = resp;
    //       if (status === "Valid") {
    //         setInvalidPancard(false);
    //         setPanCardErrorMessage("");
    //       } else {
    //         setInvalidPancard(true);
    //         setPanCardErrorMessage("Enter valid PAN number");
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("error: ", err);
    //       setInvalidPancard(true);
    //       setPanCardErrorMessage("Enter valid PAN number");
    //     });
    // };
    // console.log("state.financeTerms: ", state.financeTerms);
    return (
      <React.Fragment>
        <div className='row'>
          <h2 className={styles.title}>Payment Details</h2>
        </div>
        <div className='row'>
          <div className='col-2 form-group disp-box d-flex  align-items-center'>
            <label for='availability' className='lbl'>
              Payment Mode :
            </label>
          </div>
          {<div className={`col-4 form-group disp-box d-flex align-items-center ${styles.selectWrapper}`}>
              <SelectComponent
                  key={JSON.stringify(paymentMode)}
                  name={JSON.stringify(paymentMode)}
                  hideSelectedOptions={false}
                  isClearable={false}
                  optionsList={assistBookingPaymentModesDropdown}
                  onChange={onChangePaymentMode}
                  defaultValue={paymentMode}
                  isDisabled={isFormDisabled}
              />
          </div>}
        </div>
        
        {state.paymentMode === paymentModesValues.CARS24_FINANCE && isFinanceCustomization && !isBuyerCFEligible &&
          <>
            <div className='row'>
              <div className='col-2 form-group disp-box d-flex  align-items-center'>
                <label for='dateOfBirth' className='lbl'>
                  Date of Birth :
                </label>
              </div>
              <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.selectWrapper}`}>
                <DatePicker
                  calendarClassName="datepicker-container"
                  selected={selectedDateOfBirth}
                  dateFormat="dd-MM-yyyy"
                  onChange={onChangeDate}
                  maxDate={new Date()}
                  showYearDropdown
                  showMonthDropdown
                  disabled={isFormDisabled}
                  disabledKeyboardNavigation
                />
              </div>
              <div className='col-2 form-group disp-box d-flex  align-items-center'>
                <label for='panCardNo' className='lbl'>
                  Pan Card No :
                </label>
              </div>
              <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
                <InputText
                  id="panCardNo"
                  type='text'
                  className='form-control'
                  name={'panCardNo'}
                  value={panCard}
                  validator={panValidator}
                  onChange={onChangePanCard}
                  maxLength={PANCARD_LENGTH}
                  errorMessage={panCardErrorMessage || "Invalid Pancard"}
                  disabled={isFormDisabled}
                  // isError={invalidPancard}
                  // onBlurCallback={onPanCardLostFocus}
                  // version={"v2"}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-2 form-group disp-box d-flex  align-items-center'>
                <label for='gender' className='lbl'>
                  Gender :
                </label>
              </div>
              <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.selectWrapper}`}>
                {!!currentGender && <SelectComponent
                  key={`key_gender_payment`}
                  name={`key_gender_payment`}
                  hideSelectedOptions={false}
                  isClearable={false}
                  optionsList={GenderDropDown}
                  onChange={onChangeGender}
                  defaultValue={currentGender}
                  isDisabled={isFormDisabled}
                />}
              </div>
              <div className='col-2 form-group disp-box d-flex  align-items-center'>
                <label for='currentPinCode' className='lbl'>
                  Pin Code (current) :
                </label>
              </div>
              <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
                <InputText
                    type='number'
                    name={'currentPinCode'}
                    onChange = {onChangePinCode}
                    value={currentPinCode}
                    isError={invalidPincode}
                    maxLength={PINCODE_LENGTH}
                    validator={pincodeValidator}
                    errorMessage="Invalid Pin Code"
                    version={"v2"}
                    disabled={isFormDisabled}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-2 form-group disp-box d-flex  align-items-center'>
                <label for='availability' className='lbl'>
                  House Type :
                </label>
              </div>
              <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.selectWrapper}`}>
                  {!!houseType && <SelectComponent
                      key={'key_housetype_payment'}
                      name={'key_housetype_payment'}
                      hideSelectedOptions={false}
                      isClearable={false}
                      optionsList={houseTypeDropdown}
                      onChange={onChangeHouseType}
                      defaultValue={houseType}
                  />}
              </div>
            </div>
            <div className='row'>
              <div className='col-2 form-group disp-box d-flex  align-items-center'>
                <label for='availability' className='lbl'>
                  House/Flat No. :
                </label>
              </div>
              <div className={`col-10 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
                <InputText id="houseNo"
                  type='text'
                  className='form-control'
                  name={'houseNo'}
                  value={houseNo}
                  onChange={onChangeHouseNo}
                  />
              </div>
            </div>
            <div className='row'>
              <div className='col-2 form-group disp-box d-flex  align-items-center'>
                <label for='availability' className='lbl'>
                  Street/Locality :
                </label>
              </div>
              <div className={`col-10 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
                <InputText id="locality"
                  type='text'
                  className='form-control'
                  name={'locality'}
                  value={locality}
                  onChange={onChangeLocality}/>
              </div>
            </div>
            <div className='row'>
              <div className='col-2 form-group disp-box d-flex  align-items-center'>
                  <label for='key_curCity_payment' className='lbl'>
                    City :
                  </label>
              </div>
                <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
                  <InputText
                    key={'key_curCity_payment'}
                    name={'key_curCity_payment'}
                    className='form-control'
                    disabled={true}
                    value={curCity}
                />
              </div>
              <div className='col-2 form-group disp-box d-flex  align-items-center'>
                <label for='payment-state' className='lbl'>
                  State :
                </label>
              </div>
              <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
                <InputText id="payment-state"
                  type='text'
                  className='form-control'
                  name={'payment-state'}
                  disabled={true}
                  value={curState}/>
              </div>
            </div>
            <div className='row'>
              <div className='col-2 form-group disp-box d-flex  align-items-center'>
                <label for='availability' className='lbl'>
                Employment Type :
                </label>
              </div>
              <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.selectWrapper}`}>
                {!!employmentType && <SelectComponent
                    key={'key_employmentType_payment'}
                    name={'key_employmentType_payment'}
                    hideSelectedOptions={false}
                    isClearable={false}
                    optionsList={employmentTypeDropdown}
                    onChange={onChangeEmploymentType}
                    defaultValue={employmentType}
                />}
              </div>
              <div className='col-2 form-group disp-box d-flex  align-items-center'>
                <label for='monthlyIncome' className='lbl'>
                  Monthly Income :
                </label>
              </div>
              <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
                <InputText id="monthlyIncome"
                  type='number'
                  className='form-control'
                  name={'monthlyIncome'}
                  value={monthlyIncome}
                  onChange={onChangeMonthlyIncome}/>
              </div>
            </div>
            {showCompanyName && <div className='row'>
              <div className='col-2 form-group disp-box d-flex  align-items-center'>
                <label for='companyName' className='lbl'>
                  Company Name :
                </label>
              </div>
              <div className={`col-10 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
                <InputText id="companyName"
                  type='text'
                  className='form-control'
                  name={'companyName'}
                  value={companyName}
                  onChange={onChangeCompanyName}/>
              </div>
            </div>}
            <div className='row'>
              <div className='col-2 form-group disp-box d-flex  align-items-center'>
                <label for='availability' className='lbl'>
                Income Proof :
                </label>
              </div>
              <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.selectWrapper}`}>
                {!!incomeProof && <SelectComponent
                    key={'key_incomeProof_payment'}
                    name={'key_incomeProof_payment'}
                    hideSelectedOptions={false}
                    isClearable={false}
                    optionsList={incomeProofStatusDropdown}
                    onChange={onChangeIncomeProof}
                    defaultValue={incomeProof}
                />}
              </div>
              <div className='col-2 form-group disp-box d-flex  align-items-center'>
                <label for='availability' className='lbl'>
                Total Existing EMI :
                </label>
              </div>
              <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
                <InputText id="totalExistingEMI"
                  type='number'
                  className='form-control'
                  name={'totalExistingEMI'}
                  value={totalExistingEMI}
                  onChange={onChangeToatlExistingEMI}/>
              </div>
            </div>
          </>}
        {state.paymentMode === paymentModesValues.CARS24_FINANCE && !!state.financeTerms && <div className='row'>
            <div className='col-2 form-group disp-box d-flex  align-items-center'>
              <label for='bankStateValidateStatus' className='lbl'>
                Bank Statement Validate :
              </label>
            </div>
            <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
              <InputText id="bankStateValidateStatus"
                type='text'
                className='form-control'
                name={'bankStateValidateStatus'}
                value={state.bankStatementStatus}
                disabled={true}
                />
            </div>
            {state.bankStatementStatus !== "Completed" && <div className={`col-6 form-group disp-box d-flex  align-items-center ${styles.inputWrapper}`}>
              <Button label={"Validate Bank Statement"} onClick={() => onValidateBankStatement()} />
              <Button label={"Share Bank Statement Link"} onClick={() => onShareBankStatementLink()} />
            </div>}
          </div>
        }
        {state.paymentMode === paymentModesValues.CARS24_FINANCE && isFinanceCustomization && !isBuyerCFEligible && 
          <>
            <div className='row'>
              <div className={`col-12 form-group disp-box d-flex  align-items-center justify-content-start ${styles.inputWrapper}`}>
                <Button label={"Check Eligibility"} onClick={() => onClickCheckEligility()} />
                {!!cfError && cfError !== "" && <span className="errortext" >{cfError}</span>}
              </div>
            </div>
          </>
        }
        {isBuyerCFEligible && !!state.financeTerms && state.paymentMode === paymentModesValues.CARS24_FINANCE && 
          <>
            <div className='row'>
              <div className='col-2 form-group disp-box d-flex  align-items-center'>
                <label for='loanTenure' className='lbl'>
                  Loan Tenure :
                </label>
              </div>
              <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.selectWrapper}`}>
                <SelectComponent
                  key={JSON.stringify(selectedTenure)}
                  name={JSON.stringify(selectedTenure)}
                  hideSelectedOptions={false}
                  isClearable={false}
                  optionsList={loanTenureList}
                  selected={selectedTenure}
                  onChange={onLoanTenureChange}
                  defaultValue={selectedTenure}
                  isDisabled={isFormDisabled}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-2 form-group disp-box d-flex  align-items-center'>
                <label for='downPayment' className='lbl'>
                  Downpayment : 
                </label>
              </div>
              {!!maxDownPayment && <div className={`col-4 form-group disp-box d-flex  align-items-center ${styles.emiSlider}`}>
                <EmiSlider 
                  domain={[0, maxDownPayment]}  
                  priceValue={state.financeTerms.downPayment} 
                  onSliderValueChanged={onSliderValueChanged}
                  disabled={isFormDisabled}
                />
              </div>}
            </div>
            <div className='row'>
              <div className='col-2 form-group disp-box d-flex  align-items-center'>
                <label for='monthlyEMI' className='lbl'>
                  Monthly EMI :
                </label>
              </div>
              <div className={`col-4 form-group disp-box d-flex align-items-center inputWrapperSign ${styles.inputWrapper}`}>
              {!!state.financeTerms && <InputText
                  id="monthlyEMI"
                  type='number'
                  name={'emiAmount'}
                  value={state.financeTerms.emiAmount}
                  disabled={true}
                />}
              </div>
            </div>
          </>
        }
      </React.Fragment>
    );
  };

export default PaymentDetails;
