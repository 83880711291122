import React from 'react';
import C2cStoreView from '../../components/c2c-store-view';
import withAdminLayout from '../../../shared/hoc/admin-layout';
import ErrorBoundary from '../../components/common/errorBoundary';
import { withAuthorization } from '../../../shared/utils/firebase';
const C2cStores = (props) => {
  return (
    <div>
      <ErrorBoundary>
        <C2cStoreView {...props} />
      </ErrorBoundary>
    </div>
  );
};
export default withAdminLayout(withAuthorization(() => true)(C2cStores));
