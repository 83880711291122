import Types from "../constants/actionTypes";
// import { languageApi } from "./../service/api";
import { languageService } from "../../services";

const fetchLanguagesInit = () => ({
  type: Types.ACTION_LANGAUGE_INIT
});

const fetchLanguagesSuccess = data => ({
  type: Types.ACTION_LANGAUGE_SUCCESS,
  data
});

const fetchLanguagesFail = error => ({
  type: Types.ACTION_LANGAUGE_FAIL,
  error
});

const fetchLanguageConnected = () => dispatch => {
  dispatch(fetchLanguagesInit());
  return new Promise((resolve, reject) => {
    languageService
      .fetchLanguageInfo()
      .then(resp => {
        resolve(resp);
        dispatch(fetchLanguagesSuccess(resp));
      })
      .catch(error => {
        dispatch(fetchLanguagesFail(error));
        reject(error);
      });
  });
};

export { fetchLanguageConnected };
