import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setRcDealInfo } from '../rc-tracker-deal-info/actions';
import DeChecklist from './component';
// import { RcManagementAction } from '../../actions';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setRcDealInfoConnect : (data) => setRcDealInfo(data)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DeChecklist);
