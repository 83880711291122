import {
  rcStates,
  dealStatus,
  nbfcStatus,
  RC_OTHERS_TRACKER_STATUS
} from '../../../constants/constants';

export default () => ([
  {
    colId: 'uid',
    rc_type: 'rc',
    headerName: 'UID',
    width: 120,
    resizable: false,
    field: 'uid',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    cellRenderer: 'LinkRenderer',
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    colId: 'loan_id',
    rc_type: 'rco',
    headerName: 'Loan Id',
    width: 140,
    field: 'loan_id',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    suppressSizeToFit: false,
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    colId: 'requester_email_id',
    rc_type: 'rc',
    headerName: 'Requester Email ID',

    field: 'requester_email_id',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    colId: 'appointment_id',
    rc_type: 'rc',
    headerName: 'Appt. ID',
    width: 110,
    field: 'appointment_id',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    suppressSizeToFit: false,
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    colId: 'booking_id',
    rc_type: 'rco',
    headerName: 'Booking ID',
    width: 130,
    field: 'booking_id',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    suppressSizeToFit: false,
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    colId: 'segment',
    rc_type: 'rco',
    headerName: 'Segment',
    width: 140,
    field: 'segment',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    colId: 'registration_number',
    rc_type: 'rc',
    headerName: 'Registration Number',
    width: 140,
    valueFormatter: 'value.toUpperCase()',
    field: 'registration_number',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },

    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    colId: 'deal_status',
    rc_type: 'rc',
    headerName: 'Deal Status',
    width: 120,
    field: 'deal_status',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'deal_status',
      options: Object.values(dealStatus)
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: Object.values(dealStatus)
    },
    suppressMenu: true
  },
  {
    colId: 'file_status',
    rc_type: 'rc',
    headerName: 'File State',
    width: 160,
    field: 'file_status',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'file_status',
      options: Object.values(rcStates)
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: Object.values(rcStates)
    },
    suppressMenu: true
  },
  {
    colId: 'service_status_display_value',
    rc_type: 'rc',
    headerName: 'Rc Tracker Status',
    width: 230,
    field: 'service_status_display_value',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'MultiSelectFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'rc_tracker_status',
      options: RC_OTHERS_TRACKER_STATUS
    },
    //cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: RC_OTHERS_TRACKER_STATUS
    },
    suppressMenu: true
  },
  {
    colId: 'correction_type',
    rc_type: 'rco',
    headerName: 'Correction Type',
    width: 140,
    field: 'correction_type',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    colId: 'last_communicated_at',
    rc_type: 'rc',
    headerName: 'Last Updated at',
    field: 'last_communicated_at',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    colId: 'buyer_email_id',
    rc_type: 'rc',
    headerName: 'Buyer Email',

    field: 'buyer_email_id',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    colId: 'buyer_number',
    rc_type: 'rc',
    headerName: 'Buyer Number',
    width: 125,
    field: 'buyer_number',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    colId: 'owner_email_id',
    rc_type: 'rc',
    headerName: 'Seller Email',

    field: 'owner_email_id',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    suppressSizeToFit: false,
    resizable: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    colId: 'owner_number',
    rc_type: 'rc',
    headerName: 'Seller Number',
    field: 'owner_number',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true },

  },
  {
    colId: 'buyer_name',
    rc_type: 'rc',
    headerName: 'Buyer Name',
    field: 'buyer_name',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    suppressSizeToFit: false,
    resizable: false,
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    colId: 'owner_name',
    rc_type: 'rc',
    headerName: 'Seller Name',
    field: 'owner_name',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    suppressSizeToFit: false,
    resizable: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    colId: 'nbfc_answer',
    rc_type: 'rc',
    headerName: 'NBFC',
    width: 140,
    field: 'nbfc_answer',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'nbfc_answer',
      options: Object.values(nbfcStatus)
    },
    //cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: Object.values(nbfcStatus)
    },
    suppressMenu: true
  }
]);
