import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { showToastMessages } from './helper';
dayjs.extend(utc);

export const getLocalDatetime = (date, showInvalidErrorMessage = true) => {
  /* returns current local date with time set to current time */
  try {
    if (!dayjs(date).isValid() && showInvalidErrorMessage) {
      throw new Error('Invalid Date');
    }

    const ct = new Date();
    date.setHours(ct.getHours());
    date.setMinutes(ct.getMinutes());
    date.setSeconds(ct.getSeconds());

    const localDate = dayjs(date)
      .local()
      .format();
    const formattedDate = localDate.split('+')[0];
    return formattedDate;
  } catch (e) {
    showInvalidErrorMessage && showToastMessages('Invalid Date', false);
    return null;
  }
};

export const getformattedDate = (
  input,
  type = 'date',
  format = 'YYYY-MM-DD'
) => {
  let output = '';
  let isInputNumber = isNaN(input)
  if (dayjs(input).isValid()) {
    switch (type) {
      case 'datetime':
        if (isNaN(input)){
          output = dayjs(input).format('DD/MM/YYYY HH:mm');
        }
        else{
          output = dayjs.unix(input).format('DD/MM/YYYY HH:mm');
        }
        break;

      case 'custom':
        if (isNaN(input)){
          output = dayjs(input).format(format);
        }
        else
        {
          output = dayjs.unix(input).format(format);
        }
        break;

      default:
        if (isNaN(input)){
          output = dayjs(input).format('DD/MM/YYYY');
        }
        else{
          output = dayjs.unix(input).format('DD/MM/YYYY');
        }
        break;
    }
  } else {
    //showToastMessages('Invalid Date', false);
    output = '';
  }
  return output;
};
