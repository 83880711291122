import Types from './types';

const resetAccessLoaderConnected = () => ({
  type: Types.RESET_LOADER
});

const setAccessLoaderConnected = (
  loaderState = { isLoading: true, isForbidden: false }
) => {
  return {
    type: Types.SET_LOADER,
    payload: loaderState
  };
};

const setForbiddenConnected = (
  loaderState = { isLoading: false, isForbidden: true }
) => {
  return {
    type: Types.SET_LOADER,
    payload: loaderState
  };
};

const setErrorConnected = (error = { message: 'Something went wrong.' }) => {
  return {
    type: Types.SET_LOADER_ERROR,
    payload: error
  };
};

export {
  resetAccessLoaderConnected,
  setAccessLoaderConnected,
  setForbiddenConnected,
  setErrorConnected
};
