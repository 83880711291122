import React, { useState, useEffect } from 'react';
import columnDefs from './columnConfig';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import GridView from '../common/grid-view';
import CustomPagination from '../custom-pagination';
import { DEFAULT_PAGE_SIZE } from '../../constants/constants';
import './style.css';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';
import { ROLES, checkForUserRoles } from '../../../shared/utils/constants';

const HpManagementGrid = (props) => {
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [recordsOnPage, setRecordsOnPage] = useState(0);
  const [totalRecords, SetTotalRecords] = useState(0);
  const [rowData, setRowData] = useState();
  const [filterParams, setFilterParams] = useState({});
  const [loaderDisabled, setLoaderDisabled] = useState(false);

  useEffect(() => {
    const query = {
      page: props.pageNumber || 0,
      size: DEFAULT_PAGE_SIZE
    };
    getData(query);
  }, [filterParams]);

  const getData = (query) => {
    props
      .fetchHpManagementGridInfoConnected(query, filterParams)
      .then((response) => {
        setRowData(response.content);
        setPage(response.number);
        props.setPageNumberConnected(response.number);
        setTotalPages(response.totalPages);
        SetTotalRecords(response.totalElements);
        setRecordsOnPage(response.content ? response.content.length : 0);
      })
      .catch((err) => {
        
      });
  };
  const onRouteId = (data) => {
    const route = `/hp-management/${data.id}`;
    props.history.push(route);
  };

  const handlePageChange = (pageNumber) => {
    setLoaderDisabled(true);
    getData({page: pageNumber, size: DEFAULT_PAGE_SIZE});
  };

  const onFilterChanged = (params = {}) => {
    let filters = {};
    !!params && Object.keys(params).map((key)=>{
      filters = {...filters, [key]: params[key][0]}
    });
    setLoaderDisabled(true);
    setFilterParams(filters);
    
    // handlePageChange(0);
  };

  return (
    <LoaderWithAccessControl disabled={loaderDisabled}>
      {rowData && (
        <>
          {!!checkForUserRoles([ROLES.HYPOTHECATION_NBFC_OPS]) && <div className='row'>
          {/* {<div className='row'> */}
            <div className='col-md-12 text-right'>
              <Link to='/hp-management/add'>
                <button className='btn btn-success' type='button'>
                  Create Order
                </button>
              </Link>
            </div>
          </div>}
          <GridView
            columnDefs={columnDefs}
            rowData={rowData && rowData}
            onRedirect={onRouteId}
            uniqueIdName={'deal_id'}
            onFilterChanged={onFilterChanged}
            columnsToFit={true}
            // resetFilter={resetFilter}
            uniqueGridId={'rcManagement'}
          />
          <CustomPagination
            page={page}
            totalRecords={totalRecords}
            handlePageChange={handlePageChange}
            recordsOnPage={recordsOnPage}
            totalPages={totalPages}
          />
        </>
      )}
    </LoaderWithAccessControl>
  );
};

export default withRouter(HpManagementGrid);
