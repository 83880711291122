import Types from './types';

const setRcDealInfo = data => {
  return {
    type: Types.SET_RC_DEAL_INFO,
    data
  };
};

export { setRcDealInfo };
