import { config } from '../config/local';

export default (api) => {
  const fetchStates = () => {
    const url = config.api.c2c.rtoDelayReasons.fetchStates.path;
    return api
      .get(url)
      .then((resp) => {
        return resp.data.data;
      })
      .catch((error) => {
        throw error;
      });
  };
  
  const fetchRtosByStateCode = (params) => {
    const url = `${config.api.c2c.rtoDelayReasons.fetchRtos.path}/${params.stateCode}`;
    return api
      .get(url)
      .then((resp) => {
        return resp.data.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  const fetchRcDelayStages = () => {
    const url = config.api.c2c.rtoDelayReasons.fetchRcDelayStages.path;
    return api
      .get(url)
      .then((resp) => {
        return resp.data.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  const uploadDelayProof = (body) => {
    return new Promise((resolve, reject) => {
      const url = config.api.c2c.rtoDelayReasons.uploadDelayProof.path;
      api.post(url, body)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          })
    });
  };

  const saveRtoDelays = (body) => {
    return new Promise((resolve, reject) => {
      const url = config.api.c2c.rtoDelayReasons.saveRtoDelays.path;
      api.post(url, body)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          })
    });
  };

  const fetchRtoDelayLogs = ({ page, size }) => {
    const url = `${config.api.c2c.rtoDelayReasons.fetchRtoDelayLogs.path}?page=${page}&size=${size}`;
    return api
      .get(url)
      .then((resp) => {
        return resp.data.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  const updateRtoDelay = (body) => {
    return new Promise((resolve, reject) => {
      const url = config.api.c2c.rtoDelayReasons.updateRtoDelay.path;
      api.put(url, body)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          })
    });
  };

  return {
    fetchStates,
    fetchRtosByStateCode,
    fetchRcDelayStages,
    uploadDelayProof,
    saveRtoDelays,
    fetchRtoDelayLogs,
    updateRtoDelay,
  };
};