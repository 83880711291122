import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    FETCH_FINANCE_DEALINFO_INIT
    FETCH_FINANCE_DEALINFO_SUCCESS
    FETCH_FINANCE_DEALINFO_FAIL
`,
  {
    prefix: 'financedealinfo/'
  }
);
