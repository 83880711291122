import React, { useState, useEffect } from 'react';
import { QUESTIONS } from '../constants';
import { YES } from '../../../../../shared/utils/constants';

const RcOthersCasetypeQuestions = ({
  questionnaire,
  onChangeAnswer,
  possibleAnswersType
}) => {
  const [isBuyerLoan, setBuyerLoan] = useState(false);

  const isChecked = (status, value) => {
    return status === value;
  };

  useEffect(() => {
    /* To activate - Name of the bank for Loan? */
    questionnaire &&
      questionnaire.forEach((data) => {
        data.question_text === 'Is Buyer taking loan on the vehicle?' &&
          handleBankLoan(data, data.actual_answer);
      });
  }, [questionnaire]);

  const handleBankLoan = (data, value) => {
    setBuyerLoan(value === YES);
  };

  const isDisabled = (data) => {
    if (data.question_text === QUESTIONS.BANK_NAME) {
      return !isBuyerLoan;
    } else {
      return false;
    }
  };

  return (
    <>
      {questionnaire &&
        questionnaire.map((data, index) => {
          return (
            <React.Fragment key={index}>
              <div className='col-3 mt10'>
                <p className='fs12'>{data.question_text}</p>
              </div>
              {data.answer_button_type === 'radioButton' ? (
                <div className='col-8 d-flex'>
                  {possibleAnswersType[data.possible_answer_type]
                    ? possibleAnswersType[data.possible_answer_type].map(
                        (type, i) => {
                          return (
                            <div className='col-3 mt10' key={i}>
                              <label className='d-flex align-items-center fs12'>
                                <input
                                  type='radio'
                                  name={index}
                                  value={type || ''}
                                  checked={isChecked(data.actual_answer, type)}
                                  onChange={(e) => {
                                    data.question_text ===
                                      'Is Buyer taking loan on the vehicle?' &&
                                      handleBankLoan(data, e.target.value);
                                    onChangeAnswer(e, index);
                                  }}
                                />
                                &nbsp;&nbsp;
                                {type}
                              </label>
                            </div>
                          );
                        }
                      )
                    : ''}
                </div>
              ) : (
                <div className='col-8 d-flex'>
                  <div className='col-2 d-flex align-items-baseline mt10'>
                    <input
                      type='text'
                      name={index}
                      value={data.actual_answer || ''}
                      disabled={isDisabled(data)}
                      onChange={(e) => onChangeAnswer(e, index)}
                      autoComplete='off'
                    />
                  </div>
                </div>
              )}
              <div className='col-12' />
            </React.Fragment>
          );
        })}
    </>
  );
};

export default RcOthersCasetypeQuestions;
