import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DocumentsConfigList from "./component";
import { fetchDocumentConfig, updateDocumentData, updateDocumentConfig, resetConfigList, deleteDocumentConfig } from "../documents-config/actions";

const mapStateToProps = ({
  documentsConfig: {
    attributes,
    isAttributesFetched,
    regionConfigs,
    regionConfigs: {
      configsUpdated
    }
  }
}) => ({
  attributes,
  isAttributesFetched,
  regionConfigs,
  configsUpdated
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchDocumentConfigConnect: fetchDocumentConfig,
  updateDocumentConfigConnect: updateDocumentConfig,
  updateDocumentDataConnect: updateDocumentData,
  resetConfigListConnect: resetConfigList,
  deleteDocumentConfigConnect: deleteDocumentConfig,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsConfigList);
