import React from 'react';
import './styles.css';
import CONSTANTS from './constants';

const AuditQuestionaire = ({
  listingPrice,
  auditData,
  setAuditData,
  auditError
}) => {
  const onChange = e => {
    setAuditData({ ...auditData, [e.target.name]: e.target.value });
  };

  const getQuestion = (qItem, listingPrice) => {
    if (qItem.apiKey === 'listingPriceRevision') {
      return listingPrice || listingPrice === 0
        ? qItem.qtext.replace('<listing price>', listingPrice)
        : qItem.altQuestion;
    } else {
      return qItem.qtext;
    }
  };
  return (
    <div className='row mb20'>
      <div
        className={
          auditError ? 'audit-questions audit-border-error' : 'audit-questions'
        }
      >
        <div className='main_section_car block'>
          <h2 className='title'>Audit</h2>
          <ul className='fullGrid pl0'>
            {CONSTANTS.questions.map((qItem, qIndex) => {
              const question = getQuestion(qItem, listingPrice);
              return (
                <li key={`${qItem.apiKey}-${qIndex}`} className='row'>
                  <div className='col-8'>
                    {qIndex + 1}. {question}
                  </div>
                  <div className='col-4'>
                    {CONSTANTS.options.map((item, index) => {
                      return (
                        <label
                          key={`q-opt-${index}`}
                          className='radio_label ml30'
                        >
                          <input
                            type='radio'
                            className='radio-btn'
                            name={qItem.apiKey}
                            value={item.value}
                            onChange={onChange}
                          />
                          &nbsp;&nbsp;{item.label}
                        </label>
                      );
                    })}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AuditQuestionaire;
