import React, { useState, useEffect } from 'react';
import Accordion from '../../../../../shared/accordion';
import ArrowIcon from '../../../../../shared/arrow';
import { rcManagementService } from '../../../../../services';
import { SelectComponent } from '../../../../../client/components/shared';
import styles from './styles.module.css';
import { formatDate, showToastMessages } from '../../../../../shared/utils/helper';

const RcOthersDelayReasons = (props) => {
  const {
    trackerData,
    updateTrackerData,
    delayReasonsData,
    setDelayReasonsData
  } = props;
  const {deal_id} =trackerData;
  const [delayReasons, setDelayReasons] = useState([]);
  
  const [categories, setcategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [isResolvedClick, setIsResolvedClick] = useState(false);


  const fetchDelayReasons = () => {
    return rcManagementService.fetchOthersRcDelayReasons();
  }

  useEffect(() => {
    //master data
    fetchDelayReasons().then((response) => {
      setDelayReasons(response);
      const cats = Object.keys(response).map((item)=> { return {label: item, value: item}});
      setcategories(cats);
    });
  }, []);

  const onAddDelayReason = () => {
    const reason = {
      dealId: deal_id,
      resolved: false
    };
    const updateDelayReasonData = [...delayReasonsData, reason];
    setDelayReasonsData(updateDelayReasonData);
  };

  const onChangeCategory = (index, value) => {
    setSubCategories();
    const selectedItem = delayReasonsData[index];
    delayReasonsData[index] = {...selectedItem, category: value, subCategory:''};
    const subCats = !!delayReasons[value] && delayReasons[value].length > 0 && delayReasons[value].map((item)=>{
      return {label: item, value: item}
    });
    setTimeout(()=>{
      !!subCats && setSubCategories(subCats);
    }, 500);
    
  };

  const getSubcategories = (category) => {
    const subCats = !!delayReasons[category] && delayReasons[category].length > 0 && delayReasons[category].map((item)=>{
      return {label: item, value: item}
    });
    return subCats;
  };

  const onChangeSubCategory = (index, value) => {
    const selectedItem = delayReasonsData[index];
    delayReasonsData[index] = {...selectedItem, subCategory: value};
    updateTrackerData({
      ...trackerData,
      delayReasons: delayReasonsData
    });
  };

  const onResolvedReason = (index, id) => {
    setIsResolvedClick(true);
    rcManagementService.resolveDelayReason(id, true).then((resp)=>{
      const {data: {status, message}} = resp;
      if (status === 200) {
        const selectedItem = delayReasonsData[index];
        delayReasonsData[index] = {...selectedItem, resolved: true};
      }
      showToastMessages(message, status === 200);
    }).finally(()=> {
      setIsResolvedClick(false)
    });
  };

  return (
    <Accordion totalItems={1}>
      {({ onClick, currentVisibleStates }) => {
        return (
          <Accordion.Content>
            <Accordion.Heading>
              <h2
                className='d-flex fwb justify-content-between m0'
                onClick={() => onClick({ index: 0 })}
              >
                Delay Reasons
                <ArrowIcon
                  grey={'GreyArrow'}
                  rotateBy={currentVisibleStates[0] ? 180 : 90}
                />
              </h2>
            </Accordion.Heading>
            <Accordion.Body>
              <div
                className={
                  currentVisibleStates[0] ? 'accordionShow mt10 top-border' : 'accordionHide'
                }
              >
               <div className={`row ${styles.delayReasonsTable}`}>
                 <table cellPadding="0" cellSpacing="0" width="100%">
                   <thead>
                     <tr>
                       <th>Stage</th>
                       <th>Category</th>
                       <th>Sub Category</th>
                       <th>Created By</th>
                       <th>Created Date</th>
                       <th>Updated By</th>
                       <th>Updated Date</th>
                       <th>Resolved?</th>
                     </tr>
                   </thead>
                   <tbody>
                     {delayReasonsData.length > 0 && delayReasonsData.map((item, index)=> {
                      return (
                        <tr>
                          <td>{!!item.delayType ? item.delayType : ''}</td>
                          <td>
                            {!!item.delayReasonId ? <span>{item.category}</span> : 
                            <SelectComponent
                                placeholder="Select Category"
                                key={`delay_category_${index}_${item.delayReasonId}`}
                                name={`delay_category_${index}_${item.delayReasonId}`}
                                optionsList={categories}
                                defaultValue={!!item.category ? {label: item.category, value: item.category} : ''}
                                onChange={(value)=> onChangeCategory(index, value)}
                                isDisabled={!!item.category && !!item.delayReasonId}
                                isClearable={false}
                            />}
                          </td>
                          <td>
                            {!!item.delayReasonId ? <span>{item.subCategory}</span> :
                            <SelectComponent
                                placeholder="Select Subcategory"
                                key={`delay_subcategory_${index}_${item.delayReasonId}`}
                                name={`delay_subcategory_${index}_${item.delayReasonId}`}
                                optionsList={!!item.category && getSubcategories(item.category)}
                                isClearable={false}
                                value={item.subCategory}
                                onChange={(value)=> onChangeSubCategory(index, value)}
                            />}
                          </td>
                          <td>{!!item.createdBy ? item.createdBy : ''}</td>
                          <td>{!!item.createdAt ? formatDate(item.createdAt) : ''}</td>
                          <td>{!!item.updatedBy ? item.updatedBy : ''}</td>
                          <td>{!!item.updatedAt ? formatDate(item.updatedAt) : ''}</td>
                          <td>{!!item.resolved ? <span>Yes</span> : !!item.delayReasonId && <button className='btn btn-primary' onClick={()=> onResolvedReason(index, item.delayReasonId)} disabled={isResolvedClick}>RESOLVE</button>}</td>
                        </tr>
                      );
                     })
                    }
                   </tbody>
                 </table>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Content>
        );
      }}
    </Accordion>
  );
};

export default RcOthersDelayReasons;
