import React from "react";
import { UploadViewRenderer } from "../components/docket-management/cell-renderers/upload-view-renderer";
import MultiSelectRenderer from "../components/docket-management/cell-renderers/multiselect-renderer";
import RejectionReasonsRenderer from "../components/docket-management/cell-renderers/rejection-reasons-renderer";

export const DOCKET_MANAGEMENT_COLUMN_KEYS = {
  DOCUMENT_NAME: 'documentName',
  CATEGORY: 'category',
  DOC_IMAGE: 'fileName',
  ACTION: 'status',
  REMARKS: 'remarks',
}

export const columns = ({
  statusDropdownOptions,
  rejectionReasonsByDocument,
  handleMultiSelectChange,
  handleUploadFile,
  handleView,
}) => [
  {
    title: 'Document Name',
    field: DOCKET_MANAGEMENT_COLUMN_KEYS.DOCUMENT_NAME,
  },
  {
    title: 'Category',
    width: 200,
    field: DOCKET_MANAGEMENT_COLUMN_KEYS.CATEGORY,
  },
  {
    title: 'View/ Upload',
    field: DOCKET_MANAGEMENT_COLUMN_KEYS.DOC_IMAGE,
    cell: (props) => 
      <UploadViewRenderer 
        {...props}
        onUploadFile={handleUploadFile}
        onView={handleView}
      />
  },
  {
    title: 'Action',
    field: DOCKET_MANAGEMENT_COLUMN_KEYS.ACTION,
    cell: (props) =>
      <MultiSelectRenderer 
        {...props}
        handleMultiSelectChange={handleMultiSelectChange}
        options={statusDropdownOptions}
      />
  },
  {
    title: 'Remarks',
    field: DOCKET_MANAGEMENT_COLUMN_KEYS.REMARKS,
    cell: (props) =>
      <RejectionReasonsRenderer 
        {...props}
        rejectionReasonsByDocument={rejectionReasonsByDocument}
        handleMultiSelectChange={handleMultiSelectChange}
      />
  },
];
