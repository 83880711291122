import React, { useMemo } from 'react'
import { withRouter } from 'react-router-dom';
import { columns } from '../../../config/document-rr-configs-columns';
import GridView from '../../common/grid-view';
import { DOCUMENTS_GRID_ID } from '../../../constants/constants';
import { showToastMessages } from '../../../../shared/utils/helper';

const DocumentRejectionReasonsConfigAdd = ({
  documentsList,
  addConfigData,
  addDocumentRejectionReasonsConfigConnect,
  updateAddRejectionReasonsConfigDataConnect,
  fetchDocumentRejectionReasonsConfigConnect,
  match
}) => {
  const documentId = match.params.documentId;
  const documentName = documentsList.byId[documentId] ? documentsList.byId[documentId].documentName : "";
  let columnDefs = useMemo(() => columns(), []);
  columnDefs = columnDefs.slice(0, columnDefs.length - 1);

  const handleAddConfig = () => {
    let data = {
      documentId,
      ...addConfigData
    };
    addDocumentRejectionReasonsConfigConnect({}, data)
    .then((res) => {
      fetchDocumentRejectionReasonsConfigConnect({ documentId });
      showToastMessages(res.message);
    });
  }

  const handleInputChange = (attributeKey, value) => {
    updateAddRejectionReasonsConfigDataConnect({ key: attributeKey, value });
  }

  return (
    <>
      <h5>Add new Rule ({documentName})</h5>
      <GridView
        className={DOCUMENTS_GRID_ID.RR_CONFIG_ADD}
        uniqueGridId={DOCUMENTS_GRID_ID.RR_CONFIG_LIST}
        columnDefs={columnDefs}
        rowData={[addConfigData]}
        context={{ handleInputChange }}
        columnsToFit={true}
        floatingFilter={false}
        getRowHeight={() => 55}
      />
      <div className="text-right">
        <button
          className='btn btn-sm btn-success mt-neg-lg'
          onClick={handleAddConfig}
        >
          Add Rule
        </button>
      </div>
    </>
  )
}

export default withRouter(DocumentRejectionReasonsConfigAdd);