import {
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected,
  handleHttpStatusError
} from '../../../services/helper';
import { rtoApi } from '../../../services';

const fetchRtoGridInfoConnected = (params) => (dispatch) => {
  dispatchSetLoaderConnected();
  return rtoApi
    .fetchRtoGridInfo(params)
    .then((resp) => {
      dispatchResetLoaderConnected();
      if (resp.data && resp.data.data) {
        return resp.data.data;
      } else {
        return {
          page: 0,
          totalPages: 0,
          totalRecords: 0,
          response: []
        };
      }
    })
    .catch((error) => {
      handleHttpStatusError(error, true);
      throw error;
    });
};

export { fetchRtoGridInfoConnected };
