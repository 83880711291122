import React, { useEffect } from 'react';
import './style.css';
import locale from '../../utils/locale';
import { Spinner } from 'reactstrap';

const Component = ({
  disabled = false,
  isLoading,
  isForbidden,
  isError,
  message,
  resetAccessControlConnected,
  setLoaderConnected,
  children
}) => {
  useEffect(() => {
    return () => {
      resetAccessControlConnected();
    };
  }, []);

  return (
    <div>
      {disabled ? (
        children
      ) : (
        <>
          <div className='center'>
            {isForbidden && locale.NO_ACCESS}
            {isLoading && <Spinner animation='border' variant='primary' />}
            {isError && <h6>{message}</h6>}
          </div>
          {!isLoading && !isForbidden && !isError ? children : null}
        </>
      )}
    </div>
  );
};

export default Component;
