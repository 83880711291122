import React from 'react';
import MultiSelectRenderer from './multiselect-renderer';

const RejectionReasonsRenderer = ({
  dataItem,
  field,
  isMulti,
  rejectionReasonsByDocument,
  handleMultiSelectChange
}) => {
  const documentName = dataItem['documentName'];
  const options = rejectionReasonsByDocument[documentName] || [];

  return (
    <MultiSelectRenderer
      dataItem={dataItem}
      field={field}
      isMulti={isMulti}
      options={options}
      handleMultiSelectChange={handleMultiSelectChange}
    />
  );
};

export default RejectionReasonsRenderer;
