import React, { useState } from 'react';
import Slider from 'react-slick';
import { dimensions } from './constant';
//TODO :- "Sprint 9" MAke a constant file for this
const CarImagesSlider = ({ imageData, onChange, imageLabel, baseUrl }) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => {
      onChange(imageData && imageData[current]);
    },
  };
  return (
    <>
      <div className='slider'>
        <Slider {...settings}>
          {imageData &&
            imageData.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {imageLabel &&
                    imageLabel.map((label, i) => {
                      return (
                        <React.Fragment key={i}>
                          {item.image_type_id === label.id && (
                            <>
                              <img
                                src={`${baseUrl}/${item.url}`}
                                //FOR TESTING UNCOMMENT THIS
                                //src={item.url}
                                alt='no'
                              />
                              <p style={{ color: 'white' }}>{label.type}</p>
                            </>
                          )}
                        </React.Fragment>
                      );
                    })}
                </React.Fragment>
              );
            })}
        </Slider>
      </div>
    </>
  );
};

export default CarImagesSlider;
