import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  FETCH_DOCKET_DOCS_SUCCESS
  FETCH_DOCKET_FORM_DATA_SUCCESS
  RESET_DOCKET_DATA
  UPDATE_DOCKET_DIY_DOC_DATA
  UPDATE_DOCKET_ADMIN_DOC_DATA
  UPDATE_DOCKET_FIELD_DATA
`,
  {
    prefix: 'docketManagement/'
  }
);
