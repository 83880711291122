import React from "react";

export const ViewRenderer = ({
  dataItem,
  field,
  onView,
  label,
}) => {
  const handleView = () => {
    if (onView) {
      onView(dataItem, field);
    }
  };

  return (
    <td>
      {dataItem && dataItem[field] && (
        <button className="btn btn-sm btn-primary mt-1" onClick={handleView}>{label}</button>
      )}
    </td>
  )
}