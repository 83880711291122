import React, { useEffect, useState } from 'react';
import {
  CAR_DETAILS,
  BUYER_DETAILS,
  SELLER_DETAILS,
  BUYER_PAYMENT_DETAILS,
  SELLER_PAYMENT_DETAILS
} from './constants';
import { withRouter } from 'react-router-dom';
import { getSelectedOption, showToastMessages } from '../../../shared/utils/helper';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';
import Locale from '../../../shared/utils/locale';
import { getformattedDate } from '../../../shared/utils/datesHelper';
import globalStyles from '../../../shared/utils/global.module.css';
import {DataService} from '../../../client/service';
import { CANCEL_ACCESS_USER_ROLE } from '../../../client/components/shared/Constants';
import { dealStatusTypes } from '../../constants/constants';
import {checkForUserRoles} from '../../../shared/utils/constants';
import { financeService } from '../../../services';
import Popup from '../../../shared/popup/component';
import { isNumeric, roundTo2Decimal } from '../../../client/components/shared/utility';
import InputText from '../../../shared/components/input-text';

const FinanceDealInfo = ({
  dealInfo,
  match,
  fetchFinanceDealInfoByIdConnected
}) => {
  const {deal_status} = dealInfo;
  const [uiRules, setUiRules] = useState({});
  const [isCngPopOpen, setIsCngPopupOpen] = useState(false);
  const dealCanbeCancelled = !!checkForUserRoles(CANCEL_ACCESS_USER_ROLE) && (deal_status === dealStatusTypes.TOKEN_DONE) && !uiRules.freezeCancelDeal;

  const fetchUiRules = async (suppliedDealId) => {
    setUiRules(await financeService.getUiRulesForDeal(suppliedDealId));
  }

  useEffect(() => {
    let id = match.params.id;
    if (id) {
      fetchFinanceDealInfoByIdConnected(id);
      fetchUiRules(id);
    }
  }, [match.params.id]);

  const onDealCancel = (data) => {
    // const userId = getFromLocalStorage('userId');
    const dealId = match.params.id;
    DataService.cancelDeal(dealId, data).then(() => {
      setIsCngPopupOpen(false);
      setTimeout(()=> {
        fetchFinanceDealInfoByIdConnected(dealId);
      }, 2000);
    });
  };

  const onFinanceRefresh = () => {
    const dealId = match.params.id;
    financeService.onRefreshFinanceData(dealId).then(() => {
      showToastMessages("Finance data refreshed successfully");
      setTimeout(()=> {
        fetchFinanceDealInfoByIdConnected(dealId);
      }, 2000);
    }).catch(()=>{
      showToastMessages("Finance data failed to refresh", false);
    });
  };

  const getValue = (item) => {
    const value = dealInfo[item.key];
    if (dealInfo && item.key in dealInfo) {
      switch (item.type) {
        case 'text':
          if (item.key === 'car_source') {
            const list = dealInfo && dealInfo.car_source_meta_data_list;
            const selected = getSelectedOption(list, 'value', value);
            return selected && selected.display_text
              ? selected.display_text
              : Locale.NA;
          } else if (item.key === 'deal_status') {
            const list = dealInfo && dealInfo.deal_status_metadata_list;
            const selected = getSelectedOption(list, 'value', value);
            return selected && selected.display_text
              ? selected.display_text
              : Locale.NA;
          } else if (isNumeric(value)) {
            return roundTo2Decimal(value);
          } else return value;

        case 'date':
          return getformattedDate(dealInfo[item.key]);

        default:
          return '';
      }
    } else {
      return '';
    }
  };

  const handleDealCancelClick = () => {
    if(uiRules.cngDeal) {
      setIsCngPopupOpen(true);
    } else {
      let data = { cancellationCharges : 0 };
      onDealCancel(data);
    }
  }

  return (
    <LoaderWithAccessControl>
      <div>
        <div className={`row col-12 ${globalStyles.mb20}`}>
          <DataContainer
            title='Car Details'
            config={CAR_DETAILS}
            getValue={getValue}
            data={dealInfo}
          />
        </div>

        <div className={`row col-12 ${globalStyles.mb20}`}>
          <DataContainer
            title='Buyer Details'
            config={BUYER_DETAILS}
            getValue={getValue}
            data={dealInfo}
          />
          <DataContainer
            title='Seller Details'
            config={SELLER_DETAILS}
            getValue={getValue}
            data={dealInfo}
          />
        </div>
        <div className={`row col-12 ${globalStyles.mb20}`}>
          <DataContainer
            title='Buyer Payment Details'
            config={BUYER_PAYMENT_DETAILS}
            getValue={getValue}
            data={dealInfo}
          />
          <DataContainer
            title='Seller Payment Details'
            config={SELLER_PAYMENT_DETAILS}
            getValue={getValue}
            data={dealInfo}
          />
        </div>

        <div className={`col-12 ${globalStyles.mb20}`}>
          <h2 className={`${globalStyles.headingUnderlined}`}>Token Details</h2>
          <p>
            {getValue({
              key: 'token_date',
              type: 'date'
            })}{' '}
            : Token completed by{' '}
            {getValue({
              key: 'buddy_name',
              type: 'text'
            })}
            ,{' '}
            {getValue({
              key: 'buddy_number',
              type: 'text'
            })}{' '}
            | TM -
            {getValue({
              key: 'tm_email_id',
              type: 'text'
            })}
          </p>
        </div>
        <div className={`col-6 ${globalStyles.mb20}`}>
          {!!dealCanbeCancelled && <button onClick={handleDealCancelClick} className='btn btn-primary ml10'>
            Cancel Deal
          </button>}
          <button onClick={onFinanceRefresh} className='btn btn-primary ml10'>
            Refresh
          </button>
        </div>
      </div>
      <CngPopup
        isOpen={isCngPopOpen}
        handleClose={() => setIsCngPopupOpen(false)}
        onDealCancel={onDealCancel}
      />
    </LoaderWithAccessControl>
  );
};

const DataContainer = ({ title, config, getValue }) => {
  return (
    <div className='col-6'>
      <h2 className={`${globalStyles.headingUnderlined}`}>{title} </h2>
      {config.map((item, index) => {
        return <Field key={index} item={item} getValue={getValue} />;
      })}
    </div>
  );
};

const Field = ({ item, getValue }) => {
  const value = getValue(item);
  let fontStyle = '';
  if (item.color && value) {
    fontStyle =
      parseFloat(value) > 0 ? globalStyles.textError : globalStyles.textSuccess;
  }
  return (
    <div className='row'>
      <div className='col-5'>
        <label className='font-weight-bold capitalize'>{item.label}</label>
      </div>
      <div className='col-7'>
        <label className={`${globalStyles.wordBreakAll} ${fontStyle}`}>
          : {value}
        </label>
      </div>
    </div>
  );
};

const CngPopup = ({ isOpen, handleClose, onDealCancel }) => {
  const [fields, setFields] = useState({ cancellationCharges: 15000 });

  const handleFieldChange = (e) => setFields({ ...fields, [e.target.id]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();

    let formData = Object.entries(fields);
    let data = {};
    
    for(const field of formData) {
      data[field[0]] = Number(field[1]);
    }
    onDealCancel(data);
  }

  return (
    <Popup isOpen={isOpen} close={handleClose} closeOnOutside={false} contentStyles={{ width: '500px' }}>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="cancellationCharges">Enter Cancellation Charges</label>
          <InputText
            type="number"
            className="form-control"
            id="cancellationCharges"
            name="cancellationCharges"
            value={fields.cancellationCharges}
            onChange={handleFieldChange}
            disabled
          />
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>
    </Popup>
  )
};

export default withRouter(FinanceDealInfo);
