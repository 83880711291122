export const FIELDS = {
  reason: {
    label: 'Discard Reason',
    keyName: 'reason',
    type: 'select',
    required: true,
    elementType: 'select'
  },
  remarks: {
    label: 'Discard Remarks',
    keyName: 'remarks',
    type: 'text',
    required: false,
    elementType: 'textarea'
  }
};

export const getInitValues = () => {
  let initObj = {};
  Object.keys(FIELDS).forEach((item) => {
    initObj[item] = '';
  });
  return initObj;
};

export const DISCARD_REASONS = {
  INCORRECT_IMAGE_UPLOADED: {
    label: 'Incorrect Image uploaded',
    value: 'Incorrect Image uploaded'
  },
  IMAGE_NOT_CLEAR: {
    label: 'Image not clear',
    value: 'Image not clear'
  },
  INCORRECT_UTR: {
    label: 'Incorrect UTR',
    value: 'Incorrect UTR'
  },
  INSUFFICIENT_AMOUNT_FOR_UTR: {
    label: 'Insufficient amount for UTR',
    value: 'Insufficient amount for UTR'
  }
};
