import React from 'react';
// import { Redirect } from 'react-router-dom';
// import { getIsLoggedInFromLocalStorage, removeDataFromLocalStorage } from '../helper';
import Success from '../../../images/success.svg';

const ThankYou = () => {
  // const isLoggedIn = getIsLoggedInFromLocalStorage();

  // if (isLoggedIn) {
    return (
      <div className="container text-center thanks">
        <img src={Success} alt="Success!"></img>
        <h2>Thank You!</h2>
      </div>
    );
  // } else {
  //   removeDataFromLocalStorage();
  //   return <Redirect to='/login' />
  // }
}

export default ThankYou;