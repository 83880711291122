import React, { useState } from "react";
import { CBox, CCloseButton } from "../c-box";
import { getformattedDate } from "../../../../shared/utils/datesHelper";

export const ResolveRtosDelay = ({
  rtoDelayDetails,
  onSave
}) => {
  const [current, setCurrent] = useState(rtoDelayDetails.filter(({ rtoResolved }) => !rtoResolved));
  const [resolved,] = useState(rtoDelayDetails.filter(({ rtoResolved }) => rtoResolved))
  const [toBeResolved, setToBeResolved] = useState([]);

  const handleResolve = (rtoCode) => {
    const index = current.findIndex((rtoDelayDetail) => rtoDelayDetail.rtoCode === rtoCode);
    if (index !== -1) {
      const updatedToBeResolved = [...toBeResolved, {
        ...current[index],
      }];
      setToBeResolved(updatedToBeResolved);

      const updatedCurrent = [...current];
      updatedCurrent.splice(index, 1);
      setCurrent(updatedCurrent);
    }
  };

  const handleSave = () => {
    onSave(toBeResolved);
  };

  return (
    <div className="bg-white p-4" style={{ width: '800px', height: '710px'}}>
      <CBox>
        <div style={{ borderBottom: '1px solid gray', paddingBottom: '20px' }}>
          <h2 className="font-weight-bold">RTOs where delays will be updated</h2>
          <div style={{ minHeight: '250px', maxHeight: '250px', overflow: 'scroll' }}>
            {current && !!current.length && (
              <div className="d-flex flex-wrap overflow-auto">
                {current.map(({ rtoCode }, idx) => {
                  return (
                    <CCloseButton
                      key={idx}
                      label={rtoCode}
                      className={'mr-3 mt-2'}
                      onRemove={() => handleResolve(rtoCode)}
                    />
                  )
                })}
              </div>
            )}
          </div>
        </div>
        <div style={{ paddingTop: '20px'}}>
          <h2 className="font-weight-bold">RTOs where delays were resolved</h2>
            <div style={{ minHeight: '250px', maxHeight: '250px', overflow: 'scroll' }}>
              <div className="d-flex flex-wrap overflow-auto">
                {(resolved.concat(toBeResolved)).map((rtoDelayDetail, idx) => {
                  const { rtoResolved, rtoCode, updatedAt } = rtoDelayDetail;
                  let label = rtoCode;
                  if (rtoResolved) {
                    label += ` - ${getformattedDate(updatedAt, 'custom', 'DD MMM YYYY')}` 
                  }
                  return (
                    <CCloseButton
                      key={idx}
                      label={label}
                      className={'mr-3 mt-2'}
                      showRemoveIcon={false}
                      onRemove={() => handleResolve(rtoCode)}
                    />
                  )
                })}
              </div>
            </div>
        </div>
        {toBeResolved && !!toBeResolved.length && (
          <button className="btn btn-sm btn-primary mt-4" onClick={handleSave}>Save</button>
        )}
      </CBox>
    </div>
  )
}