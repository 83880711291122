import React, { useState, useEffect } from 'react';
import { Button, Label, Input } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './style/style.css';
import { SelectComponent, DispositionConstants } from '..';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { withRouter } from 'react-router-dom';
import { DataService } from '../../../service';
import InputRadioButton from '../../../../shared/input-radio-button';
import { YES } from '../../../../admin-app/components/rc-management-detail/constant';
import { NO } from '../../../../shared/utils/constants';
import { getLeadType } from '../utility';
import { CAccordian } from '../Accordion/c-accordion';
import { PREFERRED_LANGUAGE_TYPES } from '../Constants';
import { isDateTimeRequired, isLanguageRequired } from './helper';

const DispositionDetails = ({
  leadType,
  whatsappOpted,
  phone,
  dispositionData,
  setDisposition,
  isSubmit,
  setSubDispositionStatus,
  subDispositionStatus,
  setIsValidDisposition,
  setPostData,
  sellerMaxListingPrice = 0,
  buyerDiscountedPrice = 0,
  submitErrors,
  showSubmitErrors,
  setShowSubmitErrors,
  car = null,
  selectedCcDispositionId,
  setSelectedCcDispositionId,
  selectedTags,
  setSelectedTags,
  ctaText = 'Submit',
  ...restProps
}) => {
  let dispState = getLeadType();
  
  const [dispositionList, setDispositionList] = useState(
    dispositionData
      .map((item) => {
        return { value: item.disposition, label: item.disposition };
      })
  );
  
  const [subDispositionList, setSubDispositionList] = useState([]);
  const [dispositionStatus, setDispositionStatus] = useState();
  const [dispositionStatusError, setDispositionStatusError] = useState(false);
  const [subDispositionStatusError, setSubDispositionStatusError] = useState(false);
  const [dialerDisposition, setDialerDisposition] = useState('');
  const [isWhatsappOpted, setIsWhatsappOpted] = useState(whatsappOpted);
  const [remarks, setRemarks] = useState('');
  const [remarksError, setRemarksError] = useState(false);
  const [language, setLanguage] = useState('');
  const [languageError, setLanguageError] = useState(false);
  const [dateTime, setDateTime] = useState('');
  const [dateTimeError, setDateTimeError] = useState(false);

  useEffect(()=>{
    setIsWhatsappOpted(whatsappOpted);
  },[whatsappOpted])

  useEffect(() => {
    restProps.defaultDisposition &&
      setDispositionStatus(restProps.defaultDisposition);
  }, [restProps.defaultDisposition]);

  useEffect(() => {
    if (dispositionList) {
      const uniqueDispositionList = [
        ...new Set(dispositionList.map((item) => JSON.stringify(item)))
      ].map((item) => JSON.parse(item));
      setDispositionList(uniqueDispositionList);
    }
    restProps.defaultDisposition &&
      setDispositionStatus(restProps.defaultDisposition);
  }, []);

  useEffect(() => {
    if (dispositionStatus) {
      setSubDispositionList(
        dispositionData
          .filter(
            (item) =>
              item.disposition === dispositionStatus
          )
          .map((item) => {
            return {
              value: item.subDisposition,
              label: item.subDisposition
            };
          })
      );
      setSubDispositionStatus();
      setDialerDisposition('');
    }
  }, [dispositionStatus]);

  useEffect(() => {
    setShowSubmitErrors(false);
    setLanguage('');
    setDateTime('');
  }, [dispositionStatus, subDispositionStatus]);

  const updateDisposition = (status) => {
    const dispData = dispositionData.find(
      (item) => item.disposition === status
    );

    const dispositionObj = {
      dispositionId: dispData.id,
      dispositionStatus: dispData.disposition,
      dialerDisposition: null,
      cfDetail: null
    };
    setSelectedCcDispositionId(dispData.id);
    setDisposition(dispositionObj);
    setSubDispositionStatus();
    setSubDispositionStatusError(false);
  };

  const onSubmit = () => {
    const isValid = validateDisposition();
    if (isValid) {
      const dispData = dispositionData.find(
        (item) =>
          item.disposition === dispositionStatus &&
          item.subDisposition === subDispositionStatus
      );

      const dispositionObj = {
        dispositionId: dispData.id,
        dispositionStatus: dispData.dispositionStatus,
        dialerDisposition,
        cfDetail: null,
        additionalFields: {},
        ccDisposition: dispData.disposition,
        ccSubDisposition: dispData.subDisposition
      };

      if (remarks) {
        dispositionObj.additionalFields.remarks = remarks;
      }
      if (isLanguageRequired(dispositionStatus, subDispositionStatus)) {
        dispositionObj.additionalFields.language = language;
      }
      if (isDateTimeRequired(dispositionStatus, subDispositionStatus)) {
        dispositionObj.additionalFields.dateTime = dateTime;
      }

      setDisposition(dispositionObj);
      setIsValidDisposition(true);
    } else {
      setDisposition();
      setIsValidDisposition(false);
    }
    setPostData(true);
  };

  const validateDisposition = () => {
    let errors = 0;
    dispositionStatus
      ? setDispositionStatusError(false)
      : ++errors && setDispositionStatusError(true);
    subDispositionStatus
      ? setSubDispositionStatusError(false)
      : ++errors && setSubDispositionStatusError(true);
    remarks ? setRemarksError(false) : ++errors && setRemarksError(true);
    if (isLanguageRequired(dispositionStatus, subDispositionStatus)) {
      language ? setLanguageError(false) : ++errors && setLanguageError(true);
    }
    if (isDateTimeRequired(dispositionStatus, subDispositionStatus)) {
      dateTime ? setDateTimeError(false) : ++errors && setDateTimeError(true);
    }

    if (errors === 0) return true;
    else return false;
  };

  const onWhatsOptedClick = (value) => {
    setIsWhatsappOpted(value)
    DataService.whatsAppOpted(phone,value).then((resp)=>{
      // console.log("whatsapp response:", resp);
    });
  };

  const onRemarksChange = (e) => {
    const { value } = e.target;
    setRemarks(value);
  }

  const onLanguageChange = (value) => {
    setLanguage(value);
  }

  const onDateTimeChange = (value) => {
    setDateTime(value);
  }

  return (
    <div className='bg-white p-0 disposition-block'>
      <CAccordian
        heading={<h2 className='title'>Disposition Details</h2>}
        body={(
          <>
            <div className='row'>
              <div className='col-5 form-group disp-box'>
                <Label for='dispositionStatus' className='lbl'>
                  Disposition*:
                </Label>
              </div>
              <div className='col-7 form-group disp-box'>
                <SelectComponent
                  key={dispositionStatus ? dispositionStatus : 'dispositionStatus'}
                  name='dispositionStatus'
                  hasError={dispositionStatusError ? true : false}
                  onChange={(value) => {
                    setDispositionStatus(value);
                    setDispositionStatusError(false);
                    updateDisposition(value);
                  }}
                  defaultValue={{
                    label: dispositionStatus,
                    value: dispositionStatus
                  }}
                  value={{
                    label: dispositionStatus,
                    value: dispositionStatus
                  }}
                  optionsList={dispositionList}
                  hideSelectedOptions={false}
                  isClearable={false}
                />
                {dispositionStatusError && (
                  <span className='text-error'>Disposition is required</span>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col-5 form-group disp-box'>
                <Label for='subDispositionStatus' className='lbl'>
                  Sub-Disposition*:
                </Label>
              </div>
              <div className='col-7 form-group disp-box'>
                <SelectComponent
                  key={
                    dispositionStatus
                      ? `sub_${dispositionStatus}`
                      : 'subDispositionStatus'
                  }
                  name='subDispositionStatus'
                  hasError={subDispositionStatusError ? true : false}
                  onChange={(value) => {
                    setSubDispositionStatus(value);
                    setSubDispositionStatusError(false);
                  }}
                  value={{
                    label: subDispositionStatus,
                    value: subDispositionStatus
                  }}
                  optionsList={subDispositionList}
                  hideSelectedOptions={false}
                  isClearable={false}
                />
                {subDispositionStatusError && (
                  <span className='text-error'>Sub-Disposition is required</span>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col-5 form-group disp-box'>
                <Label for='remarks' className='lbl'>
                  Remarks*:
                </Label>
              </div>
              <div className='col-7 form-group disp-box'>
                <Input
                  type='textarea'
                  className={
                    'textarea' +
                    (remarksError
                      ? ' error'
                      : '')
                  }
                  name={"remarks"}
                  value={remarks}
                  autoComplete='off'
                  onChange={onRemarksChange}
                />
                {remarksError && (
                  <span className='text-error'>Remarks is required</span>
                )}
              </div>
            </div>
            {isLanguageRequired(dispositionStatus, subDispositionStatus) && (
              <div className='row'>
                <div className='col-5 form-group disp-box'>
                  <Label for='language' className='lbl'>
                    Preferred Language*:
                  </Label>
                </div>
                <div className='col-7 form-group disp-box'>
                  <SelectComponent
                    name={"language"}
                    hasError={
                      languageError
                        ? true
                        : false
                    }
                    onChange={onLanguageChange}
                    optionsList={PREFERRED_LANGUAGE_TYPES}
                    hideSelectedOptions={false}
                    isClearable={false}
                    value={{
                      label: language,
                      value: language
                    }}
                  />
                  {languageError && (
                    <span className='text-error'>Preferred Language is required</span>
                  )}
                </div>
              </div>
            )}
            {isDateTimeRequired(dispositionStatus, subDispositionStatus) && (
              <div className='row'>
                <div className='col-5 form-group disp-box'>
                  <Label for='dateTime' className='lbl'>
                    Date and Time*:
                  </Label>
                </div>
                <div className='col-7 form-group disp-box'>
                  <div className='datetime-picker'>
                    <DatePicker
                      calendarClassName='datepicker-container'
                      className={
                        'adtnl-flds-dt' +
                        (dateTimeError
                          ? ' error'
                          : '')
                      }
                      selected={
                        dateTime
                          ? new Date(dateTime)
                          : null
                      }
                      onChange={onDateTimeChange}
                      onChangeRaw={(e) => e.preventDefault()}
                      minDate={new Date()}
                      disabledKeyboardNavigation
                      showTimeSelect
                      timeFormat='HH:mm'
                      timeIntervals={15}
                      dateFormat='d MMMM yyyy h:mm aa'
                      timeCaption='Time'
                    />
                  </div>
                  {dateTimeError && (
                    <span className='text-error'>Date and Time is required</span>
                  )}
                </div>
              </div>
            )}
            <div className='row d-flex align-items-center'>
              <div className='col-5 form-group disp-box'>
                  <Label for={`whatsapp`} className='lbl'>
                    {`WhatsApp Opt-In`}:
                  </Label>
              </div>
              <div className='dispositioncheckbox'>
                <InputRadioButton 
                  labelId={`whatsapp_${YES}`} 
                  id={`whatsapp_${YES}`}
                  value={YES} 
                  checked={isWhatsappOpted} 
                  text={YES} 
                  onClickCallback ={()=> onWhatsOptedClick(true)}
                />
                <InputRadioButton 
                  labelId={`whatsapp_${NO}`}
                  id={`whatsapp_${NO}`}
                  value={NO} 
                  checked={!isWhatsappOpted} 
                  text={NO} 
                  onClickCallback={()=> onWhatsOptedClick(false)}
                />
              </div>
            </div>
            {showSubmitErrors && submitErrors && (
              <>
                {submitErrors.map((item, index) => (
                  <p key={`err_${index}`} className='text-error'>
                    {item.message}
                  </p>
                ))}
              </>
            )}
            {dispState === DispositionConstants.BUYER &&
              selectedCcDispositionId ===
                DispositionConstants.BUYER_OFFER_PRICE_CC_DISPOSITION_ID &&
              !car && (
                <div>
                  <span className='text-error'>
                    Car Details not available. Please check CarUrl.
                  </span>
                </div>
              )}
            <div className='form-group disp-box button-align disposition-btn'>
              <Button
                className='d-block submit'
                onClick={onSubmit}
                disabled={isSubmit}
              >
                {isSubmit ? 'Processing' : ctaText}
              </Button>
            </div>
          </>
        )}
      />
    </div>
  );
};

export default withRouter(DispositionDetails);
