import React from 'react';
import Select from 'react-select';
import isNil from 'lodash/isNil';

const MultiSelectRenderer = ({
  data,
  attributeKey,
  isMulti = false,
  options = [],
  context: {
    handleMultiSelectChange
  }
}) => {
  const handleChange = (selectedVals) => {
    handleMultiSelectChange(attributeKey, selectedVals === null ? [] : selectedVals, data && data.id);
  };

  const getValue = () => {
    if(!data || !Object.keys(data).length || !options.length || isNil(data[attributeKey])) return;

    if(isMulti) {
      return data[attributeKey].map(docId => ({
        label: options.find(o => o.value === docId).label,
        value: docId
      }));
    } else {
      const { label, value } = options.find(o => o.value === data[attributeKey]);
      return { label, value };
    }
  }

  return (
    <Select
      classNamePrefix="dreMultiselect"
      styles={{ menuPortal: (provided) => ({ ...provided, zIndex: 10000000 }) }}
      menuPortalTarget={document.body}
      menuPlacement="auto"
      isMulti={isMulti}
      options={options}
      onChange={handleChange}
      value={getValue()}
      hideSelectedOptions={false}
    />
  );
};

export default MultiSelectRenderer;
