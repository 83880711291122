import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { OPTIONS, HANDOVER_METHODS_BUYER_VALUES } from './constant';
import rcTrackerConstants from '../../components/rc-tracker/constants';
import {
  SelectComponent,
  SelectDropdown
} from '../../../client/components/shared';
import './style.css';
import { getLocalDatetime } from '../../../shared/utils/datesHelper';
import UploadButton, {
  UploadIcon
} from '../../../shared/components/upload-button/component';
import { rcManagementService } from '../../../services';
import CustomButton from '../../../shared/components/button';
import Locale from '../../../shared/utils/locale';
import { formatDateTime } from '../../../shared/utils/helper';
import { withRouter } from 'react-router-dom';
import { useIsRcOthers } from '../../../shared/hooks';
import { 
  getCategory,
  getSubCategory,
  onChangeDelayCategory,
  onChangeDelaySubCategory, 
} from "../../viewModels/rc-management";
const RcTrackerFileStatus = ({
  delayData,
  file,
  updateTrackerData,
  trackerData,
  fileCompletionDate,
  updateError,
  refetchRCTrackerData,
  originalTrackerData,
}) => {
  const isOtherRcView = useIsRcOthers();
  const statusMap = {
    file_rc_team_date: rcTrackerConstants.file_status.file_received.value,
    e_receipt_issue_date: rcTrackerConstants.file_status.e_receipt_issued.value,
    rc_transfer_completion_date: rcTrackerConstants.file_status.rc_transfer_completed.value,
    physical_rc_receiving_date: rcTrackerConstants.file_status.physical_rc_received.value
  };
  
  const onChange = (name, value) => {
    const fileState = { ...trackerData };
    fileState[name] = value;
    updateTrackerData(fileState);
  };
  
  const handleDateChange = (date, value, showInvalidErrorMessage) => {
    let fileState = { ...trackerData };
    fileState[value] = getLocalDatetime(date, showInvalidErrorMessage);
    if(value === "rc_transfer_completion_date" && !!date && !!fileState && !!fileState.transferToRtoCodeInfo && !!fileState.transferToRtoCodeInfo.isDirectRto) {
      fileState = {...fileState,
        'rc_handover_buyer_method' : HANDOVER_METHODS_BUYER_VALUES.DIRECT_RTO,
        'speedpost_delivery_status': 'Dispatched',
        'speedpost_dispatch_date': new Date()
      }
    }
    if(statusMap[value] && date) {
      fileState["rc_transfer_status"] = statusMap[value]
    }
    updateTrackerData(fileState);
    updateError(false);
  };
  const onChangeSelect = (selected, name) => {
    let fileState = { ...trackerData };
    if (selected) {
      if (name === "rc_handover_buyer_method") {
        if ([HANDOVER_METHODS_BUYER_VALUES.SPEED_POST, HANDOVER_METHODS_BUYER_VALUES.SALES, HANDOVER_METHODS_BUYER_VALUES.NBFC, 
          HANDOVER_METHODS_BUYER_VALUES.TAKEN_BY_CUSTOMER].includes(selected.value)) {
          fileState = {...fileState,
            'speedpost_delivery_status': 'Dispatched',
            'speedpost_dispatch_date': new Date()
          }
        }
        if (selected.value !== HANDOVER_METHODS_BUYER_VALUES.SALES) {
          fileState = {...fileState,
            'customerNameSales': '',
            'customerMobileSales': '',
            'receiving_proof_sales_id': ''
          };
        }
      }
      fileState[name] = selected.value;
      updateTrackerData(fileState);
    } else {
      fileState[name] = '';
      if (name === "rc_handover_buyer_method") {
        fileState = {...fileState,
          'speedpost_delivery_status': '',
          'speedpost_dispatch_date': ''
        }
      }
    }
    updateTrackerData(fileState);
  };

  const handleUpload = (file, field) => {
    const formData = new FormData();
    formData.append('file', file);
    let params = {
      tracker_id: trackerData.id,
      file_type: field.fileType,
      rc_application_id: trackerData.rc_application_id
    };
    rcManagementService[isOtherRcView ? "uploadFileRcOthersTracker" : "uploadFileRcTracker"](params, formData).then((response) => {
      const updatedTrackerData = {};
      if(field.dependentKey && statusMap[field.dependentKey]) {
        updatedTrackerData["rc_transfer_status"] = statusMap[field.dependentKey]
      }
      if(["rto_noc", "buyer_rc", "e_receipt", "physical_rc", "proof_sales"].includes(field.fileType)) {
        refetchRCTrackerData((resp) => {
          if(resp.e_receipt_issue_date) { updatedTrackerData["e_receipt_issue_date"] = resp.e_receipt_issue_date; }
          if(resp.rc_transfer_completion_date) 
          { 
            updatedTrackerData["rc_transfer_completion_date"] = resp.rc_transfer_completion_date; 
          } else {
            updatedTrackerData["rc_transfer_status"] = resp.rc_transfer_status;
          }
          if(resp.rto_noc_issue_date) { updatedTrackerData["rto_noc_issue_date"] = resp.rto_noc_issue_date; }
          if(resp.physical_rc_receiving_date) { updatedTrackerData["physical_rc_receiving_date"] = resp.physical_rc_receiving_date }
          updateTrackerData({ ...trackerData, [field.apiKey]: response, ...updatedTrackerData });
        });
      }
    });
  };

  const viewFile = (id) => {
    rcManagementService[isOtherRcView ? "viewFileRcOthersTracker" : "viewFileRcTracker"](id).then((response) => {
      if (response && response.data && response.data.data) {
        window.open(response.data.data.imageUrl);
      }
    });
  };

  const deleteFile = (id, key) => {
    rcManagementService[isOtherRcView ? "deleteFileRcOthersTracker" : "deleteFileRcTracker"](id).then((response) => {
      updateTrackerData({ ...trackerData, [key]: null });
    });
  };

  const typeSwitch = (param, field) => {
    switch (param) {
      case 'delay_dropdown':
        const categories = getCategory({fieldKey: field.apiKey, delayData}) || [];
        const subCategories = getSubCategory({fieldKey: field.apiKey, delayData, trackerData}) || [];
        return (
          <div className='col-6'>
              {trackerData && <div className="row">
                <div className='col-6'>
                  <SelectDropdown
                    name={`${field.apiKey}_category`}
                    optionsList={categories}
                    value={trackerData[field.apiKey] ? trackerData[field.apiKey].category : null}
                    onChange={(value)=> onChangeDelayCategory({key: field.apiKey, data: value, trackerData, updateTrackerData})}
                    isEmptyDefaultValue={originalTrackerData[field.apiKey] && originalTrackerData[field.apiKey].category ? false : true}
                    isSelectRequired={false}
                  />
                </div>
                <div className='col-6'>
                  <SelectDropdown
                    name={`${field.apiKey}_subcategory`}
                    optionsList={subCategories}
                    value={trackerData[field.apiKey] ? trackerData[field.apiKey].subCategory: null}
                    onChange={(value)=> onChangeDelaySubCategory({key: field.apiKey, data: value, trackerData, updateTrackerData})}
                    isEmptyDefaultValue={true}
                    isSelectRequired={false}
                  />
                </div>
              </div>}
          </div>
        );
      case 'dropdown':
        return (
          <div className='col-6'>
            {trackerData && (
              <SelectComponent
                key={
                  trackerData[field.apiKey]
                    ? `${trackerData[field.apiKey].replace(/\s/g, '')}`
                    : field.apiKey
                }
                name={field.apiKey}
                optionsList={OPTIONS[field.dropDownName]}
                defaultValue={
                  trackerData[field.apiKey]
                    ? {
                        label: trackerData[field.apiKey],
                        value: trackerData[field.apiKey]
                      }
                    : null
                }
                value={
                  trackerData[field.apiKey]
                    ? {
                        label: trackerData[field.apiKey],
                        value: trackerData[field.apiKey]
                      }
                    : null
                }
                //placeholder={trackerData[field.apiKey]}
                onChange={onChangeSelect}
                version='v2'
                isClearable={true}
              />
            )}
          </div>
        );
      case 'date':
        let dateProps = {};
        if (field.apiKey === 'file_rc_team_date' && fileCompletionDate) {
          dateProps = {
            minDate: new Date(fileCompletionDate)
          };
        } else if (field.apiKey === 'rc_transfer_completion_date') {
          dateProps = {
            minDate: new Date()
          };
        }
        else {
          dateProps = {};
        }

        const isDisabled =
          field.disabled ||
          (field.dependentKey &&
            trackerData[field.dependentKey] === false &&
            !trackerData[field.apiKey]);
        const placeholder =
          isDisabled && field.apiKey === 'e_receipt_issue_date'
            ? Locale.NOT_AVAILABLE
            : '';

        return (
          <div className='col-6 datepicker_custom'>
            <DatePicker
              selected={
                trackerData[field.apiKey]
                  ? new Date(trackerData[field.apiKey])
                  : null
              }
              onChange={(date) => handleDateChange(date, field.apiKey, false)}
              onChangeRaw={(e) => {
                e.preventDefault();
              }}
              isClearable={field.disabled ? false : true}
              disabled={isDisabled}
              placeholderText={placeholder}
              dateFormat='dd/MM/yyyy'
              {...dateProps}
            />
          </div>
        );
      case 'text':
        let fieldValue = trackerData[field.apiKey] || '';
        if (field.apiKey === 'courierAddressCaptureDate' && !!fieldValue) {
          fieldValue = formatDateTime(fieldValue);
        }
        return (
          <div className='col-6'>
            <input
              type={field.apiKey === 'customerMobileSales'? 'number' : 'text'}
              className='form-control'
              name={field.apiKey}
              value={fieldValue}
              onChange={(e) => onChange(field.apiKey, e.target.value)}
              disabled={field.disabled}
            />
          </div>
        );
      case 'textarea':
        return (
          <div className='col-6'>
            <textarea
              type='text'
              className='form-control'
              name={field.apiKey}
              value={trackerData[field.apiKey] || ''}
              onChange={(e) => onChange(field.apiKey, e.target.value)}
              disabled={field.disabled}
            />
          </div>
        );
      case 'upload':
        return (
          <div className='col-6 relative'>
            {!trackerData[field.apiKey] ? (
              <UploadButton
                label='Upload'
                name={field.apiKey}
                onChangeHandler={(file) => handleUpload(file, field)}
                accept='image/*'
                btnIcon={UploadIcon}
                iconAlt='Replace Icon'
                // isDisabled={
                //   field.dependentKey && !trackerData[field.dependentKey]
                // }
              />
            ) : (
              <>
                <CustomButton
                  label='View'
                  //btnIcon={DownloadIcon}
                  className={'mr20'}
                  iconAlt='Download button Icon'
                  onClick={() => viewFile(trackerData[field.apiKey])}
                />

                <CustomButton
                  label='Delete'
                  //btnIcon={DownloadIcon}
                  iconAlt='Download button Icon'
                  onClick={() =>
                    deleteFile(trackerData[field.apiKey], field.apiKey)
                  }
                />
              </>
            )}
          </div>
        );
      default:
        return '';
    }
  };
  return (
    //NEED TO REMOVE THIS FILE TO SHARED
    <div className='row'>
      {file.map((field, key) => {
        if (field.type === 'upload' && !trackerData.id) return null;
        if (["receiving_proof_sales_id", "customerMobileSales", "customerNameSales"].includes(field.apiKey) 
          && !!trackerData && trackerData.rc_handover_buyer_method !== HANDOVER_METHODS_BUYER_VALUES.SALES) {
          return null;
        }
        return (
          <div key={key} className='col-6 d-flex justify-content-between mb20'>
            <label>{field.label}</label>
            {typeSwitch(field.type, field)}
          </div>
        );
      })}
    </div>
  );
};

export default withRouter(RcTrackerFileStatus);
