import Types from './types';

import {
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected,
  handleHttpStatusError
} from '../../../services/helper';
import { rcManagementService } from '../../../services';
const setPageNumberConnected = (pageNo) => ({
  type: Types.SET_PAGE_NUMBER,
  payload: pageNo
});
const fetchAgentGridInfoConnected = (params) => (dispatch) => {
  dispatchSetLoaderConnected();
  return rcManagementService
    .fetchAgentGridInfo(params)
    .then((resp) => {
      dispatchResetLoaderConnected();
      if (resp.data && resp.data.data) {
        return resp.data.data;
      } else {
        return {
          page: 0,
          totalPages: 0,
          totalRecords: 0,
          response: []
        };
      }
    })
    .catch((error) => {
      handleHttpStatusError(error, true);
      throw error;
    });
};

export { setPageNumberConnected, fetchAgentGridInfoConnected };
