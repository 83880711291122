import React, { useState } from "react";
import { SelectDropdown } from "../../../client/components/shared";
import { useUlip } from "../../hooks/custom-hooks/ulip-hook";

const INITIAL_FORM = {
  values: {},
  errors: {},
};

export const VahanApiFrequency = () => {
  const {
    config,
    ulipData,
    getUlipData,
    updateUlipData,
  } = useUlip();
  const [form, setForm] = useState(INITIAL_FORM);

  const handleStageChange = (e) => {
    const { value, name } = e.target;
    setForm({
      ...form,
      values: {
        ...form.values,
        [name]: value,
      }
    })
  };

  const handleSelectDay = (key) => {
    const selectedDays = (form.values.days || []);
    const index = selectedDays.findIndex((dayKey) => dayKey === key);
    if (index > -1) {
      selectedDays.splice(index, 1);
    } else {
      selectedDays.push(key);
    }
    setForm({
      ...form,
      values: {
        ...form.values,
        "days": selectedDays,
      }
    })
  };

  const handleUpdate = () => {
    const { stage, days } = form.values;
    updateUlipData({
      stage,
      days,
    })
      .then(() => {
        setForm(INITIAL_FORM);
        getUlipData();
      })
  };

  const getStageLabel = (stageKey) => {
    return (config.stage.find(({ key }) => key === stageKey) || {}).value || "";
  };

  const getDaysLabel = (daysKeys) => {
    return daysKeys.map((dayKey) => {
      return (config.days.find(({ key }) => key === dayKey) || {}).value || "";
    });
  }

  return (
    <div className="p-4">
      <div className="h4 mb-3">Vahan API Hit Frequency</div>
      <div>
        <div className="mb-4 d-flex flex-row">
          <label htmlFor="stateCode" className="col-2">Select Stage</label>
          <div className="col-3">
            <SelectDropdown
              name='stage'
              optionsList={(config.stage || []).map(({ key, value }) => ({ value: key, label: value }))}
              value={form.values.stage}
              onChange={handleStageChange}
              placeholder="Select stage"
            />
          </div>
        </div>
        <div className="mb-4 d-flex flex-row">
          <label htmlFor="days" className="col-2">Select Stage</label>
          <div className="col-10 flex flex-row">
            {config.days.map(({ key, value}, index) => {
              const isSelected = (form.values.days || []).includes(key);
              return (
                <div
                  style={{ cursor: "pointer" }}
                  className={`p-2 border rounded ${isSelected ? 'border-primary text-primary': 'border-secondary text-secondary'} ${index ? 'ml-3': ''}`}
                  onClick={() => handleSelectDay(key)}
                >
                  {value.slice(0, 3)}
                </div>
              )
            })}
          </div>
        </div>
        <div className="d-flex flex-row">
          <div className="col-2"></div>
          <button className="btn btn-sm btn-primary ml-3"  onClick={handleUpdate}>Update</button>
        </div>
      </div>
      {!!ulipData && (
        <div className="mt-5 p-4 rounded" style={{ backgroundColor: "rgba(60, 141, 188, 0.1)", maxWidth: "fit-content"}}>
          <div className="h5 mb-3">Current Frequency</div>
          <div className="d-flex flex-row">
            <div className="mr-4">Stage</div>
            <div className="text-primary">{getStageLabel(ulipData.stage)}</div>
          </div>
          <div className="d-flex flex-rowmb-3">
            <div className="mr-4">Days</div>
            <div className="text-primary ml-1">{getDaysLabel(ulipData.days || []).toString().split(",").join(", ")}</div>
          </div>
        </div>
      )}
    </div>
  )
}