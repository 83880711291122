import React from 'react';
import UserView from '../../components/user-view';
import withAdminLayout from '../../../shared/hoc/admin-layout';
import ErrorBoundary from '../../components/common/errorBoundary';
import { withAuthorization } from '../../../shared/utils/firebase';
import withUsersViewAuthorization from '../../../shared/hoc/users-view-authorization';

const UserForm = (props) => {
  return (
    <div>
      <ErrorBoundary>
        <UserView {...props} />
      </ErrorBoundary>
    </div>
  );
};
export default withAdminLayout(withUsersViewAuthorization(withAuthorization(() => true)(UserForm)));
//TODO:-sp-13 to sp-14(Akash)
