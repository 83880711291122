export const columns = () => [
  {
    headerName: 'Rejection Reason',
    cellRenderer: 'InputRenderer',
    cellRendererParams: {
      attributeKey: "rejectionReason",
      options: []
   }
  },
  {
    headerName: 'Delete',
    cellRenderer: 'DeleteIconRenderer',
  },
];