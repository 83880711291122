import {
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected,
  handleHttpStatusError
} from '../../../../services/helper';
import { rcManagementService } from '../../../../services';

const addOthersRcDetails = (payload) => () => {
  dispatchSetLoaderConnected();
  return rcManagementService
    .addRcDetailsOthers(payload)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      handleHttpStatusError(error, true);
      throw error;
    })
    .finally(() => {
      dispatchResetLoaderConnected();
    })
}

export { addOthersRcDetails };
