import Types from './types';
import { userRcAccessService } from '../../../services';
import { showToastMessages } from '../../../shared/utils/helper';
import {
  setAccessLoaderConnected,
  resetAccessLoaderConnected,
  setForbiddenConnected
} from '../../../shared/components/loader-with-access-control/actions';
import { handleHttpStatusError } from '../../../services/helper';
import { rcManagementService } from '../../../services';

const fetchUserRcAccessInfoSuccess = (data) => ({
  type: Types.FETCH_RCUSER_SUCCESS,
  payload: data
});
const fetchListFilterSuccess = (data) => ({
  type: Types.FETCH_RCUSER_FILTERLIST_SUCCESS,
  payload: data
});
const fetchUserRcAccessConnected = (userEmail) => (dispatch) => {
  dispatch(setAccessLoaderConnected());

  return new Promise((resolve, reject) => {
    userRcAccessService
      .fetchUserRcAccess(userEmail)
      .then((resp) => {
        resolve(resp);
        dispatch(fetchUserRcAccessInfoSuccess(resp.data.data));
        dispatch(resetAccessLoaderConnected());
      })
      .catch((error) => {
        handleHttpStatusError(error);
        reject(error);
      });
  });
};
const fetchRcManagemetFilterDataConnected = (userEmail) => (dispatch) => {
  return new Promise((resolve, reject) => {
    rcManagementService
      .fetchFilter('RC_MANAGEMENT')
      .then((resp) => {
        resolve(resp);
        dispatch(fetchListFilterSuccess(resp.meta_data));
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const addUserRcAccessConnected = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    userRcAccessService
      .addUserRcAccess(data)
      .then((resp) => {
        resolve(resp);

        showToastMessages(resp.data.message);
      })
      .catch((error) => {
        error.errors &&
          error.errors[0] &&
          error.errors[0]['message'] &&
          showToastMessages(error.errors[0]['message'], false);
        reject(error);
      });
  });
};

const editUserRcAccessConnected = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    userRcAccessService
      .editUserRcAccess(data)
      .then((resp) => {
        resolve(resp);
        showToastMessages(resp.data.message);
      })
      .catch((error) => {
        error.errors &&
          error.errors[0] &&
          error.errors[0]['message'] &&
          showToastMessages(error.errors[0]['message'], false);
        reject(error);
      });
  });
};

export {
  addUserRcAccessConnected,
  editUserRcAccessConnected,
  fetchUserRcAccessConnected,
  fetchRcManagemetFilterDataConnected
};
