import Types from './types';
import {
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected,
  handleHttpStatusError
} from '../../../services/helper';
import { userService } from './../../../services';

const fetchUserGridInfoInit = () => ({
  type: Types.FETCH_USER_GRID_DATA_INIT
});

const fetchUserGridInfoSuccess = (data) => ({
  type: Types.FETCH_USER_GRID_DATA_SUCCESS,
  payload: data
});

const fetchUserGridInfoFailure = (error) => ({
  type: Types.FETCH_USER_GRID_DATA_FAILURE,
  payload: error
});

const fetchUserGridInfoConnected = (params) => (dispatch) => {
  dispatch(fetchUserGridInfoInit());
  dispatchSetLoaderConnected();

  return userService
    .fetchUserGridInfo(params)
    .then((resp) => {
      dispatchResetLoaderConnected();
      if (resp.data.data) {
        let userHashed = {};
        resp.data.data.forEach((item) => {
          userHashed[item.userId] = item;
        });
        const result = { userList: resp.data.data, userHashed };
        dispatch(fetchUserGridInfoSuccess(result));
        return resp.data.data;
      } else {
        return {
          response: []
        };
      }
    })
    .catch((error) => {
      dispatch(fetchUserGridInfoFailure(error));
      handleHttpStatusError(error, true);
      throw error;
    });
};

export { fetchUserGridInfoConnected };
