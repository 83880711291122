import React, { useEffect, useState } from 'react';
import CustomButton from '../../../shared/components/button';
import InputText from '../../../shared/components/input-text';
import styles from './styles.module.css';

const ServiceCharges = ({
  fetchAllServiceChargesConnect,
  submitServiceChargeSlabConnect
}) => {
  const [serviceChargesSlab, setServiceChargesSlab] = useState([]);
  const [gst, setGST] = useState(0);
  const [gstMultiplier, setGstMultiplier] = useState(0);
  const [isSlabValid, setIsSlabValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    fetchAllServiceChargesConnect().then((response) => {
      if (response) {
        setServiceChargesSlab(response.service_charge_slabs);
        setGST(response.gst_percent);
      }
    });
  }, []);

  useEffect(() => {
    const multiplier = gst / 100 + 1;
    setGstMultiplier(multiplier);
    // prettier-ignore
    const updatedSlab = serviceChargesSlab.map((item) => {
      return {
        ...item,
        buyer_service_charge_with_gst : (item.buyer_service_charge_without_gst * multiplier).toFixed(2),
        seller_service_charge_with_gst : (item.seller_service_charge_without_gst * multiplier).toFixed(2),
      };
    });
    setServiceChargesSlab(updatedSlab);
  }, [gst]);

  const handleSlabChange = (e) => {
    const { name, value } = e.target;
    const index = e.target.getAttribute('index');

    let updatedSlab = [...serviceChargesSlab];
    updatedSlab[index][name] = parseInt(value) ? '' + parseInt(value) : 0;
    setServiceChargesSlab(updatedSlab);
  };

  const onBlurCallback = (e) => {
    const { name, value } = e.target;
    const currentIndex = e.target.getAttribute('index');
    const parsedValue = parseFloat(value);
    const node = `${name}_${currentIndex}`;
    const isOverlapped = serviceChargesSlab.some((item, index) => {
      const { lower_bound_asp, upper_bound_asp } = item;
      // prettier-ignore
      if (currentIndex === index) {
        return false;
      } else if(lower_bound_asp < parsedValue && parsedValue < upper_bound_asp) {
        return true;
      }else {
        // prettier-ignore
        const lowerBoundNode = `${Object.keys({lower_bound_asp})[0]}_${index}`;
        const upperBoundNode = `${Object.keys({upper_bound_asp})[0]}_${index}`;
        document.getElementById(lowerBoundNode).style.borderColor = '#ced4da';
        document.getElementById(upperBoundNode).style.borderColor = '#ced4da';
        return false;
      }
    });

    if (isOverlapped) {
      // document.getElementById(node).focus();
      document.getElementById(node).style.borderColor = 'red';
    } else {
      setTimeout(() => {
        document.getElementById(node).style.borderColor = '#ced4da';
      }, 0);
    }

    setIsSlabValid(isOverlapped);
  };
  // prettier-ignore
  const updateServiceCharge = (e) => {
    const { name, value } = e.target;
    const index = e.target.getAttribute('index');
    const gstfield = e.target.getAttribute('gstfield');

    let updatedSlab = [...serviceChargesSlab];
    const parsedValue = parseFloat(value) ? '' + parseFloat(value) : 0;
    updatedSlab[index][name] = parsedValue ;
    updatedSlab[index][gstfield] = (parsedValue * gstMultiplier).toFixed(2);
    setServiceChargesSlab(updatedSlab);
  };

  const updateGST = (e) => {
    const { value } = e.target;
    setGST(value);
  };

  const addNewSlab = () => {
    const newSlab = {
      buyer_service_charge_with_gst: 0,
      buyer_service_charge_without_gst: 0,
      id: serviceChargesSlab.length + 1,
      lower_bound_asp: 0,
      seller_service_charge_with_gst: 0,
      seller_service_charge_without_gst: 0,
      upper_bound_asp: 0
    };
    let updatedSlab = [...serviceChargesSlab, newSlab];
    setServiceChargesSlab(updatedSlab);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    submitServiceChargeSlabConnect({
      gst_percent: gst,
      service_charge_slabs: serviceChargesSlab
    })
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  };

  return (
    <div className={`col-12`}>
      <div className='col-6 d-flex align-content-center'>
        <label className={styles.gstlabel}>GST : </label>
        <InputText
          id={'gst'}
          name={'gst'}
          type='number'
          className='form-control'
          name='GST percentage'
          value={gst}
          onChange={updateGST}
          // required={true}
        />
      </div>
      <table className={`table table-boder ${styles.tableWapper}`}>
        <thead>
          <tr>
            <td colSpan='2'>ASP buckets</td>
            <td colSpan='2'>Service Charges (without GST)</td>
            <td colSpan='2'>Service Charges (with GST)</td>
          </tr>
          <tr>
            <td>From</td>
            <td>To</td>
            <td>Buyer SC</td>
            <td>Seller SC</td>
            <td>Buyer SC</td>
            <td>Seller SC</td>
          </tr>
        </thead>
        <tbody>
          {serviceChargesSlab &&
            serviceChargesSlab.map((item, index) => {
              const {
                id,
                buyer_service_charge_with_gst,
                buyer_service_charge_without_gst,
                lower_bound_asp,
                seller_service_charge_with_gst,
                seller_service_charge_without_gst,
                upper_bound_asp
              } = item;

              return (
                <tr key={`tr_${index}`}>
                  <td>
                    <InputText
                      id={`lower_bound_asp_${index}`}
                      name={'lower_bound_asp'}
                      type='number'
                      className='form-control'
                      value={lower_bound_asp}
                      onChange={handleSlabChange}
                      onBlurCallback={onBlurCallback}
                      required={true}
                      index={index}
                    />
                  </td>
                  <td>
                    <InputText
                      id={`upper_bound_asp_${index}`}
                      name={'upper_bound_asp'}
                      type='number'
                      className='form-control'
                      value={upper_bound_asp}
                      onChange={handleSlabChange}
                      onBlurCallback={onBlurCallback}
                      required={true}
                      index={index}
                    />
                  </td>
                  <td>
                    <InputText
                      id={`buyer_service_charge_without_gst_${index}`}
                      name={'buyer_service_charge_without_gst'}
                      type='number'
                      className='form-control'
                      value={buyer_service_charge_without_gst}
                      onChange={updateServiceCharge}
                      required={true}
                      index={index}
                      gstfield={'buyer_service_charge_with_gst'}
                    />
                  </td>
                  <td>
                    <InputText
                      id={`seller_service_charge_without_gst_${index}`}
                      name={'seller_service_charge_without_gst'}
                      type='number'
                      className='form-control'
                      value={seller_service_charge_without_gst}
                      onChange={updateServiceCharge}
                      required={true}
                      index={index}
                      gstfield={'seller_service_charge_with_gst'}
                    />
                  </td>
                  <td>{buyer_service_charge_with_gst}</td>
                  <td>{seller_service_charge_with_gst}</td>
                </tr>
              );
            })}
        </tbody>
        <tfoot />
      </table>
      <div className='d-flex'>
        {/* <div className='col-4' /> */}
        <div className='col-2'>
          <CustomButton
            label='Save'
            disabled={isSlabValid}
            onClick={handleSubmit}
            isLoading={isLoading}
            className={styles.customBtn}
          />
        </div>
        <div className='col-2'>
          <CustomButton
            label='Add'
            onClick={addNewSlab}
            className={styles.customBtn}
          />
        </div>
        <div className='col-8' />
      </div>
      {error && <p className={styles.errorLabel}>{error}</p>}
    </div>
  );
};

export default ServiceCharges;
