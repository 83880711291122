import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FinanceServiceSection from './component';
import { submitServiceChargesConnected } from '../finance-service-tab/actions';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      submitServiceChargesConnected
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinanceServiceSection);
