import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FinanceUpload from './component';
import {
  withAuthorization,
  withAdminLayout
} from '../../../shared/utils/firebase';
import { uploadUtrConnected } from './actions';

const mapStateToProps = (state) => {
  const {
    financeUploadInfo: { isLoading }
  } = state;
  return { isLoading };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ uploadUtrConnected }, dispatch);

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(FinanceUpload);
export default withAdminLayout(withAuthorization(() => true)(connected));
