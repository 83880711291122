import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  message: null,
  pageNumber: 0
};

export const setPageNumber = (state = INITIAL_STATE, action) => {
  const data = action.payload;
  return { ...state, pageNumber: data };
};

export const HANDLERS = {
  [Types.SET_PAGE_NUMBER]: setPageNumber
};

export default createReducer(INITIAL_STATE, HANDLERS);
