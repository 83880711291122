import React, { Fragment, useState, useEffect } from 'react';
import './style.css';
import useModal from 'react-hooks-use-modal';
import { useFormik } from 'formik';
import InputText from '../../../shared/components/input-text';
import { FIELDS, getInitValues, CANCEL_REASON } from './constants';
import { SelectComponent } from '../../../client/components/shared';
import { getParsedSelectOptions } from '../../../shared/utils/helper';
import { Wrapper } from '../form-element';
import CustomButton from '../../../shared/components/button';
import CustomDatePicker from '../../../shared/components/date-picker/component';
import { validationSchema } from './validations';

const CFinanceRefundCancelledModal = ({
  clickHandler,
  paymentModes,
  userType,
  dealId,
  id,
  refundCancelledInfoConnected,
  fetchTransactionLogsConnected
}) => {
  const [Modal, open, close] = useModal();
  const [options, setOptions] = useState({});

  const handleSubmit = (values) => {
    const payload = {
      reason: values.reason.value,
      id: id
    };
    refundCancelledInfoConnected(payload).then(() => {
      close();
      fetchTransactionLogsConnected(userType, dealId, false);
      formik.resetForm();
    });
  };

  const formik = useFormik({
    initialValues: getInitValues(),
    validationSchema: validationSchema,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    setOptions({
      ...options,

      [FIELDS.refund_reason.keyName]: Object.values(CANCEL_REASON)
    });
  }, [paymentModes]);
  const onChangeSelect = (selected, param) => {
    if (selected) {
      formik.setFieldValue(param, selected, true);
    } else {
      formik.setFieldValue(param, {}, true);
    }
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    formik.setFieldValue(name, value, true);
  };
  const onBlurCallback = (e) => {
    e.preventDefault();
    const { name } = e.target;
    formik.setFieldTouched(name, true, true);
  };
  const handleDateChange = (date, keyname) => {
    formik.setFieldValue(keyname, date);
  };
  return (
    <>
      <button onClick={open} className='btn btn-primary'>
        Cancel Refund
      </button>
      {
        <Modal>
          <div
            className='Filter_popUP cancellation-popup col-md-12'
            style={{ maxWidth: '60vw' }}
          >
            <h4>Cancellation</h4>
            <form onSubmit={formik.handleSubmit}>
              {Object.values(FIELDS).map((fieldItem) => {
                const {
                  subType,
                  label,
                  keyName,
                  required,
                  elementType,
                  isMulti,
                  maxLength,
                  ...restProps
                } = fieldItem;

                switch (elementType) {
                  case 'input':
                    return (
                      <Wrapper
                        key={keyName}
                        item={fieldItem}
                        containerClass='row justify-content-between'
                      >
                        <InputText
                          id={keyName}
                          name={keyName}
                          type={subType}
                          onChange={onChangeInput}
                          value={formik.values[keyName] || ''}
                          onBlurCallback={onBlurCallback}
                          isError={
                            formik.touched[keyName] && formik.errors[keyName]
                              ? true
                              : false
                          }
                          errorMessage={
                            formik.touched[keyName] && formik.errors[keyName]
                              ? formik.errors[keyName]
                              : null
                          }
                          version='v2'
                          {...restProps}
                        />
                      </Wrapper>
                    );

                  case 'select':
                    return (
                      <Wrapper key={keyName} item={fieldItem}>
                        <SelectComponent
                          id={keyName}
                          name={keyName}
                          optionsList={options[keyName]}
                          placeholder={label}
                          defaultValue={formik.values[keyName] || null}
                          value={formik.values[keyName] || null}
                          onChange={onChangeSelect}
                          className='form-control'
                          isMulti={isMulti || false}
                          version='v2'
                          errorMessage={
                            formik.touched[keyName] && formik.errors[keyName]
                              ? formik.errors[keyName]
                              : null
                          }
                        />
                      </Wrapper>
                    );

                  case 'date':
                    return (
                      <Wrapper key={keyName} item={fieldItem}>
                        <div className='datepicker_custom'>
                          <CustomDatePicker
                            name={keyName}
                            selected={formik.values[keyName]}
                            onDateChange={handleDateChange}
                          />
                        </div>
                      </Wrapper>
                    );
                  default:
                    return null;
                }
              })}
              <CustomButton type='submit' label='Submit' />
            </form>
          </div>
        </Modal>
      }
    </>
  );
};

export default CFinanceRefundCancelledModal;
