import React, {Fragment} from "react";
import withAdminLayout from "../../../shared/hoc/admin-layout";

const Dashboard = () => {
  return (
    <Fragment>
      <h1>Welcome to C2C Admin Panel</h1>
    </Fragment>
  )
}

export default withAdminLayout(Dashboard);
