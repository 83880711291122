import React from 'react';
import qs from 'qs';
import { Redirect, withRouter } from 'react-router-dom';
import './style.css';
import locale from '../../utils/locale';
import CButton from '../button';
import globalStyles from '../../utils/global.module.css';

const Unauthorised = (props) => {
  const queryParams = qs.parse(window.location.search.substring(1));
  const onRetry = () => {
    if (queryParams.return_url) {
      // return <Redirect to={queryParams.return_url} />;
      window.location.href = queryParams.return_url;
    }
  };

  const redirectToLogin = () => {
    return (window.location.href = '/login');
  };

  const redirectToHome = () => {
    console.log('props', props);
    props.history.push('/');
  };
  return (
    <>
      <div className='col-12'>
        <div className={`${globalStyles.mv20} ${globalStyles.textCenter}`}>
          {locale.UNAUTHORISED} Please try following options
        </div>
      </div>
      <div className='center'>
        <CButton
          label='Retry'
          onClick={onRetry}
          className={`${globalStyles.mh10}`}
        />
        <CButton
          label='Login'
          onClick={redirectToLogin}
          className={`${globalStyles.mh10}`}
        />
        <CButton
          label='Home'
          onClick={redirectToHome}
          className={`${globalStyles.mh10}`}
        />
      </div>
    </>
  );
};

export default withRouter(Unauthorised);
