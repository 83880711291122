import React, { useState, useEffect } from 'react';
import columnDefs from './config/columnDefs';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import GridView from '../common/grid-view';
import { DEFAULT_PAGE_SIZE } from '../../constants/constants';
import CustomPagination from '../custom-pagination';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';

import './style.css';

const CityGrid = function (props) {
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [recordsOnPage, setRecordsOnPage] = useState(0);
  const [totalRecords, SetTotalRecords] = useState(0);
  const [rowData, setRowData] = useState();
  const [queryParams, setQueryParams] = useState({});
  const [loaderDisabled, setLoaderDisabled] = useState(false);

  useEffect(() => {
    props
      .fetchCityGridInfoConnected()
      .then((response) => {
        setRowData(response.response);
        setPage(response.page);
        setTotalPages(response.totalPages);
        SetTotalRecords(response.totalRecords);
        setRecordsOnPage(response.response ? response.response.length : 0);
      })
      .catch((err) => {});
  }, []);
  const onRouteId = (data) => {
    const route = `/city/${data.city_id}`;
    props.history.push(route);
  };

  const handlePageChange = (pageNumber, params) => {
    setLoaderDisabled(true);
    const qs = params ? params : queryParams;
    let query = { ...qs };
    query.page = pageNumber;
    query.size = DEFAULT_PAGE_SIZE;
    props
      .fetchCityGridInfoConnected(query)
      .then((response) => {
        setPage(response.page);
        setRowData(response.response);
        setTotalPages(response.totalPages);
        SetTotalRecords(response.totalRecords);
        setRecordsOnPage(response.response ? response.response.length : 0);
      })
      .catch((err) => {});
  };

  const onFilterChanged = (params = {}) => {
    setQueryParams(params);
    handlePageChange(0, params);
  };
  return (
    <LoaderWithAccessControl disabled={loaderDisabled}>
      {rowData && (
        <div>
          <div className='row'>&nbsp;</div>
          <div className='row'>
            <div className='col-md-1 pull-right'></div>
            <br />
          </div>
          <div className='ag-theme-balham ag-grid-outer-wrapper ag-grid-paginator-wrapper'>
            <GridView
              columnDefs={columnDefs}
              rowData={rowData && rowData}
              onRedirect={onRouteId}
              uniqueIdName={'city_id'}
              onFilterChanged={onFilterChanged}
              uniqueGridId={'city'}
            />
          </div>
          <CustomPagination
            page={page}
            totalRecords={totalRecords}
            handlePageChange={handlePageChange}
            recordsOnPage={recordsOnPage}
            totalPages={totalPages}
          />
        </div>
      )}
    </LoaderWithAccessControl>
  );
};

export default withRouter(CityGrid);
