export const FIELDS = [
  {
    label: 'Seller Name',
    fieldName: 'ownerName',
    type: 'text',
    autoComplete: 'off',
    valueKey: 'ownerName',
    readOnly: true,
    readOnlyConditional: true
  },
  {
    label: 'Seller Mobile Number',
    fieldName: 'ownerPrimaryPhone',
    type: 'number',
    autoComplete: 'off',
    valueKey: 'ownerPrimaryPhone',
    readOnly: true,
    readOnlyConditional: true
  },
  {
    label: 'Secondary Phone Number',
    fieldName: 'ownerSecondaryPhone',
    type: 'number',
    autoComplete: 'off',
    valueKey: 'ownerSecondaryPhone',
    readOnly: true,
    readOnlyConditional: true
  },
  {
    label: 'Seller Email',
    fieldName: 'email',
    type: 'email',
    autoComplete: 'off',
    valueKey: 'email',
    readOnly: true,
    readOnlyConditional: true
  },
  {
    label: 'Secondary Email',
    fieldName: 'secondaryEmail',
    type: 'email',
    autoComplete: 'off',
    valueKey: 'secondaryEmail',
    readOnly: true,
    readOnlyConditional: true
  },
  {
    label: 'Current Address Line 1',
    fieldName: 'owner_address_line_1',
    type: 'textarea',
    autoComplete: 'off',
    valueKey: 'owner_address_line_1',
    readOnly: true,
    readOnlyConditional: true
  },
  {
    label: 'Current Address Line2',
    fieldName: 'owner_address_line_2',
    type: 'textarea',
    autoComplete: 'off',
    valueKey: 'owner_address_line_2',
    readOnly: true,
    readOnlyConditional: true
  },
  {
    label: 'Landmark',
    fieldName: 'landmark',
    type: 'text',
    autoComplete: 'off',
    valueKey: 'landmark',
    readOnly: true,
    readOnlyConditional: true
  },
  {
    label: 'C2C Quote',
    fieldName: 'car24Price',
    type: 'number',
    autoComplete: 'off',
    valueKey: 'car24Price',
    readOnly: true,
    readOnlyConditional: false
  },
  {
    label: 'Listing Price',
    fieldName: 'listingPrice',
    type: 'number',
    autoComplete: 'off',
    valueKey: 'listingPrice',
    readOnly: true,
    readOnlyConditional: false
  },
  // {
  //   label: 'C24 Quote',
  //   fieldName: 'c24Quote',
  //   type: 'number',
  //   autoComplete: 'off',
  //   valueKey: 'c24Quote',
  //   readOnly: false,
  //   readOnlyConditional: false
  // },
  {
    label: 'TP',
    fieldName: 'targetPrice',
    type: 'number',
    autoComplete: 'off',
    valueKey: 'tpPrice',
    readOnly: true,
    readOnlyConditional: false
  },
  {
    label: 'PAI',
    fieldName: 'postAuctionIncrement',
    type: 'number',
    autoComplete: 'off',
    valueKey: 'paiPrice',
    readOnly: true,
    readOnlyConditional: false
  },
  {
    label: 'Original LP',
    fieldName: 'originalPrice',
    type: 'number',
    autoComplete: 'off',
    valueKey: 'originalPrice',
    readOnly: true,
    readOnlyConditional: false
  }
];
