import React, { useState, useEffect } from 'react';
import {
  isArrayEmpty,
  getFilterListData,
  getParsedSelectOptions
} from '../../../shared/utils/helper';
import { SelectComponent } from '../../../client/components/shared';
import CustomButton from '../../../shared/components/button';
import ArrowIcon from '../../../shared/arrow';
import { Spinner } from 'reactstrap';
import Accordion from '../../../shared/accordion';
import Locale from '../../../shared/utils/locale';
import InputText from '../../../shared/components/input-text';
const CityStoreConfig = ({
  storeInfo,
  zoneInfo,
  fetchZonesListConnected,
  editInspectionStoreConnected
}) => {
  const [cityStoreInfo, setCityStoreInfo] = useState();
  const [options, setOptions] = useState({});
  const [zoneIndex, setZoneIndex] = useState(null);
  const [payload, setPayload] = useState();
  useEffect(() => {
    if (!isArrayEmpty(zoneInfo.data)) {
      const zoneOptions = getParsedSelectOptions(zoneInfo.data, {
        labelKey: 'zone',
        valueKey: 'id'
      });
      setOptions({
        ...options,
        zone_id: zoneOptions
      });
    } else if (zoneInfo.isError) {
      setOptions({
        ...options,
        zone_id: []
      });
    }
  }, [zoneInfo]);
  useEffect(() => {
    storeInfo && setCityStoreInfo(storeInfo);
    fetchZonesListConnected();
  }, []);

  useEffect(() => {
    storeInfo && setCityStoreInfo(storeInfo);
  }, [storeInfo]);
  const onChangeSelect = (selected, index, keyName) => {
    if (selected) {
      let storeData = [...cityStoreInfo];
      const currentState = storeData[index];
      const payload = { ...currentState, zone_id: selected.value };

      setPayload(payload);
    } else {
      setPayload(null);
    }
  };

  const onChange = (e, index) => {
    const { name, value } = e.target;
    let storeInfoCp = [...cityStoreInfo];
    storeInfoCp[index] = { ...storeInfoCp[index], [name]: value };
    setCityStoreInfo(storeInfoCp);
    setPayload(storeInfoCp[index]);
    setZoneIndex(index);
    if (!value) {
      setPayload(null);
    }
  };

  const onSubmit = (index) => {
    editInspectionStoreConnected(payload)
      .then((response) => {
        let storeData = [...cityStoreInfo];
        storeData[index]['zone_id'] = payload.zone_id;
        setCityStoreInfo(storeData);
        setZoneIndex(null);
        setPayload(null);
      })
      .catch(() => {
        setZoneIndex(null);
        setPayload(null);
      });
  };
  const updateZone = (index) => {
    setCityStoreInfo(storeInfo);
    setZoneIndex(index);
    const currentState =
      zoneIndex !== index ? 'Update' : payload ? 'Save' : 'Cancel';
    if (currentState === 'Save') {
      onSubmit(index);
    } else if (currentState === 'Cancel') {
      setZoneIndex(null);
      setCityStoreInfo(storeInfo);
    }
  };
  return (
    <Accordion>
      {({ onClick, currentVisibleStates }) => (
        <Accordion.Content>
          <div className='borderWrapper'>
            <Accordion.Heading>
              <h2
                className='font-weight-bold p0'
                onClick={() => onClick({ index: 0 })}
              >
                Store
                <ArrowIcon
                  grey={'GreyArrow'}
                  rotateBy={currentVisibleStates[0] ? 180 : 90}
                />
              </h2>
            </Accordion.Heading>
            <Accordion.Body>
              <div
                className={
                  currentVisibleStates[0]
                    ? 'accordionShow table mt20 top-border'
                    : 'accordionHide'
                }
              >
                {cityStoreInfo && cityStoreInfo.length > 0 ? (
                  <table className='table'>
                    <thead>
                      <tr>
                        <th scope='col'>Store Id</th>
                        <th scope='col'>Store Name</th>
                        <th scope='col'>Configuration (hours)</th>
                        <th scope='col'>Zone</th>
                        <th scope='col'>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cityStoreInfo &&
                        cityStoreInfo.map((item, index) => {
                          const defaultSelection = getFilterListData(
                            options['zone_id'],
                            item['zone_id']
                          );
                          return (
                            <tr key={index}>
                              <td>{item.id}</td>
                              <td>{item.store_name}</td>
                              <td>
                                <InputText
                                  type='number'
                                  name='store_config_hrs'
                                  onChange={(e) => onChange(e, index)}
                                  value={item.store_config_hrs || ''}
                                />
                              </td>
                              <td>
                                {zoneIndex !== index ? (
                                  <span>
                                    {defaultSelection
                                      ? defaultSelection[0].label
                                      : Locale.NA}
                                  </span>
                                ) : options['zone_id'] ? (
                                  <SelectComponent
                                    id={'zone_id'}
                                    name={'zone_id'}
                                    optionsList={options['zone_id']}
                                    defaultValue={getFilterListData(
                                      options['zone_id'],
                                      item['zone_id']
                                    )}
                                    value={getFilterListData(
                                      options['zone_id'],
                                      item['zone_id']
                                    )}
                                    onChange={(value) =>
                                      onChangeSelect(value, index, 'zone_id')
                                    }
                                    className='form-control inputSpace col-6'
                                    isMulti={false}
                                    version='v2'
                                  />
                                ) : (
                                  <Spinner
                                    variant='primary'
                                    animation='border'
                                    size='sm'
                                  />
                                )}
                              </td>
                              <td>
                                <CustomButton
                                  className={
                                    zoneIndex !== index
                                      ? ''
                                      : payload
                                      ? ''
                                      : 'btn-danger'
                                  }
                                  label={
                                    zoneIndex !== index
                                      ? 'Update'
                                      : payload
                                      ? 'Save'
                                      : 'Cancel'
                                  }
                                  onClick={() => updateZone(index)}
                                  type='button'
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                ) : (
                  <p>No Data available</p>
                )}
              </div>
            </Accordion.Body>
          </div>
        </Accordion.Content>
      )}
    </Accordion>
  );
};

export default CityStoreConfig;
