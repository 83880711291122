import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import Arrow from "./images/arrow.svg";
import { crmPaymentModesValues, DELIVERY_MODES } from "../Constants";
import priceFormatter from "../../../utils/price-formatter";
import {sumChargesAmount, getDiscountPrice, getAdditionalChargeAmount } from "../utility";
import Button from "../../../../shared/components/button";
import { DataService } from '../../../service';
import { DispositionConstants } from "../Constants";
// spelling check 
const OrderSummaryDetails = ({ 
  form,
  orderData,
  leadType,
  onBookingUpdated,
  setOrderData
  }) => {
    const [isViewBookingAmountClicked, setIsViewBookingAmountClicked] = useState(false);
    const { state, dispatch } = form;
    const financeOpted = !!state.paymentMode && state.paymentMode === crmPaymentModesValues.CARS24_FINANCE ? true : false;
    const [showDropDownView, setShowDropDownView] = useState(financeOpted ? true : false);
    const [showBookingAmountError, setShowBookingAmountError] = useState(false);
    const {vehiclePrice, bookingAmount, orderReward={}} = orderData || {};
    let currentOrder = !!orderData && state.orders.find(item => item.orderId === orderData.orderId);
    if (!currentOrder) {
      currentOrder = orderData;
    }
    const {insurance = {}} = currentOrder || {};
    const {chargeDetails, financeTerms } = state || {};
    const {downPayment, emiAmount, tenure, chargeDetails: finCharges} = financeTerms || {}
    const totalProcessingFee = sumChargesAmount(finCharges);
    const shippingCharge = getAdditionalChargeAmount(chargeDetails, "shipping_charge");
    const interStateRCTransferAmount = getAdditionalChargeAmount(chargeDetails, "rc_charge");
    const discount = getDiscountPrice(orderReward);
    const insurancePrice = !!insurance && !!insurance.price && insurance.price > 0 ? insurance.price : 0;
    const finalAmountPayable = financeOpted ? vehiclePrice + totalProcessingFee + shippingCharge + interStateRCTransferAmount + insurancePrice - discount : vehiclePrice + shippingCharge + interStateRCTransferAmount + insurancePrice - discount;
    
    useEffect(() => {
      return () => {
          document.removeEventListener("click", handleClickOutside, false);
      };
    }, []);

    const handleClickOutside = event => {
        setShowDropDownView(false);
    };

    useEffect(()=> {
      if (financeOpted) {
        setShowDropDownView(true);
      } else {
        setShowDropDownView(false);
      }
    },[financeOpted]);

    const onUpdateBookingAmount = (bookingAmount) => {
      setShowBookingAmountError(false);
      dispatch({
        type: "bookingAmount",
        bookingAmount
      });
      bookingAmount === 0 && dispatch({
        type: "isCompleteBookingAllowed",
        isCompleteBookingAllowed: true
      });
    };

    const getBookingAmount = () => {
      onBookingUpdated();
      let isValidPaymentMode = false;
      let isValidDeliveryMode = false;
      if (state.paymentMode === crmPaymentModesValues.CARS24_FINANCE && !!state.financeTerms) {
        isValidPaymentMode = true; 
      }
      if (state.paymentMode === crmPaymentModesValues.PAY_ON_DELIVERY) {
        isValidPaymentMode = true; 
      }

      if (state.deliveryMode === DELIVERY_MODES.HOME_DELIVERY) {
        const {date = '', slots} = state.deliveryTime || {};
        if (!!state.deliveryPincode && !!state.deliveryAddress1 && !!state.deliveryAddress2 &&
          !!state.deliveryState && !!state.deliveryCity && !!date && !!slots && slots.length > 0) {
            isValidDeliveryMode = true;
        }
      } else if (state.deliveryMode === DELIVERY_MODES.PICK_UP && state.deliveryPincode) {
        const {date = '', slots} = state.deliveryTime || {};
        if (!!date && !!slots && slots.length > 0) {
          isValidDeliveryMode = true;
        }
      }

      const sessionOrderIndex = state.orders.findIndex(item => item.orderId === currentOrder.orderId);
      if (isValidDeliveryMode && isValidPaymentMode) {
        if (sessionOrderIndex > -1) {
          setIsViewBookingAmountClicked(true);
          DataService.updateCCOBBookingData(state).then(resp => {
            const {bookingAmount=0} = resp;
            setOrderData(resp);
            onUpdateBookingAmount(bookingAmount);
            //reset cache orders session after submit
            const orders = state.orders.filter(item => item.orderId !== currentOrder.orderId);
            dispatch({
              type: "orders",
              orders: orders
            });
          }).catch(err => {
            setShowBookingAmountError(true);
          }).finally(()=>{
            setIsViewBookingAmountClicked(false);
          })
        } else {
          const {bookingAmount=0} = currentOrder;
          onUpdateBookingAmount(bookingAmount);
        }
      }
      else if (!isValidDeliveryMode || !isValidPaymentMode) {
        setShowBookingAmountError(true);
      }
    };
    return (
      <>
        <div className={styles.ordersummary}>
          <h2 className={styles.title}>Order Summary</h2>
          <div stylename={styles.orderSummaryUnder}>
            <div className={styles.orderSummaryWrapper}>
              {/* <strong styleName={styles.price}>{"Final amount payable"}
                  {!financeOpted && <small styleName={styles.subText}>No financing selected</small> }
              </strong>
              <strong styleName={styles.price}>Rs 2,00,000</strong> */}
              <div className={`${styles.finalAmountHeaderWrapper} ${showDropDownView ? styles.active : ''}`}>
                <div className={styles.finalAmountHeader}>
                  <div className={styles.arrowWrapper}>
                    <p>Final amount payable</p>
                    {financeOpted && <img src={Arrow} alt="arrow" onClick={() => setShowDropDownView(!showDropDownView)} />}
                  </div>
                  <span>Rs. {priceFormatter(finalAmountPayable)}</span>
                </div>
                <p className={styles.text}>{financeOpted ? `financing @ Rs. ${priceFormatter(emiAmount)} per month for ${tenure / 12} yrs` : `No financing selected`}</p>

                <div className={styles.finalAmountHeaderContent}>
                  <div className={styles.priceList}>
                    <div className={styles.servicesAmount}>
                        <p>Car Price</p>
                        <span>Rs. {priceFormatter(vehiclePrice)}</span>
                    </div>
                    <ul>
                      <li>
                        <p>Down Payment</p>
                        <span>Rs. {priceFormatter(downPayment)}</span>
                      </li>
                      <li>
                        <p>Load Amount with Charges</p>
                        <span>Rs. {priceFormatter(vehiclePrice - downPayment + totalProcessingFee)}</span>
                      </li>
                      {discount > 0 && <li>
                        <p>Discount</p>
                        <span>Rs. {priceFormatter(discount)}</span>
                      </li>}
                    </ul>
                  </div>

                  <div className={styles.priceList}>
                    <div className={styles.servicesAmount}>
                        <p>Financing Charges</p>
                        <span>Rs. {priceFormatter(totalProcessingFee)}</span>
                    </div>
                    <ul>
                      {
                        !!finCharges && finCharges.map((charge, index) => {
                            return (
                                <li key={index}>
                                    <p>{charge.description}</p>
                                    <span>Rs. {priceFormatter(charge.amount)}</span>
                                </li>
                            );
                        })
                      }
                    </ul>
                  </div>
                </div>
              </div>

              <div className={styles.payAble}>
                  <p>{state.lmsStatus === "Completed" ? "Paid Already" : "Payable Now"}</p>
                  <span>Rs. {priceFormatter(state.lmsStatus === "Completed" ?  bookingAmount : state.bookingAmount)}</span>  
                  {((leadType === DispositionConstants.CCOB_ASSISTED_BOOKING || leadType === DispositionConstants.CC_OUTBOUND_BUYER) && state.lmsStatus === "Pending" )&& <Button label="View Booking Amount" onClick={()=> getBookingAmount()} disabled={isViewBookingAmountClicked}></Button>}
              </div>
              {(leadType === DispositionConstants.CCOB_ASSISTED_BOOKING || leadType === DispositionConstants.CC_OUTBOUND_BUYER) && state.lmsStatus === "Pending" && (showBookingAmountError && <p className={styles.errortext}>{`Please fill checkout detail first.`}</p>)}
            </div>
          </div>
        </div>
      </>
    );
};

export default OrderSummaryDetails;
