import { config } from './../config/local';
import { buildQuery } from '../../services/helper';
import { IS_ENV_DEV, IS_MOCK } from '../../shared/utils/constants';
export default (api) => {
  const getDealerFormData = () => {
    const path = config.api.c2c.dealerForm.path;
    const url = `${path}`;
    return api.get(url);
  };

  const fetchDealerGridInfo = (params = { page: 0, size: 10 }) => {
    const path = config.api.c2c.dealers.path;
    const queryStr = params ? `?${buildQuery(params)}` : '';
    const url = path + queryStr;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };
  const getArDealer = (dealerId) => {
    const path = config.api.c2c.arDealerGrid.path;
    const url = `${path}/${dealerId}`;
    return api
      .get(url)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        if (data) {
          return data;
        } else {
          return {
            response: []
          };
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  const getDealerDetailById = (dealerId) => {
    const path = config.api.c2c.dealers.path;
    const url = `${path}/${dealerId}`;
    return api.get(url);
  };

  const addDealer = (data) => {
    const path = config.api.c2c.dealers.path;
    const url = `${path}`;
    return api.post(url, data);
  };

  const fetchArDealerById = (arId) => {
    const path = config.api.c2c.arDealer.path;
    const url = `${path}s/${arId}`;
    return api.get(url);
  };
  const addArDealer = (data) => {
    const path = config.api.c2c.arDealer.path;
    const url = `${path}`;
    return api.post(url, data);
  };
  return {
    getDealerFormData,
    getDealerDetailById,
    addDealer,
    fetchArDealerById,
    addArDealer,
    fetchDealerGridInfo,
    getArDealer
  };
};
