import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { getIsLoggedInFromLocalStorage, removeDataFromLocalStorage, getAuthorisedRoutes } from '../../../shared/utils/helper';

const Home = () => {
  const isLoggedIn = getIsLoggedInFromLocalStorage();
  if (isLoggedIn) {
    return (
      <div>
        <div>
          <h4>Welcome to C2C</h4>
        </div>
        <div>
          {/* <h2>CC-Agent, Konnect and Admin-Panel</h2> */}
        </div>

        {/* {getAuthorisedRoutes('visible').map((item, key) => {
          return <p key={'route_' + key}><Link to={item.path}>{item.label}</Link></p>
        })} */}
      </div>
    );
  } else {
    removeDataFromLocalStorage();
    return <Redirect to='/login' />
  }
}

export default Home