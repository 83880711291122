import { showToastMessages } from '../../shared/utils/helper';
import {
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected,
  handleHttpStatusError
} from '../../services/helper';
import { config } from './../config/local';
export default (api) => {
  const getQcPanel = (id, is_refresh) => {
    dispatchSetLoaderConnected();
    const url = config.api.c2c.rcQcPanel.path;
    return api
      .get(`${url}/${id}?isRefresh=${is_refresh}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        dispatchResetLoaderConnected();
        return data;
      })
      .catch((error) => {
        handleHttpStatusError(error);
        return Promise.reject(error);
      });
  };
  const refreshQcPanel = (id, is_refresh) => {
    // dispatchSetLoaderConnected();
    const url = config.api.c2c.rcQcPanel.path;
    return api
      .get(`${url}/${id}?isRefresh=${is_refresh}`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        // dispatchResetLoaderConnected();
        return data;
      })
      .catch((error) => {
        // handleHttpStatusError(error);
        return Promise.reject(error);
      });
  };

  const getQcBuyerInsurance = (id) => {
    return new Promise((resolve, reject) => {
      api.get(`${config.api.c2c.qcBuyerInsurance.path}/${id}`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      })
    });
  };

  const getRcQuestion = (id, withLoader = true) => {
    const url = config.api.c2c.rcQuestion.path;
    withLoader && dispatchSetLoaderConnected();
    return api
      .get(`${url}/${id}`)
      .then((resp) => {
        withLoader && dispatchResetLoaderConnected();
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        handleHttpStatusError(error);
        return Promise.reject(error);
      });
  };

  const getOthersRcQuestion = (id, withLoader = true) => {
    const url = config.api.c2c.rcOthersQuestion.path;
    withLoader && dispatchSetLoaderConnected();
    return api
      .get(`${url}/${id}`)
      .then((resp) => {
        withLoader && dispatchResetLoaderConnected();
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        handleHttpStatusError(error);
        return Promise.reject(error);
      });
  };

  const submitRcCaseData = (method, data) => {
    const url = config.api.c2c.rcQuestion.path;
    return api[method](`${url}`, data)
      .then((resp) => {
        const {
          data: { data, message }
        } = resp;
        showToastMessages(message);
        return data;
      })
      .catch((error) => {
        return error;
      });
  };

  const submitOthersRcCaseData = (method, data) => {
    const url = config.api.c2c.submitOtherRcCaseType.path;
    return api[method](`${url}`, data)
      .then((resp) => {
        const {
          data: { data, message }
        } = resp;
        showToastMessages(message);
        return data;
      })
      .catch((error) => {
        return error;
      });
  };

  const releaseBlockHoldback = (data, method = 'put') => {
    const url = config.api.c2c.rcReleaseBlockHoldback.path;
    return api[method](`${url}`, data)
      .then((resp) => {
        const {
          data: { data, message }
        } = resp;
        showToastMessages(message);
        return data;
      })
      .catch((error) => {
        return error;
      });
  };

  const deleteBuyerInsurance = (fileId,dealId) => {
    return new Promise((resolve, reject) => {
      api.delete(`/buyer-insurance/file/${fileId}?dealId=${dealId}`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      })
    });
  };

  const uploadBuyerInsuranceFile = (id, params) => {
    return new Promise((resolve, reject) => {
      api.post(`/buyer-insurance-file/${id}`, params).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      })
    });
  };

  const saveBuyerInsurance = (id, expDate, insuranceProvider,insuranceId) => {
    const param = !!insuranceId ? `&insuranceId=${insuranceId}` : '';
    return new Promise((resolve, reject) => {
      api.post(`/buyer-insurance/${id}?insuranceExpiryDate=${expDate}&insuranceProvider=${insuranceProvider}${param}`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      })
    });
  };
  
  return {
    saveBuyerInsurance, 
    uploadBuyerInsuranceFile,
    deleteBuyerInsurance,
    getQcBuyerInsurance,
    getRcQuestion,
    submitRcCaseData,
    getQcPanel,
    refreshQcPanel,
    releaseBlockHoldback,
    getOthersRcQuestion,
    submitOthersRcCaseData
  };
};
