const parseById = (data = []) => {
  const byId = {};
  const allIds = [];

  data.forEach(item => {
    allIds.push(item.id);
    byId[item.id] = item;
  });

  return { byId, allIds };
};

export default parseById;
