import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PricingLogs from './component';
import { fetchPricingLogsConnected } from './actions';

const mapStateToProps = ({pricingLogs}) => ({
  pricingLogs
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPricingLogsConnected: fetchPricingLogsConnected
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PricingLogs);
