import { useEffect, useState } from "react";
import { docketManagementService, documentRuleEngineService } from "../../services";

export const useDockerManagement = () => {
  const [statusDropdownOptions, setStatusDropdownOptions] = useState([]);
  const [rejectionReasonsByDocument, setRejectionReasonsByDocument] = useState({});

  const fetchDocumentConfig = () => {
    docketManagementService.fetchDocketManagementDocumentsConfig()
      .then((data) => {
        setStatusDropdownOptions(data.documentStatus);
      })
  };

  const fetchRejectionReasonsByDocument = () => {
    documentRuleEngineService.fetchRejectionReasonsByDocument()
      .then((data) => {
        setRejectionReasonsByDocument(data);
      })
  };

  useEffect(() => {
    fetchDocumentConfig();
    fetchRejectionReasonsByDocument();
  }, []);
       
  return { statusDropdownOptions, rejectionReasonsByDocument };
}