import React, { Component } from 'react';

// import CarDetails from './carDetails';
import OrdersCarInformation from '../orders-car-information';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OrderActions, InventoryActions } from './../../actions';

// import Publish from './publish';
// import OrderPublishTab from '../order-publish-tab';

import OrderUpdateTab from '../order-update-tab';

// import Inspection from './inspection';
import InspectionTab from '../order-inspection-tab';
import DealerDetail from './dealerDetail';
// import Inventory from './inventory';
import Inventory from '../order-inventory-tab';
import { CLASSIFIED_CAR, PUBLISH } from './constant';

import withAdminLayout from '../../../shared/hoc/admin-layout';
import { withAuthorization } from '../../../shared/utils/firebase';
import CarImages from '../car-images';

/* Todo refactor Order seller tab */
import OrderSellerTab from '../order-seller-tab';
// import Seller from './seller';

const TABS = {
  OrdersCarInformation,
  OrderSellerTab,
  OrderUpdateTab,
  // OrderPublishTab,
  InspectionTab,
  DealerDetail,
  Inventory,
  CarImages
};
// prettier-ignore
const TAB_PROPS = {
  OrdersCarInformation: { label: 'Car Information', isActiveKey: 'active' },
  OrderSellerTab: { label: 'Seller Information', isActiveKey: 'active' },
  OrderUpdateTab: { label: 'Update Details', isActiveKey: 'active' },
  // OrderPublishTab: { label: 'Publish/Archive', isActiveKey: 'active' },
  InspectionTab: { label: 'Inspection Details', isActiveKey: 'active' },
  DealerDetail: { label: 'Dealer Details', isActiveKey: 'dealerTabCheck' },
  // Inventory: {label: 'Inventory Management Details',isActiveKey: 'inventoryTabCheck'},
  CarImages: { label: 'Car Image', isActiveKey: 'imageTabCheck' }
};

class Stock extends Component {
  state = {
    selectedIndex: 0
  };

  //handle on click
  handleOnClick = (index) => {
    this.setState({ selectedIndex: index });
  };

  //Get order inspection details
  getOrderInspectionDetails = () => {
    const appointmentId = this.props.match.params.id;
    this.props.getOrderInspectionDetails(appointmentId);
  };

  //Get inventory log details
  getInventoryLogDetails = () => {
    const appointmentId = this.props.match.params.id;
    this.props.getInventoryLog(appointmentId);
  };

  renderTabs = (tabChecks) => {
    return (
      <ul className='nav nav-tabs'>
        {Object.values(TAB_PROPS).map((item, index) => {
          if (tabChecks[item.isActiveKey]) {
            return (
              <li
                key={index}
                className={this.state.selectedIndex === index ? 'active' : ''}
                onClick={() => this.handleOnClick(index)}
              >
                <a href='#car' data-toggle='tab' aria-expanded='true'>
                  <b>{item.label}</b>
                </a>
              </li>
            );
          } else {
            return null;
          }
        })}
      </ul>
    );
  };

  //Render
  render() {
    const imageTabCheck =
      this.props.orderDetails &&
      this.props.orderDetails.carSource === CLASSIFIED_CAR &&
      this.props.orderDetails.orderState !== PUBLISH;
    const inventoryTabCheck =
      this.props.orderDetails && this.props.orderDetails.carSource;
    const dealerTabCheck =
      this.props.orderDetails && this.props.orderDetails.isTopSelling === true;

    const tabChecks = {
      active: true,
      imageTabCheck,
      inventoryTabCheck,
      dealerTabCheck
    };
    return (
      <React.Fragment>
        <div className='col-md-12'>
          <div className='nav-tabs-custom no-box-shadow '>
            {this.renderTabs(tabChecks)}

            <div className='tab-content'>
              {Object.keys(TAB_PROPS).map((tabName, index) => {
                const isTabActive =
                  tabChecks[TAB_PROPS[tabName]['isActiveKey']];
                if (isTabActive) {
                  return (
                    <CTab
                      key={index}
                      selectedIndex={this.state.selectedIndex}
                      activeIndex={index}
                      tabName={tabName}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// Seller.propTypes = {};
const mapStateToProps = ({ order, inventory }) => {
  const { inspection } = order;
  const dealerDetails = order
    ? order.order
      ? order.order.detail
        ? order.order.detail.dealerDetails
        : []
      : []
    : [];

  const inventoryLogDetail = inventory ? inventory.inventoryLogDetail : [];
  const orderDetails = order ? (order.order ? order.order.detail : []) : [];
  return {
    order,
    inspection,
    dealerDetails,
    orderDetails,
    inventoryLogDetail
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOrderInspectionDetails: OrderActions.fetchOrderInspectionDetail,
      getInventoryLog: (appointmentId) =>
        InventoryActions.getInventoryLog(appointmentId)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAdminLayout(withAuthorization(() => true)(Stock)));

/* (Sprint-8) creating temporary components as this file will refactored again   */
// const CustomTab = (props) => {
//   const { selectedIndex, activeIndex } = props;
//   if (activeIndex === selectedIndex) {
//     return (
//       <div
//         className={
//           activeIndex === selectedIndex ? 'tab-pane active' : 'tab-pane'
//         }
//       >
//         {props.children}
//       </div>
//     );
//   } else {
//     return null;
//   }
// };

/* (Sprint-8) creating temporary components as this file will refactored again   */
const CTab = (props) => {
  const { selectedIndex, activeIndex, tabName } = props;
  const TabView = TABS[tabName];
  if (activeIndex === selectedIndex) {
    return (
      <div
        className={
          activeIndex === selectedIndex ? 'tab-pane active' : 'tab-pane'
        }
      >
        <TabView />
      </div>
    );
  } else {
    return null;
  }
};
