import React from "react";
import ArDealerView from "../../components/ar-dealer-view";
import withAdminLayout from "../../../shared/hoc/admin-layout";
import ErrorBoundary from "../../components/common/errorBoundary"
import { withAuthorization } from "../../../shared/utils/firebase";

const ArDealer = (props) => {
    return (
        <div>
            <ErrorBoundary>
                <ArDealerView {...props}/>
            </ErrorBoundary>
        </div>
    );
};
export default withAdminLayout(withAuthorization(()=>true)(ArDealer));
