import * as Yup from 'yup';
export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is Required')
    .matches(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z ]*)*$/, 'Name is not valid'),
  emailId: Yup.string()
    .email('Invalid email format')
    .required('Email is Required'),
  phone: Yup.string()
    .matches(/^[6789]\d{9}$/, 'Phone number is not valid')
    .required('Phone number is Required'),
  status: Yup.string().required('Status is Required').nullable(),
  role: Yup.string()
    .required('C2C LMS Role is Required')
    .nullable(),
});