import { useState } from "react";
import { rcManagementService } from "../../../services"

export const useRcOthersDealInfo = () => {
  const getDealCancelReasons = rcManagementService.getRcOthersDealCancelReasons;

  const [dealCancelReasonsOptions, setDealCancelReasonsOptions] = useState([]);

  const fetchDealCancelReasons = () => {
    getDealCancelReasons()
      .then((resp) => {
        if (resp) {
          setDealCancelReasonsOptions(resp.map((cr) => ({ value: cr, label: cr })));
        }
      })
  };

  return { dealCancelReasonsOptions, fetchDealCancelReasons };
}