import React from 'react';
import Select from 'react-select';
import isNil from 'lodash/isNil';

const MultiSelectRenderer = ({
  dataItem: data,
  field: attributeKey,
  isMulti = false,
  options = [],
  handleMultiSelectChange
}) => {
  const handleChange = (selectedVals) => {
    handleMultiSelectChange(attributeKey, selectedVals === null ? [] : selectedVals, data);
  };

  const getValue = () => {
    if(!data || !Object.keys(data).length || !options.length || isNil(data[attributeKey])) return;

    if(isMulti) {
      return data[attributeKey].map(docId => ({
        label: options.find(o => o.value === docId).label,
        value: docId
      }));
    } else {
      const option = options.find(o => o.value === data[attributeKey]);
      if (option) {
        const { label, value } = option;
        return { label, value };
      } else {
        return null;
      }
    }
  }

  return (
    <td>
      <Select
        classNamePrefix="dreMultiselect"
        styles={{ menuPortal: (provided) => ({ ...provided, zIndex: 10000000 }) }}
        menuPortalTarget={document.body}
        menuPlacement="auto"
        isMulti={isMulti}
        options={options}
        onChange={handleChange}
        value={getValue()}
        hideSelectedOptions={false}
      />
    </td>
  );
};

export default MultiSelectRenderer;
