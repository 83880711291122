import React, { useState, useEffect } from 'react';
import DealInformation from '../rc-tracker-deal-info';
import { withRouter } from 'react-router-dom';
import { TABS } from './constant';
import RcCaseType from '../rc-casetype';
import RcTracker from '../rc-tracker';
import RcDocuments from '../rc-documents';
import DeChecklist from '../de-checklist';
import Card from '../../../shared/card';
import { formatDate } from '../../../shared/utils/helper';
import { useIsRcOthers } from '../../../shared/hooks';
import RcVerify from '../rc-verify';
import { rcManagementService } from '../../../services';
function RcManagementTabs({ location, dealInfoData }) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [delayData, setDelayData] = useState({});
  const handleOnClick = (index) => {
    setSelectedIndex(index);
  };
  const isOtherRcView = useIsRcOthers();
  useEffect(() => {
    rcManagementService.getRcMgmtDelayReasons().then((resp)=>{
      setDelayData(resp.data);
    });
    
  }, [])
  
  useEffect(() => {
    if (location.state && location.state.openTabKey) {
      setSelectedIndex(location.state.openTabKey);
    }
  }, []);

  const tabs = () => {
    return (
      <ul className='nav nav-tabs'>
        {Object.keys(TABS).map((tabKey, index) => {
            return (isOtherRcView && tabKey === "deChecklist") ? null : (
              <li
                key={index}
                className={selectedIndex === index ? 'active' : ''}
                onClick={() => handleOnClick(index)}
              >
                <a>
                  <b>{TABS[tabKey]['label']}</b>
                </a>
              </li>
            );
        })}
      </ul>
    );
  };
  const cardList = [
    {
      title: 'Token Date',
      value: '',
      boxBg: 'bg-blue',
      key: 'tokenDate',
      parser: (val) => val ? formatDate(val) : "--"
    },
    {
      title: 'UID',
      value: 'DEL#10000',
      boxBg: 'bg-sky-blue',
      key: 'dealIdentifier'
    },
    {
      title: 'Segment',
      value: 'Guaranteed Sales',
      boxBg: 'bg-purple',
      key: 'displayCarSource'
    },
    {
      title: 'Case Type',
      value: '',
      boxBg: 'bg-peach',
      key: 'rcCaseType'
    },
    {
      title: 'NBFC',
      value: '',
      boxBg: 'bg-purple',
      key: 'isNbfc'
    }
  ];
  return (
    <React.Fragment>
      <div className='col-md-12'>
        <div className='nav-tabs-custom no-box-shadow'>
          {cardList.map((item, i) => (
            <span key={i}>
              <Card
                title={item.title}
                value={item.parser ? item.parser(dealInfoData && dealInfoData[item.key]) : (dealInfoData && dealInfoData[item.key]) || '--'}
                boxBg={item.boxBg}
              />
            </span>
          ))}
          {tabs()}
          <div className='tab-content'>
            {selectedIndex === 0 && (
              <div
                className={selectedIndex === 0 ? 'tab-pane active' : 'tab-pane'}
              >
                <DealInformation />
              </div>
            )}
            {selectedIndex === 1 && (
              <div
                className={selectedIndex === 1 ? 'tab-pane active' : 'tab-pane'}
              >
                <RcCaseType dealInfoData={dealInfoData} />
              </div>
            )}
            {selectedIndex === 2 && (
              <div
                className={selectedIndex === 2 ? 'tab-pane active' : 'tab-pane'}
              >
                <DeChecklist />
              </div>
            )}
            {selectedIndex === 3 && (
              <div
                className={selectedIndex === 3 ? 'tab-pane active' : 'tab-pane'}
              >
                <RcDocuments delayData={delayData}/>
              </div>
            )}
            {selectedIndex === 4 && (
              <div
                className={selectedIndex === 4 ? 'tab-pane active' : 'tab-pane'}
              >
                <RcTracker delayData={delayData}/>
              </div>
            )}
            {selectedIndex === 5 && (
              <div
                className={selectedIndex === 5 ? 'tab-pane active' : 'tab-pane'}
              >
                <RcVerify />
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default withRouter(RcManagementTabs);
