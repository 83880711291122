import { agentStatus } from '../../constants/constants';

export default [
  {
    headerName: 'Agent Name',
    // width: 100,
    resizable: false,
    field: 'name',
    renderField: 'id',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    cellRenderer: 'LinkRenderer',
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Code',
    // width: 110,
    field: 'code',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    suppressSizeToFit: false,
    // cellRenderer: 'LinkRenderer',
    floatingFilterComponentParams: { suppressFilterButton: true }
  },

  {
    headerName: 'Agent Status',
    //width: 160,
    field: 'isActive',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'payment_status',
      options: Object.values(agentStatus)
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: Object.values(agentStatus)
    },
    suppressMenu: true
  }
];
