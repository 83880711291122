export const DOCKET_MANAGEMENT_FIELDS = () => [
  {
    key: 'nameOnAadhar',
    label: 'Name on Aadhar - from OCR'
  },
  {},
  {
    key: 'addressOnAadhar',
    label: 'Address on Aadhar - from OCR'
  },
  {
    key: 'pincode',
    label: 'Pincode',
    inputFieldType: 'number',
    maxLength: 6
  },
  {
    key: 'nameOnPanCard',
    label: 'Name on PAN Card - from OCR'
  },
  {},
  {
    key: 'nameOnRc',
    label: 'Name required on RC'
  },
  {},
  {
    key: 'addressOnRc',
    label: 'Address required on RC'
  },
  {},
  {
    key: 'aadhaarNumber',
    label: 'Aadhaar Number',
    inputFieldType: 'number',
    maxLength: 12
  },
  {},
  {
    key: 'panNumber',
    label: 'Pan Number',
  },
];