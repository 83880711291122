export const FIELDS = {
  city_id: {
    label: 'City',
    keyName: 'city_id',
    type: 'select',
    isMulti: true,
    required: false,
    elementType: 'select',
    isReadOnly: false
  },
  buddy_email: {
    label: 'User Email Id',
    keyName: 'buddy_email',
    type: 'select',
    isMulti: true,
    required: false,
    elementType: 'select',
    isReadOnly: false
  },
  car_source: {
    label: 'Car Source',
    keyName: 'car_source',
    type: 'select',
    isMulti: true,
    required: false,
    elementType: 'select',
    isReadOnly: false
  },
  parking_yard_id: {
    label: 'Parking Yards',
    keyName: 'parking_yard_id',
    type: 'select',
    isMulti: true,
    required: false,
    elementType: 'select',
    isReadOnly: false
  },
  city_ops: {
    label: 'City Ops',
    keyName: 'city_ops',
    type: 'checkbox',
    required: false,
    elementType: 'checkbox',
    isReadOnly: false
  }
};

export const _FIELD_SETS = [
  [FIELDS.car_source],
  [FIELDS.buddy_email],
  [FIELDS.city_id]
];

export const FIELD_SETS = [
  [FIELDS.city_id],
  [FIELDS.buddy_email],
  [FIELDS.car_source],
  [FIELDS.parking_yard_id],
  [FIELDS.city_ops]
];
