import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  error: null,
  message: null,
  data: {}
};

export const fetchInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true, isError: false, message: null };
};

export const fetchSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return { ...state, isLoading: false, isError: false, data };
};

export const fetchFailure = (state = INITIAL_STATE, action) => {
  const { message } = action.error;
  return {
    ...state,
    data: {},
    isLoading: false,
    isError: true,
    message: message
  };
};

export const HANDLERS = {
  [Types.FETCH_INSPECTION_DETAIL_INIT]: fetchInit,
  [Types.FETCH_INSPECTION_DETAIL_SUCCESS]: fetchSuccess,
  [Types.FETCH_INSPECTION_DETAIL_FAIL]: fetchFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
