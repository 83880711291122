import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  FETCH_RCUSER_INIT
  FETCH_RCUSER_SUCCESS
  FETCH_RCUSER_FAILURE
  FETCH_RCUSER_FILTERLIST_SUCCESS
  
  
`,
  {
    prefix: 'users-rc-access/'
  }
);
