import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  error: null,
  message: null,
  data: {},
  zones: {}
};

export const fetchInit = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
    isError: false,
    message: null,
    error: null
  };
};

export const fetchSuccess = (state = INITIAL_STATE, action) => {
  const { data, message } = action.payload;
  const alteredData = data.map((item, key) => {
    return { ...item, isActive: item.isActive ? 'Active' : 'Inactive' };
  });
  return {
    ...state,
    isLoading: false,
    isError: false,
    error: null,
    data: alteredData,
    message
  };
};

export const fetchFailure = (state = INITIAL_STATE, action) => {
  const { message } = action.error;
  return {
    ...state,
    error: action.error,
    data: {},
    isLoading: false,
    isError: true,
    message: message
  };
};

export const HANDLERS = {
  [Types.FETCH_ZONES_INIT]: fetchInit,
  [Types.FETCH_ZONES_SUCCESS]: fetchSuccess,
  [Types.FETCH_ZONES_FAIL]: fetchFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
