import React, { useState, useEffect } from 'react';
import Accordion from '../../../shared/accordion';
import ArrowIcon from '../../../shared/arrow';
import TabularLogs from '../tabular-logs';
import { console_in_dev } from '../../../shared/utils/helper';

const CLogsAccordian = ({
  title = 'Logs',
  data = [],
  headers = [],
  userType,
  dealId
}) => {
  return (
    <Accordion totalItems={1}>
      {({ onClick, currentVisibleStates }) => {
        return (
          <Accordion.Content>
            <Accordion.Heading>
              <h2
                className='d-flex justify-content-between fwb sellerDocuments p0 m0'
                onClick={() => onClick({ index: 0 })}
              >
                {title}
                <ArrowIcon
                  grey={'GreyArrow'}
                  rotateBy={currentVisibleStates[0] ? 180 : 90}
                />
              </h2>
            </Accordion.Heading>
            <Accordion.Body>
              <div
                className={
                  currentVisibleStates[0]
                    ? 'accordionShow mt20 top-border'
                    : 'accordionHide'
                }
              >
                <TabularLogs
                  logKey='REMARKS'
                  headers={headers}
                  logs={data}
                  userType={userType}
                  dealId={dealId}
                />
              </div>
            </Accordion.Body>
          </Accordion.Content>
        );
      }}
    </Accordion>
  );
};

export default CLogsAccordian;
