import React from 'react'
import "./styles.css";

const Card = ({
    title,
    value,
    boxBg
}) => {
    return (
        <div className={`custom-card ${boxBg}`}>
            <h4>{title}</h4>
            <p>{value}</p>
        </div>
    )
}
export default Card;
