import React from 'react';
import { Label } from 'reactstrap';
import './style/style.css';

const InteractionJourney = ({ interactionJourney }) => {
  const showInteractionJourney = () => {
    return interactionJourney.map((item, index) =>
      <div className="row count" key={'key_' + index}>
        <div className="col-2 td">
          {new Date(item.createdOn).toLocaleString()}
        </div>
        <div className="col-2 td">
          {item.agentId}
        </div>
        <div className="col-2 td">
          {item.dispositionName}
        </div>
        <div className="col-3 td">
          {item.subDispositionName}
        </div>
        <div className="col-2 td">
          {item.agentRemark}
        </div>
        <div className="col-1 td">
          {item.dialerDisposition}
        </div>
      </div>
    )
  }

  return (
    <div className="container-fluid">
      <h2 className="journey">Interaction Journey</h2>
      <div className="section_interaction_journey">
        <div className="row">
          <div className="col-2">
              <Label for="dateAndTime" className="lbl">Date & Time</Label>
          </div>
          <div className="col-2">
              <Label for="agentIdOrName" className="lbl">Agent ID/Name</Label>
          </div>
          <div className="col-2">
              <Label for="disposition" className="lbl">Disposition</Label>
          </div>
          <div className="col-3">
              <Label for="subDisposition" className="lbl">Sub-Disposition</Label>
          </div>
          <div className="col-2">
              <Label for="remarks" className="lbl">Remarks</Label>
          </div>
          <div className="col-1">
              <Label for="state" className="lbl">State</Label>
          </div>
        </div>
        {
          showInteractionJourney()
        }
      </div>
    </div>
  );
}

export default InteractionJourney;