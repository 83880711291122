import React from "react";

const CtaRenderer = ({
  data,
  label,
  className = "btn btn-success",
  context: {
    handleCtaClick
  }
}) => {
  return (
    <button
      className={className}
      onClick={() => handleCtaClick(data)}
    >
      {label}
    </button>
  )
}

export default CtaRenderer;