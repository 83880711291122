export const FIELDS = {
  refund_reason: {
    label: 'Refund Reason',
    keyName: 'refund_reason',
    type: 'select',
    required: true,
    elementType: 'select'
  },
  utr: {
    label: 'UTR',
    keyName: 'utr',
    type: 'text',
    required: true,
    elementType: 'input'
  },
  amount: {
    label: 'Refund Amount',
    keyName: 'amount',
    type: 'number',
    required: true,
    elementType: 'input'
  },
  refund_date: {
    label: 'Refund Date',
    keyName: 'refund_date',
    type: 'text',
    required: false,
    elementType: 'date'
  },
  refund_mode: {
    label: 'Refund Mode',
    keyName: 'refund_mode',
    type: 'text',
    required: false,
    elementType: 'select'
  }
};

export const getInitValues = () => {
  let initObj = {};
  Object.keys(FIELDS).forEach((item) => {
    initObj[item] = '';
  });
  return initObj;
};

export const REFUND_REASON = {
  EXCESS_PAYMENT_BUYER: {
    label: 'Excess Payment to Buyer',
    value: 'Excess Payment to Buyer'
  },
  EXCESS_PAYMNENT_SELLER: {
    label: 'Excess Payment to Seller',
    value: 'Excess Payment to Seller'
  },
  FCL_PAYMENT: {
    label: 'FCL payment',
    value: 'FCL payment'
  },
  HOLD_BACK_REFUND_TO_BUYER: {
    label: 'Hold Back Refund To Buyer',
    value: 'Hold Back Refund To Buyer'
  },
  HOLD_BACK_REFUND_TO_SELLER: {
    label: 'Hold Back Refund To Seller',
    value: 'Hold Back Refund To Seller'
  },
  REFUND_TO_BUYER_SERVICE_CHARGE: {
    label: 'Refund to Buyer Sevice Charge',
    value: 'Refund to Buyer Sevice Charge'
  },
  REFUND_TO_SELLER_SERVICE_CHARGE: {
    label: 'Refund to Seller Sevice Charge',
    value: 'Refund to Seller Sevice Charge'
  },
  REFUND_OF_INSURANCE: {
    label: 'Refund of Insurance',
    value: 'Refund of Insurance'
  },
  REFUND_OF_WARRANTY: {
    label: 'Refund of Warranty',
    value: 'Refund of Warranty'
  },
  REFUND_OF_CF_AMOUNT_TO_NBFC_TEAM: {
    label: 'Refund Of CF Amount To NBCF Team',
    value: 'Refund Of CF Amount To NBCF Team'
  },
  OTHER: {
    label: 'Other',
    value: 'Other'
  }
};
