import React, { useEffect, useState } from 'react';
import { SelectComponent, CheckBox, SelectDropdown } from '../../../client/components/shared';
import { HOLDBACK_SERVICES } from './constants';
import './styles.css';
import InputText from '../../../shared/components/input-text';
import CustomButton from '../../../shared/components/button';
import { rcCaseTypeService } from '../../../services';
import { showToastMessages } from '../../../shared/utils/helper';
import Accordion from '../../../shared/accordion';
import ArrowIcon from '../../../shared/arrow';

const RcCasetypeCost = ({
  caseTypeData,
  onChangeSelect,
  onChangeCost,
  setCaseTypeData,
  releaseBlockHoldback,
  dealId,
  dealInfoData
}) => {
  const [rcCaseTypeList, setRcCaseTypeList] = useState([]);
  useEffect(()=>{
    if (!!caseTypeData && !!caseTypeData.rc_case_type_list) {
      const items = caseTypeData.rc_case_type_list.map((option) => {
        return { value: option, label: option };
      });
      !!items && setRcCaseTypeList(items);
    }
  },[caseTypeData]);

  const updateCost = (e, index, costType) => {
    const { name, value } = e.target;
    let updatedData = { ...caseTypeData };
    const oldValue = updatedData[costType][index][name];
    updatedData[costType][index] = {
      ...updatedData[costType][index],
      [name]: value
    };
    const serviceName = updatedData[costType][index]['cost_name'];
    updatedData = updateHoldBackCost(
      e,
      oldValue,
      serviceName,
      costType,
      updatedData
    );
    setCaseTypeData(updatedData);
  };

  const updateHoldBackCost = (
    e,
    oldValue,
    serviceName,
    costType,
    updatedData
  ) => {
    const { name, value } = e.target;
    if (HOLDBACK_SERVICES.includes(serviceName)) {
      const index = updatedData[costType].findIndex((item) => {
        return item.cost_name === 'HOLDBACK';
      });
      updatedData[costType][index] = {
        ...updatedData[costType][index],
        [name]: updatedData[costType][index][name] + (value - oldValue)
      };
      return updatedData;
    } else {
      return updatedData;
    }
  };
  
  const onBlur = (e, index, costType) => {
    const { name, value } = e.target;
    if (!value) {
      let updatedData = { ...caseTypeData };
      updatedData[costType][index] = {
        ...updatedData[costType][index],
        [name]: 0
      };
      setCaseTypeData(updatedData);
    }
  };

  const onReleaseBlock = (index, costType) => {
    let updatedData = { ...caseTypeData };

    const holdbackIndex = updatedData[costType].findIndex((item) => {
      return item.cost_name === 'HOLDBACK';
    });
    const payload = {
      deal_id: dealId,
      holdback_costs: [
        updatedData[costType][index],
        updatedData[costType][holdbackIndex]
      ]
    };
    updatedData[costType][index] = {
      ...updatedData[costType][index],
      holdback_block_status: !updatedData[costType][index].holdback_block_status
    };

    releaseBlockHoldback(payload, updatedData);
  };

  const defaultValues = () => {
    const {finance_opted = false, rc_case_type} = caseTypeData || {};
    let rcCaseTypes = rc_case_type || [];
    return rcCaseTypes.filter(item => !!item).map((item) => {
      return {value: item, label: item};
    });
  };

  const onAddLineItemsRCTypeCost = (item, index) => {
    const lineItem = {
      rcCaseType: '',
      rcCaseTypeCost: 0
    };
    let updatedData = { ...caseTypeData };
    updatedData['buyer_costs'][index].caseTypeLineItems = [...item.caseTypeLineItems, lineItem];
    setCaseTypeData(updatedData);
  };

  const onSelectCasetype = (value, index, parentIndex) => {
    let updatedData = { ...caseTypeData };
    updatedData['buyer_costs'][parentIndex].caseTypeLineItems[index].rcCaseType = value;
    setCaseTypeData(updatedData);
  };
  const getTotalRCTypesCost = (list) => {
    if (!!list) {
      return list.reduce((acc = 0, cur)=>{ 
        if (!!cur.rcCaseTypeCost) {
          return acc +  Number(cur.rcCaseTypeCost);
        } else {
          return acc;
        }
      }, 0);
    } else {
      return 0;
    }
  };
  const onSelectCasetypeCost = (value, index, parentIndex) => {
    let updatedData = { ...caseTypeData };
    updatedData['buyer_costs'][parentIndex].caseTypeLineItems[index].rcCaseTypeCost = value;
    updatedData['buyer_costs'][parentIndex].expected = getTotalRCTypesCost(updatedData['buyer_costs'][parentIndex].caseTypeLineItems);;
    setCaseTypeData(updatedData);
  }
  const displayRCTransferCost = (item, index) => {
    // console.log({item});
    return (
      <div className='row' key={`buyer_${index}`}>
        <div className="col-12">
          <Accordion totalItems={1}>
          {({ onClick, currentVisibleStates }) => {
              return (
                <Accordion.Content>
                  <Accordion.Heading>
                    <div onClick={() => onClick({ index: 0 })}>
                        <div className="row">
                          <div className="col-4">{item.cost_display}</div>
                          <div className='col-3'>
                            <InputText
                              id={`buyer_cost_${index}`}
                              type='number'
                              name='expected'
                              value={item.expected}
                              readOnly
                            />
                          </div>
                          <div className='col-3'>
                            <InputText
                              id={`buyer_waiver_${index}`}
                              type='number'
                              name='waiver'
                              value={item.waiver}
                              onChange={(e) => updateCost(e, index, 'buyer_costs')}
                              onBlurCallback={(e) => onBlur(e, index, 'buyer_costs')}
                              className='form-control inputSpace'
                            />
                          </div>
                          <div className='col-2'>
                            <ArrowIcon
                              grey={'GreyArrow'}
                              rotateBy={currentVisibleStates[0] ? 180 : 90}
                            />
                          </div>
                        </div>
                    </div>
                  </Accordion.Heading>
                  <Accordion.Body>
                  <div
                        className={
                          currentVisibleStates[0]
                            ? 'accordionShow table'
                            : 'accordionHide'
                        }
                      >
                        {item.caseTypeLineItems && item.caseTypeLineItems.length > 0 && item.caseTypeLineItems.map((lineItem, lineIndex)=>{
                          return (
                            <div className="row">
                              <div className="col-4"></div>
                              <div className="col-3">
                                <div className="lh38 font-weight-bold">Case Type</div>
                                <div className="inputWrapper">
                                    <SelectDropdown
                                      key={`caseTypeLineItems_${lineIndex}}`}
                                      name={`caseTypeLineItems_${lineIndex}}`}
                                      optionsList={rcCaseTypeList}
                                      value={lineItem.rcCaseType}
                                      onChange={(e) => onSelectCasetype(e.target.value, lineIndex, index)}
                                      isClearable={false}
                                    />
                                </div>
                              </div>  
                              <div className="col-3">
                                <div className="lh38 font-weight-bold">Cost</div>
                                <InputText
                                  type='number'
                                  name={`case-type-${lineItem.caseType}-${lineIndex}`}
                                  value={lineItem.rcCaseTypeCost}
                                  onChange={(e)=> onSelectCasetypeCost(e.target.value, lineIndex, index)}
                                />
                              </div>
                            </div>
                          );
                        })}
                        <div className="row">
                          <div className="col-4"></div>
                          <div className="col-3">
                            <div className="mt-4 pt-2">
                              <CustomButton
                                label={'Add Cost'}
                                onClick={() => onAddLineItemsRCTypeCost(item, index)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                  </Accordion.Body>
                </Accordion.Content>
              );
            }}
          </Accordion>
        </div>
      </div>
    );
  };

  return caseTypeData ? (
    <div className='row top-border align-items-center'>
      <div className='col-3 mb20 lh38'>RC Case Type</div>
      <div className='col-3 mb20 rcCaseType'>
        <SelectComponent
          name='rc_case_type'
          optionsList={rcCaseTypeList}
          defaultValue={defaultValues()}
          onChange={onChangeSelect}
          version='v2'
          className="basic-multi-select"
          classNamePrefix="select"
          isMulti
        />
      </div>
      <div className='col-3 mb20 lh10 d-flex align-items-center'>Buyer Party Peshi Applicable
        <div className='col-1 d-flex align-items-center'>
          <CheckBox
            name='party_peshi_applicable'
            onClickCallback={(e) => onChangeCost(e)}
            checkedStatus={caseTypeData && caseTypeData.party_peshi_applicable}
            disabled={!!caseTypeData && !!caseTypeData.party_peshi_applicable_at_Rto}
          />
        </div>
        
      </div>

      <div className='col-12'>
        <div className='row'>
          <div className='col-4 lh38 font-weight-bold'>Buyer Service</div>
          <div className='col-3 inputSpace font-weight-bold'>Cost</div>
          <div className='col-3 inputSpace font-weight-bold'>Waiver</div>
          <div className='col-2' />
        </div>
        {caseTypeData &&
          caseTypeData.buyer_costs.map((item, index) => {
            if (item.cost_name === "RC_TRANSFER_COST") {
              return displayRCTransferCost(item, index);
            } else {
              return (
                <div className='row' key={`buyer_${index}`}>
                  <div className='col-4 lh38'>{item.cost_display}</div>
                  <div className='col-3'>
                    <InputText
                      id={`buyer_cost_${index}`}
                      type='number'
                      name='expected'
                      value={item.expected}
                      onChange={(e) => updateCost(e, index, 'buyer_costs')}
                      onBlurCallback={(e) => onBlur(e, index, 'buyer_costs')}
                      className='form-control inputSpace'
                      readOnly={
                        ['HOLDBACK', 'INTRASTATE_ROAD_TAX'].includes(item.cost_name) ||
                        item.holdback_block_status === false
                      }
                    />
                  </div>
                  <div className='col-3'>
                    <InputText
                      id={`buyer_waiver_${index}`}
                      type='number'
                      name='waiver'
                      value={item.waiver}
                      onChange={(e) => updateCost(e, index, 'buyer_costs')}
                      onBlurCallback={(e) => onBlur(e, index, 'buyer_costs')}
                      className='form-control inputSpace'
                      readOnly={
                        ['HOLDBACK', 'INTRASTATE_ROAD_TAX'].includes(item.cost_name) ||
                        item.holdback_block_status === false
                      }
                    />
                  </div>
  
                  {item.holdback_block_status !== null && (
                    <div className='col-2'>
                      <CustomButton
                        label={item.holdback_block_status ? 'Release' : 'Block'}
                        onClick={() => onReleaseBlock(index, 'buyer_costs')}
                      />
                    </div>
                  )}
                </div>
              );
            }
          })}

        <div className='row mt20'>
          <div className='col-4 lh38 font-weight-bold'>Seller Services</div>
          <div className='col-3 inputSpace font-weight-bold'>Cost</div>
          <div className='col-3 inputSpace font-weight-bold'>Waiver</div>
          <div className='col-2' />
        </div>

        {caseTypeData &&
          caseTypeData.seller_costs.map((item, index) => {
            return (
              <div className='row' key={`buyer_${index}`}>
                <div className='col-4 lh38'>{item.cost_display}</div>
                <div className='col-3'>
                  <InputText
                    id={`seller_cost_${index}`}
                    type='number'
                    name='expected'
                    value={item.expected}
                    onChange={(e) => updateCost(e, index, 'seller_costs')}
                    onBlurCallback={(e) => onBlur(e, index, 'seller_costs')}
                    className='form-control inputSpace'
                    readOnly={
                      item.cost_name === 'HOLDBACK' ||
                      item.holdback_block_status === false
                    }
                    //readOnly={item.cost_name == 'RC TRANSFER COST'}
                  />
                </div>
                <div className='col-3'>
                  <InputText
                    id={`seller_waiver_${index}`}
                    type='number'
                    name='waiver'
                    value={item.waiver}
                    onChange={(e) => updateCost(e, index, 'seller_costs')}
                    onBlurCallback={(e) => onBlur(e, index, 'seller_costs')}
                    className='form-control inputSpace'
                    readOnly={
                      item.cost_name === 'HOLDBACK' ||
                      item.holdback_block_status === false
                    }
                  />
                </div>
                {item.holdback_block_status !== null && (
                  <div className='col-2'>
                    <CustomButton
                      label={item.holdback_block_status ? 'Release' : 'Block'}
                      onClick={() => onReleaseBlock(index, 'seller_costs')}
                    />
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  ) : null;
};

export default RcCasetypeCost;
