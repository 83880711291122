import React from "react";
import { ROLES } from "../../../../shared/utils/constants";

const TmAssignCtaRenderer = ({
  data,
  attributeKey,
  context: {
    handleTmAssignClick
  }
}) => {
  return (
    <button
      className="btn btn-success"
      onClick={() => handleTmAssignClick(data)}
      disabled={!(window.localStorage.role === ROLES.C2C_LMS_ADMIN)}
    >{data[attributeKey] ? "Re-Assign" : "Assign"}</button>
  )
}

export default TmAssignCtaRenderer;