import { cityService } from '../../../services';
import Types from './types';
import {
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected,
  handleHttpStatusError
} from '../../../services/helper';

const setCityGridInfoInit = () => ({
  type: Types.SET_CITY_GRID_DATA_INIT
});

const setCityGridInfoSuccess = (data) => ({
  type: Types.SET_CITY_GRID_DATA_SUCCESS,
  payload: data
});
const setCityGridInfoFail = (data) => ({
  type: Types.SET_CITY_GRID_DATA_FAILURE,
  payload: data
});

const fetchCityGridInfoConnected = (params) => (dispatch) => {
  dispatchSetLoaderConnected();
  dispatch(setCityGridInfoInit());
  return cityService
    .fetchCityGridInfo(params)
    .then((resp) => {
      dispatchResetLoaderConnected();
      if (resp.data && resp.data.data) {
        dispatch(setCityGridInfoSuccess(resp.data.data.response));
        return resp.data.data;
      } else {
        return {
          page: 0,
          totalPages: 0,
          totalRecords: 0,
          response: []
        };
      }
    })
    .catch((error) => {
      dispatch(setCityGridInfoFail(error));
      handleHttpStatusError(error, true);
      throw error;
    });
};

export { fetchCityGridInfoConnected };
