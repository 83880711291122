import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrderGrid from './component';

import { setPageNumberConnected, fetchOrdersAction } from './actions';

const mapStateToProps = (state) => {
  const {
    detail,
    ordersInfo: { pageNumber }
  } = state;
  return {
    detail,
    pageNumber
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchOrdersAction,
      setPageNumberConnected
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderGrid);
