import Types from './types';
import { financeService } from '../../../services';
import {
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected,
  handleHttpStatusError
} from '../../../services/helper';

const fetchFinanceDealInfoByIdInit = () => {
  return {
    type: Types.FETCH_FINANCE_DEALINFO_INIT
  };
};

const fetchFinanceDealInfoByIdSucess = (data) => {
  return {
    type: Types.FETCH_FINANCE_DEALINFO_SUCCESS,
    data: data
  };
};

const fetchFinanceDealInfoByIdFail = (error) => {
  return {
    type: Types.FETCH_FINANCE_DEALINFO_FAIL,
    error: error
  };
};

const fetchFinanceDealInfoByIdConnected = (id, withLoader = true) => (
  dispatch
) => {
  dispatchSetLoaderConnected(withLoader);
  withLoader && dispatch(fetchFinanceDealInfoByIdInit());
  return new Promise((resolve, reject) => {
    financeService
      .fetchFinanceDealInfoById(id)
      .then((resp) => {
        resolve(resp);
        withLoader && dispatch(fetchFinanceDealInfoByIdSucess(resp.data.data));
        dispatchResetLoaderConnected();
      })
      .catch((error) => {
        withLoader && dispatch(fetchFinanceDealInfoByIdFail(error));
        handleHttpStatusError(error);
        reject(error);
      });
  });
};

export { fetchFinanceDealInfoByIdConnected };
