import Types from './types';
import { rcManagementService } from '../../../../../services';

const fetchRcCaseRemarks = (id, type, withOps, page) => {
  return rcManagementService.fetchOthersRcCasetypeRemarks(id, type, withOps, page);
}

const fetchRcCasetypeRemarksConnected = (id, type, withOps = false, page = 0) => (
  dispatch
) => {
  dispatch(fetchRcCasetypeRemarksRequested());
  //let params = {};
  return fetchRcCaseRemarks(id, type, withOps, page)
    .then((response) => {
      const { data } = response;
      dispatch(fetchRcCasetypeRemarksSuccess(data, withOps));
    })
    .catch((error) => {
      dispatch(dispatch(fetchRcCasetypeRemarksFailure(error)));
    });
};

const fetchRcCasetypeRemarksRequested = () => {
  return {
    type: Types.FETCH_REMARKS_REQUESTED
  };
};

const fetchRcCasetypeRemarksSuccess = (data, withOps) => {
  return {
    type: Types.FETCH_REMARKS_SUCCESS,
    payload: data,
    withOps
  };
};

const fetchRcCasetypeRemarksFailure = (error) => {
  return {
    type: Types.FETCH_REMARKS_FAILURE,
    payload: error
  };
};

export { fetchRcCasetypeRemarksConnected };
