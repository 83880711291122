import React, { useState } from "react";
import Accordion from "../../../../shared/accordion";
import ArrowIcon from "../../../../shared/arrow";
import { getformattedDate } from "../../../../shared/utils/datesHelper";
import useModal from 'react-hooks-use-modal';
import { ResolveRtosDelay } from "./resolve-rtos-delay-modal";
import { showToastMessages } from "../../../../shared/utils/helper";
import { CBox } from "../c-box";

export const CustomAccordian = ({
  heading,
  body,
}) => {
  return (
    <Accordion>
      {({ onClick, currentVisibleStates }) => (
        <Accordion.Content>
          <div className='borderWrapper'>
            <Accordion.Heading>
              <h2
                className=''
                onClick={() => onClick({ index: 0 })}
                role="button"
              >
                {heading}
                <ArrowIcon
                  grey={'GreyArrow'}
                  rotateBy={currentVisibleStates[0] ? 180 : 90}
                />
              </h2>
            </Accordion.Heading>
            <Accordion.Body>
              <div
                className={
                  currentVisibleStates[0]
                    ? 'accordionShow mt-3'
                    : 'accordionHide'
                }
              >
                {body}
              </div>
            </Accordion.Body>
          </div>
        </Accordion.Content>
      )}
    </Accordion>
  )
};

const RtoDelayLabelValueRenderer = ({
  isColType = true,
  label,
  value,
  className,
  labelClassName,
  valueClassName,
  style = {},
}) => {
  return (
    <div className={`d-flex ${isColType ? 'flex-column col-md' : 'flex-row'} ${className}`} style={style}>
      <div className={`${labelClassName}`}>{label}</div>
      <div className={`${valueClassName}`}>{value}</div>
    </div>
  )
};

export const RtoDelayLogs = ({
  rtoDelays,
  isVisibleRtoDelayShowMore,
  stateOptions,
  updateRtoDelay,
  onShowMore,
  refeshRtoDelayLogs
}) => {
  const [Modal, open, close] = useModal();
  const [selectedRtoDelayId, setSelectedRtoDelayId] = useState();

  const getStateLabel = (stateCode) => {
    const state = stateOptions.find(({ value }) => value === stateCode);
    if (state) return state.label;
    return '-'
  }

  const handleCompleteResolve = (id) => {
    updateRtoDelay({
      id,
      completeResolved: true,
    })
    .then(() => {
      refeshRtoDelayLogs()
    })
  };

  const handleViewRtos = (id) => {
    setSelectedRtoDelayId(id);
    open();
  };

  const handleSave = (rtoDelayDetails) => {
    updateRtoDelay({
      id: selectedRtoDelayId,
      rtoDelayDetails: rtoDelayDetails.map(({ rtoCode }) => ({ rtoCode, rtoResolved: true }))
    })
    .then(({ message }) => {
      refeshRtoDelayLogs();
      showToastMessages(message);
      close();
    })
  }

  const renderDelayHeading = ({
    rtoDelayStage,
    rtoDelayReason,
    updatedOn,
  }) => {
    return (
      <div className="d-flex flex-column">
        <div className="d-flex flex-row">
          <RtoDelayLabelValueRenderer
            label={"RC Stage:"}
            value={rtoDelayStage}
            isColType={false}
            valueClassName="ml-1 font-weight-bold"
          />
          <RtoDelayLabelValueRenderer
            label={"Delay Reason:"}
            value={rtoDelayReason}
            isColType={false}
            style={{ marginLeft: '200px'}}
            valueClassName="ml-1 font-weight-bold"
          />
        </div>
        <RtoDelayLabelValueRenderer
          label={"Updated at:"}
          value={updatedOn ? getformattedDate(updatedOn, "custom", "DD MMM YYYY") : '-'}
          isColType={false}
          valueClassName="ml-1 font-weight-bold"
          style={{ marginTop: '15px'}}
        />
      </div>
    )
  };

  const renderDelayBody = ({
    id,
    completeResolved,
    delayStartDate,
    delayEndDate,
    stateCode,
    customRtoDelayReason,
  }) => {
    return (
      <>
        <div className="row">
          <RtoDelayLabelValueRenderer
            label={"Start Date"}
            value={delayStartDate ? getformattedDate(delayStartDate, "custom", "DD MMM YYYY") : '-'}
            labelClassName="font-weight-bold"
            valueClassName="mt-2"
          />
          <RtoDelayLabelValueRenderer
            label={"End Date"}
            value={delayEndDate ? getformattedDate(delayEndDate, "custom", "DD MMM YYYY") : '-'}
            className="ml-5"
            labelClassName="font-weight-bold"
            valueClassName="mt-2"
          />
          <RtoDelayLabelValueRenderer
            label={"State"}
            value={getStateLabel(stateCode)}
            className="ml-5"
            labelClassName="font-weight-bold"
            valueClassName="mt-2"
          />
          <RtoDelayLabelValueRenderer
            label={"RTOs"}
            value={(
              <div className="text-primary" role="button" onClick={() => handleViewRtos(id)}>View/Edit</div>
            )}
            className="ml-5"
            labelClassName="font-weight-bold"
            valueClassName="mt-2"
          />
          <RtoDelayLabelValueRenderer
            label={"Resolved?"}
            value={
              completeResolved ? "Yes" : (
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => handleCompleteResolve(id)}
                >
                  Resolve
                </button>
              )
            }
            className="ml-5"
            labelClassName="font-weight-bold"
            valueClassName="mt-2"
          />
        </div>
        {!!customRtoDelayReason && (
          <>
            <h2 className="font-weight-bold mb-2 mt-3">Custom delay message to customers</h2>
            <CBox>
              <h2 className="text-secondary">{customRtoDelayReason}</h2>
            </CBox>
          </>
        )}
      </>
    )
  };


  return (
    <>
      {!!selectedRtoDelayId && (
        <Modal>
          <ResolveRtosDelay
            rtoDelayDetails={((rtoDelays.find(({ id }) => id === selectedRtoDelayId) || {}).rtoDelayDetails || [])}
            onSave={handleSave}
          />
        </Modal>
      )}
      <div>
        <div className="h4 mb-3 mt-3">RTO Delay Logs</div>
        <div className="bg-white py-2 rounded-lg">
          <div>
            {rtoDelays.map((rtoDelay, idx) => {
              return (
                <div key={idx} className="col-12">
                  <CustomAccordian
                    heading={renderDelayHeading(rtoDelay)}
                    body={renderDelayBody(rtoDelay)}
                  />
                </div>
              )
            })}
          </div>
          {isVisibleRtoDelayShowMore && (
            <div
              className="d-flex align-items-middle justify-content-center"
              role="button"
              onClick={onShowMore}
            >
              Show More
            </div>
          )}
        </div>
      </div>
    </>
  )
}