import React, { useState, useEffect } from "react";
import { FILE_UPLOAD, ALLOCATIONS_LIST, UPLOAD_PATH,PUBLISH_LIST,PUBLISH_NEW_TYPE, ADMIN_UPLOADS } from '../../components/shared/Constants';
import { Button, Input } from 'reactstrap';
import { DataService, AuthService } from "../../service";
import {uploadServiceApi} from '../../../services'
import './style/style.css';
import { SelectComponent } from '../../components/shared';
import {isObject} from '../../../shared/utils/helper'


import { withAuthorization } from '../../../shared/utils/firebase'

const Upload = () => {

  const [pathType] = window.location.pathname.includes(UPLOAD_PATH.ALLOCATIONS) ? useState(UPLOAD_PATH.ALLOCATIONS) : useState(UPLOAD_PATH.PUBLISH);
  const [file, setUploadFile] = useState();
  const [isValid, setIsValid] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [errors, setErrors] = useState(null);
  const [uploadType,setUploadType]=useState()
  const [showError, setShowError] = useState(false);

  const validateFileExtension = (filePath) => {
    const fileExtension = filePath.substring(filePath.lastIndexOf('.') + 1);
    return fileExtension === FILE_UPLOAD.XLSX;
  }

  const validateFileSize = (fileSize) => {
    return fileSize <= FILE_UPLOAD.MAX_SIZE;
  }

  const validateFile = (e) => {
    let isValidFileSize = false;
    const isValidFileExtension = validateFileExtension(e.target.value);
    if (!isValidFileExtension) {
      setErrorMessage(`Please select a valid ${FILE_UPLOAD.XLSX} file to upload`);
    } else {
      isValidFileSize = validateFileSize(e.target.files[0].size);
      if (!isValidFileSize) {
        setErrorMessage(`Max allowed size is ${FILE_UPLOAD.MAX_SIZE / (1024 * 1024)} MB`);
      } else {
        setErrorMessage('');
      }
    }
    isValidFileExtension && isValidFileSize ? setIsValid(true) : setIsValid(false);
  }

  const enableResponseMessage = (resp) => {
    resp.data && setErrors(resp.data);
    setResponseMessage(resp.message);
  }

  const disableResponseMessage = () => {
    setErrors(null);
    setResponseMessage('');
  }

  const onFileChange = (e) => {
    e.persist();
    disableResponseMessage();
    if (e.target.files.length > 0) {
      validateFile(e);
      setUploadFile(e.target.files[0]);
    } else {
      setIsValid(false);
      setErrorMessage('Please select a file to upload');
    }
  }

  const checkValidAllocation = () => {
    if (uploadType) {
      return true;
    }
     else {
      return false;
    }
  }

  const onUpload = () => {
    disableResponseMessage();
    uploadType ? setShowError(false) : setShowError(true);
    
    if (file) {
      if (isValid && checkValidAllocation()) {
        setIsSubmitting(true);
        setIsValid(true);
        setErrorMessage('');
        let data = new FormData();
        data.append('file', file);
        if(uploadType!==PUBLISH_NEW_TYPE && ADMIN_UPLOADS.indexOf(uploadType) === -1)
        {
        DataService.uploadFile(data, uploadType).then(resp => {
          // console.log('resp received', resp);
          enableResponseMessage(resp);
          setIsSubmitting(false);
        });
      }
      else {
        uploadServiceApi.uploadFile(data,uploadType).then(resp=>{
          enableResponseMessage(resp);
          setIsSubmitting(false);
        })
      }
      }
    } else {
      setIsValid(false);
      setErrorMessage('Please select a file to upload');
      setIsSubmitting(false);
    }
  }

  return (
    <div className="container-fluid">
      <div className="row col-12 upload">
        <h2 className="">LEAD UPLOAD</h2>
        <div className="col-12  uploadSection">
         
            <div className="select-allocation col-2">
              <SelectComponent name="allocations" optionsList={pathType === UPLOAD_PATH.ALLOCATIONS?ALLOCATIONS_LIST:PUBLISH_LIST} onChange={(value) => { setUploadType(value); setShowError(false); }} />
              {showError && <span>Please select</span>}
            </div>

          <div className="uploadFile">
            <Input type="file" onChange={onFileChange}></Input>
            {!isValid && <span>{errorMessage}</span>}
          </div>
          <div className="uploadBtn">
            <Button onClick={onUpload} disabled={isSubmitting}>{isSubmitting ? 'Processing' : 'Upload'}</Button>
          </div>
        </div>
        {responseMessage && <div><span>{responseMessage}</span></div>}


        {
           isObject(errors)?<div className="row col-12">
            {
              Object.keys(errors).map((key) => {
                return !(errors[key].match(new RegExp('Empty row.', 'i'))) && <div className="row col-12" key={key}> {`${key} . ${errors[key]}`}</div>
              })
            }
          </div>
        : <div className="row col-12"><span>{!!errors?errors:''}</span></div>}
      </div>
    </div>
  )
}



// const condition = () => ALLOCATIONS_ACCESS_ROLES.includes(getFromLocalStorage(ROLE));

const condition = () => true;

export default withAuthorization(condition)(Upload)