import Types from './types';
import { cityService } from '../../../services';
import {
  setAccessLoaderConnected,
  resetAccessLoaderConnected,
  setForbiddenConnected
} from '../../../shared/components/loader-with-access-control/actions';
import { handleHttpStatusError } from '../../../services/helper';

const fetchCityInspectionStoreInfoByIdInit = (data) => ({
  type: Types.FETCH_CITY_INSPECTION_STORE_INIT,
  data
});
const fetchCityInspectionStoreInfoByIdSuccess = (data) => ({
  type: Types.FETCH_CITY_INSPECTION_STORE_SUCCESS,
  data
});

const fetchCityInspectionStoreInfoByIdFail = (error) => ({
  type: Types.FETCH_CITY_INSPECTION_STORE_FAIL,
  error
});

const fetchCityInspectionStoreInfoByIdConnected = (cityId) => (dispatch) => {
  dispatch(setAccessLoaderConnected());
  dispatch(fetchCityInspectionStoreInfoByIdInit());
  return new Promise((resolve, reject) => {
    cityService
      .fetchCityInspectionStoreInfoById(cityId)
      .then((resp) => {
        resolve(resp);
        dispatch(fetchCityInspectionStoreInfoByIdSuccess(resp.data.data));
        dispatch(resetAccessLoaderConnected());
      })
      .catch((error) => {
        dispatch(fetchCityInspectionStoreInfoByIdFail(error));
        handleHttpStatusError(error);
        reject(error);
      });
  });
};

const updateSellerToCCFlag = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    cityService
      .updateSellerToCC(data)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { fetchCityInspectionStoreInfoByIdConnected, updateSellerToCCFlag };
