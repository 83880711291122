import React from 'react';
import styles from './styles.css';

const SelectDropdown = ({ className, value, name, required = false, placeholder = "", defaultValue = "", hasError = false, 
isDisabled = false, isMulti = false, onChange, optionsList, isSelectRequired = true, isEmptyDefaultValue = false }) => {
  const options = optionsList && optionsList.map((option, index) => {
    return <option key={'option_' + index} value={option.value}>{option.label}</option>;
  });
  return (
    <select
      required={required}
      className={className ? className : "form-control"}
      name={name}
      onChange={e => onChange(e)}
      value={value || ''}
      disabled={isDisabled}
    >
      {isSelectRequired && <option value="">--Select--</option>}
      {isEmptyDefaultValue && <option value=""></option>}
      {options && options}
    </select>
  );
}

export default SelectDropdown;