import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    FETCH_ARDEALER_INIT
    FETCH_ARDEALER_SUCCESS
    FETCH_ARDEALER_FAILURE
    REMOVE_ARDEALER_DATA
`,
  {
    prefix: 'ar-dealer-form/'
  }
);
