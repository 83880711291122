import Types from './types';

const setRcOthersDealInfo = data => {
  return {
    type: Types.SET_RC_OTHERS_DEAL_INFO,
    data
  };
};

export { setRcOthersDealInfo };
