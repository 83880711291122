import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrderPublishTab from './component';
import {
  updateInventoryConnected,
  fetchInventoryLogConnected,
  fetchStores
} from './actions';
import { StoreActions, OrderActions } from '../../actions';

import { fetchCarDetailConnected } from '../orders-car-information/actions';

// const mapStateToProps = () => ({});

const mapStateToProps = (state) => {
  const {
    order: { order, despositions },
    inventoryInfo: { isLoading, inventoryLogs, error }
  } = state;
  return {
    order,
    despositions,
    inventoryLogs,
    error,
    isLoading
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateInventoryConnected,
      fetchInventoryLogConnected,
      fetchStores,
      fetchAllStores: StoreActions.fetchAllStores,
      fetchCarDetailConnected: fetchCarDetailConnected
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OrderPublishTab);
