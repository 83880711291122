import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import {
  isArrayEmpty,
  getFromLocalStorage,
  pattern,
  showToastMessages
} from '../../../../shared/utils/helper';
import { RC_OTHERS_CUSTOMER_INFO } from './constant';
import 'react-datepicker/dist/react-datepicker.css';
import { dealInfoService } from '../../../../services';
import './style.css';
import LoaderWithAccessControl from '../../../../shared/components/loader-with-access-control';
import { getformattedDate } from '../../../../shared/utils/datesHelper';
import { ROLES, checkForUserRoles } from '../../../../shared/utils/constants';
import Locale from '../../../../shared/utils/locale';
import useModal from 'react-hooks-use-modal';
import { useRcOthersDealInfo } from '../../../hooks/custom-hooks/rc-others-deal-info-hook';
import { DealCancelModal } from './deal-cancel-modal';

const VALID_ROLES = [
  ROLES.C2C_ADMIN,
  ROLES.C2C_OPS_RC_DOCS,
  ROLES.C2C_OPS_BUDDY,
  ROLES.C2C_OPS_RC_TRACKER,
  ROLES.C2C_OPS_DOCS_CASE,
];

const DATA_KEYS = {
  buyer: {
    SECONDARY_PHONE: 'buyerSecondaryNumber',
    SECONDARY_EMAIL: 'buyerSecondaryEmail'
  },
  seller: {
    SECONDARY_PHONE: 'sellerSecondaryNumber',
    SECONDARY_EMAIL: 'sellerSecondaryEmail'
  },
  applicant: {
    SECONDARY_PHONE: 'applicantSecondaryNumber',
    SECONDARY_EMAIL: 'applicantSecondaryEmail'
  },
  serviceDetails: {
    SECONDARY_PHONE: 'serviceDetailsSecondaryNumber',
    SECONDARY_EMAIL: 'serviceDetailsSecondaryEmail'
  }
};

const CUSTOMER = {
  BUYER: 'buyer',
  SELLER: 'seller',
  APPLICANT: 'applicant',
  SERVICE_DETAILS: 'serviceDetails',
};

function DealInformation({ match, setRcDealInfoConnect, updateOthersRcStatusConnect }) {
  const { dealCancelReasonsOptions, fetchDealCancelReasons } = useRcOthersDealInfo();
  const [dealinfo, setDealInfo] = useState({});
  const [dealInfoCopy, setDealInfoCopy] = useState({});
  const [dealHistory, setDealHistory] = useState({});
  const [editables, setEditables] = useState({});
  const [editStatus, setEditStatus] = useState({});
  const [addDetails, setAddDetails] = useState({ buyer: false, seller: false, applicant: false });
  const [rcDealStatus, setRcDealStatus] = useState("");

  const formRef = useRef([]);

  const [Modal, open, close] = useModal();

  useEffect(() => {
    getDealInfo();
    getDealHistory();
  }, []);

  const getDealHistoryDetails = () => {
    return dealInfoService.fetchOthersDealHistoryById(match.params.id);
  }

  const getDealHistory = () => {
    getDealHistoryDetails()
      .then((res) => {
        if (res.deal_info_history) {
          setDealHistory(res.deal_info_history);
        } else {
          setDealHistory({});
        }
      })
      .catch((err) => {});
  };

  const getDealDetail = () => {
    return dealInfoService.getRcOthersDetail(match.params.id);
  }

  const getDealInfo = (withLoader = true) => {
    getDealDetail()
      .then((res) => {
        setDealInfo(res);
        setRcDealInfoConnect(res);
      })
      .catch((err) => {});
  };

  const NUM_KEYS = [
    // 'buyerNumber',
    // 'sellerNumber',
    'buyerSecondaryNumber',
    'sellerSecondaryNumber',
    'agreedSalesPrice'
  ];

  const handleChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    if (NUM_KEYS.includes(name)) {
      value = value ? value.replace(/[^0-9]/g, '') : '';
    }
    setDealInfo({ ...dealinfo, [name]: value });
    setEditables({
      ...editables,
      [name]: true
    });
    setEditStatus({ ...editStatus, [name]: false });
  };

  useEffect(() => {
    setRcDealStatus(dealinfo.rcStatus);
  }, [dealinfo.rcStatus]);

  const updateRcData = (id, payload) => {
    return dealInfoService.updateOthersRcData(id, payload);
  }

  const handleSave = (e, customerType, keyname) => {
    e.preventDefault();
    if (!dealinfo[keyname]) return false;
    
    if (['buyerName', 'sellerName'].includes(keyname) && dealinfo[keyname].length > 89) {
       showToastMessages(`Please provide valid ${keyname}`, false, 5000);
       return;
    }

    const payload = {
      [keyname]: dealinfo[keyname]
    };
    updateRcData(match.params.id, payload)
      .then(() => {
        setDealInfoCopy({ ...dealInfoCopy, [keyname]: dealinfo[keyname] });

        setEditStatus({ ...editStatus, [keyname]: true });
        setEditables({ ...editables, [keyname]: false });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const canAddSecondaryDetails = (type) => {
    return (
      !!checkForUserRoles(VALID_ROLES) &&
      !dealinfo[DATA_KEYS[type]['SECONDARY_PHONE']] &&
      !dealinfo[DATA_KEYS[type]['SECONDARY_EMAIL']] &&
      !addDetails[type]
    );
  };

  const addSecondaryDetails = (type, status) => {
    setAddDetails({ ...addDetails, [type]: status });
  };

  const enableSecondaryDetails = (keyname, status) => {
    setEditables({
      ...editables,
      [keyname]: status
    });
    setEditStatus({ ...editStatus, [keyname]: !status });

    if (status) {
      setDealInfoCopy({ ...dealInfoCopy, [keyname]: dealinfo[keyname] });
    } else {
      setDealInfo({ ...dealinfo, [keyname]: dealInfoCopy[keyname] });
    }
  };

  const handleStatusChange  =  () => {
    if (rcDealStatus === "OPEN") {
      open();
    } else {
      updateOthersRcStatusConnect(match.params.id, { status: "OPEN" }).then(() => {
        setRcDealStatus("OPEN");
      });
    }
  }

  const handleCancelDeal = (reason) => {
    close();
    updateOthersRcStatusConnect(match.params.id, {
      status: "CLOSE",
      reason,
    }).then(() => {
      setRcDealStatus("CLOSE");
    });
  }

  const ActionBox = ({ keyname }) => {
    const isSaved = keyname in editStatus ? editStatus[keyname] : true;
    return (
      <span>
        {!!checkForUserRoles(VALID_ROLES) &&
          (isSaved ? (
            <i
              className='icon-fa fa fa-pencil'
              onClick={() => enableSecondaryDetails(keyname, true)}
            />
          ) : (
            <>
              <i
                className='icon-fa fa fa-times'
                style={{ color: 'red', marginLeft: '4px' }}
                onClick={() => enableSecondaryDetails(keyname, false)}
              />
              <i className='icon-fa'>
                <i
                  className='icon-fa fa fa-check'
                  style={{ color: 'green' }}
                  onClick={() => formRef.current[keyname].click()}
                />
                <input
                  type='submit'
                  ref={(el) => (formRef.current[keyname] = el)}
                  style={{ display: 'none' }}
                  name={keyname}
                />
              </i>
            </>
          ))}
      </span>
    );
  };

  const CustomerField = ({ customerType, title = 'Details' }) => {
    const KEYS = DATA_KEYS[customerType];

    return (
      <div key={customerType}>
        <h2 className='heading'>{title} </h2>
        {RC_OTHERS_CUSTOMER_INFO[customerType].map((data, dataIndex) => {
          const keyname = data.apiKey;
          const label = data.label;
          const isEnabled = data.isEnabled({ serviceCompleted: dealinfo.serviceCompleted });

          let extraProps = {};
          if (data.data_type === 'phone') {
            extraProps = {
              maxLength: 10,
              pattern: pattern.mobile,
              title: '10 digit phone no. starting with 6/7/8/9'
            };
          }
          if (data.data_type === 'number') {
            extraProps = {
              pattern: pattern.amount,
              title: 'Please enter digits only'
            };
          }
          if (['buyerName', 'sellerName'].includes(data.apiKey)) {
            extraProps = {
              maxLength: 90,
            };
          }

          if (['buyerEmail', 'sellerEmail'].includes(data.apiKey)) {
            extraProps = {
              maxLength: 75,
            };
          }

          return (
            <form
              key={`form_${keyname}`}
              onSubmit={(e) => handleSave(e, customerType, keyname)}
            >
              <div className='row'>
                <div className='col-5'>
                  <label className='font-weight-bold capitalize'>{label}</label>
                </div>
                <div className='col-7'>
                  {!editables[keyname] ? (
                    <label>: {dealinfo[keyname]}</label>
                  ) : (
                    <span>
                      :{' '}
                      <input
                        className='deal-input'
                        type= {data.element_type}
                        name={keyname}
                        value={dealinfo[keyname] || ''}
                        onChange={handleChange}
                        {...extraProps}
                      />
                    </span>
                  )}
                  {isEnabled &&
                    ActionBox({
                      customerType,
                      keyname: keyname
                    })}
                </div>
              </div>
            </form>
          );
        })}
        {canAddSecondaryDetails(customerType) ? (
          <div className='row'>
            <div
              className='col-12'
              onClick={() => addSecondaryDetails(customerType, true)}
            >
              <label
                className='font-weight-bold capitalize'
                style={{ color: '#0000EE', cursor: 'pointer' }}
              >
                + Add secondary details
              </label>
            </div>
          </div>
        ) : (
          <>
            <form
              onSubmit={(e) =>
                handleSave(e, customerType, KEYS['SECONDARY_PHONE'])
              }
            >
              <div className='row'>
                <div className='col-5'>
                  <label className='font-weight-bold capitalize'>
                    Secondary Number
                  </label>
                </div>
                <div className='col-7'>
                  {!editables[KEYS['SECONDARY_PHONE']] ? (
                    <label>: {dealinfo[KEYS['SECONDARY_PHONE']]}</label>
                  ) : (
                    <span>
                      :{' '}
                      <input
                        className='deal-input'
                        type='text'
                        name={KEYS['SECONDARY_PHONE']}
                        value={dealinfo[KEYS['SECONDARY_PHONE']] || ''}
                        onChange={handleChange}
                        maxLength={10}
                        pattern={pattern.mobile}
                        title='10 digit phone no. starting with 6/7/8/9'
                      />
                    </span>
                  )}
                  {ActionBox({
                    customerType,
                    keyname: KEYS['SECONDARY_PHONE']
                  })}
                </div>
              </div>
            </form>
            <form
              onSubmit={(e) =>
                handleSave(e, customerType, KEYS['SECONDARY_EMAIL'])
              }
            >
              <div className='row'>
                <div className='col-5'>
                  <label className='font-weight-bold capitalize'>
                    Secondary Email
                  </label>
                </div>
                <div className='col-7'>
                  {!editables[KEYS['SECONDARY_EMAIL']] ? (
                    <label>: {dealinfo[KEYS['SECONDARY_EMAIL']]}</label>
                  ) : (
                    <span>
                      :{' '}
                      <input
                        className='deal-input'
                        type='email'
                        name={KEYS['SECONDARY_EMAIL']}
                        value={dealinfo[KEYS['SECONDARY_EMAIL']] || ''}
                        onChange={handleChange}
                        title='Invalid Email!'
                      />
                    </span>
                  )}
                  {ActionBox({
                    customerType,
                    keyname: KEYS['SECONDARY_EMAIL']
                  })}
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    );
  };

  return (
    <LoaderWithAccessControl>
      <Modal>
        <DealCancelModal onSubmit={handleCancelDeal} dealCancelReasonsOptions={dealCancelReasonsOptions} fetchDealCancelReasons={fetchDealCancelReasons} />
      </Modal>
      <div className='col-12 rc-tracker'>
        <div className='row'>
          <div className='col-6'>
            {CustomerField({
              customerType: CUSTOMER.SERVICE_DETAILS,
              title: 'Service Details'
            })}
          </div>
          <div className='col-6'>
            {CustomerField({
              customerType: CUSTOMER.BUYER,
              title: 'Buyer Details'
            })}
            {CustomerField({
              customerType: CUSTOMER.SELLER,
              title: 'Owner Details'
            })}
            {CustomerField({
              customerType: CUSTOMER.APPLICANT,
              title: 'Applicant Details'
            })}
          </div>
        </div>
      </div>
      <div className='col-12 mt80'>
        <button onClick={handleStatusChange} className='btn btn-primary mb20'>
          {rcDealStatus === "OPEN" ? `Cancel Deal` : `Re-open Deal`}
        </button>
        <div className='row'>
          <div className='col-12'>
            <p className='fs16 font-weight-bold'>
              Token Details (Case Type:{' '}
              {dealinfo.rcCaseTypeStatus || 'Not Set '}| File Completion Status:{' '}
              {dealinfo.fileCompletionStatus || Locale.NA}| KYC Status:{' '}
              {dealinfo.kycStatus || 'Not Set'})
            </p>
          </div>
          {!isArrayEmpty(dealHistory) ? (
            dealHistory.map((item, index) => {
              return (
                <div key={`doc_history_${index}`} className='row col-12'>
                  <div className='col-2'>
                    <label className='font-weight-bold capitalize'>
                      {getformattedDate(item.date)}
                    </label>
                  </div>
                  <div className='col-9'>: {item.value}</div>
                </div>
              );
            })
          ) : (
            <span className='ml20'>No Records Available</span>
          )}
        </div>
      </div>
    </LoaderWithAccessControl>
  );
}

export default withRouter(DealInformation);
