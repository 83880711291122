import React from 'react';
import DealerForm from '../../components/dealer-form';
import withAdminLayout from '../../../shared/hoc/admin-layout';
import ErrorBoundary from '../../components/common/errorBoundary';
import { withAuthorization } from '../../../shared/utils/firebase';
const DealerFormPage = (props) => {
  return (
    <div>
      <ErrorBoundary>
        <DealerForm {...props} />
      </ErrorBoundary>
    </div>
  );
};
export default withAdminLayout(withAuthorization(() => true)(DealerFormPage));
