import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import UploadButton from '../../../shared/components/upload-button';
import UploadIcon from '../../../shared/utils/images/upload-icon.svg';
import { console_in_dev } from '../../../shared/utils/helper';

const BankUpload = function(props) {
  const [filename, setFilename] = useState();

  const handleUpload = (file) => {
    console_in_dev({ file });
    setFilename(file.name);
    const formData = new FormData();
    formData.append('file', file);
    props.uploadUtrConnected(formData);
  };
  return (
    <div className='container-fluid'>
      <div className='row col-12 upload'>
        <h2 className=''>UTR UPLOAD</h2>
        <div className='col-12  uploadSection'>
          {/* <div className='uploadFile'>
            <UploadButton
              label='Select Doc'
              onChangeHandler={() => {}}
              accept='image/*'
              btnIcon={UploadIcon}
              iconAlt='Replace Icon'
            />
          </div> */}
          <div className='uploadBtn'>
            <span className='mr20'>{filename || ''}</span>
            <UploadButton
              label='Upload'
              onChangeHandler={handleUpload}
              accept='.xlsx'
              btnIcon={UploadIcon}
              iconAlt='Replace Icon'
              isLoading={props.isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(BankUpload);
