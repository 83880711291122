import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import './style.css';
import { SelectComponent } from '../../../client/components/shared';
import CustomButton from '../../../shared/components/button';

import InputText from '../../../shared/components/input-text';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';
import Locale from '../../../shared/utils/locale';
import { isArrayEmpty, showToast } from '../../../shared/utils/helper';
import {
  STOCK_OUT_REASONS_OPTIONS,
  inventoryStatusConstants as INVENTORY_STATUS_CONSTANT,
  INVENTORY_STATUS,
  STOCK_OUT_REASONS_CONSTANTS
} from '../../../shared/utils/constants/inventory';
import LogsWithAccordian from '../logs-with-accordian';
import { TOAST_TYPES } from '../../../shared/utils/constants';

const READABLE_FIELDS = [
  { label: 'Appointment Id', keyName: 'appointmentId' },
  { label: 'Current Inventory Status', keyName: 'inventoryStatus' },
  { label: 'Next Inventory Status', keyName: 'nextInventoryStatus' }
];

const headers = [
  {
    label: 'Inventory Status',
    headerKey: 'inventoryStatusLabel',
    colWidth: 'col-2'
  },
  {
    label: 'Stock In Store',
    headerKey: 'stockInStoreLabel',
    colWidth: 'col-4'
  },
  {
    label: 'Stock Out Store',
    headerKey: 'stockOutStoreLabel',
    colWidth: 'col-4'
  },
  {
    label: 'Date',
    headerKey: 'updatedAt',
    colWidth: 'col-2'
    //dataType: 'datetime' //todo:sprint-11 Ask backend to correct date format
  }
];

const COrderInventory = function (props) {
  const [storeOptions, setStoreOptions] = useState();
  const [storeHashed, setStoreHashed] = useState();
  const [stockOutReasonOptions, setStockOutReasonOptions] = useState(
    STOCK_OUT_REASONS_OPTIONS
  );
  const [appointmentId, setAppointmentId] = useState(props.match.params.id);
  const [orderDetails, setOrderDetails] = useState();
  const [formData, setFormData] = useState({});
  const [inventoryLogs, setInventoryLogs] = useState([]);
  const [formKey, setFormKey] = useState(0);

  useEffect(() => {
    if (
      !isArrayEmpty(props.inventoryLogs) &&
      props.inventoryLogs &&
      storeHashed
    ) {
      const inventoryLogs = props.inventoryLogs.map((item) => {
        return {
          ...item,
          inventoryStatusLabel: item.inventoryStatus
            ? INVENTORY_STATUS[item.inventoryStatus]['label']
            : Locale.NA,
          stockInStoreLabel:
            item.stockInStoreId && storeHashed[item.stockInStoreId]
              ? storeHashed[item.stockInStoreId]['label']
              : Locale.NA,
          stockOutStoreLabel:
            item.stockOutStoreId && storeHashed[item.stockOutStoreId]
              ? storeHashed[item.stockOutStoreId]['label']
              : Locale.NA
        };
      });
      setInventoryLogs(inventoryLogs);
    }
  }, [props.inventoryLogs, storeHashed]);

  useEffect(() => {
    props
      .fetchStores()
      .then((response) => {
        try {
          setStoreHashed(response.storeHashed);
          setStoreOptions(response.storeOptions);
          // Inventory is dependent on stores
          props.fetchInventoryLogConnected(appointmentId);
        } catch {
          showToast(Locale.NO_STORES, TOAST_TYPES.INFO);
          setStoreOptions([]);
        }
      })
      .catch((error) => {
        showToast(Locale.NO_STORES, TOAST_TYPES.WARNING);
      });
  }, []);

  useEffect(() => {
    if (props.order && props.order.detail) {
      setOrderDetails(props.order.detail);
      const {
        inventoryStatus,
        nextInventoryStatus,
        storeId
      } = props.order.detail;

      const defaultStockInStore =
        storeId && storeHashed && storeHashed[storeId]
          ? {
              label: storeHashed[storeId]['label'],
              value: storeId
            }
          : null;
      setFormData({
        ...formData,
        appointmentId: appointmentId,
        inventoryStatus: inventoryStatus || '',
        nextInventoryStatus: nextInventoryStatus || '',
        stockInStoreId: defaultStockInStore,
        stockOutReason: null,
        stockOutStoreId: null
      });
    }
  }, [props.order]);

  useEffect(() => {
    if (
      orderDetails &&
      orderDetails.storeId &&
      storeHashed &&
      storeHashed[orderDetails.storeId]
    ) {
      setFormData({
        ...formData,
        stockInStoreId: !orderDetails.storeId
          ? null
          : {
              label: storeHashed[orderDetails.storeId]['label'],
              value: orderDetails.storeId
            }
      });
    }
  }, [storeHashed]);

  const onChangeSelect = (param, selected) => {
    if (selected) {
      setFormData({ ...formData, [param]: selected });
    } else {
      setFormData({ ...formData, [param]: null });
    }
  };

  const updateInventory = (e) => {
    e.preventDefault();
    let payload = {
      appointmentId: formData.appointmentId,
      inventoryStatus: formData.nextInventoryStatus,
      stockInStoreId: formData.stockInStoreId.value
    };
    if (
      formData.inventoryStatus &&
      formData.inventoryStatus === INVENTORY_STATUS_CONSTANT.STOCK_IN
    ) {
      payload = {
        ...payload,
        stockOutReason: formData.stockOutReason.value
      };
      if (formData.stockOutStoreId && formData.stockOutStoreId.value) {
        payload = {
          ...payload,
          stockOutStoreId: formData.stockOutStoreId.value
        };
      }
    }
    props.updateInventoryConnected(payload).then(() => {
      props.fetchInventoryLogConnected(appointmentId);
      props.fetchCarDetailConnected(appointmentId);
    });
  };

  return (
    <LoaderWithAccessControl>
      <form
        key={`inventory_form_${formKey}`}
        onSubmit={(e) => updateInventory(e)}
      >
        <div className='form-group col-md-12'>
          {READABLE_FIELDS.map((item, key) => {
            return (
              <div
                key={`readable_${key}`}
                className='float-left form-group col-md-4'
              >
                <label className='font-weight-bold'>{item.label}</label>
                <InputText
                  type='text'
                  id={item.keyName}
                  className='form-control'
                  name={item.keyName}
                  value={formData[item.keyName] || ''}
                  readOnly={true}
                />
              </div>
            );
          })}

          {orderDetails && (
            <div className='float-left form-group col-md-4'>
              <label className='font-weight-bold'>Stock In Store</label>
              <SelectComponent
                key={`stockInStoreId_${
                  formData.stockInStoreId
                    ? formData.stockInStoreId.value
                    : 'init'
                }`}
                name='stockInStoreId'
                optionsList={storeOptions || []}
                value={formData.stockInStoreId || ''}
                defaultValue={formData.stockInStoreId || ''}
                placeholder={'Select'}
                version='v2'
                onChange={(value) => onChangeSelect('stockInStoreId', value)}
                isRequired={true}
              />
            </div>
          )}
          {orderDetails &&
            formData.inventoryStatus === INVENTORY_STATUS_CONSTANT.STOCK_IN && (
              <div className='float-left form-group col-md-4'>
                <label className='font-weight-bold'>Stock Out Reason</label>
                <SelectComponent
                  key={`stockOutReason`}
                  name='stockOutReason'
                  optionsList={stockOutReasonOptions || []}
                  value={formData.stockOutReason || ''}
                  // defaultValue={state.disposition || ''}
                  placeholder={'Select'}
                  version='v2'
                  onChange={(value) => onChangeSelect('stockOutReason', value)}
                  isRequired={true}
                />
              </div>
            )}

          {formData.stockOutReason &&
            formData.stockOutReason.value ===
              STOCK_OUT_REASONS_CONSTANTS.C2C_STORE_MOVEMENT && (
              <div className='float-left form-group col-md-4'>
                <label className='font-weight-bold'>Stock Out Store</label>
                <SelectComponent
                  key={`stockOutStoreId`}
                  name='stockOutStoreId'
                  optionsList={storeOptions || []}
                  value={formData.stockOutStoreId || ''}
                  // defaultValue={state.disposition || ''}
                  placeholder={'Select'}
                  version='v2'
                  onChange={(value) => onChangeSelect('stockOutStoreId', value)}
                  isRequired={true}
                />
              </div>
            )}

          <div className='row col-md-12'>
            <div className='float-left form-group col-md-4'>
              <CustomButton label={Locale.SAVE} type='submit' />
            </div>
          </div>
        </div>
      </form>
      <div className='wrapperAgentDetails mt-20 p15'>
        <LogsWithAccordian
          title='Inventory Log'
          headers={headers}
          data={inventoryLogs || []}
        />
      </div>
    </LoaderWithAccessControl>
  );
};

export default withRouter(COrderInventory);
