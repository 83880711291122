import { createReducer } from 'reduxsauce';
import Types from './types';
import { console_in_dev } from '../../../shared/utils/helper';

export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  error: null,
  message: null,
  seller: {},
  buyer: {},
  transactionLogs: [],
  paymentModes: []
};

export const fetchPaymentInfoInit = (state = INITIAL_STATE) => {
  return {
    ...state,
    seller: {},
    buyer: {},
    isLoading: true,
    isError: false,
    message: null,
    error: null
  };
};

export const fetchPaymentInfoSuccess = (state = INITIAL_STATE, action) => {
  console_in_dev({ action });
  const {
    response: { data },
    user_type
  } = action.payload;
  return {
    ...state,
    isLoading: false,
    isError: false,
    error: null,
    [user_type]: data // seller or buyer
  };
};

export const fetchPaymentInfoFailure = (state = INITIAL_STATE, action) => {
  const { error, user_type } = action.payload;
  return {
    ...state,
    isLoading: false,
    isError: true,
    error: error,
    [user_type]: {}
  };
};

/* -------------------------------------------------------------------------------- */

export const fetchTransactionLogsInit = (state = INITIAL_STATE) => {
  return {
    ...state,
    transactionLogs: [],
    isLoading: true,
    isError: false,
    message: null,
    error: null
  };
};

export const fetchTransactionLogsSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    isLoading: false,
    isError: false,
    error: null,
    transactionLogs: data
  };
};

export const fetchTransactionLogsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    isLoading: false,
    isError: true,
    error: error
  };
};

/*------------------------------------------------------------------------------*/
export const fetchPaymentModeInit = (state = INITIAL_STATE) => {
  return {
    ...state,
    paymentModes: [],
    isLoading: true,
    isError: false,
    message: null,
    error: null
  };
};

export const fetchPaymentModeSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    isError: false,
    error: null,
    paymentModes: action.payload
  };
};

export const fetchPaymentModeFailure = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;
  return {
    ...state,
    isLoading: false,
    isError: true,
    error: error
  };
};

export const HANDLERS = {
  [Types.FETCH_PAYMENT_INFO_INIT]: fetchPaymentInfoInit,
  [Types.FETCH_PAYMENT_INFO_SUCCESS]: fetchPaymentInfoSuccess,
  [Types.FETCH_PAYMENT_INFO_FAILURE]: fetchPaymentInfoFailure,

  [Types.FETCH_TRANSACTION_LOGS_INIT]: fetchTransactionLogsInit,
  [Types.FETCH_TRANSACTION_LOGS_SUCCESS]: fetchTransactionLogsSuccess,
  [Types.FETCH_TRANSACTION_LOGS_FAILURE]: fetchTransactionLogsFailure,

  [Types.FETCH_PAYMENT_MODE_INIT]: fetchPaymentModeInit,
  [Types.FETCH_PAYMENT_MODE_SUCCESS]: fetchPaymentModeSuccess,
  [Types.FETCH_PAYMENT_MODE_FAILURE]: fetchPaymentModeFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
