import React from "react";
import Accordion from "../../../../shared/accordion";
import ArrowIcon from "../../../../shared/arrow";
import styles from "./styles.module.css";

export const CAccordian = ({
  heading,
  body,
  contentClass,
  accordianClass,
  headingClass
}) => {
  return (
    <Accordion className={`${accordianClass ? accordianClass : styles.cAccordianWrapper}`}>
      {({ onClick, currentVisibleStates }) => (
        <Accordion.Content>
          <div className={`${contentClass ? contentClass : styles.borderWrapper}`}>
            <Accordion.Heading>
              <h2
                className={headingClass}
                onClick={() => onClick({ index: 0 })}
                role="button"
              >
                {heading}
                <ArrowIcon
                  grey={'GreyArrow'}
                  rotateBy={currentVisibleStates[0] ? 180 : 90}
                />
              </h2>
            </Accordion.Heading>
            <Accordion.Body>
              <div
                className={
                  currentVisibleStates[0]
                    ? 'accordionShow mt-3'
                    : 'accordionHide'
                }
              >
                {body}
              </div>
            </Accordion.Body>
          </div>
        </Accordion.Content>
      )}
    </Accordion>
  )
};