import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  message: null,
  arDealerData: {}
};

export const fetchArdealerDataInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true, isError: false, message: null };
};

export const fetchArdealerDataSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    isError: false,
    arDealerData: action.payload
  };
};

export const fetchArDealerFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    isError: true,
    message: action.payload,
    arDealerData: {}
  };
};
export const removeArDealerData = () => {
  return INITIAL_STATE;
};
export const HANDLERS = {
  [Types.FETCH_ARDEALER_INIT]: fetchArdealerDataInit,
  [Types.FETCH_ARDEALER_SUCCESS]: fetchArdealerDataSuccess,
  [Types.FETCH_ARDEALER_FAILURE]: fetchArDealerFailure,
  [Types.REMOVE_ARDEALER_DATA]: removeArDealerData
};

export default createReducer(INITIAL_STATE, HANDLERS);
