import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FinancePaymentTab from './component';
import {
  fetchPaymentInfoConnected,
  fetchTransactionLogsConnected,
  fetchPaymentModeConnected,
  addMorePaymentInfoConnected,
  discardPaymentInfoConnected
} from './actions';
import { fetchFinanceDealInfoByIdConnected } from '../finance-dealInfo/actions';

const mapStateToProps = (state) => {
  const { paymentInfo } = state;

  return {
    paymentInfo
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPaymentInfoConnected,
      fetchTransactionLogsConnected,
      fetchPaymentModeConnected,
      addMorePaymentInfoConnected,
      discardPaymentInfoConnected,
      fetchFinanceDealInfoByIdConnected
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinancePaymentTab);
