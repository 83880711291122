import Types from './types';
import { documentRuleEngineService } from '../../../services';
import { parseById } from '../../helper/parsers';

export const fetchAttributesData = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    documentRuleEngineService
      .fetchAttributesData(params)
      .then((data) => {
        dispatch(fetchAttributesDataSuccess(data));
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addDocumentConfig = (params, body) => (dispatch) => {
  return new Promise((resolve, reject) => {
    documentRuleEngineService
      .addDocumentConfig(params, body)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      })
  });
};

export const fetchDocumentConfig = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    documentRuleEngineService
      .fetchDocumentConfig(params)
      .then((resp) => {
        dispatch(fetchDocumentConfigSuccess(parseById(resp.data.data)));
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      })
  });
};

export const updateDocumentConfig = (params, body) => (dispatch) => {
  return new Promise((resolve, reject) => {
    documentRuleEngineService
      .updateDocumentConfig(params, body)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      })
  });
};

export const deleteDocumentConfig = (id) => () => {
  return new Promise((resolve, reject) => {
    documentRuleEngineService
      .deleteDocumentConfig(id)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      })
  });
};

const fetchAttributesDataSuccess = (data) => ({
  type: Types.FETCH_ATTRIBUTES_DATA_SUCCESS,
  data
});

export const updateAddConfigData = (data) => ({
  type: Types.UPDATE_ADD_CONFIG_DATA,
  data
})

const fetchDocumentConfigSuccess = (data) => ({
  type: Types.FETCH_DOCUMENT_CONFIG_SUCCESS,
  data
});

export const updateDocumentData = (data) => ({
  type: Types.UPDATE_DOCUMENT,
  data
});

export const resetConfigList = () => ({
  type: Types.RESET_CONFIG_LIST
});
