import React, { useEffect, useState } from 'react';
import InputSwitch from '../../../shared/components/input-switch';
import { CAR_INFORMATION, POST_DATA_LIST } from './constant';
import { FUEL_OPTIONS, CLASSIFIED_CAR } from '../../../shared/utils/constants';
import { withRouter } from 'react-router-dom';
const OrdersCarInformation = ({
  carDetails,
  makeList,
  modelList,
  variantList,
  fetchCarDetailConnected,
  getMakeDataConnected,
  submitCarDetail,
  getModelDataConnected,
  getVariantDataConnected,
  match,
  order
}) => {
  const carInfo = order && order.detail;
  const [carData, setCarData] = useState(carInfo);
  const [makeId, setMakeId] = useState();
  const [modelId, setModelId] = useState();
  //console.log('carInfo', carInfo);
  useEffect(() => {
    fetchCarDetailConnected(match.params.id);
  }, []);

  useEffect(() => {
    setCarData(carInfo);
  }, [carInfo]);

  useEffect(() => {
    getMakeDataConnected();
  }, []);
  useEffect(() => {
    if (modelId) {
      getVariantDataConnected(modelId);
    }
  }, [modelId]);

  useEffect(() => {
    if (makeId) {
      getModelDataConnected(makeId);
    }
  }, [makeId]);

  const onChangeDropdown = (name, value) => {
    const valueMap = {
      make: {
        key: 'makeId',
        values: makeList.make_data,
        nameKey: 'make',
        dataKey: 'make_name'
      },
      model: {
        key: 'modelId',
        values: modelList.model_data,
        nameKey: 'model',
        dataKey: 'model_name'
      },
      variant: {
        key: 'variantId',
        values: variantList.variant_data,
        nameKey: 'variant',
        dataKey: 'variant_name'
      },
      fuelType: {
        key: 'label',
        values: FUEL_OPTIONS,
        nameKey: 'fuelType',
        dataKey: 'value'
      }
    };
    if (name === 'make') {
      let details = { ...carData };
      if (makeId !== value.value) {
        details['variant'] = '';
        details['model'] = '';
      }

      setCarData({
        ...details,
        [valueMap[name].nameKey]: value.label,
        [valueMap[name].key]: value.value
      });
    } else if (name === 'model') {
      let details = { ...carData };
      details['variant'] = '';
      setCarData({
        ...details,
        [valueMap[name].nameKey]: value.label,
        [valueMap[name].key]: value.value
      });
    } else {
      setCarData({
        ...carData,
        [valueMap[name].nameKey]: value.label,
        [valueMap[name].key]: value.value
      });
    }
  };

  const onChangeText = (name, value) => {
    let details = { ...carData };
    if (name === 'make') {
      onChangeDropdown(name, value);
      setMakeId(value.value);
    } else if (name === 'model') {
      onChangeDropdown(name, value);
      setModelId(value.value);
    } else if (name === 'variant') {
      onChangeDropdown(name, value);
    } else if (name === 'fuelType') {
      onChangeDropdown(name, value);
    } else {
      details[name] = value;
      setCarData(details);
    }
  };

  const submit = (e) => {
    e.preventDefault();
    let carInformation = {};
    POST_DATA_LIST.map((item, index) => {
      if (carData.hasOwnProperty(item)) {
        carInformation[item] = carData[item];
      }
    });
    submitCarDetail(carInformation);
  };
  const getList = (type) => {
    switch (type) {
      case 'fuel':
        return fuelList();
      case 'make':
        return makeCarList();
      case 'model':
        return modelCarList();
      case 'variant':
        return variantCarList();
      default:
        break;
    }
  };
  const fuelList = () => {
    const list = FUEL_OPTIONS.map((option, index) => {
      return { value: option.value, label: option.label };
    });
    return list;
  };
  const makeCarList = () => {
    const list =
      makeList &&
      makeList.make_data &&
      makeList.make_data.map((option, index) => {
        return { value: option.make_id, label: option.make_name };
      });
    return list || [];
  };
  const modelCarList = () => {
    const list =
      modelList &&
      modelList.model_data &&
      modelList.model_data.map((option, index) => {
        return { value: option.model_id, label: option.model_name };
      });
    return list || [];
  };
  const variantCarList = () => {
    const list =
      variantList &&
      variantList.variant_data &&
      variantList.variant_data.map((option, index) => {
        return { value: option.variant_id, label: option.variant_name };
      });
    return list || [];
  };
  return (
    <form onSubmit={(e) => submit(e)}>
      <div className='boxs'>
        <div className='box-header'>
          <h3 className='box-title'>Generic</h3>
        </div>
        <div className='row'>
          <div className='box-body col-md-12'>
            {CAR_INFORMATION.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div className='float-left form-group col-md-3'>
                    <label className='font-weight-bold'>{item.label}</label>
                    <InputSwitch
                      min={item.min}
                      max={item.max}
                      type={item.type}
                      param={item.type}
                      item={item}
                      name={item['value']}
                      value={carData && carData[item['value']]}
                      onChange={onChangeText}
                      list={getList}
                      readOnly={
                        (carData && carData.carSource !== CLASSIFIED_CAR) ||
                        item.disabled
                      }
                      disabled={item.disabled}
                      required={item.required}
                    />
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className='form-group col-md-8'>
          <div className='rows'>&nbsp;</div>
          <div className='form-group col-md-5'>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={carData && carData.carSource !== CLASSIFIED_CAR}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default withRouter(OrdersCarInformation);
