import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrderSellerTab from './component';
import {
  fetchSellerDetailConnected,
  updateCarSourceConnected,
  updateSellerConnected
} from './actions';

const mapStateToProps = (state) => {
  const {
    sellerInfo: {
      isLoading,
      sellerData: { data }
    }
  } = state;
  return {
    isLoading,
    sellerData: data
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSellerDetailConnected,
      updateCarSourceConnected,
      updateSellerConnected
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OrderSellerTab);
