import { createTypes } from 'reduxsauce';

export default createTypes(
  `
   FETCH_CITY_INSPECTION_STORE_INIT
    FETCH_CITY_INSPECTION_STORE_SUCCESS
    FETCH_CITY_INSPECTION_STORE_FAIL
`,
  {
    prefix: 'citydetailconfig/'
  }
);
