import { USER_STATE_OPTIONS } from '../constants';
const columnDefs = [
  {
    headerName: 'Email Id',
    field: 'emailId',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    cellRenderer: 'LinkRenderer',
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Phone Number',
    width: 160,
    field: 'phone',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    cellRenderer: 'LabelRenderer',
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Status',
    field: 'status',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'status',
      options: Object.values(USER_STATE_OPTIONS)
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: Object.values(USER_STATE_OPTIONS)
    },
    suppressMenu: true
  }
];

export default columnDefs;
