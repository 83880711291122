import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DocumentRejectionReasonsConfigAdd from "./component";
import { addDocumentRejectionReasonsConfig, fetchDocumentRejectionReasonsConfig, updateAddRejectionReasonsConfigData } from "../actions";

const mapStateToProps = ({
  documentRejectionReasonsConfig: {
    addConfig: addConfigData
  },
  documentsList
}) => ({
  documentsList,
  addConfigData
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
  addDocumentRejectionReasonsConfigConnect: addDocumentRejectionReasonsConfig,
  updateAddRejectionReasonsConfigDataConnect: updateAddRejectionReasonsConfigData,
  fetchDocumentRejectionReasonsConfigConnect: fetchDocumentRejectionReasonsConfig
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentRejectionReasonsConfigAdd);
