import Types from './types';
import { financeService } from '../../../services';

// Global actions
import {
  handleHttpStatusError,
  dispatchResetLoaderConnected,
  dispatchSetLoaderConnected
} from '../../../services/helper';
import {
  showToastMessages,
  console_in_dev,
  catchErrorMessage
} from '../../../shared/utils/helper';

//Fetch order details

const fetchPaymentInfoConnected = (user_type, deal_id, withLoader = true) => (
  dispatch
) => {
  dispatchSetLoaderConnected(withLoader);
  withLoader && dispatch(fetchPaymentInfoInit());
  return new Promise((resolve, reject) => {
    financeService
      .fetchPaymentInfo(user_type, deal_id)
      .then((resp) => {
        console_in_dev({ fetchPaymentInfo: resp });
        dispatch(fetchPaymentInfoSuccess({ response: resp.data, user_type }));
        dispatchResetLoaderConnected();
        resolve(resp);
      })
      .catch((error) => {
        dispatch(fetchPaymentInfoFailure({ error, user_type }));
        handleHttpStatusError(error);
        reject(error);
      });
  });
};

const fetchPaymentInfoInit = () => ({
  type: Types.FETCH_PAYMENT_INFO_INIT
});

const fetchPaymentInfoSuccess = (data) => ({
  type: Types.FETCH_PAYMENT_INFO_SUCCESS,
  payload: data
});

const fetchPaymentInfoFailure = (error) => ({
  type: Types.FETCH_PAYMENT_INFO_FAILURE,
  payload: error
});

/* --------- ------------- ------------ --------*/

const fetchTransactionLogsConnected = (
  user_type,
  deal_id,
  withLoader = true
) => (dispatch) => {
  dispatchSetLoaderConnected(withLoader);
  dispatch(fetchTransactionLogsInit());
  return new Promise((resolve, reject) => {
    financeService
      .fetchTransactionLogs(user_type, deal_id)
      .then((resp) => {
        console_in_dev({ fetchTransactionLogs: resp });
        dispatch(
          fetchTransactionLogsSuccess({
            data: resp.data.data ? resp.data.data : [],
            user_type
          })
        );
        dispatchResetLoaderConnected();
        resolve(resp);
      })
      .catch((error) => {
        dispatch(fetchTransactionLogsFailure({ error, user_type }));
        handleHttpStatusError(error);
        reject(error);
      });
  });
};

const fetchTransactionLogsInit = () => ({
  type: Types.FETCH_TRANSACTION_LOGS_INIT
});

const fetchTransactionLogsSuccess = (data) => ({
  type: Types.FETCH_TRANSACTION_LOGS_SUCCESS,
  payload: data
});

const fetchTransactionLogsFailure = (error) => ({
  type: Types.FETCH_TRANSACTION_LOGS_FAILURE,
  payload: error
});

const fetchPaymentModeConnected = () => (dispatch) => {
  dispatch(fetchPaymentModeInit());
  return new Promise((resolve, reject) => {
    financeService
      .fetchPaymentMode()
      .then((resp) => {
        dispatch(fetchPaymentModeSuccess(resp.data.data.payment_modes));
        resolve(resp);
      })
      .catch((error) => {
        dispatch(fetchPaymentModeFailure(error));
        handleHttpStatusError(error);
        reject(error);
      });
  });
};

const fetchPaymentModeInit = () => ({
  type: Types.FETCH_PAYMENT_MODE_INIT
});

const fetchPaymentModeSuccess = (data) => ({
  type: Types.FETCH_PAYMENT_MODE_SUCCESS,
  payload: data
});

const fetchPaymentModeFailure = (error) => ({
  type: Types.FETCH_PAYMENT_MODE_FAILURE,
  payload: error
});
/*------------------------------------------------------------ */

const addMorePaymentInfoConnected = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    financeService
      .addMorePaymentInfo(data)
      .then((resp) => {
        resolve(resp.data.data);
        showToastMessages(resp.data.message);
      })
      .catch((error) => {
        reject(catchErrorMessage(error));
      });
  });
};

const discardPaymentInfoConnected = (id, payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    financeService
      .discardPaymentInfo(id, payload)
      .then((resp) => {
        resolve(resp.data.data);
        showToastMessages(resp.data.message);
      })
      .catch((error) => {
        reject(catchErrorMessage(error));
      });
  });
};

export {
  fetchPaymentInfoConnected,
  fetchTransactionLogsConnected,
  fetchPaymentModeConnected,
  discardPaymentInfoConnected,
  addMorePaymentInfoConnected
};
