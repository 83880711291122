import Types from './types';
import { orderService } from '../../../services';

// Global actions
import {
  setAccessLoaderConnected,
  resetAccessLoaderConnected,
  setForbiddenConnected
} from '../../../shared/components/loader-with-access-control/actions';
import { handleHttpStatusError } from '../../../services/helper';

//Fetch order inspection details
const fetchInspectionDetailInit = () => ({
  type: Types.FETCH_INSPECTION_DETAIL_INIT
});

const fetchInspectionDetailSuccess = (data) => ({
  type: Types.FETCH_INSPECTION_DETAIL_SUCCESS,
  payload: data
});

const fetchInspectionDetailFail = (error) => ({
  type: Types.FETCH_INSPECTION_DETAIL_FAIL,
  error
});

const fetchInspectionDetailConnected = (appointmentId) => (dispatch) => {
  dispatch(setAccessLoaderConnected());
  dispatch(fetchInspectionDetailInit());
  return new Promise((resolve, reject) => {
    orderService
      .fetchInspectionDetail(appointmentId)
      .then((resp) => {
        resolve(resp);
        dispatch(fetchInspectionDetailSuccess(resp.data));
        dispatch(resetAccessLoaderConnected());
      })
      .catch((error) => {
        dispatch(fetchInspectionDetailFail(error));
        handleHttpStatusError(error);
        reject(error);
      });
  });
};

export { fetchInspectionDetailConnected };
