import { regionAllocationService, taskManagementService } from '../../../services';

export const updateAgentRegions = (body) => () => {
  return regionAllocationService
    .updateAgentRegions(body)
};

export const fetchAgents = () => () => {
  return taskManagementService
    .fetchAgents();
};
