import { config } from '../config/local';

export default (api) => {
  const updateAgentRegions = (body) => {
    return new Promise((resolve, reject) => {
      const url = config.api.c2c.regionAllocation.updateAgentRegions.path;
      api.put(url, body)
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            reject(error);
        })
    });
  }

  return {
    updateAgentRegions
  };
};