export const FIELDS = {
  old_deal_id: {
    label: 'Old Deal',
    keyName: 'old_deal_id',
    type: 'text',
    required: true,
    elementType: 'input'
  },
  utr: {
    label: 'UTR',
    keyName: 'utr',
    type: 'text',
    required: true,
    elementType: 'input'
  },
  proof_switch: {
    label: 'Proof switch',
    keyName: 'proof_switch',
    type: 'select',
    required: true,
    elementType: 'select'
  },
  amount: {
    label: 'Switch Amount',
    keyName: 'amount',
    type: 'number',
    required: true,
    elementType: 'input'
  }
};

export const getInitValues = () => {
  let initObj = {};
  Object.keys(FIELDS).forEach((item) => {
    initObj[item] = '';
  });
  return initObj;
};

export const SWITCH_PROOF = {
  BUYER: {
    label: 'Buyer',
    value: 'buyer'
  },
  SELLER: {
    label: 'Seller',
    value: 'seller'
  }
};
