import { useEffect, useState } from 'react';
import { rcManagementService } from '../../../../services';
import { useIsRcOthers } from '../../../../shared/hooks';

export const useLogs = ({ dealId }) => {
  const isOtherRcView = useIsRcOthers();
  const [logs, setLogs] = useState({ data: [], total: 0 });
  const [pagination, setPagination] = useState({ skip: 0, take: 10 });

  const getRcVerificationLogs = (params) => {
    return isOtherRcView ? rcManagementService.fetchRcOthersVerificationLogs(params) : rcManagementService.fetchRcVerificationLogs(params)
  }

  useEffect(() => {
    const page = pagination.skip / pagination.take;
    const size = pagination.take;
    const params = {
      [isOtherRcView ? "rcApplicationId": "dealId"]: dealId,
      page,
      size
    };

    getRcVerificationLogs(params)
    .then(({ items, total }) => {
      setLogs({ data: items, total });
    })
  }, [pagination.skip]);

  return { logs, pagination, setPagination };
}