import {
  HpTypes,
  hpRequestTypes
} from '../../constants/constants';

export default [
  {
    headerName: 'Hypothecation ID',
    width: 220,
    resizable: false,
    field: 'id',
    renderField: 'id',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    cellRenderer: 'LinkRenderer',
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Hypothecation Type',
    width: 220,
    field: 'type',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'type',
      options: Object.values(HpTypes)
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: Object.values(HpTypes)
    },
    suppressMenu: true
  },
  {
    headerName: 'Loan ID',
    width: 225,
    field: 'loanId',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    suppressSizeToFit: false,
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'App ID',
    width: 200,
    field: 'appointmentId',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    suppressSizeToFit: false,
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Chassis No.',
    width: 235,
    field: 'chassisNo',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    suppressSizeToFit: false,
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Engine No.',
    width: 235,
    field: 'engineNo',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    suppressSizeToFit: false,
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Registration No.',
    width: 200,
    field: 'registrationNo',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    suppressSizeToFit: false,
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Request Status',
    width: 200,
    field: 'status',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'status',
      options: Object.values(hpRequestTypes)
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: Object.values(hpRequestTypes)
    },
    suppressMenu: true
  }
];
