const Locale = {
  NO_ACCESS: 'You do not have access to this section.',
  UNAUTHORISED: 'Unable to validate user.',
  DOWNLOAD_FAILED: 'Unable to download file',
  CONVERT_GS_CAR: 'Convert To GS Car',
  SAVE: 'Save',
  DOWNLOAD: 'Download',
  SAVING: 'Saving ...',
  UPDATING: 'Updating ...',
  NO_DATA: 'Data not available !!',
  BTN_GENERATE_INVOICE: 'Generate Invoice',
  GENERATE_INVOICE_SUCCESS: 'Invoice Generated Successfully*',
  NA: 'n/a',
  NOT_AVAILABLE: 'Not Available',
  MAX_CHARACTER_ERROR: 'Maximum character exceeded',
  CHARACTER_REMAINING: ' character remaining ',
  NO_STORES: 'Unable to load Stores *',
  VALIDATE: 'Validate',
  DISCARD: 'Discard',
  PROOF_SWITCH_AMOUNT_BUYER_VALIDATION:
    'Amount in the old deal ID for buyer is not sufficient',
  PROOF_SWITCH_AMOUNT_SELLER_VALIDATION:
    'Amount in the old deal ID for seller is not sufficient'
};

export default Locale;
