import { config } from './../config/local';
import { buildQuery } from '../../services/helper';
import { IS_ENV_DEV, IS_MOCK } from '../../shared/utils/constants';

export default (api) => {
  const getStores = (params = { page: 0, size: 10 }) => {
    const url = config.api.c2c.stores.path;
    const queryStr = params ? `?${buildQuery(params)}` : '';
    return api
      .get(url + queryStr)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        if (data) {
          return data;
        } else {
          return {
            page: 0,
            totalPages: 0,
            totalRecords: 0,
            response: []
          };
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
  const fetchStoreGridInfo = (params = { page: 0, size: 10 }) => {
    const path = config.api.c2c.stores.path;
    const queryStr = params ? `?${buildQuery(params)}` : '';
    const url = path + queryStr;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };
  const fetchStoreDetails = (storeId) => {
    const path = config.api.c2c.stores.path;
    const url = `${path}/${storeId}`;
    return api.get(url);
  };

  const addStore = (data) => {
    const path = config.api.c2c.stores.path;
    const url = `${path}`;
    return api.put(url, data);
  };
  const fetchStores = () => {
    const path = config.api.c2c.storesAll.path;
    const url = path;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };
  return {
    fetchStoreDetails,
    addStore,
    getStores,
    fetchStoreGridInfo,
    fetchStores
  };
};
