import React from 'react';
import CustomCheckBox from '../../../shared/components/custom-checkbox';
import Accordion from '../../../shared/accordion';
import ArrowIcon from '../../../shared/arrow';
import InputRadioButton from '../../../shared/input-radio-button';
import styles from './style.module.css';
import { getPaymentStatusQuestions } from '../../constants/constants';
import { useIsRcOthers } from '../../../shared/hooks';


const PaymentStatus = ({
  data = {},
  payment_received_enabled,
  handlePaymentStatusChange
}) => {
  const isOtherRcView = useIsRcOthers();

  return (
    <>
      <Accordion>
        {({ onClick, currentVisibleStates }) => (
          <Accordion.Content>
            <div className='borderWrapper'>
              <Accordion.Heading>
                <h2
                  className='font-weight-bold sellerDocuments p0'
                  onClick={() => onClick({ index: 0 })}
                >
                  Other Information of file
                  <ArrowIcon
                    grey={'GreyArrow'}
                    rotateBy={currentVisibleStates[0] ? 180 : 90}
                  />
                </h2>
              </Accordion.Heading>
              <Accordion.Body>
                <table
                  className={
                    currentVisibleStates[0]
                      ? 'accordionShow table mt-20'
                      : 'accordionHide'
                  }
                >
                  <tbody>
                    {getPaymentStatusQuestions(isOtherRcView).map((item, index) => {
                      switch (item.type) {
                        case 'checkbox':
                          return (
                            <tr key={index}>
                              <td width='50%'>{item.question}</td>
                              <td width='50%'>
                                <div
                                  className={
                                    'inputRadioWrap rcsellerDocumentBox'
                                  }
                                >
                                  <div className='row'>
                                    {item.keyname &&
                                      item.keyname.map((ckItem, ckIndex) => {
                                        return (
                                          <div
                                            className={`col-4 ${
                                              styles.radioFlex
                                            }`}
                                          >
                                            <CustomCheckBox
                                              key={`${ckItem.key}`}
                                              id={`${ckItem.key}`}
                                              name={`${ckItem.key}`}
                                              label={ckItem.label}
                                              value={data[ckItem.key]}
                                              onClickCallback={
                                                handlePaymentStatusChange
                                              }
                                              checkedStatus={
                                                data[ckItem.key] ? true : false
                                              }
                                              className={styles.checkbox}
                                            />
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );

                        default:
                          return (
                            <tr key={index}>
                              <td width='50%'>{item.question}</td>
                              <td width='50%'>
                                <div
                                  className={
                                    'inputRadioWrap rcsellerDocumentBox'
                                  }
                                >
                                  <div className='row'>
                                    <div
                                      className={`col-6 ${styles.radioFlex}`}
                                    >
                                      <InputRadioButton
                                        labelId={item.keyname + 'Yes'}
                                        id={item.keyname + 'Yes'}
                                        name={item.keyname}
                                        value='Yes'
                                        text='Yes'
                                        // isDisabled = {!payment_received_enabled && item.keyname === 'payment_received'? true : false}
                                        onClickCallback={(e) =>
                                          handlePaymentStatusChange(e)
                                        }
                                        checked={data[item.keyname] === 'Yes'}
                                      />
                                    </div>
                                    <div
                                      className={`col-6 ${styles.radioFlex}`}
                                    >
                                      <InputRadioButton
                                        labelId={item.keyname + 'No'}
                                        id={item.keyname + 'No'}
                                        name={item.keyname}
                                        value='No'
                                        text='No'
                                        // isDisabled = {!payment_received_enabled && item.keyname === 'payment_received'? true : false}
                                        onClickCallback={(e) =>
                                          handlePaymentStatusChange(e)
                                        }
                                        checked={data[item.keyname] === 'No'}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                      }
                    })}
                  </tbody>
                </table>
              </Accordion.Body>
            </div>
          </Accordion.Content>
        )}
      </Accordion>
    </>
  );
};

export default PaymentStatus;
