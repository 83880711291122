import React,{useState} from "react";
import styles from  './styles.module.css';
import InputText from "../../shared/components/input-text";

const FinanceDealInfoBuyBackPopup = ({isCngDeal, returnFees: returnFeesInitialValue, onSubmit, onClose}) => {
    const [processing, setProcessing] = useState(false);
    const [challan, setChallan] = useState(0.0);
    const [otherHoldback, setOtherHoldback] = useState(0.0);
    const [rcHoldback, setRcHoldback] = useState(0.0);
    const [returnFees, setReturnFees] = useState(returnFeesInitialValue)
    const cancellationCharges = isCngDeal ? 15000 : 0;
    
    const onBuybackInitiated = async () => {
        setProcessing(true);
        const params = {
            "rcHoldback": rcHoldback,
            "challanHoldback": challan,
            "otherHoldback": otherHoldback,
            "cngHoldback": cancellationCharges,
            returnFees,
        };
        try {
            await onSubmit(params);
        } catch (err) {
            setProcessing(false);
            throw err;
        }
        onClose();
    };

    return (
        <div className={styles.adminPopup}> 
            <h2 className={styles.adminPopupHeader}>{`Initiate Post Deliver Cancellation / Buyback`}</h2>
            <div className='row'>
                <div className='col-6'>
                    <label htmlFor={'challan' }>{'Challan'}</label>
                    <InputText 
                        id={'challan'}
                        name={'challan'}
                        type='number'
                        className={styles.adminPopupText}
                        value={challan}
                        onChange={(e)=> setChallan(e.target.value)} 
                    />
                </div>
                <div className='col-6'>
                    <label htmlFor={'otherHoldback' }>{'Other Holdback'}</label>
                    <InputText 
                        id={'otherHoldback'}
                        name={'amount'}
                        type='number'
                        className={styles.adminPopupText}
                        value={otherHoldback}
                        onChange={(e)=> setOtherHoldback(e.target.value)} 
                    />
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-6'>
                    <label htmlFor={'rcHoldback' }>{'RC Holdback'}</label>
                    <InputText 
                        id={'rcHoldback'}
                        name={'rcHoldback'}
                        type='number'
                        className={styles.adminPopupText}
                        value={rcHoldback}
                        onChange={(e)=> setRcHoldback(e.target.value)} 
                    />
                </div>
                <div className='col-6'>
                    <label htmlFor={'cancellationCharges'}>{'Cancellation Charges'}</label>
                    <InputText 
                        id={'cancellationCharges'}
                        name={'cancellationCharges'}
                        type='number'
                        className={styles.adminPopupText}
                        value={cancellationCharges}
                        disabled
                    />
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-6'>
                    <label htmlFor={'returnFees'}>{'Return Fees'}</label>
                    <InputText 
                        id={'returnFees'}
                        name={'returnFees'}
                        type='number'
                        className={styles.adminPopupText}
                        value={returnFees}
                        onChange={(e)=> setReturnFees(e.target.value)} 
                    />
                </div>
            </div>
            <div className={`${styles.modalButton} text-right`}>
                <button className='btn btn-primary' onClick={onBuybackInitiated} disabled={processing}>Submit</button>
                <button className='btn btn-secondary ml10' onClick={onClose} disabled={processing}>Cancel</button>
            </div>
        </div>
    );
};

export default FinanceDealInfoBuyBackPopup;
