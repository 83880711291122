import React from 'react';
import { isRouteAuthorised } from '../../utils/helper';
import { getIsLoggedInFromLocalStorage } from '../../utils/helper';
import NoAccess from '../../components/no-access/component';

const withAuthorization = (condition) => (Component) => {
  const WithAuthorization = (props) => {
    const isLoggedIn = getIsLoggedInFromLocalStorage();

    return isRouteAuthorised(props.match.path) ? (
      <Component {...props} />
    ) : (
      <NoAccess />
    );
  };
  return WithAuthorization;
};
export default withAuthorization;
