import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrderSellerTab from './component';
import { updateOrderConnected } from './actions';

const mapStateToProps = (state) => {
  const {
    sellerInfo: {
      isLoading,
      sellerData: { data }
    },
    order: { order: orderDetails },
    basicDetailInfo: { isUpdating }
  } = state;
  return {
    isLoading,
    sellerData: data,
    orderDetails,
    isUpdating
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateOrderConnected
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderSellerTab);
