import React from "react";
import { SalePanel } from "../sales-panel";

const OutBoundDetails = (props) => {
    const { customerDetails, lmsLeadId } = props;

    if (!customerDetails) return null;
    if (!customerDetails.userId) return null;
    return (
        <SalePanel userId={customerDetails.userId} lmsLeadId={lmsLeadId}/>
    );
};

export default OutBoundDetails;