import './style/style.css';
import { Link, Redirect } from 'react-router-dom';
import {
  getAuthorisedRoutes
} from '../../../shared/utils/helper';
import { SIDEBAR_GROUP } from '../../../router/config_roles';
import { C2C_LMS_ROLES, checkForUserRoles } from '../../utils/constants';

const AdminSideBar = ({
  updateItemIndex,
  selectedItemIndex,
  resetAllGridModelConnected
}) => {
  const authorisedRoutes = getAuthorisedRoutes();

  const toggleClass = (id) => {
    resetAllGridModelConnected();
    updateItemIndex(id);
  };

  if (!!checkForUserRoles([C2C_LMS_ROLES.CC_AGENT_FOR_C2C, C2C_LMS_ROLES.DSA_AGENT_FOR_C2C])) {
    return <Redirect to='/welcome' />;
  }

  return (
    <aside className='main-sidebar'>
      <section className='sidebar'>
        <ul className='sidebar-menu tree' data-widget='tree'>
          {Object.values(SIDEBAR_GROUP).map((sidebarItem, sidebarIndex) => {
            return (
              <span key={`sidebar_${sidebarIndex}`}>
                {sidebarItem.label}
                {authorisedRoutes.map((item, index) => {
                  return (
                    item.isVisible && item.isVisible() &&
                    item.group === sidebarItem.key && (
                      <li
                        key={item.key}
                        className={selectedItemIndex === index ? 'active' : ''}
                        onClick={() => toggleClass(index)}
                      >
                        <Link to={item.path}>{item.label}</Link>
                      </li>
                    )
                  );
                })}
              </span>
            );
          })}
          {authorisedRoutes.map((item, index) => {
            return (
              item.isVisible && item.isVisible() &&
              !item.group && (
                <span
                  key={item.key}
                  className={
                    selectedItemIndex === index ? 'active dealer' : 'dealer'
                  }
                  onClick={() => toggleClass(index)}
                >
                  <Link to={item.path}>{item.label}</Link>
                </span>
              )
            );
          })}
        </ul>
      </section>
    </aside>
  );
};

export default AdminSideBar;
