export const getB2CLmsPanelUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'qa':
      return 'https://b2c-lms-panel.qac24svc.dev';
    case 'stage':
      return 'https://stage-b2c-lms-panel.qac24svc.dev';
    case 'prod':
      return 'https://b2c-lms-panel.c24.tech';
    default:
      return 'https://b2c-lms-panel.qac24svc.dev';
  }
}