import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  message: null,
  data: {}
};

export const fetchPricingLogsRequested = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    isError: false,
    message: null
  };
};
export const fetchPricingLogsSuccess = (state = INITIAL_STATE, action) => {
  const {
    payload: { data }
  } = action;

  return {
    ...state,
    isLoading: false,
    isError: false,
    message: null,
    data: data
  };
};

export const fetchPricingLogsFailure = (state = INITIAL_STATE, action) => {
  const {
    payload: { message }
  } = action;
  return {
    ...state,
    isLoading: false,
    isError: true,
    message: message,
    data: {}
  };
};

export const HANDLERS = {
  [Types.FETCH_PRICING_LOGS_REQUESTED]: fetchPricingLogsRequested,
  [Types.FETCH_PRICING_LOGS_SUCCESS]: fetchPricingLogsSuccess,
  [Types.FETCH_PRICING_LOGS_FAILURE]: fetchPricingLogsFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
