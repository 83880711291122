export const SERVICE_DETAILS = [
  {
    label: 'Appointment Id',
    apiKey: 'appointmentId',
    isEnabled: () => true,
    element_type: 'text',
    data_type: 'number'
  },
  {
    label: 'Registration Number',
    apiKey: 'registration',
    element_type: 'text',
    isEnabled: () => true,
    format: 'capitalize'
  },
  {
    label: 'Deal Status ',
    apiKey: 'dealStatus',
    isEnabled: () => false,
    element_type: 'text'
  },
  {
    label: 'Owner HP',
    apiKey: 'ownerHp',
    isEnabled: () => true,
    element_type: 'text'
  },
  {
    label: 'Owner Bank NOC received',
    apiKey: 'ownerBankNocReceived',
    isEnabled: () => false,
    element_type: 'text'
  },
  {
    label: 'Owner Form 35 received',
    apiKey: 'ownerForm35Received',
    isEnabled: () => false,
    element_type: 'text'
  },
  {
    label: 'Booking ID',
    apiKey: 'bookingId',
    isEnabled: () => false,
    element_type: 'text'
  },
  {
    label: 'Loan ID',
    apiKey: 'loanId',
    isEnabled: () => true,
    element_type: 'text'
  },
  {
    label: 'Dealer ID',
    apiKey: 'dealId',
    isEnabled: () => false,
    element_type: 'text'
  },
  {
    label: 'Dealer Name',
    apiKey: 'dealName',
    isEnabled: () => false,
    element_type: 'text'
  },
  {
    label: 'B2B Partner Name',
    apiKey: 'b2bPartnerName',
    isEnabled: () => true,
    element_type: 'text'
  },
  {
    label: 'Segment',
    apiKey: 'segment',
    isEnabled: () => false,
    element_type: 'text'
  },
  {
    label: 'Service Type',
    apiKey: 'serviceTypeDisplayValue',
    isEnabled: () => false,
    element_type: 'text'
  },
  {
    label: 'Service Details',
    apiKey: 'serviceDetailsDisplayValue',
    isEnabled: () => false,
    element_type: 'text'
  },
  {
    label: 'Service Cost',
    apiKey: 'totalServiceCost',
    isEnabled: () => false,
    element_type: 'text'
  },
  {
    label: 'Buyer Holdback',
    apiKey: 'buyerHoldback',
    isEnabled: () => false,
    element_type: 'text'
  },
  {
    label: 'City',
    apiKey: 'rcTransferCity',
    isEnabled: () => false,
    element_type: 'text'
  },
  {
    label: 'State',
    apiKey: 'state',
    isEnabled: () => false,
    element_type: 'text'
  },
  {
    label: 'Challan Number',
    apiKey: 'challanNumber',
    isEnabled: () => true,
    element_type: 'text',
    data_type: 'number'
  },
  {
    label: 'Challan Amount',
    apiKey: 'challanAmount',
    isEnabled: () => true,
    element_type: 'text',
    data_type: 'number'
  },
  {
    label: 'Correction Type',
    apiKey: 'correctionType',
    isEnabled: () => false,
    element_type: 'text'
  },
  {
    label: 'Correction Segment',
    apiKey: 'correctionSegment',
    isEnabled: () => false,
    element_type: 'text'
  },
  {
    label: 'Old HP Bank Name',
    apiKey: 'currentHPBankName',
    isEnabled: () => true,
    element_type: 'text'
  },
  {
    label: 'New HP Bank Name',
    apiKey: 'newHPBankName',
    isEnabled: () => true,
    element_type: 'text'
  },
  {
    label: 'Requester Email',
    apiKey: 'requesterEmail',
    isEnabled: () => false,
    element_type: 'text'
  },
];

export const RC_OTHERS_CUSTOMER_INFO = {
  buyer: [
    {
      label: 'Buyer Name',
      apiKey: 'buyerName',
      isEnabled: () => true,
      element_type: 'text'
    },
    {
      label: 'Loan Name',
      apiKey: 'buyerLoanName',
      isEnabled: () => true,
      element_type: 'text'
    },
    {
      label: 'Buyer Number',
      apiKey: 'buyerNumber',
      isEnabled: () => true,
      element_type: 'text',
      data_type: 'phone'
    },
    {
      label: 'Buyer Email',
      apiKey: 'buyerEmailId',
      isEnabled: () => true,
      element_type: 'email'
    },
    {
      label: 'Buyer Address',
      apiKey: 'buyerAddress',
      isEnabled: () => true,
      element_type: 'text'
    },
    {
      label: "Father's Name",
      apiKey: 'fathersName',
      isEnabled: () => true,
      element_type: 'text'
    },
    {
      label: 'Address on RC',
      apiKey: 'addressOnRc',
      isEnabled: () => true,
      element_type: 'text'
    },
  ],
  seller: [
    {
      label: 'Owner Name',
      apiKey: 'ownerName',
      isEnabled: () => true,
      element_type: 'text'
    },
    {
      label: 'Owner Number',
      apiKey: 'ownerNumber',
      isEnabled: () => true,
      element_type: 'text',
      data_type: 'phone'
    },
    {
      label: 'Owner Email',
      apiKey: 'ownerEmailId',
      isEnabled: () => true,
      element_type: 'email'
    }
  ],
  applicant: [
    {
      label: 'Applicant Name',
      apiKey: 'applicantName',
      isEnabled: ({ serviceCompleted }) => !serviceCompleted,
      element_type: 'text',
    },
    {
      label: 'Applicant Number',
      apiKey: 'applicantNo',
      isEnabled: ({ serviceCompleted }) => !serviceCompleted,
      element_type: 'text',
      data_type: 'phone'
    }
  ],
  serviceDetails: SERVICE_DETAILS,
};

