import React, { useEffect, useState } from 'react';
// import ArrowIcon from "../../../shared/arrow";
import PricingService from '../pricing-services';
import PricingMarkupslab from '../pricing-markupslab';
import { storePricingApi } from '../../../services';
import Modal from '../../../shared/modal';
import getSlabNumber from '../../utils/slabNumber';
import './style.css';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';

const Pricing = ({ id }) => {
  const [pricingDetails, setPricingDetails] = useState({});
  const [initialState, setInitialState] = useState(null);

  useEffect(() => {
    if (id) {
      storePricingApi
        .getPricing(id)
        .then((res) => {
          setPricingDetails(res);
          setInitialState(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const onChangeAmount = (e, index) => {
    const details = { ...pricingDetails };
    details.detail[index][e.target.name] = e.target.value;
    setPricingDetails({ ...details });
  };

  const reset = (e, index) => {
    let details = null;
    details = JSON.parse(JSON.stringify(pricingDetails));
    let initialStates = JSON.parse(JSON.stringify(initialState));
    details.detail[index].serviceMarkUps = {
      ...initialState.detail[index].serviceMarkUps
    };
    details.detail[index].serviceMarkUps.isPercentage =
      initialStates.detail[index].serviceMarkUps.isPercentage;
    setPricingDetails({ ...details });
  };

  const onChangeSlab = (e, index, isPercent) => {
    const details = JSON.parse(JSON.stringify(pricingDetails));
    if (isPercent) {
      if (e.target.value <= 100)
        details.detail[index].serviceMarkUps[
          e.target.name
        ] = e.target.value.replace(/[^0-9]/g, '');
      else {
        alert('please Enter value in %');
      }
    } else {
      details.detail[index].serviceMarkUps[
        e.target.name
      ] = e.target.value.replace(/[^0-9]/g, '');
    }
    setPricingDetails(details);
  };

  const onChangeChechbox = (e, index) => {
    const details = { ...pricingDetails };
    details.detail[index][e.target.name] = e.target.checked;
    setPricingDetails({ ...details });
  };

  const onChangeSlabChechbox = (e, index) => {
    let details = null;
    if (e.target.checked === false) {
      details = JSON.parse(JSON.stringify(pricingDetails));
      details.detail[index].serviceMarkUps[e.target.name] = e.target.checked;
      setPricingDetails({ ...details });
    } else {
      details = JSON.parse(JSON.stringify(pricingDetails));
      details.detail[index].serviceMarkUps = resetMarkups(
        details.detail[index].serviceMarkUps
      );
      details.detail[index].serviceMarkUps[e.target.name] = e.target.checked;
      setPricingDetails({ ...details });
    }
  };

  const resetMarkups = (serviceMarkUps) => {
    let count = getSlabNumber(serviceMarkUps);
    for (let i = 1; i <= count; i++) {
      serviceMarkUps[`slab${i}`] = 0;
    }
    return serviceMarkUps;
  };
  const handleSave = (e, close) => {
    storePricingApi.submitPricingData(id, pricingDetails.detail);
    close();
  };

  return (
    <LoaderWithAccessControl>
      <PricingService
        serviceDetails={pricingDetails.detail}
        onChangeAmount={onChangeAmount}
        onChangeChechbox={onChangeChechbox}
      />
      <PricingMarkupslab
        markUpDetails={pricingDetails.detail}
        onChangeSlab={onChangeSlab}
        onChangeSlabChechbox={onChangeSlabChechbox}
        reset={reset}
      />
      <div className='col-md-12 text-left'>
        <Modal clickHandler={handleSave} />
        {/* <button
            type="button"
            onClick={handleSave}
            className="btn btn-primary">
            Save
            </button> */}
      </div>
    </LoaderWithAccessControl>
  );
};

export default Pricing;
