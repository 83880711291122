import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ArDealerForm from './component';
import {
  StateActions,
  CityActions,
  ArDealerAction,
  StoreActions
} from '../../actions';
import {
  addArdealerConnected,
  fetchArDealerInfoByIdConnected,
  removeArDealerDetailConnected
} from './actions';

const mapStateToProps = ({ arDealerInfo: { arDealerData } }) => {
  return {
    arDealerData
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchStates: StateActions.fetchStates,
      fetchCities: CityActions.fetchCities,
      fetchZones: StoreActions.fetchAllZones,
      removeArDealerDetailConnected,
      addArdealerConnected,
      fetchArDealerInfoByIdConnected
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ArDealerForm);
