import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DealInformation from './component';
import { setRcDealInfo } from './actions';
import { withRouter } from 'react-router-dom';
import { updateOthersRcStatus } from '../../actions/rcManagementAction';

const mapStateToProps = (state) => {
  const { detail, loaderInfo } = state;
  return {
    detail,
    loaderInfo
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRcDealInfo: (data) => setRcDealInfo(data),
      updateOthersRcStatusConnect: updateOthersRcStatus
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DealInformation));
