import React from 'react';
import Accordion from '../../../shared/accordion';
import ArrowIcon from '../../../shared/arrow';
import LogsGrid from './logs-grid/LogsGrid';

export const RcVerificationLogs = ({ dealId }) => {
  return (
    <Accordion>
      {({ onClick, currentVisibleStates }) => (
        <Accordion.Content>
          <div className='borderWrapper'>
            <Accordion.Heading>
              <h2
                className='font-weight-bold rcDocuments p0'
                onClick={() => onClick({ index: 0 })}
              >
                RC Verification Logs
                <ArrowIcon
                  grey={'GreyArrow'}
                  rotateBy={currentVisibleStates[0] ? 180 : 90}
                />
              </h2>
            </Accordion.Heading>
            <Accordion.Body>
              <div className={`${
                  currentVisibleStates[0]
                    ? 'accordionShow p-2 py-4'
                    : 'accordionHide'
                }`}>
                <LogsGrid dealId={dealId} />
              </div>
            </Accordion.Body>
          </div>
        </Accordion.Content>
      )}
    </Accordion>
  )
}