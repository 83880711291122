import React from 'react';
import withAdminLayout from '../../../shared/hoc/admin-layout';
import ErrorBoundary from '../../components/common/errorBoundary';
import { withAuthorization } from '../../../shared/utils/firebase';
import CaseTypessConfig from '../../components/case-types-config';

const CaseTypeRules = (props) => {
  return (
    <div>
      <ErrorBoundary>
        <CaseTypessConfig />
      </ErrorBoundary>
    </div>
  );
};
export default withAdminLayout(withAuthorization(() => true)(CaseTypeRules));