import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  INVENTORY_UPDATE_INIT
  INVENTORY_UPDATE_SUCCESS
  INVENTORY_UPDATE_FAIL

  FETCH_INVENTORY_LOG_INIT
  FETCH_INVENTORY_LOG_SUCCESS
  FETCH_INVENTORY_LOG_FAIL
`,
  {
    prefix: 'order-inventory-tab/'
  }
);
