import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Common routes
import Login from '../shared/pages/login';

/**CLIENT DIRECTORY */
import CCInboundBuyer from '../client/containers/CCInboundBuyer';
import CCoutboundBuyerMissedCall from '../client/containers/CCoutboundBuyerMissedCall';
import CCOutboundBuyer from '../client/containers/CCOutboundBuyer';
import CCOBAssistedBooking from '../client/containers/CCOBAssistedBooking';
import Seller from '../client/containers/Seller';
import Buyer from '../client/containers/Buyer';
import Upload from '../client/containers/Upload';
import { Welcome, ThankYou, ErrorPage } from '../client/components/shared';

/**Used imports */
import Home from '../client/containers/Home';
/**SALES IFRAME */
import CCOBPreBooking from '../client/containers/CCOBPreBooking';
import CCOBPostDelivery from '../client/containers/CCOBPostDelivery';
import CCOBPostBooking from '../client/containers/CCOBPostBooking';

// Admin-panel routes
import OrderGrid from '../admin-app/pages/Orders';
import ZonesGrid from '../admin-app/pages/zones';
import AddZone from '../admin-app/pages/AddZones';
import TokenServices from '../admin-app/pages/TokenServices';
import Notfound from '../admin-app/components/common/notfound';
import Dashboard from '../admin-app/components/home/dashboard';
import Stock from '../admin-app/components/stock/stock';
import StoreGrid from '../admin-app/pages/Stores';
import DealerGrid from '../admin-app/pages/Dealers';
import FinanceGrid from '../admin-app/components/finance-grid';
import FinanceTracker from '../admin-app/components/finance-tracker';
import AddB2c from '../admin-app/components/stock/addB2c';
import RcManagementPage from '../admin-app/pages/RcManagement';
import RcTrackerDetailPage from '../admin-app/pages/RcTrackerDetail';
import CityPage from '../admin-app/pages/City';
import CityPageDetail from '../admin-app/pages/CityDetail';
import ZoneDetail from '../admin-app/pages/ZonesDetail';
import withLayout from '../shared/components/layout/component';
import ArDealer from '../admin-app/pages/ArDealer';
import ArdealerForm from '../admin-app/pages/ArDealerForm';
import Rto from '../admin-app/pages/Rto';
import RtoDetails from '../admin-app/pages/RtoDetails';
import DealerFormPage from '../admin-app/pages/dealerForm';
import C2cStores from '../admin-app/pages/C2cStores';
import withAdminLayout from '../shared/hoc/admin-layout';
import UserGrid from '../admin-app/pages/Users';
import UserForm from '../admin-app/pages/UserForm';
import FinanceBankUpload from '../admin-app/components/finance-bank-upload';
import Unauthorised from '../shared/components/unauthorised-access/';
import AgentGrid from '../admin-app/components/agent-grid';
import Agent from '../admin-app/components/agent-add-edit';
import HpManagement from '../admin-app/pages/HpManagement';
import HpManagmentInfo from '../admin-app/components/hp-managment-add-edit';
import FinanceRefundsGrid from '../admin-app/components/finance-refunds-grid';
import Documents from '../admin-app/pages/Documents';
import DocumentRules from '../admin-app/pages/DocumentRules';
import RtoDelayUpdatesPage from '../admin-app/pages/RtoDelayUpdates';
import CaseTypeRules from '../admin-app/pages/CaseTypeRules';
import DocumentsListPage from '../admin-app/pages/DocumentsList';
import DocumentRejectionReasonPage from '../admin-app/pages/DocumentRejectionReasonPage';
import TaskManagementPage from '../admin-app/pages/TaskManagement';
import RcManagementOthersPage from '../admin-app/pages/RcManagementOthers';
import RcOthersSegmentForm from '../admin-app/components/rc-others/rc-others-segment-form';
import RcTrackerOthersDetailPage from '../admin-app/pages/RcTrackerOthersDetail';
import RegionAllocationPage from '../admin-app/pages/RegionAllocation';
import VahanApiFrequency from '../admin-app/pages/VahanApiFrequency';

const RenderRoutes = () => {
  return (
    <main>
      <Switch>
        {routes.map((route) => (
          <Route key={route.path} {...route} />
        ))}
        <Route
          exact
          path='/dashboard'
          render={(props) => <Dashboard {...props} />}
        />
        <Route
          exact
          path='/orders'
          render={(props) => <OrderGrid {...props} />}
        />

        <Route
          exact
          path='/zones'
          render={(props) => <ZonesGrid {...props} />}
        />
        <Route
          exact
          path='/ardealer'
          render={(props) => <ArDealer {...props} />}
        />
        <Route
          exact
          path='/ardealer-add/:dealerId'
          render={(props) => <ArdealerForm {...props} />}
        />
        <Route
          exact
          path='/ardealer-add/:dealerId?/:arId?'
          render={(props) => <ArdealerForm {...props} />}
        />
        <Route path='/zones/add' render={(props) => <AddZone {...props} />} />
        <Route exact path='/city' render={(props) => <CityPage {...props} />} />
        <Route
          exact
          path='/city/:id'
          render={(props) => <CityPageDetail {...props} />}
        />
        <Route
          exact
          path='/rc-tracker'
          render={(props) => <RcManagementPage {...props} />}
        />
        <Route
          exact
          path='/rc-tracker/:id'
          render={(props) => <RcTrackerDetailPage {...props} />}
        />
        <Route
          exact
          path='/rc-tracker-others'
          render={(props) => <RcManagementOthersPage {...props} />}
        />
        <Route
          exact
          path='/rc-tracker-others/:id'
          render={(props) => <RcTrackerOthersDetailPage {...props} />}
        />
        <Route
          exact
          path='/hp-management'
          render={(props) => <HpManagement {...props} />}
        />
        <Route
          exact
          path='/hp-management/:id'
          render={(props) => <HpManagmentInfo {...props} />}
        />
        <Route
          exact
          path='/zones/:id'
          render={(props) => <ZoneDetail {...props} />}
        />
        <Route
          path='/order/stock/:id'
          render={(props) => <Stock {...props} />}
        />
        <Route
          exact
          path='/stores'
          render={(props) => <StoreGrid {...props} />}
        />
        {/* <Route path='/store/add' render={(props) => <Store {...props} />} /> */}
        <Route
          path='/store/add'
          render={(props) => <C2cStores {...props} isAdd={true} />}
        />
        <Route
          exact
          path='/dealers'
          render={(props) => <DealerGrid {...props} />}
         />
        <Route
          exact
          path='/token-services'
          render={(props) => <TokenServices/>}
        />
        <Route exact path='/rto' render={(props) => <Rto {...props} />} />
        <Route
          exact
          path='/rto/:id'
          render={(props) => <RtoDetails {...props} />}
        />
        <Route
          path='/dealer/add'
          render={(props) => <DealerFormPage {...props} />}
        />
        <Route
          path='/dealer/:id'
          render={(props) => <DealerFormPage {...props} />}
        />
        <Route
          exact
          path='/agents'
          render={(props) => <AgentGrid {...props} />}
        />
        <Route path='/agent/add' render={(props) => <Agent {...props} />} />
        <Route path='/agent/:id' render={(props) => <Agent {...props} />} />

        <Route path='/orders/add' render={(props) => <AddB2c {...props} />} />
        <Route path='/hp-management/add' render={(props) => <HpManagmentInfo {...props} />} />
        <Route path='/rc-tracker-others-details/add' render={(props) => <RcOthersSegmentForm {...props} />} />
        <Route
          exact
          path='/finance'
          render={(props) => <FinanceGrid {...props} />}
        />

        <Route
          exact
          path='/finance-tracker/:id'
          render={(props) => <FinanceTracker {...props} />}
        />

        <Route
          exact
          path='/finance/auto-refunds'
          render={(props) => <FinanceRefundsGrid {...props} />}
        />

        <Route
          exact
          path='/finance/upload'
          render={(props) => <FinanceBankUpload {...props} />}
        />
        <Route path='/store/:id' render={(props) => <C2cStores {...props} />} />
        <Route path='/documents/list' exact render={(props) => <DocumentsListPage {...props} />} />
        <Route path='/documents/add-rejection-reasons/:documentId' render={(props) => <DocumentRejectionReasonPage {...props} />} />
        <Route path='/documents' exact render={(props) => <Documents {...props} />} />
        <Route path='/documents/:regionId' render={(props) => <DocumentRules {...props} />} />


        <Route
          exact
          path='/rto-delay-updates'
          render={(props) => <RtoDelayUpdatesPage {...props} />}
        />

        <Route path='/case-types' render={(props) => <CaseTypeRules {...props} />} />
        <Route
          exact
          path='/task-management'
          render={(props) => <TaskManagementPage {...props} />}
        />

        <Route
          exact
          path='/agent-region-allocation'
          render={(props) => <RegionAllocationPage {...props} />}
        />

        <Route
          exact
          path='/vahan-api-frequency'
          render={(props) => <VahanApiFrequency {...props} />}
        />
        <Route component={Notfound} />
      </Switch>
    </main>
  );
};

export default RenderRoutes;

const routes = [
  {
    path: '/',
    exact: true,
    component: withAdminLayout(Home)
  },
  {
    path: '/cc-inbound-buyer',
    exact: true,
    component: withLayout(CCInboundBuyer)
  },
  {
    path: '/cc-outbound-missedcall-buyer',
    exact: true,
    component: withLayout(CCoutboundBuyerMissedCall)
  },
  {
    path: '/cc-outbound-buyer',
    exact: true,
    component: withLayout(CCOutboundBuyer)
  },
  {
    path: '/ccob-assisted-booking',
    exact: true,
    component: withLayout(CCOBAssistedBooking)
  },
  {
    path: '/ccob-pre-booking',
    exact: true,
    component: withLayout(CCOBPreBooking)
  },
  {
    path: '/ccob-post-delivery',
    exact: true,
    component: withLayout(CCOBPostDelivery)
  },
  {
    path: '/ccob-post-booking',
    exact: true,
    component: withLayout(CCOBPostBooking)
  },
  {
    path: '/ccob-lku-pre-booking',
    exact: true,
    component: withLayout(CCOBPreBooking)
  },
  {
    path: '/ccob-lku-post-booking',
    exact: true,
    component: withLayout(CCOBPostBooking)
  },
  {
    path: '/seller',
    exact: true,
    component: withLayout(Seller)
  },
  {
    path: '/buyer',
    exact: true,
    component: withLayout(Buyer)
  },
  {
    path: '/login',
    exact: true,
    component: Login
  },
  {
    path: '/token-services',
    exact: true,
    component: withAdminLayout(TokenServices)
  },
  {
    path: '/welcome',
    exact: true,
    component: withLayout(Welcome)
  },
  {
    path: '/unauthorised',
    exact: true,
    component: withLayout(Unauthorised)
  },
  {
    path: '/thank-you',
    exact: true,
    component: withLayout(ThankYou, false)
  },
  {
    path: '/error',
    exact: true,
    component: withLayout(ErrorPage, false)
  },
  {
    path: '/upload/allocations',
    exact: true,
    component: withAdminLayout(Upload)
  },
  {
    path: '/upload/publish',
    exact: true,
    component: withAdminLayout(Upload)
  }
];
