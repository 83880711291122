import React from 'react';
import RenderRoutes from './router/routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => (
  <Provider store={store}>
    <Router>
      <RenderRoutes />
    </Router>
    <ToastContainer />
  </Provider>
);

export default App;
