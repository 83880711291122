import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DealerForm from './component';
import { StateActions, CityActions, StoreActions } from '../../actions';
import {
  fetchDealerDetailConnected,
  addDealerConnected,
  removeDealerDetailConnected,
  fetchDealerFormConnected
} from './actions';

const mapStateToProps = ({ dealerInformation: { detail } }) => {
  return {
    detail
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDealerDetailConnected,
      addDealerConnected,
      removeDealerDetailConnected,
      fetchDealerFormConnected,
      fetchStates: StateActions.fetchStates,
      fetchCities: CityActions.fetchCities,
      fetchZones: StoreActions.fetchAllZones
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DealerForm);
