export const tvrQuestions = {
    coApplicant: {
        "questionId": 46,
        "question": "Can you provide co-applicant if required?",
        "options": [
            {
                "id": 48,
                "value": "Yes"
            },
            {
                "id": 49,
                "value": "No"
            }
        ],
        "subQuestions": {}
    },
    sourceOfIncome: {
        "type": "DROP_DOWN",
        "options": [
            {
                "id": 13,
                "value": "Salaried"
            },
            {
                "id": 14,
                "value": "Self-employed"
            }
        ],
        "question": "Source of Income",
        "questionId": 37,
        "subQuestions": {
            "13": [
                38
            ],
            "14": [
                38
            ]
        }
    },
    occupation: {
        "questionId": 38,
        "question": "Occupation",
        "type": "DROP_DOWN",
        "options": [
            {
                "order": 1,
                "id": 91,
                "value": "Advertising and Communication"
            },
            {
                "order": 2,
                "id": 92,
                "value": "Arms and ammunition dealer"
            },
            {
                "order": 3,
                "id": 93,
                "value": "Auto Manufacturing and Ancillary"
            },
            {
                "order": 4,
                "id": 94,
                "value": "Banking and Finance"
            },
            {
                "order": 5,
                "id": 95,
                "value": "BPO and Call Centre"
            },
            {
                "order": 6,
                "id": 96,
                "value": "CA and Tax Consultants"
            },
            {
                "order": 7,
                "id": 97,
                "value": "Chemical and Commodities"
            },
            {
                "order": 8,
                "id": 98,
                "value": "Construction and Real Estate"
            },
            {
                "order": 9,
                "id": 99,
                "value": "Consulting"
            },
            {
                "order": 10,
                "id": 100,
                "value": "Courier and flight"
            },
            {
                "order": 11,
                "id": 101,
                "value": "Defence Sector"
            },
            {
                "order": 12,
                "id": 102,
                "value": "EDUCATION"
            },
            {
                "order": 13,
                "id": 103,
                "value": "Electronics"
            },
            {
                "order": 14,
                "id": 104,
                "value": "FMCG"
            },
            {
                "order": 15,
                "id": 105,
                "value": "GEMS and Jewellery"
            },
            {
                "order": 16,
                "id": 106,
                "value": "Gym Owner"
            },
            {
                "order": 17,
                "id": 107,
                "value": "Hospital and Ancillaries"
            },
            {
                "order": 18,
                "id": 108,
                "value": "Hospitality Industry"
            },
            {
                "order": 19,
                "id": 109,
                "value": "Insurance"
            },
            {
                "order": 20,
                "id": 110,
                "value": "ITES"
            },
            {
                "order": 21,
                "id": 111,
                "value": "Lawyers"
            },
            {
                "order": 22,
                "id": 112,
                "value": "Legal Service"
            },
            {
                "order": 23,
                "id": 113,
                "value": "Manpower Consulting"
            },
            {
                "order": 24,
                "id": 114,
                "value": "Manufacturing"
            },
            {
                "order": 25,
                "id": 115,
                "value": "Media"
            },
            {
                "order": 26,
                "id": 116,
                "value": "Media Journalist"
            },
            {
                "order": 27,
                "id": 117,
                "value": "NGO"
            },
            {
                "order": 28,
                "id": 118,
                "value": "OIL and Natural Gas"
            },
            {
                "order": 29,
                "id": 119,
                "value": "Police employee"
            },
            {
                "order": 30,
                "id": 120,
                "value": "Politician"
            },
            {
                "order": 31,
                "id": 121,
                "value": "Power and Port"
            },
            {
                "order": 32,
                "id": 122,
                "value": "Private Financiers/Money lenders"
            },
            {
                "order": 33,
                "id": 123,
                "value": "Private Security Agencies"
            },
            {
                "order": 34,
                "id": 124,
                "value": "PSU and Central Gove Undertaking"
            },
            {
                "order": 35,
                "id": 125,
                "value": "Railways"
            },
            {
                "order": 36,
                "id": 126,
                "value": "Real Estate Agent"
            },
            {
                "order": 37,
                "id": 127,
                "value": "Service Industry"
            },
            {
                "order": 38,
                "id": 128,
                "value": "Sports and Others"
            },
            {
                "order": 39,
                "id": 129,
                "value": "State and Local Gove undertaking"
            },
            {
                "order": 40,
                "id": 130,
                "value": "Stock and Community Broking"
            },
            {
                "order": 41,
                "id": 131,
                "value": "Telecom Service"
            },
            {
                "order": 42,
                "id": 132,
                "value": "Textile and Appeals"
            },
            {
                "order": 43,
                "id": 133,
                "value": "TOUR AND TRAVEL"
            },
            {
                "order": 45,
                "id": 134,
                "value": "OTHER"
            },
            {
                "order": 44,
                "id": 292,
                "value": "Taxi and related Business"
            }
        ],
        "subQuestions": {}
    }
}
