import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    FETCH_STORE_INIT
    FETCH_STORE_SUCCESS
    FETCH_STORE_FAIL
    ADD_STORE_INIT
    ADD_STORE_SUCCESS
    ADD_STORE_FAIL
    REMOVE_STORE_DATA
`,
  {
    prefix: 'c2cstoreview/'
  }
);
