import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  message: null,
  firstDataSet: null,
  data: { page: 0, totalPages: 1, totalRecords: 0, response: [] }
};

export const fetchPublishRemarksRequested = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    isError: false,
    message: null
    // data: { ...INITIAL_STATE['data'] }
  };
};
export const fetchPublishRemarksSuccess = (state = INITIAL_STATE, action) => {
  const {
    payload: { data }
  } = action;

  return {
    ...state,
    isLoading: false,
    isError: false,
    message: null,
    data: data,
    firstDataSet: data.page === 0 ? data.response : state.firstDataSet
  };
};

export const fetchPublishRemarksFailure = (state = INITIAL_STATE, action) => {
  const {
    payload: { message }
  } = action;
  return {
    ...state,
    isLoading: false,
    isError: true,
    message: message,
    data: { ...INITIAL_STATE['data'] }
  };
};

export const HANDLERS = {
  [Types.FETCH_PUBLISH_REMARKS_REQUESTED]: fetchPublishRemarksRequested,
  [Types.FETCH_PUBLISH_REMARKS_SUCCESS]: fetchPublishRemarksSuccess,
  [Types.FETCH_PUBLISH_REMARKS_FAILURE]: fetchPublishRemarksFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
