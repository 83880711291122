import React from 'react';
import Accordion from '../../../shared/accordion';
import ArrowIcon from '../../../shared/arrow';
import { withRouter } from 'react-router-dom';
import styles from './styles.module.css';
import InputText from '../../../shared/components/input-text';
import CustomCheckBox from '../../../shared/components/custom-checkbox';
import { FIELDS } from './constants';
import { buddyScoringApi } from '../../../client/service';
import globalStyles from '../../../shared/utils/global.module.css';

const ZoneConfiguration = ({ id, buddyScoringLead, setBuddyScoringLead }) => {
  const onInputChange = () => {};

  const onChange = (e) => {
    const { name, value, type } = e.target;
    setBuddyScoringLead({
      ...buddyScoringLead,
      [e.target.name]: type === 'number' ? parseFloat(value) || null : value
    });
  };
  const onChangeChechbox = (e) => {
    setBuddyScoringLead({
      ...buddyScoringLead,
      [e.target.name]: e.target.checked
    });
  };

  const handleSave = () => {
    buddyScoringApi.submitgetBuddyScoringLead(id, buddyScoringLead);
  };
  return (
    <Accordion>
      {({ onClick, currentVisibleStates }) => (
        <Accordion.Content>
          <div className='borderWrapper'>
            <Accordion.Heading>
              <h2
                className='font-weight-bold p0'
                onClick={() => onClick({ index: 0 })}
              >
                Zone Configuration
                <ArrowIcon
                  grey={'GreyArrow'}
                  rotateBy={currentVisibleStates[0] ? 180 : 90}
                />
              </h2>
            </Accordion.Heading>
            <Accordion.Body>
              <div
                className={
                  currentVisibleStates[0]
                    ? 'accordionShow table mt20 top-border'
                    : 'accordionHide'
                }
              >
                {/* <ZoneBuddyLead /> */}
                <div className={``}>
                  <div className='row'>
                    {Object.values(FIELDS).map((item, index) => {
                      const { type, keyName, label } = item;
                      switch (type) {
                        case 'number':
                        case 'text':
                          return (
                            <div
                              key={index}
                              className={`col-4 ${globalStyles.mb10}`}
                            >
                              <div>
                                <strong>{label}</strong>
                              </div>
                              <InputText
                                type={type}
                                className='form-control'
                                name={keyName}
                                value={buddyScoringLead[keyName]}
                                onChange={onChange}
                              />
                            </div>
                          );
                        case 'checkbox':
                          return (
                            <div
                              key={index}
                              className={`col-4 ${globalStyles.mb10}`}
                            >
                              <div>
                                <strong>{label}</strong>
                              </div>
                              <CustomCheckBox
                                key={keyName}
                                name={keyName}
                                value={buddyScoringLead[keyName]}
                                onClickCallback={onChangeChechbox}
                                checkedStatus={buddyScoringLead[keyName]}
                              />
                            </div>
                          );

                        default:
                          return null;
                      }
                    })}
                  </div>

                  <div className='form-group col-md-12 text-right'>
                    <button className='btn btn-primary' onClick={handleSave}>
                      Save
                    </button>
                  </div>
                </div>
                {/* <p>no data available</p> */}
              </div>
            </Accordion.Body>
          </div>
        </Accordion.Content>
      )}
    </Accordion>
  );
};

export default withRouter(ZoneConfiguration);
