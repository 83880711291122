import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Filter from './component';
import { resetAllGridModelConnected } from '../common/grid-view/actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ resetAllGridModelConnected }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
