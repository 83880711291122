import React from 'react';
import ErrorBoundary from '../../components/common/errorBoundary';
import TokenServices from './component';

const ServiceCharges = () => {
  return (
    <ErrorBoundary>
      <TokenServices />
    </ErrorBoundary>
  );
};

export default ServiceCharges;
