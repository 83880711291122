import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RemarksRcCaseType from './component';
import { fetchRcCasetypeRemarksConnected } from './actions';

const mapStateToProps = (state) => {
  const {
    rcCaseTypeRemarksInfo: { remarks, remarksOps }
  } = state;
  return {
    remarks,
    remarksOps
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchRcCasetypeRemarksConnected
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemarksRcCaseType);
