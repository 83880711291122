import React, { useRef, useEffect } from "react";
import { toast } from 'react-toastify';

const FileUpload = ({

    id = "file",
    name = "file",
    onChangeHandler,
    accept,
    fileType = [],
    size,
    data,
    docName,
    isDisabled,
    fileFor,
    indexof
}) => {

    const uploadRef = useRef();
    useEffect(() => {
        if (!data.fileId) {
            uploadRef.current.value = '';
        }
    }, [data.fileId])
    const onChange = (e) => {
        let file = e.target.files[0]
        if (file && validateSize(e) && validateType(file, e.target.value)) {
            onChangeHandler(docName, file, fileFor, indexof, uploadRef);
            toast.dismiss();
            e.target.value = '';

        }
        else {
            toast.error(`Allowed file types ${fileType.join(', ')}`, {
                hideProgressBar: true,
                autoClose: 10000
            });
            e.target.value = '';
        }
    }

    const validateType = (file, filePath) => {
        if (file && Array.isArray(fileType) && fileType.length > 0) {
            const fileExtension = filePath.substring(filePath.lastIndexOf('.') + 1);
            return fileType.indexOf(fileExtension) === -1 ? false : true;
        } else {
            return true;
        }
    }



    const validateSize = (event) => {
        let file = event.target.files[0];
        let err = '';
        if (file && file.size > size) {
            err = 'file is too large, please pick a smaller file\n';
            alert(err);
            return false
        }
        return true
    };

    return (
        <form>
            <input
                ref={uploadRef}
                type="file"
                name={name}
                // id={id}
                onChange={onChange}
                accept={accept}
                disabled={isDisabled}
                className="input-filename-hidden"
            />
        </form>)
}

export default FileUpload