import React, { useEffect, useState } from 'react';
import SelectComponent from '../../../../shared/components/select-component';

export const AssignForm = ({ data, onSubmitSuccess, updateAssignToConnect, fetchAgentsConnect }) => {
  const [agents, setAgents] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleChange = (value) => {
    setInputValue(value);
  };

  const handleSubmit = () => {
    updateAssignToConnect(null, {
      bookingId: data.bookingId,
      agentEmail: inputValue,
    })
      .then(() => {
        if (onSubmitSuccess) {
          onSubmitSuccess(data, inputValue);
        }
      })
      .catch((err) => {
        console.log(err);
      })
  };

  useEffect(() => {
    fetchAgentsConnect({ isActive: true })
      .then((resp) => {
        setAgents(resp.map(({ name, emailId }) => ({ value: emailId, label: name })));
      })
  }, []);

  return (
    <div className='bg-white' style={{ width: '30vw', height: '20vh', padding: '20px'}}>
      <label htmlFor='agentId'>Agent</label>
      <SelectComponent
        name='agentId'
        optionsList={agents}
        placeholder={"Select Agent"}
        value={inputValue}
        onChange={(value) => handleChange(value)}
        className='form-control inputSpace col-6'
      />
      <button className='btn btn-success mt-4 float-right' onClick={handleSubmit}>Submit</button>
    </div>
  );
};
