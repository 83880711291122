import React, { useState, useEffect, useReducer } from 'react';
import {
  InboundBuyerDisposition as DispositionDetails, DispositionConstants, SelectComponent
} from '../../components/shared';
import { DataService } from '../../service';
import NoResult from '../../images/no-result.png';
import qs from 'qs';
import { Redirect } from 'react-router-dom';
import Loader from '../../images/preloader.gif';
import styles from './style.module.css';
import OutBoundDetails from '../../components/shared/outbound-details/component';
import { zendesksMock } from './data';
import { CAMPAIGN_TABS, LANGUAGE_TYPES } from '../../components/shared/Constants';
import Button from 'reactstrap/lib/Button';
import BuyerProfiles from '../../components/shared/outbound-details/buyer-profiles';
import { showToastMessages } from '../../../shared/utils/helper';
import { saveToLocalStorage } from '../../../shared/utils/helper';
import { APP_SOURCE, APP_SOURCE_TYPE, BUYER_PRIMARY_PHONE } from '../../../shared/utils/constants';
import { getLeadType } from '../../components/shared/utility';

const CCOBPostDelivery = (props) => {
    const {search, state} = props.location || {};
    if (!search && !state) {
      return <Redirect to='/error' />;
    }
    const leadType = getLeadType();
    const dialerData = search && qs.parse(window.location.search.substring(1));
    const customerInfo = !!dialerData.customerInfo && JSON.parse(dialerData.customerInfo) || {};
    const {phone: ind_phone, country, vehicleType, type: sourceType} = state || {};
    const getParams = {
      buyerId: customerInfo.buyer_id ? customerInfo.buyer_id : null,
      sessionId: dialerData.sessionId,
      campaignId: dialerData.campaignId,
      crtObjectId: dialerData.crtObjectId,
      userCrtObjectId: dialerData.userCrtObjectId,
      userId: dialerData.userId,
      customerId: dialerData.customerId,
      phone: dialerData.phone || ind_phone,
      agentName: dialerData.agentName,
      dispositionCode: dialerData.dispositionCode,
      dispositionAttr: dialerData.dispositionAttr,
      country: country,
      vehicleType: vehicleType,
      sourceType: sourceType,
      referenceid: customerInfo.referenceid,
      callType: customerInfo.reference_id2,
      bookingId: customerInfo.reference_id3,
      lmsLeadId: customerInfo.lms_lead_id,
      source: customerInfo.reference_id1
    };
    if (!sourceType) {
      saveToLocalStorage(APP_SOURCE, APP_SOURCE_TYPE.CC);
    } else {
      saveToLocalStorage(APP_SOURCE, APP_SOURCE_TYPE.INDEPENDENT);
    }
    saveToLocalStorage(BUYER_PRIMARY_PHONE, getParams.phone);
    const [showLoader, setShowLoader] = useState(true);
    const [showError] = !getParams.phone ? useState(true) : useState(false);
    const [bookingsData, setBookingsData] = useState(null);
    const [dispositionData, setDispositionData] = useState();
    const [selectedCcDispositionId, setSelectedCcDispositionId] = useState();
    const [isSubmit, setIsSubmit] = useState(false);
    const [disposition, setDisposition] = useState();
    const [defaultDisposition, setDefaultDisposition] = useState();
    const [subDispositionStatus, setSubDispositionStatus] = useState();
    const [isValidDisposition, setIsValidDisposition] = useState(false);
    const [postData, setPostData] = useState(false);
    const [submitPostData, setSubmitPostData] = useState(false);
    const [redirectToThankYou, setRedirectToThankYou] = useState(false);
    const [submitErrors, setSubmitErrors] = useState();
    const [showSubmitErrors, setShowSubmitErrors] = useState(false);
    const [leadInteractions, setLeadInteractions] = useState({});
    const [customerDetails, setCustomerDetails] = useState({});
    const [zendeskTickets, setZendeskTickets] = useState({});
    const [activeTab, setActiveTab] = useState(CAMPAIGN_TABS.CUSTOMER_DETAILS);
    const [buyerProfiles, setBuyerProfiles] = useState();
    const [repairFieldsData, setRepairFieldsData] = useState();
    const [isIndependentRequestCallbackClick, setIsIndependentRequestCallbackClick] = useState(false);
    const [callBackLanguage, setCallBackLanguage] = useState(LANGUAGE_TYPES[0].value);
    const [selectedTags, setSelectedTags] = useState([]);
    const [isIndependentSaveClick, setIsIndependentSaveClick] = useState(false);
    const [userFinanceDetails, setUserFinanceDetails] = useState({});
    // reducer started
    const initialState = {
      name: '',
      email: '',
      emailNotRequired: false,
      deliveryMode: '',
      deliveryAddress1: '',
      deliveryAddress2: '',
      deliveryState: null,
      deliveryCity: null,
      deliveryPincode: null,
      chargeDetails: null,
      financeOpted: null,
      paymentMode: '',
      financeTerms: null,
      bookingAmount: null,
      sharedCarLink: [],
      pickupTime: '',
      deliveryTime: null,
      bookingStatus: '',
      visitStatus: '',
      bankStatementStatus: '',
      sharedAppIds: [],
      bankStatementShared: false,
      ppUserId: '',
      buyerPincode: '',
      buyerPhone: Number(getParams.phone),
      whatsappOpted: false,
      orderSummaryRequired: false,
      lmsStatus: '',
      alternatePhone: '',
      sellCarFirst: null,
      linkedTicketsId: [],
      indepentdentCallbackRequested: false,
      city: '',
      state: '',
      enablePincode: false
    };
    //update
    function reducer(state, action) {
        switch (action.type) {
          case 'enablePincode':
            return  {...state, enablePincode: action.enablePincode}
          case 'linkedTicketsId':
            return  {...state, linkedTicketsId: action.linkedTicketsId}
          case 'indepentdentCallbackRequested':
            return  {...state, indepentdentCallbackRequested: action.indepentdentCallbackRequested}
          case 'sellCarFirst':
            return  {...state, sellCarFirst: action.sellCarFirst}
          case 'alternatePhone':
            return { ...state, alternatePhone: action.alternatePhone };
          case 'lmsStatus':
            return { ...state, lmsStatus: action.lmsStatus };
          case 'orderSummaryRequired':
            return { ...state, orderSummaryRequired: action.orderSummaryRequired };
          case 'name':
            return { ...state, name: action.name };
          case 'whatsappOpted':
            return { ...state, whatsappOpted: action.whatsappOpted };
          case 'ppUserId':
            return { ...state, ppUserId: action.ppUserId };
          case 'buyerPincode':
            return { ...state, buyerPincode: action.buyerPincode };
          case 'email':
            return { ...state, email: action.email };
          case 'bookingAmount':
            return { ...state, bookingAmount: action.bookingAmount };
          case 'bookingStatus':
            return { ...state, bookingStatus: action.bookingStatus };
          case 'deliveryMode':
            return {
              ...state,
              deliveryMode: action.deliveryMode
            };
          case 'bankStatementStatus':
            return { ...state, bankStatementStatus: action.bankStatementStatus };
          case 'deliveryAddress1':
            return { ...state, deliveryAddress1: action.deliveryAddress1 };
          case 'deliveryAddress2':
            return { ...state, deliveryAddress2: action.deliveryAddress2 };
          case 'deliveryState':
            return {
              ...state,
              deliveryState: action.deliveryState,
              deliveryCity: null
            };
          case 'deliveryCity':
            return { ...state, deliveryCity: action.deliveryCity };
          case 'deliveryPincode':
            return { ...state, deliveryPincode: action.deliveryPincode };
          case 'deliveryPlace':
            return { ...state, deliveryPlace: action.deliveryPlace };
          case 'deliveryTime':
            return { ...state, deliveryTime: action.deliveryTime };
          case 'paymentMode':
            return { ...state, paymentMode: action.paymentMode };
          case 'nameOnRc':
            return { ...state, nameOnRc: action.nameOnRc };
          case 'chargeDetails':
              return  {...state, chargeDetails: action.chargeDetails}
          case 'financeOpted':
            return  {...state, financeOpted: action.financeOpted}
          case 'financeTerms':
              return  {...state, financeTerms: action.financeTerms}
          case 'sharedCarLink':
            return  {...state, sharedCarLink: action.sharedCarLink}
          case 'pickupTime':
            return  {...state, pickupTime: action.pickupTime}
          case 'sharedAppIds':
            return  {...state, sharedAppIds: action.sharedAppIds}
          case 'bankStatementShared':
            return  {...state, bankStatementShared: action.bankStatementShared}
          case 'city':
            return { ...state, city: action.city };
          case 'state':
            return { ...state, state: action.state };
          case 'emailNotRequired':
            return { ...state, emailNotRequired: action.emailNotRequired };    
          case 'restore':
            return { ...initialState, ...action.data };
          case 'update':
            return { ...state, ...action.data};
          case 'uiReset':
            return { ...initialState, 
              whatsappOpted: state.whatsappOpted,
              ppUserId: state.ppUserId,
              buyerPincode: state.buyerPincode,
              formStatus: state.formStatus,
              name: state.name,
              email: state.email,
              alternatePhone: state.alternatePhone,
              cfFormData: state.cfFormData,
              enablePincode: state.enablePincode
            };
          default:
            throw state;
        }
    }
    const getRepairFieldsData = () => {
      DataService.getRepairTicketsFieldsData().then(resp=>{
        const {data} = resp;
        setRepairFieldsData(data);
      }).catch((err)=> {
        console.log("getRepairFieldsData", err);
      });
    };

    const getZendeskTickets = () => {
      DataService.fetchPostDeliveryZendeskTickets(getParams.phone)
        .then((resp) => {
          const {data} = resp;
          setZendeskTickets(data);
        })
        .catch((error) => {
          setZendeskTickets();
          console.log("fetchDispositions error ", error)
        });
    };

    const fetchUserFinanceDetails = (userId) => {
      DataService.fetchFinanceDetails(userId)
        .then((resp) => {
          const {data} = resp;
          setUserFinanceDetails(data);
        })
        .catch((error) => {
          console.log("fetchUserFinanceDetails error ", error)
        });
    };

    useEffect(()=> {
      DataService.fetchLeadInteractionHistory(getParams.phone)
        .then((leadResponse) => {
          setLeadInteractions(leadResponse);
        })
        .catch(err => {
          console.log("lead api error", err);
        });

      !getParams.sourceType && getParams.lmsLeadId && DataService.fetchDispositionsByLmsLeadId(getParams.lmsLeadId)
        .then((resp) => {
          setDispositionData(resp);
        })
        .catch((error) => {
          setDispositionData([]);
          console.log("fetchDispositions error ", error)
        });

      DataService.fetchCustomerDetails(getParams.phone, leadType)
        .then((cdResponse) => {
          setShowLoader(false);
          setCustomerDetails(cdResponse);
          const {whatsappOpted, userId, pincode, sellCarFirst, address} = cdResponse;
          const customerPincode = !!address && !!address.pincode ? address.pincode : pincode;
          formDispatch({
            type: 'update',
            data: {
              ...formState,
              ...cdResponse,
              ppUserId: userId,
              buyerPincode : customerPincode,
              enablePincode: !!customerPincode,
              whatsappOpted,
              sellCarFirst
            }
          });
          // get buyerprofile flags
          !!userId && DataService.getBuyerProfile(getParams.phone, userId, leadType)
          .then((bpResponse)=>{
            setBuyerProfiles(bpResponse);
          });

          // fetch finance details
          !!userId && fetchUserFinanceDetails(userId);
        })
        .catch(err => {
          console.log("customer-details api error", err);
        }).finally(()=>{
          setShowLoader(false);
        });
      // Bookings delivery
      DataService.fetchPostDeliveryBookings(getParams.phone)
        .then(data => {
          setBookingsData(data);
        }).catch(() => {
          setBookingsData();
        });
      
      getRepairFieldsData();
      //Zendesk tickets
      getZendeskTickets();
    }, []);

    const [formState, formDispatch] = useReducer(reducer, initialState);

    useEffect(() => {
      if (postData) {
        if (!!formState.name && formState.name.length > 89) {
          showToastMessages(`Please provide valid customer name input in Customer Details tab`, false, 5000);
          setIsValidDisposition(false);
        }
        if (!!formState.email && !formState.emailNotRequired && formState.email.length > 74) {
          showToastMessages(`Please provide valid customer email input in Customer Details tab`, false, 5000);
          setIsValidDisposition(false);
        }
        setSubmitPostData(true);
      } else {
        setPostData(false);
      }
    }, [postData]);

    useEffect(() => {
      if (submitPostData) {
        setSubmitErrors();
        setShowSubmitErrors(false);
        if (isValidDisposition) {
          delete formState.userId;
          const formData = {
            ...dialerData,
            customerInfo,
            ...formState,
            tags:selectedTags,
            additionalFields: disposition.additionalFields,
            pincode: formState.buyerPincode,
            deliveryDate: new Date(formState.deliveryDate),
            ccDispositionId: disposition.dispositionId,
            ccDisposition: disposition.ccDisposition,
            ccSubDisposition: disposition.ccSubDisposition,
          };
          delete formData.stores;
          delete formData.bookingDate;
          setIsSubmit(true);
          DataService.submitCCOBPostDelivery(formData).then((response) => {
            if (response.errors && response.errors.length > 0) {
              setSubmitErrors(response.errors);
              setShowSubmitErrors(true);
              setPostData(false);
              setSubmitPostData(false);
              setIsSubmit(false);
            } else {
              reset();
              setRedirectToThankYou(true);
              setSubmitErrors();
              setShowSubmitErrors(false);
            }
          });
        } else {
          setPostData(false);
          setSubmitPostData(false);
        }
      }
    }, [submitPostData]);  

    const reset = () => {
      setDisposition();
      setPostData(false);
      setSubmitPostData(false);
      setIsValidDisposition(false);
      // setIsBuyerCFEligible(false);
      setIsSubmit(false);
    };

    if (redirectToThankYou) {
      return <Redirect to='/thank-you' />;
    }
  
    if (showLoader) {
      return (
        <div className='container text-center loader'>
          <img src={Loader} alt='Loading' className='center-block' />
        </div>
      );
    }
  
    if (showError) {
      return (
        <div className='no-results container-fluid'>
          <img src={NoResult} alt='no record' />
        </div>
      );
    }
    const onBackClick = () => {
      props.history.push({pathname: `/welcome`})
    };

    const onRequestCallback = () => {
      setIsIndependentRequestCallbackClick(true);
      DataService.onIndependentRequestCallBack(getParams.phone, callBackLanguage)
        .then((resp)=> {
          formDispatch({
              type: 'indepentdentCallbackRequested',
              indepentdentCallbackRequested : true
          });
          showToastMessages('Callback request is successfully created', true, 5000);
        }).catch((err)=>{
          showToastMessages(err.message, false, 5000);
        }).finally(()=>{
          setIsIndependentRequestCallbackClick(false);
        });
    };

    const onIndependentSaveClick = () => {
      setIsIndependentSaveClick(true);
      const payload = {...formState, pincode: formState.buyerPincode};
      delete payload.bookingDate;
      if (!!payload.name && payload.name.length > 89) {
        showToastMessages(`Please provide valid customer name input in Customer Details tab`, false, 5000);
        setIsIndependentSaveClick(false);
        return;
      }
      if (!!payload.email && !payload.emailNotRequired && payload.email.length > 74) {
        showToastMessages(`Please provide valid customer email input in Customer Details tab`, false, 5000);
        setIsIndependentSaveClick(false);
        return;
      }
      DataService.saveIndependentCCUI(payload).then((response) => { //Toodo
        showToastMessages(response.message, response.code === "SUCCESS200", 5000);
        response.code === "SUCCESS200" && formDispatch({
          type: 'uiReset'
        });
      }).catch((err)=>{
        showToastMessages(err.message, false, 5000);
      }).finally(()=>{
        setTimeout(()=>{
          setIsIndependentSaveClick(false);
        }, 2000);
      });
    };
    
    return (
        <div className={`container-fluid ${styles.mainWrapper}`}>
          {getParams.sourceType === DispositionConstants.CC_OB_INDEPENDENT &&  <div className='row'>
            <div className={`col-5 form-group disp-box d-flex align-items-center ${styles.buttonWrapper}`}>
              <Button id='back' className="submit" onClick={onBackClick}>Back to Home Page</Button>
              <Button id='independent-cc-ui-save' className="submit ml-10" disabled={isIndependentSaveClick} onClick={onIndependentSaveClick}>Submit Details</Button>
              <Button id='independent-cc-ui-independent-request-callback' className="submit ml-10" disabled={isIndependentRequestCallbackClick || formState.indepentdentCallbackRequested} onClick={onRequestCallback}>Request Callback</Button>
              <SelectComponent
                  key={'languageType'}
                  name={'languageType'}
                  hideSelectedOptions={false}
                  isClearable={false}
                  optionsList={LANGUAGE_TYPES}
                  onChange={(e)=> setCallBackLanguage(e)}
                  defaultValue={LANGUAGE_TYPES[0]}
              />
            </div>
          </div>}
          <div className='row'>
            <div className='col-8'>
              <BuyerProfiles data={buyerProfiles} leadType={leadType} params={getParams}/>
            </div>
            {!showLoader && (
              <div className={`col-4 ${styles.dispositionDetailsWrapper}`}>
                {!getParams.sourceType && dispositionData && <DispositionDetails
                  leadType={leadType}
                  whatsappOpted={formState.whatsappOpted}
                  phone={formState.buyerPhone}
                  dispositionData={dispositionData}
                  setDisposition={setDisposition}
                  setSubDispositionStatus={setSubDispositionStatus}
                  subDispositionStatus={subDispositionStatus}
                  defaultDisposition={defaultDisposition}
                  isSubmit={isSubmit}
                  setIsValidDisposition={setIsValidDisposition}
                  setPostData={setPostData}
                  submitErrors={submitErrors}
                  showSubmitErrors={showSubmitErrors}
                  setShowSubmitErrors={setShowSubmitErrors}
                  selectedCcDispositionId={selectedCcDispositionId}
                  setSelectedCcDispositionId={setSelectedCcDispositionId}
                  selectedTags={selectedTags}
                  setSelectedTags={setSelectedTags}
                  ctaText={'Submit & Dispose'}
                />}
              </div>
            )}
          </div>
          {!showLoader && <div className='row'>
            <div className='col-12'>
              <div className={`main_section_disp  ${styles.outboundBlock}`}>
                <OutBoundDetails
                  leadInteractions={leadInteractions}
                  leadType={leadType}
                  customerDetails={customerDetails}
                  bookingsData={bookingsData}
                  form={{ state: formState, dispatch: formDispatch }}
                  zendeskTickets={zendeskTickets}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  getZendeskTickets={getZendeskTickets}
                  repairFieldsData={repairFieldsData}
                  userFinanceDetails={userFinanceDetails}
                  lmsLeadId={getParams.lmsLeadId}
                />
              </div>
            </div>
          </div>}
        </div>
    );
  };
  export default CCOBPostDelivery;