import React from 'react';
import { CEditableLabel } from '../common/c-editable-label/c-editable-label';
import { DOCKET_MANAGEMENT_FIELDS } from './meta-data';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import Accordion from '../../../shared/accordion';
import ArrowIcon from '../../../shared/arrow';

export const DocketManagementView = ({
  docketData,
  rowData,
  columnDefs,
  handleInputEdit,
  handleSave,
}) => {
  return (
    <Accordion>
      {({ onClick, currentVisibleStates }) => (
        <Accordion.Content>
          <div className='borderWrapper'>
            <Accordion.Heading>
              <h2
                className='font-weight-bold rcDocuments p0'
                onClick={() => onClick({ index: 0 })}
              >
                Docket Management
                <ArrowIcon
                  grey={'GreyArrow'}
                  rotateBy={currentVisibleStates[0] ? 180 : 90}
                />
              </h2>
            </Accordion.Heading>
            <Accordion.Body>
              <div className={`${
                  currentVisibleStates[0]
                    ? 'accordionShow p-2 py-4'
                    : 'accordionHide'
                }`}>
                <div className='row'>
                  {DOCKET_MANAGEMENT_FIELDS().map((fieldData) => {
                    if (Object.keys(fieldData).length === 0) return <div className='col-6'></div>;
                    const { key, label, inputFieldType, maxLength } = fieldData;
                    return (
                      <div className='col-6 mb-1'>
                        <CEditableLabel
                          key={key}
                          name={key}
                          label={label}
                          initialValue={docketData[key]}
                          onOk={handleInputEdit}
                          inputProps={{
                            inputType: inputFieldType || 'text',
                            maxLength
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
                <div className="docket-grid mt-3 mb-3">
                  <Grid
                    data={rowData}
                    pageable={false}
                  >
                    {columnDefs.map((column) => {
                      return (
                        <GridColumn {...column} />
                      )
                    })}
                  </Grid>
                </div>
                <div className='col-12'>
                  <button className='btn btn-sm btn-primary float-right' onClick={handleSave}>Save</button>
                </div>
              </div>
            </Accordion.Body>
          </div>
        </Accordion.Content>
      )}
    </Accordion>
  )
}