import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  
  FETCH_USER_GRID_DATA_INIT
  FETCH_USER_GRID_DATA_SUCCESS
  FETCH_USER_GRID_DATA_FAILURE
`,
  {
    prefix: 'user-grid/'
  }
);
