import { dealerService } from '../../../services';
import Types from './types';
import {
  setAccessLoaderConnected,
  resetAccessLoaderConnected,
  setForbiddenConnected
} from '../../../shared/components/loader-with-access-control/actions';
import { handleHttpStatusError } from '../../../services/helper';
import { showToastMessages } from '../../../shared/utils/helper';
const fetchArDealerInfoByIdInit = () => ({
  type: Types.FETCH_ARDEALER_INIT
});

const fetchArDealerInfoByIdSuccess = (data) => ({
  type: Types.FETCH_ARDEALER_SUCCESS,
  payload: data
});

const fetchArDealerFailure = (error) => ({
  type: Types.FETCH_ARDEALER_FAILURE,
  payload: error
});
const removeArDealerDetailConnected = () => {
  return {
    type: Types.REMOVE_ARDEALER_DATA
  };
};
const addArdealerConnected = (data) => () => {
  dealerService
    .addArDealer(data)
    .then((resp) => {
      showToastMessages(resp.data.message);
      return resp.data;
    })
    .catch((error) => {
      error.errors &&
        error.errors[0] &&
        error.errors[0]['message'] &&
        showToastMessages(error.errors[0]['message'], false);
    });
};

const fetchArDealerInfoByIdConnected = (arId) => (dispatch) => {
  dispatch(setAccessLoaderConnected());
  dispatch(fetchArDealerInfoByIdInit);
  return new Promise((resolve, reject) => {
    dealerService
      .fetchArDealerById(arId)
      .then((resp) => {
        dispatch(fetchArDealerInfoByIdSuccess(resp.data.data));
        dispatch(resetAccessLoaderConnected());
        resolve(resp);
      })
      .catch((error) => {
        dispatch(fetchArDealerFailure(error.message));
        handleHttpStatusError(error);
        reject(error);
      });
  });
};
export {
  addArdealerConnected,
  fetchArDealerInfoByIdConnected,
  removeArDealerDetailConnected
};
