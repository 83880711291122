import React from 'react';
import Zones from '../../components/zones-grid';
import withAdminLayout from '../../../shared/hoc/admin-layout';
import ErrorBoundary from '../../components/common/errorBoundary';
import { withAuthorization } from '../../../shared/utils/firebase';

const ZonesPage = () => {
  return (
    <div>
      <ErrorBoundary>
        <Zones />
      </ErrorBoundary>
    </div>
  );
};
export default withAdminLayout(withAuthorization(() => true)(ZonesPage));
