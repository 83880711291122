import { config } from '../config/local';

export default (api) => {
  const fetchAgentAvailability = () => {
    return new Promise((resolve, reject) => {
      const url = config.api.c2c.agentAvailability.fetchAvailability.path;
      return api
        .get(url)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch(error => {
          reject(error);
      })
    });
  };

  const updateAgentAvailability = (body) => {
    return new Promise((resolve, reject) => {
      const url = config.api.c2c.agentAvailability.updateAvailability.path;
      api.put(url, body)
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            reject(error);
        })
    });
  }

  return {
    fetchAgentAvailability,
    updateAgentAvailability,
  };
};