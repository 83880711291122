import React, { useState, useEffect, useReducer } from 'react';
import {
  InboundBuyerDisposition as DispositionDetails, DispositionConstants, SelectComponent
} from '../../components/shared';
import { DataService } from '../../service';
import NoResult from '../../images/no-result.png';
import qs from 'qs';
import { Redirect } from 'react-router-dom';
import Loader from '../../images/preloader.gif';
import styles from './style.module.css';
import OutBoundDetails from '../../components/shared/outbound-details/component';
import { VALIDATION_REQUIRED_SUBDISPOSITIONS, PRE_BOOKING_FORM_VALIDATION_RULES, CAMPAIGN_TABS, DELIVERY_MODES, ACTIVE_BOOKING_TYPES, LANGUAGE_TYPES, LEAD_TYPE, FORM_VALIDATION_FIELDS, IGNORE_VALIDATIONS_FIELDS, DISCOUNT_STATUS} from '../../components/shared/Constants';
import OrderSummaryDetails from '../../components/shared/outbound-details/order-summary-details';
import Button from 'reactstrap/lib/Button';
import BuyerProfiles from '../../components/shared/outbound-details/buyer-profiles';
import { showToastMessages, saveToLocalStorage } from '../../../shared/utils/helper';
import { APP_SOURCE, APP_SOURCE_TYPE, BUYER_PRIMARY_PHONE } from '../../../shared/utils/constants';
import { getLeadType } from '../../components/shared/utility';
import { fetchFinance} from '../../modules/finance';

const CCOBPreBooking = (props) => {
    const {search, state} = props.location || {};
    if (!search && !state) {
      return <Redirect to='/error' />;
    }
    const leadType = getLeadType();
    const dialerData = search && qs.parse(search.substring(1));
    const customerInfo = !!dialerData.customerInfo && JSON.parse(dialerData.customerInfo) || {};
    const {phone: ind_phone, country, vehicleType, type: sourceType} = state || {};
    const getParams = {
      buyerId: customerInfo.buyer_id ? customerInfo.buyer_id : null,
      sessionId: dialerData.sessionId,
      campaignId: dialerData.campaignId,
      crtObjectId: dialerData.crtObjectId,
      userCrtObjectId: dialerData.userCrtObjectId,
      userId: dialerData.userId,
      customerId: dialerData.customerId,
      phone: dialerData.phone || ind_phone,
      agentName: dialerData.agentName,
      dispositionCode: dialerData.dispositionCode,
      dispositionAttr: dialerData.dispositionAttr,
      country: country,
      vehicleType: vehicleType,
      sourceType: sourceType,
      callType: customerInfo.reference_id2,
      bookingId: customerInfo.reference_id3,
      lmsLeadId: customerInfo.lms_lead_id,
      source: customerInfo.reference_id1
    };
    if (!sourceType) {
      saveToLocalStorage(APP_SOURCE, APP_SOURCE_TYPE.CC);
    } else {
      saveToLocalStorage(APP_SOURCE, APP_SOURCE_TYPE.INDEPENDENT);
    }
    saveToLocalStorage(BUYER_PRIMARY_PHONE, getParams.phone);
    const [showLoader, setShowLoader] = useState(true);
    const [showError] = !getParams.phone ? useState(true) : useState(false);
    const [bookingsData, setBookingsData] = useState(null);
    const [orderData, setOrderData] = useState({});
    const [pincodeData, setPincodeData] = useState();
    const [discountData, setDiscountData] = useState();
    const [selectedPickupLocationIndex, setSelectedPickupLocationIndex] = useState(0);
    const [dispositionData, setDispositionData] = useState();
    const [selectedCcDispositionId, setSelectedCcDispositionId] = useState();
    const [isSubmit, setIsSubmit] = useState(false);
    const [disposition, setDisposition] = useState();
    const [defaultDisposition, setDefaultDisposition] = useState();
    const [subDispositionStatus, setSubDispositionStatus] = useState();
    const [isValidDisposition, setIsValidDisposition] = useState(false);
    const [postData, setPostData] = useState(false);
    const [redirectToThankYou, setRedirectToThankYou] = useState(false);
    const [submitErrors, setSubmitErrors] = useState();
    const [showSubmitErrors, setShowSubmitErrors] = useState(false);
    const [leadInteractions, setLeadInteractions] = useState([]);
    const [customerDetails, setCustomerDetails] = useState({});
    const [activeTab, setActiveTab] = useState(CAMPAIGN_TABS.CUSTOMER_DETAILS);
    const [formValidationRules, setFormValidationRules] = useState({});
    const [buyerProfiles, setBuyerProfiles] = useState();
    const [isIndependentSaveClick, setIsIndependentSaveClick] = useState(false);
    const [isIndependentRequestCallbackClick, setIsIndependentRequestCallbackClick] = useState(false);
    const [recommendCars, setRecommendCars] = useState();
    const [callBackLanguage, setCallBackLanguage] = useState(LANGUAGE_TYPES[0].value);
    const [submitPostData, setSubmitPostData] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [preApprovedData, setPreApprovedData] = useState();

    const initialState = {
      name: '',
      email: '',
      emailNotRequired: false,
      deliveryMode: '',
      deliveryAddress1: '',
      deliveryAddress2: '',
      deliveryState: null,
      deliveryCity: null,
      deliveryPincode: null,
      chargeDetails: null,
      financeOpted: null,
      paymentMode: '',
      financeTerms: null,
      bookingAmount: null,
      sharedCarLink: [],
      pickupTime: '',
      deliveryTime: null,
      bookingStatus: '',
      visitStatus: '',
      bankStatementStatus: '',
      sharedAppIds: [],
      bankStatementShared: false,
      cfFormLinkShared: false,
      ppUserId: '',
      buyerPincode: '',
      buyerPhone: Number(getParams.phone),
      buyerCreditLimit: 0,
      whatsappOpted: false,
      cFFormStatus: false,
      pincodeServiceable: false,
      cfApproved: false,
      cfStatus: '',
      visitDate: '',
      visitTime: '',
      loanStatus: '',
      orderSummaryRequired: false,
      lmsStatus: '',
      alternatePhone: '',
      cfFormData: {formStatus: '', addressDetails:{}, inComeDetails: {}},
      initiatedBookings: [],
      completeBookingIds: [],
      isBookingUpdate: false,
      carAvailability: '',
      orderLinkShared: [],
      orders: [],
      sellCarFirst: null,
      cfToPod:[],
      podToCf: [],
      hdToPickup: [],
      pickupToHd: [],
      visitDateUpdated: [],
      rejectionDetails: '',
      preApproved: false,
      bankStatementRejectedReason: '',
      indepentdentCallbackRequested: false,
      handPickedCarAppIds: [],
      buyerAddress: null,
      currentLoanStage: '',
      financeStatus: '',
      discountStatus: DISCOUNT_STATUS.DONTKNOW,
      isCompleteBookingAllowed: false,
      cfRejectedReason: '',
      city: '',
      state: '',
      deliveryModeCopy: '',
      enablePincode: false
    };
    function reducer(state, action) {
        switch (action.type) {
          case 'enablePincode':
            return  {...state, enablePincode: action.enablePincode}
          case 'deliveryModeCopy':
            return  {...state, deliveryModeCopy: action.deliveryModeCopy}
          case 'cfRejectedReason':
            return  {...state, cfRejectedReason: action.cfRejectedReason}
          case 'discountStatus':
            return  {...state, discountStatus: action.discountStatus}
          case 'isCompleteBookingAllowed':
            return  {...state, isCompleteBookingAllowed: action.isCompleteBookingAllowed}
          case 'handPickedCarAppIds':
            return  {...state, handPickedCarAppIds: action.handPickedCarAppIds}
          case 'buyerAddress':
            return  {...state, buyerAddress: action.buyerAddress}
          case 'financeStatus':
            return  {...state, financeStatus: action.financeStatus}
          case 'currentLoanStage':
            return  {...state, currentLoanStage: action.currentLoanStage}
          case 'bankStatementRejectedReason':
            return  {...state, bankStatementRejectedReason: action.bankStatementRejectedReason}
          case 'indepentdentCallbackRequested':
            return  {...state, indepentdentCallbackRequested: action.indepentdentCallbackRequested}
          case 'preApproved':
            return  {...state, preApproved: action.preApproved}
          case 'rejectionDetails':
            return  {...state, rejectionDetails: action.rejectionDetails}
          case 'visitDateUpdated':
            return  {...state, visitDateUpdated: action.visitDateUpdated}
          case 'pickupToHd':
            return  {...state, pickupToHd: action.pickupToHd}
          case 'hdToPickup':
            return  {...state, hdToPickup: action.hdToPickup}
          case 'podToCf':
            return  {...state, podToCf: action.podToCf}
          case 'cfToPod':
            return  {...state, cfToPod: action.cfToPod}
          case 'sellCarFirst':
            return  {...state, sellCarFirst: action.sellCarFirst}
          case 'orders':
            return  {...state, orders: action.orders}
          case 'cfApproved':
            return  {...state, cfApproved: action.cfApproved}
          case 'cfStatus':
            return  {...state, cfStatus: action.cfStatus}
          case 'initiatedBookings':
            return  {...state, initiatedBookings: action.initiatedBookings}
          case 'orderLinkShared':
            return { ...state, orderLinkShared: action.orderLinkShared };
          case 'carAvailability':
            return { ...state, carAvailability: action.carAvailability };
          case 'isBookingUpdate':
            return { ...state, isBookingUpdate: action.isBookingUpdate };
          case 'cfFormData':
            return { ...state, cfFormData: action.cfFormData };
          case 'alternatePhone':
            return { ...state, alternatePhone: action.alternatePhone };
          case 'lmsStatus':
            return { ...state, lmsStatus: action.lmsStatus };
          case 'loanStatus':
            return { ...state, loanStatus: action.loanStatus };
          case 'pincodeServiceable':
            return { ...state, pincodeServiceable: action.pincodeServiceable };
          case 'orderSummaryRequired':
            return { ...state, orderSummaryRequired: action.orderSummaryRequired };
          case 'buyerCreditLimit':
            return { ...state, buyerCreditLimit: action.buyerCreditLimit };
          case 'cFFormStatus':
            return { ...state, cFFormStatus: action.cFFormStatus };
          case 'name':
            return { ...state, name: action.name };
          case 'whatsappOpted':
            return { ...state, whatsappOpted: action.whatsappOpted };
          case 'ppUserId':
            return { ...state, ppUserId: action.ppUserId };
          case 'buyerPincode':
            return { ...state, buyerPincode: action.buyerPincode };
          case 'email':
            return { ...state, email: action.email };
          case 'bookingAmount':
            return { ...state, bookingAmount: action.bookingAmount };
          case 'bookingStatus':
            return { ...state, bookingStatus: action.bookingStatus };
          case 'deliveryMode':
            return {
              ...state,
              deliveryMode: action.deliveryMode
            };
          case 'bankStatementStatus':
            return { ...state, bankStatementStatus: action.bankStatementStatus };
          case 'deliveryAddress1':
            return { ...state, 
              deliveryAddress1: action.deliveryAddress1
            };
          case 'deliveryAddress2':
            return { ...state, deliveryAddress2: action.deliveryAddress2 };
          case 'deliveryState':
            return {
              ...state,
              deliveryState: action.deliveryState,
              deliveryCity: null
            };
          case 'deliveryCity':
            return { ...state, deliveryCity: action.deliveryCity };
          case 'deliveryPincode':
            return { ...state, deliveryPincode: action.deliveryPincode };
          case 'deliveryPlace':
            return { ...state, deliveryPlace: action.deliveryPlace };
          case 'deliveryTime':
            return { ...state, deliveryTime: action.deliveryTime };
          case 'paymentMode':
            return { ...state, paymentMode: action.paymentMode };
          case 'nameOnRc':
            return { ...state, nameOnRc: action.nameOnRc };
          case 'chargeDetails':
              return  {...state, chargeDetails: action.chargeDetails}
          case 'financeOpted':
            return  {...state, financeOpted: action.financeOpted}
          case 'financeTerms':
              return  {...state, financeTerms: action.financeTerms}
          case 'sharedCarLink':
            return  {...state, sharedCarLink: action.sharedCarLink}
          case 'pickupTime':
            return  {...state, pickupTime: action.pickupTime}
          case 'sharedAppIds':
            return  {...state, sharedAppIds: action.sharedAppIds}
          case 'bankStatementShared':
            return  {...state, bankStatementShared: action.bankStatementShared}
          case 'cfFormLinkShared':
            return  {...state, cfFormLinkShared: action.cfFormLinkShared}
          case 'city':
            return { ...state, city: action.city };
          case 'state':
            return { ...state, state: action.state };
          case 'emailNotRequired':
            return { ...state, emailNotRequired: action.emailNotRequired};   
          case 'update':
            return { ...state, ...action.data};
          case 'restore':
            return { ...initialState, ...action.data };
          case 'completeBookingIds':
            return { ...state, completeBookingIds: action.completeBookingIds};
          case 'reset':
            return { ...initialState, 
              bankStatementShared: state.bankStatementShared,
              cfFormLinkShared: state.cfFormLinkShared,
              bankStatementStatus: state.bankStatementStatus,
              loanStatus: state.loanStatus,
              buyerCreditLimit: state.buyerCreditLimit,
              whatsappOpted: state.whatsappOpted,
              ppUserId: state.ppUserId,
              buyerPincode: state.buyerPincode,
              cFFormStatus: state.cFFormStatus,
              cfApproved: state.cfApproved,
              cfStatus: state.cfStatus,
              pincodeServiceable: state.pincodeServiceable,
              formStatus: state.formStatus,
              name: state.name,
              email: state.email,
              alternatePhone: state.alternatePhone,
              cfFormData: state.cfFormData,
              sharedCarLink: state.sharedCarLink,
              sharedAppIds: state.sharedAppIds,
              initiatedBookings: state.initiatedBookings,
              orderLinkShared: state.orderLinkShared,
              orders: state.orders,
              sellCarFirst: state.sellCarFirst,
              cfToPod: state.cfToPod,
              podToCf: state.podToCf,
              hdToPickup: state.hdToPickup,
              pickupToHd: state.pickupToHd,
              visitDateUpdated: state.visitDateUpdated,
              preApproved: state.preApproved,
              indepentdentCallbackRequested: state.indepentdentCallbackRequested,
              handPickedCarAppIds: state.handPickedCarAppIds,
              buyerAddress: state.buyerAddress,
              financeStatus: state.financeStatus,
              currentLoanStage: state.currentLoanStage,
              discountStatus: state.discountStatus,
              completeBookingIds: state.completeBookingIds,
              emailNotRequired: state.emailNotRequired,
              city: state.city,
              state: state.state,
              enablePincode: state.enablePincode
            };
          case 'uiReset':
            return { ...initialState, 
              bankStatementShared: state.bankStatementShared,
              cfFormLinkShared: state.cfFormLinkShared,
              bankStatementStatus: state.bankStatementStatus,
              loanStatus: state.loanStatus,
              buyerCreditLimit: state.buyerCreditLimit,
              whatsappOpted: state.whatsappOpted,
              ppUserId: state.ppUserId,
              buyerPincode: state.buyerPincode,
              cFFormStatus: state.cFFormStatus,
              pincodeServiceable: state.pincodeServiceable,
              formStatus: state.formStatus,
              name: state.name,
              email: state.email,
              alternatePhone: state.alternatePhone,
              cfFormData: state.cfFormData,
              orderSummaryRequired: state.orderSummaryRequired,
              enablePincode: state.enablePincode
            };
          case 'visitDate':
            return { ...state, visitDate: action.visitDate };
          case 'visitTime':
            return { ...state, visitTime: action.visitTime };
          case 'formStatus':
            return { ...state, formStatus: action.formStatus };
          default:
            throw state;
        }
    }

    const fetchDeliveryBookings = () => {
      DataService.fetchDeliveryBookings(getParams.phone, leadType)
        .then(data => {
          setBookingsData(data);
        }).catch(() => {
          setBookingsData();
        });
    };

    const fetchBuyerCommendedCars = () => {
      DataService.getRecommendCars(getParams.phone, LEAD_TYPE.PreBooking)
        .then(resp => {
          setRecommendCars(resp.data);
        }).catch(() => {
          setBookingsData([]);
        });
      // const car = getRecommendedCars();
      // setRecommendCars(car.data);
    };
    const isOrderChanged = () => {
      let isUpdated = false;
      if (!orderData) {
        return false;
      }
      // payment section
      if (!isUpdated && orderData.financeOpted !== undefined && formState.financeOpted !== orderData.financeOpted) {
        isUpdated = true;
        // console.log("financeOpted: new: ", formState.financeOpted, ", old: ", orderData.financeOpted);
      }
      if (!isUpdated && formState.financeOpted && formState.financeTerms) {
        if (!isUpdated && !orderData.financeTerms && !!formState.financeTerms.tenure ) {
          isUpdated = true;
          // console.log("tenure: old: , new: ", formState.financeTerms.tenure);
        }
        if (!isUpdated && orderData.financeTerms && formState.financeTerms.tenure !== orderData.financeTerms.tenure) {
          isUpdated = true;
          // console.log("tenure: new: ", formState.financeTerms.tenure, ", old: ", orderData.financeTerms.tenure);
        }
        if (!isUpdated && !orderData.financeTerms && !!formState.financeTerms.downPayment ) {
          isUpdated = true;
          // console.log("tenure: old: , new: ", formState.financeTerms.downPayment);
        }
        if (!isUpdated && formState.financeTerms.downPayment !== orderData.financeTerms.downPayment) {
          isUpdated = true;
          // console.log("downPayment new: ", formState.financeTerms.downPayment, ", old: ", orderData.financeTerms.downPayment);
        }
      }
      //Delivery Section
      const {date = '', slots =''} = orderData.deliveryTime || {}; 
      if (!isUpdated && formState.deliveryMode === DELIVERY_MODES.PICK_UP) {
        if (orderData.deliveryMode && formState.deliveryMode !== orderData.deliveryMode) {
          isUpdated = true;
          // console.log("deliveryMode: new: ", formState.deliveryMode, ", old: ", orderData.deliveryMode);
        }
        if (!isUpdated && !orderData.pickupStoreId && !!formState.deliveryPlace) {
          isUpdated = true;
          // console.log("deliveryPlace: old: , new: ", formState.deliveryPlace);
        }
        if (!isUpdated && orderData.pickupStoreId && orderData.pickupStoreId !== formState.deliveryPlace.toString()) {
          isUpdated = true;
          // console.log("deliveryPlace: new: ", formState.deliveryPlace, ", old: ", orderData.pickupStoreId);
        }
        if (!isUpdated && !date && !!formState.visitDate) {
          isUpdated = true;
          // console.log("visitDate: old: , new: ", formState.visitDate);
        } 
        
        if (!isUpdated && date && date !== formState.visitDate) {
          isUpdated = true;
          // console.log("visitDate: old: ", date, ", new: ", formState.visitDate);
        }

        if (formState.deliveryModeCopy === DELIVERY_MODES.PICK_UP_FREE && !isUpdated && !!slots && slots.length === 0 && !!formState.visitTime) {
          isUpdated = true;
          // console.log("visitTime old: , new: ", formState.visitTime);
        } 
        
        if (formState.deliveryModeCopy === DELIVERY_MODES.PICK_UP_FREE && !isUpdated && slots && slots.length > 0 && slots[0].display !== formState.visitTime) {
          isUpdated = true;
          // console.log("visitTime: old: ", slots[0].display, ", new: ", formState.visitTime);
        }
      } else if (!isUpdated && formState.deliveryMode === DELIVERY_MODES.HOME_DELIVERY) {
        if (!isUpdated && orderData.deliveryMode && orderData.deliveryMode !== formState.deliveryMode) {
          isUpdated = true;
          // console.log("deliveryMode: new: ", formState.deliveryMode, ", old: ", orderData.deliveryMode);
        }
        if (!isUpdated && !orderData.deliveryAddress && !!formState.deliveryAddress1) {
          isUpdated = true;
          // console.log("addressLine1 old: , new: ", formState.deliveryAddress1);
        } 
        if (!isUpdated && orderData.deliveryAddress && orderData.deliveryAddress.addressLine1 !== formState.deliveryAddress1) {
          isUpdated = true;
          // console.log("addressLine1: old: ", orderData.deliveryAddress.addressLine1, ", new: ", formState.deliveryAddress1);
        }
        if (!isUpdated && !orderData.deliveryAddress && !!formState.deliveryAddress2) {
          isUpdated = true;
          // console.log("addressLine2: old:  ,state: ", formState.deliveryAddress2);
        } 
        
        if (!isUpdated && orderData.deliveryAddress && orderData.deliveryAddress.addressLine2 !== formState.deliveryAddress2) {
          isUpdated = true;
          // console.log("addressLine2: old: ", orderData.deliveryAddress.addressLine2, ", new: ", formState.deliveryAddress2);
        }

        if (!isUpdated && !orderData.deliveryAddress && !!formState.deliveryCity) {
          isUpdated = true;
          // console.log("city: old: , new: ", formState.deliveryCity);
        }

        if (!isUpdated && orderData.deliveryAddress && orderData.deliveryAddress.city !== formState.deliveryCity) {
          isUpdated = true;
          // console.log("city: old: ", orderData.deliveryAddress.city, ", new: ", formState.deliveryCity);
        }
        if (!isUpdated && !orderData.deliveryAddress && !!formState.deliveryState) {
          isUpdated = true;
          // console.log("state: old: , new: ", formState.deliveryState);
        }

        if (!isUpdated && orderData.deliveryAddress && orderData.deliveryAddress.state !== formState.deliveryState) {
          isUpdated = true;
          // console.log("state: old: ", orderData.deliveryAddress.state, ", new: ", formState.deliveryState);
        }

        if (!isUpdated && !date && !!formState.visitDate) {
          isUpdated = true;
          // console.log("visitDate: old: , new: ", formState.visitDate);
        } 
        
        if (!isUpdated && date && date !== formState.visitDate) {
          isUpdated = true;
          // console.log("visitDate: old: ", date, ", new: ", formState.visitDate);
        }
        
        if (!isUpdated && !!slots && slots.length === 0 && !!formState.visitTime) {
          isUpdated = true;
          // console.log("visitTime old: , new: ", formState.visitTime);
        }
        
        if (!isUpdated && slots && slots.length > 0 && slots[0].display !== formState.visitTime) {
          isUpdated = true;
          // console.log("visitTime: old: ", slots[0].display, ", new: ", formState.visitTime);
        }
      }
      if (!isUpdated && !orderData.deliveryMode && !!formState.deliveryMode) {
        isUpdated = true;
        // console.log("deliveryMode: new: ", formState.deliveryMode, ", old: ", orderData.deliveryMode);
      }

      if (!isUpdated && orderData.deliveryAddress && orderData.deliveryAddress.pincode !== formState.deliveryPincode.toString()) {
        isUpdated = true;
        // console.log("deliveryAddress: old: ", orderData.deliveryAddress.pincode, ", new: ", formState.deliveryPincode.toString());
      }
      return isUpdated;
    };

    const onBookingUpdated = () => {
      if (!!orderData && !!orderData.orderId && ACTIVE_BOOKING_TYPES.includes(orderData.status) && isOrderChanged()) {
        const updateOrder = {
          ...orderData,
          financeOpted: formState.financeOpted,
          chargeDetails: formState.chargeDetails,
          financeTerms: formState.financeTerms,
          deliveryMode: formState.deliveryMode,
          deliveryTime: formState.deliveryTime || orderData.deliveryTime,
          pickupStoreId: formState.deliveryPlace,
          deliveryAddress: {
            ...formState.deliveryAddress,
            addressLine1: formState.deliveryAddress1,
            addressLine2: formState.deliveryAddress2,
            city: formState.deliveryCity,
            pincode: formState.deliveryPincode,
            state: formState.deliveryState,
          }
        };
        const i = formState.orders.findIndex(item => item.orderId === orderData.orderId);
        if (i > -1) formState.orders[i] = updateOrder;
        else formState.orders.push(updateOrder);
        formDispatch({
          type: "orders",
          orders: formState.orders
          });
      }
    };
    
    useEffect(()=> {
      DataService.fetchLeadInteractionHistory(getParams.phone)
        .then((leadResponse) => {
          setLeadInteractions(leadResponse || []);
        })
        .catch(err => {
          console.log("lead api error", err);
        });

      !getParams.sourceType && getParams.lmsLeadId && DataService.fetchDispositionsByLmsLeadId(getParams.lmsLeadId)
        .then((resp) => {
          setDispositionData(resp);
        })
        .catch((error) => {
          setDispositionData([]);
          console.log("fetchDispositions error ", error)
        });

      fetchDeliveryBookings();
      fetchBuyerCommendedCars();
      DataService.fetchCustomerDetails(getParams.phone, leadType)
      .then((cdResponse) => {
        setShowLoader(false);
        setCustomerDetails(cdResponse);
        const {whatsappOpted, userId, pincode, name, sellCarFirst, address} = cdResponse;
        const customerPincode = !!address && !!address.pincode ? address.pincode : pincode;
        formDispatch({
          type: 'update',
          data: {
            ...formState,
            ...cdResponse,
            ppUserId: userId,
            buyerPincode : customerPincode,
            enablePincode: !!customerPincode,
            whatsappOpted,
            sellCarFirst,
            cfFormData: {
              ...formState.cfFormData,
              name: name || '',
              pinCode: pincode,
              addressDetails: {
                ...formState.cfFormData.addressDetails,
                pincode: pincode
              }
            },
            buyerAddress: address
          }
        });
        // get buyerprofile flags
        !!userId && DataService.getBuyerProfile(getParams.phone, userId, leadType)
          .then((bpResponse)=>{
            setBuyerProfiles(bpResponse);
          }).catch((err)=>{
            setBuyerProfiles([]);
            console.log("Buyer profile api error", err);
          });
        //get CFFormDetails
        !!userId && DataService.fetchCFFormDetails(userId).then(cfResponse => {
            const {data : cfForm} = cfResponse || {};
            if (cfForm) {
              formDispatch({
                type: 'cfFormData',
                cfFormData : {...formState.cfFormData, ...cfForm}
              });
            }
            // loan status API
            fetchFinance(userId, formState, formDispatch, cfForm, setPreApprovedData);
        });
        // get user discount details
        let discount = DISCOUNT_STATUS.DONTKNOW;
        !!userId && DataService.fetchUserDiscounts(userId).then((response)=>{
          // const response = getDiscountData();
          if (!!response.data && response.data.length > 0) {
            const discounts = response.data.map((item)=> {
              const expirydate = new Date(item.expiry * 1000).toLocaleDateString();
              return {label: item.code, value: {id: item.id, category: item.category, expiry: expirydate, code: item.code, config: item.config}}
            });
            setDiscountData(discounts);
            discount = DISCOUNT_STATUS.AVAILABLE;
          } else if(!!response.data && response.data.length === 0) {
            discount = DISCOUNT_STATUS.NOTAVAILABLE;
          } 
        }).catch((err)=> {
          console.log("fetchUserDiscounts", err);
        }).finally(()=>{
          formDispatch({
            type: 'discountStatus',
            discountStatus: discount
          })
        });

      })
      .catch(err => {
        console.log("customer-details api error", err);
      });
    }, []);

    const [formState, formDispatch] = useReducer(reducer, initialState);
    useEffect(()=> {
      setFormValidationRules();
      const dispositionStatus = disposition && disposition.dispositionStatus;
      if (!!dispositionStatus && dispositionStatus === "Interested in Booking" && !!subDispositionStatus && VALIDATION_REQUIRED_SUBDISPOSITIONS.includes(subDispositionStatus)) {
        setFormValidationRules(PRE_BOOKING_FORM_VALIDATION_RULES[dispositionStatus]);
      }
    }, [disposition, subDispositionStatus]);

    useEffect(() => {
      if (!postData) {
        return;
      }
      if (!!formState.name && formState.name.length > 89) {
        setSubmitPostData(true);
        setIsValidDisposition(false);
        showToastMessages(`Please provide valid customer name input in Customer Details tab`, false, 5000);
        return;
      }
      if (!!formState.email && !formState.emailNotRequired && formState.email.length > 74) {
        setSubmitPostData(true);
        setIsValidDisposition(false);
        showToastMessages(`Please provide valid customer email input in Customer Details tab`, false, 5000);
        return;
      }
      const dispositionStatus = disposition && disposition.dispositionStatus;
      let emptyFields = [];
      let hasError = false;
      if ((!!dispositionStatus && dispositionStatus === "Interested in Booking") 
        && !!subDispositionStatus && VALIDATION_REQUIRED_SUBDISPOSITIONS.includes(subDispositionStatus)) {
        if (!formState.orderSummaryRequired) {
          setSubmitPostData(true);
        } else {
            const requiredFields = FORM_VALIDATION_FIELDS.filter((val) => {
              return IGNORE_VALIDATIONS_FIELDS[formState.deliveryMode].indexOf(val) === -1
            });
            const fieldsRequired = requiredFields.filter(
              (val) => {
                return PRE_BOOKING_FORM_VALIDATION_RULES[dispositionStatus] && PRE_BOOKING_FORM_VALIDATION_RULES[dispositionStatus][val] ? PRE_BOOKING_FORM_VALIDATION_RULES[dispositionStatus][val].required : false
              }
            );
            emptyFields = fieldsRequired.filter((val) => {return !formState[val];});
            hasError = emptyFields.length > 0;
            if (!hasError) {
              setSubmitPostData(true);
            } else {
              setPostData(false);
              setSubmitPostData(false);
              emptyFields.length > 0 && showToastMessages(`Please provide valid ${emptyFields[0]} input in ${PRE_BOOKING_FORM_VALIDATION_RULES[dispositionStatus][emptyFields[0]].tabName} tab`, false, 5000);
            }
        }
      } else {
        setSubmitPostData(true);
      }
    }, [postData]);

    useEffect(() => {
      if (submitPostData) {
        setSubmitErrors();
        setShowSubmitErrors(false);
        orderData && onBookingUpdated();

        if (isValidDisposition) {
          const formData = {
            ...dialerData,
            customerInfo,
            tags:selectedTags,
            additionalFields: disposition.additionalFields,
            name: formState.name,
            email: formState.email,
            bankStatementStatus: formState.bankStatementStatus,
            sharedAppIds: formState.sharedAppIds,
            bankStatementShared: formState.bankStatementShared,
            cfFormLinkShared: formState.cfFormLinkShared,
            ppUserId: formState.ppUserId,
            buyerPincode: formState.buyerPincode,
            pincode: formState.buyerPincode,
            buyerPhone: formState.buyerPhone,
            phone: formState.buyerPhone,
            alternatePhone: formState.alternatePhone,
            sharedCarLink: formState.sharedCarLink,
            orderLinkShared: formState.orderLinkShared,
            initiatedBookings: formState.initiatedBookings,
            orders: formState.orders,
            sellCarFirst: formState.sellCarFirst,
            cfToPod: formState.cfToPod,
            podToCf: formState.podToCf,
            hdToPickup: formState.hdToPickup,
            pickupToHd: formState.pickupToHd,
            visitDateUpdated: formState.visitDateUpdated,
            handPickedCarAppIds: formState.handPickedCarAppIds,
            completeBookingIds: formState.completeBookingIds,
            ccDispositionId: disposition.dispositionId,
            ccDisposition: disposition.ccDisposition,
            ccSubDisposition: disposition.ccSubDisposition,
          };
          setIsSubmit(true);
          DataService.submitCCOBBookingAssistData(formData).then((response) => {
            if (response.errors && response.errors.length > 0) {
              setSubmitErrors(response.errors);
              setShowSubmitErrors(true);
              setIsSubmit(false);
            } else {
              reset();
              setRedirectToThankYou(true);
              setSubmitErrors();
              setShowSubmitErrors(false);
            }
            setSubmitPostData(false);
            setPostData(false);
          });
        } else {
          setPostData(false);
          setSubmitPostData(false);
        }
      }
    }, [submitPostData]);   
    
    const reset = () => {
      setDisposition();
      setPostData(false);
      setIsValidDisposition(false);
      setIsSubmit(false);
    };

    if (redirectToThankYou) {
      return <Redirect to='/thank-you' />;
    }
  
    if (showLoader) {
      return (
        <div className='container text-center loader'>
          <img src={Loader} alt='Loading' className='center-block' />
        </div>
      );
    }
  
    if (showError) {
      return (
        <div className='no-results container-fluid'>
          <img src={NoResult} alt='no record' />
        </div>
      );
    }
    const onBackClick = () => {
      props.history.push({pathname: `/welcome`})
    };

    const onRequestCallback = () => {
      setIsIndependentRequestCallbackClick(true);
      DataService.onIndependentRequestCallBack(getParams.phone, callBackLanguage)
        .then((resp)=> {
          formDispatch({
              type: 'indepentdentCallbackRequested',
              indepentdentCallbackRequested : true
          });
          showToastMessages('Callback request is successfully created', true, 5000);
        }).catch((err)=>{
          showToastMessages(err.message, false, 5000);
        }).finally(()=>{
          setIsIndependentRequestCallbackClick(false);
        });
    };

    const onIndependentSaveClick = () => {
      setIsIndependentSaveClick(true);
      onBookingUpdated();
      const payload = {...formState, pincode: formState.buyerPincode};
      delete payload.bookingDate;
      if (!!payload.name && payload.name.length > 89) {
        showToastMessages(`Please provide valid customer name input in Customer Details tab`, false, 5000);
        setIsIndependentSaveClick(false);
        return;
      }
      if (!!payload.email && !payload.emailNotRequired && payload.email.length > 74) {
        showToastMessages(`Please provide valid customer email input in Customer Details tab`, false, 5000);
        setIsIndependentSaveClick(false);
        return;
      }
      DataService.saveIndependentCCUI(payload).then((response) => {
        showToastMessages(response.message, response.code === "SUCCESS200", 5000);
        response.code === "SUCCESS200" && formDispatch({
          type: 'uiReset'
        });
      }).catch((err)=>{
        showToastMessages(err.message, false, 5000);
      }).finally(()=>{
        setTimeout(()=>{
          fetchDeliveryBookings();
          setIsIndependentSaveClick(false);
        }, 2000);
        
      });
    };

    return (
        <div className={`container-fluid ${styles.mainWrapper}`}>
          {getParams.sourceType === DispositionConstants.CC_OB_INDEPENDENT &&  <div className='row'>
            <div className={`col-5 form-group disp-box d-flex align-items-center ${styles.buttonWrapper}`}>
              <Button id='back' className="submit" onClick={onBackClick}>Back to Home Page</Button>
              <Button id='independent-cc-ui-save' className="submit ml-10" disabled={isIndependentSaveClick} onClick={onIndependentSaveClick}>Submit Details</Button>
              <Button id='independent-cc-ui-independent-request-callback' className="submit ml-10" disabled={isIndependentRequestCallbackClick || formState.indepentdentCallbackRequested} onClick={onRequestCallback}>Request Callback</Button>
              <SelectComponent
                  key={'languageType'}
                  name={'languageType'}
                  hideSelectedOptions={false}
                  isClearable={false}
                  optionsList={LANGUAGE_TYPES}
                  onChange={(e)=> setCallBackLanguage(e)}
                  defaultValue={LANGUAGE_TYPES[0]}
              />
            </div>
          </div>}
          <div className='row'>
            <div className='col-8'>
              <BuyerProfiles data={buyerProfiles} financeStatus={formState.financeStatus} params={getParams}/>
            </div>
            {!showLoader && (
              <div className={`col-4 ${styles.dispositionDetailsWrapper}`}>
                {!getParams.sourceType &&  dispositionData && <DispositionDetails
                  leadType={leadType}
                  whatsappOpted={formState.whatsappOpted}
                  phone={formState.buyerPhone}
                  dispositionData={dispositionData}
                  setDisposition={setDisposition}
                  setSubDispositionStatus={setSubDispositionStatus}
                  subDispositionStatus={subDispositionStatus}
                  defaultDisposition={defaultDisposition}
                  isSubmit={isSubmit}
                  setIsValidDisposition={setIsValidDisposition}
                  setPostData={setPostData}
                  submitErrors={submitErrors}
                  showSubmitErrors={showSubmitErrors}
                  setShowSubmitErrors={setShowSubmitErrors}
                  selectedCcDispositionId={selectedCcDispositionId}
                  setSelectedCcDispositionId={setSelectedCcDispositionId}
                  selectedTags={selectedTags}
                  setSelectedTags={setSelectedTags}
                  ctaText={'Submit & Dispose'}
                />}
                {formState.orderSummaryRequired && activeTab === CAMPAIGN_TABS.BOOKING_DETAILS && <OrderSummaryDetails
                  form={{ state: formState, dispatch: formDispatch }}
                  orderData={orderData}
                  pincodeData={pincodeData}
                  selectedPickupLocationIndex={selectedPickupLocationIndex}
                  leadType={leadType}
                  onBookingUpdated={onBookingUpdated}
                  setOrderData={setOrderData}
                  />}
              </div>
            )}
          </div>
          {!showLoader && <div className='row'>
            <div className='col-12'>
              <div className={`main_section_disp  ${styles.outboundBlock}`}>
                <OutBoundDetails
                  leadInteractions={leadInteractions}
                  leadType={leadType}
                  customerDetails={customerDetails}
                  bookingsData={bookingsData}
                  rules={formValidationRules}
                  form={{ state: formState, dispatch: formDispatch }}
                  orderData={orderData}
                  setOrderData={setOrderData}
                  pincodeData={pincodeData}
                  setPincodeData={setPincodeData}
                  fetchDeliveryBookings={fetchDeliveryBookings}
                  selectedPickupLocationIndex={selectedPickupLocationIndex}
                  setSelectedPickupLocationIndex={setSelectedPickupLocationIndex}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  onBookingUpdated={onBookingUpdated}
                  recommendCars={recommendCars}
                  discountData={discountData}
                  preApprovedData={preApprovedData}
                  setPreApprovedData={setPreApprovedData}
                  lmsLeadId={getParams.lmsLeadId}
                />
              </div>
            </div>
          </div>}
        </div>
    );
  };
  export default CCOBPreBooking;