/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import DealInformation from '../finance-dealInfo';
import { withRouter } from 'react-router-dom';
import { TAB_LIST } from './constant';
import FinancePaymentTab from '../finance-payment-tab';
import FinanceServiceCharge from '../finance-service-tab';
import { capitalizeFirstLetter } from '../../../shared/utils/helper';
import Card from '../../../shared/card';
import FinanceRefunds from '../finance-refunds';
import PostDeliverySales from '../finance-post-delivery-sales';

const TabNavs = ({ list, activeTab, toggle, getStatus }) => {
  return (
    <ul className='nav nav-tabs'>
      {list.map((item, index) => {
        return (
          <li
            key={index}
            className={activeTab === index ? 'active' : ''}
            onClick={() => toggle(index)}
          >
            <a>
              <b>
                {item.withStatus
                  ? `${item.label} ${getStatus(item.key)}`
                  : item.label}
              </b>
            </a>
          </li>
        );
      })}
    </ul>
  );
};

const CustomTabItem = (props) => {
  const { activeTab, tabIndex, children } = props;
  if (activeTab === tabIndex) {
    return (
      <div className={activeTab === tabIndex ? 'tab-pane active' : 'tab-pane'}>
        {children}
      </div>
    );
  } else {
    return null;
  }
};

const FinanceTabs = ({ dealInfo }) => {
  const [activeTab, setActiveTab] = useState(0);
  const toggleCallback = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  const getStatus = (customer) => {
    const keyname =
      customer === 'buyerPayment' ? 'buyer_validation' : 'seller_validation';
    const status =
      dealInfo && dealInfo[keyname]
        ? capitalizeFirstLetter(dealInfo[keyname])
        : null;
    return status ? ` - ${status}` : '';
  };

  const cardList = [
    {
      title: 'UID',
      value: 'DEL#10000',
      boxBg: 'bg-sky-blue',
      key: 'deal_identifier'
    },
    {
      title: 'Car Source',
      value: 'Guaranteed Sales',
      boxBg: 'bg-purple',
      key: 'display_car_source'
    }
    // {
    //   title: 'NBFC Case',
    //   value: 'Yes',
    //   boxBg: 'bg-peach',
    //   key: 'nbfc_answer'
    // }
  ];

  return (
    <div className='col-md-12'>
      <div className='row'>
        {cardList.map((item, i) => (
          <span key={i}>
            <Card
              title={item.title}
              value={(dealInfo && dealInfo[item.key]) || '--'}
              boxBg={item.boxBg}
            />
          </span>
        ))}
        <div className='nav-tabs-custom no-box-shadow full-grid'>
          <TabNavs
            list={TAB_LIST}
            activeTab={activeTab}
            toggle={toggleCallback}
            getStatus={getStatus}
          />
          <div className='tab-content'>
            <CustomTabItem activeTab={activeTab} tabIndex={0}>
              <DealInformation />
            </CustomTabItem>

            <CustomTabItem activeTab={activeTab} tabIndex={1}>
              <FinanceServiceCharge />
            </CustomTabItem>

            <CustomTabItem activeTab={activeTab} tabIndex={2}>
              <FinancePaymentTab
                user_type='buyer'
                status={getStatus('buyer')}
              />
            </CustomTabItem>

            <CustomTabItem activeTab={activeTab} tabIndex={3}>
              <FinancePaymentTab
                user_type='seller'
                status={getStatus('seller')}
              />
            </CustomTabItem>
            <CustomTabItem activeTab={activeTab} tabIndex={4}>
              <FinanceRefunds />
            </CustomTabItem>
            <CustomTabItem activeTab={activeTab} tabIndex={5}>
              <PostDeliverySales />
            </CustomTabItem>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(FinanceTabs);
