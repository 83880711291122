import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  FETCH_REMARKS_REQUESTED
  FETCH_REMARKS_SUCCESS
  FETCH_REMARKS_FAILURE

  FETCH_OTHERS_RC_REMARKS_REQUESTED
  FETCH_OTHERS_RC_REMARKS_SUCCESS
  FETCH_OTHERS_RC_REMARKS_FAILURE
`,
  {
    prefix: 'rcDocuments_remarks/'
  }
);
