const descopeConfigDev = {
  projectId: "P2gP9VJGfQyhq8WeHI9aLEw99Cgf",
  flowId: "sales-panel-sign-in"
}

const descopeConfigProd = {
  projectId: "P2juPVYdngNU4S5v3ROIxRxMI3UW",
  flowId: "konnect-panel-sign-in-flow"
}

export const descopeConfig = (function() {
  const env = process.env.REACT_APP_ENV;

  switch(env) {
    case 'qa': {
      return descopeConfigDev;
    }
    case 'stage': {
      return descopeConfigDev;
    }
    case 'prod': {
      return descopeConfigProd;
    }
    default: {
      return descopeConfigDev;
    }
  }
})();