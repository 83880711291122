import { BASE_API_URL } from '../../services/adminApi';
import { config } from '../config/local';

export default (api) => {
  const fetchRegionList = (params) => {
    const url = config.api.c2c.documentRuleEngine.fetchRegionList.path;
    return api
      .get(url)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        return error;
      });
  };
  
  const fetchAttributesData = (params) => {
      const url = config.api.c2c.documentRuleEngine.fetchAttributesData.path;
      return api
        .get(url)
        .then((resp) => {
          const {
            data: { data }
          } = resp;
          return data;
        })
        .catch((error) => {
          return error;
        });
    };

  const fetchDocumentConfig = (params) => {
    return new Promise((resolve, reject) => {
      const url = `${config.api.c2c.documentRuleEngine.fetchDocumentConfig.path}?regionId=${params.regionId}`;
      api.get(url, params)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          })
    });
  }

  const addDocumentConfig = (params, body) => {
    return new Promise((resolve, reject) => {
      const url = config.api.c2c.documentRuleEngine.addDocumentConfig.path;
      api.post(url, body)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          })
    });
  }

  const updateDocumentConfig = (params, body) => {
    return new Promise((resolve, reject) => {
      const url = config.api.c2c.documentRuleEngine.updateDocumentConfig.path;
      api.put(url, body)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          })
    });
  }

  const downloadConfigRules = (params) => {
    const url = config.api.c2c.documentRuleEngine.downloadConfigRules.path;
    window.open(`${BASE_API_URL.substring(0, BASE_API_URL.length - 1)}${url}`, "_blank");
  }

  const uploadConfigRules = (params, body) => {
    return new Promise((resolve, reject) => {
      const url = config.api.c2c.documentRuleEngine.uploadConfigRules.path;
      api.post(url, body)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          })
    });
  }

  const fetchDocumentsList = () => {
    const url = config.api.c2c.documentRuleEngine.fetchDocumentsList.path;
    return api
      .get(url)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        throw error;
      });
  };

  const deleteDocumentConfig = (id) => {
    return new Promise((resolve, reject) => {
      const url = `${config.api.c2c.documentRuleEngine.deleteDocumentConfig.path}/${id}`;
      api.delete(url)
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            reject(error);
        })
    });
  }

  const fetchDocumentRejectionReasonsConfig = (params) => {
    return new Promise((resolve, reject) => {
      const url = `${config.api.c2c.documentRuleEngine.fetchDocumentRrConfig.path}/${params.documentId}`;
      api.get(url, params)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          })
    });
  };

  const addDocumentRejectionReasonsConfig = (params, body) => {
    return new Promise((resolve, reject) => {
      const url = config.api.c2c.documentRuleEngine.addDocumentRrConfig.path;
      api.post(url, body)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          })
    });
  };

  const updateDocumentRejectionReasonsConfig = (params, body) => {
    return new Promise((resolve, reject) => {
      const url = config.api.c2c.documentRuleEngine.updateDocumentRrConfig.path;
      api.put(url, body)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          })
    });
  };

  const deleteDocumentRejectionReasonsConfig = (id) => {
    return new Promise((resolve, reject) => {
      const url = `${config.api.c2c.documentRuleEngine.deleteDocumentRrConfig.path}/${id}`;
      api.delete(url)
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            reject(error);
        })
    });
  }

  const fetchRejectionReasonsByDocument = () => {
    const url = config.api.c2c.documentRuleEngine.getDocumentRejectionReasons.path;
    return api
      .get(url)
      .then((resp) => {
        return resp.data.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  return {
    fetchRegionList,
    fetchAttributesData,
    addDocumentConfig,
    updateDocumentConfig,
    fetchDocumentConfig,
    downloadConfigRules,
    uploadConfigRules,
    fetchDocumentsList,
    fetchDocumentRejectionReasonsConfig,
    addDocumentRejectionReasonsConfig,
    updateDocumentRejectionReasonsConfig,
    deleteDocumentRejectionReasonsConfig,
    fetchRejectionReasonsByDocument,
    deleteDocumentConfig,
  };
};