import React, { useEffect, useState } from "react";
import { showToastMessages } from "../../../../shared/utils/helper";
import { ToggleButtonView } from "../../shared/toggle-button";

export const ShowInDocketToggle = ({ docData, dealId, updateDocketDocVisibilityConnect, fetchDocketManagementDocsConnect }) => {
  const [showInDocket, setShowInDocket] = useState(false);

  const handleSelect = (value) => {
    updateDocketDocVisibilityConnect(
      {
        dealId,
        showInDocket: value,
        fileType: docData.file_type,
        fileUserType: docData.file_user_type,
      }
    )
      .then((resp) => {
        showToastMessages(resp.message, true);
        setShowInDocket(value);
        fetchDocketManagementDocsConnect({ dealId });
      })
      .catch((err) => {
        showToastMessages(err.message, false);
      })
  };

  useEffect(() => {
    setShowInDocket(docData.show_in_docket);
  }, [docData]);

  return (
      <ToggleButtonView isOn={showInDocket} onSelect={handleSelect} key={docData.file_type}/>
  );
}