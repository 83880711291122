import React from 'react';
import PropTypes from 'prop-types';
import styles from './component.module.css';

const ZoomWrapper = ({ zoomIn, zoomOut }) => {
  return (
    <section className={styles.zoomHandler}>
      <button className={styles.pl20} onClick={zoomOut}>
        <i class={`fa fa-minus`} />
      </button>
      <button className={styles.pr20} onClick={zoomIn}>
        <i class={`fa fa-plus`} />
      </button>
    </section>
  );
};

ZoomWrapper.propTypes = {
  childern: PropTypes.object
};

export default ZoomWrapper;
