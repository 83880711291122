import { createReducer } from 'reduxsauce';
import Types from "../constants/actionTypes";

export const INITIAL_STATE = {
    detail: {},
    isLoading: false
  };
  
  export const fetchLanguagesInit = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
  };
  
  export const fetchLanguagesSuccess = (state = INITIAL_STATE, { data: detail }) => {
    return {
      ...state,
      detail,
      isLoading: false
    };
  };
  
  export const fetchLanguagesFail = (state = INITIAL_STATE) => {
    return { ...state, isLoading: false };
  };
  
  export const HANDLERS = {
    [Types.ACTION_LANGAUGE_INIT]: fetchLanguagesInit,
    [Types.ACTION_LANGAUGE_SUCCESS]: fetchLanguagesSuccess,
    [Types.ACTION_LANGAUGE_FAIL]: fetchLanguagesFail
  };
  
  export default createReducer(INITIAL_STATE, HANDLERS);