import { getSidebarList, ROLE_ROUTES_MAPPING } from '../../router/config_roles';
import { toast } from 'react-toastify';
import locale from './locale';
import { C2C_LMS_ROLES, checkForUserRoles, IS_ENV_DEV, REACT_APP_ENV, USER_ROLES } from './constants';

const numWords = require('num-words');

const getEmailFromLocalStorage = () => {
  let email = localStorage.hasOwnProperty('email')
    ? localStorage.getItem('email')
    : '';
  return email;
};

const getIsLoggedInFromLocalStorage = () => {
  let isLoggedIn = localStorage.hasOwnProperty('isLoggedIn')
    ? localStorage.getItem('isLoggedIn')
    : '';
  return isLoggedIn;
};

const removeDataFromLocalStorage = () => {
  localStorage.clear();
};

const getPriceInWords = (number) => {
  number = Number(number);
  return number && number.toString().length < 10 ? numWords(number) : '';
};

const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

const getFromLocalStorage = (key) => {
  return localStorage.getItem(key);
};

const onNumberKeyPress = (e) => {
  if (e.charCode === 0 || e.charCode === 8 || e.charCode === 13) {
    return null;
  } else if (e.charCode >= 48 && e.charCode <= 57) {
    return true;
  } else {
    e.preventDefault();
    return false;
  }
};

const isRouteAuthorised = (pathname) => {
  const allowedRoutes = getAuthorisedRoutes();
  if (!allowedRoutes) return;
  const filteredRoutes = allowedRoutes.filter((item) => {
    const isActive = pathname === item.path;
    return isActive;
  });
  return filteredRoutes.length === 0 ? false : true;
};

const getAuthorisedRoutes = () => {
  if (!getFromLocalStorage(USER_ROLES)) return [];

  const rolesToCheck = [C2C_LMS_ROLES.CC_AGENT_FOR_C2C, C2C_LMS_ROLES.DSA_AGENT_FOR_C2C];
  const userRole = checkForUserRoles(rolesToCheck);
  const allowedRoutes = ROLE_ROUTES_MAPPING[userRole] || ROLE_ROUTES_MAPPING['DEFAULT'];

  let sidebarList = [];
  
  getSidebarList().forEach((item) => {
    if (allowedRoutes.includes(item.key)) {
      sidebarList.push(item);
    }
  });
  return sidebarList;
};

const findByKey = (array = [], key, value, cb) => {
  for (let i = 0; i < array.length; i++) {
    if (
      typeof cb === 'function'
        ? cb(array[i][key], value)
        : array[i][key] === value
    ) {
      return { ...array[i], itemIndex: i };
    }
  }
  return {};
};

const showToastMessages = (message, isSuccess = true, autoClose = 3000) => {
  toast.dismiss();
  setTimeout(() => {
    if (isSuccess) {
      toast.success(message, {
        hideProgressBar: true,
        autoClose: autoClose
      });
    } else {
      toast.error(message, {
        hideProgressBar: true,
        autoClose: autoClose,
        closeOnClick: true
      });
    }
  }, 0);
};

const showToast = (message, type = 'default', autoClose = 3000) => {
  toast.dismiss();
  setTimeout(() => {
    toast[type](message, {
      // position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      autoClose: autoClose,
      pauseOnHover: true,
      closeOnClick: true
    });
  }, 0);
};

const formatDate = (date) => {
  const d = new Date(date);
  if (date && d instanceof Date && !isNaN(d)) {
    // check for valid date
    const formatedDate =
      ('0' + d.getDate()).slice(-2) +
      '-' +
      ('0' + (d.getMonth() + 1)).slice(-2) +
      '-' +
      d.getFullYear();
    return formatedDate;
  } else {
    return null;
  }
};

const formatDateTime = (date, separator="/") => {
  const d = new Date(date);
  if (date && d instanceof Date && !isNaN(d)) {
    // check for valid date
    const formatedDate =
      ('0' + d.getDate()).slice(-2) +
      separator +
      ('0' + (d.getMonth() + 1)).slice(-2) +
      separator +
      d.getFullYear();
     ;
    let curr_min = d.getMinutes();
    let curr_hr= d.getHours();
    if(curr_hr.toString().length == 1)
      curr_hr = '0' + curr_hr;
    if(curr_min.toString().length == 1)
      curr_min = '0' + curr_min;
    return `${formatedDate} ${curr_hr}:${curr_min}:${d.getSeconds()}`;
  } else {
    return null;
  }
};

const isObject = (obj) => {
  return obj !== null && obj === Object(obj);
};

const isArrayEmpty = (item) => {
  // return true when array is empty or data type is not any array
  const is_array = Array.isArray(item);
  if (!is_array) {
    return true; // not an array
  } else {
    return item.length > 0 ? false : true; // check array is empty of not
  }
};

const isValidURL = (string) => {
  var res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
};

const openUrlTab = (url, type = '_blank') => {
  if (url && isValidURL(url)) {
    const myWindow = window.open(url, type);
    return myWindow;
  } else {
    return false;
  }
};

const downloadBlob = (data, filename) => {
  try {
    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', filename); //filename with extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    showToastMessages(locale.DOWNLOAD_FAILED, false);
  }
};

const console_in_dev = (data, identifier = 'data') => {
  IS_ENV_DEV && console.log(identifier, data);
};

const filterObject = (obj) => {
  if (isObject(obj)) {
    for (var prop in obj) {
      if (obj[prop] === null || obj[prop] === undefined) {
        delete obj[prop];
      }
    }
    return obj;
  } else {
    return {};
  }
};

// Creates a option list for SelectComponent
const getParsedSelectOptions = (list, params) => {
  // todo::Currently this works only for array of object, add support for plain array where label and value keys will have same data
  const { labelKey, valueKey } = params;
  if (!isArrayEmpty(list) && labelKey && valueKey) {
    return list.map((item) => {
      return { ...item, label: item[labelKey], value: item[valueKey] };
    });
  } else {
    return [];
  }
};
const getSelectedOption = (list, searchKey = 'value', search = null) => {
  if (!isArrayEmpty(list) && searchKey && search) {
    let result = list.filter(
      (item) => item[searchKey] && item[searchKey] === search
    );
    return result[0] || null;
  } else {
    return null;
  }
};

const getMultiSelectedOption = (
  list,
  search = null,
  valueKey = 'value',
  labelKey = 'label'
) => {
  if (!isArrayEmpty(list) && valueKey && search) {
    const result = list.filter((item) => search.includes(item[valueKey]));

    // check label and value key
    const selectedOpts = result.map((item) => {
      return { ...item, value: item[valueKey], label: item[labelKey] };
    });

    return selectedOpts || null;
  } else {
    return null;
  }
};
const getObjectFromArraySelect = (value) => {
  let result = [];
  if (value && !isArrayEmpty(value)) {
    value &&
      value.forEach((item) => {
        result.push({
          label: item,
          value: item
        });
      });

    return result;
  } else return [];
};
const getMappedData = (data, keyName) => {
  let result = [];
  if (data && keyName && !isArrayEmpty(data)) {
    data.forEach((item) => {
      result.push(item[keyName]);
    });
    return result;
  } else return [];
};

const getFilterListData = (list, value) => {
  if (value && list && !isArrayEmpty(list)) {
    let result = list.filter((item) => {
      return item.value === value;
    });
    return result;
  } else return null;
};
const catchErrorMessage = (error) => {
  if (error.errors && error.errors[0] && error.errors[0]['message']) {
    showToastMessages(error.errors[0]['message'], false);
  }
};
const validation = {
  isEmailAddress: function(str) {
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return pattern.test(str); // returns a boolean
  },
  isNotEmpty: function(str) {
    var pattern = /\S+/;
    return pattern.test(str); // returns a boolean
  },
  isNumber: function(str) {
    var pattern = /^\d+$/;
    return pattern.test(str); // returns a boolean
  },
  isSame: function(str1, str2) {
    return str1 === str2;
  }
};

const pattern = {
  email:
    "/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/",
  mobile: '[6-9]{1}[0-9]{9}',
  amount: '[0-9]+'
};

const capitalizeFirstLetter = (string) => {
  if (string && typeof string === 'string')
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  else return '';
}

const toCamelCase = (value) => {
  const sentence = value.replace("_", " ").toLowerCase();
  return sentence.split(' ').map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
};

const isUsersViewAccessible = () => {
  const getAllowedUsersList = (env) => {
    const qaUsers = ["rahul.yadav5@cars24.com", "neha.pundir@cars24.com", "rohit.kr03@cars24.com", "ishan.agarwal@cars24.com", "jaspreet.juneja@cars24.com"];
    const prodUsers = ["anil.kr04@cars24.com", "aman.rajput@cars24.com", "poonam.sheoran@cars24.com", "aayush.goel@cars24.com", "rahul.yadav5@cars24.com", "neha.pundir@cars24.com", "jaspreet.juneja@cars24.com", "rohit.kr03@cars24.com"];
    switch (env) {
      case 'qa':
        return qaUsers;
      case 'stage':
        return qaUsers;
      case 'prod':
        return prodUsers; 
      default:
        return qaUsers;
    }
  }

  const loggedInUser = localStorage.getItem("email");
  const USERS_WITH_ACCESS = getAllowedUsersList(REACT_APP_ENV);
  return !!USERS_WITH_ACCESS.includes(loggedInUser);
};

export {
  formatDateTime,
  getFilterListData,
  getMappedData,
  getObjectFromArraySelect,
  getMultiSelectedOption,
  getSelectedOption,
  getEmailFromLocalStorage,
  removeDataFromLocalStorage,
  getIsLoggedInFromLocalStorage,
  getPriceInWords,
  saveToLocalStorage,
  getFromLocalStorage,
  onNumberKeyPress,
  isRouteAuthorised,
  getAuthorisedRoutes,
  findByKey,
  showToast,
  showToastMessages,
  formatDate,
  isObject,
  isArrayEmpty,
  openUrlTab,
  downloadBlob,
  console_in_dev,
  filterObject,
  getParsedSelectOptions,
  catchErrorMessage,
  capitalizeFirstLetter,
  validation,
  pattern,
  toCamelCase,
  isUsersViewAccessible,
};
