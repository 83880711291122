import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ZonesGrid from './component';
import { fetchZonesListConnected } from './actions';

const mapStateToProps = (state) => {
  const { zoneInfo } = state;
  return { zoneInfo };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchZonesListConnected }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ZonesGrid);
