export const INPUT_BOX_DETAIL = [
  {
    label: 'Dealer Id',
    value: 'dealerId',
    disabled: false,
    type: 'number',
    required: true,
    min: 1
  },
  {
    label: 'Dealer Code',
    value: 'dealerCode',
    disabled: false,
    type: 'text',
    required: true
  },
  {
    label: 'Name',
    value: 'name',
    disabled: false,
    type: 'text',
    required: true
  },
  {
    label: 'Mobile Number',
    value: 'mobileNumber',
    disabled: false,
    type: 'number',
    required: true,
    min: 10
  },
  {
    label: 'Email',
    value: 'email',
    disabled: false,
    type: 'text',
    required: true
  },
  {
    label: 'Dealer Type',
    value: 'dealerType',
    disabled: true,
    type: 'dropDown',
    required: true,
    listName: 'dealerType'
  },
  {
    label: 'Address Line 1',
    value: 'addressLine1',
    disabled: false,
    type: 'textArea',
    required: true
  },
  {
    label: 'Address Line 2',
    value: 'addressLine2',
    disabled: false,
    type: 'textArea',
    required: true
  },
  {
    label: 'State',
    value: 'stateId',
    disabled: false,
    type: 'dropDown',
    required: true,
    listName: 'stateId'
  },
  {
    label: 'City',
    value: 'cityId',
    disabled: false,
    type: 'dropDown',
    required: true,
    listName: 'cityId'
  },
  {
    label: 'Zones',
    value: 'zoneId',
    disabled: false,
    type: 'dropDown',
    required: true,
    listName: 'zoneId'
  },
  {
    label: 'Is Active?',
    value: 'status',
    disabled: false,
    type: 'checkBox',
    required: true
  }
];
