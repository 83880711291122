import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  FETCH_ZONES_INIT
  FETCH_ZONES_SUCCESS
  FETCH_ZONES_FAIL
`,
  {
    prefix: 'zones-grid/'
  }
);
