import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  SET_PAGE_NUMBER
`,
  {
    prefix: 'stores/'
  }
);
