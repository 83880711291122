import * as httpService from "./httpService";
import { httpConstants } from "../constants/httpConstants";
import { config } from "../config/local";
import { showToastMessages } from "../../shared/utils/helper";

//update inventory
export const updateInventory = async (data, params) => {
  const host = config.api.c2c.host;
  const path = config.api.c2c.updateInventory.path;
  const url = host.concat(path);
  const response = await httpService.put(url, data, params);
  if (response && response.status === httpConstants.HTTP_STATUS_OK) {
    showToastMessages("Inventory has been updated successfully");
    return response.data ? response.data : [];
  } else {
    return [];
  }
};

//get inventory log data
export const getInventoryLog = async appointmentId => {
  const host = config.api.c2c.host;
  const path = config.api.c2c.inventoryLog.path;
  const url = host
    .concat(path)
    .concat("/")
    .concat(appointmentId);
  const response = await httpService.get(url, {});
  if (response && response.status === httpConstants.HTTP_STATUS_OK) {
    return response.data ? response.data : [];
  } else {
    return [];
  }
};
