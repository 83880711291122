import React from 'react';
import Types from "../constants/actionTypes";
import { DataService } from "./../../client/service";
import { showToastMessages } from '../../shared/utils/helper';

//Fetch store details
const fetchZoneDetailInit = () => ({
  type: Types.ACTION_FETCH_ZONE_INIT
});

const fetchZoneDetailSuccess = data => ({
  type: Types.ACTION_FETCH_ZONE_SUCCESS,
  data
});

const fetchZoneDetailFail = error => ({
  type: Types.ACTION_FETCH_ZONE_FAIL,
  error
});

const fetchZoneDetail = ZoneId => dispatch => {
  dispatch(fetchZoneDetailInit());
  return new Promise((resolve, reject) => {
    DataService
      .fetchSpecificZone(ZoneId)
      .then(resp => {
        resolve(resp);
        dispatch(fetchZoneDetailSuccess(resp));
      })
      .catch(error => {
        dispatch(fetchZoneDetailFail(error));
        reject(error);
      });
  });
};

const addZoneInit = () => ({
  type: Types.ACTION_ADD_ZONE_INIT
});

const addZoneSuccess = data => ({
  type: Types.ACTION_ADD_ZONE_SUCCESS,
  data
});

const addZoneFail = error => ({
  type: Types.ACTION_ADD_ZONE_FAIL,
  error
});

const addZone = data => dispatch => {   
  DataService
      .addZone(data)
      .then(resp => {
        showToastMessages(resp.data.message);     
      })
      .catch(error => {
        showToastMessages(error.message, false);
      });
};

const fetchAllZonesInit = () => ({
  type: Types.ACTION_FETCH_ALL_ZONES_INIT
});

const fetchAllZonesSuccess = data => ({
  type: Types.ACTION_FETCH_ALL_ZONES_SUCCESS,
  data
});

const fetchAllZonesFail = error => ({
  type: Types.ACTION_FETCH_ALL_ZONES_FAIL,
  error
});

const fetchAllZones = data => dispatch => {
  dispatch(fetchAllZonesInit());
  return new Promise((resolve, reject) => {
    DataService
      .fetchZonesList()
      .then(resp => {
        resolve(resp);
        dispatch(fetchAllZonesSuccess(resp));
      })
      .catch(error => {
        dispatch(fetchAllZonesFail(error));
        reject(error);
      });
  });
};

//export all usable actions
export { fetchZoneDetail, addZone, fetchAllZones };
