import React, { useEffect, useState } from 'react';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';
import { withRouter } from 'react-router-dom';
import styles from './styles.module.css';
import {CheckBox, FileUpload, SelectDropdown} from '../../../client/components/shared';
import { dealInfoService } from '../../../services';
import { openUrlTab, showToastMessages } from '../../../shared/utils/helper';
import InputText from '../../../shared/components/input-text';
import DeChecklistLogs from '../de-checklist-logs/component';
import { ALLOWED_DOC_TYPES, SIZE } from '../rc-management-detail/constant';

const COURIER_TITLE = 'Courier';

const DeChecklist = ({match, setRcDealInfoConnect}) => {
  const [documentChecklist, setDocumentChecklist] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataModel, setDataModel] = useState([]);
  const [checkedState, setCheckedState] = useState([]);
  const [lastCheckedStates, setLastCheckedStates] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [courierList, setCourierList] = useState([]);
  const [courierPartner, setCourierPartner] = useState('');
  const [trackingId, setTrackingId] = useState('');
  const [isCourierSelected, setIsCourierSelected] = useState('');
  const [logs, setLogs] = useState([]);
  const [is2ndKeyUploaded, setIs2ndKeyUploaded] = useState(false);
  const getDealChecklistData = () => {
    setIsLoading(true);
    dealInfoService.getDealDeliveryChecklist(match.params.id).then((resp)=>{
      const updateDataModel = [];
      if (!!resp.data && resp.data.length > 0) {
        setDocumentChecklist(resp.data);
        if (!!resp.data[0].subParameters && !!resp.data[0].subParameters.length > 0) {
          setIs2ndKeyUploaded(!!resp.data[0].imageUrl);
          let first = resp.data[0].subParameters.find((value)=> {return !!value.selected});
          if (!!first) {
            updateDataModel.push({"subParamId": first.id, "paramId": resp.data[0].id});
            
          } else {
            updateDataModel.push({"paramId": resp.data[0].id});
          }
        }
        const items = !!resp.data[1].subParameters && resp.data[1].subParameters.map((value)=> {return !!value.selected});
        setCheckedState(items);
        setLastCheckedStates([...items]);
        const checkedItems = !!resp.data[1].subParameters && resp.data[1].subParameters.filter((value)=> !!value.selected);
        if (!!checkedItems && checkedItems.length > 0) {
          checkedItems.map((item)=>{
            updateDataModel.push({"subParamId": item.id, "paramId": resp.data[1].id});
          });
        } else {
          updateDataModel.push({"paramId": resp.data[1].id})
        } 

        if (!!resp.data[2].subParameters && !!resp.data[2].subParameters.length > 0) {
          const courier = resp.data[2].subParameters.find((item) => item.title === COURIER_TITLE);
          const {dispatchPartnerList=[],courierPartner='', trackingId='' } = courier || {};
          // fill master data
          const couriers = dispatchPartnerList.map((item)=> {
            return {
              label: item, value: item
            }
          });
          setCourierList(couriers);
          // prefill already existing data
          let second = resp.data[2].subParameters.find((value)=> {return !!value.selected});
          if (!!second) {
            if (second.title === COURIER_TITLE) {
              setIsCourierSelected(true);
              setCourierPartner(courierPartner);
              setTrackingId(trackingId);
              updateDataModel.push({
                "subParamId": second.id, 
                "paramId": resp.data[2].id,
                "trackingId": trackingId,
                "courierPartner": courierPartner
              });
            } else {
              updateDataModel.push({"subParamId": second.id, "paramId": resp.data[2].id});
            }
          } else {
            updateDataModel.push({"paramId": resp.data[2].id});
          }
        }
        if (!!resp.data[3].subParameters && resp.data[3].subParameters.length > 0) {
          let third = resp.data[3].subParameters.find((value)=> {return !!value.selected});
          if (!!third) {
            updateDataModel.push({"paramId": resp.data[3].id, "subParamId": third.id, "remarks": third.remarks});
          }else {
            updateDataModel.push({"paramId": resp.data[3].id})
          }
        }
      }
      setDataModel(updateDataModel);

      //get logs
      dealInfoService.getDealDeliveryChecklistLogs(match.params.id).then((resp)=>{
        setLogs(resp.data);
      })
    }).finally(()=>{
      setIsLoading(false);
    });
  };

  useEffect(()=>{
    getDealChecklistData();
  },[]);

  const updatedDataModel = (id, parentId, index) => {
    //handle only for document handover method
    if (index === 2) {
      const document = documentChecklist.find((item) => item.id === parentId);
      const selectedDocument = document.subParameters.find((item) => item.id.toString() === id);
      if (!!selectedDocument && selectedDocument.title === COURIER_TITLE) {
        setIsCourierSelected(true);
      } else {
        setIsCourierSelected(false);
      }
    }
    let updateDataModel = [...dataModel];
    const selectedItemIndex = updateDataModel.findIndex((item)=> item.paramId === parentId);
    if (selectedItemIndex > -1) {
      updateDataModel[selectedItemIndex].subParamId = id;
    } else {
      updateDataModel.push({"paramId": parentId, "subParamId": id});
    }
    setDataModel(updateDataModel);
  };

  const onTextChange = (value, id, parentId) => {
    let updateDataModel = [...dataModel];
    const selectedItemIndex = updateDataModel.findIndex((item)=> item.paramId === parentId);
    if (selectedItemIndex > -1) {
      updateDataModel[selectedItemIndex].subParamId = id;
      updateDataModel[selectedItemIndex].remarks = value;
    } else {
      updateDataModel.push({"paramId": parentId, "subParamId": id, "remarks": value});
    }
    setDataModel(updateDataModel);
  };

  const onChangeItem = (e, id, parentId, idx) => {
    let updateDataModel = [...dataModel];
    const selectedItemIndex = updateDataModel.findIndex((item)=> item.subParamId === id);
    if (selectedItemIndex > -1) {
      if (e.target.checked) {
        updateDataModel[selectedItemIndex].subParamId = id;
        checkedState[idx] = true;
      } else {
        updateDataModel.splice(selectedItemIndex, 1);
        checkedState[idx] = false;
      }
    } else {
      updateDataModel.push({"paramId": parentId, "subParamId": id});
      checkedState[idx] = true;
    }
    setDataModel(updateDataModel);
  };
  //refresh the deal data in cache
  const updateRcDealInfo = () => {
    dealInfoService
      .getDealInfoById(match.params.id, true)
      .then((res) => {
        setRcDealInfoConnect(res);
      })
      .catch((err) => {});
  };

  const getSelectedSubParam = (index) => {
    let param = documentChecklist[index];
    return dataModel.find((item)=> item.paramId === param.id);
  };

  const onSaveDocuments = () => {
    let data = getSelectedSubParam(2);
    if (!!data && !!data.subParamId) {
      const courierParam = documentChecklist[2].subParameters.find((item) => item.title === COURIER_TITLE);
      if (!!courierParam && courierParam.id.toString() === data.subParamId){
        if (!data.courierPartner) {
          showToastMessages('Please select Docs Handover Courier partner', false, 5000);
          return;
        } else if (!data.trackingId) {
          showToastMessages('Please select Docs Handover TrackingId', false, 5000);
          return;
        }
      }
    }
    const payload = dataModel.filter((item) => !!item.subParamId);
    setIsSaved(true);
    dealInfoService.saveDealDeliveryChecklist(match.params.id, payload).then((resp)=>{
      showToastMessages(resp.message);
      setTimeout(()=>{
        updateRcDealInfo();
        getDealChecklistData();
      },2000);
    }).catch((err)=>{
      console.log({err});
    }).finally(()=>{
      setIsSaved(false);
    });
  };

  const getSelectedItemId = (index, parentId) => {
    let item = documentChecklist[index].subParameters.find((value)=> {return !!value.selected});
    let element = dataModel.find((item)=> item.paramId === parentId);
    return !!element ? element.subParamId : !!item ? item.id : '';
  };

  const getRemarksValue = (index, parentId) => {
    let element = !!dataModel && dataModel.find((item)=> item.paramId === parentId);
    return !!element ? element.remarks : documentChecklist[index].subParameters[0].remarks;
  };

  const onSelectCourierPartner = (parentId, value) => {
    setCourierPartner(value);
    let updateDataModel = [...dataModel];
    const selectedItemIndex = updateDataModel.findIndex((item)=> item.subParamId === parentId);
    updateDataModel[selectedItemIndex].courierPartner = value;
    setDataModel(updateDataModel);
  };

  const onChangeTrackingId = (parentId, value) => {
    setTrackingId(value);
    let updateDataModel = [...dataModel];
    const selectedItemIndex = updateDataModel.findIndex((item)=> item.subParamId === parentId);
    updateDataModel[selectedItemIndex].trackingId = value;
    setDataModel(updateDataModel);
  };

  const deleteFile = () => {
    dealInfoService
      .deleteSecondKeyImageFile(match.params.id)
      .then((res) => {
        setIs2ndKeyUploaded(false);
        setTimeout(()=>{
          getDealChecklistData();
        },2000);
      });
  };
  const onUpload = (docName, file, fileFor, index) => {
    dealInfoService.uploadSecondKeyImageFile(match.params.id, file).then((resp)=>{
      setIs2ndKeyUploaded(true);
      setTimeout(()=>{
        getDealChecklistData();
      },2000);
    });
  };

  return ( !isLoading ?
    <LoaderWithAccessControl>
      <div className={styles.deChecklist}>
        <div className="row">
          <div className="col-md-3">
            <label className='col-form-label'>{documentChecklist[0].title}</label>
          </div>
          <div className="col-md-3">
            <SelectDropdown
              key={`dc-step-1-${documentChecklist[0].id}`}
              name={`dc-step-1-${documentChecklist[0].id}`}
              optionsList={!!documentChecklist[0].subParameters && documentChecklist[0].subParameters.map((item)=>{
                return {
                  label: item.title, value: item.id
                }
              })}
              onChange={(e) => updatedDataModel(e.target.value, documentChecklist[0].id, 0)}
              value={getSelectedItemId(0, documentChecklist[0].id)}
              isSelectRequired={true}
            />
          </div>
        </div>
        <div className="row mt10">
          <div className="col-md-3">
            <label>{"Upload 2nd Key"}</label>
          </div>
          {is2ndKeyUploaded ? (
            <>
              <div className='col-1 text-center'>
                <button
                  className='btn btn-primary'
                  onClick={() =>
                    openUrlTab(documentChecklist[0].imageUrl)
                  }
                >
                  View
                </button>
              </div>
              <div className='col-1 text-center'>
                <button
                  className='btn btn-primary'
                  onClick={() =>
                    deleteFile()
                  }
                >
                  Delete
                </button>
              </div>
            </>
          ) : <></>}
          <div className='col-1 text-center'>
            <FileUpload
              onChangeHandler={onUpload}
              data={{file_name: documentChecklist[0].imageUrl || '', fileId: '' }}
              fileType={ALLOWED_DOC_TYPES}
              size={SIZE}
              accept='.pdf,image/*'
            />
          </div>
        </div>
        <div className="row mt30">
          <div className="col-md-12">
            <label className='font-weight-bold'>{documentChecklist[1].title}</label>
          </div>
        </div>
        <div className="row">
          {documentChecklist[1].subParameters.map((value, index)=>{
            return (
              <div className='col-3 lh10 d-flex align-items-center'>
                <CheckBox
                  name={`dc-step-2-${value.id}`}
                  onClickCallback={(e) => onChangeItem(e,value.id,documentChecklist[1].id, index)}
                  checkedStatus={checkedState[index]}
                  disabled={lastCheckedStates[index]}
                />
                <label className='col-form-label'>{value.title}</label>
              </div>
            )
          })}
        </div>
        <div className="row mt30">
          <div className="col-md-3">
            <label className='col-form-label'>{documentChecklist[2].title} </label>
          </div>
          <div className="col-md-3">
            <SelectDropdown
                key={`dc-step-1-${documentChecklist[2].id}`}
                name={`dc-step-1-${documentChecklist[2].id}`}
                optionsList={documentChecklist[2].subParameters.map((item)=>{
                  return {
                    label: item.title, value: item.id
                  }
                })}
                onChange={(e) => updatedDataModel(e.target.value, documentChecklist[2].id, 2)}
                value={getSelectedItemId(2, documentChecklist[2].id)}
                isSelectRequired={true}
              />
          </div>
        </div>
        {isCourierSelected && <div className="row mt30">
          <div className="col-md-3">
            <label className='col-form-label'>{'Courier'} </label>
          </div>
          <div className="col-md-3">
            <SelectDropdown
                key={`dc-step-2-courier-${documentChecklist[2].id}`}
                name={`dc-step-2-courier-${documentChecklist[2].id}`}
                optionsList={courierList}
                onChange={(e) => onSelectCourierPartner(getSelectedItemId(2, documentChecklist[2].id), e.target.value)}
                value={courierPartner}
                isSelectRequired={true}
              />
          </div>
        </div>}
        {isCourierSelected && <div className="row mt30">
          <div className="col-md-3">
            <label className='col-form-label'>{'Tracking Id'} </label>
          </div>
          <div className="col-md-3">
            <InputText 
              id={`dc-step-2-courier_${getSelectedItemId(2, documentChecklist[2].id)}`}
              name='TrackingId'
              value={trackingId}
              onChange={(e) => onChangeTrackingId(getSelectedItemId(2, documentChecklist[2].id), e.target.value)}
            />
          </div>
        </div>}
        <div className="row mt30">
          <div className="col-md-3">
            <label className='font-weight-bold'>{documentChecklist[3].title}</label>
          </div>
        </div>
        <div className={styles.remarks}>
          <textarea 
            name={`dc-step-2-${documentChecklist[3].id}`}
            onChange={e=> onTextChange(e.target.value, documentChecklist[3].subParameters[0].id, documentChecklist[3].id )}
            value={getRemarksValue(3, documentChecklist[3].id)}
          />
        </div>
        <div className='row'>
            <div className='col-12'>
              <button className='btn btn-primary' onClick={()=> onSaveDocuments()} disabled={isSaved} >Save</button>
            </div>
        </div>
      </div>
      <DeChecklistLogs data={logs}/>
    </LoaderWithAccessControl>
  : null);
}
export default withRouter(DeChecklist);
