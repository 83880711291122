import React, { useState } from 'react';
import ServiceCharges from '../../components/service-charges';
// import { TABS } from './constant';

export const TABS = {
  dealInfo: {
    label: 'Token Services'
  },
  rcCaseType: {
    label: 'RC holdback'
  }
};

const TokenServices = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleOnClick = (index) => {
    setSelectedIndex(index);
  };

  const tabs = () => {
    return (
      <ul className='nav nav-tabs'>
        {Object.keys(TABS).map((tabKey, index) => {
          return (
            <li
              key={index}
              className={selectedIndex === index ? 'active' : ''}
              onClick={() => handleOnClick(index)}
            >
              <a>
                <b>{TABS[tabKey]['label']}</b>
              </a>
            </li>
          );
        })}
      </ul>
    );
  };
  return (
    <React.Fragment>
      <div className='col-md-12'>
        <div className='nav-tabs-custom no-box-shadow'>
          {tabs()}
          <div className='tab-content'>
            {selectedIndex === 0 && (
              <div
                className={selectedIndex === 0 ? 'tab-pane active' : 'tab-pane'}
              >
                <ServiceCharges />
              </div>
            )}
            {selectedIndex === 1 && (
              <div
                className={selectedIndex === 1 ? 'tab-pane active' : 'tab-pane'}
              >
                Coming soon ..
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TokenServices;
