import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  FETCH_DOCUMENTS_LIST_SUCCESS
`,
  {
    prefix: 'documentRuleEngine/'
  }
);
