import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { usePostDeliverySales } from '../../custom-hooks/post-delivery-sales-hook';
import { columns } from './columns';
import { openUrlTab } from '../../../shared/utils/helper';
import { Grid, GridColumn } from '@progress/kendo-react-grid';

const PostDeliverySales = (props) => {
  const { data, fetchPostDeliveryData } = usePostDeliverySales();
  const [dealId] = useState(props.match.params.id);

  const handleView = (rowData) => {
    openUrlTab(rowData.invoiceUrl);
  };

  useEffect(() => {
    fetchPostDeliveryData(dealId)
  }, [dealId]);

  const columnDefs = useMemo(() => columns({
    handleView,
  }), [
    handleView,
  ]);

  const rowData = data;

  return (
    <div>
      <Grid
        data={rowData}
        pageable={false}
      >
        {columnDefs.map((column) => {
          return (
            <GridColumn {...column} />
          )
        })}
      </Grid>
    </div>
  );
};

export default withRouter(PostDeliverySales);
