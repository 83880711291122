import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  addConfig: {},
  documentsRrConfigs: {
    configsUpdated: [],
    byId: {},
    allIds: []
  }
};

export const updateAddRejectionReasonsConfigData = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    addConfig: {
      ...state.addConfig,
      [data.key]: data.value
    }
  };
};

const fetchDocumentRejectionReasonsConfigSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    documentsRrConfigs: {
      ...state.documentsRrConfigs,
      byId: data.byId,
      allIds: data.allIds
    }
  };
};

const updateDocumentRejectionReasonsData = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    documentsRrConfigs: {
      ...state.documentsRrConfigs,
      byId: {
        ...state.documentsRrConfigs.byId,
        [data.id]: {
          ...state.documentsRrConfigs.byId[data.id],
          [data.key]: data.value
        }
      },
      configsUpdated: [
        ...state.documentsRrConfigs.configsUpdated,
        ...(state.documentsRrConfigs.configsUpdated.indexOf(data.id) === -1 ? [data.id]: [])
      ]
    }
  };
};

const resetRejectionReasonsConfigList = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    documentsRrConfigs: {
      configsUpdated: [],
      byId: {},
      allIds: []
    }
  };
};

export const HANDLERS = {
  [Types.UPDATE_ADD_REJECTION_REASONS_CONFIG_DATA]: updateAddRejectionReasonsConfigData,
  [Types.FETCH_DOCUMENT_REJECTION_REASONS_CONFIG_SUCCESS]: fetchDocumentRejectionReasonsConfigSuccess,
  [Types.UPDATE_DOCUMENT_REJECTION_REASONS]: updateDocumentRejectionReasonsData,
  [Types.RESET_REJECTION_REASONS_CONFIG_LIST]: resetRejectionReasonsConfigList
};

export default createReducer(INITIAL_STATE, HANDLERS);
