import React, { useState, useEffect } from 'react';
import Accordion from '../../../shared/accordion';
import ArrowIcon from '../../../shared/arrow';
import TabularLogs from '../tabular-logs';
import { TABLE_HEADERS } from './constants';
import PublishRemarksPopup from '../publish-remarks-popup';
import { PAGE_SIZE } from '../../../shared/utils/constants';

const PricingLogs = ({
  title = 'Pricing Logs',
  appointmentId,
  pricingLogs,
  fetchPricingLogsConnected,
  ...restProps
}) => {
  useEffect(() => {
    fetchPricingLogsConnected(appointmentId);
  }, []);
  const {pricing_logs} = !!pricingLogs ? pricingLogs.data : [];
  return (
    <Accordion>
      {({ onClick, currentVisibleStates }) => {
        return (
          <Accordion.Content>
            <Accordion.Heading>
              <h2
                className='d-flex justify-content-between fwb sellerDocuments p0 m0'
                onClick={() => onClick({ index: 0 })}
              >
                {title}
                <ArrowIcon
                  grey={'GreyArrow'}
                  rotateBy={currentVisibleStates[0] ? 180 : 90}
                />
              </h2>
            </Accordion.Heading>
            <Accordion.Body>
              <div
                className={
                  currentVisibleStates[0]
                    ? 'accordionShow mt20 top-border'
                    : 'accordionHide'
                }
              >
                <TabularLogs
                  logKey='REMARKS'
                  headers={TABLE_HEADERS}
                  logs={!!pricing_logs?pricing_logs:[]}
                />
              </div>
            </Accordion.Body>
          </Accordion.Content>
        );
      }}
    </Accordion>
  );
};
export default PricingLogs;
