import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import RcTracker from './component';

const mapStateToProps = (state) => {
  const {
    dealInfo: { dealInfo = {} },
    loaderInfo
  } = state;
  return {
    dealInfo,
    loaderInfo
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RcTracker));
