import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RemarksRcCaseType from './component';
import { fetchRcDocumentRemarksConnected, fetchOthersRcDocumentRemarksConnected } from './actions';

const mapStateToProps = (state) => {
  const {
    rcDocumentRemarksInfo: { remarks, remarksOps }
  } = state;
  return {
    remarks,
    remarksOps
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchRcDocumentRemarksConnected,
      fetchOthersRcDocumentRemarksConnected
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RemarksRcCaseType);
