import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RcTrackerDetail from './component';
import { RcManagementAction } from '../../actions';

const mapStateToProps = ({ dealInfo }) => {
  const { dealInfo: dealInfoData } = dealInfo;
  return { dealInfoData };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchRcDocumentDetail: RcManagementAction.fetchRcDocumentDetail,
      addRc: (data) => RcManagementAction.addRc(data),
      editRc: (data) => RcManagementAction.editRc(data)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RcTrackerDetail);
