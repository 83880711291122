import React, { useState } from 'react';
//import styles from '../input-mobile/styles.css';
//import useToggle from "../../../hooks/use-toggle";
import './styles.css';
import PropTypes from 'prop-types';
import './styles.css';
const MAX_LENGTH = 255;

const InputText = ({
  value = '',
  id = '',
  text = '',
  onChange = () => {},
  isAutoFocused = false,
  onBlurCallback = () => {},
  maxLength = MAX_LENGTH,
  validator = () => {},
  errorMessage = '',
  inputRef,
  placeholder = null,
  onFocusCallback = () => {},
  readOnly,
  disabled,
  required,
  type,
  max,
  min,
  name = 'base-input',
  isError = false,
  version = null,
  ...restProps
}) => {
  const [isTouched, setIsTouched] = useState(false);
  //const [isFocused, setIsFocused] = useToggle(isAutoFocused);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(errorMessage);

  const onChangeHandler = (e) => {
    const { value, type } = e.target;
    if (validator(value, type)) {
      setHasError(false);
      setError('');
    } else {
      setHasError(true);
      setError(errorMessage);
    }

    if (value && value.length > maxLength && type === 'number') {
      return false;
    }

    onChange(e);
  };

  const onFocus = (e) => {
    // setIsFocused();
    setIsTouched(true);
    onFocusCallback(e);
  };

  const onBlur = (e) => {
    //setIsFocused();
    onBlurCallback(e);
  };

  return (
    <div>
      <input
        ref={inputRef}
        name={name}
        id={id}
        type={type}
        max={max}
        min={min}
        className={
          version === 'v2' && isError
            ? 'form-control input-error'
            : 'form-control'
        }
        value={value}
        maxLength={maxLength}
        onChange={onChangeHandler}
        onFocus={(e) => onFocus(e)}
        onBlur={(e) => onBlur(e)}
        autoComplete='off'
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
        required={required}
        {...restProps}
      />
      {text && <label stylename={`${!!value ? 'active' : ''}`}>{text}</label>}
      {version !== 'v2' && isTouched && hasError && (
        <small className={"error"}>{error}</small>
      )}
      {version === 'v2' && isError && (
        <small className={"error"}>{errorMessage}</small>
      )}
    </div>
  );
};

InputText.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string,
  text: PropTypes.string,
  onChange: PropTypes.func,
  onBlurCallback: PropTypes.func,
  maxLength: PropTypes.number,
  validator: PropTypes.func,
  errorMessage: PropTypes.string,
  inputRef: PropTypes.object,
  onFocusCallback: PropTypes.func,
  placeholder: PropTypes.string,
  isAutoFocused: PropTypes.bool
};
export default InputText;
