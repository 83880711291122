import Types from './types';
import { financeService } from '../../../services';

import { showToastMessages } from '../../../shared/utils/helper';
import locale from '../../../shared/utils/locale';

const uploadUtrConnected = (data) => (dispatch) => {
  dispatch(uploadUtrConnectedInit());
  return new Promise((resolve, reject) => {
    financeService
      .uploadUtrDocument(data)
      .then((resp) => {
        showToastMessages(resp.data.message);
        dispatch(uploadUtrConnectedSuccess(resp.data));
        resolve(resp);
      })
      .catch((error) => {
        dispatch(uploadUtrConnectedFail(error));
        reject(error);
      });
  });
};

const uploadUtrConnectedInit = () => ({
  type: Types.UPLOAD_UTR_INIT
});

const uploadUtrConnectedSuccess = (data) => ({
  type: Types.UPLOAD_UTR_SUCCESS,
  data
});

const uploadUtrConnectedFail = (error) => ({
  type: Types.UPLOAD_UTR_FAIL,
  error
});

export { uploadUtrConnected };
