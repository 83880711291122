import { createTypes } from "reduxsauce";

export default createTypes(
    `
    ACTION_TOGGLE_SIDEBAR
    ACTION_TOGGLE_PROFILE
    userLoginSuccess
    ACTION_SET_USER
    ACTION_LOGOUT
    ACTION_UPDATE_LOGGEDIN_STATUS
    ACTION_UPDATE_LOGGEDIN_USER
    ACTION_LOGOUT
    ACTION_SET_USER
`,
    {
        prefix: "adminheader/"
    }
);
