/* eslint-disable no-magic-numbers */
import React from "react";
import { Slider, Handles } from 'react-compound-slider';
import styles from  './styles.module.css';

export function Handle({
    handle: { id, value, percent },
    getHandleProps
    }) {
    return (
        <>
            <div className={styles.sliderWrapper}
            style={{
                left: `${percent}%`,
            }}
            {...getHandleProps(id)}
            > 
            </div>
            <div className={styles.sliderValue}>Rs. {value}</div>
        </>
    )
}

const sliderStyle = {  // Give the slider some width
    position: 'relative',
    width: '100%',
    height: 40,
    marginLeft: 15,
    marginRight: 20,
}
  
const railStyle = {
    position: 'absolute',
    width: '100%',
    height: 2,
    marginTop: 20,
    borderRadius: 5,
    backgroundColor: 'rgb(248, 150, 51)',
}

const EmiSlider = ({
    priceValue,
    priceStep = 1,
    domain,
    onSliderValueChanged,
    disabled=false
}) => {
    return (
        <React.Fragment>
            <span className={styles.min}>Min</span>
                <Slider
                    rootStyle={sliderStyle}
                    domain={domain}
                    step={priceStep}
                    mode={2}
                    values={[priceValue]}
                    onChange={onSliderValueChanged}	
                    disabled={disabled}
                >
                    <div style={railStyle} />
                    <Handles>
                        {({ handles, getHandleProps }) => (
                            <div className="slider-handles">
                            {handles.map(handle => (
                                <Handle
                                key={handle.id}
                                handle={handle}
                                getHandleProps={getHandleProps}
                                />
                            ))}
                            </div>
                        )}
                    </Handles>
                </Slider>
            <span className={styles.max}>Max</span>
        </React.Fragment>
    );
};

export default EmiSlider;
