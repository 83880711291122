import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PostDeliverySales from './component';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostDeliverySales);
