import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    selectedItemIndex: 0
};

export const updateItemIndex = (state = INITIAL_STATE, { selectedItemIndex }) => {
  return { ...state, selectedItemIndex };
};

export const HANDLERS = {
  [Types.ACTION_UPDATE_INDEX]: updateItemIndex
};

export default createReducer(INITIAL_STATE, HANDLERS);
