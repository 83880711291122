import React, { useState, useEffect } from 'react';
import {
  InboundBuyerDetails,
  CarDetails
} from '../../components/Buyer-Executive';
import {
  InboundBuyerDisposition as DispositionDetails,
  InteractionJourney
} from '../../components/shared';
import { Label } from 'reactstrap';
import { DataService } from '../../service';
import NoResult from '../../images/no-result.png';
import qs from 'qs';
import { Redirect } from 'react-router-dom';
import Loader from '../../images/preloader.gif';
import { StateActions, CityActions } from '../../../admin-app/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IS_ENV_DEV } from '../../../shared/utils/constants';
import { RESTRICTED_PHONE } from '../../components/shared/Constants';

const CCOutboundBuyerMissedCall = (props) => {
  if (!window.location.search) {
    return <Redirect to='/error' />;
  }
  const dialerData = qs.parse(window.location.search.substring(1));
  // if(dialerData.phone1) { dialerData.phone = dialerData.phone1; }

  let customerInfo = {};

  try {
    customerInfo = JSON.parse(dialerData.customerInfo);
  } catch (e) {
    customerInfo = {};
  }

  const getParams = {
    sessionId: dialerData.sessionId,
    campaignId: dialerData.campaignId,
    crtObjectId: dialerData.crtObjectId,
    userCrtObjectId: dialerData.userCrtObjectId,
    userId: dialerData.userId,
    customerId: dialerData.customerId,
    phone: dialerData.phone,
    agentName: dialerData.agentName,
    dispositionCode: dialerData.dispositionCode,
    dispositionAttr: dialerData.dispositionAttr
  };

  const [showLoader, setShowLoader] = useState(true);
  const [showError] = !getParams.phone ? useState(true) : useState(false);

  const [buyerData, setBuyerData] = useState();
  const [car, setCar] = useState();
  const [carUrl, setCarUrl] = useState();
  const [refreshCar, setRefreshCar] = useState(false);
  const [loadCarData, setLoadCarData] = useState(false);
  const [appointmentId, setAppointmentId] = useState();
  const [dispositionData, setDispositionData] = useState();
  const [selectedCcDispositionId, setSelectedCcDispositionId] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [buyer, setBuyer] = useState();
  const [isValidBuyer, setIsValidBuyer] = useState(false);
  const [disposition, setDisposition] = useState();
  const [defaultDisposition, setDefaultDisposition] = useState();

  const [isValidDisposition, setIsValidDisposition] = useState(false);

  const [postData, setPostData] = useState(false);
  const [submitPostData, setSubmitPostData] = useState(false);
  const [redirectToThankYou, setRedirectToThankYou] = useState(false);
  const [submitErrors, setSubmitErrors] = useState();
  const [showSubmitErrors, setShowSubmitErrors] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [sendMessageError, setSendMessageError] = useState();

  const [buyerType, setBuyerType] = useState('');

  useEffect(() => {
    // Fetch Buyer Info
    const buyerPhoneMatch =
      dialerData &&
      dialerData.phone &&
      dialerData.phone.substring(dialerData.phone.length - 10);
    const isRestrictedPhone = RESTRICTED_PHONE.includes(buyerPhoneMatch);
    getParams.phone &&
      DataService.fetchInboundBuyerData(dialerData.phone)
        .then((resp) => {
          setShowLoader(false);
          setBuyerData({
            ...resp,
            phone: isRestrictedPhone ? '' : dialerData.phone,
            phoneOriginal: dialerData.phone
          });
          resp.phone ? setBuyerType('EXISTING') : setBuyerType('NEW');
        })
        .catch((error) => {
          setShowLoader(false);
        });

    // Fetch Dispositions
    DataService.fetchOutboundMissedCallBuyerDisposition()
      .then((resp) => {
        setDispositionData(resp);
      })
      .catch((error) => {
        setDispositionData(null);
      });
  }, []);

  const sendMessage = () => {
    IS_ENV_DEV && alert('sendMessage disabled');
    return false;
    const { name, phone } = buyerData;
    const { inventoryShortUrl, parkingYardLocation, city } = car;

    DataService.sendMessage({
      appointmentId,
      name,
      phone,
      city,
      inventoryShortUrl,
      parkingYardLocation
    })
      .then(() => {
        setSendMessageError(false);
        setIsMessageSent(true);
      })
      .catch((err) => {
        setIsMessageSent(false);
        setSendMessageError(err.message);
      });
  };

  useEffect(() => {
    if (refreshCar) {
      const carUrlArray = carUrl && carUrl.trim() ? carUrl.split('-') : [];
      if (carUrlArray && carUrlArray.length) {
        const apntmntId = carUrlArray[carUrlArray.length - 1].replace('/', '');
        if (apntmntId !== appointmentId) {
          setLoadCarData(true);
          DataService.fetchCarData(apntmntId).then((resp) => {
            if (resp) {
              setCar(resp);
            } else {
              setCar();
            }
            // setBuyerData({
            //   ...buyerData
            // });
            setAppointmentId(apntmntId);
            setDefaultDisposition('Pre-Lead');
            setLoadCarData(false);
          });
        }
      } else {
        setAppointmentId(0);
        setCar();
        setDefaultDisposition();
      }
      setRefreshCar(false);
    }
  }, [refreshCar]);

  useEffect(() => {
    if (submitPostData) {
      setSubmitErrors();
      setShowSubmitErrors(false);
      // if (isValidDisposition && isValidBuyer && car) {
      if (isValidDisposition && isValidBuyer) {
        let postParams = {
          additionalFields: disposition.addtionalFields,
          appId: appointmentId > 0 ? appointmentId : null,
          dispositionId: disposition.dispositionId,
          name: buyer.name,
          phone: buyer.phone,
          city: buyer.city,
          state: buyer.state,
          email: buyer.emailId,
          customerInfo,
          customerId: dialerData.customerId,
          diallerDetails: {
            campaignId: dialerData.campaignId,
            crtObjectId: dialerData.crtObjectId,
            dispositionCode: null,
            sessionId: dialerData.sessionId,
            userCrtObjectId: dialerData.userCrtObjectId,
            userId: dialerData.userId
          },
          campaignType: "MISSED_CALL_BUYER"
        };

        setIsSubmit(true);
        DataService.submitInboundBuyerData(postParams).then((response) => {
          if (response.errors && response.errors.length > 0) {
            setSubmitErrors(response.errors);
            setShowSubmitErrors(true);
            setPostData(false);
            setSubmitPostData(false);
            setIsSubmit(false);
          } else {
            reset();
            setRedirectToThankYou(true);
            setSubmitErrors();
            setShowSubmitErrors(false);
          }
        });
      } else {
        setPostData(false);
        setSubmitPostData(false);
      }
    }
  }, [submitPostData]);

  const reset = () => {
    setDisposition();
    setPostData(false);
    setSubmitPostData(false);
    setIsValidDisposition(false);
    setIsValidBuyer(false);
    setIsSubmit(false);
  };

  if (redirectToThankYou) {
    return <Redirect to='/thank-you' />;
  }

  if (showLoader) {
    return (
      <div className='container text-center loader'>
        <img src={Loader} alt='Loading' className='center-block' />
      </div>
    );
  }

  if (showError) {
    return (
      <div className='no-results container-fluid'>
        <img src={NoResult} alt='no record' />
      </div>
    );
  }

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='details-head'>
            <div className='col-12 d-flex'>
              <div className='head_left'>
                <Label for='Buyer Type' className='labl'>
                  Buyer Type :
                </Label>
                <span className='span'>{buyerType}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-3'>
            {buyerData && (
              <InboundBuyerDetails
                buyerData={buyerData}
                car={car}
                carUrl={carUrl}
                setCarUrl={setCarUrl}
                postData={postData}
                setBuyer={setBuyer}
                setIsValidBuyer={setIsValidBuyer}
                setSubmitPostData={setSubmitPostData}
                setRefreshCar={setRefreshCar}
                selectedCcDispositionId={selectedCcDispositionId}
                onSendMessage={sendMessage}
                isMessageSent={isMessageSent}
                sendMessageError={sendMessageError}
                setIsMessageSent={setIsMessageSent}
                setSendMessageError={setSendMessageError}
                // onChangeSelect={onChangeSelect}
              />
            )}
          </div>
          <div className='col-5'>
            {buyerData && <CarDetails car={car} loadCarData={loadCarData} />}
          </div>
          <div className='col-4'>
            {dispositionData && (
              <DispositionDetails
                dispositionData={dispositionData}
                setDisposition={setDisposition}
                defaultDisposition={defaultDisposition}
                isSubmit={isSubmit}
                setIsValidDisposition={setIsValidDisposition}
                setPostData={setPostData}
                submitErrors={submitErrors}
                showSubmitErrors={showSubmitErrors}
                setShowSubmitErrors={setShowSubmitErrors}
                car={car}
                buyerDiscountedPrice={car ? car.discountedPrice : 0}
                selectedCcDispositionId={selectedCcDispositionId}
                setSelectedCcDispositionId={setSelectedCcDispositionId}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ dealerInformation: { detail } }) => {
  return {
    detail
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchStates: StateActions.fetchStates,
      fetchCities: CityActions.fetchCities
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CCOutboundBuyerMissedCall);
