import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  withAdminLayout,
  withAuthorization
} from '../../../../shared/utils/firebase';
import { addOthersRcDetails } from './actions';
import RcOthersSegmentForm from './component';

const mapStateToProps = (state) => {
 return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addOthersRcDetailsConnect: addOthersRcDetails
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withAdminLayout(withAuthorization(() => true)(withRouter(RcOthersSegmentForm))));
