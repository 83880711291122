import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import useModal from 'react-hooks-use-modal';
import { useFormik } from 'formik';
import InputText from '../../../shared/components/input-text';
import { FIELDS, getInitValues, SWITCH_PROOF } from './constants';
import { SelectComponent } from '../../../client/components/shared';
import { Wrapper } from '../form-element';
import CustomButton from '../../../shared/components/button';
import { validationSchema } from './validations';

const CFinanceSwitchProofModal = ({
  paymentModes,
  userType,
  dealId,
  switchProofInfoConnected,
  fetchFinanceDealInfoByIdConnected,
  fetchTransactionLogsConnected
}) => {
  const [Modal, open, close] = useModal();
  const [options, setOptions] = useState({});
  const [oldDealIdData, setOldDealIdData] = useState({});
  const [formError, setFormError] = useState(null);

  const handleSubmit = (values) => {
    const oldDealId = values.old_deal_id.split('#')[1];
    if (!oldDealId) {
      setFormError('Invalid Deal Identifier !');
      return false;
    }
    const payload = {
      old_deal_id: oldDealId,
      proof_switch: values.proof_switch.value,
      amount: values.amount,
      deal_id: dealId,
      current_remitter: userType,
      utr: values.utr,
      id: null
    };
    switchProofInfoConnected(payload)
      .then(() => {
        close();
        fetchTransactionLogsConnected(userType, dealId, false);
        formik.resetForm();
      })
      .catch((error) => {
        setFormError(error.message || '');
      });
  };

  const formik = useFormik({
    initialValues: getInitValues(),
    validationSchema: validationSchema,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    setOptions({
      ...options,

      [FIELDS.proof_switch.keyName]: Object.values(SWITCH_PROOF)
    });
  }, [paymentModes]);
  const onChangeSelect = (selected, param) => {
    if (selected) {
      formik.setFieldValue(param, selected, true);
    } else {
      formik.setFieldValue(param, {}, true);
    }
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    formik.setFieldValue(name, value, true);
  };
  const onBlurCallback = (e, keyName) => {
    e.preventDefault();
    const { name } = e.target;
    if (keyName === 'old_deal_id') {
      const oldDealId = formik.values.old_deal_id.split('#')[1];
      if (!oldDealId || oldDealId === dealId) {
        setFormError('Invalid old deal Identifier !!');
        return false;
      } else {
        setFormError();
        fetchFinanceDealInfoByIdConnected(oldDealId, false)
          .then((res) => {
            setOldDealIdData(res.data.data);
          })
          .catch((error) => {
            setFormError(error.message || '');
          });
      }
    }
    formik.setFieldTouched(name, true, true);
  };

  return (
    <>
      <button onClick={open} className='btn btn-primary'>
        Proof Switch
      </button>

      <Modal>
        <div
          className={`Filter_popUP ${style.proofSwitch} col-md-12`}
          style={{ width: '35vw' }}
        >
          <h4> Proof Switch</h4>
          <div className='d-flex'>
            <form onSubmit={formik.handleSubmit}>
              {Object.values(FIELDS).map((fieldItem) => {
                const {
                  subType,
                  label,
                  keyName,
                  required,
                  elementType,
                  isMulti,
                  maxLength,
                  ...restProps
                } = fieldItem;

                switch (elementType) {
                  case 'input':
                    return (
                      <Wrapper
                        key={keyName}
                        item={fieldItem}
                        containerClass='row justify-content-between mb20'
                      >
                        <InputText
                          id={keyName}
                          name={keyName}
                          type={subType}
                          onChange={onChangeInput}
                          value={formik.values[keyName] || ''}
                          onBlurCallback={(e, name) =>
                            onBlurCallback(e, keyName)
                          }
                          isError={
                            formik.touched[keyName] && formik.errors[keyName]
                              ? true
                              : false
                          }
                          errorMessage={
                            formik.touched[keyName] && formik.errors[keyName]
                              ? formik.errors[keyName]
                              : null
                          }
                          version='v2'
                          {...restProps}
                        />
                      </Wrapper>
                    );

                  case 'select':
                    return (
                      <Wrapper
                        key={keyName}
                        item={fieldItem}
                        containerClass='row justify-content-between mb20'
                      >
                        <SelectComponent
                          key={formik.values[keyName] || keyName}
                          id={keyName}
                          name={keyName}
                          optionsList={options[keyName]}
                          placeholder={label}
                          defaultValue={formik.values[keyName] || null}
                          value={formik.values[keyName] || null}
                          onChange={onChangeSelect}
                          className='form-control'
                          isMulti={isMulti || false}
                          version='v2'
                          errorMessage={
                            formik.touched[keyName] && formik.errors[keyName]
                              ? formik.errors[keyName]
                              : null
                          }
                        />
                      </Wrapper>
                    );

                  default:
                    return null;
                }
              })}
              {formError && <div className={style.proofError}>{formError}</div>}
              <CustomButton type='submit' label='Submit' />
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CFinanceSwitchProofModal;
