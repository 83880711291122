import React from 'react';
const CheckBox = ({
  checkedStatus,
  onClickCallback = () => {},
  id = 'checkbox',
  name = 'checkbox',
  value = false,
  label = '',
  className = ''
}) => {
  const onClickHandler = (e) => {
    onClickCallback(e, 'checkbox');
  };

  return (
    <div stylename={'styles.checkboxHolder'}>
      <input
        onChange={onClickHandler}
        type='checkbox'
        id={id}
        name={name}
        value={value}
        checked={checkedStatus || ''}
        className={className}
      />
      {label && <label htmlFor={id}> {label}</label>}
    </div>
  );
};

export default CheckBox;
