import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DocketManagement } from "./component";
import { 
  fetchDocketManagementFormData,
  fetchDocketManagementDocs,
  updateDocketManagementData,
  uploadDocketManagementDiyDoc,
  updateDocketManagementFieldData,
  updateDocketManagementDiyDocData,
  updateDocketManagementAdminDocData,
  getDocketManagementDiyDoc,
} from "./actions";

const mapStateToProps = ({
  docketManagement: {
    docketData,
    docketData: {
      diyDocumentList,
      adminDocumentList,
    }
  }
}) => ({
  docketData,
  diyDocumentList,
  adminDocumentList,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchDocketManagementFormDataConnect: fetchDocketManagementFormData,
  fetchDocketManagementDocsConnect: fetchDocketManagementDocs,
  updateDocketManagementDataConnect: updateDocketManagementData,
  uploadDocketManagementDiyDocConnect: uploadDocketManagementDiyDoc,
  updateDocketManagementFieldDataConnect: updateDocketManagementFieldData,
  updateDocketManagementDiyDocDataConnect: updateDocketManagementDiyDocData,
  updateDocketManagementAdminDocDataConnect: updateDocketManagementAdminDocData,
  getDocketManagementDiyDocConnect: getDocketManagementDiyDoc,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocketManagement);
