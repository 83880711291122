import React, { Component, Fragment, useEffect } from 'react';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';
import { withRouter } from 'react-router-dom';
import * as DealerActions from '../order-dealer-tab/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
const DealerDetails = ({ fetchDealerDetailConnected, match, dealerData }) => {
  useEffect(() => {
    fetchDealerDetailConnected(match.params.id);
  }, []);
  return (
    <LoaderWithAccessControl>
      <div className='row'>
        <div className='col-md-12'>
          <h4>Dealer Details</h4>
        </div>
        <div className='col-md-12'>
          <p>
            <b>Name:</b>
            {dealerData.data ? dealerData.data.name : 'N/A'}
          </p>
          <p>
            <b>Mobile Number:</b>
            {dealerData.data ? dealerData.data.mobileNumber : 'N/A'}
          </p>
          <p>
            <b>Email:</b>
            {dealerData.data ? dealerData.data.email : 'N/A'}
          </p>
          <p>
            <b>Dealer Type:</b>
            {dealerData.data ? dealerData.data.dealerType : 'N/A'}
          </p>
          <p>
            <b>Address Line 1:</b>
            {dealerData.data ? dealerData.data.addressLine1 : 'N/A'}
          </p>
          <p>
            <b>Address Line 2:</b>
            {dealerData.data ? dealerData.data.addressLine2 : 'N/A'}
          </p>
        </div>
      </div>
    </LoaderWithAccessControl>
  );
};
DealerDetails.propTypes = {};
const mapStateToProps = ({
  order: {
    order: { detail }
  },
  dealerInfo: { dealerData }
}) => {
  return {
    detail,
    dealerData
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDealerDetailConnected: DealerActions.fetchDealerDetailConnected
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DealerDetails));
