const options = [
  { label: 'Yes', value: 'YES' },
  { label: 'No', value: 'NO' },
  { label: `Don't Know`, value: 'DONT_KNOW' }
];

const questions = [
  {
    apiKey: 'sellCarsToBuyerDirectly',
    qtext:
      'Do you know that Cars24 helps sell your car directly to end buyers?',
    altQuestion: ''
  },
  {
    apiKey: 'listingCarProcessExplanation',
    qtext:
      'Were you explained the process of listing the car on Cars24 website and then meeting direct buyers through Cars24?',
    altQuestion: ''
  },
  {
    apiKey: 'listingPriceRevision',
    qtext:
      'Your car is currently listed at  ₹<listing price>. Do you want to revise it?',
    altQuestion:
      'Your car is currently not listed on our website. Do you want to list it?'
  }
];

const CONSTANTS = { options, questions };

export default CONSTANTS;
