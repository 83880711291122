import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    UPDATE_ORDER_INIT
    UPDATE_ORDER_SUCCESS
    UPDATE_ORDER_FAIL
`,
  {
    prefix: 'order-update-tab/'
  }
);
