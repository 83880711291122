import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { CheckBox } from '../../../client/components/shared';
import { rcManagementService } from '../../../services';
import validate from '../../utils/validation/validation';

const Agent = (props) => {
  const [validation, setValidation] = useState({});
  const [id] = useState(props.match.params.id);

  const [agentFormData, setAgentFormData] = useState({
    id: null,
    name: '',
    code: '',
    phone_number: '',
    isActive: false
  });

  useEffect(() => {
    if (id) {
      rcManagementService.fetchAgentInfoById(id).then((response) => {
        setAgentFormData(response.data.data);
      });
    } else {
    }
  }, []);

  const { name, code, phone_number, isActive } = agentFormData;

  const onChange = (e) => {
    let { name, value } = e.target;
    if (name === 'phone_number') {
      value = value ? value.replace(/[^0-9]/g, '') : '';
    }
    setAgentFormData({ ...agentFormData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // const validationObj = validate({
    //   name,
    //   phone_number
    // });
    // setValidation(validationObj);
    // const isValid = Object.values(validationObj).filter((x) => !x.valid);
    if (id) {
      rcManagementService.editAgent(agentFormData).then(() => {
        const route = `/agents`;
        props.history.push(route);
      });
    } else {
      rcManagementService.addAgent(agentFormData).then(() => {
        const route = `/agents`;
        props.history.push(route);
      });
    }
  };
  const onChangeChechbox = (e) => {
    setAgentFormData({ ...agentFormData, [e.target.name]: e.target.checked });
  };
  return (
    <>
      <form name='store' method='POST' onSubmit={(e) => handleSubmit(e)}>
        <div className='form-group row'>
          <div className='form-group col-md-3'>
            <input type='hidden' name='id' />
            <label>Name</label>
            <input
              className='form-control'
              type='text'
              name='name'
              value={name}
              onChange={(e) => onChange(e)}
            />
            <span>
              {validation && validation.name && validation.name.message}
            </span>
          </div>

          <div className='form-group col-md-3'>
            <input type='hidden' name='id' />
            <label>Code</label>
            <input
              className='form-control'
              type='text'
              name='code'
              value={code}
              onChange={(e) => onChange(e)}
            />
            <span>
              {validation && validation.code && validation.code.message}
            </span>
          </div>

          <div className='form-group col-md-3'>
            <input type='hidden' name='phone_number' />
            <label>Phone Number</label>
            <input
              className='form-control'
              type='text'
              name='phone_number'
              value={phone_number}
              maxLength={10}
              onChange={(e) => onChange(e)}
            />
            <span>
              {validation &&
                validation.phone_number &&
                validation.phone_number.message}
            </span>
          </div>

          <div className='form-group col-md-3'>
            <label>Active</label>
            <CheckBox
              name='isActive'
              onClickCallback={(e) => onChangeChechbox(e)}
              checkedStatus={isActive}
            />
          </div>
        </div>

        <div className='form-group col-md-8'>
          <div className='rows'>&nbsp;</div>
          <div className='form-group col-md-5'>
            <button type='submit' className='btn btn-primary'>
              {id ? 'Update' : 'Save'}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default withRouter(Agent);
