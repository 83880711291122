import React from "react";

const CtaRenderer = ({
  dataItem: data,
  label,
  className = "btn btn-success btn-sm",
  handleCtaClick
}) => {
  return (
    <td>
      <button
        className={className}
        onClick={() => handleCtaClick(data)}
      >
        {label}
      </button>
    </td>
  )
}

export default CtaRenderer;