
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AddZone from './component';
import {  StateActions, CityActions ,ZoneActions} from "../../actions";
const mapStateToProps = ({ store: { detail } }) => {
    return {
      detail
    };
  };
  
  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
  
        addZone: data => ZoneActions.addZone(data),
        fetchStates: () => StateActions.fetchStates(),
        fetchCities: params => CityActions.fetchCities(params)
      },
      dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AddZone);
