
import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
const LeadInteractionHistory = ({leadInteractions
  }) => {
    const totalItem = !!leadInteractions ? leadInteractions.length : 0;
    const displayLinks = (link) => {
      const carUrlArray = link.trim() ? link.split('-') : [];
      if (carUrlArray.length > 0) {
        const apntmntId = carUrlArray[carUrlArray.length - 1].replace('/', '');
        const aptId = apntmntId.includes("?") ? apntmntId.substring(0, apntmntId.indexOf("?")) : apntmntId;
        return (
          <li>
            <a href={link} target="_blank">{aptId}</a>
          </li>
        );
      }
    };
    const displayItem = (item, index) => {
      return (
        <tr>
          <td>{totalItem-index}</td>
          <td>{item.interactionTime}</td>
          <td>{item.disposition}</td>
          <td>{item.subDisposition}</td>
          <td>{item.remark}</td>
          <td>{item.bookingCompletionLink}</td>
          <td><ul className={styles.appLink}>{!!item.sharedCarLinks && item.sharedCarLinks.map((link)=> displayLinks(link))}</ul></td>
        </tr>
      );
    };

    return (
        <div className={styles.leadInteractionHistory}>
          <h2 className={styles.title}>Lead Interaction History</h2>
          <div className={styles.leadInteractionTable}>
            <table cellPadding="0" cellSpacing="0" width="100%">
              <thead>
                <tr>
                  <th>Interaction order</th>
                  <th>Interaction Time</th>
                  <th>Disposition</th>
                  <th>Sub-Disposition</th>
                  <th>Agent Remark</th>
                  <th>Booking Completion Link sent for Booking ID</th>
                  <th>Car Link shared</th>
                </tr>
              </thead>
              <tbody>
                {!!leadInteractions && leadInteractions.length > 0 && leadInteractions.map((item, index) => displayItem(item, index))}
              </tbody>
            </table>
          </div>
        </div>
    );
};

export default LeadInteractionHistory;
