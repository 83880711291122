export const RC_OTHERS_DELAY_REASONS_KEY = {
  FILE_CLOSURE_DELAY: "File closure Delay",
  AGENT_HANDOVER_DELAY: "Agent Handover Delay",
  AADHAAR_UPDATE_DELAY: "Aadhaar Update Delay",
  E_RECEIPT_DELAY: "E-Receipt Delay",
  RTO_NOC_DELAY: "RTO NOC Delay",
  RC_TRANSFER_DELAY: "RC Transfer Delay",
  PHYSICAL_RC_RECEIVING_DELAY: "Physical RC receiving Delay",
  PHYSICAL_RC_HANDOVER_DELAY: "Physical RC handover Delay",
  OWNER_PARTY_PESHI_DELAY: "Owner Party Peshi Delay",
  BUYER_PARTY_PESHI_DELAY: "Buyer Party Peshi Delay",
  LEARNING_LICENCE_DELAY: "Learning Licence Delay",
  DRIVING_TEST_DELAY: "Driving Test Delay",
  APPLICANT_PHOTOGRAPH_DELAY: "Applicant Photograph Delay"
}

export const getDelayCategoryList = (delayReasons, delayReasonKey) => {
  const data = delayReasons.find((dr) => dr.value === delayReasonKey);
  return data && data.subDropDownList ? data.subDropDownList : [];
}

export const getDelaySubCategoryList = (delayReasons, delayReasonKey, categoryKey) => {
  const data = delayReasons.find((dr) => dr.value === delayReasonKey);
  if (data && data.subDropDownList) {
    const subData = data.subDropDownList.find((c) => c.value === categoryKey);
    return subData && subData.subDropDownList ? subData.subDropDownList : [];
  }
  return [];
}