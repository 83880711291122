import React from 'react';
import withLayout from '../../../shared/components/layout/component';

const styles = {
  padding: '50px 0',
  textAlign: 'center'
};
const Notfound = () => (
  <div style={styles}>
    <h1>404, Page Not found</h1>
  </div>
);
export default withLayout(Notfound);
