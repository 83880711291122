import React, { useState, useEffect, useContext } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import useModal from 'react-hooks-use-modal';

import { SelectComponent } from '../../../client/components/shared';
import { FILTER_SETS, FILTER_API_KEYS, PAYLOAD } from './constants';
import { rcManagementService } from '../../../services';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';
import validationSchema from './validations';
import { isObject } from '../../../shared/utils/helper';

const dataWrapperArrayOfObj = (data, labelKey, valueKey) => {
  if (data && Array.isArray(data)) {
    return data.map((item) => {
      if (!('label' in item)) {
        item['label'] = item[labelKey];
      }
      if (!('value' in item)) {
        item['value'] = item[valueKey];
      }
      return item;
    });
  } else {
    return [];
  }
};

const dataWrapperArray = (data) => {
  if (data && Array.isArray(data)) {
    return data.map((item) => {
      return { label: item, value: item };
    });
  } else {
    return [];
  }
};

const initValue = {
  reporting_manager_of_buddy: [],
  city_id: [],
  state_id: [],
  car_source: [],
  rc_verification_status: [],
  agent_id: [],
  delivery_date: '',
  delivery_date_operator: '',
  file_completion_date: '',
  file_completion_date_operator: '',
  token_date: '',
  token_date_operator: ''
};

const Filter = (props) => {
  const [Modal, open, close] = useModal();

  const [initialValues, setInitialValues] = useState(initValue);
  const [options, setOptions] = useState();
  const [defaultOptions, setDefaultOptions] = useState();

  const [filterType, setFilterType] = useState();
  const [filterId, setFilterId] = useState();
  const [gridFilters, setGridFilters] = useState(props.query);
  const [modelKey, setModelKey] = useState(0);

  useEffect(()=>{
    let filterType = 'RC_MANAGEMENT';
    if (!!props.isRCMgmtOthers) {
      filterType= 'RC_MANAGEMENT_OTHERS';
    }
    setFilterType(filterType);
    loadFilters(filterType);
    setGridFilters(props.query);
  }, [props.isRCMgmtOthers, props.query]);

  const loadFilters = (filterType) => {
    rcManagementService.fetchFilter(filterType).then((response) => {
      const {
        filter_data,
        id,
        meta_data: {
          tm_emails,
          agent_list,
          city_list,
          state_list,
          car_source,
          date_operators,
          rc_verification_status,
        }
      } = response;

      setFilterId(id);

      // --- todo --- try generate this dynamically
      const tempOptions = {
        agent_list: dataWrapperArrayOfObj(agent_list, 'agent_name', 'agent_id'),
        tm_emails: dataWrapperArray(tm_emails),
        city_list: dataWrapperArrayOfObj(city_list, 'cityName', 'cityId'),
        //city_list: [],
        state_list: dataWrapperArrayOfObj(state_list, 'stateName', 'stateId'),
        car_source: dataWrapperArrayOfObj(car_source, 'display_text', 'value'),
        rc_verification_status: dataWrapperArrayOfObj(rc_verification_status, 'label', 'value'),
        date_operators: dataWrapperArrayOfObj(
          date_operators,
          'display_text',
          'value'
        )
      };
      // set meta data for select box options
      setOptions(tempOptions);

      //  --- todo --- generate this dynamically via FILTER_API_KEYS
      const tempDefaults = {
        agent_id: getDefaultValues(
          tempOptions.agent_list,
          filter_data,
          'agent_id',
          'multiple'
        ),
        reporting_manager_of_buddy: getDefaultValues(
          tm_emails,
          filter_data,
          'reporting_manager_of_buddy',
          'single'
        ),
        city_id: getDefaultValues(
          tempOptions.city_list,
          filter_data,
          'city_id',
          'multiple'
        ),
        state_id: getDefaultValues(
          tempOptions.state_list,
          filter_data,
          'state_id',
          'multiple'
        ),
        car_source: getDefaultValues(
          tempOptions.car_source,
          filter_data,
          'car_source',
          'multiple'
        ),
        rc_verification_status: getDefaultValues(
          tempOptions.rc_verification_status,
          filter_data,
          'rc_verification_status',
          'multiple'
        ),
        delivery_date_operator: getDefaultValues(
          tempOptions.date_operators,
          filter_data,
          'delivery_date_operator',
          'string'
        ),
        file_completion_date_operator: getDefaultValues(
          tempOptions.date_operators,
          filter_data,
          'file_completion_date_operator',
          'string'
        ),
        token_date_operator: getDefaultValues(
          tempOptions.date_operators,
          filter_data,
          'token_date_operator',
          'string'
        )
      };
      // set default data for select box - prefill data
      setDefaultOptions(tempDefaults);

      // setting initial values for formik
      setInitialValues({
        ...initialValues,
        ...tempDefaults,
        file_completion_date:
          filter_data &&
          filter_data.file_completion_date &&
          new Date(filter_data.file_completion_date),
        delivery_date:
          filter_data &&
          filter_data.delivery_date &&
          new Date(filter_data.delivery_date),
        token_date:
          filter_data &&
          filter_data.token_date &&
          new Date(filter_data.token_date)
      });
    });
  };

  const getDefaultValues = (sourceSet, filter_data, key, type) => {
    let result = [];
    const valueSet = filter_data && filter_data[key];
    if (sourceSet && Array.isArray(sourceSet) && valueSet) {
      // set default values for array of objects (select isMuilt is true)
      if (Array.isArray(valueSet) && type === 'multiple') {
        sourceSet.forEach((srcItem) => {
          valueSet.forEach((valItem) => {
            // Note : not using strict comparison because API return numbers as string
            if (valItem === srcItem['value']) {
              result.push(srcItem);
            }
          });
        });
        return result;
      } else if (Array.isArray(valueSet) && type === 'single') {
        // set default values for array (select isMuilt is false)
        sourceSet.forEach((srcItem) => {
          valueSet.forEach((valItem) => {
            if (valItem === srcItem) {
              result.push({ label: valItem, value: valItem });
            }
          });
        });
        return result;
      } else if (type === 'string') {
        // set default values for datepicker or string input
        sourceSet.forEach((srcItem) => {
          if (valueSet === srcItem['value']) {
            result = srcItem;
          }
        });
        return result;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  // const handleOnChange = e => {
  // };
  const handleOnChangeDate = (id, date, formikProps) => {
    try {
      formikProps.setFieldValue(id, date && date.toISOString());
    } catch (e) {
      alert('Invalid Date Selected');
    }
  };
  const handleOnChangeSelect = (formikProps, selectedOptions, id) => {
    formikProps.setFieldValue(id, selectedOptions, true);
  };

  const getPayloadValue = (data) => {
    if (data && Array.isArray(data) && data.length > 0) {
      return data.map((item) => item.value);
    } else {
      return [];
    }
  };

  const handleFetchData = () => {
    if (gridFilters) {
      // Fetch data from first page
      props.getData(0, gridFilters);
    } else if (isObject(props.query) && Object.keys(props.query).length > 0) {
      props.resetGridFilter(true);
    } else {
      // Fetch data from first page
      props.getData(0, {});
    }
  };

  const handleSubmit = (values, action) => {
    try {
      const filter_data = {
        reporting_manager_of_buddy: getPayloadValue(
          values['reporting_manager_of_buddy']
        ),
        city_id: getPayloadValue(values['city_id']),
        state_id: getPayloadValue(values['state_id']),
        car_source: getPayloadValue(values['car_source']),
        rc_verification_status: getPayloadValue(values['rc_verification_status']),
        agent_id: getPayloadValue(values['agent_id']),
        delivery_date: values['delivery_date'],
        delivery_date_operator: values['delivery_date_operator']['value'],
        file_completion_date: values['file_completion_date'],
        file_completion_date_operator:
          values['file_completion_date_operator']['value'],
        token_date: values['token_date'],
        token_date_operator: values['token_date_operator']['value']
      };
      rcManagementService
        .saveFilters(filter_data, filterType, filterId)
        .then((response) => {
          handleFetchData();
          handleFilterClose();
        })
        .catch((e) => {
          action.setSubmitting(false);
        });
    } catch (e) {
      console.log('try--catch handleSubmit', e);
      action.setSubmitting(false);
    }
  };

  const handleFilterOpen = () => {
    props.resetGridFilter(false);
    loadFilters();
    open();
  };

  const handleFilterClose = () => {
    loadFilters();
    close();
  };

  const clearFilter = (formikProps) => {
    setInitialValues(initValue);
    formikProps.setValues(initValue);

    const tempDefaults = {
      agent_id: [],
      reporting_manager_of_buddy: [],
      city_id: [],
      rc_verification_status: [],
      state_id: [],
      car_source: [],
      delivery_date_operator: [],
      file_completion_date_operator: [],
      token_date_operator: []
    };
    // set default data for select box - prefill data
    setDefaultOptions(tempDefaults);
    // “Clear” should clear all the filter in the grid as well as additional filters
    setGridFilters(null);
    setModelKey(modelKey + 1);
  };

  return (
    <div className='row'>
      <div className='col-md-12 text-right'>
        <button onClick={handleFilterOpen} className='btn btn-custom'>
          Filters
        </button>
        <Modal key={`model_${modelKey}`}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {(formikProps) => {
              return (
                <Form onSubmit={formikProps.handleSubmit}>
                  <div
                    className='Filter_popUP col-md-12'>
                    <button
                      type='button'
                      className='modal-close-button close'
                      aria-label='Close'
                      onClick={handleFilterClose}
                    >
                      <span aria-hidden='true'>&times;</span>
                    </button>
                    {Object.keys(formikProps.values).length > 0 &&
                      FILTER_SETS.map((set, setKey) => {
                        return (
                          <div
                            key={setKey}
                            className='row mb20 datepicker_custom align-items-center'
                          >
                            {set.map((item) => {
                              if (item.type === 'datepicker') {
                                return (
                                  <Datepicker
                                    key={item['key']}
                                    id={item['key']}
                                    label={item['label']}
                                    value={formikProps.values[item['key']]}
                                    formikProps={formikProps}
                                    onChange={handleOnChangeDate}
                                  />
                                );
                              } else {
                                const key = item['key'];
                                const labelText = item['label'];
                                const optionsList =
                                  options && options[item['optionKey']];
                                const isMulti = item['isMulti'];
                                return (
                                  optionsList && (
                                    <FilterSelect
                                      key={key}
                                      id={key}
                                      label={labelText}
                                      value={
                                        formikProps.values &&
                                        formikProps.values[key]
                                      }
                                      defaultValue={
                                        defaultOptions && defaultOptions[key]
                                          ? defaultOptions[key]
                                          : []
                                      }
                                      onChange={handleOnChangeSelect}
                                      autoComplete='off'
                                      formikProps={formikProps}
                                      optionsList={optionsList}
                                      isMulti={isMulti}
                                    />
                                  )
                                );
                              }
                            })}
                          </div>
                        );
                      })}
                    <div className='row'>
                      <div className='col-3' />
                      <div className='col-3 text-center'>
                        <button
                          type='reset'
                          className='btn btn-custom pull-right'
                          onClick={() => clearFilter(formikProps)}
                        >
                          Clear Filter
                        </button>
                      </div>
                      <div className='col-3 text-center'>
                        <button
                          type='submit'
                          className='btn btn-custom pull-left'
                          disabled={formikProps.isSubmitting}
                        >
                          Apply Filter
                        </button>
                      </div>
                      <div className='col-3' />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </div>
      <br />
    </div>
  );
};

const CInput = ({ id, value, onChange, rest = {} }) => {
  return (
    <input
      id={id}
      name={id}
      type='text'
      defaultValue={value}
      onChange={onChange}
      className='form-control'
      {...rest}
    />
  );
};

// --- todo sprint-7 --- create a seperate component
const FilterSelect = ({
  id,
  label,
  value,
  defaultValue,
  onChange,
  formikProps,
  optionsList,
  isMulti,
  rest = {}
}) => {
  return (
    <div className='col-6'>
      <div className='row align-items-center'>
        {label && (
          <div className='col-4'>
            <label htmlFor={id}>{label}</label>
          </div>
        )}
        <div className='col-8 customScrollY'>
          <SelectComponent
            id={id}
            name={id}
            optionsList={optionsList}
            placeholder={label}
            defaultValue={defaultValue}
            value={value ? value : null}
            onChange={(selectedOptions, id) =>
              onChange(formikProps, selectedOptions, id)
            }
            // isDisabled={!!trackerData['base_agent_id']}
            className='form-control inputSpace col-6'
            isMulti={isMulti}
            version='v2'
            //style={customStyle}
          />
          <Error id={id} />
        </div>
      </div>
    </div>
  );
};

// --- todo sprint-7 --- create a seperate component
// const FilterInput = ({ id, label, value, onChange, rest = {} }) => {
//   const inputProps = { id, value, onChange, ...rest };
//   return (
//     <div className='col-6'>
//       <div className='row align-items-baseline'>
//         <div className='col-6'>
//           <label htmlFor={id}>{label}</label>
//         </div>
//         <div className='col-6'>
//           <CInput {...inputProps} />
//           {/* <ErrorMessage name={id} /> */}
//         </div>
//       </div>
//     </div>
//   );
// };

// --- todo sprint-7 --- create a seperate component
const Datepicker = ({
  id,
  label,
  value,
  defaultValue = null,
  onChange,
  formikProps,
  ...dateProps
}) => {
  return (
    <div className='col-6'>
      <div className='row align-items-center'>
        {label && (
          <div className='col-4'>
            <label htmlFor={id}>{label}</label>
          </div>
        )}
        <div className='col-8 customScrollY'>
          {/*  onChangeRaw used to restrict user from typing value in input box */}
          <DatePicker
            selected={value || ''}
            onChange={(date) => onChange(id, date, formikProps)}
            onChangeRaw={(e) => {
              e.preventDefault();
            }}
          />
          <Error id={id} />
        </div>
      </div>
    </div>
  );
};

// --- todo sprint-7 --- create a seperate component
const Error = ({ id }) => (
  <ErrorMessage name={id}>
    {(msg) => (
      <font size='1' color='red' style={{ float: 'right' }}>
        {msg}
      </font>
    )}
  </ErrorMessage>
);

export default Filter;
