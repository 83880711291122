import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FinanceDiscardProofModal from './component';

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinanceDiscardProofModal);
