export const FIELDS = {
  cityId: {
    label: 'City Name',
    keyName: 'city_id',
    type: 'text',
    required: false,
    elementType: 'input'
  },
  zoneId: {
    label: 'Zone ',
    keyName: 'zone_id',
    type: 'text',
    required: false,
    elementType: 'input'
  },
  zones: {
    label: 'Zone',
    keyName: 'zone_id',
    type: 'select',
    isMulti: false,
    required: false,
    elementType: 'select',
    isReadOnly: false
  },
  language: {
    label: 'Language',
    keyName: 'cc_language',
    type: 'select',
    isMulti: false,
    required: true,
    elementType: 'select',
    isReadOnly: false
  },

  push_seller_to_cc: {
    label: 'Push seller to CC ',
    keyName: 'push_seller_to_cc',
    type: 'checkbox',
    required: false,
    elementType: 'checkbox'
  }
};
export const FIELD_SETS = [
  [FIELDS.cityId],
  [FIELDS.zones],
  [FIELDS.language],
  [FIELDS.push_seller_to_cc]
];
