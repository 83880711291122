import React, { useState, useEffect } from 'react';
import columnDefs from './config/columnDefs';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import GridView from '../common/grid-view';

import './style.css';
import globalStyles from '../../../shared/utils/global.module.css';
import UploadButton, {
  UploadIcon
} from '../../../shared/components/upload-button/component';
import { userService } from '../../../services';
import { showToastMessages } from '../../../shared/utils/helper';
//import storeService from '../../service/storeService';

const UserGrid = function(props) {
  const [rowData, setRowData] = useState();
  const [isUpdating, setIsUpdating] = useState();
  useEffect(() => {
    props
      .fetchUserGridInfoConnected()
      .then((response) => {
        setRowData(response);
      })
      .catch((err) => {});
  }, []);
  const onRouteId = (data) => {
    const route = `/user/${data.userId}/${data.rowIndex}`;
    props.history.push(route);
  };

  const handleUpload = (file) => {
    // setFilename(file.name);
    setIsUpdating(true);
    const formData = new FormData();
    formData.append('file', file);

    userService
      .updateUsers(formData)
      .then(() => {
        setIsUpdating(false);
        showToastMessages('Users update request accepted', true);
      })
      .catch(() => {
        setIsUpdating(false);
      });
  };
  return (
    <>
      <div className='row'>&nbsp;</div>
      <div className='row'>
        <div className='col-12 text-right d-flex justify-content-end'>
          <Link to='/user/add'>
            <button
              className={`btn btn-success ${globalStyles.mr10}`}
              type='button'
            >
              Add
            </button>
          </Link>

          <UploadButton
            label='Update'
            onChangeHandler={handleUpload}
            accept='.xlsx'
            btnIcon={UploadIcon}
            iconAlt='Replace Icon'
            isLoading={isUpdating}
          />
        </div>

        <div className='col-md-1 pull-right' />
        <br />
      </div>
      <GridView
        columnDefs={columnDefs}
        rowData={rowData && rowData}
        onRedirect={onRouteId}
        uniqueIdName={'userId'}
        uniqueGridId={'users'}
      />
    </>
  );
};

export default withRouter(UserGrid);
