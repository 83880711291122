export const CAR_INFORMATION = [
  {
    label: 'Appointment ID',
    value: 'appointmentId',
    disabled: true,
    type: 'text',
    required: true,
  },
  {
    label: 'Make',
    value: 'make',
    type: 'dropDown',
    disabled: false,
    required: true,
    listName: 'make',
  },
  {
    label: 'Model',
    value: 'model',
    type: 'dropDown',
    disabled: false,
    required: true,
    listName: 'model',
  },
  {
    label: 'Variant',
    value: 'variant',
    type: 'dropDown',
    disabled: false,
    required: true,
    listName: 'variant',
  },
  {
    label: 'Manufacturing Year',
    value: 'manufacturingYear',
    type: 'number',
    disabled: false,
    required: true,
    min: 1,
    max: 2020,
  },
  {
    label: 'Manufacturing Month',
    value: 'manufacturingMonth',
    type: 'number',
    disabled: true,
    required: false,
    min: 1,
    max: 12,
  },
  {
    label: 'City',
    value: 'city',
    disabled: true,
    type: 'text',
    required: true,
  },
  {
    label: 'Fuel Type',
    value: 'fuelType',
    type: 'dropDown',
    disabled: false,
    required: true,
    listName: 'fuel',
  },
  {
    label: 'Registration Number',
    value: 'registrationNumber',
    type: 'text',
    disabled: false,
    required: false,
  },
  {
    label: 'Insurance Expiry',
    value: 'insuranceExpiryDate',
    type: 'date',
    disabled: false,
    required: true,
  },
  {
    label: 'Odometer',
    value: 'odometerReading',
    type: 'number',
    disabled: false,
    required: true,
    min: 1,
    max: 1200000000,
  },
];

export const POST_DATA_LIST = [
  'appointmentId',
  'make',
  'model',
  'variant',
  'manufacturingYear',
  'fuelType',
  'registrationNumber',
  'odometerReading',
  'insuranceExpiryDate',
  'makeId',
  'modelId',
  'variantId',
];
