import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dealer from './component';

import { fetchCityGridInfoConnected } from './action';
const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCityGridInfoConnected
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dealer);
