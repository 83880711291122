import * as Yup from 'yup';
export const phoneRegExp = /^(0|[+91]{3})?[6-9][0-9]{9}$/;
export const phoneNumberRegExp = /^[6789]\d{9}$/;
export const nameRegExp = /^[a-zA-Z ,.'-]+$/;
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const panCardRegex = /[A-Za-z]{5}\d{4}[A-Za-z]{1}/;

export const string = Yup.string();

export const validationMessage = (message, type = 'string') => {
  switch (type) {
    case 'string':
      return Yup.string().required(message);

    case 'object':
      return Yup.object()
        .required(message)
        .nullable();

    default:
      return Yup.string().required(message);
  }
};

export const amount = validationMessage('Amount is Required');
export const payment_mode = validationMessage(
  'Payment Mode Required',
  'object'
).nullable();

// export const name = Yup.string()
//   .required('Name is required')
//   .matches(nameRegExp, 'Invalid Name')
//   .typeError('Name should be a string');

// export const phoneAsNum = Yup.number()
//   .required('Phone number is required')
//   .matches(phoneRegExp, 'Invalid Phone Number')
//   .typeError(' should be a number');
//var a=2,
// export const phoneAsStr = Yup.string()
//   .required('Phone number is required')
//   .matches(phoneNumberRegExp, 'Invalid Phone Number')
//   .typeError(' should be a number');

// export const email = Yup.string()
//   .required('Email is required')
//   .matches(emailRegex, 'Invalid Email')
//   .typeError('Invalid Email');

// export const utr = Yup.number('Invalid')
//   .required('UTR is required')
//   .typeError('UTR must be a number');

// export const selectRequired = Yup.string()
//   .required('Status is Required')
//   .nullable();

/*
1.Multiselect -2 or more required
2.Date -Normal 
3.Date 15 days in future ex. current date 15 june 2020 
      input-20 june 2020 validation result-Invalid date 
      input-1 july 20202 validation result-success
*/
