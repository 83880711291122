import React, { Component } from 'react';

const LinkRenderer = (props) => {
  const {
    data,
    context: { uniqueIdName, onRedirect },
    value,
    colDef,
    rowIndex
  } = props;

  return (
    <a
      href='javascript:void(0);'
      onClick={() => onRedirect({ ...data, rowIndex })}
    >
      {value || data[colDef.renderField]}
    </a>
  );
};

export default LinkRenderer;
