import React, { useState, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import DropDownFilter from '../grid/dropDownFilter';
import MultiSelectFilter from '../grid/multiSelectFilter';
import LinkRenderer from './linkRenderer';
import LabelRenderer from '../grid/labelRenderer';
import MultiSelectRenderer from '../grid/MultiSelectRenderer';
import DeleteIconRenderer from '../grid/deleteIconRenderer';
import InputRenderer from '../grid/inputRenderer';
import TmAssignCtaRenderer from '../grid/TmAssignCtaRenderer';
import CtaRenderer from '../grid/ctaRenderer';
import { isObject } from '../../../../shared/utils/helper';
import './styles.css';
import { getformattedDate } from '../../../../shared/utils/datesHelper';

function GridView({
  columnDefs,
  rowData,
  onRedirect,
  uniqueIdName,
  onFilterChanged,
  onSortChanged,
  columnsToFit = true,
  resetFilter = false,
  uniqueGridId = null,
  setGridFilterConnected,
  resetAllGridModelConnected,
  getRowHeight = null,
  floatingFilter = true,
  context,
  className,
  ...props
}) {
  const [gridApi, setGridApi] = useState();
  const frameworkComponents = useMemo(() => (
    {
      LinkRenderer,
      LabelRenderer,
      DropDownFilter,
      MultiSelectFilter,
      MultiSelectRenderer,
      DeleteIconRenderer,
      InputRenderer,
      TmAssignCtaRenderer,
      CtaRenderer,
    }
  ), []);

  useEffect(() => {
    if (resetFilter) {
      // used when clear filter is used from additional filters
      resetAllGridModelConnected();
      gridApi.setFilterModel(null);
    }
  }, [resetFilter]);

  const useAgGrid = (gridOptions) => {
    setGridApi(gridOptions.api);
    gridOptions.api.setColumnDefs(columnDefs);
    gridOptions.api.setRowData(rowData);

    // const colIds = gridOptions.columnApi
    //   .getAllColumns()
    //   .map((cols) => cols.colId);
    // gridOptions.columnApi.autoSizeColumns(colIds, false);
    columnsToFit && gridOptions.api.sizeColumnsToFit();

    /**  functionality to retain grid filter after page change */
    uniqueGridId &&
      props.gridInfo &&
      props.gridInfo[uniqueGridId] &&
      gridOptions.api.setFilterModel(props.gridInfo[uniqueGridId]['model']);
  };

  const handleFilterChanged = () => {
    const model = gridApi.getFilterModel();
    const params = createQuery(model);

    let rawFilterConnected = props.gridInfo[uniqueGridId]['raw'] || {};
    let filtersConnected = props.gridInfo[uniqueGridId]['filter'] || {};

    if (isObject(params)) {
      const rawFilterConnectedKeys = Object.keys(rawFilterConnected);
      const filtersConnectedKeys = Object.keys(filtersConnected);
      const notConnectedKeys = filtersConnectedKeys.filter(
        (x) => !rawFilterConnectedKeys.includes(x)
      );
      notConnectedKeys.forEach((key) => {
        delete filtersConnected[key];
      });

      if (!onFilterChanged) return;

      const modifiedParams = {};
      Object.keys(params).forEach((filter) => {
        modifiedParams[filter] = params[filter].split(',');
      });
      if (uniqueGridId) {
        props.setGridModelConnected({
          uniqueGridId,
          model,
          filter: modifiedParams
        });
      }

      const allFilter = {
        ...modifiedParams,
        ...filtersConnected
      };
      onFilterChanged && onFilterChanged(allFilter);
    } else {
      resetAllGridModelConnected();
      gridApi.setDatasource();
    }
  };

  const createQuery = (model) => {
    let query = {};
    Object.keys(model).forEach((key) => {
      if (model[key].filter) {
        query[key] = model[key].filter;
      }
      if (model[key].filterType === 'date') {
        query[key] = getformattedDate(
          model[key].dateFrom,
          'custom',
          'YYYY-MM-DD'
        );
        query['token_date_operator'] = 'EQUALS';
      }
    });

    return query;
  };

  const handleSortChange = () => {
    const sortState = gridApi.getSortModel();
    onSortChanged(sortState);
  }

  useEffect(() => {
    if (gridApi) {
      gridApi.refreshCells();
    }
  }, [rowData]);

  return (
    <div className={`ag-theme-balham ag-grid-outer-wrapper header-select ag-grid-paginator-wrapper ${className}`}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={Array.isArray(rowData) ? rowData : []}
        defaultColDef={{
          resizable: true
        }}
        context={{
          onRedirect,
          uniqueIdName,
          onFilterChanged,
          uniqueGridId,
          setGridFilterConnected,
          gridInfo: props.gridInfo,
          ...context
        }}
        floatingFilter={floatingFilter}
        onFilterChanged={handleFilterChanged}
        frameworkComponents={frameworkComponents}
        onGridReady={useAgGrid}
        enableSorting
        // onPaginationChanged={this.handlePaginationChanged}
        onSortChanged={handleSortChange}
        suppressPaginationPanel
        enableCellTextSelection={true}
        rowSelection='single'
        skipHeaderOnAutoSize={true}
        //domLayout='autoHeight'
        // onSelectionChanged={onSelectionChanged}
        reactNext={true}
        getRowHeight={getRowHeight}
      />
    </div>
  );
}
export default GridView;
