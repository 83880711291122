// import Types from './types';
import { financeService } from '../../../services';
import {
  showToastMessages,
  catchErrorMessage
} from '../../../shared/utils/helper';

const switchProofInfoConnected = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    financeService
      .switchProof(data)
      .then((resp) => {
        resolve(resp);
        showToastMessages(resp.data.message);
      })
      .catch((error) => {
        catchErrorMessage(error);
        reject(error);
      });
  });
};

export { switchProofInfoConnected };
