const AGENT_LOGS = [
  {
    label: 'Agent Name',
    headerKey: 'agent_name',
    colWidth: 'col-2'
  },
  {
    label: 'Agent Cost',
    headerKey: 'agent_cost',
    colWidth: 'col-2'
  },
  {
    label: 'Agent Payment',
    headerKey: 'agent_payment',
    colWidth: 'col-3'
  },
  {
    label: 'Agent Status',
    headerKey: 'agent_status',
    colWidth: 'col-3'
  },
  { label: 'Date', headerKey: 'date', colWidth: 'col-2', dataType: 'datetime' }
];

const REMARKS_LOGS = [
  {
    label: 'Date',
    headerKey: 'created_at',
    colWidth: 'col-2',
    dataType: 'datetime'
  },
  { label: 'User Email', headerKey: 'created_by', colWidth: 'col-3' },
  {
    label: 'Remark',
    headerKey: 'remarks_text',
    colWidth: 'col-7 word-break'
  }
];

export const TABLE_HEADERS = {
  AGENTS: AGENT_LOGS,
  REMARKS: REMARKS_LOGS
};
