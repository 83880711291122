import { zoneCarSourceOptions } from '../../../shared/utils/constants/inventory';

export default [
  {
    headerName: 'Id',
    width: 100,
    field: 'id',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    cellRenderer: 'LinkRenderer',
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Zone',
    width: 200,
    field: 'zone',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true
    },

    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'City',
    width: 200,
    field: 'city',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },

    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Type',
    width: 200,
    field: 'type',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'type',
      options: Object.values(zoneCarSourceOptions)
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: [Object.values(zoneCarSourceOptions)]
    },
    suppressMenu: true
  },
  {
    headerName: 'Status',
    width: 200,
    field: 'isActive',
    value: 'viv',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'isActive',
      options: [
        { key: 'Active', value: 'Active' },
        { key: 'Inactive', value: 'Inactive' }
      ]
    }
  }
];
