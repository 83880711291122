import React from "react";
import './style.css'
import locale from '../../utils/locale';

const NoAccess = () => {
  return (
    <div className="center">
      {locale.NO_ACCESS}
    </div>
  );
};

export default NoAccess;
