import { useEffect, useState } from "react";
import { rtoDelayReasonsService } from "../../../services"

export const useRtoDelayReason = () => {
  const fetchStates = rtoDelayReasonsService.fetchStates;
  const fetchRtosByStateCode = rtoDelayReasonsService.fetchRtosByStateCode;
  const fetchRcDelayStages = rtoDelayReasonsService.fetchRcDelayStages;
  const uploadDelayProof = rtoDelayReasonsService.uploadDelayProof;
  const saveRtoDelays = rtoDelayReasonsService.saveRtoDelays;
  const fetchRtoDelayLogs = rtoDelayReasonsService.fetchRtoDelayLogs;
  const updateRtoDelay = rtoDelayReasonsService.updateRtoDelay;

  const [stateOptions, setStatesOptions] = useState([]);
  const [rtoOptions, setRtoOptions] = useState([]);
  const [rcDelayStages, setRcDelayStages] = useState([]);
  const [rcDelayStagesOptions, setRcDelayStagesOptions] = useState([]);
  const [rtoDelays, setRtoDelays] = useState([]);
  const [rtoDelayPageSize,] = useState(10);
  const [rtoDelayPageIndex, setRtoDelayPageIndex] = useState(0);
  const [isVisibleRtoDelayShowMore, setIsVisibleRtoDelayShowMore] = useState(false);
  const [resetDelayLogs, setResetDelayLogs] = useState(false);

  const getStates = () => {
    fetchStates()
      .then((data) => {
        const stateOptions = data.map(({ stateName, stateCode }) => ({ value: stateCode, label: stateName }));
        setStatesOptions(stateOptions);
      });
  };

  const getRcDelayStages = () => {
    fetchRcDelayStages()
      .then((data) => {
        setRcDelayStages(data);
        const rcDelayStagesOptions = Object.keys(data).map((key) => ({ value: key, label: key }));
        setRcDelayStagesOptions(rcDelayStagesOptions);
      })
  };

  const getDelayReasonOptionsByStage = (key) => {
    return (rcDelayStages[key] || []).map((delayReason) => ({ value: delayReason, label: delayReason }));
  }

  const getRtosByStateCode = (stateCode) => {
    fetchRtosByStateCode({ stateCode })
      .then((data) => {
        const rtoOptions = data.map(({ rtoCode }) => ({ value: rtoCode, label: rtoCode }));
        setRtoOptions(rtoOptions);
      });
  }

  const getRtoDelayLogs = (body) => {
    fetchRtoDelayLogs(body)
      .then((resp) => {
        const { content, totalElements } = resp;
        let updatedRtoDelays;
        if (rtoDelayPageIndex) {
          updatedRtoDelays = rtoDelays.concat(content);
        } else {
          updatedRtoDelays = content;
        }
        setRtoDelays(updatedRtoDelays);
        if (totalElements === updatedRtoDelays.length) {
          setIsVisibleRtoDelayShowMore(false);
        } else {
          setIsVisibleRtoDelayShowMore(true);
        }
      });
  }

  const refreshRtoDelayLogs = () => {
    /**
     * this fn calls delay logs api with page 0 to reset the logs list to the first page
     */
    setRtoDelayPageIndex(0);
    
    if(!rtoDelayPageIndex) {
      setResetDelayLogs(!resetDelayLogs);
    }
  }

  const onShowMore = () => {
    setRtoDelayPageIndex(rtoDelayPageIndex + 1);
  }

  useEffect(() => {
    getStates();
    getRcDelayStages();
  }, []);

  useEffect(() => {
    getRtoDelayLogs({
      page: rtoDelayPageIndex,
      size: rtoDelayPageSize,
    });
  }, [rtoDelayPageIndex, rtoDelayPageSize, resetDelayLogs]);

  return { 
    stateOptions, 
    rtoOptions,
    rcDelayStagesOptions,
    rtoDelays, 
    isVisibleRtoDelayShowMore,
    setRtoOptions,
    getRtosByStateCode,
    getDelayReasonOptionsByStage,
    uploadDelayProof,
    saveRtoDelays, 
    getRtoDelayLogs,
    refreshRtoDelayLogs,
    rtoDelayPageSize,
    setRtoDelayPageIndex,
    updateRtoDelay,
    onShowMore,
  };
}