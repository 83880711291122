import React, { useState, useEffect } from 'react';
import { Label, Input } from 'reactstrap';
import '../Buyer-Details/style/style.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { VALID_NAME_REGEX, DispositionConstants } from '../../shared/Constants';
import { SelectComponent } from '../../shared';
import messageIcon from "../../shared/images/message.png";

const BuyerDetails = ({
  buyerData,
  car,
  carUrl,
  setCarUrl,
  postData,
  setBuyer,
  setIsValidBuyer,
  setSubmitPostData,
  setRefreshCar,
  selectedCcDispositionId,
  onSendMessage,
  isMessageSent,
  sendMessageError,
  setIsMessageSent,
  setSendMessageError
}) => {
  const [name, setName] = useState(buyerData.name);
  const [buyerCity, setBuyerCity] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [carUrlError, setCarUrlError] = useState(false);

  useEffect(() => {
    if (buyerData.buyerCities && buyerData.buyerCities.cities) {
      const cityList = buyerData.buyerCities.cities;
      let defaultCity = cityList.find((item) => item.id === buyerData.buyerCities.defaultCityId);
      defaultCity = defaultCity ? defaultCity.title : null;
      setBuyerCity(defaultCity);
    }
  }, [])

  useEffect(() => {
    if (name) {
      name.match(VALID_NAME_REGEX) ? setNameError(false) : setNameError(true);
    } else {
      selectedCcDispositionId && selectedCcDispositionId === DispositionConstants.BUYER_OFFER_PRICE_CC_DISPOSITION_ID ? setNameError(true) : setNameError(false);
    }
    if (selectedCcDispositionId && selectedCcDispositionId === DispositionConstants.BUYER_OFFER_PRICE_CC_DISPOSITION_ID) {
      carUrl ? setCarUrlError(false) : setCarUrlError(true);
    } else {
      setCarUrlError(false);
    }
  }, [selectedCcDispositionId])

  useEffect(() => {
    if (postData) {
      const isValid = validateBuyer();
      if (isValid) {
        const buyerObj = {
          name: name,
          carUrl: carUrl,
          city: buyerCity
        };
        setBuyer(buyerObj);
        setIsValidBuyer(true);
      } else {
        setBuyer();
        setIsValidBuyer(false);
      }
      setSubmitPostData(true);
    }
  }, [postData]);

  const onNameChange = (e) => {
    const nameVal = e.target.value;
    setName(nameVal);
    if (nameVal) {
      nameVal.match(VALID_NAME_REGEX) ? setNameError(false) : setNameError(true);
    } else {
      selectedCcDispositionId && selectedCcDispositionId === DispositionConstants.BUYER_OFFER_PRICE_CC_DISPOSITION_ID ? setNameError(true) : setNameError(false);
    }
  }

  const onCarUrlChange = (e) => {
    const carUrlVal = e.target.value.trim();
    setCarUrl(carUrlVal);
    if (selectedCcDispositionId && selectedCcDispositionId === DispositionConstants.BUYER_OFFER_PRICE_CC_DISPOSITION_ID) {
      carUrlVal ? setCarUrlError(false) : setCarUrlError(true);
    } else {
      setCarUrlError(false);
    }
  }

  const onCarUrlBlur = (e) => {
    e.preventDefault();
    setRefreshCar(true);
    setIsMessageSent(false);
    setSendMessageError(false);
  }

  const validateBuyer = () => {
    let errors = 0;
    if (name) {
      name.match(VALID_NAME_REGEX) ? setNameError(false) : ++errors && setNameError(true);
    } else {
      selectedCcDispositionId && selectedCcDispositionId === DispositionConstants.BUYER_OFFER_PRICE_CC_DISPOSITION_ID ? ++errors && setNameError(true) : setNameError(false);
    }
    if (selectedCcDispositionId && selectedCcDispositionId === DispositionConstants.BUYER_OFFER_PRICE_CC_DISPOSITION_ID) {
      carUrl ? setCarUrlError(false) : ++errors && setCarUrlError(true);
    } else {
      setCarUrlError(false);
    }
    if (selectedCcDispositionId && selectedCcDispositionId === DispositionConstants.BUYER_OFFER_PRICE_CC_DISPOSITION_ID && !car) {
      ++errors;
    }
    if (errors === 0)
      return true;
    else
      return false;
  }

  const showBuyerCities = () => {
    const cityList = buyerData.buyerCities.cities || [];
    let optionsList = cityList.map((option) => {
      return (
        { value: option.title, label: option.title }
      )
    });
    let defaultCity = cityList.find((item) => item.id === buyerData.buyerCities.defaultCityId);
    defaultCity = defaultCity ? { value: defaultCity.title, label: defaultCity.title } : '';
    return (
      <div className="row">
        <div className="col-12 seller-box">
          <div className="select buyer-city">
            <SelectComponent name="buyerCity"
              defaultValue={defaultCity}
              onChange={(value) => setBuyerCity(value)}
              optionsList={optionsList} />
            <Label for="buyerCity" className="label">Buyer City</Label>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="main_section_buyer block">
        <h2 className="title">Buyer Details</h2>
        <div className="row">
          <div className="col-12 seller-box">
            <div className="group">
              <Input type="text" className={"txtbox" + (nameError ? ' error' : '')} name="name" value={name || ''} autoComplete="off" onChange={(e) => onNameChange(e)} />
              {
                nameError &&
                <span className="text-error">Valid name is required</span>
              }
              <span className="highlight"></span>
              <Label for="name" className="label">Buyer Name</Label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 seller-box">
            <div className="group">
              <DatePicker
                calendarClassName="datepicker-container"
                selected={buyerData.leadSubmitTime ? new Date(buyerData.leadSubmitTime) : null}
                dateFormat="d MMMM yyyy"
                disabled={true}
                className="picker"
              />
              <span className="highlight"></span>
              <Label for="leadSubmittedTime" className="label">Lead Submitted Date</Label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 seller-box">
            <div className="group">
              <Input type="number" className="txtbox" disabled name="offeredPrice" value={buyerData.offeredPrice || ''} />
              <span className="highlight"></span>
              <Label for="offeredPrice" className="label">Offered Price</Label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 seller-box">
            <div className="group">
              <Input type="text" className={"txtbox" + (carUrlError ? ' error' : '')} name="carUrl" value={carUrl || ''} autoComplete="off" onChange={onCarUrlChange} onBlur={onCarUrlBlur} />
              {
                carUrlError &&
                <span className="text-error">Car url is required</span>
              }
              <span className="highlight"></span>
              <Label for="carUrl" className="label">Car URL</Label>
              {
                isMessageSent
                  ? <span className="success-message">Message sent!</span>
                  : <div onClick={onSendMessage} className="message">
                    Send message
                      <label>
                      <img src={messageIcon} alt="Message" />
                    </label>
                  </div>
              }
              {
                sendMessageError && <span className={'error-message'}>{sendMessageError}</span>
              }
            </div>

          </div>
        </div>
        <div className="row">
          <div className="col-12 seller-box">
            <div className="group">
              <Input type="text" className="txtbox" disabled name="sellerLocation" value={car ? car.sellerLocation || '' : ''} />
              <span className="highlight"></span>
              <Label for="sellerLocation" className="label">Seller Location</Label>
            </div>
          </div>
        </div>
        {
          showBuyerCities()
        }
      </div>
    </div>
  );
};

export default BuyerDetails;