import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import InspectionSection from '../order-inspection-section';
import CONFIG from './constants';
import './style.css';
import CustomError from '../../../shared/components/custom-error';
import CustomButton from '../../../shared/components/button';
import Locale from '../../../shared/utils/locale';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';
import { filterObject, openUrlTab } from '../../../shared/utils/helper';

const OrderInspection = function(props) {
  const [appointmentId] = useState(props.match.params.id);
  const [exteriorTyresData, setExteriorTyresData] = useState();
  const [interiorData, setInteriorData] = useState();
  const [engineData, setEngineData] = useState();
  const [otherData, setOtherData] = useState();
  const [tyreData, setTyreData] = useState();
  const [reportLink, setReportLink] = useState();
  useEffect(() => {
    const {
      exteriorTyres,
      electricalsInterior,
      airConditioning,
      engineTransmission,
      cngLpgFitment,
      steeringSuspensionBrakes,
      registrationState,
      registrationCity,
      pdfUrl = null
    } = props.inspectionInfo.detail || {};

    setReportLink(pdfUrl);

    setExteriorTyresData(exteriorTyres);
    if (
      exteriorTyres &&
      exteriorTyres.subparts &&
      exteriorTyres.subparts.tyre
    ) {
      setTyreData(exteriorTyres.subparts.tyre);
    } else {
      setTyreData(null);
    }

    setEngineData({ ...engineTransmission, cngLpgFitment });
    setInteriorData({ ...electricalsInterior, ...airConditioning });
    setOtherData({
      ...steeringSuspensionBrakes,
      registrationState,
      registrationCity
    });
  }, [props.inspectionInfo]);

  useEffect(() => {
    props.fetchInspectionDetailConnected(appointmentId);
  }, []);

  const getReport = () => {
    if (reportLink) {
      openUrlTab(reportLink);
    }
  };

  return (
    <LoaderWithAccessControl>
      {reportLink && (
        <CustomButton label={'View Inspection Report'} onClick={getReport} />
      )}
      {!props.isError ? (
        <div>
          <InspectionSection
            label={'Exterior'}
            data={exteriorTyresData}
            config={CONFIG.exterior}
          />
          <InspectionSection
            label={'Interior'}
            data={filterObject(interiorData)}
            config={CONFIG.interior}
          />
          <InspectionSection
            label={'Engine'}
            data={filterObject(engineData)}
            config={CONFIG.engine}
          />

          <InspectionSection label={'Tyres'} data={tyreData} config={false} />
          <InspectionSection
            label={'Others'}
            data={filterObject(otherData)}
            config={CONFIG.others}
          />
        </div>
      ) : (
        <CustomError message={Locale.NO_DATA} />
      )}
    </LoaderWithAccessControl>
  );
};

export default withRouter(OrderInspection);
