import { RC_OTHERS_DELAY_REASONS_KEY } from "../../constants";
import { RC_OTHERS_TRACKER_FORM_KEYS } from "./meta-data";

export const KEY_AND_FILE_TYPE_MAP = {
  [RC_OTHERS_TRACKER_FORM_KEYS.LEARNING_LICENCE_UPLOAD]: "learning_licence",
  [RC_OTHERS_TRACKER_FORM_KEYS.E_RECIPT_UPLOAD]: "e_receipt",
  [RC_OTHERS_TRACKER_FORM_KEYS.RTO_NOC_UPLOAD]: "rto_noc",
  [RC_OTHERS_TRACKER_FORM_KEYS.SERVICE_PROOF_UPLOAD]: "service_proof",
  [RC_OTHERS_TRACKER_FORM_KEYS.PHYSICAL_DOC_UPLOAD]: "physical_document",
}

export const RC_OTHERS_TRACKER_FIELD_KEY_DELAY_KEY_MAP = {
  [RC_OTHERS_TRACKER_FORM_KEYS.AGENT_HANDOVER_DELAY]: RC_OTHERS_DELAY_REASONS_KEY.AGENT_HANDOVER_DELAY,
  [RC_OTHERS_TRACKER_FORM_KEYS.AADHAAR_UPDATE_DELAY]: RC_OTHERS_DELAY_REASONS_KEY.AADHAAR_UPDATE_DELAY,
  [RC_OTHERS_TRACKER_FORM_KEYS.E_RECEIPT_DELAY]: RC_OTHERS_DELAY_REASONS_KEY.E_RECEIPT_DELAY,
  [RC_OTHERS_TRACKER_FORM_KEYS.RTO_NOC_DELAY]: RC_OTHERS_DELAY_REASONS_KEY.RTO_NOC_DELAY,
  [RC_OTHERS_TRACKER_FORM_KEYS.PHYSICAL_RC_RECEIVING_DELAY]: RC_OTHERS_DELAY_REASONS_KEY.PHYSICAL_RC_RECEIVING_DELAY,
  [RC_OTHERS_TRACKER_FORM_KEYS.PHYSICAL_RC_HANDOVER_DELAY]: RC_OTHERS_DELAY_REASONS_KEY.PHYSICAL_RC_HANDOVER_DELAY,
  [RC_OTHERS_TRACKER_FORM_KEYS.OWNER_PARTY_PESHI_DELAY]: RC_OTHERS_DELAY_REASONS_KEY.OWNER_PARTY_PESHI_DELAY,
  [RC_OTHERS_TRACKER_FORM_KEYS.BUYER_PARTY_PESHI_DELAY]: RC_OTHERS_DELAY_REASONS_KEY.BUYER_PARTY_PESHI_DELAY,
  [RC_OTHERS_TRACKER_FORM_KEYS.LEARNING_LICENCE_DELAY]: RC_OTHERS_DELAY_REASONS_KEY.LEARNING_LICENCE_DELAY,
  [RC_OTHERS_TRACKER_FORM_KEYS.DRIVING_TEST_DELAY]: RC_OTHERS_DELAY_REASONS_KEY.DRIVING_TEST_DELAY,
  [RC_OTHERS_TRACKER_FORM_KEYS.APPLICANT_PHOTOGRAPH_DELAY]: RC_OTHERS_DELAY_REASONS_KEY.APPLICANT_PHOTOGRAPH_DELAY,
}