import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
  isArrayEmpty,
  getFilterListData,
  getParsedSelectOptions
} from '../../../shared/utils/helper';
import InputText from '../../../shared/components/input-text';
import CityStoreConfig from '../city-store-config';
import { FIELDS, FIELD_SETS } from './constants';
import { Spinner } from 'reactstrap';
import CustomCheckBox from '../../../shared/components/custom-checkbox';
import globalStyles from '../../../shared/utils/global.module.css';
import SelectComponent from '../../../shared/components/select-component';
import Button from '../../../shared/components/button';
const Wrapper = ({ item, children }) => {
  const { label, keyName, required = false } = item;
  return (
    <div className={`col-6 d-flex ${globalStyles.mb10}`}>
      <label className='col-4 justify-content-start' htmlFor={keyName}>
        <span>{label}</span>
        <span>{required ? ' *' : ''}</span>
      </label>
      <div className='col-8'>{children}</div>
    </div>
  );
};

const CityDetailConfig = ({
  fetchCityInspectionStoreInfoByIdConnected,
  match,
  detail,
  zoneInfo,
  langaugeInfo,
  fetchCities,
  updateSellerToCCFlag,
  fetchLanguageConnected,
  fetchZonesListConnected
}) => {
  const [options, setOptions] = useState({});
  const [defaults, setDefaults] = useState({});
  const [cityId] = useState(match.params.id);
  const [sellerFlagStatus, setSellerFlagStatus] = useState('');
  const [pushSellerToCC, setPushSellerToCC] = useState(
    detail.push_seller_to_cc || false
  );
  const [selectedLanguage, setSelectedLanguage] = useState(
    detail.cc_language || ''
  );

  const [selectedZone, setSelectedZone] = useState(detail.zone_id || '');
  const [language, setLanguage] = useState([]);

  useEffect(() => {
    let defaultLanguage = null;
    let defaultZone = null;
    if (!!detail.cc_language) {
      defaultLanguage = {
        label: detail.cc_language,
        value: detail.cc_language
      };
      setDefaults({ ...defaults, cc_language: defaultLanguage });
    }

    if (!!detail.zone_id) {
      defaultZone = { label: detail.zone_id, value: detail.zone_id };
      setDefaults({ ...defaults, zone_id: defaultZone });
      setSelectedZone(detail.zone_id);
    }

    setTimeout(() => {
      setDefaults({
        ...defaults,
        zone_id: defaultZone,
        cc_language: defaultLanguage
      });
    }, 100);
  }, [detail]);

  useEffect(() => {
    setPushSellerToCC(detail.push_seller_to_cc || false);
  }, [detail.push_seller_to_cc]);

  useEffect(() => {
    let query = {};
    query.city_id = cityId;
    fetchCityInspectionStoreInfoByIdConnected(query);
    fetchLanguageConnected();
    // fetchZonesListConnected().then((response) => {
    //   console.log('response', response);
    // });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setSellerFlagStatus('');
    }, 15000);
  }, [sellerFlagStatus]);

  useEffect(() => {
    if (!isArrayEmpty(zoneInfo.data)) {
      const activeZones = zoneInfo.data.filter(
        (item) => item.isActive && item.isActive.toLowerCase() === 'active'
      );
      const zoneOptions = getParsedSelectOptions(activeZones, {
        labelKey: 'zone',
        valueKey: 'id'
      });

      fetchCities()
        .then((resp) => {
          const cityOptions = getParsedSelectOptions(resp, {
            labelKey: 'cityName',
            valueKey: 'cityId'
          });

          setOptions({
            ...options,
            [FIELDS.cityId.keyName]: cityOptions,
            [FIELDS.zoneId.keyName]: zoneOptions
          });
        })
        .catch((err) => {
          setOptions({
            ...options,
            [FIELDS.cityId.keyName]: []
          });
        });
    }
  }, [zoneInfo]);

  useEffect(() => {
    if (
      !!langaugeInfo &&
      !langaugeInfo.isLoading &&
      !!langaugeInfo.detail.data
    ) {
      if (!isArrayEmpty(langaugeInfo.detail.data.data.languages)) {
        const languageOptions = langaugeInfo.detail.data.data.languages.map(
          (item) => {
            return { label: item, value: item };
          }
        );
        setLanguage(languageOptions);
        setOptions({
          ...options,
          [FIELDS.language.keyName]: languageOptions
        });
      }
    }
  }, [langaugeInfo]);

  const handleInputChange = (e) => {
    const { value, checked, type } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setPushSellerToCC(newValue);
    // setSellerFlagStatus('');
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    updateSellerToCCFlag({
      push_seller_to_cc: pushSellerToCC,
      city_id: cityId,
      cc_language: selectedLanguage,
      zone_id: selectedZone
    })
      .then(() => {
        setSellerFlagStatus('Saved');
        setSelectedLanguage(selectedLanguage);
      })
      .catch(() => {
        setSellerFlagStatus('Failed');
      });
    // setSellerFlagStatus('');
  };
  const onChangeSelect = (key, selected) => {
    if (selected) {
      key === 'cc_language'
        ? setSelectedLanguage(selected)
        : setSelectedZone(selected);
    } else {
      key === 'cc_language' ? setSelectedLanguage(null) : setSelectedZone(null);
    }
  };

  return (
    <>
      <form name='store' onSubmit={onHandleSubmit}>
        {FIELD_SETS.map((set, setIndex) => {
          return (
            <div key={`outer_${setIndex}`}>
              {set.map((fieldItem, fieldItemIndex) => {
                const {
                  type,
                  label,
                  keyName,
                  required,
                  elementType,
                  isMulti,
                  maxLength,
                  ...restProps
                } = fieldItem;
                const defaultSelection = getFilterListData(
                  options[keyName],
                  detail && detail[keyName]
                );
                switch (elementType) {
                  case 'input':
                    return (
                      <Wrapper
                        item={fieldItem}
                        key={`wrapper_${fieldItemIndex}`}
                      >
                        {options[keyName] ? (
                          <InputText
                            key={keyName}
                            id={keyName || null}
                            name={keyName || null}
                            type={type}
                            className='form-control wd100'
                            disabled={true}
                            value={
                              detail && detail[keyName] && defaultSelection
                                ? defaultSelection[0].label
                                : ''
                            }
                            required={required}
                            maxLength={maxLength}
                            {...restProps}
                          />
                        ) : (
                          <Spinner
                            variant='primary'
                            animation='border'
                            size='sm'
                          />
                        )}
                      </Wrapper>
                    );
                  case 'select':
                    return (
                      <Wrapper item={fieldItem}>
                        {options[keyName] ? (
                          <SelectComponent
                            key={
                              defaults[keyName]
                                ? defaults[keyName].value
                                : keyName
                            }
                            name={keyName}
                            // onChange={onChangeSelect}
                            onChange={(value) => onChangeSelect(keyName, value)}
                            className='form-control inputSpace col-6'
                            optionsList={
                              options[keyName] ? options[keyName] : []
                            }
                            value={
                              keyName === 'zone_id'
                                ? selectedZone
                                : selectedLanguage
                            }
                            defaultValue={defaultSelection || null}
                          />
                        ) : (
                          <Spinner
                            variant='primary'
                            animation='border'
                            size='sm'
                          />
                        )}
                      </Wrapper>
                    );
                  default:
                    return null;
                }
              })}
            </div>
          );
        })}

        <div className='col-6 d-flex'>
          <label
            className='col-4 justify-content-start'
            htmlFor={'pushSellerToCC'}
          >
            <span>{'Push seller to CC '}</span>
          </label>
          <div className='col-1'>
            <CustomCheckBox
              key={'pushSellerToCC'}
              name={'pushSellerToCC'}
              value={pushSellerToCC}
              onClickCallback={handleInputChange}
              checkedStatus={pushSellerToCC}
            />
          </div>
          <div className='col-7'>
            <span
              className={
                sellerFlagStatus === 'Saved' ? 'text-success' : 'text-error'
              }
            >
              {sellerFlagStatus}
            </span>
          </div>
        </div>
        <div
          className={`${globalStyles.mv10} ${globalStyles.mh10} col-6 d-flex`}
        >
          <Button label='Save' type='submit' />
        </div>
      </form>
      <CityStoreConfig storeInfo={detail && detail.inspection_stores} />
    </>
  );
};

export default withRouter(CityDetailConfig);
