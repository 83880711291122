import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HpManagementGrid from './component';
import {
  setPageNumberConnected,
  fetchHpManagementGridInfoConnected
} from './actions';
const mapStateToProps = (state) => {
  const {
    rcManagementInfo: { pageNumber }
  } = state;
  return {
    pageNumber
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPageNumberConnected,
      fetchHpManagementGridInfoConnected
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HpManagementGrid);
