import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  allIds: [],
  byId: {},
  isUploading: false
};

export const fetchRegionListSuccess = (state = INITIAL_STATE, { data }) => {
  return { ...state, ...data };
};

export const uploadConfigRulesInit = (state = INITIAL_STATE) => {
  return { ...state, isUploading: true };
};

export const uploadConfigRulesEnd = (state = INITIAL_STATE) => {
  return { ...state, isUploading: false };
};

export const HANDLERS = {
  [Types.FETCH_REGION_LIST_SUCCESS]: fetchRegionListSuccess,
  [Types.UPLOAD_CONFIG_RULES_INIT]: uploadConfigRulesInit,
  [Types.UPLOAD_CONFIG_RULES_END]: uploadConfigRulesEnd
};

export default createReducer(INITIAL_STATE, HANDLERS);
