import dayjs from "dayjs";

const ownershipTypes = {
  INDIVIDUAL: "Individual",
  PROPRIETORSHIP: "Proprietorship",
  COMPANY: "Company",
  PARTNERSHIP: "Partnership"
};

const ownershipTypesMap = {
  [ownershipTypes.INDIVIDUAL]: {
    key: ownershipTypes.INDIVIDUAL,
    value: 'Individual'
  },
  [ownershipTypes.PROPRIETORSHIP]: {
    key: ownershipTypes.PROPRIETORSHIP,
    value: 'Proprietorship'
  },
  [ownershipTypes.COMPANY]: {
    key: ownershipTypes.COMPANY,
    value: 'Company'
  },
  [ownershipTypes.PARTNERSHIP]: {
    key: ownershipTypes.PARTNERSHIP,
    value: 'Partnership'
  }
}

const documentationStatuses = {
  VERIFYING: "Verifying",
  REJECTED: "Rejected",
  VERIFIED: "Verified",
}

const documentationStatusesMap = {
  [documentationStatuses.VERIFYING]: {
    key: documentationStatuses.VERIFYING,
    value: 'Verifying'
  },
  [documentationStatuses.REJECTED]: {
    key: documentationStatuses.REJECTED,
    value: 'Rejected'
  },
  [documentationStatuses.VERIFIED]: {
    key: documentationStatuses.VERIFIED,
    value: 'Verified'
  }
}

export const columns = ({ REGIONS_OPTIONS }) => [
  {
    headerName: 'UID',
    width: 280,
    field: 'uid',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    cellRenderer: "LinkRenderer",
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'App. Id',
    width: 280,
    field: 'appointmentId',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Customer name',
    width: 280,
    field: 'customerName',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Ownership type',
    width: 280,
    field: 'ownershipType',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'ownershipType',
      options: Object.values(ownershipTypesMap)
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: Object.values(ownershipTypesMap)
    },
    suppressMenu: true
  },
  {
    headerName: 'Creation Date',
    field: 'createdOn',
    filter: 'agDateColumnFilter',
    floatingFilter: false,
    valueFormatter: function (params) {
      if (params.value) {
        return dayjs(params.value).format('DD MMM YYYY');
      } else {
        return "-";
      }
    },
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      color: 'red'
    },
    filterParams: {
      filterOptions: ['lessThanOrEqual'],
      browserDatePicker: true,
      applyButton: true,
      clearButton: true,
      suppressAndOrCondition: true,
      newRowsAction: 'keep'
    },
    suppressMenu: true,
    sortable: true,
  },
  {
    headerName: 'Vehicle Number',
    width: 280,
    field: 'vehicleNumber',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Iteration Tag',
    width: 280,
    field: 'iterationTag',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Documentation Status',
    width: 280,
    field: 'documentStatus',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'documentStatus',
      options: Object.values(documentationStatusesMap)
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: Object.values(documentationStatusesMap)
    },
    suppressMenu: true
  },
  {
    headerName: 'Assigned to',
    width: 280,
    field: 'assigneeEmail',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    coldId: "regionName",
    headerName: 'Region',
    width: 280,
    field: 'regionName',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'regionName',
      options: REGIONS_OPTIONS
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: REGIONS_OPTIONS
    },
    suppressMenu: true
  },
  {
    headerName: 'Action',
    width: 280,
    field: 'assigneeEmail',
    cellRenderer: "TmAssignCtaRenderer",
    cellRendererParams: {
      attributeKey: "assigneeEmail"
    }
  },
];