import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dealer from './component';

import { removeDealerDetailConnected } from '../dealer-form/actions';
import { fetchDealerGridInfoConnected } from './action';
const mapStateToProps = ({ store: { detail } }) => {
  return {
    detail
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeDealerDetailConnected,
      fetchDealerGridInfoConnected
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dealer);
