import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserGrid from './component';
import { fetchUserGridInfoConnected } from './actions';

const mapStateToProps = (state) => {
  const {
    detail,
    UserGridInfo: { userData }
  } = state;
  return {
    detail,
    userData
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUserGridInfoConnected
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserGrid);
