import React from "react";
import BookingDetails from "./booking-details";
import CustomerDetails from "./customer-details";
import DeliveryDetails from "./delivery-details";
import RCTransferDetails from "./rc-transfer-details";
import PaymentDetails from "./payment-details";
import FulfilmentDetails from "./fulfilment-details";
import BookingAssistDetails from "./booking-assist-details";
import BuyerProfiles from "./buyer-profiles";
const OutBoundBuyerDetails = ({
  buyerData,
  states,
  form,
  rules,
  isCCOBAssitBooking = false,
  orderData,
  setOrderData,
  pincodeData,
  setPincodeData,
  isBuyerCFEligible,
  setIsBuyerCFEligible,
  selectedPickupLocationIndex,
  setSelectedPickupLocationIndex,
  consumerFinanceData,
  isFinanceCustomization,
  setIsFinanceCustomization, 
  bookingData,
  setSelectBookingItem,
  selectBookingItem,
  setShowLoader,
  deliverySlots,
  setDeliverySlots,
  isFormDisabled,
  customerDetails
}) => {
    return (
      <React.Fragment>
        <BuyerProfiles customerDetails={customerDetails} />
        <CustomerDetails buyerData={buyerData} form={form} rules={rules} isFormDisabled={isFormDisabled} />
        <BookingAssistDetails buyerData={buyerData} form={form} bookingData={bookingData} setSelectBookingItem={setSelectBookingItem} 
        selectBookingItem={selectBookingItem} orderData={orderData} setShowLoader={setShowLoader} isCCOBAssitBooking={isCCOBAssitBooking} 
        isFormDisabled={isFormDisabled} />
        <PaymentDetails buyerData={buyerData} form={form} rules={rules} orderData={orderData}
          setOrderData={setOrderData} isBuyerCFEligible={isBuyerCFEligible} setIsBuyerCFEligible={setIsBuyerCFEligible}
          consumerFinanceData={consumerFinanceData} isFinanceCustomization={isFinanceCustomization} 
          setIsFinanceCustomization={setIsFinanceCustomization} isFormDisabled={isFormDisabled}/>
        <DeliveryDetails buyerData={buyerData} form={form} states={states} rules={rules} pincodeData={pincodeData} setPincodeData={setPincodeData}
          is_booking_assist_buyer= {isCCOBAssitBooking || false} selectedPickupLocationIndex={selectedPickupLocationIndex} 
          setSelectedPickupLocationIndex={setSelectedPickupLocationIndex} deliverySlots={deliverySlots} setDeliverySlots={setDeliverySlots} 
          isFormDisabled={isFormDisabled}
        />
      </React.Fragment>
    );
};

export default OutBoundBuyerDetails;
