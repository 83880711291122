import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RegionAllocation from "./component";
import { fetchAgents, updateAgentRegions } from "./actions";

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchAgentsConnect: fetchAgents,
  updateAgentRegionsConnect: updateAgentRegions,
}, dispatch);

export default connect(null, mapDispatchToProps)(RegionAllocation);