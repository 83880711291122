import React, { useEffect, useMemo } from 'react';
import { DOCKET_MANAGEMENT_COLUMN_KEYS, columns } from '../../config/docket-management-columns';
import { DocketManagementView } from './component-view';
import { useDockerManagement } from '../../custom-hooks/docket-management-hook';

export const DocketManagement = ({
  dealId,
  docketData,
  diyDocumentList,
  adminDocumentList,
  fetchDocketManagementFormDataConnect,
  fetchDocketManagementDocsConnect,
  updateDocketManagementDataConnect,
  uploadDocketManagementDiyDocConnect,
  updateDocketManagementFieldDataConnect,
  updateDocketManagementDiyDocDataConnect,
  updateDocketManagementAdminDocDataConnect,
  getDocketManagementDiyDocConnect,
}) => {
  const { statusDropdownOptions, rejectionReasonsByDocument } = useDockerManagement();
  const rowData = useMemo(() => {
    return Object.values(diyDocumentList.byId)
            .concat(
              Object.values(adminDocumentList.byId)
                .map(o => ({ ...o, isAdminDoc: true }))
            )
  }, [diyDocumentList.byId, adminDocumentList.byId]);

  const handleInputEdit = (value, name) => {
    updateDocketManagementFieldDataConnect({
      key: name,
      value
    })
  };

  const fetchDocketManagementData = () => {
    fetchDocketManagementFormDataConnect({ dealId });
  };

  const handleSave = () => {
    const payload = {
      ...docketData,
      actionSteps: ["DOCKET_UPDATE"],
      diyDocumentList: Object.values(diyDocumentList.byId),
      adminDocumentList: Object.values(adminDocumentList.byId)
    }
    updateDocketManagementDataConnect({ dealId }, payload)
      .then(() => {
        fetchDocketManagementData();
        fetchDocketManagementDocsConnect({ dealId });
      });
  };

  const handleMultiSelectChange = (attributeKey, data, docData) => {
    let value = Array.isArray(data) ? data.map(val => val.value) : data.value;
    const payload = { id: docData.documentName, key: attributeKey, value };
    const updateFunction = docData.isAdminDoc ? updateDocketManagementAdminDocDataConnect : updateDocketManagementDiyDocDataConnect;
    updateFunction(payload);
  };

  const handleUploadFile = (file, data, attributeKey) => {
    const formData = new FormData();
    formData.append('file', file);
    uploadDocketManagementDiyDocConnect(
      { 
        dealId,
        documentName: data[DOCKET_MANAGEMENT_COLUMN_KEYS.DOCUMENT_NAME],
        documentSource: data.documentSource,
      }, 
      formData
    )
      .then((resp) => {
        const updateFunction = data.isAdminDoc ? updateDocketManagementAdminDocDataConnect : updateDocketManagementDiyDocDataConnect;
        updateFunction({ id: data.documentName, key: attributeKey, value: resp.fileName });
        updateFunction({ id: data.documentName, key: "id", value: resp.id });
        updateFunction({ id: data.documentName, key: "status", value: resp.status });
        updateFunction({ id: data.documentName, key: "remarks", value: resp.remarks });
      })
  };

  const handleView = (data) => {
    getDocketManagementDiyDocConnect(data.id)
      .then((resp) => {
        const { fileUrl } = resp;
        window.open(fileUrl);
      })
  };

  useEffect(() => {
    fetchDocketManagementData();
  }, []);

  useEffect(() => {
    if (docketData.formSubmitted) {
      fetchDocketManagementDocsConnect({ dealId });
    }
  }, [docketData && docketData.formSubmitted])

  const columnDefs = useMemo(() => columns({
    statusDropdownOptions,
    rejectionReasonsByDocument,
    handleMultiSelectChange,
    handleUploadFile,
    handleView,
  }), [
    statusDropdownOptions,
    rejectionReasonsByDocument,
    handleMultiSelectChange,
    handleUploadFile,
    handleView,
  ]);

  return (
    <DocketManagementView
      docketData={docketData}
      rowData={rowData}
      columnDefs={columnDefs}
      handleInputEdit={handleInputEdit}
      handleSave={handleSave}
    />
  )
}