export const FILTER_API_KEYS = {
    manager: {key: 'reporting_manager_of_buddy', type: 'array', label: 'TM Name', optionKey: 'tm_emails', isMulti: true},
    city_id: {key: 'city_id', type: 'array', label: 'City', optionKey: 'city_list', isMulti: true},
    state_id: {key: 'state_id', type: 'array', label: 'State', optionKey: 'state_list', isMulti: true},
    car_source: {key: 'car_source', type: 'array', label: 'Car Source', optionKey: 'car_source', isMulti: true},
    rc_verification_status: {key: 'rc_verification_status', type: 'array', label: 'RC verification status', optionKey: 'rc_verification_status', isMulti: true},
    agent_id: {key: 'agent_id', type: 'array', label: 'Agent', optionKey: 'agent_list', isMulti: true},
    delivery_date: {key: 'delivery_date', type: 'datepicker', label: '', optionKey: '', isMulti: false},
    delivery_date_operator: {key: 'delivery_date_operator', type: 'array', label: 'Delivery Date', optionKey: 'date_operators', isMulti: false},
    file_completion_date: {key: 'file_completion_date', type: 'datepicker', label: '', optionKey: '', isMulti: false},
    file_completion_date_operator: {key: 'file_completion_date_operator', type: 'array', label: 'File Completion Date', optionKey: 'date_operators', isMulti: false},

    token_date: {key: 'token_date', type: 'datepicker', label: '', optionKey: '', isMulti: false},
    token_date_operator: {key: 'token_date_operator', type: 'array', label: 'Token Date', optionKey: 'date_operators', isMulti: false},
}

export const FILTER_SETS = [
    [FILTER_API_KEYS.manager],
    [FILTER_API_KEYS.city_id, FILTER_API_KEYS.state_id],
    [FILTER_API_KEYS.car_source, FILTER_API_KEYS.agent_id],
    [FILTER_API_KEYS.rc_verification_status],
    [FILTER_API_KEYS.delivery_date_operator, FILTER_API_KEYS.delivery_date],
    [FILTER_API_KEYS.file_completion_date_operator, FILTER_API_KEYS.file_completion_date],
    [FILTER_API_KEYS.token_date_operator, FILTER_API_KEYS.token_date],
]

export const PAYLOAD = {
    "filter_data": {
        "reporting_manager_of_buddy": [],
        "city_id": [],
        "state_id": [],
        "car_source": [],
        "rc_verification_status": [],
        "agent_id": [],
        "delivery_date": "",
        "delivery_date_operator": "",
        "file_completion_date": "",
        "file_completion_date_operator": ""
    },
    "filter_type": "RC_MANAGEMENT"
}
