import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import './styles.css';

import UploadIcon from './images/upload-icon.svg';
import DownloadIcon from './images/download-icon.svg';
import ReplaceIcon from './images/replace.svg';
import { Spinner } from 'reactstrap';

const UploadButton = ({
  id = 'file',
  name = 'file',
  onChangeHandler,
  accept,
  fileType = [],
  size,
  isDisabled,
  label = '',
  btnIcon,
  iconAlt,
  isLoading = false,
  className = ''
}) => {
  const uploadRef = useRef();

  const onChange = (e) => {
    let file = e.target.files[0];
    if (file && validateSize(e) && validateType(file, e.target.value)) {
      onChangeHandler(file, e);
      toast.dismiss();
      e.target.value = '';
    } else {
      toast.error(`Allowed file types ${fileType.join(', ')}`, {
        hideProgressBar: true,
        autoClose: 10000
      });
      e.target.value = '';
    }
  };

  const validateType = (file, filePath) => {
    if (file && Array.isArray(fileType) && fileType.length > 0) {
      const fileExtension = filePath.substring(filePath.lastIndexOf('.') + 1);
      return fileType.indexOf(fileExtension) === -1 ? false : true;
    } else {
      return true;
    }
  };

  const validateSize = (event) => {
    let file = event.target.files[0];
    let err = '';
    if (file && file.size > size) {
      err = 'file is too large, please pick a smaller file\n';
      alert(err);
      return false;
    }
    return true;
  };

  return (
    <div className='upload-btn-wrapper'>
      <button className={`btn btn-primary ${className}`} disabled={isDisabled}>
        {label}
        {'   '}
        {isLoading ? (
          <Spinner size='sm' color='light' />
        ) : (
          <i className='ml10'>
            <img src={btnIcon} alt={iconAlt} />
          </i>
        )}
      </button>
      <input
        ref={uploadRef}
        type='file'
        name={name}
        // id={id}
        onChange={onChange}
        accept={accept}
        disabled={isDisabled}
      />
    </div>
  );
};

export default UploadButton;
export { UploadIcon, DownloadIcon, ReplaceIcon };
