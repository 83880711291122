const SERVICE_DETAIL_KEYS = {
  LEARNING_LICENCE: "LEARNING_LICENCE",
  OFFLINE_CHALLAN: "OFFLINE_CHALLAN",
  ONLINE_CHALLAN: "ONLINE_CHALLAN",
  HSRP: "HSRP",
  PUC: "PUC",
  RC_TRANSFER_SERVICE: "RC_TRANSFER_SERVICE",
  VEHICLE_MIGRATION: "VEHICLE_MIGRATION",
  NEW_DL: "NEW_DL",
  DL_RENEWAL: "DL_RENEWAL",
  HPT: "HPT",
  HPA: "HPA",
  RE_REGISTRATION: "REREGISTRATION",
  GREEN_TAX: "GREEN_TAX",
  FITNESS_EXTENSION: "FITNESS_EXTENSION",
  TAX_PAYMENT: "TAX_PAYMENT",
  RTO_NOC: "RTO_NOC",
  NOC_CANCELLATION: "NOC_CANCELLATION",
  VEHICLE_REGISTRATION: "VEHICLE_REGISTRATION",
  BLACKLIST_AND_THEFT: "BLACKLIST_AND_THEFT",
  DL_MIGRATION: "DL_MIGRATION",
  CORRECTION: "CORRECTION_TYPE",
  CNG: "CNG",
}

export const RC_OTHERS_TRACKER_FORM_KEYS = {
  SERVICE_STATUS: "serviceStatus",
  RTO_TEAM_HANDOVER_DATE: "rtoTeamHandoverDate",
  EXPECTED_COMPLETION_DATE: "expectedCompletionDate",
  AGENT_HANDOVER_DATE: "agentHandoverDate",
  AGENT_HANDOVER_DELAY: "agentHandoverDelay",
  OWNER_PP_SCHEDULED_DATE: "ownerPpScheduledDate",
  OWNER_PP_COMPLETION_DATE: "ownerPpCompletionDate",
  OWNER_PARTY_PESHI_DELAY: "ownerPartyPeshiDelay",
  BUYER_PP_SCHEDULED_DATE: "buyerPpScheduledDate",
  BUYER_PP_COMPLETION_DATE: "buyerPpCompletionDate",
  BUYER_PARTY_PESHI_DELAY: "buyerPartyPeshiDelay",
  LEARNING_LICENCE_UPLOAD: "learningLicenceUpload",
  LEARNING_LICENCE_ISSUED_DATE: "learningLicenceIssuedDate",
  LEARNING_LICENCE_DELAY: "learningLicenceDelay",
  DRIVING_TEST_COMPLETION_DATE: "drivingTestCompletionDate",
  DRIVING_TEST_DELAY: "drivingTestDelay",
  APPLICANT_PHOTOGRAPH_DATE: "applicantPhotographDate",
  APPLICANT_PHOTOGRAPH_DELAY: "applicantPhotographDelay",
  ONLINE_TEST_COMPLETION_DATE: "onlineTestCompletionDate",
  HSRP_INSTALLATION_SCHEDULED_DATE: "hsrpInstallationScheduledDate",
  AADHAR_CHANGE_STATUS: "aadharChangeStatus",
  ADHAR_CHANGE_COMPLETION_DATE: "adharChangeCompletionDate",
  AADHAAR_UPDATE_DELAY: "aadhaarUpdateDelay",
  E_RECIPT_UPLOAD: "eReceiptUpload",
  E_RECEIPT_DATE: "eReceiptDate",
  E_RECEIPT_DELAY: "eReceiptDelay",
  RTO_NOC_UPLOAD: "rtoNocUplaod",
  RTO_NOC_ISSUE_DATE: "rtoNocIssueDate",
  RTO_NOC_DELAY: "rtoNocDelay",
  SERVICE_PROOF_UPLOAD: "serviceProofUpload",
  SERVICE_COMPLETION_DATE: "serviceCompletionDate",
  PHYSICAL_DOC_UPLOAD: "physicalDocUpload",
  PHYSICAL_RC_RECEIVING_DELAY: "physicalRcReceivingDelay",
  PHYSICAL_DOC_UPLOAD_DATE: "physicalDocUploadDate",
  PHYSICAL_DOC_COURIER_ADDRESS: "physicalDocCourierAddress",
  PHYSICAL_DOC_HANDOVER_METHOD: "physicalDocHandoverMethod",
  PHYSICAL_DOC_HANDOVER_STATUS: "physicalDocHandoverStatus",
  PHYSICAL_DOC_HANDOVER_DATE: "physicalDocHandoverDate",
  PHYSICAL_RC_HANDOVER_DELAY: "physicalRcHandoverDelay",
  TRACKING_ID: "trackingId",
  DISPATCH_LINK: "dispatchLink",
};

export const RC_OTHERS_TRACKER_METADATA = ({
  serviceStatusDropdownOptions = [],
  aadharStatusDropdownOptions = [],
  handoverMethodDropdownOptions = [],
  handoverStatusDropdownOptions = [],
  dispatchLinkDropdownOptions = [],
  serviceDetail,
}) => [
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.SERVICE_STATUS,
    label: "Service Status",
    fieldType: "dropdown",
    getDropdownOptions: () => serviceStatusDropdownOptions,
    isVisible: () => true,
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.RTO_TEAM_HANDOVER_DATE,
    label: "RTO Team Handover Date",
    fieldType: "date",
    disabled: false,
    isVisible: () => true,
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.EXPECTED_COMPLETION_DATE,
    label: "Expected Completion Date",
    fieldType: "date",
    disabled: false,
    isVisible: () => true,
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.AGENT_HANDOVER_DATE,
    label: "Agent Handover Date",
    fieldType: "date",
    disabled: false,
    isVisible: () => true,
    isValid: () => true,
    isRequired: () => false,
    shouldStartFromNewRow: true,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.AGENT_HANDOVER_DELAY,
    label: "Agent handover Delay",
    fieldType: "delay_dropdown",
    getDropdownOptions: () => [],
    isVisible: () => true,
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.OWNER_PP_SCHEDULED_DATE,
    label: "Owner PP Scheduled Date",
    fieldType: "date",
    disabled: false,
    isVisible: () => {
      return ![
        SERVICE_DETAIL_KEYS.LEARNING_LICENCE,
        SERVICE_DETAIL_KEYS.OFFLINE_CHALLAN,
        SERVICE_DETAIL_KEYS.ONLINE_CHALLAN,
        SERVICE_DETAIL_KEYS.HSRP,
        SERVICE_DETAIL_KEYS.PUC,
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.OWNER_PP_COMPLETION_DATE,
    label: "Owner PP Completion Date",
    fieldType: "date",
    disabled: false,
    isVisible: () => {
      return ![
        SERVICE_DETAIL_KEYS.LEARNING_LICENCE,
        SERVICE_DETAIL_KEYS.OFFLINE_CHALLAN,
        SERVICE_DETAIL_KEYS.ONLINE_CHALLAN,
        SERVICE_DETAIL_KEYS.HSRP,
        SERVICE_DETAIL_KEYS.PUC,
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
    shouldStartFromNewRow: true,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.OWNER_PARTY_PESHI_DELAY,
    label: "Owner Party Peshi Delay",
    fieldType: "delay_dropdown",
    getDropdownOptions: () => [],
    isVisible: () => {
      return ![
        SERVICE_DETAIL_KEYS.LEARNING_LICENCE,
        SERVICE_DETAIL_KEYS.OFFLINE_CHALLAN,
        SERVICE_DETAIL_KEYS.ONLINE_CHALLAN,
        SERVICE_DETAIL_KEYS.HSRP,
        SERVICE_DETAIL_KEYS.PUC,
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.BUYER_PP_SCHEDULED_DATE,
    label: "Buyer PP Scheduled Date",
    fieldType: "date",
    disabled: false,
    isVisible: () => {
      return [
        SERVICE_DETAIL_KEYS.RC_TRANSFER_SERVICE,
        SERVICE_DETAIL_KEYS.VEHICLE_MIGRATION,
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.BUYER_PP_COMPLETION_DATE,
    label: "Buyer PP Completion Date",
    fieldType: "date",
    disabled: false,
    isVisible: () => {
      return [
        SERVICE_DETAIL_KEYS.RC_TRANSFER_SERVICE,
        SERVICE_DETAIL_KEYS.VEHICLE_MIGRATION,
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
    shouldStartFromNewRow: true,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.BUYER_PARTY_PESHI_DELAY,
    label: "Buyer Party Peshi Delay",
    fieldType: "delay_dropdown",
    getDropdownOptions: () => [],
    isVisible: () => {
      return [
        SERVICE_DETAIL_KEYS.RC_TRANSFER_SERVICE,
        SERVICE_DETAIL_KEYS.VEHICLE_MIGRATION,
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.LEARNING_LICENCE_UPLOAD,
    label: "Learning Licence Upload",
    fieldType: "upload",
    isVisible: () => {
      return [
        SERVICE_DETAIL_KEYS.NEW_DL,
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.LEARNING_LICENCE_ISSUED_DATE,
    label: "Learning Licence Issue Date",
    fieldType: "date",
    disabled: false,
    isVisible: () => {
      return [
        SERVICE_DETAIL_KEYS.NEW_DL,
        SERVICE_DETAIL_KEYS.LEARNING_LICENCE
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
    shouldStartFromNewRow: true,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.LEARNING_LICENCE_DELAY,
    label: "Learning Licence Delay",
    fieldType: "delay_dropdown",
    getDropdownOptions: () => [],
    isVisible: () => {
      return [
        SERVICE_DETAIL_KEYS.NEW_DL,
        SERVICE_DETAIL_KEYS.LEARNING_LICENCE
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.DRIVING_TEST_COMPLETION_DATE,
    label: "Driving Test Completion Date",
    fieldType: "date",
    disabled: false,
    isVisible: () => {
      return [
        SERVICE_DETAIL_KEYS.NEW_DL,
        SERVICE_DETAIL_KEYS.DL_RENEWAL
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
    shouldStartFromNewRow: true,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.DRIVING_TEST_DELAY,
    label: "Driving Test Delay",
    fieldType: "delay_dropdown",
    getDropdownOptions: () => [],
    isVisible: () => {
      return [
        SERVICE_DETAIL_KEYS.NEW_DL,
        SERVICE_DETAIL_KEYS.DL_RENEWAL
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.APPLICANT_PHOTOGRAPH_DATE,
    label: "Applicant Photograph Date",
    fieldType: "date",
    isVisible: () => {
      return [
        SERVICE_DETAIL_KEYS.NEW_DL,
        SERVICE_DETAIL_KEYS.DL_RENEWAL
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
    shouldStartFromNewRow: true,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.APPLICANT_PHOTOGRAPH_DELAY,
    label: "Applicant Photograph Delay",
    fieldType: "delay_dropdown",
    getDropdownOptions: () => [],
    isVisible: () => {
      return [
        SERVICE_DETAIL_KEYS.NEW_DL,
        SERVICE_DETAIL_KEYS.DL_RENEWAL
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.ONLINE_TEST_COMPLETION_DATE,
    label: "Online Test Completion Date",
    fieldType: "date",
    isVisible: () => {
      return [
        SERVICE_DETAIL_KEYS.NEW_DL,
        SERVICE_DETAIL_KEYS.LEARNING_LICENCE
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.HSRP_INSTALLATION_SCHEDULED_DATE,
    label: "HSRP Installation Completion Date",
    fieldType: "date",
    isVisible: () => {
      return [
        SERVICE_DETAIL_KEYS.HSRP,
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.AADHAR_CHANGE_STATUS,
    label: "Aadhar Change Status",
    fieldType: "dropdown",
    getDropdownOptions: () => aadharStatusDropdownOptions,
    isVisible: () => {
      return [
        SERVICE_DETAIL_KEYS.RC_TRANSFER_SERVICE,
        SERVICE_DETAIL_KEYS.HPT,
        SERVICE_DETAIL_KEYS.HPA,
        SERVICE_DETAIL_KEYS.RE_REGISTRATION,
        SERVICE_DETAIL_KEYS.GREEN_TAX,
        SERVICE_DETAIL_KEYS.FITNESS_EXTENSION,
        SERVICE_DETAIL_KEYS.TAX_PAYMENT,
        SERVICE_DETAIL_KEYS.RTO_NOC,
        SERVICE_DETAIL_KEYS.NOC_CANCELLATION,
        SERVICE_DETAIL_KEYS.VEHICLE_REGISTRATION,
        SERVICE_DETAIL_KEYS.VEHICLE_MIGRATION,
        SERVICE_DETAIL_KEYS.BLACKLIST_AND_THEFT,
        SERVICE_DETAIL_KEYS.NEW_DL,
        SERVICE_DETAIL_KEYS.DL_MIGRATION,
        SERVICE_DETAIL_KEYS.LEARNING_LICENCE,
        SERVICE_DETAIL_KEYS.CORRECTION,
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.ADHAR_CHANGE_COMPLETION_DATE,
    label: "Aadhar Change Completion Date",
    fieldType: "date",
    disabled: false,
    isVisible: () => {
      return [
        SERVICE_DETAIL_KEYS.RC_TRANSFER_SERVICE,
        SERVICE_DETAIL_KEYS.HPT,
        SERVICE_DETAIL_KEYS.HPA,
        SERVICE_DETAIL_KEYS.RE_REGISTRATION,
        SERVICE_DETAIL_KEYS.GREEN_TAX,
        SERVICE_DETAIL_KEYS.FITNESS_EXTENSION,
        SERVICE_DETAIL_KEYS.TAX_PAYMENT,
        SERVICE_DETAIL_KEYS.RTO_NOC,
        SERVICE_DETAIL_KEYS.NOC_CANCELLATION,
        SERVICE_DETAIL_KEYS.VEHICLE_REGISTRATION,
        SERVICE_DETAIL_KEYS.VEHICLE_MIGRATION,
        SERVICE_DETAIL_KEYS.BLACKLIST_AND_THEFT,
        SERVICE_DETAIL_KEYS.NEW_DL,
        SERVICE_DETAIL_KEYS.DL_MIGRATION,
        SERVICE_DETAIL_KEYS.LEARNING_LICENCE,
        SERVICE_DETAIL_KEYS.CORRECTION,
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
    shouldStartFromNewRow: true,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.AADHAAR_UPDATE_DELAY,
    label: "Aadhaar Update Delay",
    fieldType: "delay_dropdown",
    getDropdownOptions: () => [],
    isVisible: () => {
      return [
        SERVICE_DETAIL_KEYS.RC_TRANSFER_SERVICE,
        SERVICE_DETAIL_KEYS.HPT,
        SERVICE_DETAIL_KEYS.HPA,
        SERVICE_DETAIL_KEYS.RE_REGISTRATION,
        SERVICE_DETAIL_KEYS.GREEN_TAX,
        SERVICE_DETAIL_KEYS.FITNESS_EXTENSION,
        SERVICE_DETAIL_KEYS.TAX_PAYMENT,
        SERVICE_DETAIL_KEYS.RTO_NOC,
        SERVICE_DETAIL_KEYS.NOC_CANCELLATION,
        SERVICE_DETAIL_KEYS.VEHICLE_REGISTRATION,
        SERVICE_DETAIL_KEYS.VEHICLE_MIGRATION,
        SERVICE_DETAIL_KEYS.BLACKLIST_AND_THEFT,
        SERVICE_DETAIL_KEYS.NEW_DL,
        SERVICE_DETAIL_KEYS.DL_MIGRATION,
        SERVICE_DETAIL_KEYS.LEARNING_LICENCE,
        SERVICE_DETAIL_KEYS.CORRECTION,
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.E_RECIPT_UPLOAD,
    label: "E-Receipt Upload",
    fieldType: "upload",
    isVisible: () => {
      return [
        SERVICE_DETAIL_KEYS.RC_TRANSFER_SERVICE,
        SERVICE_DETAIL_KEYS.HPT,
        SERVICE_DETAIL_KEYS.HPA,
        SERVICE_DETAIL_KEYS.RE_REGISTRATION,
        SERVICE_DETAIL_KEYS.GREEN_TAX,
        SERVICE_DETAIL_KEYS.FITNESS_EXTENSION,
        SERVICE_DETAIL_KEYS.TAX_PAYMENT,
        SERVICE_DETAIL_KEYS.RTO_NOC,
        SERVICE_DETAIL_KEYS.NOC_CANCELLATION,
        SERVICE_DETAIL_KEYS.VEHICLE_REGISTRATION,
        SERVICE_DETAIL_KEYS.VEHICLE_MIGRATION,
        SERVICE_DETAIL_KEYS.BLACKLIST_AND_THEFT,
        SERVICE_DETAIL_KEYS.NEW_DL,
        SERVICE_DETAIL_KEYS.DL_MIGRATION,
        SERVICE_DETAIL_KEYS.LEARNING_LICENCE,
        SERVICE_DETAIL_KEYS.CORRECTION,
        SERVICE_DETAIL_KEYS.DL_RENEWAL,
        SERVICE_DETAIL_KEYS.OFFLINE_CHALLAN,
        SERVICE_DETAIL_KEYS.ONLINE_CHALLAN,
        SERVICE_DETAIL_KEYS.HSRP,
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.E_RECEIPT_DATE,
    label: "E-Receipt Date",
    fieldType: "date",
    disabled: false,
    isVisible: () => {
      return [
        SERVICE_DETAIL_KEYS.RC_TRANSFER_SERVICE,
        SERVICE_DETAIL_KEYS.HPT,
        SERVICE_DETAIL_KEYS.HPA,
        SERVICE_DETAIL_KEYS.RE_REGISTRATION,
        SERVICE_DETAIL_KEYS.GREEN_TAX,
        SERVICE_DETAIL_KEYS.FITNESS_EXTENSION,
        SERVICE_DETAIL_KEYS.TAX_PAYMENT,
        SERVICE_DETAIL_KEYS.RTO_NOC,
        SERVICE_DETAIL_KEYS.NOC_CANCELLATION,
        SERVICE_DETAIL_KEYS.VEHICLE_REGISTRATION,
        SERVICE_DETAIL_KEYS.VEHICLE_MIGRATION,
        SERVICE_DETAIL_KEYS.BLACKLIST_AND_THEFT,
        SERVICE_DETAIL_KEYS.NEW_DL,
        SERVICE_DETAIL_KEYS.DL_MIGRATION,
        SERVICE_DETAIL_KEYS.LEARNING_LICENCE,
        SERVICE_DETAIL_KEYS.CORRECTION,
        SERVICE_DETAIL_KEYS.DL_RENEWAL,
        SERVICE_DETAIL_KEYS.OFFLINE_CHALLAN,
        SERVICE_DETAIL_KEYS.ONLINE_CHALLAN,
        SERVICE_DETAIL_KEYS.HSRP,
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
    shouldStartFromNewRow: true,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.E_RECEIPT_DELAY,
    label: "E-Receipt Delay",
    fieldType: "delay_dropdown",
    getDropdownOptions: () => [],
    isVisible: () => {
      return [
        SERVICE_DETAIL_KEYS.RC_TRANSFER_SERVICE,
        SERVICE_DETAIL_KEYS.HPT,
        SERVICE_DETAIL_KEYS.HPA,
        SERVICE_DETAIL_KEYS.RE_REGISTRATION,
        SERVICE_DETAIL_KEYS.GREEN_TAX,
        SERVICE_DETAIL_KEYS.FITNESS_EXTENSION,
        SERVICE_DETAIL_KEYS.TAX_PAYMENT,
        SERVICE_DETAIL_KEYS.RTO_NOC,
        SERVICE_DETAIL_KEYS.NOC_CANCELLATION,
        SERVICE_DETAIL_KEYS.VEHICLE_REGISTRATION,
        SERVICE_DETAIL_KEYS.VEHICLE_MIGRATION,
        SERVICE_DETAIL_KEYS.BLACKLIST_AND_THEFT,
        SERVICE_DETAIL_KEYS.NEW_DL,
        SERVICE_DETAIL_KEYS.DL_MIGRATION,
        SERVICE_DETAIL_KEYS.LEARNING_LICENCE,
        SERVICE_DETAIL_KEYS.CORRECTION,
        SERVICE_DETAIL_KEYS.DL_RENEWAL,
        SERVICE_DETAIL_KEYS.OFFLINE_CHALLAN,
        SERVICE_DETAIL_KEYS.ONLINE_CHALLAN,
        SERVICE_DETAIL_KEYS.HSRP,
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: RC_OTHERS_TRACKER_FORM_KEYS.RTO_NOC_UPLOAD,
    label: "RTO NOC Upload",
    fieldType: "upload",
    isVisible: () => {
      return [
        SERVICE_DETAIL_KEYS.RC_TRANSFER_SERVICE,
        SERVICE_DETAIL_KEYS.VEHICLE_MIGRATION,
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: [RC_OTHERS_TRACKER_FORM_KEYS.RTO_NOC_ISSUE_DATE],
    label: "RTO NOC Issue Date",
    fieldType: "date",
    disabled: false,
    isVisible: () => {
      return [
        SERVICE_DETAIL_KEYS.RC_TRANSFER_SERVICE,
        SERVICE_DETAIL_KEYS.VEHICLE_MIGRATION,
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
    shouldStartFromNewRow: true,
  },
  {
    key: [RC_OTHERS_TRACKER_FORM_KEYS.RTO_NOC_DELAY],
    label: "RTO NOC Delay",
    fieldType: "delay_dropdown",
    getDropdownOptions: () => [],
    isVisible: () => {
      return [
        SERVICE_DETAIL_KEYS.RC_TRANSFER_SERVICE,
        SERVICE_DETAIL_KEYS.VEHICLE_MIGRATION,
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: [RC_OTHERS_TRACKER_FORM_KEYS.SERVICE_PROOF_UPLOAD],
    label: "Service Proof Upload",
    fieldType: "upload",
    isVisible: () => {
      return ![
        SERVICE_DETAIL_KEYS.LEARNING_LICENCE,
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: [RC_OTHERS_TRACKER_FORM_KEYS.SERVICE_COMPLETION_DATE],
    label: "Service Completion Date",
    fieldType: "date",
    disabled: false,
    isVisible: () => {
      return ![
        SERVICE_DETAIL_KEYS.LEARNING_LICENCE,
      ].includes(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: [RC_OTHERS_TRACKER_FORM_KEYS.PHYSICAL_DOC_UPLOAD],
    label: "Physical Doc Upload",
    fieldType: "upload",
    isVisible: () => {
      return isPhysicalDocFieldVisible(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
    shouldStartFromNewRow: true,
  },
  {
    key: [RC_OTHERS_TRACKER_FORM_KEYS.PHYSICAL_RC_RECEIVING_DELAY],
    label: "Physical RC receiving Delay",
    fieldType: "delay_dropdown",
    getDropdownOptions: () => [],
    isVisible: () => {
      return isPhysicalDocFieldVisible(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: [RC_OTHERS_TRACKER_FORM_KEYS.PHYSICAL_DOC_UPLOAD_DATE],
    label: "Physical Doc Upload Date",
    fieldType: "date",
    disabled: false,
    isVisible: () => {
      return isPhysicalDocFieldVisible(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: [RC_OTHERS_TRACKER_FORM_KEYS.PHYSICAL_DOC_COURIER_ADDRESS],
    label: "Physical Doc Courier Address",
    fieldType: "textarea",
    disabled: false,
    isVisible: () => {
      return isPhysicalDocFieldVisible(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: [RC_OTHERS_TRACKER_FORM_KEYS.PHYSICAL_DOC_HANDOVER_METHOD],
    label: "Physical Doc Handover Method",
    fieldType: "dropdown",
    getDropdownOptions: () => handoverMethodDropdownOptions,
    isVisible: () => {
      return isPhysicalDocFieldVisible(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: [RC_OTHERS_TRACKER_FORM_KEYS.PHYSICAL_DOC_HANDOVER_STATUS],
    label: "Physical Doc Handover Status",
    fieldType: "dropdown",
    getDropdownOptions: () => handoverStatusDropdownOptions,
    isVisible: () => {
      return isPhysicalDocFieldVisible(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: [RC_OTHERS_TRACKER_FORM_KEYS.PHYSICAL_DOC_HANDOVER_DATE],
    label: "Physical Doc Handover Date",
    fieldType: "date",
    disabled: false,
    isVisible: () => {
      return isPhysicalDocFieldVisible(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
    shouldStartFromNewRow: true,
  },
  {
    key: [RC_OTHERS_TRACKER_FORM_KEYS.PHYSICAL_RC_HANDOVER_DELAY],
    label: "Physical RC Handover Delay",
    fieldType: "delay_dropdown",
    getDropdownOptions: () => [],
    isVisible: () => {
      return isPhysicalDocFieldVisible(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: [RC_OTHERS_TRACKER_FORM_KEYS.TRACKING_ID],
    label: "Tracking ID",
    fieldType: "text",
    isVisible: () => {
      return isPhysicalDocFieldVisible(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
  {
    key: [RC_OTHERS_TRACKER_FORM_KEYS.DISPATCH_LINK],
    label: "Dispatch Link",
    fieldType: "dropdown",
    getDropdownOptions: () => dispatchLinkDropdownOptions,
    isVisible: () => {
      return isPhysicalDocFieldVisible(serviceDetail);
    },
    isValid: () => true,
    isRequired: () => false,
  },
]

const isPhysicalDocFieldVisible = (serviceDetail) => {
  return [
    SERVICE_DETAIL_KEYS.RC_TRANSFER_SERVICE,
    SERVICE_DETAIL_KEYS.HPT,
    SERVICE_DETAIL_KEYS.HPA,
    SERVICE_DETAIL_KEYS.RE_REGISTRATION,
    SERVICE_DETAIL_KEYS.GREEN_TAX,
    SERVICE_DETAIL_KEYS.FITNESS_EXTENSION,
    SERVICE_DETAIL_KEYS.TAX_PAYMENT,
    SERVICE_DETAIL_KEYS.RTO_NOC,
    SERVICE_DETAIL_KEYS.NOC_CANCELLATION,
    SERVICE_DETAIL_KEYS.VEHICLE_REGISTRATION,
    SERVICE_DETAIL_KEYS.VEHICLE_MIGRATION,
    SERVICE_DETAIL_KEYS.NEW_DL,
    SERVICE_DETAIL_KEYS.DL_RENEWAL,
    SERVICE_DETAIL_KEYS.DL_MIGRATION,
    SERVICE_DETAIL_KEYS.CNG,
    SERVICE_DETAIL_KEYS.CORRECTION,
  ].includes(serviceDetail);
}