import Types from './types';
import { docketManagementService } from '../../../services';
import parseByIdV2 from '../../helper/parsers/parseByIdV2';
import { showToastMessages } from '../../../shared/utils/helper';

const filterDiyDocsWithFile = (diyDocumentList) => {
  // to filter out docs which are not uploaded by Sales Panel
  if (!diyDocumentList) return diyDocumentList;
  return diyDocumentList.filter(({ id }) => !!id);
};

export const fetchDocketManagementFormData = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    docketManagementService
      .fetchDocketManagementFormData(params)
      .then((data) => {
        dispatch(fetchDocketManagementFormDataSuccess(data));
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchDocketManagementDocs = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    docketManagementService
      .fetchDocketManagementDocs(params)
      .then((data) => {
        data = {
          ...data,
          diyDocumentList: parseByIdV2(filterDiyDocsWithFile(data.diyDocumentList), 'documentName'),
          adminDocumentList: parseByIdV2(data.adminDocumentList, 'documentName'),
        }
        dispatch(fetchDocketManagementDocsSuccess(data));
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateDocketManagementData = (params, body) => (dispatch) => {
  return new Promise((resolve, reject) => {
    docketManagementService
      .updateDocketManagementData(params, body)
      .then((resp) => {
        resolve(resp);
        showToastMessages(resp.message);
      })
      .catch((error) => {
        reject(error);
      })
  });
};

export const uploadDocketManagementDiyDoc = (params, body) => (dispatch) => {
  return new Promise((resolve, reject) => {
    docketManagementService
      .uploadDocketManagementDiyDoc(params, body)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      })
  });
};

export const updateDocketDocVisibility = (params) => () => {
  return new Promise((resolve, reject) => {
    docketManagementService
      .updateDocketDocVisibility(params)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      })
  });
};

export const getDocketManagementDiyDoc = (params) => () => {
  return new Promise((resolve, reject) => {
    docketManagementService
      .getDocketManagementDiyDoc(params)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      })
  });
};

const fetchDocketManagementFormDataSuccess = (data) => ({
  type: Types.FETCH_DOCKET_FORM_DATA_SUCCESS,
  data
});

const fetchDocketManagementDocsSuccess = (data) => ({
  type: Types.FETCH_DOCKET_DOCS_SUCCESS,
  data
});

export const updateDocketManagementDiyDocData = (data) => ({
  type: Types.UPDATE_DOCKET_DIY_DOC_DATA,
  data
});

export const updateDocketManagementAdminDocData = (data) => ({
  type: Types.UPDATE_DOCKET_ADMIN_DOC_DATA,
  data
});

export const updateDocketManagementFieldData = (data) => ({
  type: Types.UPDATE_DOCKET_FIELD_DATA,
  data
});

export const resetDocketManagementData = (data) => ({
  type: Types.RESET_DOCKET_DATA,
  data
});
