/* eslint-disable no-console */
import axios from 'axios';
import { UNAUTHORISED } from '../components/shared/Constants';
import { removeDataFromLocalStorage } from '../../shared/utils/helper';
import { getSessionToken } from '@descope/react-sdk';

const DEBUG = process.env.NODE_ENV === 'development';

let BASE_API_URL = 'https://sales-product-gateway.qac24svc.dev/c2c-lms/';
if (typeof window !== 'undefined') {
  switch (process.env.REACT_APP_ENV) {
    case 'local':
      BASE_API_URL = 'https://sales-product-gateway.qac24svc.dev/c2c-lms/';
      // BASE_API_URL = 'http://10.102.9.155:8080/';
      break;
    case 'qa':
      BASE_API_URL = 'https://sales-product-gateway.qac24svc.dev/c2c-lms/';
      break;
    case 'stage':
      // BASE_API_URL = 'https://c2c-lms-stage.cars24.team/';
      BASE_API_URL = 'https://stage-sales-product-gateway.qac24svc.dev/c2c-lms/'
      break;
    case 'prod':
      BASE_API_URL = 'https://sales-product-gateway.c24.tech/c2c-lms/';
      break;
    default:
      break;
  }
}

/* Create axios instance */
const api = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    // 'x-interaction-source' : getFromLocalStorage(APP_SOURCE),
    // 'x-phone' : getFromLocalStorage(BUYER_PRIMARY_PHONE)
    // 'User-Id': 2
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
  },
  // withCredentials: true
});

api.interceptors.request.use(
  (config) => {
    /** In dev, intercepts request and logs it into console for dev */
    if (DEBUG) {
      console.info('✉️ ', config);
    }
    config.headers["Authorization"] = `Bearer ${getSessionToken()}`;
    return config;
  },
  (error) => {
    if (DEBUG) {
      console.error('✉️ ', error);
    }
    return Promise.reject(error);
  }
);

/**
 * Passes response.data to services.
 * In dev, intercepts response and logs it into console for dev
 */
api.interceptors.response.use(
  (response) => {
    if (DEBUG) {
      console.info('📩 ', response);
    }
    return response;
  },
  (error) => {
    if (error.response) {
      if (DEBUG) {
        console.error('Error: ', error.response.data);
      }
      if (UNAUTHORISED === error.response.status) {
        // console.log('status', error.response.data.status, window.location);
        let return_url = encodeURIComponent(
          window.location.pathname + window.location.search
        );
        removeDataFromLocalStorage();
        if (!window.location.pathname.includes('login')) {
          return (window.location = `${
            window.origin
          }/login?return_url=${return_url}`);
        }
      }
      return Promise.reject(error.response.data);
    }
    if (DEBUG) {
      console.error('📩 ', error);
    }
    return Promise.reject(error.message);
  }
);

export default api;
