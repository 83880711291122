import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  FETCH_INSPECTION_DETAIL_INIT
  FETCH_INSPECTION_DETAIL_SUCCESS
  FETCH_INSPECTION_DETAIL_FAIL
`,
  {
    prefix: 'order-inspection-tab/'
  }
);
