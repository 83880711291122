import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  FETCH_PRICING_LOGS_REQUESTED
  FETCH_PRICING_LOGS_SUCCESS
  FETCH_PRICING_LOGS_FAILURE
  
  SUBMIT_PRICING_LOGS_REMARKS
`,
  {
    prefix: 'order-seller-pricing-logs/'
  }
);
