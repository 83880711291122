import React, { useEffect } from 'react';
import { Label, Input } from 'reactstrap';
import { SelectComponent } from '../../shared';
import { questionConstants } from '../../shared/Constants';
import { tvrQuestions } from './cfConstants';
import { onNumberKeyPress } from '../../../../shared/utils/helper';

const CFDetails = ({
  age, setAge,
  income, setIncome,
  pincode, setPincode,
  incomeSource, setIncomeSource,
  setProfession,
  setCoApplicant
}) => {

  useEffect(() => {
    return () => {
      setAge("");
      setIncome("");
      setPincode("");
      setIncomeSource("");
      setProfession("");
      setCoApplicant("")
    }
  }, []);

  useEffect(() => {
    setProfession("");
  }, [incomeSource]);

  const getOptionsList = key => tvrQuestions[key].options.map(({ id, value }) => ({ value: id, label: value }));

  const getSelectText = id => {
    let value = null;
    switch (id) {
      case 13:
        value = 'Profession';
        break;
      case 14:
        value = 'Industry'
        break;
      default:
        break;
    }
    return value;
  }

  return (
    <>
      <h2 className="title">Basic Details</h2>
      <div className="row">
        <div className="col-5 form-group disp-box">
          <Label for="age" className="lbl">Age:</Label>
        </div>
        <div className="col-7 form-group disp-box">
          <Input type="text" name="age" autoComplete="off" maxLength={3} className='text'
            onKeyPress={(e) => onNumberKeyPress(e)}
            onChange={(e) => !isNaN(e.target.value) && e.target.value ? setAge(Number(e.target.value)) : setAge("")}
            value={age}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-5 form-group disp-box">
          <Label for="income" className="lbl">Monthly Income:</Label>
        </div>
        <div className="col-7 form-group disp-box">
          <Input type="text" name="income" autoComplete="off" maxLength={9} className='text'
            onKeyPress={(e) => onNumberKeyPress(e)}
            onChange={(e) => !isNaN(e.target.value) && e.target.value ? setIncome(Number(e.target.value)) : setIncome("")}
            value={income}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-5 form-group disp-box">
          <Label for="pincode" className="lbl">Current Residence Pincode:</Label>
        </div>
        <div className="col-7 form-group disp-box">
          <Input type="text" name="pincode" autoComplete="off" maxLength={6} className='text'
            onKeyPress={(e) => onNumberKeyPress(e)}
            onChange={(e) => !isNaN(e.target.value) && e.target.value ? setPincode(Number(e.target.value)) : setPincode("")}
            value={pincode}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-5 form-group disp-box">
          <Label for="incomeSource" className="lbl">Source of Income:</Label>
        </div>
        <div className="col-7 form-group disp-box">
          <SelectComponent name="incomeSource"
            onChange={(value) => setIncomeSource(value)}
            optionsList={getOptionsList(questionConstants.SOURCE_OF_INCOME)}
          />
        </div>
      </div>
      {
        getSelectText(incomeSource) !== null &&
        <div className="row">
          <div className="col-5 form-group disp-box">
            <Label for={getSelectText(incomeSource).toLowerCase()} className="lbl">{getSelectText(incomeSource)}</Label>
          </div>
          <div className="col-7 form-group disp-box">
            <SelectComponent name={getSelectText(incomeSource).toLowerCase()}
              onChange={(value) => setProfession(value)}
              optionsList={getOptionsList(questionConstants.OCCUPATION)}
            />
          </div>
        </div>
      }
      <div className="row">
        <div className="col-5 form-group disp-box">
          <Label for="coApplicant" className="lbl">Can you provide Co-Applicant if Required:</Label>
        </div>
        <div className="col-7 form-group disp-box">
          <SelectComponent name="coApplicant"
            onChange={(value) => setCoApplicant(value)}
            optionsList={getOptionsList(questionConstants.CO_APPLICANT)}
          />
        </div>
      </div>
    </>
  );
}

export default CFDetails;