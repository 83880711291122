export const ATTRIBUTE_KEYS = {
  OWNERSHIP_TYPE: 'ownershipType',
  NAME_CHECK: 'nameCheck',
  STATE_CHECK: 'stateCheck',
  DISTRICT_CHECK: 'districtCheck',
  IS_INTRASTATE_REQUIRED: 'isIntraStateRequired',
  CASE_TYPE: 'caseType',
  IS_CA_CALLING_REQUIRED: 'isCaCallingRequired'
}

export const columns = (attributes) => [
  {
    headerName: 'Ownership Type',
    cellRenderer: 'MultiSelectRenderer',
    cellRendererParams: {
      attributeKey: ATTRIBUTE_KEYS.OWNERSHIP_TYPE,
      options: attributes.ownershipType
   }
  },
  {
    headerName: 'Name Check',
    cellRenderer: 'MultiSelectRenderer',
    cellRendererParams: {
      attributeKey: ATTRIBUTE_KEYS.NAME_CHECK,
      options: attributes.nameCheck
   }
  },
  {
    headerName: 'State Check',
    cellRenderer: 'MultiSelectRenderer',
    cellRendererParams: {
      attributeKey: ATTRIBUTE_KEYS.STATE_CHECK,
      options: attributes.stateCheck
   }
  },
  {
    headerName: 'District Check',
    cellRenderer: 'MultiSelectRenderer',
    cellRendererParams: {
      attributeKey: ATTRIBUTE_KEYS.DISTRICT_CHECK,
      options: attributes.districtCheck
   }
  },
  {
    headerName: 'Intrastate Required',
    cellRenderer: 'MultiSelectRenderer',
    cellRendererParams: {
      attributeKey: ATTRIBUTE_KEYS.IS_INTRASTATE_REQUIRED,
      options: attributes.isIntraStateRequired
   }
  },
  {
    headerName: 'Case Type',
    cellRenderer: 'MultiSelectRenderer',
    cellRendererParams: {
      attributeKey: ATTRIBUTE_KEYS.CASE_TYPE,
      options: attributes.caseType,
      isMulti: true,
   }
  },
  {
    headerName: 'CA Calling Required',
    cellRenderer: 'MultiSelectRenderer',
    cellRendererParams: {
      attributeKey: ATTRIBUTE_KEYS.IS_CA_CALLING_REQUIRED,
      options: attributes.isCaCallingRequired
   }
  },
  {
    headerName: 'Delete',
    cellRenderer: 'DeleteIconRenderer',
  },
];
