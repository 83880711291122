import React, { useEffect } from 'react'
import DocumentRejectionReasonsConfigAdd from './add-rejection-reason';
import DocumentRejectionReasonsConfigList from './rejection-reason-list';

const DocumentConfigRejectionReasons = ({
  documentsList,
  fetchDocumentsListConnect,
}) => {
  useEffect(() => {
    if(!documentsList.allIds.length) {
      fetchDocumentsListConnect();
    }
  }, []);

  return (
    <>
      <DocumentRejectionReasonsConfigAdd />
      <DocumentRejectionReasonsConfigList />
    </>
  )
}

export default DocumentConfigRejectionReasons;