import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RtoGridView from './component';
import { fetchRtoGridInfoConnected } from './actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchRtoGridInfoConnected }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RtoGridView);
