import CtaRenderer from "../components/docket-management/cell-renderers/cta-renderer";
import MultiSelectRenderer from "../components/docket-management/cell-renderers/multiselect-renderer";
import React from "react";

export const ATTRIBUTE_KEYS = {
  EMAIL_ID: 'emailId',
  REGION_ID: 'regionId',
}

export const columns = ({ regionDropdownOptions, handleMultiSelectChange, handleCtaClick }) => [
  {
    title: 'Email Id',
    field: ATTRIBUTE_KEYS.EMAIL_ID,
    filterable: true,
  },
  {
    title: 'Region',
    field: ATTRIBUTE_KEYS.REGION_ID,
    cell: (props) =>
      <MultiSelectRenderer 
        {...props}
        handleMultiSelectChange={handleMultiSelectChange}
        options={regionDropdownOptions}
        isMulti={true}
      />,
    filterable: false,
  },
  {
    title: 'Update',
    cell: (props) =>
      <CtaRenderer
        {...props}
        label={"Update Regions"}
        handleCtaClick={handleCtaClick}
      />,
    filterable: false,
  },
];
