import Types from './types';
import { rcManagementService } from '../../../services';

const fetchRcDocumentRemarksConnected = (id, type, withOps = false, page = 0) => (
  dispatch
) => {
  dispatch(fetchRcDocumentRemarksRequested());
  //let params = {};
  return rcManagementService
    .fetchRcDocumentRemarks(id, type, withOps, page)
    .then((response) => {
      const { data } = response;
      dispatch(fetchRcDocumentRemarksSuccess(data, withOps));
    })
    .catch((error) => {
      dispatch(dispatch(fetchRcDocumentRemarksFailure(error)));
    });
};

const fetchRcDocumentRemarksRequested = () => {
  return {
    type: Types.FETCH_REMARKS_REQUESTED
  };
};

const fetchRcDocumentRemarksSuccess = (data, withOps) => {
  return {
    type: Types.FETCH_REMARKS_SUCCESS,
    payload: data,
    withOps
  };
};

const fetchRcDocumentRemarksFailure = (error) => {
  return {
    type: Types.FETCH_REMARKS_FAILURE,
    payload: error
  };
};

const fetchOthersRcDocumentRemarksConnected = (id, type, withOps = false, page = 0) => (
  dispatch
) => {
  dispatch(fetchOthersRcDocumentRemarksRequested());
  //let params = {};
  return rcManagementService
    .fetchOthersRcRemarks(id, type, withOps, page)
    .then((response) => {
      const { data } = response;
      dispatch(fetchOthersRcDocumentRemarksSuccess(data, withOps));
    })
    .catch((error) => {
      dispatch(dispatch(fetchOthersRcDocumentRemarksFailure(error)));
    });
};

const fetchOthersRcDocumentRemarksRequested = () => {
  return {
    type: Types.FETCH_OTHERS_RC_REMARKS_REQUESTED
  };
};

const fetchOthersRcDocumentRemarksSuccess = (data, withOps) => {
  return {
    type: Types.FETCH_OTHERS_RC_REMARKS_SUCCESS,
    payload: data,
    withOps
  };
};

const fetchOthersRcDocumentRemarksFailure = (error) => {
  return {
    type: Types.FETCH_OTHERS_RC_REMARKS_FAILURE,
    payload: error
  };
};

export { fetchRcDocumentRemarksConnected, fetchOthersRcDocumentRemarksConnected };
