import React from "react";
import RtoGridView from "../../components/rto-grid-view";
import withAdminLayout from "../../../shared/hoc/admin-layout";
import ErrorBoundary from "../../components/common/errorBoundary"
import { withAuthorization } from "../../../shared/utils/firebase";

const RtoGrid = (props) => {
    return (
        <div>
            <ErrorBoundary>
                <RtoGridView {...props}/>
            </ErrorBoundary>
        </div>
    );
};
export default withAdminLayout(withAuthorization(()=>true)(RtoGrid));
