import React, { useEffect } from 'react';
import useModal from 'react-hooks-use-modal';

import './style.css';
import TabularLogs from '../tabular-logs';
import CustomPagination from '../custom-pagination/component';
import { PAGE_SIZE } from '../../../shared/utils/constants';

const CPublishRemarksModel = (props) => {
  const {
    data: { page, totalPages, totalRecords, response: remarks }
  } = props;
  const [Modal, open, close] = useModal();

  const fetchData = (pageNo) => {
    props.fetchData(props.orderId, { page: pageNo, size: PAGE_SIZE['20'] });
  };

  const handleFilterOpen = (e) => {
    e.preventDefault();
    fetchData(1);
    open();
  };

  const handleFilterClose = (e) => {
    e.preventDefault();
    close();
  };

  const handlePageChange = (pageNo) => {
    fetchData(pageNo);
  };

  return (
    <div className='row'>
      <div className='col-md-12 text-right'>
        {totalPages > 1 && (
          <a href='javascript:void(0);' onClick={handleFilterOpen}>
            More ...
          </a>
        )}
        <Modal>
          <div className='publish-remarks-mdl-container col-md-12'>
            <button
              type='button'
              className='modal-close-button close'
              aria-label='Close'
              onClick={handleFilterClose}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
            <TabularLogs
              label='Publish Logs'
              logKey='REMARKS'
              headers={props.headers}
              logs={remarks}
            />
            <CustomPagination
              page={page}
              totalRecords={totalRecords}
              handlePageChange={handlePageChange}
              recordsOnPage={null}
              totalPages={totalPages}
              itemsCountPerPage={PAGE_SIZE['20']}
            />
          </div>
        </Modal>
      </div>
      <br />
    </div>
  );
};

export default CPublishRemarksModel;
