import React from 'react';
import withAdminLayout from '../../../shared/hoc/admin-layout';
import ErrorBoundary from '../../components/common/errorBoundary';
import { withAuthorization } from '../../../shared/utils/firebase';
import { RtoDelayUpdates } from '../../components/rto-delay-updates/component';

const RtoDelayUpdatesPage = () => {
  return (
    <div>
      <ErrorBoundary>
        <RtoDelayUpdates />
      </ErrorBoundary>
    </div>
  );
};
export default withAdminLayout(
  withAuthorization(() => true)(RtoDelayUpdatesPage)
);
