import React from 'react';
import Orders from '../../components/orders-grid';
import withAdminLayout from '../../../shared/hoc/admin-layout';
import ErrorBoundary from '../../components/common/errorBoundary';
import { withAuthorization } from '../../../shared/utils/firebase';

const OrdersPage = () => {
  return (
    <div>
      <ErrorBoundary>
        <Orders />
      </ErrorBoundary>
    </div>
  );
};
export default withAdminLayout(withAuthorization(() => true)(OrdersPage));
