import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TaskManagementGrid from './component';
import { fetchTaskManagementGridInfo, updateAssignTo, fetchAgents } from './actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
    fetchTaskManagementGridInfoConnect: fetchTaskManagementGridInfo,
    updateAssignToConnect: updateAssignTo,
    fetchAgentsConnect: fetchAgents,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TaskManagementGrid);
