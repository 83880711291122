import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CarImages from './component';
import {
  fetchImageDetailConnected,
  replaceImageConnected,
  approveImageConnected,
  downloadImageConnected,
} from './actions';

const mapStateToProps = ({
  imageInfo: { imageData },
  order: {
    order: { detail },
  },
}) => {
  return {
    imageData,
    detail,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchImageDetailConnected,
      replaceImageConnected,
      approveImageConnected,
      downloadImageConnected,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CarImages);
