import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  FETCH_REMARKS_REQUESTED
  FETCH_REMARKS_SUCCESS
  FETCH_REMARKS_FAILURE
`,
  {
    prefix: 'rcOthersCasetypeRemarks/'
  }
);
