import React, { useEffect, useState } from "react"
import './styles.css';

export const CEditableLabel = ({
  name,
  label,
  initialValue,
  onChange,
  onOk,
  inputProps: {
    inputType,
    maxLength,
  } = {
    inputType: 'text',
    maxLength: 100
  }
}) => {
  const [value, setValue] = useState(initialValue || '');
  const [isEditingMode, setIsEditingMode] = useState(false);

  const handleEditClick = () => {
    setIsEditingMode(true);
  };

  const handleCancelClick = () => {
    setIsEditingMode(false);
    setValue(initialValue);
  };

  const handleOkClick = () => {
    if (onOk) {
      onOk(value, name);
    }
    setIsEditingMode(false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setValue(value);
    if (onChange) {
      onChange(e);
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div className="row">
      <label htmlFor={name} className="col-5 font-weight-bold">{label}</label>
      <div className="col-1">:</div>
      <div className="col-6 row">
        {isEditingMode ? (
          <input
            type={inputType}
            name={name}
            value={value}
            maxLength={maxLength || 100}
            onChange={(e) => {
              if (inputType === 'number') {
                const { value } = e.target;
                if (value.length > maxLength) {
                  return;
                } else {
                  handleChange(e);
                }
              } else {
                handleChange(e);
              }
            }}
            className="editable-label-input col-8"
          />
        ) : (
          <div className="col-8">{value}</div>
        )}
        <ActionBox
          isEditingMode={isEditingMode}
          className="col-4"
          onCancel={handleCancelClick}
          onOk={handleOkClick}
          onEdit={handleEditClick}
        />
      </div>
    </div>
  )
}

export const ActionBox = ({
  isEditingMode,
  className = '',
  onEdit,
  onOk,
  onCancel,
}) => {
  return (
    <div className={className}>
      {isEditingMode ? (
        <>
          <i
            className='icon-fa fa fa-check'
            style={{ color: 'green' }}
            onClick={onOk}
          />
          <i
            className='icon-fa fa fa-times'
            style={{ color: 'red', marginLeft: '4px' }}
            onClick={onCancel}
          />
        </>
      ) : (
        <i
          className='icon-fa fa fa-pencil'
          onClick={onEdit}
        />
      )}
    </div>
  )
}