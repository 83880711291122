import React, { useState, useEffect } from 'react';
import Accordion from '../../../../../shared/accordion';
import ArrowIcon from '../../../../../shared/arrow';
import { rcManagementService } from '../../../../../services';
import AgentLog from '../rc-others-agent-log';
import CTextArea from '../../../../../shared/components/custom-textarea';
import CustomPagination from '../../../custom-pagination/component';

const RcOthersAgentRemarks = ({ rcTrackerId }) => {
  const [payload, updatePayload] = useState({
    id: null,
    remarks_type_id: rcTrackerId,
    remarks_type: 'TRACKER',
    remarks_text: '',
    created_at: null,
    created_by: null
  });

  const [trackerRemarks, setTrackerRemarks] = useState([]);
  const [trackerRemarksOps, setTrackerRemarksOps] = useState([]);

  useEffect(() => {
    fetchRemarks();
  }, []);

  const fetchRemarks = () => {
    if (!rcTrackerId) return false;
    rcManagementService["fetchRcOthersTrackerRemarks"](rcTrackerId).then((response) => {
      setTrackerRemarks(response);
    });
    rcManagementService["fetchRcOthersTrackerRemarks"](rcTrackerId, true)
      .then((response) => {
        setTrackerRemarksOps(response);
      });
  };

  const onChange = (e) => {
    const targetValue = e.target.value;
    // const targetName = e.target.name;
    updatePayload({ ...payload, remarks_text: targetValue });
  };

  const saveRemarks = () => {
    if (payload.remarks_text && payload.remarks_type_id) {
      rcManagementService.saveRcTrackerRemarks(payload, true).then((response) => {
        if (response) {
          updatePayload({ ...payload, remarks_text: '' });
          fetchRemarks();
        }
      });
    }
  };

  const handlePageChangeRemarks = (page) => {
    rcManagementService.fetchRcTrackerRemarks(rcTrackerId, false, page).then((response) => {
      setTrackerRemarks(response);
    });
  }

  const handlePageChangeRemarksOps = (page) => {
    rcManagementService
      .fetchRcTrackerRemarks(rcTrackerId, true, page)
      .then((response) => {
        setTrackerRemarksOps(response);
      });
  }

  return (
    <Accordion totalItems={1}>
      {({ onClick, currentVisibleStates }) => {
        return (
          <Accordion.Content>
            <Accordion.Heading>
              <h3
                className='d-flex justify-content-between'
                onClick={() => onClick({ index: 0 })}
              >
                <label>Remarks</label>
                <ArrowIcon
                  grey={'GreyArrow'}
                  rotateBy={currentVisibleStates[0] ? 180 : 90}
                />
              </h3>
            </Accordion.Heading>
            <Accordion.Body>
              <div
                className={
                  currentVisibleStates[0] ? 'accordionShow' : 'accordionHide'
                }
              >
                <CTextArea
                  className={'form-control mb20'}
                  value={payload.remarks_text}
                  onChange={onChange}
                />
                <button
                  type='button'
                  onClick={saveRemarks}
                  className='btn btn-primary mb20'
                >
                  Save Remarks
                </button>
                <AgentLog
                  label='Remark Logs (City Ops)'
                  logKey='REMARKS'
                  logs={((trackerRemarksOps || {}).response || [])}
                />

                { trackerRemarksOps && <CustomPagination
                  page={trackerRemarksOps.page}
                  totalRecords={trackerRemarksOps.totalRecords}
                  handlePageChange={handlePageChangeRemarksOps}
                  recordsOnPage={(trackerRemarksOps.response || []).length}
                  totalPages={trackerRemarksOps.totalPages}
                  itemsCountPerPage={5}
                /> }

                <AgentLog
                  label='Remark Logs'
                  logKey='REMARKS'
                  logs={((trackerRemarks || {}).response || [])}
                />

                { trackerRemarks && <CustomPagination
                  page={trackerRemarks.page}
                  totalRecords={trackerRemarks.totalRecords}
                  handlePageChange={handlePageChangeRemarks}
                  recordsOnPage={(trackerRemarks.response || []).length}
                  totalPages={trackerRemarks.totalPages}
                  itemsCountPerPage={5}
                /> }
              </div>
            </Accordion.Body>
          </Accordion.Content>
        );
      }}
    </Accordion>
  );
};

export default RcOthersAgentRemarks;
