import React from "react";
import { withRouter } from 'react-router-dom';
import { useDescope } from "@descope/react-sdk";
import { removeDataFromLocalStorage } from "../../../shared/utils/helper";

const Header = (props) => {
  const { logout } = useDescope();
  
  const handleLogout = async (e) => {
    e.preventDefault();
    await logout();
    removeDataFromLocalStorage();
    props.history.push("/login");
  };

  return (
    <header className="main-header">
      <a href="/" className="logo no-deco no-padding">
        <span className="logo-mini">
          <b>C</b>24
        </span>
        <span className="logo-lg">
          <b>C2C</b>&nbsp;Panel
        </span>
      </a>
      <nav className="navbar navbar-static-top" role="navigation">
        <a
          href="# "
          className="sidebar-toggle p-0"
          data-toggle="offcanvas"
          role="button"
          onClick={() => props.toggleSidebar()}
        >
          <span className="sr-only">Toggle navigation</span>
        </a>
        <div
          className={
            props.isLoggedIn
              ? "navbar-custom-menu"
              : "navbar-custom-menu hide"
          }
          onClick={() => props.toggleProfile()}
        >
          <ul className="nav navbar-nav">
            <li className="dropdown user user-menu">
              <a
                href="# "
                className="dropdown-toggle p-0"
                data-toggle="dropdown"
                aria-expanded={props.profile}
              >
                <img src="/logo.png" className="user-image" alt="User" />
                <span className="hidden-xs">{props.email}</span>
              </a>
              <ul
                className={
                  props.profile ? "dropdown-menu d-block" : "dropdown-menu"
                }
              >
                <li className="user-header">
                  <img src="/logo.png" className="img-circle" alt="User" />
                  <p>C2C User</p>
                </li>
                <li className="user-footer">
                  <div className="pull-right">
                    <a
                      href="/"
                      className="btn btn-default btn-flat"
                      onClick={handleLogout}
                    >
                      Sign out
                    </a>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default withRouter(Header);
