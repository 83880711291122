import React from "react";

export const CBox = ({
  children
}) => {
  return (
    <div className="w-100 p-2 px-3 border" style={{ minHeight: '150px', borderRadius: '20px', position: 'relative'}}>
      {children}
    </div>
  )
}

export const CCloseButton = ({
  label,
  showRemoveIcon = true,
  className,
  onRemove
}) => {
  return (
    <span className={`btn-primary btn-sm ${className}`}>
      <span className="mr-2">{label}</span>
      {showRemoveIcon && (
        <span style={{ fontSize: '18px', cursor: 'pointer'}} onClick={onRemove}>&times;</span>
      )}
    </span>
  )
}