import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    FETCH_ORDER_DEALER_INIT
    FETCH_ORDER_DEALER_SUCCESS
    FETCH_ORDER_DEALER_FAILURE
`,
  {
    prefix: 'orderDEALERTab/'
  }
);
