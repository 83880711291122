import {
  rcStates,
  rcType,
  rcTrackerStatus,
  dealStatus,
  nbfcStatus
} from '../../constants/constants';

export default (isOtherRcView) => ([
  {
    rc_type: 'rc',
    headerName: 'UID',
    width: 120,
    resizable: false,
    field: 'deal_identifier',
    renderField: 'deal_id',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    cellRenderer: 'LinkRenderer',
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    rc_type: 'rco',
    headerName: 'Loan Id',
    width: 140,
    field: 'loan_id',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    suppressSizeToFit: false,
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    rc_type: 'rc',
    headerName: 'Appt. ID',
    width: 110,
    field: 'appointment_id',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    suppressSizeToFit: false,
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    rc_type: 'rco',
    headerName: 'Booking ID',
    width: 130,
    field: 'booking_id',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    suppressSizeToFit: false,
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    rc_type: 'rc',
    headerName: 'Buddy Name',
    field: 'buddy_name',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    rc_type: 'rco',
    headerName: 'Segment',
    width: 140,
    field: 'segment',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    }
  },
  {
    rc_type: 'rc',
    headerName: 'Registration Number',
    width: 140,
    valueFormatter: 'value.toUpperCase()',
    field: 'registration_number',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },

    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    rc_type: 'rc',
    headerName: 'Deal Status',
    width: 120,
    field: 'deal_status',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'deal_status',
      options: Object.values(dealStatus)
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: Object.values(dealStatus)
    },
    suppressMenu: true
  },
  {
    rc_type: 'rc',
    headerName: 'File State',
    width: 160,
    field: 'file_status',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'file_status',
      options: Object.values(rcStates)
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: Object.values(rcStates)
    },
    suppressMenu: true
  },

  {
    rc_type: 'rc',
    headerName: 'RC Type',
    width: 140,
    field: 'rc_type',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'rc_type',
      options: Object.values(rcType)
    },
    //cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: Object.values(rcType)
    },
    suppressMenu: true,
    valueFormatter: function(params) {
      return params.data.display_rc_type;
    },
  },
  {
    rc_type: 'rc',
    headerName: 'Rc Tracker Status',
    width: 230,
    field: 'rc_tracker_status',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'MultiSelectFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'rc_tracker_status',
      options: Object.values(rcTrackerStatus)
    },
    //cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: Object.values(rcTrackerStatus)
    },
    suppressMenu: true
  },
  {
    rc_type: 'rco',
    headerName: 'Correction Type',
    width: 140,
    field: 'correction_type',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    }
  },
  {
    rc_type: 'rc',
    headerName: 'Last Updated at',
    field: 'last_communicated_at',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    rc_type: 'rc',
    headerName: 'Buyer Email',

    field: 'buyer_email_id',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    rc_type: 'rc',
    headerName: 'Buyer Number',
    width: 125,
    field: 'buyer_number',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },

  {
    rc_type: 'rc',
    headerName: 'Seller Email',

    field: isOtherRcView ? 'owner_email_id' : 'seller_email_id',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    suppressSizeToFit: false,
    resizable: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    rc_type: 'rc',
    headerName: 'Seller Number',
    field: isOtherRcView ? 'owner_number' : 'seller_number',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true },

  },
  {
    rc_type: 'rc',
    headerName: 'Buyer Name',
    field: 'buyer_name',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    suppressSizeToFit: false,
    resizable: false,
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    rc_type: 'rc',
    headerName: 'Seller Name',
    field: isOtherRcView ? 'owner_name' : 'seller_name',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    suppressSizeToFit: false,
    resizable: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    rc_type: 'rc',
    headerName: 'NBFC',
    width: 140,
    field: 'nbfc_answer',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'nbfc_answer',
      options: Object.values(nbfcStatus)
    },
    //cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: Object.values(nbfcStatus)
    },
    suppressMenu: true
  }
]);
