export const inventoryStatusConstants = {
  IN_TRANSIT: 'IN_TRANSIT',
  STOCK_IN: 'STOCK_IN',
  STOCK_OUT: 'STOCK_OUT',
  NEW_CAR: 'NEW_CAR',
  FULL_TRANSFER: 'FULL',
  PICKUP_REQUEST: 'PICKUP_REQUEST',
  BID_WON: 'BID_WON'
};
// prettier-ignore
export const INVENTORY_STATUS = {
  [inventoryStatusConstants.IN_TRANSIT]: {label: 'In-Transit',value: inventoryStatusConstants.IN_TRANSIT },
  [inventoryStatusConstants.STOCK_IN]: { label: 'Stock In', value: inventoryStatusConstants.STOCK_IN  },
  [inventoryStatusConstants.STOCK_OUT]: { label: 'Stock Out', value: inventoryStatusConstants.STOCK_OUT  },
  [inventoryStatusConstants.NEW_CAR]: { label: 'New Car', value: inventoryStatusConstants.NEW_CAR  },
  [inventoryStatusConstants.BID_WON]: { label: 'Bid Won', value: inventoryStatusConstants.BID_WON  },
  [inventoryStatusConstants.FULL_TRANSFER]: { label: 'Full Transfer', value: inventoryStatusConstants.FULL_TRANSFER  },
  [inventoryStatusConstants.PICKUP_REQUEST]: { label: 'Picup Request', value: inventoryStatusConstants.PICKUP_REQUEST  }
};

export const backgroundCheckStatusConstants = {
  PENDING: 'PENDING',
  ASSURED: 'ASSURED',
  NON_ASSURED: 'NON_ASSURED',
  DELIST: 'DELIST'
};

export const carSourceConstants = {
  CUSTOMER_CARE: 'CUSTOMER_CARE',
  B2C: 'B2C',
  GS_CAR: 'GS_CAR',
  MANUAL_UPLOAD: 'MANUAL_UPLOAD',
  C2C_DEALER_CAR: 'C2C_DEALER_CAR',
  CLASSIFIED_CAR: 'CLASSIFIED_CAR',
  GS_DEALER: 'GS_DEALER',
  MARKETPLACE: 'MARKETPLACE'
};

export const orderStateConstants = {
  DRAFT: 'DRAFT',
  PUBLISH: 'PUBLISH',
  BOOKED: 'BOOKED',
  SOLD: 'SOLD',
  ARCHIVE: 'ARCHIVE',
  REJECT: 'REJECT',
  AUCTIONED: 'AUCTIONED'
};

export const ORDER_STATE_OPTIONS = {
  DRAFT: { key: orderStateConstants.DRAFT, value: 'Draft' },
  PUBLISH: { key: orderStateConstants.PUBLISH, value: 'Publish' },
  BOOKED: { key: orderStateConstants.BOOKED, value: 'Booked' },
  SOLD: { key: orderStateConstants.SOLD, value: 'Sold' },
  ARCHIVE: { key: orderStateConstants.ARCHIVE, value: 'Archive' },
  REJECT: { key: orderStateConstants.REJECT, value: 'Reject' },
  AUCTIONED: { key: orderStateConstants.AUCTIONED, value: 'Auctioned' }
};

export const carSourceOptions = {
  // [carSourceConstants.CUSTOMER_CARE]: {
  //   key: carSourceConstants.CUSTOMER_CARE,
  //   value: 'Customer Care - Marketplace'
  // },
  [carSourceConstants.C2C_DEALER_CAR]: {
    key: carSourceConstants.C2C_DEALER_CAR,
    value: 'Business to Customer'
  },
  [carSourceConstants.GS_CAR]: {
    key: carSourceConstants.GS_CAR,
    value: 'Guaranteed Sales'
  },
  [carSourceConstants.MARKETPLACE]: {
    key: carSourceConstants.MARKETPLACE,
    value: 'Marketplace'
  },
  [carSourceConstants.CLASSIFIED_CAR]: {
    key: carSourceConstants.CLASSIFIED_CAR,
    value: 'Classified Car'
  },
  [carSourceConstants.GS_DEALER]: {
    key: carSourceConstants.GS_DEALER,
    value: 'GS Buyback'
  }
};

export const zoneCarSourceOptions = {
  [carSourceConstants.CUSTOMER_CARE]: {
    key: carSourceConstants.CUSTOMER_CARE,
    value: 'Customer Care'
  },
  [carSourceConstants.C2C_DEALER_CAR]: {
    key: carSourceConstants.C2C_DEALER_CAR,
    value: 'Business to Customer'
  },
  [carSourceConstants.GS_CAR]: {
    key: carSourceConstants.GS_CAR,
    value: 'Guaranteed Sales Car'
  },
  [carSourceConstants.MANUAL_UPLOAD]: {
    key: carSourceConstants.MANUAL_UPLOAD,
    value: 'Manual Upload'
  },
  [carSourceConstants.CLASSIFIED_CAR]: {
    key: carSourceConstants.CLASSIFIED_CAR,
    value: 'Classified Car'
  },
  [carSourceConstants.GS_DEALER]: {
    key: carSourceConstants.GS_DEALER,
    value: 'GS Dealer'
  }
};

export const DEALER_CAR_SOURCE_OPTIONS = [
  {
    value: carSourceConstants.GS_CAR,
    label: 'Guarnateed Sales Car'
  },
  { value: carSourceConstants.C2C_DEALER_CAR, label: 'Business To Customer' }
];
export const agentStatusConstants = {
  PENDING: 'PENDING',
  ASSIGNED_TO_FINANCE: 'ASSIGNED_TO_FINANCE',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  ASSIGNED_TO_VENDOR: 'ASSIGNED_TO_VENDOR'
};

export const legalStatusConstants = {
  INDIVIDUAL: 'INDIVIDUAL',
  COMPANY: 'COMPANY',
  PARTNERSHIP: 'PARTNERSHIP',
  PROPRIETORSHIP: 'PROPRIETORSHIP',
  HUF: 'HUF',
  OTHERS: 'OTHERS'
};

export const documentConstants = {
  NON_GST_CERTIFICATE: 'NON_GST_CERTIFICATE',
  GST_CERTIFICATE: 'GST_CERTIFICATE',
  PAN_CARD: 'PAN_CARD',
  AADHAR_CARD: 'AADHAR_CARD',
  DECLARATION_FORM: 'DECLARATION_FORM',
  BANK_DOCUMENT: 'BANK_DOCUMENT',
  EMPANELMENT_FORM: 'EMPANELMENT_FORM'
};

export const rcStates = {
  PENDING: { key: 'PENDING', value: 'Pending' },
  OK_FOR_DOCS: { key: 'OK_FOR_DOCS', value: 'Ok for docs' },
  OK_FOR_SALES: { key: 'OK_FOR_SALES', value: 'Ok for Sales' },
  OK_FOR_RTO: { key: 'OK_FOR_RTO', value: 'Ok for RTO' }
};
export const rcType = {
  SELF: { key: 'SELF', value: 'Self' },
  NOC: { key: 'NOC', value: 'Noc' },
  FULL_TRANSFER: { key: 'FULL', value: 'Full Transfer' }
};
export const rcTrackerStatus = {
  File_Received: { key: 'File Received', value: 'File Received' },
  E_Receipt_Issued: { key: 'E-Receipt Issued', value: 'E-Receipt Issued' },
  Handed_over_to_the_Agent: {
    key: 'Handed over to the Agent',
    value: 'Handed over to the Agent'
  },
  RC_transfer_completed: {
    key: 'RC transfer completed',
    value: 'RC transfer completed'
  },
  Handed_over_to_Buyer: {
    key: 'Handed over to Buyer',
    value: 'Handed over to Buyer'
  }
};

export const dealStatus = {
  TOKEN_DONE: { key: 'TOKEN_DONE', value: 'Active' },
  DEAL_LOST: { key: 'DEAL_LOST', value: 'Cancelled' }
};

export const STOCK_OUT_REASONS_CONSTANTS = {
  SOLD: 'SOLD',
  OUT_FOR_MAINTENANCE: 'OUT_FOR_MAINTENANCE',
  C2C_STORE_MOVEMENT: 'C2C_STORE_MOVEMENT',
  SOLD_IN_B2B: 'SOLD_IN_B2B'
};

export const STOCK_OUT_REASONS_OPTIONS = [
  {
    label: 'C2C Store Movement',
    value: STOCK_OUT_REASONS_CONSTANTS.C2C_STORE_MOVEMENT
  },
  { label: 'Sold', value: STOCK_OUT_REASONS_CONSTANTS.SOLD },
  {
    label: 'Out For Maintenance',
    value: STOCK_OUT_REASONS_CONSTANTS.OUT_FOR_MAINTENANCE
  },
  {
    label: 'Sold in B2B',
    value: STOCK_OUT_REASONS_CONSTANTS.SOLD_IN_B2B
  }
];

export const STORE_TYPES = {
  C2B_PARKING: "C2B_PARKING",
  GS_FULFILMENT_CENTRE: "GS_FULFILMENT_CENTRE",
  SERVICE_CENTRE: "SERVICE_CENTRE",
  PHOTO_STUDIO: "PHOTO_STUDIO"
}

export const STORE_TYPES_LABELS = {
  [STORE_TYPES.C2B_PARKING]: "C2B Parking",
  [STORE_TYPES.GS_FULFILMENT_CENTRE]: "GS Fulfilment Centre",
  [STORE_TYPES.SERVICE_CENTRE]: "Service Centre",
  [STORE_TYPES.PHOTO_STUDIO]: "Photo Studio"
}

export const STORE_TYPE_OPTIONS = [
  { value: STORE_TYPES.C2B_PARKING, label: STORE_TYPES_LABELS.C2B_PARKING },
  { value: STORE_TYPES.GS_FULFILMENT_CENTRE, label: STORE_TYPES_LABELS.GS_FULFILMENT_CENTRE },
  { value: STORE_TYPES.SERVICE_CENTRE, label: STORE_TYPES_LABELS.SERVICE_CENTRE },
  { value: STORE_TYPES.PHOTO_STUDIO, label: STORE_TYPES_LABELS.PHOTO_STUDIO },
]