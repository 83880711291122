import React from 'react';
import './style.css'

const CheckBox = ({
  checkedStatus = false,
  onClickCallback = () => {},
  id = 'checkbox',
  name = 'checkbox',
  disabled=false,
  label=''
}) => {
  const onClickHandler = e => {
    onClickCallback(e);
  };

  return (
    <div className='checkboxHolder'>
      <input
        onChange={onClickHandler}
        type='checkbox'
        id={id}
        name={name}
        checked={checkedStatus}
        disabled={disabled}
      />
      <label htmlFor={id} >{label}</label>
    </div>
  );
};

export default CheckBox;
