import React, { useState, useEffect } from 'react';
import CheckBox from '../../../client/components/shared/CheckBox';
import { buddyScoringApi } from '../../../client/service';
import { withRouter } from 'react-router-dom';

const ZonesBuddyLead = ({ match }) => {
  const [buddyScoringLead, setBuddyScoringLead] = useState({});
  const id = match.params.id;
  useEffect(() => {
    buddyScoringApi
      .getBuddyScoringLead(id)
      .then((res) => {
        setBuddyScoringLead(res.data);
      })
      .catch((err) => {});
  }, []);
  const handleSave = () => {
    buddyScoringApi.submitgetBuddyScoringLead(id, buddyScoringLead);
  };
  const onChange = (e) => {
    setBuddyScoringLead({
      ...buddyScoringLead,
      [e.target.name]: e.target.value
    });
  };
  const onChangeChechbox = (e) => {
    setBuddyScoringLead({
      ...buddyScoringLead,
      [e.target.name]: e.target.checked
    });
  };
  return (
    <div>
      {buddyScoringLead ? (
        <div>
          <div className='form-group row'>
            <div className='form-group col-md-3'>
              <input type='hidden' name='id' />
              <label>Min Lead</label>
              <input
                className='form-control'
                type='number'
                min='0'
                name='minLeadCap'
                value={buddyScoringLead.minLeadCap || ''}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className='form-group col-md-3'>
              <input type='hidden' name='id' />
              <label>Max Lead</label>
              <input
                className='form-control'
                type='number'
                min='0'
                name='maxLeadCap'
                value={buddyScoringLead.maxLeadCap || ''}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className='form-group col-md-3'>
              <label>Activate Allocation</label>
              <CheckBox
                name='buddyAllocationActive'
                value={buddyScoringLead.buddyAllocationActive || ''}
                onClickCallback={onChangeChechbox}
                checkedStatus={buddyScoringLead.buddyAllocationActive}
              />
            </div>
            <div className='form-group col-md-3'>
              <label>E-Payment Enabled</label>
              <CheckBox
                name='isEPaymentEnabled'
                value={buddyScoringLead.isEPaymentEnabled || ''}
                onClickCallback={onChangeChechbox}
                checkedStatus={buddyScoringLead.isEPaymentEnabled}
              />
            </div>
          </div>
          <div className='form-group col-md-12 text-right'>
            <button onClick={handleSave} className='btn btn-primary'>
              Save
            </button>
          </div>
        </div>
      ) : (
        <p>No data Present</p>
      )}
    </div>
  );
};

export default withRouter(ZonesBuddyLead);
