import {
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected,
  handleHttpStatusError
} from '../../../services/helper';

import { financeService } from '../../../services';
import { showToastMessages } from '../../../shared/utils/helper';

const fetchAllServiceCharges = (params) => (dispatch) => {
  dispatchSetLoaderConnected();
  return financeService
    .fetchAllServiceCharges(params)
    .then((resp) => {
      dispatchResetLoaderConnected();
      if (resp.data && resp.data.data) {
        return resp.data.data;
      } else {
        throw null;
      }
    })
    .catch((error) => {
      handleHttpStatusError(error, true);
      throw error;
    });
};

const submitServiceChargeSlab = (params) => (dispatch) => {
  // dispatchSetLoaderConnected();
  return financeService
    .submitServiceChargeSlab(params)
    .then((resp) => {
      // dispatchResetLoaderConnected();
      if (resp.data && resp.data.data) {
        showToastMessages(resp.data.message, true);
        return resp.data.data;
      } else {
        throw null;
      }
    })
    .catch((error) => {
      handleHttpStatusError(error, true);
      throw error;
    });
};
export { fetchAllServiceCharges, submitServiceChargeSlab };
