import { DispositionConstants } from "..";

const validateSellerListingPrice = (dispState, additionalFieldsValues, ccDispositionId, maxListPrice) => {
  let isValid = true;
  if(dispState === DispositionConstants.SELLER && additionalFieldsValues.length > 0 && ccDispositionId === DispositionConstants.SELLER_LIST_PRICE_CC_DISPOSITION_ID) {
    additionalFieldsValues.forEach(item => {
      if (item[0] === DispositionConstants.PRICE && item[1] > maxListPrice) {
        isValid = false;
      }
    });
  }
  return isValid;
};

const validateBuyerOfferPrice = (dispState, additionalFieldsValues, ccDispositionId, discountedPrice) => {
  let isValid = true;
  if(dispState === DispositionConstants.BUYER && additionalFieldsValues.length > 0 && ccDispositionId === DispositionConstants.BUYER_OFFER_PRICE_CC_DISPOSITION_ID) {
    additionalFieldsValues.forEach(item => {
      if (item[0] === DispositionConstants.PRICE && item[1] < discountedPrice) {
        isValid = false;
      }
    });
  }
  return isValid;
};

export {
  validateSellerListingPrice,
  validateBuyerOfferPrice
};