import Types from './types';
import { documentRuleEngineService } from '../../../services';
import { parseById } from '../../helper/parsers';
import { showToastMessages } from '../../../shared/utils/helper';

export const fetchRegionList = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    documentRuleEngineService
      .fetchRegionList()
      .then((data) => {
        dispatch(fetchRegionListSuccess(parseById(data)));
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const downloadConfigRules = (params) => (dispatch) => {
  documentRuleEngineService.downloadConfigRules(params);
};

export const uploadConfigRules = (params, body) => (dispatch) => {
  dispatch(uploadConfigRulesInit());
  return new Promise((resolve, reject) => {
    documentRuleEngineService
      .uploadConfigRules(params, body)
      .then((resp) => {
        resolve(resp);
        showToastMessages(resp.message);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        dispatch(uploadConfigRulesEnd());
      });
  });
};

const fetchRegionListSuccess = (data) => ({
  type: Types.FETCH_REGION_LIST_SUCCESS,
  data
});

const uploadConfigRulesInit = () => ({
  type: Types.UPLOAD_CONFIG_RULES_INIT
});

const uploadConfigRulesEnd = () => ({
  type: Types.UPLOAD_CONFIG_RULES_END
});
