import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  FETCH_PUBLISH_REMARKS_REQUESTED
  FETCH_PUBLISH_REMARKS_SUCCESS
  FETCH_PUBLISH_REMARKS_FAILURE
  
  SUBMIT_PUBLISH_REMARKS
`,
  {
    prefix: 'order-publish-remarks/'
  }
);
