import React from 'react';
import withAdminLayout from '../../../shared/hoc/admin-layout';
import ErrorBoundary from '../../components/common/errorBoundary';
import { withAuthorization } from '../../../shared/utils/firebase';
import RegionAllocationGrid from '../../components/region-allocation-grid';

const RegionAllocationPage = () => {
  return (
    <div>
      <ErrorBoundary>
        <RegionAllocationGrid />
      </ErrorBoundary>
    </div>
  );
};
export default withAdminLayout(
  withAuthorization(() => true)(RegionAllocationPage)
);
