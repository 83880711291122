import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CaseTypesConfigList from "./component";
import { fetchCaseTypeConfig, updateCaseTypeConfig, updateCaseTypeData, resetConfigList, deleteCaseTypeConfig } from "../case-types-config/actions";

const mapStateToProps = ({
  caseTypeConfig: {
    attributes,
    isAttributesFetched,
    caseTypeConfigs,
    caseTypeConfigs: {
      configsUpdated
    }
  }
}) => ({
  attributes,
  isAttributesFetched,
  caseTypeConfigs,
  configsUpdated
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCaseTypeConfigConnect: fetchCaseTypeConfig,
  updateCaseTypeConfigConnect: updateCaseTypeConfig,
  updateCaseTypeDataConnect: updateCaseTypeData,
  resetConfigListConnect: resetConfigList,
  deleteCaseTypeConfigConnect: deleteCaseTypeConfig,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CaseTypesConfigList);
