import * as Yup from 'yup';

const validationSchema = Yup.object().shape(
  {
    delivery_date_operator: Yup.string().when('delivery_date', {
      is: val => (val ? true : false),
      then: Yup.string()
        .nullable()
        .required('Required'),
      otherwise: Yup.string().nullable()
    }),
    delivery_date: Yup.date().when('delivery_date_operator', {
      is: val => (val ? true : false),
      then: Yup.date()
        .nullable()
        .required('Required'),
      otherwise: Yup.date().nullable()
    }),

    file_completion_date_operator: Yup.string().when('file_completion_date', {
      is: val => (val ? true : false),
      then: Yup.string()
        .nullable()
        .required('Required'),
      otherwise: Yup.string().nullable()
    }),
    file_completion_date: Yup.date().when('file_completion_date_operator', {
      is: val => (val ? true : false),
      then: Yup.date()
        .nullable()
        .required('Required'),
      otherwise: Yup.date().nullable()
    }),

    token_date_operator: Yup.string().when('token_date', {
      is: val => (val ? true : false),
      then: Yup.string()
        .nullable()
        .required('Required'),
      otherwise: Yup.string().nullable()
    }),
    token_date: Yup.date().when('token_date_operator', {
      is: val => (val ? true : false),
      then: Yup.date()
        .nullable()
        .required('Required'),
      otherwise: Yup.date().nullable()
    })
  },
  [
    ['delivery_date_operator', 'delivery_date'],
    ['delivery_date', 'delivery_date_operator'],

    ['file_completion_date_operator', 'file_completion_date'],
    ['file_completion_date', 'file_completion_date_operator'],

    ['token_date_operator', 'token_date'],
    ['token_date', 'token_date_operator']
  ]
);

export default validationSchema;
