import Types from './types';
import { userService } from '../../../services';
import { showToastMessages } from '../../../shared/utils/helper';

const setUserInfoByIndexSuccess = (data) => ({
  type: Types.SET_USER_SUCCESS,
  payload: data
});

const addUserConnected = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    userService
      .addUser(data)
      .then((resp) => {
        resolve(resp);
        showToastMessages(resp.data.message);
      })
      .catch((error) => {
        error.errors &&
          error.errors[0] &&
          error.errors[0]['message'] &&
          showToastMessages(error.errors[0]['message'], false);
        reject(error);
      });
  });
};

const fetchUser = (userId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    userService
      .fetchUserById(userId)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const editUserConnected = (id, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    userService
      .editUser(id, data)
      .then((resp) => {
        resolve(resp);
        showToastMessages(resp.data.message);
      })
      .catch((error) => {
        error.errors &&
          error.errors[0] &&
          error.errors[0]['message'] &&
          showToastMessages(error.errors[0]['message'], false);
        reject(error);
      });
  });
};
const resetUserInfoByIndexConnected = () => {
  return {
    type: Types.RESET_USER_DETAILS
  };
};

const setUserInfoByIndexConnected = (data) => (dispatch) => {
  dispatch(setUserInfoByIndexSuccess(data));
};

export {
  fetchUser,
  addUserConnected,
  setUserInfoByIndexConnected,
  editUserConnected,
  resetUserInfoByIndexConnected
};
