import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Accordion from '../../../shared/accordion';
import ArrowIcon from '../../../shared/arrow';
import './styles.css';
import InputText from '../../../shared/components/input-text';
import { IS_ENV_DEV } from '../../../shared/utils/constants';
import { FORMULAE } from './constants';
import Locale from '../../../shared/utils/locale';
import CustomButton from '../../../shared/components/button';
import { openUrlTab, isArrayEmpty } from '../../../shared/utils/helper';
import { BUTTON_ICON } from '../../../shared/utils/constants/appConstants';
import { financeService } from '../../../services';
import { roundTo2Decimal } from '../../../client/components/shared/utility';

const SERVICES_NAME = {
  TOTAL: 'Total',
  EXCESS: 'Excess',
  ON_BEHALF_OF_SELLER: 'On Behalf Of Seller',
  CAR_SALE_PRICE: 'Car Sale Price',
  TWOPERCENT_CONVENIENCE_FEE: '2% convenience fee',
  RC_TRANSSFER_COST: 'RC Transfer Cost',
  RC_HOLDBACK: 'RC Holdback',
  INSURANCE: 'Insurance',
};

const COLUMNS = {
  EXPECTED: 'expected',
  COLLECTED: 'collected',
  WAIVER: 'waiver'
};

const FinanceServiceSection = (props) => {
  const { label, data, type: chargeType } = props;
  const [isDisabled, setIsDisabled] = useState(true);
  const {
    transaction_log_total_buyer,
    transaction_log_total_seller,
    balance_buyer,
    balance_seller,
    deal_id,
    invoice_generated_buyer = false,
    invoice_generated_seller = false,
    invoice_file_name_buyer,
    invoice_file_name_seller,
    buyer_credit_notes,
    seller_credit_notes,
    version,
    delivery_challan_generated_buyer = false,
    delivery_challan_file_name_buyer,
  } = data;

  const invoice_generated =
    chargeType === 'buyer_services'
      ? invoice_generated_buyer
      : invoice_generated_seller;
  const invoice_url =
    chargeType === 'buyer_services'
      ? invoice_file_name_buyer
      : invoice_file_name_seller;
  const credit_notes =
    chargeType === 'buyer_services' ? buyer_credit_notes : seller_credit_notes;

  const [chargesData, setChargesData] = useState(data[chargeType]);
  const [buyerBalance, setBuyerBalance] = useState(balance_buyer);
  const [sellerBalance, setSellerBalance] = useState(balance_seller);
  const [errorOnSave, setErrorOnSave] = useState();

  useEffect(() => {
    const { data, type: chargeType } = props;
    const { balance_buyer, balance_seller } = data;

    setChargesData(data[chargeType]);
    setBuyerBalance(balance_buyer);
    setSellerBalance(balance_seller);
  }, [props]);

  useEffect(() => {
    updateBalanceAmount();
  }, [chargesData]);

  useEffect(() => {
    fetchUiRules(deal_id);
  }, [deal_id]);

  const fetchUiRules = (suppliedDealId) => {
    financeService.getUiRulesForDeal(suppliedDealId).then((uiRules) => {
      setIsDisabled(uiRules.freezeBuyerServiceCharges);
    }).catch((err) => {
      console.log(err);
    });
  }

  const updateExpectedAmount = (e, service_name, index) => {
    const { name, value } = e.target;

    const newAmount = parseFloat(value);
    const { expected, collected, waiver } = chargesData[index];

    // 'Total Expected should not be less than Total Collected + Total Waiver'
    let error = null;
    if (newAmount < 0) {
      error = 'Expected amount cannot be less than zero';
    } else if (newAmount < collected + waiver) {
      error =
        'Total Expected should not be less than Total Collected + Total Waiver';
    } else {
      error = null;
    }

    let updatedData = [...chargesData];
    updatedData[index] = {
      ...updatedData[index],
      [name]: newAmount,
      error: error
    };
    setChargesData(updatedData);
  };

  const isCollectedValid = (newCollected, currentState, service_name) => {
    const { expected, collected, waiver } = currentState;
    let error = null;
    if (newCollected < 0) {
      error = 'Collected amount cannot be less than zero';
    } else if (
      newCollected > expected - waiver &&
      service_name !== SERVICES_NAME.ON_BEHALF_OF_SELLER
    ) {
      error = 'Collected amount cannot be greater than Expected - Waiver';
    } else {
      error = null;
    }
    return error;
  };

  const updateCollectedAmount = (e, service_name, index) => {
    const { name, value } = e.target;

    const newAmount = parseFloat(value);
    const { expected, collected, waiver } = chargesData[index];
    const error = isCollectedValid(newAmount, chargesData[index], service_name);

    let updatedData = [...chargesData];
    updatedData[index] = {
      ...updatedData[index],
      [name]: newAmount,
      error: error
    };
    setChargesData(updatedData);
  };

  const handleCollectedAmount = (e, service_name, index) => {
    const { name, value } = e.target;
    const newAmount = parseFloat(value) || 0;

    const { expected, collected, waiver } = chargesData[index];
    const { collected: initCollected } = data[chargeType][index];
    const error = isCollectedValid(newAmount, chargesData[index], service_name);
    if (error) return false;
    if (newAmount >= initCollected) {
      //todo : waiver to be updated
      let updatedData = [...chargesData];
      updatedData[index] = {
        ...updatedData[index],
        [name]: newAmount,
        error: null
      };
      setChargesData(updatedData);
      // updatedData = setTotalAmount(COLUMNS.COLLECTED, updatedData);

      const totalCollectedAsCustomer =
        chargeType === 'buyer_services'
          ? transaction_log_total_buyer
          : transaction_log_total_seller;

      const excessAmount =
        totalCollectedAsCustomer -
        getTotalAmount(COLUMNS.COLLECTED, updatedData);
      setFieldAmount(
        SERVICES_NAME.EXCESS,
        COLUMNS.COLLECTED,
        excessAmount,
        updatedData
      );
    } else if (newAmount < initCollected) {
      let updatedData = [...chargesData];
      updatedData[index] = {
        ...updatedData[index],
        [name]: newAmount,
        error: null
      };
      setChargesData(updatedData);
      //updatedData = setTotalAmount(COLUMNS.COLLECTED, updatedData);
      const totalCollectedAsCust =
        chargeType === 'buyer_services'
          ? transaction_log_total_buyer
          : transaction_log_total_seller;
      const excessAmount =
        totalCollectedAsCust - getTotalAmount(COLUMNS.COLLECTED, updatedData);

      setFieldAmount(
        SERVICES_NAME.EXCESS,
        COLUMNS.COLLECTED,
        excessAmount,
        updatedData
      );
    }
  };

  const handleExpectedAmount = (e, service_name, index) => {
    const { name, value } = e.target;
    const newAmount = parseFloat(value) || 0;

    let updatedData = setFieldAmountByIndex(name, newAmount, index);

    const { expected, collected, waiver } = chargesData[index];
    const { expected: initExpected } = data[chargeType][index];
    let error = null;
    if (newAmount < 0) {
      error = 'Expected amount cannot be less than zero';
      return false;
    } else if (newAmount < collected + waiver) {
      error =
        'Total Expected should not be less than Total Collected + Total Waiver';
      return false;
    } else {
      error = null;
    }
    if (newAmount > initExpected) {
      updatedData = setFieldAmountByIndex(name, newAmount, index);
      setTotalAmount(COLUMNS.EXPECTED, updatedData);
    } else {
      updatedData = setFieldAmountByIndex(name, newAmount, index);
      setTotalAmount(COLUMNS.EXPECTED, updatedData);
    }
  };

  const updateWaiverAmount = (e, service_name, index) => {
    const { name, value } = e.target;

    const newWaiverAmt = parseFloat(value);
    const { expected } = chargesData[index];

    const error =
      newWaiverAmt > expected
        ? 'Waiver value can be entered as max of total expected value'
        : null;

    let updatedData = [...chargesData];
    updatedData[index] = {
      ...updatedData[index],
      [name]: newWaiverAmt,
      error: error
    };
    setChargesData(updatedData);
  };

  const handleWaiverAmount = (e, service_name, index) => {
    const { name, value } = e.target;
    const newWaiverAmt = parseFloat(value) || 0;
    if (!newWaiverAmt) {
      setFieldAmountByIndex(name, 0, index);
    }

    const { expected, collected, waiver } = chargesData[index];

    const isWaiverValid = newWaiverAmt <= expected ? true : false;
    if (!isWaiverValid) return false;

    // Expected - Collected < Waiver => update collected amount
    if (canUpdateCollectedByWaiver(newWaiverAmt, chargesData[index])) {
      let updatedData = [...chargesData];
      updatedData[index] = {
        ...updatedData[index],
        collected: expected - newWaiverAmt,
        [name]: newWaiverAmt,
        error: null
      };
      setChargesData(updatedData);
      // updatedData = setTotalAmount('waiver', updatedData);
      const totalCollectedAsCustomer =
        chargeType === 'buyer_services'
          ? transaction_log_total_buyer
          : transaction_log_total_seller;

      const excessAmount =
        totalCollectedAsCustomer -
        getTotalAmount(COLUMNS.COLLECTED, updatedData);
      updatedData = setTotalAmount(COLUMNS.WAIVER, updatedData);
      setFieldAmount(
        SERVICES_NAME.EXCESS,
        COLUMNS.COLLECTED,
        excessAmount,
        updatedData
      );
    } else {
      let updatedData = setFieldAmountByIndex(name, newWaiverAmt, index);
      setTotalAmount(COLUMNS.WAIVER, updatedData);
    }
  };

  /* ---------------------------------------------------------------------- */

  const canUpdateCollectedByWaiver = (newWaiverAmt, updatedChargesData) => {
    const { expected, collected, waiver } = updatedChargesData;
    const isWaiverValid = newWaiverAmt <= expected ? true : false;
    // Expected - Collected < Waiver => update collected amount
    return expected - collected < newWaiverAmt && isWaiverValid ? true : false;
  };

  const setTotalAmount = (field, sourceData = chargesData) => {
    const total = getTotalAmount(field, sourceData);
    const updatedData = setFieldAmount(
      SERVICES_NAME.TOTAL,
      field,
      total,
      sourceData
    );
    return updatedData;
  };

  const updateFieldAmount = (serviceName, field, amount, sourceData) => {
    const index = sourceData.findIndex((item) => {
      return item.service_name === serviceName;
    });

    let updatedData = [...sourceData];
    updatedData[index] = {
      ...updatedData[index],
      [field]: updatedData[index][field] + amount,
      error: null
    };
    setChargesData(updatedData);
    return updatedData;
  };

  const setFieldAmountByIndex = (field, amount, index) => {
    let updatedData = [...chargesData];
    updatedData[index] = {
      ...updatedData[index],
      [field]: amount,
      error: null
    };
    setChargesData(updatedData);
    return updatedData;
  };

  const setFieldAmount = (serviceName, field, amount, sourceData) => {
    const index = sourceData.findIndex((item) => {
      return item.service_name === serviceName;
    });

    let updatedData = [...sourceData];
    updatedData[index] = {
      ...updatedData[index],
      [field]: amount || 0,
      error: null
    };
    setChargesData(updatedData);
    return updatedData;
  };

  const getTotalAmount = (field, sourceData) => {
    const initialValue = 0;
    const reducer = (accumulator, item) => {
      return accumulator + (item[field] || 0);
    };
    const excludedServices = [SERVICES_NAME.EXCESS, SERVICES_NAME.TOTAL];
    const total = sourceData
      .filter((item) => !excludedServices.includes(item.service_name))
      .reduce(reducer, initialValue);
    return parseFloat(total) || 0;
  };

  const getFieldAmount = (serviceName, field, sourceData = chargesData) => {
    const index = sourceData.findIndex((item) => {
      return item.service_name.toLowerCase() === serviceName.toLowerCase();
    });
    if (index >= 0) {
      return sourceData[index] ? sourceData[index][field] : 0;
    } else {
      return 0;
    }
  };

  // prettier-ignore
  const updateBalanceAmount = () => {
    if (chargeType === 'buyer_services') {
      // Balance of Buyer: Total Expected of Buyer + Total Collected in “On Behalf of Seller” - Total Collected of Buyer - Total Waiver of Buyer
      const totalExpected = Math.round(getTotalAmount(COLUMNS.EXPECTED, chargesData));
      const totalCollectedOnBehalfSeller = Math.round(getFieldAmount(SERVICES_NAME.ON_BEHALF_OF_SELLER,COLUMNS.COLLECTED));
      const totalCollected = Math.round(transaction_log_total_buyer);  //getTotalAmount(COLUMNS.COLLECTED, chargesData);
      const totalWaiver = Math.round(getTotalAmount(COLUMNS.WAIVER, chargesData));
      const balance = +totalExpected + +totalCollectedOnBehalfSeller - +totalCollected - +totalWaiver;
        
      setBuyerBalance(balance);
    } else {
      // Balance of Seller: Total Expected of Seller - Total Collected of Seller - Total Collected in “On Behalf of Seller” - Total Waiver of Seller
      const totalExpected = getTotalAmount(COLUMNS.EXPECTED, chargesData);
      const totalCollectedOnBehalfSeller = getFieldAmount(SERVICES_NAME.ON_BEHALF_OF_SELLER,COLUMNS.COLLECTED, data['buyer_services']);
      const totalCollected = transaction_log_total_seller;  //getTotalAmount(COLUMNS.COLLECTED, chargesData);
      const totalWaiver = getTotalAmount(COLUMNS.WAIVER, chargesData);
      // prettier-ignore
      const balance = totalExpected - totalCollected - totalCollectedOnBehalfSeller - totalWaiver;
      
      setSellerBalance(balance);
    }
  };

  const onSubmit = () => {
    //On click of save, there should be validation that sum of all the Total Collected fields should be equal to the sum of balance from transaction logs
    const totalCollected =
      getTotalAmount(COLUMNS.COLLECTED, chargesData) +
      getFieldAmount(SERVICES_NAME.EXCESS, COLUMNS.COLLECTED);
    const transaction_log_total =
      chargeType === 'buyer_services'
        ? transaction_log_total_buyer
        : transaction_log_total_seller;
    if (Math.abs(totalCollected - transaction_log_total) > 1.0) {
      setErrorOnSave('Error : Amount mismatch !!');
      return false;
    }

    const payload = {
      deal_id: deal_id,
      balance: chargeType === 'buyer_services' ? buyerBalance : sellerBalance,
      user_type: chargeType === 'buyer_services' ? 'BUYER' : 'SELLER',
      services: chargesData,
      version: version
    };
    props.submitServiceChargesConnected(payload).then(() => {
      props.refresh(true);
    });
  };

  const onInvoiceDownload = () => {
    openUrlTab(invoice_url);
  };

  const onDeliveryChallanDownload = () => {
    openUrlTab(delivery_challan_file_name_buyer);
  };

  const openCreditNotes = () => {
    credit_notes &&
      !isArrayEmpty(credit_notes) &&
      credit_notes.forEach((item) => {
        openUrlTab(item.credit_note_url, '_blank');
      });
  };
  return (
    <div className='wrapperAgentDetails p15 mt30'>
      <Accordion totalItems={1}>
        {({ onClick, currentVisibleStates }) => {
          return (
            <Accordion.Content>
              <Accordion.Heading>
                <h2
                  className='d-flex fwb justify-content-between m0'
                  onClick={() => onClick({ index: 0 })}
                >
                  {label}
                  <ArrowIcon
                    grey={'GreyArrow'}
                    rotateBy={currentVisibleStates[0] ? 180 : 90}
                  />
                </h2>
              </Accordion.Heading>
              <Accordion.Body>
                <div
                  className={
                    currentVisibleStates[0]
                      ? 'accordionShow mt20 top-border financeService'
                      : 'accordionHide'
                  }
                >
                  {/* start */}
                  <div className='row'>
                    <div className='col-2'>&nbsp;</div>
                    <div className='col-2'> Expected</div>
                    <div className='col-2'> Collected</div>
                    <div className='col-2'> Waiver</div>
                    <div className='col-2'> HoldBack</div>
                    <div className='col-2' />
                  </div>
                  {/* end */}
                  {/* start */}
                  {chargesData &&
                    chargesData.map((item, index) => {
                      const {
                        service_display,
                        service_name,
                        expected,
                        collected,
                        waiver,
                        error = null,
                        holdBack,
                        expectedFreezed,
                        waiverFreezed
                      } = item;
                      return (
                        <div className='row' key={`${chargeType}_${index}`}>
                          <div className='col-2'>{service_display}</div>
                          <div className='col-2'>
                            <InputText
                              id={`expected_${chargeType}_${index}`}
                              type='number'
                              name='expected'
                              className='form-control inputSpace'
                              value={roundTo2Decimal(expected)}
                              onChange={(e) =>
                                updateExpectedAmount(e, service_name, index)
                              }
                              onBlurCallback={(e) =>
                                handleExpectedAmount(e, service_name, index)
                              }
                              disabled={expectedFreezed}
                            />
                          </div>
                          <div className='col-2'>
                            <InputText
                              id={`collected_${chargeType}_${index}`}
                              type='number'
                              name='collected'
                              className='form-control inputSpace'
                              value={roundTo2Decimal(collected)}
                              onChange={(e) =>
                                updateCollectedAmount(e, service_name, index)
                              }
                              onBlurCallback={(e) =>
                                handleCollectedAmount(e, service_name, index)
                              }
                              disabled
                            />
                          </div>
                          <div className='col-2'>
                            <InputText
                              id={`waiver_${chargeType}_${index}`}
                              type='number'
                              name='waiver'
                              className='form-control inputSpace'
                              value={roundTo2Decimal(waiver)}
                              onChange={(e) =>
                                updateWaiverAmount(e, service_name, index)
                              }
                              onBlurCallback={(e) =>
                                handleWaiverAmount(e, service_name, index)
                              }
                              disabled={waiverFreezed}
                            />
                          </div>
                          <div className='col-2'>
                            <InputText
                              id={`holdBack_${chargeType}_${index}`}
                              type='number'
                              name='holdBack'
                              className='form-control inputSpace'
                              value={roundTo2Decimal(holdBack)}
                              disabled
                            />
                          </div>
                          <div className='col-2'>
                            {error && (
                              <span
                                style={{ color: '#c80039', fontSize: '10px' }}
                              >
                                {error || ''}
                              </span>
                            )}
                          </div>
                        </div>
                      );
                    })}

                  <div className='row'>
                    <div className='col-2'>{'Balance'}</div>
                    <div className='col-2'>
                      <InputText
                        id={`balance`}
                        type='number'
                        name='balance'
                        className='form-control inputSpace'
                        value={
                          chargeType === 'buyer_services'
                            ? roundTo2Decimal(buyerBalance)
                            : roundTo2Decimal(sellerBalance)
                        }
                        // onChange={(e) => updateBalanceAmount(e)}
                        // onBlurCallback={(e) => handleBalanceAmount(e)}
                        disabled={true}
                      />
                    </div>
                    <div className='col-8'>
                      {IS_ENV_DEV ? (
                        <span style={{ color: '#a05119', fontSize: '10px' }}>
                          {chargeType === 'buyer_services'
                            ? FORMULAE.BALANCE_OF_BUYER
                            : FORMULAE.BALANCE_OF_SELLER}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-2'>
                      <CustomButton label={Locale.SAVE} onClick={onSubmit} />
                    </div>
                    <div className='col-4'>
                      {chargeType === 'buyer_services' && delivery_challan_generated_buyer && (
                        <CustomButton
                          label={'Delivery Challan'}
                          onClick={onDeliveryChallanDownload}
                          btnIconName={BUTTON_ICON.download}
                          className='mr-2'
                        />
                      )}
                      {invoice_generated ? (
                        <CustomButton
                          label={'Invoice'}
                          onClick={onInvoiceDownload}
                          btnIconName={BUTTON_ICON.download}
                        />
                      ) : null}
                    </div>
                    <div className='col-3'>
                      {credit_notes && !isArrayEmpty(credit_notes) ? (
                        <CustomButton
                          label={'Credit Notes'}
                          onClick={openCreditNotes}
                          btnIconName={BUTTON_ICON.download}
                        />
                      ) : null}
                    </div>
                    <div className='col-5'>
                      <span style={{ color: '#c80039', fontSize: '10px' }}>
                        {errorOnSave || ''}
                      </span>
                    </div>
                  </div>
                  {/* end */}
                </div>
              </Accordion.Body>
            </Accordion.Content>
          );
        }}
      </Accordion>
    </div>
  );
};
export default withRouter(FinanceServiceSection);
