export const REFUNDS_CATEGORIES = {
    POST_DELIVERY: 'POST_DELIVERY',
    BUYBACK: 'BUYBACK',
};

export const REFUND_ACTIONS = {
    INITIATE_PAYOUT: 'INITIATE_PAYOUT',
    APPROVE: 'APPROVE',
    REJECT: 'REJECT',
};

export const LABELS = {
  "type": {
    "EXCESS": "Excess",
    "BUYBACK": "Buyback",
    "RTO_HOLDBACK": "RTO Holdback",
    "CHALLAN_HOLDBACK": "Challan Holdback",
    "OTHER_HOLDBACK": "Other Holdback",
    "CNG_HOLDBACK": "Cancellation Charges"
  },
  "category": {
    "BUYBACK": "Buyback",
    "POST_DELIVERY": "Post Delivery"
  },
  "label": {
    "INITIATED": "Initiated",
    "APPROVED": "Approved",
    "REJECTED": "Rejected",
    "CANCELLED": "Expired",
    "DONE": "Done",
    "ERROR": "Error",
    "PROCESSING": "Processing"
  }
};
const REFUND_HEADER = {
  SERVICE_NAME: "Service Name",
  BALANCE: "Balance",
  REFUND_DUE: "Refund Due",
  REFUNDABLE_AMOUNT: "Refundable Amount",
  AMOUNT_REFUNDED: "Amount Refunded",
  ACTIVE_PAYOUTS: "Active Payouts"
};

export const POST_DELIVERY_CHARGES_HEADER = [
  REFUND_HEADER.SERVICE_NAME,
  REFUND_HEADER.BALANCE,
  REFUND_HEADER.REFUNDABLE_AMOUNT,
  REFUND_HEADER.AMOUNT_REFUNDED,
  REFUND_HEADER.ACTIVE_PAYOUTS
];

export const BUYBACK_HEADER = [
  REFUND_HEADER.SERVICE_NAME,
  REFUND_HEADER.REFUND_DUE,
  REFUND_HEADER.AMOUNT_REFUNDED,
  REFUND_HEADER.ACTIVE_PAYOUTS
];
