import React from "react";
import { ViewRenderer } from "./cell-renderers/view-renderer";

export const POST_DELIVERY_SALES_COLUMN_KEYS = {
  SERVICE_NAME: 'name',
  EXPECTED: 'expected',
  COLLECTED: 'collected',
  WAIVER: 'waiver',
  INVOICE: 'invoiceUrl'
}

export const columns = ({
  handleView,
}) => [
  {
    title: 'Service',
    field: POST_DELIVERY_SALES_COLUMN_KEYS.SERVICE_NAME,
  },
  {
    title: 'Expected',
    field: POST_DELIVERY_SALES_COLUMN_KEYS.EXPECTED,
  },
  {
    title: 'Collected',
    field: POST_DELIVERY_SALES_COLUMN_KEYS.COLLECTED,
  },
  {
    title: 'Waiver',
    field: POST_DELIVERY_SALES_COLUMN_KEYS.WAIVER,
  },
  {
    title: 'Invoice',
    field: POST_DELIVERY_SALES_COLUMN_KEYS.INVOICE,
    cell: (props) =>
      <ViewRenderer 
        {...props}
        label={"Download Invoice"}
        onView={handleView}
      />
  },
];
