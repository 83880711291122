export const columns = [
  {
    headerName: 'Region Name',
    width: 280,
    field: 'regionName',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: false,
      clearButton: true,
      newRowsAction: 'keep'
    },
    cellRenderer: "LinkRenderer",
    floatingFilterComponentParams: { suppressFilterButton: true }
  }
];
