import React from 'react';
import CityGrid from '../../components/city-grid';
import withAdminLayout from '../../../shared/hoc/admin-layout';
import ErrorBoundary from '../../components/common/errorBoundary';

const CityPage = (props) => {
  return (
    <div>
      <ErrorBoundary>
        <CityGrid {...props} />
      </ErrorBoundary>
    </div>
  );
};
export default withAdminLayout(CityPage);
