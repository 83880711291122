import React, { useEffect, useMemo, } from 'react'
import GridView from '../common/grid-view';
import { columns } from '../../config/document-configs-columns';
import { withRouter } from 'react-router-dom';
import { DOCUMENTS_GRID_ID } from '../../constants/constants';
import { showToastMessages } from '../../../shared/utils/helper';

const DocumentsConfigList = ({
  attributes,
  isAttributesFetched,
  regionConfigs,
  configsUpdated,
  fetchDocumentConfigConnect,
  updateDocumentConfigConnect,
  updateDocumentDataConnect,
  resetConfigListConnect,
  deleteDocumentConfigConnect,
  match
}) => {
  const rowData = useMemo(() => Object.values(regionConfigs.byId), [regionConfigs.byId]);
  const columnDefs = useMemo(() => columns(attributes), [isAttributesFetched]);

  const fetchDocumentsConfig = () => {
    let params = { regionId: match.params.regionId };
    fetchDocumentConfigConnect(params);
  }

  useEffect(() => {
    fetchDocumentsConfig();
    return () => resetConfigListConnect();
  }, []);

  const handleMultiSelectChange = (attributeKey, data, documentId) => {
    let value = Array.isArray(data) ? data.map(val => val.value) : data.value;
    updateDocumentDataConnect({ id: documentId, key: attributeKey, value });
  }

  const handleConfigUpdate = () => {
    let data = configsUpdated.map(configId => ({
      ...regionConfigs.byId[configId],
      regionId: match.params.regionId
    }));

    updateDocumentConfigConnect({}, data).then((res) => {
      showToastMessages(res.message);
    }).finally(() => {
      fetchDocumentsConfig();
    });
  }

  const handleConfigDelete = (id) => {
    deleteDocumentConfigConnect(id).then((res) => {
      showToastMessages(res.message);
    }).finally(() => {
      fetchDocumentsConfig();
    });
  }

  const handleDeleteClick = (data) => {
    handleConfigDelete(data.id);
  }

  return (
    <>
      <h5>Document Rules</h5>
      <GridView
        className={DOCUMENTS_GRID_ID.CONFIG_LIST}
        uniqueGridId={DOCUMENTS_GRID_ID.CONFIG_LIST}
        columnDefs={columnDefs}
        rowData={rowData}
        context={{ handleMultiSelectChange, handleDeleteClick }}
        columnsToFit={false}
        floatingFilter={false}
        getRowHeight={() => 55}
      />
      <div className="text-right">
        <button
          className='btn btn-sm btn-success mt-neg-lg'
          onClick={handleConfigUpdate}
        >
          Update Rules
        </button>
      </div>
    </>
  )
}

export default withRouter(DocumentsConfigList);