import React from 'react'
import './style.css'
import { Spinner } from 'reactstrap'

const Loader = () => {
  return (
    <div className='fullscreen_loader'>
      <Spinner
        variant='primary'
        animation='border'
        size='sm'
      />
    </div>
  )
}

export default Loader