import Types from './types';
import { orderService } from '../../../services';

// Global actions
import {
  handleHttpStatusError,
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected
} from '../../../services/helper';
import { showToastMessages } from '../../../shared/utils/helper';

//Fetch order details
const fetchCarDetailInit = () => ({
  type: Types.FETCH_ORDER_CAR_INIT
});

const fetchCarDetailSuccess = (data) => ({
  type: Types.FETCH_ORDER_CAR_SUCCESS,
  payload: data
});

const fetchCarDetailFailure = (error) => ({
  type: Types.FETCH_ORDER_CAR_FAILURE,
  error
});
const fetchCarMakeSuccess = (data) => ({
  type: Types.FETCH_ORDER_MAKE_LIST,
  payload: data
});
const fetchCarModelSuccess = (data) => ({
  type: Types.FETCH_ORDER_MODEL_LIST,
  payload: data
});
const fetchCarVariantSuccess = (data) => ({
  type: Types.FETCH_ORDER_VARIANT_LIST,
  payload: data
});
const fetchCarDetailConnected = (appointmentId, withLoader = true) => (
  dispatch
) => {
  dispatchSetLoaderConnected(withLoader);
  withLoader && dispatch(fetchCarDetailInit());
  return new Promise((resolve, reject) => {
    orderService
      .fetchCarDetail(appointmentId)
      .then((resp) => {
        const order = resp.data.data;
          const data = {
            order
          };

          resolve(data);
          dispatch(fetchCarDetailSuccess(data));
          dispatchResetLoaderConnected();
      })
      .catch((error) => {
        dispatch(fetchCarDetailFailure(error));
        handleHttpStatusError(error);
        reject(error);
      });
  });
};

const submitCarDetail = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    orderService
      .submitCarDetail(data)
      .then((resp) => {
        showToastMessages(resp.data.message);
      })
      .catch((error) => {
        handleHttpStatusError(error);
      });
  });
};
const getMakeDataConnected = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    orderService
      .getMakeData()
      .then((resp) => {
        dispatch(fetchCarMakeSuccess(resp.data));
        resolve(resp);
      })
      .catch((error) => {
        handleHttpStatusError(error);
        reject(error);
      });
  });
};
const getModelDataConnected = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    orderService
      .getModelData(id)
      .then((resp) => {
        dispatch(fetchCarModelSuccess(resp.data));
        resolve(resp);
      })
      .catch((error) => {
        handleHttpStatusError(error);
        reject(error);
      });
  });
};
const getVariantDataConnected = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    orderService
      .getVariantData(id)
      .then((resp) => {
        dispatch(fetchCarVariantSuccess(resp.data));
        resolve(resp);
      })
      .catch((error) => {
        handleHttpStatusError(error);
        reject(error);
      });
  });
};
export {
  fetchCarDetailConnected,
  submitCarDetail,
  getMakeDataConnected,
  getModelDataConnected,
  getVariantDataConnected
};
