import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserRcForm from './component';
import { fetchStores } from '../order-inventory-tab/actions';
import {
  addUserRcAccessConnected,
  fetchUserRcAccessConnected,
  editUserRcAccessConnected,
  fetchRcManagemetFilterDataConnected
} from './actions';

const mapStateToProps = (state) => {
  const { userInfo, userRcAccessInfo, UserGridInfo } = state;
  return { userInfo, userRcAccessInfo, UserGridInfo };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      //TODO:-"sp-13 to sp-14 (Akash)"
      addUserRcAccessConnected,
      fetchUserRcAccessConnected,
      editUserRcAccessConnected,
      fetchRcManagemetFilterDataConnected,
      fetchStores
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserRcForm);
