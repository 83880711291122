import React from 'react';
import { Label, Input } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import '../Car-Details/style/style.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CarDetails = ({ car }) => {
  return (
    <div>
      <div className="main_section_car block">
        <h2 className="title">Car Details</h2>
        <div className="row">
          <div className="col-6">
            <div className="form-group d-flex main-box">
              <div className="group">
                <Input type="text" className="txtbox" disabled name="make" value={car.make} />
                <span className="highlight"></span>
                <Label for="make" className="label">make</Label>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group d-flex main-box">
              <div className="group">
                <Input type="text" className="txtbox" disabled name="model" value={car.model} />
                <span className="highlight"></span>
                <Label for="model" className="label">model</Label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group d-flex main-box">
              <div className="group">
                <Input type="text" className="txtbox" disabled name="variant" value={car.variant} />
                <span className="highlight"></span>
                <Label for="variant" className="label">variant</Label>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group d-flex main-box">
              <div className="group">
                <Input type="text" className="txtbox" disabled name="kmsDriven" value={car.kmsDriven} />
                <span className="highlight"></span>
                <Label for="kmsDriven" className="label">Kms Driven:</Label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group d-flex main-box">
              <div className="group">
                <Input type="text" className="txtbox" disabled name="modelYear" value={car.modelYear} />
                <span className="highlight"></span>
                <Label for="modelYear" className="label">Model Year</Label>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group d-flex main-box">
              <div className="group">
                <Input type="text" className="txtbox" disabled name="numberOfOwners" value={car.numberOfOwners} />
                <span className="highlight"></span>
                <Label for="numberOfOwners" className="label">No. Of Owners</Label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group d-flex main-box">
              <Label for="isInsuranceAvailable" className="lbl">Insurance Available:</Label>
              <label className="radio_label">
                <input type="radio" className="radio-btn" name="optradio" readOnly checked={car.isInsuranceAvailable ? true : false} />  Yes</label>
              <label className="radio_label">
                <input type="radio" className="radio-btn" name="optradio"
                  readOnly checked={!car.isInsuranceAvailable ? true : false} />  No</label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group d-flex main-box">
              <div className="group">
                <Input type="text" className="txtbox" disabled name="fuelType" value={car.fuelType} />
                <span className="highlight"></span>
                <Label for="fuelType" className="label">Fuel Type</Label>
              </div>
            </div>
          </div>

        </div>
        <div className="row">
          {car && car.isInsuranceAvailable &&
            <div className="col-6">
              <div className="form-group d-flex main-box">
                <div className="group">
                  <Input type="text" className="txtbox" disabled name="insuranceType" value={car.insuranceType ? car.insuranceType : ''} />
                  <span className="highlight"></span>
                  <Label for="insuranceType" className="label">Insurance Type</Label>
                </div>
              </div>
            </div>}
          {car && car.isInsuranceAvailable &&
            <div className="col-6">
              <div className="form-group d-flex main-box">
                <div className="group">
                  <DatePicker
                    calendarClassName="datepicker-container"
                    selected={car.insuranceExpiry ? new Date(car.insuranceExpiry) : null}
                    dateFormat="d MMMM yyyy"
                    disabled={true}
                    className="picker"
                  />
                  <span className="highlight"></span>
                  <Label for="insuranceExpiry" className="label">Insurance Expiry:</Label>
                </div>
              </div>
            </div>}
        </div>
      </div>
    </div>
  );
}

export default CarDetails;