import React, { useState, useEffect } from 'react';
import Accordion from '../../../shared/accordion';
import ArrowIcon from '../../../shared/arrow';
import { rcManagementService } from '../../../services';
import { SelectComponent } from '../../../client/components/shared';
import { AGENT_STATUS } from './constants';
import AgentLog from '../AgentLog/component';
import './style.css';
import { useIsRcOthers } from '../../../shared/hooks';

const AgentDetail = ({
  trackerData,
  updateTrackerData,
  rcTrackerId,
  updateError,
  updatedAgentHistory
}) => {
  const [agents, setAgents] = useState({});
  const [agentsHistory, setAgentHistory] = useState([]);

  const isOtherRcView = useIsRcOthers();

  const getAgents = () => {
    return isOtherRcView ? rcManagementService.fetchOthersRcAgents() : rcManagementService.fetchAgents();
  }

  useEffect(() => {
    let mounted = true;
    getAgents().then((response) => {
      if (response && Array.isArray(response)) {
        let agentObj = {};
        response.forEach((item) => {
          agentObj[item.id] = {
            label: `${item.agent_code}-${item.agent_name}`,
            value: item.id,
            agent_name: item.agent_name
          };
        });
        if (mounted) {
          setAgents(agentObj);
        }
      } else {
        setAgents({});
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    setAgentHistory(updatedAgentHistory);
  }, [updatedAgentHistory]);

  // useEffect(() => {
  //   rcTrackerId && fetchAgentLog(rcTrackerId);
  // }, [rcTrackerId]);

  // const fetchAgentLog = (rcTrackerId) => {
  //   rcManagementService.fetchAgentHistory(rcTrackerId).then((response) => {
  //     setAgentHistory(response);
  //   });
  // }

  const onChange = (key, value) => {
    const targetValue = value ? value : null;
    const targetName = key;
    if (
      targetName === 'agent_cost' ||
      targetName === 'new_agent_cost' ||
      targetName === 'previous_agent_payment'
    ) {
      const agent_cost = targetValue ? targetValue.replace(/[^0-9]/g, '') : 0;
      updateTrackerData({ ...trackerData, [targetName]: parseInt(agent_cost) });
    } else if (targetName === 'agent_status') {
      if (targetValue === AGENT_STATUS.RETURNED['value']) {
        updateTrackerData({
          ...trackerData,
          [targetName]: targetValue,
          agent_id: null,
          agent_cost: 0
        });
      } else {
        updateTrackerData({
          ...trackerData,
          [targetName]: targetValue,
          agent_id: trackerData.base_agent_id,
          agent_cost: trackerData.base_agent_cost
        });
      }
    } else {
      updateTrackerData({ ...trackerData, [targetName]: targetValue });
    }
    updateError(null);
  };

  return (
    <Accordion totalItems={1}>
      {({ onClick, currentVisibleStates }) => {
        return (
          <Accordion.Content>
            <Accordion.Heading>
              <h2
                className='d-flex fwb justify-content-between m0'
                onClick={() => onClick({ index: 0 })}
              >
                Agent Details
                <ArrowIcon
                  grey={'GreyArrow'}
                  rotateBy={currentVisibleStates[0] ? 180 : 90}
                />
              </h2>
            </Accordion.Heading>
            <Accordion.Body>
              <div
                className={
                  currentVisibleStates[0] ? 'accordionShow mt20 top-border' : 'accordionHide'
                }
              >
                <div className='row'>
                  <div className='col-4 d-flex justify-content-between align-items-baseline'>
                    <label className='agentLabel'>Agent Name :</label>
                    <div className='col-5'>
                      <SelectComponent
                        key={trackerData['agent_id']}
                        name='agent_id'
                        optionsList={Object.values(agents)}
                        placeholder={
                          trackerData['agent_id'] &&
                          agents[trackerData['agent_id']]
                            ? agents[trackerData['agent_id']]['label']
                            : '---'
                        }
                        value={trackerData['agent_id']}
                        onChange={(value) => onChange('agent_id', value)}
                        isDisabled={!!trackerData['base_agent_id']}
                        className='form-control inputSpace col-6'
                      />
                    </div>
                    <div className='col-1' />
                  </div>
                  <div className='col-3 d-flex justify-content-between align-items-baseline'>
                    <label className='agentLabel'>Agent Cost :</label>
                    <div className='col-5'>
                      <input
                        type='text'
                        name='agent_cost'
                        value={trackerData['agent_cost']}
                        onChange={(e) => onChange('agent_cost', e.target.value)}
                        className='form-control inputSpace'
                        disabled={
                          trackerData['agent_status'] ===
                            AGENT_STATUS.RETURNED['value'] ||
                          trackerData['agent_status'] ===
                            AGENT_STATUS.TRANSFERRED['value']
                        }
                      />
                    </div>
                    <div className='col-1' />
                  </div>
                  {trackerData['base_agent_id'] && (
                    <div className='col-5 d-flex justify-content-between align-items-baseline'>
                      <label className='agentLabel'>Agent Status :</label>
                      <div className='col-7'>
                        <SelectComponent
                          key={trackerData['agent_status']}
                          name='agent_status'
                          optionsList={Object.values(AGENT_STATUS)}
                          placeholder={
                            (trackerData['agent_status'] && AGENT_STATUS[trackerData['agent_status']])
                              ? AGENT_STATUS[trackerData['agent_status']][
                                  'label'
                                ]
                              : ''
                          }
                          value={trackerData['agent_status']}
                          defaultValue={
                            (trackerData['agent_status'] && AGENT_STATUS[trackerData['agent_status']])
                              ? {
                                  value: trackerData['agent_status'],
                                  label:
                                    AGENT_STATUS[trackerData['agent_status']][
                                      'label'
                                    ]
                                }
                              : null
                          }
                          onChange={(value) => onChange('agent_status', value)}
                          className='form-control inputSpace'
                        />
                      </div>
                    </div>
                  )}
                </div>
                {trackerData['agent_status'] ===
                  AGENT_STATUS.TRANSFERRED['value'] && (
                  <div className='row'>
                    <div className='col-4 d-flex justify-content-between align-items-baseline'>
                      <label className='agentLabel'>New Agent : </label>
                      <div className='col-5'>
                        <SelectComponent
                          name='new_agent_id'
                          optionsList={Object.values(agents).filter(
                            (agent) => agent.value !== trackerData['agent_id']
                          )}
                          value={trackerData['new_agent_id']}
                          onChange={(value) => onChange('new_agent_id', value)}
                          className='form-control inputSpace'
                        />
                      </div>
                      <div className='col-1' />
                    </div>
                    <div className='col-3 d-flex justify-content-between align-items-baseline'>
                      <label className='agentLabel'>New Agent Cost : </label>
                      <div className='col-5'>
                        <input
                          type='text'
                          name='new_agent_cost'
                          value={trackerData['new_agent_cost']}
                          onChange={(e) =>
                            onChange('new_agent_cost', e.target.value)
                          }
                          className='form-control inputSpace'
                        />
                      </div>
                      <div className='col-1' />
                    </div>
                    <div className='col-5 d-flex justify-content-between align-items-baseline'>
                      <label className='agentLabel'>
                        Prev- Agent payment :
                      </label>
                      <div className='col-7'>
                        <input
                          type='text'
                          name='previous_agent_payment'
                          value={trackerData['previous_agent_payment']}
                          onChange={(e) =>
                            onChange('previous_agent_payment', e.target.value)
                          }
                          className='form-control inputSpace'
                        />
                      </div>
                    </div>
                  </div>
                )}
                <AgentLog
                  label='Agent Logs'
                  logKey='AGENTS'
                  logs={agentsHistory}
                />
              </div>
            </Accordion.Body>
          </Accordion.Content>
        );
      }}
    </Accordion>
  );
};

export default AgentDetail;
