import React, { useState, useEffect } from 'react';
import { useFormik, isEmptyArray } from 'formik';
import InputText from '../../../shared/components/input-text';
import { FIELDS, getInitValues } from './constants';
import { validationSchema } from './validations';
import { SelectComponent } from '../../../client/components/shared';
import {
  console_in_dev,
  getParsedSelectOptions,
  getSelectedOption
} from '../../../shared/utils/helper';
import CustomButton from '../../../shared/components/button';
import { Wrapper } from '../form-element';
import CustomDatePicker from '../../../shared/components/date-picker';
import styles from './component.module.css';
import Locale from '../../../shared/utils/locale';
import FinanceDiscardProofModal from '../finance-discard-proof-modal/';

const CPaymentForm = (props) => {
  const {
    paymentInfo,
    activeImageId,
    onAddMore,
    onDiscard,
    paymentModes,
    userType,
    dealId,
    showAddMoreButton,
    fetchTransactionLogsConnected,
    fetchPaymentInfoConnected,
    fetchFinanceDealInfoByIdConnected
  } = props;
  const [options, setOptions] = useState({});
  const [newPaymentProof, setNewPaymentProof] = useState({});
  const [addMoreDataId] = useState(null);
  let defaultPaymentInfo =
    paymentInfo &&
    paymentInfo.image_proofs &&
    paymentInfo.image_proofs[activeImageId];
  useEffect(() => {
    setNewPaymentProof({
      id: addMoreDataId,
      image_url: defaultPaymentInfo && defaultPaymentInfo.image_url,
      user_type: userType,
      deal_id: dealId,
      payment_mode_id: defaultPaymentInfo && defaultPaymentInfo.payment_mode_id,
      amount: defaultPaymentInfo && defaultPaymentInfo.amount,
      payment_date: defaultPaymentInfo && defaultPaymentInfo.payment_date,
      bank_name: defaultPaymentInfo && defaultPaymentInfo.bank_name
    });
  }, [paymentInfo]);
  const paymentModeList = options['payment_mode_id'];

  useEffect(() => {
    const paymentModeOptions = getParsedSelectOptions(
      paymentModes && paymentModes,
      {
        labelKey: 'display_text',
        valueKey: 'id'
      }
    );
    setOptions({
      ...options,
      [FIELDS.payment_mode_id.keyName]: paymentModeOptions
    });
  }, [paymentModes]);
  useEffect(() => {
    //On Change of carousel we have to reset the form
    formik.resetForm();
    if (defaultPaymentInfo) {
      Object.keys(formik.values).forEach((key) => {
        const res = paymentInfo.image_proofs[activeImageId][key];

        if (key !== 'payment_mode_id') {
          if (res) {
            formik.setFieldValue(key, res);
          }
        } else if (
          res &&
          key === 'payment_mode_id' &&
          options['payment_mode_id'] &&
          options['payment_mode_id'].length > 0
        ) {
          const paymentModeSelected = getSelectedOption(
            paymentModeList,
            'value',
            res
          );

          formik.setFieldValue('payment_mode_id', paymentModeSelected);
        }
      });
    }
  }, [paymentInfo, activeImageId, paymentModeList]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitValues(),
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = {
        amount: values.amount,
        utr: values.utr,
        bank: values.bank_name,
        user_type: userType,
        payment_mode_id: values.payment_mode_id.value,
        deal_id: dealId,
        id: defaultPaymentInfo.id,
        payment_date: values.payment_date
      };

      props.submitPaymentProofConnected(payload).then((res) => {
        fetchPaymentInfoConnected(userType, dealId);
        fetchTransactionLogsConnected(userType, dealId, false);
        fetchFinanceDealInfoByIdConnected(dealId);
        showAddMoreButton(activeImageId);
      });
    }
  });

  const onChangeSelect = (selected, param) => {
    if (selected) {
      formik.setFieldValue(param, selected, true);
    } else {
      formik.setFieldValue(param, '', true);
    }
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    const { value, name } = e.target;

    formik.setFieldValue(name, value, true);
    console_in_dev({ formik, value, name }, 'formik onChangeInput');
  };

  // const onFocusCallback = (e) => {
  //   e.preventDefault();
  //   const { value, name } = e.target;
  //   formik.setFieldTouched(name, true, true);
  // };

  const onBlurCallback = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    formik.setFieldTouched(name, true, true);
  };
  const handleDateChange = (date, keyname) => {
    formik.setFieldValue(keyname, date);
  };
  // const handleDiscard = (e, close) => {
  //   onDiscard(defaultPaymentInfo && defaultPaymentInfo.id, activeImageId);
  //   close();
  // };

  return (
    <>
      {paymentInfo &&
      paymentInfo.image_proofs &&
      !isEmptyArray(paymentInfo.image_proofs) ? (
        <form onSubmit={formik.handleSubmit}>
          {Object.values(FIELDS).map((fieldItem) => {
            const {
              subType,
              label,
              keyName,
              required,
              elementType,
              isMulti,
              maxLength,
              ...restProps
            } = fieldItem;
            switch (elementType) {
              case 'input':
                return (
                  <Wrapper
                    key={keyName}
                    item={fieldItem}
                    containerClass='row justify-content-between mb20'
                  >
                    <InputText
                      id={keyName}
                      name={keyName}
                      type={subType}
                      onChange={onChangeInput}
                      disabled={
                        defaultPaymentInfo && defaultPaymentInfo.is_discarded
                      }
                      value={formik.values[keyName] || ''}
                      //required={required}
                      maxLength={maxLength}
                      onBlurCallback={onBlurCallback}
                      isError={
                        formik.touched[keyName] && formik.errors[keyName]
                          ? true
                          : false
                      }
                      errorMessage={
                        formik.touched[keyName] && formik.errors[keyName]
                          ? formik.errors[keyName]
                          : null
                      }
                      version='v2'
                      {...restProps}
                    />
                  </Wrapper>
                );

              case 'select':
                return (
                  <Wrapper
                    key={keyName}
                    item={fieldItem}
                    containerClass='row mb20'
                  >
                    <SelectComponent
                      key={formik.values[keyName].value || keyName}
                      id={keyName}
                      name={keyName}
                      optionsList={options[keyName]}
                      placeholder={label}
                      defaultValue={formik.values[keyName] || null}
                      value={formik.values[keyName] || null}
                      onChange={onChangeSelect}
                      isDisabled={
                        defaultPaymentInfo && defaultPaymentInfo.is_discarded
                      }
                      className='form-control'
                      isMulti={isMulti || false}
                      version='v2'
                      errorMessage={
                        formik.touched[keyName] && formik.errors[keyName]
                          ? formik.errors[keyName]
                          : null
                      }
                    />
                  </Wrapper>
                );

              case 'date':
                return (
                  <Wrapper key={keyName} item={fieldItem}>
                    <div className='datepicker_custom'>
                      <CustomDatePicker
                        name={keyName}
                        disabled={
                          defaultPaymentInfo && defaultPaymentInfo.is_discarded
                        }
                        selected={formik.values[keyName]}
                        onDateChange={handleDateChange}
                      />
                    </div>
                  </Wrapper>
                );
              case 'readable':
                if (!defaultPaymentInfo[keyName]) return null;
                return (
                  <Wrapper
                    key={keyName}
                    item={fieldItem}
                    containerClass={`row ${styles.readable}`}
                  >
                    <div>{defaultPaymentInfo[keyName]}</div>
                  </Wrapper>
                );
              default:
                return null;
            }
          })}
          <div className='d-flex justify-content-between align-items-center mb20 mt20'>
            {defaultPaymentInfo && !defaultPaymentInfo.is_discarded ? (
              <>
                <CustomButton
                  type='button'
                  label='Submit'
                  disabled={defaultPaymentInfo.is_validated || false}
                  onClick={formik.handleSubmit}
                />

                {defaultPaymentInfo && defaultPaymentInfo.is_validated && (
                  <CustomButton
                    type='button'
                    onClick={() =>
                      onAddMore(activeImageId, newPaymentProof, addMoreDataId)
                    }
                    label='Add More'
                  />
                )}
              </>
            ) : null}

            <FinanceDiscardProofModal
              onDiscard={onDiscard}
              activeImageId={activeImageId}
              imageData={defaultPaymentInfo}
            />

            {/* <Modal
              clickHandler={handleDiscard}
              text={`Do you want to ${
                defaultPaymentInfo && defaultPaymentInfo.is_discarded
                  ? Locale.VALIDATE
                  : Locale.DISCARD
              } ?`}
              buttonText={
                defaultPaymentInfo && defaultPaymentInfo.is_discarded
                  ? Locale.VALIDATE
                  : Locale.DISCARD
              }
            /> */}
          </div>
        </form>
      ) : (
        <p>{Locale.NO_DATA}</p>
      )}
    </>
  );
};

export default CPaymentForm;
