import React, { useState, useEffect } from 'react';
import './style.css';
import { withRouter } from 'react-router-dom';
import Popup from '../../../../shared/popup/component';
import LoaderWithAccessControl from '../../../../shared/components/loader-with-access-control';
import { RC_VERIFY_COLUMNS, RC_VERIFICATION_STATUSES } from './constants';
import InputSwitch from '../../../../shared/components/input-switch';
import { rcManagementService } from '../../../../services';
import { showToastMessages } from '../../../../shared/utils/helper';
import { RcVerificationLogs } from '../../rc-verification-logs';

const RcOthersVerify = ({ match }) => {
  const dealId = match.params.id;
  const [rcData, setRcData] = useState([]);
  const [isRefreshPopupOpen, setIsRefreshPopupOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(()=>{
    if (dealId) {
      rcManagementService.FetchRcVerificationData(dealId, true).then((resp)=>{
        if (!!resp.data) {
          setRcData(resp.data.rc_verification_details);
        }
      }).catch((err)=>{
        console.log({err});
      });
    }
  },[dealId]);

  const onChangeText = (e, index, row, column) => {
    let data = [...rcData];
    let updatedRow = {...row};
    if (column.value === 'status_value') {
      updatedRow = {...updatedRow, status_value: e.label, status: e.value};
    } else if (column.value === 'remarks')  {
      updatedRow = {...updatedRow, remarks: e}
    }
    data[index] = updatedRow;
    setRcData(data);
  };

  const getList = (columnName) => {
    return columnName === 'status_value' ? RC_VERIFICATION_STATUSES : [];
  };

  const getRefreshData = () => {
    setIsRefreshing(true);
    rcManagementService.FetchRefreshRcVerificationData(dealId, true).then((resp)=>{
      if (!!resp.data) {
        setRcData(resp.data.rc_verification_details);
      }
    }).catch((err)=>{
      console.log({err});
    }).finally(()=>{
      setIsRefreshPopupOpen(false);
      setIsRefreshing(false);
    });
  };

  const onSave = () => {
    let payload = {
      rc_verification_details: rcData,
    };
    payload = {...payload, rcApplicationId: dealId}
    setIsRefreshing(true);

    rcManagementService.SaveRcVerificationData(payload, true).then((resp)=>{
      setRcData(resp.data.rc_verification_details);
      showToastMessages(resp.message);
    }).catch((err)=>{
      console.log({err});
    }).finally(()=>{
      setIsRefreshing(false);
    });
  };

  const generateRows = () => {
    return (
      <>
      {rcData.map((rowItem, rowIndex) => {
          return (
            <div className='row mt-2'>
              {RC_VERIFY_COLUMNS.map((colItem, colIndex)=>{
                return (
                  <div className={colItem.className} key={`key-${colItem.value}-${rowIndex}-${colIndex}`}>
                    <InputSwitch 
                      param={colItem.type}
                      type={colItem.type}
                      value={rowItem[colItem.value]}
                      onChange={e=> onChangeText(e, rowIndex, rowItem, colItem)}
                      list={colItem.type === 'select' ? getList(colItem.value) : null}
                      readOnly={colItem.readOnly}
                    />
                  </div>
                );
              })}
            </div>
          );
        })
      }
      </>
    );
  };
  return (
    <LoaderWithAccessControl>
      <>
        <div className='row d-flex justify-content-between mt-2'>
          <div className='col-2'>
            <h3>Verification</h3>
          </div>
          <div>
            <button className='btn btn-primary' onClick={()=> setIsRefreshPopupOpen(!isRefreshPopupOpen)} disabled={isRefreshing}>Refresh</button>
            <Popup isOpen={isRefreshPopupOpen} close={()=> setIsRefreshPopupOpen(false)} closeOnOutside={false} contentStyles={{ width: '500px' }}>
              <div className="row d-flex justify-content-between mt-5">
                <div  className='col-1'/>
                <label>Are you sure you want to fetch Vahan data?</label>
                <div/>
              </div>
              <div className="row">
                <div className='col-4'></div>
                <div className='col-md-6 col-md-offset-3'>
                  <button className="btn btn-primary" onClick={()=> getRefreshData()} disabled={isRefreshing}>Submit</button>
                </div>
              </div>
            </Popup>
          </div>
        </div>
        <>
          {rcData && rcData.length > 0 ? 
            <>
              <div className='row mt-2'>
                {RC_VERIFY_COLUMNS.map((item, index)=>{
                  return (
                    <div className={item.className}><h4>{item.label}</h4></div>
                  );
                })}
              </div>
              {generateRows()}
              <div className='row d-flex justify-content-between'>
                <div className='col-2'>
                  <button className='btn btn-primary' onClick={onSave} disabled={isRefreshing}>Save</button>
                </div>
              </div>
            </>
          :
          <div className="row"> 
            <div class="col-md-12 offset-md-2">
              <p>No data available</p>
            </div> 
          </div>
        }
        </>
        <RcVerificationLogs dealId={dealId} />
      </>
    </LoaderWithAccessControl>
  );
};
export default withRouter(RcOthersVerify);