import React, { useState, useEffect } from 'react';
import Accordion from '../../../shared/accordion';
import ArrowIcon from '../../../shared/arrow';
import { rcManagementService } from '../../../services';
import AgentLog from '../AgentLog/component';
import { httpConstants } from '../../constants/httpConstants';
import TabularLogs from '../tabular-logs';
import { TABLE_HEADERS } from './constants';
import CTextArea from '../../../shared/components/custom-textarea';
import CustomPagination from '../custom-pagination/component';
import { useIsRcOthers } from '../../../shared/hooks';

const Remarks = ({
  remarksId,
  remarksType,
  remarks,
  remarksOps,
  fetchRcCasetypeRemarksConnected
}) => {
  const [payload, updatePayload] = useState({
    id: null,
    remarks_type_id: remarksId,
    remarks_type: remarksType,
    remarks_text: '',
    created_at: null,
    created_by: null
  });

  const isOtherRcView = useIsRcOthers();

  useEffect(() => {
    fetchRemarks();
  }, []);

  const fetchRemarks = () => {
    fetchRcCasetypeRemarksConnected(isOtherRcView, remarksId, remarksType);
    fetchRcCasetypeRemarksConnected(isOtherRcView, remarksId, remarksType, true);
  };

  const onChange = (e) => {
    const targetValue = e.target.value;
    // const targetName = e.target.name;
    updatePayload({ ...payload, remarks_text: targetValue });
  };

  const saveCaseRemarks = (payload) => {
    return isOtherRcView ? rcManagementService.saveOthersRcCasetypeRemarks(payload) : rcManagementService.saveRcCasetypeRemarks(payload);
  }

  const saveRemarks = () => {
    if (payload.remarks_text && payload.remarks_type_id) {
      saveCaseRemarks(payload).then((response) => {
        if (response) {
          updatePayload({ ...payload, remarks_text: '' });
          fetchRemarks();
        }
      });
    }
  };

  const handlePageChangeRemarks = (page) => {
    fetchRcCasetypeRemarksConnected(isOtherRcView, remarksId, remarksType, false, page);
  }

  const handlePageChangeRemarksOps = (page) => {
    fetchRcCasetypeRemarksConnected(isOtherRcView, remarksId, remarksType, true, page);
  }

  return (
    <Accordion totalItems={1}>
      {({ onClick, currentVisibleStates }) => {
        return (
          <Accordion.Content>
            <Accordion.Heading>
              <h2
                className='d-flex justify-content-between fwb sellerDocuments p0 m0'
                onClick={() => onClick({ index: 0 })}
              >
                Remarks
                <ArrowIcon
                  grey={'GreyArrow'}
                  rotateBy={currentVisibleStates[0] ? 180 : 90}
                />
              </h2>
            </Accordion.Heading>
            <Accordion.Body>
              <div
                className={
                  currentVisibleStates[0]
                    ? 'accordionShow mt20 top-border'
                    : 'accordionHide'
                }
              >
                <CTextArea
                  className={'form-control mb20 mt20'}
                  value={payload.remarks_text}
                  onChange={onChange}
                />
                <button
                  type='button'
                  onClick={saveRemarks}
                  className='btn btn-primary mb20 '
                >
                  Save Remarks
                </button>
                <TabularLogs
                  label='Remark Logs (City Ops)'
                  logKey='REMARKS'
                  headers={TABLE_HEADERS}
                  logs={((remarksOps || {}).response || [])}
                />

                { remarksOps && <CustomPagination
                  page={remarksOps.page}
                  totalRecords={remarksOps.totalRecords}
                  handlePageChange={handlePageChangeRemarksOps}
                  recordsOnPage={(remarksOps.response || []).length}
                  totalPages={remarksOps.totalPages}
                  itemsCountPerPage={5}
                /> }

                <TabularLogs
                  label='Remark Logs'
                  logKey='REMARKS'
                  headers={TABLE_HEADERS}
                  logs={((remarks || {}).response || [])}
                />

                { remarks && <CustomPagination
                  page={remarks.page}
                  totalRecords={remarks.totalRecords}
                  handlePageChange={handlePageChangeRemarks}
                  recordsOnPage={(remarks.response || []).length}
                  totalPages={remarks.totalPages}
                  itemsCountPerPage={5}
                /> }
              </div>
            </Accordion.Body>
          </Accordion.Content>
        );
      }}
    </Accordion>
  );
};

export default Remarks;
