import React, { useState, useEffect } from 'react';
import InputSwitch from '../../../shared/components/input-switch';
import './styles.css';
import { INPUT_BOX_DETAIL } from './constants';
import { DEALER_CAR_SOURCE_OPTIONS } from '../../../shared/utils/constants/inventory';
import DealerMarkupSlabs from '../dealer-markupslabs';
import { withRouter } from 'react-router-dom';
import ArDealer from '../ar-dealer-view';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';
import Button from '../../../shared/components/button';
const DealerForm = ({
  fetchStates,
  fetchCities,
  fetchZones,
  match,
  fetchDealerDetailConnected,
  detail,
  removeDealerDetailConnected,
  fetchDealerFormConnected,
  addDealerConnected,
  history
}) => {
  const [dealerData, setDealerData] = useState();
  const [states, setStateList] = useState();
  const [cities, setCityList] = useState();
  const [zones, setZonesList] = useState();
  const [redirect, setRedirect] = useState(false);
  const dealerId = match.params.id;
  const submit = (e) => {
    e.preventDefault();
    const data = {
      dealerId: dealerData && dealerData.dealerId,
      dealerCode: dealerData && dealerData.dealerCode,
      name: dealerData && dealerData.name,
      mobileNumber: dealerData && dealerData.mobileNumber,
      email: dealerData && dealerData.email,
      dealerType: dealerData && dealerData.dealerType,
      addressLine1: dealerData && dealerData.addressLine1,
      addressLine2: dealerData && dealerData.addressLine2,
      cityId: dealerData && dealerData.cityId,
      stateId: dealerData && dealerData.stateId,
      zoneId: dealerData && dealerData.zoneId,
      status: dealerData && dealerData.status ? 1 : 0,
      dealerMarkupSlabs:
        dealerData && dealerData.dealerMarkupSlabs
          ? dealerData && dealerData.dealerMarkupSlabs
          : []
    };
    addDealerConnected(data);
    setRedirect(true);
  };
  useEffect(() => {
    if (dealerId) {
      fetchDealerDetailConnected(match.params.id);
    } else {
      removeDealerDetailConnected();
      fetchDealerFormConnected();
    }
  }, [dealerId]);
  useEffect(() => {
    if (detail) {
      setDealerData(detail);
    }
  }, [detail]);

  async function fetchData() {
    const stateList = await fetchStates();
    setStateList(stateList);
    const zoneList = await fetchZones();
    setZonesList(zoneList);
  }
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    async function fetchData() {
      let stateId = dealerData && dealerData.stateId;
      if (stateId) {
        const list = await fetchCities({ stateId });
        setCityList(list);
      }
    }
    fetchData();
  }, [dealerData && dealerData.stateId]);
  const onChangeText = (name, value) => {
    let details = { ...dealerData };
    if (name === 'stateId' || name === 'cityId' || name === 'zoneId') {
      onChangeDropdown(name, value);
    } else if (name === 'dealerType') {
      onChangeDropdown(name, value);
    } else {
      details[name] = value;
      setDealerData(details);
    }
  };

  const onChangeDropdown = (name, value) => {
    const valueMap = {
      stateId: {
        key: 'stateId',
        values: states,
        nameKey: 'stateName'
      },
      cityId: {
        key: 'cityId',
        values: cities,
        nameKey: 'cityame'
      },
      dealerType: {
        key: 'dealerType',
        values: DEALER_CAR_SOURCE_OPTIONS,
        nameKey: 'dealerType'
      },
      zoneId: {
        key: 'zoneId',
        values: zones,
        nameKey: 'zoneId'
      }
    };
    let details = { ...dealerData };
    if (name === 'dealerType') {
      let dealerTypeWithMarkupSlabs =
        dealerData && dealerData.dealerTypeWithMarkupSlabs;
      let mappedDealerTypeMarkupSlab = [];
      dealerTypeWithMarkupSlabs &&
        dealerTypeWithMarkupSlabs.forEach((row) => {
          mappedDealerTypeMarkupSlab[row.dealerType] = row.dealerMarkupSlabs;
        });
      setDealerData({
        ...details,
        dealerMarkupSlabs: mappedDealerTypeMarkupSlab[value.value],
        [valueMap[name].nameKey]: value.label,
        [valueMap[name].key]: value.value
      });
    } else {
      setDealerData({
        ...details,
        [valueMap[name].nameKey]: value.label,
        [valueMap[name].key]: value.value
      });
    }
  };

  const getList = (type) => {
    switch (type) {
      case 'stateId':
        return stateList();
      case 'cityId':
        return cityList();
      case 'zoneId':
        return zoneList();
      case 'dealerType':
        return dealerTypeList();
      default:
        return [];
    }
  };
  const stateList = () => {
    const list =
      states &&
      states.map((option, index) => {
        return { value: option.stateId, label: option.stateName };
      });
    return list || [];
  };
  const cityList = () => {
    const list =
      cities &&
      cities.map((option, index) => {
        return { value: option.cityId, label: option.cityName };
      });
    return list || [];
  };
  const zoneList = () => {
    const list =
      zones &&
      zones.data &&
      zones.data.data.map((option, index) => {
        return { value: option.id, label: option.zone };
      });
    return list || [];
  };
  const dealerTypeList = () => {
    const list = DEALER_CAR_SOURCE_OPTIONS.map((option, index) => {
      return { value: option.value, label: option.label };
    });
    return list || [];
  };
  const onChangeChechbox = (e, name) => {
    const details = { ...dealerData };
    details[name] = e.target.checked;
    setDealerData({ ...details });
  };
  const handleSlabChange = (event, slab) => {
    if (dealerData && dealerData.dealerMarkupSlabs.length > 0) {
      let dealerMarkupSlabs = dealerData && dealerData.dealerMarkupSlabs;
      dealerMarkupSlabs.forEach((savedSlab, key) => {
        if (savedSlab.start == slab.start && savedSlab.end == slab.end) {
          dealerMarkupSlabs[key] = {
            start: slab.start,
            end: slab.end,
            markUpValue: event.target.value
          };
        }
      });

      setDealerData({ ...dealerData, dealerMarkupSlabs });
    }
  };
  useEffect(() => {
    if (redirect) {
      history.push('/dealers');
    }
  }, [redirect]);
  const dispalyData = (item) => {
    let value = dealerData && dealerData[item['value']];

    let list = getList(item.value);

    if (item.value === 'stateId') {
      return filterData(list, dealerData && dealerData.stateId, item.value);
    } else if (item.value === 'cityId') {
      return filterData(list, dealerData && dealerData.cityId, item.value);
    } else if (item.value === 'dealerType') {
      return filterData(list, dealerData && dealerData.dealerType, item.value);
    } else if (item.value === 'zoneId') {
      return filterData(list, dealerData && dealerData.zoneId, item.value);
    } else {
      return value;
    }
  };
  const filterData = (list, id, type) => {
    if (type === 'stateId' || type === 'cityId') {
      const result = list.filter((item) => item.value === parseInt(id));
      return result;
    } else if (type === 'dealerType' || type === 'zoneId') {
      const result = list.filter((item) => item.value === id);
      return result;
    }
  };
  return (
    <>
      <LoaderWithAccessControl>
        <form onSubmit={(e) => submit(e)}>
          <div className='boxs'>
            <div className='row'>
              <div className='box-body col-md-12'>
                {INPUT_BOX_DETAIL.map((item, index) => {
                  return (
                    <div
                      className='float-left form-group col-md-6 dealer-wrapper'
                      key={index}
                    >
                      <label className='font-weight-bold'>
                        <span>{item.label}</span>
                        <span>{item.required ? ' *' : ''}</span>
                      </label>
                      <InputSwitch
                        min={item.min}
                        max={item.max}
                        type={item.type}
                        param={item.type}
                        item={item}
                        name={item['value']}
                        value={dispalyData(item)}
                        onChange={onChangeText}
                        list={getList}
                        readOnly={
                          detail && detail.dealerId ? item.disabled : false
                        }
                        disabled={
                          detail && detail.dealerId ? item.disabled : false
                        }
                        required={item.required}
                        onChangeChechbox={(e) =>
                          onChangeChechbox(e, item['value'])
                        }
                      />
                    </div>
                  );
                })}
              </div>
              <DealerMarkupSlabs
                dealerData={dealerData}
                handleSlabChange={handleSlabChange}
              />
            </div>
            <Button label='Save' type={submit} />
          </div>
        </form>
        {detail && detail.dealerId && <ArDealer dealerId={detail.dealerId} />}
      </LoaderWithAccessControl>
    </>
  );
};

export default withRouter(DealerForm);
