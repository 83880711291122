const statusDependencyList = [
  {
    rc_transfer_status: 'File Received',
    dependentKey: 'file_rc_team_date',
    dependent: 'File To Rc Team Date'
  },
  {
    rc_transfer_status: 'Handed over to the Agent',
    dependentKey: 'agent_id',
    dependent: 'Agent Name'
  },
  {
    rc_transfer_status: 'E-Receipt Issued',
    dependentKey: 'e_receipt_issue_date',
    dependent: 'E-receipt issue date'
  },
  // {
  //   rc_transfer_status: 'RC transfer completed',
  //   dependentKey: 'rc_transfer_completion_date',
  //   dependent: 'RC transfer completion date'
  // },
  {
    rc_transfer_status: 'Handed over to Buyer',
    dependentKey: 'rc_handover_buyer_method',
    dependent: 'RC handover to buyer method'
  },
  {
    rc_transfer_status: 'Physical RC received',
    dependentKey: 'physical_rc_receiving_date',
    dependent: 'Physical RC received'
  }
];

const transferRequiredFields = [
  { key: 'new_agent_id', label: 'New Agent', type: 'string' },
  { key: 'new_agent_cost', label: 'New Agent Cost', type: 'cost' },
  { key: 'previous_agent_payment', label: 'Prev- Agent payment', type: 'cost' }
];
const file_status = {
  file_received: { label: 'File Received', value: 'File Received' },
  handed_over_to_agent: {
    label: 'Handed over to the Agent',
    value: 'Handed over to the Agent'
  },
  e_receipt_issued: { label: 'E-Receipt Issued', value: 'E-Receipt Issued' },
  rc_transfer_completed: {
    label: 'RC transfer completed',
    value: 'RC transfer completed'
  },
  handed_over_to_buyer: {
    label: 'Handed over to Buyer',
    value: 'Handed over to Buyer'
  },
  physical_rc_received : { label: 'Physical RC received', value: 'Physical RC received' }
};

export default {
  statusDependencyList,
  transferRequiredFields,
  file_status
};
