import { BANK_STATEMENT_STATUS, FINANCE_STATUS, SIMPLER_SOURCE } from "../components/shared/Constants";
import { DataService } from "../service";
import { getLoanStatus, getPreApprovedData } from "../utils/mockData";
// fetchBankStatementStatus
// Pending - Not Uploaded
// Pending - Incomplete Upload
// Completed
// Rejected

export const fetchBankStatementStatus = (userId, dispatch) => {
  DataService.fetchBankStatementStatus(userId)
    .then((resp)=>{
      const {data, code, message} = resp;
      if (code === "SUCCESS200") {
        const {uploaded, bankStatementStatus='', bankStatementRejectedReason=''} = data || {};
        dispatch(
        {
          type: 'bankStatementStatus',
          bankStatementStatus : !!uploaded && bankStatementStatus && BANK_STATEMENT_STATUS.COMPLETED === bankStatementStatus ? BANK_STATEMENT_STATUS.COMPLETED : bankStatementStatus
        });
        dispatch(
        {
          type: 'bankStatementRejectedReason',
          bankStatementRejectedReason : bankStatementRejectedReason
        });
      } else if (code === "ERROR500") {
        dispatch(
        {
          type: 'bankStatementStatus',
          bankStatementStatus : BANK_STATEMENT_STATUS.REJECTED
        });
        dispatch(
        {
          type: 'bankStatementRejectedReason',
          bankStatementRejectedReason : message
        });
      }
    }).catch((err)=>{
      dispatch(
        {
          type: 'bankStatementStatus',
          bankStatementStatus : BANK_STATEMENT_STATUS.REJECTED
        }); 
      dispatch(
        {
          type: 'bankStatementRejectedReason',
          bankStatementRejectedReason : err.message
        });
    });
};

export const fetchFinance = (userId, state, dispatch, cfForm, setPreApprovedData) => {
  if (!!cfForm && cfForm.formStatus === FINANCE_STATUS.FILLED) {
    dispatch({
      type: 'financeStatus',
      financeStatus : FINANCE_STATUS.FILLED
    });
    fetchBankStatementStatus(userId, dispatch);
    fetchLoanStatus(userId, dispatch, cfForm, state, setPreApprovedData);
  } else if (!!cfForm && !!cfForm.formStatus){
    dispatch({
      type: 'financeStatus',
      financeStatus: FINANCE_STATUS.INCOMPLETE
    });
  } else {
    dispatch({
      type: 'financeStatus',
      financeStatus: FINANCE_STATUS.DONTKNOW
    });
  }
};

export const fetchLoanStatus = (userId, dispatch, cfForm, state, setPreApprovedData) => {
  let loanStage = {};
  DataService.fetchLoanStatus(userId).then((resp)=> {
      const {currentLoanStage = {}, currentLoanStageId, loanStages} = resp;
      const loanStageTemp = !!loanStages && !!currentLoanStageId && loanStages.find(item => item.id === currentLoanStageId);
      loanStage = currentLoanStage || loanStageTemp;
      dispatch({
          type: 'currentLoanStage',
          currentLoanStage: currentLoanStage
      });
  }).finally(()=>{
    fetchPreBookingOffer(state, cfForm, loanStage, dispatch, setPreApprovedData);
  }); 
};

export const fetchPreBookingOffer = (state, cfData, loanStage, dispatch, setPreApprovedData) => {
    const payload = {
      ...cfData,
      source: SIMPLER_SOURCE.LMS,
      submittedBy: localStorage.getItem('email'),
      submitted: true,
    };
    // !!setIsLoading && setIsLoading(true);
    // const loanStageStatus = !!loanStage && !!loanStage.key && loanStage.key !== 'LoanApplicationReceived';
    DataService.preBookingOffer(payload)
      .then((resp)=> {
        // const respData = getPreApprovedData()
        const {data} = resp;
        !!setPreApprovedData && setPreApprovedData(data);
        const {pincodeServiceable=false, selectedOffer, offer, preApproved=false} = data || {};
        const {loanAmount=0} = selectedOffer || {};
        const {rejectionDetails=''} = offer || {}; // No reason found on preApproved=false and offer null
        
        dispatch({
          type: 'preApproved',
          preApproved: preApproved
        });

        dispatch({
          type: 'pincodeServiceable',
          pincodeServiceable: pincodeServiceable
        });
        
        if (!!pincodeServiceable) {
          if (!!preApproved) {
              if (!!loanStage && loanStage.key !== 'LoanApplicationReceived') {
                dispatch({
                  type: 'financeStatus',
                  financeStatus: loanStage.title 
                });
              } else {
                dispatch({
                  type: 'financeStatus',
                  financeStatus: state.bankStatementStatus.toLowerCase().includes('completed') ? FINANCE_STATUS.OFFER_BANKING_COMPLETE : FINANCE_STATUS.OFFER_BANKING_INCOMPLETE
                });
              }
              if (loanAmount > 0) {
                dispatch({
                    type: 'loanStatus',
                    loanStatus: 'Post Credit Approved'
                });
                dispatch({
                    type: 'buyerCreditLimit',
                    buyerCreditLimit: loanAmount
                });
              } else {
                dispatch({
                    type: 'loanStatus',
                    loanStatus: 'Pre Credit Approved'
                });
              }
          } else {
            dispatch({
                type: 'financeStatus',
                financeStatus: FINANCE_STATUS.OFFER_REJECTED
            });
          }
        } else {
          dispatch({
              type: 'financeStatus',
              financeStatus: FINANCE_STATUS.OFFER_REJECTED
          });
          dispatch({
            type: 'cfRejectedReason',
            cfRejectedReason: FINANCE_STATUS.NON_SERVICE 
          });
        }
        
      });
  };