import React, { useState } from 'react';
import DealInformation from '../rc-others-deal-info';
import { withRouter } from 'react-router-dom';
import { TABS } from './constant';
import CaseType from '../rc-others-casetype';
import RcTracker from '../rc-others-tracker';
import RcDocuments from '../rc-others-documents';
import Card from '../../../../shared/card';
import { formatDate } from '../../../../shared/utils/helper';
import RcVerify from '../rc-others-verify';
import { useRcOthers } from '../../../hooks/custom-hooks/rc-others-hook';
import { SERVICE_TYPE_KEYS } from '../rc-others-segment-form/constants';

function RcManagementOthersTabs({ dealInfoData }) {
  const { delayReasons } = useRcOthers();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleOnClick = (index) => {
    setSelectedIndex(index);
  };

  const tabs = () => {
    return (
      <ul className='nav nav-tabs'>
        {Object.keys(TABS).map((tabKey, index) => {
            return (
              <li
                key={index}
                className={selectedIndex === index ? 'active' : ''}
                onClick={() => handleOnClick(index)}
              >
                <a>
                  <b>{TABS[tabKey]['label']}</b>
                </a>
              </li>
            );
        })}
      </ul>
    );
  };

  const cardList = [
    {
      title: 'Created Date',
      value: '',
      boxBg: 'bg-blue',
      key: 'createdDate',
      parser: (val) => val ? formatDate(val) : "--"
    },
    {
      title: 'UID',
      value: 'DEL#10000',
      boxBg: 'bg-sky-blue',
      key: 'dealIdentifier'
    },
    {
      title: 'Segment',
      value: 'Guaranteed Sales',
      boxBg: 'bg-purple',
      key: 'displayCarSource'
    },
    {
      title: 'Case Type',
      value: '',
      boxBg: 'bg-peach',
      key: 'rcCaseType'
    },
    {
      title: 'NBFC',
      value: '',
      boxBg: 'bg-purple',
      key: 'isNbfc'
    }
  ];

  return (
    <React.Fragment>
      <div className='col-md-12'>
        <div className='nav-tabs-custom no-box-shadow'>
          {cardList.map((item, i) => (
            <span key={i}>
              <Card
                title={item.title}
                value={item.parser ? item.parser(dealInfoData && dealInfoData[item.key]) : (dealInfoData && dealInfoData[item.key]) || '--'}
                boxBg={item.boxBg}
              />
            </span>
          ))}
          {tabs()}
          <div className='tab-content'>
            {selectedIndex === 0 && (
              <div
                className={selectedIndex === 0 ? 'tab-pane active' : 'tab-pane'}
              >
                <DealInformation />
              </div>
            )}
            {selectedIndex === 1 && (
              <div
                className={selectedIndex === 1 ? 'tab-pane active' : 'tab-pane'}
              >
                <CaseType dealInfoData={dealInfoData} />
              </div>
            )}
            {selectedIndex === 2 && (
              <div
                className={selectedIndex === 2 ? 'tab-pane active' : 'tab-pane'}
              >
                <RcDocuments delayReasons={delayReasons}/>
              </div>
            )}
            {selectedIndex === 3 && (
              <div
                className={selectedIndex === 3 ? 'tab-pane active' : 'tab-pane'}
              >
                <RcTracker delayReasons={delayReasons} />
              </div>
            )}
            {selectedIndex === 4 && (
              <div
                className={selectedIndex === 4 ? 'tab-pane active' : 'tab-pane'}
              >
                {dealInfoData && [SERVICE_TYPE_KEYS.RC_TRANSFER_SERVICE, SERVICE_TYPE_KEYS.CORRECTION_REQUIRED].includes(dealInfoData.serviceType) && (
                  <RcVerify />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default withRouter(RcManagementOthersTabs);
