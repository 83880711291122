import React from "react";
import { formatDate } from "../../../../shared/utils/helper";

export const COLUMNS = [
  {
    title: "Category",
    field: "category"
  },
  {
    title: "Status",
    field: "status"
  },
  {
    title: "Remarks",
    field: "remarks"
  },
  {
    title: "Updated By",
    field: "updatedBy"
  },
  {
    title: "Updated At",
    cell: ({ dataItem }) => <td>{formatDate(dataItem.updatedAt)}</td>
  },
]