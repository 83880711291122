import React from 'react';
import RcMGrid from '../../components/rc-Management-grid';
import withAdminLayout from '../../../shared/hoc/admin-layout';
import ErrorBoundary from '../../components/common/errorBoundary';
import { withAuthorization } from '../../../shared/utils/firebase';

const RcManagementGrid = () => {
  return (
    <div>
      <ErrorBoundary>
        <RcMGrid />
      </ErrorBoundary>
    </div>
  );
};
export default withAdminLayout(withAuthorization(() => true)(RcManagementGrid));
