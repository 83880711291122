import Types from './types';
import { dataService } from '../../../services';

// Global actions
import {
  setAccessLoaderConnected,
  resetAccessLoaderConnected
} from '../../../shared/components/loader-with-access-control/actions';
import { handleHttpStatusError } from '../../../services/helper';

//Fetch order inspection details
const fetchZonesListInit = () => ({
  type: Types.FETCH_ZONES_INIT
});

const fetchZonesListSuccess = (data) => ({
  type: Types.FETCH_ZONES_SUCCESS,
  payload: data
});

const fetchZonesListFail = (error) => ({
  type: Types.FETCH_ZONES_FAIL,
  error
});

const fetchZonesListConnected = () => (dispatch) => {
  dispatch(setAccessLoaderConnected());
  dispatch(fetchZonesListInit());
  return new Promise((resolve, reject) => {
    dataService
      .fetchZonesList()
      .then((resp) => {
        dispatch(fetchZonesListSuccess(resp.data));
        dispatch(resetAccessLoaderConnected());
        resolve(resp);
      })
      .catch((error) => {
        dispatch(fetchZonesListFail(error));
        handleHttpStatusError(error);
        reject(error);
      });
  });
};

export { fetchZonesListConnected };
