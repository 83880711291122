import Types from './types';
import { orderService } from '../../../services';

const fetchPricingLogsConnected = (id) => (dispatch) => {
  dispatch(fetchPricingLogsRequested());
  return orderService
    .fetchPricingLogs(id)
    .then((response) => {
      const { data } = response;
      dispatch(fetchPricingLogsSuccess(data));
    })
    .catch((error) => {
      dispatch(dispatch(fetchPricingLogsFailure(error)));
    });
};

const fetchPricingLogsRequested = () => {
  return {
    type: Types.FETCH_PRICING_LOGS_REQUESTED
  };
};

const fetchPricingLogsSuccess = (data) => {
  return {
    type: Types.FETCH_PRICING_LOGS_SUCCESS,
    payload: data
  };
};

const fetchPricingLogsFailure = (error) => {
  return {
    type: Types.FETCH_PRICING_LOGS_FAILURE,
    payload: error
  };
};

export { fetchPricingLogsConnected };
