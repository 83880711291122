import Types from './types';

const resetGridModelConnected = () => ({
  type: Types.RESET_GRID_MODEL
});

const resetAllGridModelConnected = () => ({
  type: Types.RESET_ALL_GRID_MODEL
});

const setGridModelConnected = (data) => ({
  type: Types.SET_GRID_MODEL,
  payload: data
});

const setGridFilterConnected = (data) => ({
  type: Types.SET_GRID_FILTER,
  payload: data
});

const resetGridFilterConnected = (data) => ({
  type: Types.RESET_GRID_FILTER,
  payload: data
});

const updateGridFilterConnected = (data) => ({
  type: Types.UPDATE_GRID_FILTER,
  payload: data
});

export {
  setGridFilterConnected,
  resetGridFilterConnected,
  setGridModelConnected,
  resetGridModelConnected,
  updateGridFilterConnected,
  resetAllGridModelConnected
};
