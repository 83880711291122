import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  dealInfo: {},
  isLoading: false,
  error: false,
  message: null
};
export const setfinanceDealInfoInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const setfinanceDealInfoSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action;
  return { ...state, dealInfo: data };
};
export const setfinanceDealInfoFail = (state = INITIAL_STATE, action) => {
  //Todo:-(sprint-12):-use the error message in ui
  const { error } = action;
  return { ...state, error: error, message: error.message, isLoading: false };
};

export const HANDLERS = {
  [Types.FETCH_FINANCE_DEALINFO_SUCCESS]: setfinanceDealInfoSuccess,
  [Types.FETCH_FINANCE_DEALINFO_INIT]: setfinanceDealInfoInit,
  [Types.FETCH_FINANCE_DEALINFO_FAIL]: setfinanceDealInfoFail
};

export default createReducer(INITIAL_STATE, HANDLERS);
