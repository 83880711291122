import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  FETCH_REGION_LIST_SUCCESS
  UPLOAD_CONFIG_RULES_INIT
  UPLOAD_CONFIG_RULES_END
`,
  {
    prefix: 'documentRuleEngine/'
  }
);
