import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CFinanceTracker from './component';

import {
  withAuthorization,
  withAdminLayout
} from '../../../shared/utils/firebase';

// const Component = () => {
//   return (
//     <ErrorBoundary>
//       <CFinanceTracker />
//     </ErrorBoundary>
//   );
// };

const mapStateToProps = (state) => {
  const {
    financeDealInfo: { dealInfo }
  } = state;
  return {
    dealInfo
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(CFinanceTracker);
export default withAdminLayout(withAuthorization(() => true)(connected));
