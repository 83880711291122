import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ShowInDocketToggle } from "./component";
import { fetchDocketManagementDocs, updateDocketDocVisibility } from "../../docket-management/actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateDocketDocVisibilityConnect: updateDocketDocVisibility,
  fetchDocketManagementDocsConnect: fetchDocketManagementDocs
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShowInDocketToggle);