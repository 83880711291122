export const FIELDS = {
  refund_reason: {
    label: 'Reason',
    keyName: 'reason',
    type: 'select',
    required: true,
    elementType: 'select'
  }
};

export const getInitValues = () => {
  let initObj = {};
  Object.keys(FIELDS).forEach((item) => {
    initObj[item] = '';
  });
  return initObj;
};

export const CANCEL_REASON = {
  REFUND_IN_WRONG_DEAL: {
    label: 'Refund in wrong deal',
    value: 'Refund in wrong deal'
  },
  INCORRECT_REFUND_VALUE: {
    label: 'Incorrect refund value',
    value: 'Incorrect refund value'
  },
  REFUND_TRANSACTION_FAILED: {
    label: 'Refund transaction failed',
    value: 'Refund transaction failed'
  }
};
