import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  message: null,
  rcAccessDetails: {},
  lists: {},
  addSuccess: ''
};

export const fetchRcUserDetailInit = (state = INITIAL_STATE) => {
  return {
    ...state,
    userDetails: {},
    isLoading: true,
    isError: false,
    message: null
  };
};

export const fetchRcUserDetailSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    isError: false,
    rcAccessDetails: action.payload
  };
};
export const fetchRCUserCityListSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    lists: action.payload
  };
};

export const fetchRCUserDetailFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    isError: true,
    message: action.payload
  };
};
export const removeUserData = (state = INITIAL_STATE) => {
  return { ...state, rcAccessDetails: {} };
};

export const HANDLERS = {
  [Types.FETCH_RCUSER_INIT]: fetchRcUserDetailInit,
  [Types.FETCH_RCUSER_SUCCESS]: fetchRcUserDetailSuccess,
  [Types.FETCH_RCUSER_FAILURE]: fetchRCUserDetailFailure,
  [Types.FETCH_RCUSER_FILTERLIST_SUCCESS]: fetchRCUserCityListSuccess
};

export default createReducer(INITIAL_STATE, HANDLERS);
