export const LABELS = [
  {
    label: 'Representative Name',
    value: 'name',
    disabled: false,
    type: 'text',
    required: true
  },
  {
    label: 'Representative Number',
    value: 'contactNumber',
    disabled: false,
    type: 'number',
    required: true,
    maxLength: 10
  },
  {
    label: 'Shop Address',
    value: 'shopAddress',
    disabled: false,
    type: 'text',
    required: true
  },

  {
    label: 'State',
    value: 'stateId',
    disabled: false,
    type: 'dropDown',
    required: true,
    listName: 'stateId'
  },
  {
    label: 'City',
    value: 'cityId',
    disabled: false,
    type: 'dropDown',
    required: true,
    listName: 'cityId'
  },
  {
    label: 'Zones',
    value: 'zoneId',
    disabled: false,
    type: 'dropDown',
    required: true,
    listName: 'zoneId'
  },
  {
    label: 'Pin Code',
    value: 'pincode',
    disabled: false,
    type: 'number',
    required: true,
    maxLength: 6
  },
  {
    label: 'Yard',
    value: 'storeLocationId',
    disabled: false,
    type: 'dropDown',
    required: true,
    listName: 'storeLocationId'
  },
  {
    label: 'Latitude',
    value: 'latitude',
    disabled: false,
    type: 'text',
    required: false
  },
  {
    label: 'Longitude',
    value: 'longitude',
    disabled: false,
    type: 'number',
    required: false
  },
  {
    label: 'Active',
    value: 'status',
    disabled: false,
    type: 'checkBox',
    required: false
  }
];
