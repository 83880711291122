import { config } from './../config/local';
import { IS_ENV_DEV, IS_MOCK } from '../../shared/utils/constants';

export default (api) => {
    const fetchLanguageInfo = () => {
      if (IS_ENV_DEV && IS_MOCK) {
        const urlConfig = { url: config.api.c2c.language.path, baseURL: config.api.local.host };
        return api(urlConfig);
      } else {
        return api.get(config.api.c2c.language.path);
      }
    };
    return {
        fetchLanguageInfo
    };
};