import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    FETCH_ORDER_CAR_INIT
    FETCH_ORDER_CAR_SUCCESS
    FETCH_ORDER_CAR_FAILURE
    FETCH_ORDER_MAKE_LIST
    FETCH_ORDER_MODEL_LIST
    FETCH_ORDER_VARIANT_LIST
`,
  {
    prefix: 'orderCarTab/',
  }
);
