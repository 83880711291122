import { useEffect, useState } from 'react';

const useIsRcOthers = () => {
  let { pathname } = window.location;
  let [isRcOthers, setIsRcOthers] = useState(pathname.includes('others'));

  useEffect(() => {
    setIsRcOthers(pathname.includes('others'))
  }, [pathname]);

  return isRcOthers;
}

export default useIsRcOthers;