import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DocumentsConfigAdd from "./component";
import { addDocumentConfig, fetchDocumentConfig, updateAddConfigData } from "../documents-config/actions";

const mapStateToProps = ({
  documentsConfig: {
    attributes,
    isAttributesFetched,
    addConfig: addConfigData
  },
  documentsRegionList: regionList
}) => ({
  isAttributesFetched,
  attributes,
  regionList,
  addConfigData
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
  addDocumentConfigConnect: addDocumentConfig,
  updateAddConfigDataConnect: updateAddConfigData,
  fetchDocumentConfigConnect: fetchDocumentConfig
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsConfigAdd);
