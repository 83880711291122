import React, { useState, useEffect } from 'react';
import Accordion from '../../../shared/accordion';
import ArrowIcon from '../../../shared/arrow';
import { buddyScoringApi } from '../../../client/service';
import { withRouter } from 'react-router-dom';
import ZoneBuddyLead from '../zones-buddy-lead';
import './styles.css';

const ZonesBuddyScoring = ({ match }) => {
  const [buddyScoringData, setBuddyScoring] = useState([]);
  const id = match.params.id;
  useEffect(() => {
    buddyScoringApi
      .getBuddyScoring(id)
      .then((res) => {
        setBuddyScoring(res.data);
      })
      .catch((err) => {});
  }, []);
  const onChange = (e, index, key, subKey = null) => {
    if (subKey) {
      const buddyScoringClone = [...buddyScoringData];
      buddyScoringClone[index][e.target.name][subKey] = e.target.value;
      setBuddyScoring(buddyScoringClone);
    } else {
      const buddyScoringClone = [...buddyScoringData];
      buddyScoringClone[index][e.target.name] = e.target.value;
      setBuddyScoring(buddyScoringClone);
    }
  };
  const handleSave = () => {
    buddyScoringApi.submitgetBuddyScoring(match.params.id, buddyScoringData);
  };
  return (
    <Accordion>
      {({ onClick, currentVisibleStates }) => (
        <Accordion.Content>
          <div className='borderWrapper'>
            <Accordion.Heading>
              <h2
                className='font-weight-bold p0'
                onClick={() => onClick({ index: 0 })}
              >
                Buddy Scoring
                <ArrowIcon
                  grey={'GreyArrow'}
                  rotateBy={currentVisibleStates[0] ? 180 : 90}
                />
              </h2>
            </Accordion.Heading>
            <Accordion.Body>
              <div
                className={
                  currentVisibleStates[0]
                    ? 'accordionShow table mt20 top-border'
                    : 'accordionHide'
                }
              >
                {/* <ZoneBuddyLead /> */}
                {buddyScoringData.length > 0 ? (
                  <div>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th>Activities</th>
                          <th>Weightage</th>
                          <th>Benchmark</th>
                        </tr>
                      </thead>
                      {buddyScoringData &&
                        buddyScoringData.map((data, index) => {
                          return (
                            <tbody>
                              <tr key={index}>
                                <td>{data.event.event}</td>
                                <td>
                                  {' '}
                                  <input
                                    className='form-control'
                                    type='number'
                                    name='weight'
                                    min='0'
                                    value={data.weight || ''}
                                    onChange={(e) => onChange(e, index)}
                                    required
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <input
                                    className='form-control'
                                    type='number'
                                    name='benchmark'
                                    min='0'
                                    value={data.benchmark || ''}
                                    onChange={(e) => onChange(e, index)}
                                    required
                                  />
                                </td>
                              </tr>
                            </tbody>
                          );
                        })}
                    </table>
                    <div className='form-group col-md-12 text-right'>
                      <button onClick={handleSave} className='btn btn-primary'>
                        Save
                      </button>
                    </div>
                  </div>
                ) : (
                  <p>no data available</p>
                )}
              </div>
            </Accordion.Body>
          </div>
        </Accordion.Content>
      )}
    </Accordion>
  );
};

export default withRouter(ZonesBuddyScoring);
