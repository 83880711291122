import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const Accordion = ({ children, totalItems = 1, className }) => {
  const [currentVisibleStates, setCurrentVisibleStates] = useState(
    new Array(totalItems).fill(false)
  );

  const onAccordionClickHandler = ({ index }) => {
    const currentState = new Array(totalItems).fill(false);
    currentState[index] = !currentVisibleStates[index];
    setCurrentVisibleStates(currentState);
  };

  return (
    <ul className={`${className ? className : 'accordionList'}`}>
      {children({
        onClick: onAccordionClickHandler,
        currentVisibleStates
      })}
    </ul>
  );
};

Accordion.propTypes = {
  //children: PropTypes.object,
  totalItems: PropTypes.number
};

const Heading = ({ children }) => {
  return <React.Fragment>{children}</React.Fragment>;
};
Heading.propTypes = {
  children: PropTypes.object
};

const Body = ({ children }) => {
  return <React.Fragment>{children}</React.Fragment>;
};
Body.propTypes = {
  children: PropTypes.object
};
const Content = ({ children }) => {
  return <li>{children}</li>;
};
Content.propTypes = {
  //children: PropTypes.object
};

Accordion.Content = Content;
Accordion.Heading = Heading;
Accordion.Body = Body;

export default Accordion;
