import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoaderWithAccessControl from './component';
import {
  resetAccessLoaderConnected,
  setAccessLoaderConnected
} from './actions';

const mapStateToProps = (store) => {
  const {
    loaderInfo: { isLoading, isForbidden, isError, message }
  } = store;
  return { isLoading, isForbidden, isError, message };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetAccessControlConnected: resetAccessLoaderConnected,
      setLoaderConnected: setAccessLoaderConnected
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoaderWithAccessControl);
