import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AdminHeader from "./component";
import { HomeActions } from './actions'

const mapStateToProps = ({
    home: { sidebar, profile },
    user: { isLoggedIn, email },
    history
  }) => {
    return {
      sidebar,
      profile,
      isLoggedIn,
      email,
      history
    };
  };
  
  //actions being dispatached to invoke reducers
  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        toggleSidebar: HomeActions.toggleSidebar,
        toggleProfile: HomeActions.toggleProfile
      },
      dispatch
    );
  

export default connect(mapStateToProps, mapDispatchToProps)(AdminHeader);
