import { showToastMessages, console_in_dev } from '../../shared/utils/helper';
import {
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected,
  handleHttpStatusError
} from '../../services/helper';

export default (api) => {
  const getPricing = (id) => {
    dispatchSetLoaderConnected();
    return api
      .get(`/stores/pricing/${id}/`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        dispatchResetLoaderConnected();
        return data;
      })
      .catch((error) => {
        showToastMessages(error, false);
        handleHttpStatusError(error, true);
        return error;
      });
  };
  const submitPricingData = (id, data) => {
    // return api.post(`secure/buyer/appointments/${params.appId}`, params).then(resp => {
    return api
      .post(`/stores/add-pricing`, data)
      .then((resp) => {
        // const { data: { data } } = resp;
        // return data;
        showToastMessages('Added successfully');
        return resp.data;
      })
      .catch((error) => {
        showToastMessages(error, false);
        console.log({ error });
        return error;
      });
  };

  return { getPricing, submitPricingData };
};
