import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom';
import GridView from '../common/grid-view';
import { columns } from './constant';
import DocumentsConfigDataTransfer from '../documents-config-data-transfer';
import { DOCUMENTS_GRID_ID } from '../../constants/constants';

const DocumentsRegionList = ({
  regionList,
  fetchRegionListConnected,
  history
}) => {

  useEffect(() => {
    if(!regionList.allIds.length) {
      fetchRegionListConnected();
    }
  }, []);

  const handleRedirect = rowData => {
    history.push(`/documents/${rowData.id}`);
  }

  return (
    <div>
      <DocumentsConfigDataTransfer />
      <GridView
        uniqueGridId={DOCUMENTS_GRID_ID.REGION}
        columnDefs={columns}
        rowData={Object.values(regionList.byId)}
        onRedirect={handleRedirect}
      />
    </div>
  )
}

export default withRouter(DocumentsRegionList);