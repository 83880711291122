import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CityDetailConfig from './component';
import {
  fetchCityInspectionStoreInfoByIdConnected,
  updateSellerToCCFlag
} from './actions';
import { fetchZonesListConnected } from '../zones-grid/actions';
import { fetchCities } from '../../actions/cityAction';
import {fetchLanguageConnected} from '../../actions/languageActions'

const mapStateToProps = ({
  cityConfig: { detail },
  cityGridInfo: { cityGridData },
  zoneInfo,
  langaugeInfo
}) => {
  // debugger;
  return {
    detail,
    cityGridData,
    zoneInfo,
    langaugeInfo
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCityInspectionStoreInfoByIdConnected,
      fetchZonesListConnected,
      //TODO:-(Sp -13 to sp-14)Change fetchCity action according to new action structure (Akash)
      fetchCities,
      updateSellerToCCFlag,
      fetchLanguageConnected
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CityDetailConfig);
