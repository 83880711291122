import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FinanceGrid from './component';
import {
  setPageNumberConnected,
  fetchFinanceGridInfoConnected
} from './actions';
import {
  withAuthorization,
  withAdminLayout
} from '../../../shared/utils/firebase';
const mapStateToProps = (state) => {
  const {
    detail,
    financeGridInfo: { pageNumber }
  } = state;
  return {
    detail,
    pageNumber
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPageNumberConnected,
      fetchFinanceGridInfoConnected
    },
    dispatch
  );

const connected = connect(mapStateToProps, mapDispatchToProps)(FinanceGrid);
export default withAdminLayout(withAuthorization(() => true)(connected));
