import React from "react";
import "./styles.css";
import PropTypes from "prop-types";

const InputRadioButton = ({
    id = "",
    text = "",
    onClickCallback = () => { },
    labelId = "",
    value="",
    checked,
    onChange,
    name,
    isDisabled = false
}) => {
    const onClickHandler = (e) => {
        onClickCallback(e);
    };
    const showDisabled = () => {
        return (
            isDisabled ? <input id={id} type="radio" name={name} value={value} onChange={onClickHandler} checked={checked} disabled/> 
            : <input id={id} type="radio" name={name} value={value} onChange={onClickHandler} checked={checked}/>
        );
    };
    return (
        <div className="inputRadioButton">
            {/* <input id={id} type="radio" name={name} value={value} onChange={onClickHandler} checked={checked}/> */}
            {showDisabled()}
            <label htmlFor={labelId}>{text}</label>
        </div>
    );
};

InputRadioButton.propTypes = {
    text: PropTypes.string,
    id: PropTypes.string,
    labelId: PropTypes.string

};
export default InputRadioButton;
