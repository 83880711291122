import React, { Component } from 'react';
import { constants } from '../../../constants/constants';

class DropDownFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentValue: ''
    };
  }

  //Filter space and underscore from given string
  filterValue = (str) => {
    return str.replace(/[_-]/g, ' ');
  };

  //Convert string to title case
  titleCase = (str) => {
    if (str && str.length > 0) {
      let filteredValue = this.filterValue(str);
      const titleCase = filteredValue
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
      return titleCase;
    }
    return '';
  };

  //Auto Called on parent model changed
  onParentModelChanged(parentModel) {
    if (parentModel) {
      this.setState({
        currentValue: !parentModel ? '' : parentModel.filter
      });
    } else {
      this.setState({
        currentValue: ''
      });
    }
  }

  //Handle value change
  valueChanged = (event) => {
    this.setState(
      {
        currentValue: event.target.value
      },
      () => {
        let valueToUse = this.state.currentValue;
        this.props.parentFilterInstance(function(instance) {
          instance.onFloatingFilterChanged('equals', valueToUse);
        });
      }
    );
  };

  render() {
    const { name, options } = this.props;
    const optionsHtml = options.map((option) => {
      return (
        <option key={Math.random(5)} value={option.key}>
          {option.value}
        </option>
      );
    });

    return (
      <select
        key={Math.random(5)}
        className='gridSelectFilter'
        style={{ width: '95%' }}
        name={name}
        defaultValue={this.state.currentValue}
        onChange={(e) => this.valueChanged(e)}
      >
        <option value={constants.GRID_FILTER_BLANK_STRING}>All</option>
        {optionsHtml}
      </select>
    );
  }
}

export default DropDownFilter;
