import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import './styles.css';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';
import FinanceServiceSection from '../finance-service-section';

const FinanceServiceCharge = (props) => {
  const [serviceChargeData, setServiceChargeData] = useState();
  const [refresh, setRefresh] = useState();
  const [dealId] = useState(props.match.params.id);

  useEffect(() => {
    refresh &&
      props
        .fetchServiceChargeConnected(dealId)
        .then((response) => {
          response && setServiceChargeData(response);
          setRefresh(false);
        })
        .catch((error) => {
          setServiceChargeData();
        });
  }, [refresh]);

  useEffect(() => {
    props
      .fetchServiceChargeConnected(dealId)
      .then((response) => {
        response && setServiceChargeData(response);
        setRefresh(false);
      })
      .catch((error) => {
        setServiceChargeData();
      });
  }, []);

  return (
    <LoaderWithAccessControl>
      {serviceChargeData ? (
        <>
          <FinanceServiceSection
            type='buyer_services'
            label='Buyer Service Charges'
            data={serviceChargeData}
            refresh={setRefresh}
          />
          <FinanceServiceSection
            type='seller_services'
            label='Seller Service Charges'
            data={serviceChargeData}
            refresh={setRefresh}
          />
        </>
      ) : null}
    </LoaderWithAccessControl>
  );
};
export default withRouter(FinanceServiceCharge);
