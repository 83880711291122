import React, { useState, useEffect } from 'react';
import {
  SellerPricingDetails,
  CarDetails
} from '../../components/Seller-Executive';
import {
  DispositionDetails,
  InteractionJourney
} from '../../components/shared';
import { Label, Button } from 'reactstrap';
import { DataService } from '../../service';
import 'bootstrap/dist/css/bootstrap.css';
import '../Seller/style/style.css';
import NoResult from '../../images/no-result.png';
import qs from 'qs';
import { Redirect } from 'react-router-dom';
import Loader from '../../images/preloader.gif';
import AuditQuestionaire from '../../components/Audit-Questions';
import { isObject } from '../../../shared/utils/helper';

const Seller = () => {
  const dialerData = qs.parse(window.location.search.substring(1));
  // console.log('dialer', dialerData);
  const dialerCustomerInfo = JSON.parse(dialerData.customerInfo);
  const getParams = {
    appointment_id: dialerCustomerInfo.appointment_id,
    lms_lead_id: dialerCustomerInfo.lms_lead_id,
    sessionId: dialerData.sessionId,
    campaignId: dialerData.campaignId,
    crtObjectId: dialerData.crtObjectId,
    userCrtObjectId: dialerData.userCrtObjectId,
    userId: dialerData.userId,
    customerId: dialerData.customerId,
    phone: dialerData.phone,
    agentName: dialerData.agentName,
    dispositionCode: dialerData.dispositionCode,
    dispositionAttr: dialerData.dispositionAttr
  };

  const [showLoader, setShowLoader] = useState(true);
  const [showError, setShowError] = !getParams.appointment_id
    ? useState(true)
    : useState(false);
  const [source, setSource] = useState();
  const [state, setState] = useState();
  const [inspectionReportLink, setinspectionReportLink] = useState();
  const [seller, setSeller] = useState();
  const [car, setCar] = useState();
  const [dispositionData, setDispositionData] = useState();
  const [selectedCcDispositionId, setSelectedCcDispositionId] = useState();
  const [interactionJourney, setInteractionJourney] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidDisposition, setIsValidDisposition] = useState(false);
  const [disposition, setDisposition] = useState();
  const [ccLeadId, setCcLeadId] = useState();
  const [dialerResponseId, setDialerResponseId] = useState();
  const [postData, setPostData] = useState(false);
  const [redirectToThankYou, setRedirectToThankYou] = useState(false);
  const [submitErrors, setSubmitErrors] = useState();
  const [showSubmitErrors, setShowSubmitErrors] = useState(false);
  const [auditError, setAuditError] = useState(false);
  const [auditData, setAuditData] = useState({});
  const [listingPrice, setListingPrice] = useState();

  useEffect(() => {
    DataService.fetchSellerData(getParams).then((resp) => {
      setShowLoader(false);
      if (resp.errors && resp.errors.length > 0) {
        // console.log('get seller data error');
        setShowError(true);
        getDataError(resp.errors);
      } else {
        setSource(resp.source);
        setState(resp.state);
        setinspectionReportLink(resp.inspectionReportLink);
        setCar(resp.car);
        setSeller(resp.seller);
        setDispositionData(resp.disposition);
        setCcLeadId(resp.ccLeadId);
        setDialerResponseId(resp.dialerResponseId);
        setInteractionJourney(resp.interactionJourney);
        resp.car &&
          resp.car.listingPrice &&
          setListingPrice(resp.car.listingPrice);
      }
    });
  }, []);

  useEffect(() => {
    if (postData) {
      setSubmitErrors();
      setShowSubmitErrors(false);
      // console.log('seller postData called', isValidDisposition);
      const isValidAuditQuestions = validateAuditQuestion();
      if (isValidDisposition && isValidAuditQuestions) {
        const params = {
          appId: dialerCustomerInfo.appointment_id,
          additionalFields: disposition.addtionalFields,
          c24Quote: seller.c24Quote,
          ccLeadId: ccLeadId,
          city: seller.inspectedCity,
          dialerResponseId: dialerResponseId,
          dispositionId: disposition.dispositionId,
          email: seller.email,
          insuranceExpiry: car.insuranceExpiry,
          insuranceType: car.insuranceType,
          kmsDriven: car.kmsDriven,
          maxListingPrice: seller.maxListingPrice,
          make: car.make,
          model: car.model,
          name: seller.name,
          tpPrice: car.tpPrice,
          modelYear: car.modelYear,
          variant: car.variant,
          phone: seller.phone,
          address: seller.address,
          registrationNumber: car.registrationNumber,
          fuelType: car.fuelType,
          agentId: dialerData.userId,
          agentName: dialerData.agentName || null,
          storeName: seller.inspectedStore
        };
        const postParams = { ...params, ...auditData };
        setIsSubmit(true);
        DataService.submitSellerData(postParams).then((response) => {
          // console.log('seller post data response', response);
          if (response.errors && response.errors.length > 0) {
            setSubmitErrors(response.errors);
            setShowSubmitErrors(true);
            setPostData(false);
            setIsSubmit(false);
          } else {
            reset();
            setRedirectToThankYou(true);
            setSubmitErrors();
            setShowSubmitErrors(false);
          }
        });
      } else {
        setPostData(false);
      }
    }
  }, [postData]);

  const validateAuditQuestion = () => {
    if (isObject(auditData) && Object.keys(auditData).length === 3) {
      setAuditError(false);
      return true;
    } else {
      setSubmitErrors([{ message: 'All audit questions are mandatory !!' }]);
      setAuditError('All audit questions are mandatory !!');
      setShowSubmitErrors(true);
      return false;
    }
  };

  const reset = () => {
    setDisposition();
    setPostData(false);
    setIsValidDisposition(false);
    setIsSubmit(false);
  };

  const getDataError = (errors) => {
    alert(errors.map((item) => `${item.message}\n`));
  };

  if (redirectToThankYou) {
    return <Redirect to='/thank-you' />;
  }

  if (showLoader) {
    return (
      <div className='container text-center loader'>
        <img src={Loader} alt='Loading' className='center-block' />
      </div>
    );
  }

  if (showError) {
    return (
      <div className='no-results container-fluid'>
        <img src={NoResult} alt='no record' />
      </div>
    );
  }

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='details-head'>
            <div className='col-12 d-flex'>
              <div className='head_left'>
                <Label for='Source' className='labl'>
                  Source :{' '}
                </Label>
                <span className='span'>{source}</span>
              </div>
              <div className='head_left'>
                <Label for='State' className='labl'>
                  State :{' '}
                </Label>
                <span className='span'>{state}</span>
              </div>
              <div className='head_left_last'>
                <Label for='appointmentId' className='labl'>
                  App. ID :{' '}
                </Label>
                <span className='span'>{getParams.appointment_id}</span>
              </div>
              {inspectionReportLink && (
                <span className='inspection'>
                  <Button
                    color='link'
                    onClick={() => {
                      window.open(inspectionReportLink);
                    }}
                  >
                    View Inspection Report
                  </Button>
                </span>
              )}
            </div>
          </div>
        </div>
        <AuditQuestionaire
          listingPrice={listingPrice}
          auditData={auditData}
          setAuditData={setAuditData}
          auditError={auditError}
        />
        <div className='row'>
          <div className='seller-car-details'>
            {car && <CarDetails car={car} />}
          </div>
          <div className='seller-price-details'>
            {seller && <SellerPricingDetails seller={seller} />}
          </div>
          <div className='seller-dispositions'>
            {dispositionData && (
              <DispositionDetails
                dispositionData={dispositionData}
                setDisposition={setDisposition}
                isSubmit={isSubmit}
                setIsValidDisposition={setIsValidDisposition}
                setPostData={setPostData}
                submitErrors={submitErrors}
                showSubmitErrors={showSubmitErrors}
                setShowSubmitErrors={setShowSubmitErrors}
                sellerMaxListingPrice={seller.maxListingPrice}
                selectedCcDispositionId={selectedCcDispositionId}
                setSelectedCcDispositionId={setSelectedCcDispositionId}
              />
            )}
          </div>
        </div>
        {interactionJourney && (
          <div className='row'>
            <div className='seller-disposition'>
              <InteractionJourney interactionJourney={interactionJourney} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Seller;
