import Types from './types';

import {
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected,
  handleHttpStatusError
} from '../../../services/helper';
import { financeService } from '../../../services';
const setPageNumberConnected = (pageNo) => ({
  type: Types.SET_PAGE_NUMBER,
  payload: pageNo
});
const fetchFinanceGridInfoConnected = (params) => (dispatch) => {
  dispatchSetLoaderConnected();
  return computePayouts(params);
};

const computePayouts = async (params) => {
  try {
    return await financeService.fetchRefundPayouts(params);
  } catch (err) {
    return {
      page: 0,
      totalPages: 0,
      totalRecords: 0,
      response: []
    };
  }
}

export { setPageNumberConnected, fetchFinanceGridInfoConnected };
