import React, { useState, useEffect } from "react";
import { BuyerDetails, CarDetails } from '../../components/Buyer-Executive';
import { DispositionDetails, InteractionJourney } from '../../components/shared';
import { Label, Button } from 'reactstrap';
import { DataService } from "../../service";
import NoResult from '../../images/no-result.png';
import qs from 'qs';
import { Redirect } from 'react-router-dom';
import Loader from '../../images/preloader.gif';

const Buyer = () => {
  if (!window.location.search) {
    return <Redirect to="/error" />
  }
  const dialerData = qs.parse(window.location.search.substring(1));

  const dialerCustomerInfo = JSON.parse(dialerData.customerInfo);
  const getParams = {
    buyer_id: dialerCustomerInfo.buyer_id,
    lms_lead_id: dialerCustomerInfo.lms_lead_id,
    sessionId: dialerData.sessionId,
    campaignId: dialerData.campaignId,
    crtObjectId: dialerData.crtObjectId,
    userCrtObjectId: dialerData.userCrtObjectId,
    userId: dialerData.userId,
    customerId: dialerData.customerId,
    phone: dialerData.phone,
    agentName: dialerData.agentName,
    dispositionCode: dialerData.dispositionCode,
    dispositionAttr: dialerData.dispositionAttr
  }

  const [showLoader, setShowLoader] = useState(true);
  const [showError, setShowError] = !getParams.buyer_id ? useState(true) : useState(false);
  const [source, setSource] = useState();
  const [state, setState] = useState();
  const [buyerData, setBuyerData] = useState();
  const [initialOfferedPrice, setInitialOfferedPrice] = useState();
  const [car, setCar] = useState();
  const [carUrl, setCarUrl] = useState();
  const [refreshCar, setRefreshCar] = useState(false);
  const [loadCarData, setLoadCarData] = useState(false);
  const [appointmentId, setAppointmentId] = useState();
  const [initialAppointmentId, setInitialAppointmentId] = useState();
  const [dispositionData, setDispositionData] = useState();
  const [selectedCcDispositionId, setSelectedCcDispositionId] = useState();
  const [interactionJourney, setInteractionJourney] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [buyer, setBuyer] = useState();
  const [isValidBuyer, setIsValidBuyer] = useState(false);
  const [disposition, setDisposition] = useState();
  const [isValidDisposition, setIsValidDisposition] = useState(false);
  const [ccLeadId, setCcLeadId] = useState();
  const [dialerResponseId, setDialerResponseId] = useState();
  const [postData, setPostData] = useState(false);
  const [submitPostData, setSubmitPostData] = useState(false);
  const [redirectToThankYou, setRedirectToThankYou] = useState(false);
  const [submitErrors, setSubmitErrors] = useState();
  const [showSubmitErrors, setShowSubmitErrors] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [sendMessageError, setSendMessageError] = useState();

  useEffect(() => {
    DataService.fetchBuyerData(getParams).then(resp => {
      setShowLoader(false);
      if (resp.errors && resp.errors.length > 0) {
        // console.log('get buyer data error');
        getDataError(resp.errors);
        setShowError(true);
      } else {
        setSource(resp.source);
        setState(resp.state);
        setCar(resp.car);
        resp.car ? setAppointmentId(resp.car.appointmentId) : setAppointmentId(0);
        resp.car ? setInitialAppointmentId(resp.car.appointmentId) : setInitialAppointmentId(0);
        resp.car ? setCarUrl(resp.car.url) : setCarUrl();
        setBuyerData(resp.buyer);
        resp.buyer ? setInitialOfferedPrice(resp.buyer.offeredPrice) : setInitialOfferedPrice(0);
        setDispositionData(resp.disposition);
        setCcLeadId(resp.ccLeadId);
        setDialerResponseId(resp.dialerResponseId);
        setInteractionJourney(resp.interactionJourney);
      }
    });
  }, []);

  const sendMessage = () => {
    const { name, phone } = buyerData;
    const { inventoryShortUrl, parkingYardLocation, city } = car;

    DataService.sendMessage({
      appointmentId,
      name,
      phone,
      city,
      inventoryShortUrl,
      parkingYardLocation
    }).then(() => {
      setSendMessageError(false);
      setIsMessageSent(true);
    }).catch(err => {
      setIsMessageSent(false);
      setSendMessageError(err.message);
    });
  }

  useEffect(() => {
    if (refreshCar) {
      const carUrlArray = carUrl && carUrl.trim() ? carUrl.split('-') : [];
      if (carUrlArray && carUrlArray.length) {
        const apntmntId = carUrlArray[carUrlArray.length - 1].replace('/', '');
        if (apntmntId !== appointmentId) {
          setLoadCarData(true);
          DataService.fetchCarData(apntmntId).then(resp => {
            if (resp) {
              console.log('Fetched new car data', resp);
              setCar(resp);
            } else {
              setCar();
            }
            apntmntId === initialAppointmentId ? setBuyerData({ ...buyerData, 'offeredPrice': initialOfferedPrice }) : setBuyerData({ ...buyerData, 'offeredPrice': 0 });
            setAppointmentId(apntmntId);
            setLoadCarData(false);
          });
        }
      } else {
        setAppointmentId(0);
        setCar();
      }
      setRefreshCar(false);
    }
  }, [refreshCar]);

  useEffect(() => {
    if (submitPostData) {
      setSubmitErrors();
      setShowSubmitErrors(false);
      // console.log('buyer submitPostData called', isValidDisposition, isValidBuyer)
      // if (isValidDisposition && isValidBuyer && car) {
      if (isValidDisposition && isValidBuyer) {
        // let postParams = {
        //   additionalFields: disposition.addtionalFields,
        //   appId: appointmentId,
        //   c24Quote: car.c24Quote,
        //   ccLeadId: ccLeadId,
        //   dialerResponseId: dialerResponseId,
        //   dispositionId: disposition.dispositionId,
        //   insuranceExpiry: car.insuranceExpiry,
        //   insuranceType: car.insuranceType,
        //   listPrice: car.listPrice,
        //   name: buyer.name,
        //   phone: buyerData.phone,
        //   agentId: dialerData.userId,
        //   agentName: dialerData.agentName || null,
        //   sellerLocation: car.sellerLocation,
        //   publishTime: car.publishTime
        // }
        let postParams = {
          additionalFields: disposition.addtionalFields,
          appId: appointmentId > 0 ? appointmentId : null,
          c24Quote: car ? car.c24Quote : 0,
          discountedPrice: car ? car.discountedPrice : 0,
          ccLeadId: ccLeadId,
          dialerResponseId: dialerResponseId,
          dispositionId: disposition.dispositionId,
          insuranceExpiry: car ? car.insuranceExpiry : null,
          insuranceType: car ? car.insuranceType : null,
          listPrice: car ? car.listPrice : null,
          name: buyer.name,
          phone: buyerData.phone,
          agentId: dialerData.userId,
          agentName: dialerData.agentName || null,
          sellerLocation: car ? car.sellerLocation : null,
          publishTime: car ? car.publishTime : null,
          city: buyer.city,
          cfDetail: { ...disposition.cfDetail }
        }

        console.log({
          POST_PARAMS: JSON.stringify(postParams.cfDetail)
        })

        setIsSubmit(true);
        DataService.submitBuyerData(postParams).then(response => {
          // console.log('buyer post data response', response);
          if (response.errors && response.errors.length > 0) {
            setSubmitErrors(response.errors);
            setShowSubmitErrors(true);
            setPostData(false);
            setSubmitPostData(false);
            setIsSubmit(false);
          } else {
            reset();
            setRedirectToThankYou(true);
            setSubmitErrors();
            setShowSubmitErrors(false);
          }
        });
      } else {
        setPostData(false);
        setSubmitPostData(false);
      }
    }
  }, [submitPostData]);

  const reset = () => {
    setDisposition();
    setPostData(false);
    setSubmitPostData(false);
    setIsValidDisposition(false);
    setIsValidBuyer(false);
    setIsSubmit(false);
  }

  const getDataError = (errors) => {
    alert(
      errors.map((item) =>
        `${item.message}\n`
      )
    )
  }

  if (redirectToThankYou) {
    return <Redirect to='/thank-you' />
  }

  if (showLoader) {
    return (
      <div className="container text-center loader">
        <img src={Loader} alt="Loading" className="center-block" />
      </div>
    )
  }

  if (showError) {
    return (
      <div className="no-results container-fluid">
        <img src={NoResult} alt="no record"></img>
      </div>
    )
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="details-head">
            <div className="col-12 d-flex">
              <div className="head_left">
                <Label for="Source" className="labl">Source :</Label>
                <span className="span">{source}</span>
              </div>
              <div className="head_left">
                <Label for="State" className="labl">State :</Label>
                <span className="span">{state}</span>
              </div>
              <div className="head_left_last">
                <Label for="leadId" className="labl">Lead ID :</Label>
                <span className="span">{getParams.buyer_id}</span>
              </div>
              {car && <span className="inspection">
                <Button color="link" onClick={() => { window.open(car.url) }}>View On Website</Button>
              </span>
              }
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            {buyerData && <BuyerDetails
              buyerData={buyerData}
              car={car}
              carUrl={carUrl}
              setCarUrl={setCarUrl}
              postData={postData}
              setBuyer={setBuyer}
              setIsValidBuyer={setIsValidBuyer}
              setSubmitPostData={setSubmitPostData}
              setRefreshCar={setRefreshCar}
              selectedCcDispositionId={selectedCcDispositionId}
              onSendMessage={sendMessage}
              isMessageSent={isMessageSent}
              sendMessageError={sendMessageError}
              setIsMessageSent={setIsMessageSent}
              setSendMessageError={setSendMessageError}
            />}
          </div>
          <div className="col-5">
            {buyerData && <CarDetails car={car} loadCarData={loadCarData} />}
          </div>
          <div className="col-4">
            {dispositionData && <DispositionDetails dispositionData={dispositionData} setDisposition={setDisposition} isSubmit={isSubmit} setIsValidDisposition={setIsValidDisposition} setPostData={setPostData} submitErrors={submitErrors} showSubmitErrors={showSubmitErrors} setShowSubmitErrors={setShowSubmitErrors} car={car} buyerDiscountedPrice={car ? car.discountedPrice : 0} selectedCcDispositionId={selectedCcDispositionId} setSelectedCcDispositionId={setSelectedCcDispositionId} />}
          </div>
        </div>
        {interactionJourney &&
          <div className="row">
            <div className="col-12">
              <InteractionJourney interactionJourney={interactionJourney} />
            </div>
          </div>
        }
      </div>
    </>
  );
}

export default Buyer;