import React, { useState } from 'react';
import UserRcAccess from '../user-rc-access';
import UserForm from '../user-form';
import { ENTITY_TYPES } from './constants';
import { withRouter } from 'react-router-dom';
import { TAB_LIST } from './constants';

const CustomTabItem = (props) => {
  const { activeTab, tabIndex, children } = props;
  if (activeTab === tabIndex) {
    return (
      <div className={activeTab === tabIndex ? 'tab-pane active' : 'tab-pane'}>
        {children}
      </div>
    );
  } else {
    return null;
  }
};

const UserView = ({ isAdd }) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabsList = () => {
    let tabs = [ENTITY_TYPES.USER];

    if (!isAdd) {
      tabs.push([ENTITY_TYPES.RCACCESS]);
    }

    return tabs;
  };
  const TabNavs = ({ list, activeTab, toggle }) => {
    return (
      <ul className='nav nav-tabs'>
        {tabsList().map((item, index) => {
          return (
            <li
              key={index}
              className={activeTab === index ? 'active' : ''}
              onClick={() => toggle(index)}
            >
              <a>
                <b>{item}</b>
              </a>
            </li>
          );
        })}
      </ul>
    );
  };

  const toggleCallback = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  return (
    <div className='col-md-12'>
      <div className='row'>
        <div className='nav-tabs-custom no-box-shadow full-grid'>
          <TabNavs
            list={TAB_LIST}
            activeTab={activeTab}
            toggle={toggleCallback}
          />
          <div className='tab-content'>
            <CustomTabItem activeTab={activeTab} tabIndex={0}>
              <UserForm />
            </CustomTabItem>

            <CustomTabItem activeTab={activeTab} tabIndex={1}>
              <UserRcAccess />
            </CustomTabItem>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(UserView);
