export default (api) => {
  const login = (params) => {
    return new Promise((resolve, reject) => {
      api
        .post(`/login`, params)
        .then((resp) => {
          const {
            data: { data }
          } = resp;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const logout = () => {
    api
      .post('/secure/logout')
      .then((response) => {
        return response.data;
      })
      .catch((err) => console.log('logout error---', err.response));
  };

  const isAuthenticated = () => {
    return new Promise((resolve, reject) => {
      api
        .get('/secure/verify-authentication')
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    login,
    logout,
    isAuthenticated
  };
};
