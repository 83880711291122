import Types from './types';
import {
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected,
  handleHttpStatusError
} from '../../../services/helper';
import { orderService } from '../../../services';

const setPageNumberConnected = (pageNo) => ({
  type: Types.SET_PAGE_NUMBER,
  payload: pageNo
});

const fetchOrdersAction = (params) => (dispatch) => {
  dispatchSetLoaderConnected();
  return orderService
    .fetchOrders(params)
    .then((resp) => {
      dispatchResetLoaderConnected();
      if (resp.data && resp.data.data) {
        return {
          ...resp.data.data,
          response: (resp.data.data.response || []).map(i => ({
            ...i, 
            ...(i.carSource === "CUSTOMER_CARE" || i.carSource === "MANUAL_UPLOAD" ? {carSource: i.displayCarSource} : {})
          }))
        };
      } else {
        return {
          page: 0,
          totalPages: 0,
          totalRecords: 0,
          response: []
        };
      }
    })
    .catch((error) => {
      handleHttpStatusError(error, true);
      throw error;
    });
};

export { fetchOrdersAction, setPageNumberConnected };
