import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrdersCarInformation from './component';
import {
  fetchCarDetailConnected,
  submitCarDetail,
  getMakeDataConnected,
  getModelDataConnected,
  getVariantDataConnected
} from './actions';
const mapStateToProps = ({
  order: { makeList, modelList, variantList, order }
}) => {
  return {
    makeList,
    modelList,
    variantList,
    order
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCarDetailConnected,
      getMakeDataConnected,
      submitCarDetail,
      getModelDataConnected,
      getVariantDataConnected
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersCarInformation);
