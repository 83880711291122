export const YES_NO_DROPDOWN = [
  {
    value: "true",
    label: "Yes",
  },
  {
    value: "false",
    label: "No"
  }
];

export const SEGMENT_TYPE_KEYS = {
  C2B: "C2B",
  DEALER_CF: "DEALER_CF",
  CORRECTION_CASES: "CORRECTION_CASES",
  INTERNAL_EMPLOYEE: "INTERNAL_EMPLOYEE",
  LOAN_AGAINST_CAR: "LOAN_AGAINST_CAR",
  SUBROGATION_CASES: "SUBROGATION_CASES",
  UNCONFIRMED_BUYER: "UNCONFIRMED_BUYER",
  OUTSIDE_DEALER: "OUTSIDE_DEALER",
  OUTSIDE_B2C: "OUTSIDE_B2C",
  OUTSIDE_B2B: "OUTSIDE_B2B",
  C2B_DEALER_SALES: "C2B_DEALER_SALES",
  C2B_DEALER_SUPPORT: "C2B_DEALER_SUPPORT",
  C2B_SELLER_SALES: "C2B_SELLER_SALES",
  C2B_SELLER_SUPPORT: "C2B_SELLER_SUPPORT",
}

export const SEGMENT_TYPE_LABELS = {
  C2B: "C2B",
  DEALER_CF: "Dealer CF",
  CORRECTION_CASES: "Correction",
  INTERNAL_EMPLOYEE: "Employee",
  LOAN_AGAINST_CAR: "Loan Against Car (LAC)",
  SUBROGATION_CASES: "Subrogation Cases (BT)",
  UNCONFIRMED_BUYER: "Unconfirmed Buyer (UCB)",
  OUTSIDE_DEALER: "Outside-Dealer",
  OUTSIDE_B2C: "Outside - B2C",
  OUTSIDE_B2B: "Outside - B2B",
  C2B_DEALER_SALES: "C2B Dealer - Sales",
  C2B_DEALER_SUPPORT: "C2B Dealer - Support",
  C2B_SELLER_SALES: "C2B Seller - Sales",
  C2B_SELLER_SUPPORT: "C2B Seller - Support",
}

export const SEGEMENT_TYPES = Object.entries(SEGMENT_TYPE_KEYS).map(([key, value]) => {
  return {
    value,
    label: SEGMENT_TYPE_LABELS[key]
  }
});

export const SERVICE_TYPE_KEYS = {
  RC_TRANSFER_SERVICE: "RC_TRANSFER_SERVICE",
  VEHICLE_RELATED_SERVICE: "VEHICLE_RELATED_SERVICE",
  DRIVING_LICENCE: "DRIVING_LICENCE",
  CHALLAN: "CHALLAN",
  VALUE_ADDED_SERVICES: "VALUE_ADDED_SERVICES",
  CORRECTION_REQUIRED: "CORRECTION_REQUIRED",
}

export const CORRECTION_SEGMENT_KEYS = {
  BIKE_GS: "BIKE_GS",
  BIKE_DEALER: "BIKE_DEALER",
  C2B_DEALER_CAR: "C2B_DEALER_CAR",
  B2C: "B2C",
  GS: "GS",
  NBFC_DEALER: "NBFC_DEALER",
  GS_SOFT_QC: "GS_SOFT_QC",
}

export const CORRECTION_SEGMENT_LABELS = {
  BIKE_GS: "Bike-GS",
  BIKE_DEALER: "Bike-Dealer",
  C2B_DEALER_CAR: "C2B Dealer Car",
  B2C: "B2C",
  GS: "GS",
  NBFC_DEALER: "NBFC Dealer",
  GS_SOFT_QC: "GS Soft Qc"
}

export const CORRECTION_SEGMENTS = Object.entries(CORRECTION_SEGMENT_KEYS).map(([key, value]) => {
  return {
    value,
    label: CORRECTION_SEGMENT_LABELS[key]
  }
});

export const CITIES = [
  { label: 'Mumbai', value: 'MUMBAI' },
  { label: 'Ahmedabad', value: 'AHMEDABAD' },
  { label: 'Udaipur', value: 'UDAIPUR' },
  { label: 'Surat', value: 'SURAT' },
  { label: 'Vadodara', value: 'VADODARA' },
  { label: 'Rajkot', value: 'RAJKOT' },
  { label: 'Chandigarh', value: 'CHANDIGARH' },
  { label: 'Amritsar', value: 'AMRITSAR' },
  { label: 'Ludhiana', value: 'LUDHIANA' },
  { label: 'Karnal', value: 'KARNAL' },
  { label: 'Hisar', value: 'HISAR' },
  { label: 'Jalandhar', value: 'JALANDHAR' },
  { label: 'Rohtak', value: 'ROHTAK' },
  { label: 'Panipat', value: 'PANIPAT' },
  { label: 'Delhi/NCR', value: 'DELHI' },
  { label: 'Bangalore', value: 'BANGALORE' },
  { label: 'Hubli', value: 'HUBLI' },
  { label: 'Mangalore', value: 'MANGALORE' },
  { label: 'Mysore', value: 'MYSORE' },
  { label: 'Chennai', value: 'CHENNAI' },
  { label: 'Coimbatore', value: 'COIMBATORE' },
  { label: 'Madurai', value: 'MADURAI' },
  { label: 'Kochi', value: 'KOCHI' },
  { label: 'Trivandrum', value: 'TRIVANDRUM' },
  { label: 'Thrissur', value: 'THRISSUR' },
  { label: 'Salem', value: 'SALEM' },
  { label: 'Tiruchirappalli', value: 'TIRUCHIRAPPALLI' },
  { label: 'Calicut', value: 'CALICUT' },
  { label: 'Belgaum', value: 'BELGAUM' },
  { label: 'Kottayam', value: 'KOTTAYAM' },
  { label: 'Pune', value: 'PUNE' },
  { label: 'Nagpur', value: 'NAGPUR' },
  { label: 'Aurangabad', value: 'AURANGABAD' },
  { label: 'Nashik', value: 'NASHIK' },
  { label: 'Kolhapur', value: 'KOLHAPUR' },
  { label: 'Indore', value: 'INDORE' },
  { label: 'Bhopal', value: 'BHOPAL' },
  { label: 'Jabalpur', value: 'JABALPUR' },
  { label: 'Gwalior', value: 'GWALIOR' },
  { label: 'Kolkata', value: 'KOLKATA' },
  { label: 'Patna', value: 'PATNA' },
  { label: 'Ranchi', value: 'RANCHI' },
  { label: 'Bhubaneswar', value: 'BHUBANESWAR' },
  { label: 'Durgapur', value: 'DURGAPUR' },
  { label: 'Guwahati', value: 'GUWAHATI' },
  { label: 'Jamshedpur', value: 'JAMSHEDPUR' },
  { label: 'Siliguri', value: 'SILIGURI' },
  { label: 'Raipur', value: 'RAIPUR' },
  { label: 'Lucknow', value: 'LUCKNOW' },
  { label: 'Kanpur', value: 'KANPUR' },
  { label: 'Agra', value: 'AGRA' },
  { label: 'Dehradun', value: 'DEHRADUN' },
  { label: 'Bareily', value: 'BAREILY' },
  { label: 'Allahabad', value: 'ALLAHABAD' },
  { label: 'Varanasi', value: 'VARANASI' },
  { label: 'Meerut', value: 'MEERUT' },
  { label: 'Gorakhpur', value: 'GORAKHPUR' },
  { label: 'Haridwar', value: 'HARIDWAR' },
  { label: 'Aligarh', value: 'ALIGARH' },
  { label: 'Jaipur', value: 'JAIPUR' },
  { label: 'Kota', value: 'KOTA' },
  { label: 'Jodhpur', value: 'JODHPUR' },
  { label: 'Ajmer', value: 'AJMER' },
  { label: 'Alwar', value: 'ALWAR' },
  { label: 'Hyderabad', value: 'HYDERABAD' },
  { label: 'Visakhapatnam', value: 'VISAKHAPATNAM' },
  { label: 'Vijayawada', value: 'VIJAYAWADA' },
  { label: 'Guntur', value: 'GUNTUR' },
  { label: 'Nellore', value: 'NELLORE' },
];
