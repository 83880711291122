import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  message: null,
  error: null,
  data: {}
};

export const uploadUtrInit = (state = INITIAL_STATE) => {
  return {
    ...state,
    isLoading: true,
    isError: false,
    message: null,
    error: null
  };
};

export const uploadUtrSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action;
  return { ...state, isLoading: false, isError: false, error: null, data };
};

export const uploadUtrFail = (state = INITIAL_STATE, action) => {
  const { error } = action;
  return {
    ...state,
    isLoading: false,
    isError: true,
    message: null,
    error: error
  };
};

export const HANDLERS = {
  [Types.UPLOAD_UTR_INIT]: uploadUtrInit,
  [Types.UPLOAD_UTR_SUCCESS]: uploadUtrSuccess,
  [Types.UPLOAD_UTR_FAIL]: uploadUtrFail
};

export default createReducer(INITIAL_STATE, HANDLERS);
