import React, { useEffect, useMemo, } from 'react'
import GridView from '../../common/grid-view';
import { columns } from '../../../config/document-rr-configs-columns';
import { withRouter } from 'react-router-dom';
import { DOCUMENTS_GRID_ID } from '../../../constants/constants';
import { showToastMessages } from '../../../../shared/utils/helper';

const DocumentRejectionReasonsConfigList = ({
  documentsRrConfigs,
  configsUpdated,
  fetchDocumentRejectionReasonsConfigConnect,
  updateDocumentRejectionReasonsConfigConnect,
  updateDocumentRejectionsReasonDataConnect,
  resetRejectionReasonsConfigListConnect,
  deleteDocumentRejectionReasonsConfigConnect,
  match
}) => {
  const rowData = useMemo(() => Object.values(documentsRrConfigs.byId), [documentsRrConfigs.byId]);
  const columnDefs = useMemo(() => columns(), []);

  const fetchConfigs = () => {
    let params = { documentId: match.params.documentId };
    fetchDocumentRejectionReasonsConfigConnect(params);
  }

  useEffect(() => {
    fetchConfigs();
    return () => resetRejectionReasonsConfigListConnect();
  }, []);

  const handleInputChange = (attributeKey, value, documentId) => {
    updateDocumentRejectionsReasonDataConnect({ id: documentId, key: attributeKey, value });
  }

  const handleConfigUpdate = () => {
    let data = configsUpdated.map(configId => ({
      ...documentsRrConfigs.byId[configId],
      documentId: match.params.documentId
    }));

    updateDocumentRejectionReasonsConfigConnect({}, data).then((res) => {
      showToastMessages(res.message);
    }).finally(() => {
      fetchConfigs();
    });
  }

  const handleConfigDelete = (id) => {
    deleteDocumentRejectionReasonsConfigConnect(id).then((res) => {
      showToastMessages(res.message);
    }).finally(() => {
      fetchConfigs();
    });
  }

  const handleDeleteClick = (data) => {
    handleConfigDelete(data.id);
  }

  return (
    <>
      <h5>Document Rejection Rules</h5>
      <GridView
        className={DOCUMENTS_GRID_ID.RR_CONFIG_LIST}
        uniqueGridId={DOCUMENTS_GRID_ID.RR_CONFIG_LIST}
        columnDefs={columnDefs}
        rowData={rowData}
        context={{ handleInputChange, handleDeleteClick }}
        columnsToFit={true}
        floatingFilter={false}
        getRowHeight={() => 55}
      />
      <div className="text-right">
        <button
          className='btn btn-sm btn-success mt-neg-lg'
          onClick={handleConfigUpdate}
        >
          Update Rules
        </button>
      </div>
    </>
  )
}

export default withRouter(DocumentRejectionReasonsConfigList);