import React,{useState} from "react";
import styles from  './styles.module.css';
import { Button } from "reactstrap";
import InputText from "../../shared/components/input-text";
const FinanceServicesRefundPopup = ({refund, onClose, onSubmit}) => {
    const [processing, setProcessing] = useState(false);
    const [refundAmount, setRefundAmount] = useState(0);

    const onInitiatePayout = async () => {
        setProcessing(true);
        try {
            await onSubmit(refund.refundId, refundAmount);
        } catch (err) {
            setProcessing(false);
            throw err;
        }
        onClose();
    };

    return (
        <div className={styles.adminPopup}> 
            <h2 className={styles.adminPopupHeader}>{refund.displayKey}</h2>
            <label htmlFor={'refund_amount' }>{'Amount'}</label>
            <InputText 
                id={'refund_amount'}
                name={'amount'}
                type='number'
                className='form-control'
                value={refundAmount}
                onChange={(e)=> setRefundAmount(e.target.value)} 
            />
            <div className={styles.modalButton}>
                <Button className='d-block submit' onClick={onInitiatePayout} disabled={processing}>Submit</Button>
            </div>
        </div>
    );
};

export default FinanceServicesRefundPopup;
