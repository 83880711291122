import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import GridRecordView from '../grid-record-view';
import './style.css';
import { PAGE_SIZE } from '../../../shared/utils/constants';
const CustomPagination = ({
  page,
  totalRecords,
  handlePageChange,
  recordsOnPage,
  itemsCountPerPage = PAGE_SIZE['DEFAULT'],
  totalPages
}) => {
  const [activePage, setActivePage] = useState(page + 1);
  useEffect(() => {
    setActivePage(page + 1);
  }, [page]);

  const handleChange = (currentPage) => {
    handlePageChange(currentPage - 1);
  };
  return (
    <div>
      <div className='rcTracker'>
        <Pagination
          pageRangeDisplayed={1}
          prevPageText={
            <button className='btn-sm btn-default mr10'>Previous</button>
          }
          nextPageText={
            <button className='btn-sm btn-default ml10'>Next</button>
          }
          firstPageText={<button className='btn-sm btn-success'>First</button>}
          lastPageText={<button className='btn-sm btn-success'>Last</button>}
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={totalRecords}
          onChange={handleChange}
        />
      </div>
      <GridRecordView
        recordsOnPage={recordsOnPage}
        totalRecords={totalRecords}
        page={activePage}
        totalPages={totalPages}
      />
    </div>
  );
};

export default CustomPagination;
