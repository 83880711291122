export const CAR_DETAILS = [
  {
    label: 'Appointment Id',
    apiKey: 'appointmentId',
    enabled: false,
    element_type: 'text'
  },
  {
    label: 'Registration Number',
    apiKey: 'registrationNumber',
    enabled: false,
    element_type: 'text',
    format: 'capitalize'
  },
  {
    label: 'Deal Status ',
    apiKey: 'dealStatus',
    enabled: false,
    element_type: 'text'
  },
  {
    label: 'Seller HP',
    apiKey: 'sellerHp',
    enabled: false,
    element_type: 'text'
  },
  {
    label: 'Seller Bank NOC received',
    apiKey: 'sellerBankNocReceived',
    enabled: false,
    element_type: 'text'
  },
  {
    label: 'Seller Form 35 received',
    apiKey: 'sellerForm35Received',
    enabled: false,
    element_type: 'text'
  }
];

const RC_DETAILS = [
  {
    label: 'RC Transfer Cost',
    apiKey: 'totalRcTransferCost',
    enabled: false,
    element_type: 'text'
  },
  {
    label: 'Buyer Holdback',
    apiKey: 'buyerHoldback',
    enabled: false,
    element_type: 'text'
  },
  {
    label: 'Seller Holdback',
    apiKey: 'sellerHoldback',
    enabled: false,
    element_type: 'text'
  },
  {
    label: 'RC Transfer City',
    apiKey: 'rcTransferCity',
    enabled: false,
    element_type: 'text'
  },
  {
    label: 'RC Transfer State',
    apiKey: 'rcTransferState',
    enabled: false,
    element_type: 'text'
  },
  {
    label: 'Agreed Sales Price',
    apiKey: 'agreedSalesPrice',
    enabled: false,
    element_type: 'form'
  }
];

const RC_OTHERS_DETAILS = [
  {
    label: 'RC Transfer Cost',
    apiKey: 'totalRcTransferCost',
    enabled: false,
    element_type: 'text'
  },
  {
    label: 'Buyer Holdback',
    apiKey: 'buyerHoldback',
    enabled: false,
    element_type: 'text'
  },
  {
    label: 'RC Transfer City',
    apiKey: 'rcTransferCity',
    enabled: false,
    element_type: 'text'
  },
  {
    label: 'RC Transfer State',
    apiKey: 'rcTransferState',
    enabled: false,
    element_type: 'text'
  },
  {
    label: 'Details of RTO Work Required',
    apiKey: 'rtoWorkRequiredDetails',
    enabled: false,
    element_type: 'text'
  },
  {
    label: 'Correction Type',
    apiKey: 'correctionType',
    enabled: false,
    element_type: 'text'
  },
  {
    label: 'Correction Segment',
    apiKey: 'correctionSegment',
    enabled: false,
    element_type: 'text'
  },
  {
    label: 'Details Regarding Correction',
    apiKey: 'correctionDetails',
    enabled: false,
    element_type: 'text'
  },
];

export const DEAL_INFO = [
  { title: 'Car Details', content: CAR_DETAILS },
  { title: 'RC Details', content: RC_DETAILS }
];

export const getDealInfoItems = (isOthers) => {
  return [
    { title: 'Car Details', content: CAR_DETAILS },
    { title: 'RC Details', content: isOthers ? RC_OTHERS_DETAILS : RC_DETAILS }
  ];
}

export const BUYER_INFO = [
  {
    label: 'Buyer Name',
    apiKey: 'buyerName',
    enabled: true,
    element_type: 'text'
  },
  {
    label: 'Loan Name',
    apiKey: 'buyerLoanName',
    enabled: false,
    element_type: 'text'
  },
  {
    label: 'Buyer Number',
    apiKey: 'buyerNumber',
    enabled: false,
    element_type: 'text',
    data_type: 'phone'
  },
  {
    label: 'Buyer Email',
    apiKey: 'buyerEmail',
    enabled: true,
    element_type: 'email'
  },
  {
    label: 'Buyer Address',
    apiKey: 'buyerAddress',
    enabled: true,
    element_type: 'text'
  },
  {
    label: "Father's Name",
    apiKey: 'fathersName',
    enabled: true,
    element_type: 'text'
  },
  {
    label: 'Address on RC',
    apiKey: 'addressOnRc',
    enabled: true,
    element_type: 'text'
  },
];

export const SELLER_INFO = [
  {
    label: 'Seller Name',
    apiKey: 'sellerName',
    enabled: true,
    element_type: 'text'
  },
  {
    label: 'Seller Number',
    apiKey: 'sellerNumber',
    enabled: false,
    element_type: 'text',
    data_type: 'phone'
  },
  {
    label: 'Seller Email',
    apiKey: 'sellerEmail',
    enabled: true,
    element_type: 'email'
  }
];

export const CUSTOMER_INFO = {
  buyer: BUYER_INFO,
  seller: SELLER_INFO
};

export const RC_OTHERS_CUSTOMER_INFO = {
  buyer: [
    {
      label: 'Buyer Name',
      apiKey: 'buyerName',
      enabled: true,
      element_type: 'text'
    },
    {
      label: 'Loan Name',
      apiKey: 'buyerLoanName',
      enabled: false,
      element_type: 'text'
    },
    {
      label: 'Buyer Number',
      apiKey: 'buyerNumber',
      enabled: false,
      element_type: 'text',
      data_type: 'phone'
    },
    {
      label: 'Buyer Email',
      apiKey: 'buyerEmailId',
      enabled: true,
      element_type: 'email'
    },
    {
      label: 'Buyer Address',
      apiKey: 'buyerAddress',
      enabled: true,
      element_type: 'text'
    },
    {
      label: "Father's Name",
      apiKey: 'fathersName',
      enabled: true,
      element_type: 'text'
    },
    {
      label: 'Address on RC',
      apiKey: 'addressOnRc',
      enabled: true,
      element_type: 'text'
    },
  ],
  seller: [
    {
      label: 'Owner Name',
      apiKey: 'ownerName',
      enabled: true,
      element_type: 'text'
    },
    {
      label: 'Owner Number',
      apiKey: 'ownerNumber',
      enabled: false,
      element_type: 'text',
      data_type: 'phone'
    },
    {
      label: 'Owner Email',
      apiKey: 'ownerEmailId',
      enabled: true,
      element_type: 'email'
    }
  ]
};

