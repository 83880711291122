import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FinanceProofSwitchModal from './component';
import { switchProofInfoConnected } from './actions';

import { fetchFinanceDealInfoByIdConnected } from '../finance-dealInfo/actions';

import { fetchTransactionLogsConnected } from '../finance-payment-tab/actions';

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      switchProofInfoConnected,
      fetchFinanceDealInfoByIdConnected,
      fetchTransactionLogsConnected
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinanceProofSwitchModal);
