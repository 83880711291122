import Types from './types';
import { orderService } from '../../../services';

import { showToastMessages } from '../../../shared/utils/helper';
import { fetchCarDetailConnected } from '../orders-car-information/actions';

/* Update order data */

const updateOrderInit = () => ({
  type: Types.UPDATE_ORDER_INIT
});

const updateOrderSuccess = (data) => ({
  type: Types.UPDATE_ORDER_SUCCESS,
  data
});

const updateOrderFail = (error) => ({
  type: Types.UPDATE_ORDER_FAIL,
  error
});

const updateOrderConnected = (data) => (dispatch) => {
  dispatch(updateOrderInit());
  return orderService
    .updateOrder(data)
    .then((resp) => {
      showToastMessages(resp.data.message);
      dispatch(updateOrderSuccess(resp));
      dispatch(fetchCarDetailConnected(data.appointmentId, false));
      return resp.data;
    })
    .catch((error) => {
      dispatch(updateOrderFail(error));
    });
};

export { updateOrderConnected };
