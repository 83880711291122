import React, { useEffect, useState } from 'react';
import './styles.css';
import Button from '../../../shared/components/button';
import CarImagesSlider from '../car-image-slider';
import DownloadIcon from './images/download-icon.svg';
import ReplaceIcon from './images/replace.svg';
import UploadIcon from './images/upload-icon.svg';
import UploadButton from '../../../shared/components/upload-button';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';
import {
  APPROVE,
  DISCARD,
  APPROVE_TEXT,
  DISCARD_TEXT,
  DOWNLOAD_PATH,
} from './constant';

const CarImages = ({
  fetchImageDetailConnected,
  imageData,
  replaceImageConnected,
  approveImageConnected,
  downloadImageConnected,
  detail,
}) => {
  const [imagesValue, setImageValue] = useState();
  const [initStatus, setInitStatus] = useState(false);
  useEffect(() => {
    fetchImageDetailConnected(detail.orderId)
      .then(() => {
        setInitStatus(true);
      })
      .catch(() => {
        setInitStatus(true);
      });
  }, []);
  useEffect(() => {
    if (imageData && imageData.data && imageData.data.images && !initStatus) {
      setImageValue(imageData.data.images[0]);
      setInitStatus(true);
    }
  }, [imageData]);

  const download = (data) => {
    if (data.id && data.url) {
      const filename = data.url.split('/').slice(-1).pop();
      downloadImageConnected(data.id, filename);
    }
  };

  const replace = (file) => {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('image_id', imagesValue.id);
    // formData.append('order_id', detail.orderId);
    replaceImageConnected(formData, imagesValue.id, imageData);
  };
  const approve = (data) => {
    const approveDate = { ...data };
    approveDate.is_to_be_published =
      approveDate.is_to_be_published === APPROVE ? DISCARD : APPROVE;
    approveImageConnected({ ...approveDate }, detail.orderId);
  };

  const onChange = (data) => {
    setImageValue(data);
  };
  return (
    <LoaderWithAccessControl>
      {imageData && imageData.data && imageData.data.images.length !== 0 ? (
        <>
          <CarImagesSlider
            imageData={imageData && imageData.data && imageData.data.images}
            imageLabel={
              imageData && imageData.data && imageData.data.images_types
            }
            baseUrl={imageData && imageData.data && imageData.data.baseUrl}
            onChange={onChange}
          />

          <div className='d-flex align-item-center justify-content-between buttonWrapper'>
            <Button
              label='Download'
              btnIcon={DownloadIcon}
              iconAlt='Download button Icon'
              onClick={() => download(imagesValue)}
            />
            <UploadButton
              label='Replace'
              onChangeHandler={replace}
              accept='image/*'
              btnIcon={ReplaceIcon}
              iconAlt='Replace Icon'
            />
            <Button
              label={
                imagesValue && imagesValue.is_to_be_published
                  ? DISCARD_TEXT
                  : APPROVE_TEXT
              }
              iconAlt='Upload button Icon'
              onClick={() => approve(imagesValue)}
            />
          </div>
        </>
      ) : (
        <p>No data Available</p>
      )}
    </LoaderWithAccessControl>
  );
};

export default CarImages;
