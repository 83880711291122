import React, { useState, useEffect } from 'react';
import InputSwitch from '../../../shared/components/input-switch';
import './styles.css';
import { LABELS } from './constants';
import { withRouter } from 'react-router-dom';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';
import CustomButton from '../../../shared/components/button';
import { storeService } from '../../../services';

const ArDealerForm = ({
  fetchStates,
  fetchCities,
  fetchZones,
  match,
  fetchArDealerInfoByIdConnected,
  arDealerData,
  removeArDealerDetailConnected,
  addArdealerConnected,
  history
}) => {
  const [arDealerValue, setDealerData] = useState();
  const [states, setStateList] = useState();
  const [cities, setCityList] = useState();
  const [zones, setZonesList] = useState();
  const [stores, setStoresList] = useState();
  const submit = (e) => {
    e.preventDefault();
    const data = {
      name: arDealerValue.name,
      contactNumber: arDealerValue.contactNumber,
      shopAddress: arDealerValue.shopAddress,
      dealerId: match.params.dealerId,
      arId: match.params.arId,
      pincode: arDealerValue.pincode,
      latitude: arDealerValue.latitude,
      longitude: arDealerValue.longitude,
      stateId: arDealerValue.stateId,

      zoneId: arDealerValue.zoneId,
      cityId: arDealerValue.cityId,
      status: arDealerValue.status ? true : false,
      storeLocationId: arDealerValue.storeLocationId
    };
    addArdealerConnected(data);
  };
  useEffect(() => {
    if (match.params.arId) {
      fetchArDealerInfoByIdConnected(match.params.arId);
    } else {
      removeArDealerDetailConnected();
    }
  }, [match.params.arId]);
  useEffect(() => {
    if (arDealerData && arDealerData) {
      setDealerData(arDealerData);
    }
  }, [arDealerData]);

  async function fetchData() {
    const stateList = await fetchStates();
    setStateList(stateList);
    const zoneList = await fetchZones();
    setZonesList(zoneList);
    const storeList = await fetchZones();
    setZonesList(zoneList);
  }
  useEffect(() => {
    fetchData();
    storeService.fetchStores().then((resp) => {
      if (resp.data && resp.data.data) {
        setStoresList(resp.data.data);
      }
    });
  }, []);

  useEffect(() => {
    async function fetchData() {
      let stateId = arDealerValue && arDealerValue.stateId;
      if (stateId) {
        const list = await fetchCities({ stateId });
        setCityList(list);
      }
    }
    fetchData();
  }, [arDealerValue && arDealerValue.stateId]);

  const onChangeText = (name, value) => {
    let details = { ...arDealerValue };
    if (
      name === 'stateId' ||
      name === 'cityId' ||
      name === 'zoneId' ||
      name === 'storeLocationId'
    ) {
      onChangeDropdown(name, value);
    } else if (name === 'dealerType') {
      onChangeDropdown(name, value);
    } else {
      details[name] = value;
      setDealerData(details);
    }
  };

  const onChangeDropdown = (name, value) => {
    const valueMap = {
      stateId: {
        key: 'stateId',
        values: states,
        nameKey: 'stateName',
        dataKey: 'stateName'
      },
      cityId: {
        key: 'cityId',
        values: cities,
        nameKey: 'cityame',
        dataKey: 'cityame'
      },
      zoneId: {
        key: 'zoneId',
        values: zones,
        nameKey: 'zoneId'
      },
      storeLocationId: {
        key: 'storeLocationId',
        values: stores,
        nameKey: 'storeLocationId'
      }
    };
    let details = { ...arDealerValue };

    setDealerData({
      ...details,
      [valueMap[name].nameKey]: value.label,
      [valueMap[name].key]: value.value
    });
  };

  const getList = (type) => {
    switch (type) {
      case 'stateId':
        return stateList();
      case 'cityId':
        return cityList();
      case 'zoneId':
        return zoneList();
      case 'storeLocationId':
        return storeList();
      default:
        return [];
    }
  };
  const stateList = () => {
    const list =
      states &&
      states.map((option, index) => {
        return { value: option.stateId, label: option.stateName };
      });
    return list || [];
  };
  const cityList = () => {
    const list =
      cities &&
      cities.map((option, index) => {
        return { value: option.cityId, label: option.cityName };
      });
    return list || [];
  };
  const zoneList = () => {
    const list =
      zones &&
      zones.data &&
      zones.data.data.map((option, index) => {
        return { value: option.id, label: option.zone };
      });
    return list || [];
  };
  const storeList = () => {
    const list =
      stores &&
      stores.map((option, index) => {
        return { value: option.id, label: option.storeName };
      });
    return list || [];
  };
  const onChangeChechbox = (e, name) => {
    const details = { ...arDealerValue };
    details[name] = e.target.checked;
    setDealerData({ ...details });
  };

  const onShowFormData = (item) => {
    let value = arDealerValue && arDealerValue[item['value']];

    let list = getList(item.value);
    if (
      item.value === 'stateId' ||
      item.value === 'cityId' ||
      item.value === 'zoneId' ||
      item.value === 'storeLocationId'
    ) {
      return filterData(
        list,
        arDealerValue && arDealerValue[item.value],
        item.value
      );
    } else {
      return value;
    }
  };
  const filterData = (list, id, type) => {
    if (
      type === 'stateId' ||
      type === 'cityId' ||
      type === 'zoneId' ||
      type === 'storeLocationId'
    ) {
      const result = list.filter((item) => item.value === parseInt(id));
      return result;
    }
  };
  return (
    <LoaderWithAccessControl>
      <form onSubmit={(e) => submit(e)}>
        <div className='boxs'>
          <div className='row'>
            <div className='box-body col-md-12'>
              {LABELS.map((item, index) => {
                return (
                  <div
                    className='float-left form-group col-md-6 dealer-wrapper'
                    key={index}
                  >
                    <label className='font-weight-bold'>
                      <span>{item.label}</span>
                      <span>{item.required ? ' *' : ''}</span>
                    </label>
                    <InputSwitch
                      pattern={item.pattern}
                      maxLength={item.maxLength}
                      min={item.min}
                      max={item.max}
                      type={item.type}
                      param={item.type}
                      item={item}
                      name={item['value']}
                      value={onShowFormData(item)}
                      onChange={onChangeText}
                      list={getList}
                      required={item.required}
                      onChangeChechbox={(e) =>
                        onChangeChechbox(e, item['value'])
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <CustomButton label='Save' type={submit} />
        </div>
      </form>
    </LoaderWithAccessControl>
  );
};

export default withRouter(ArDealerForm);
