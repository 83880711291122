import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { SelectDropdown } from '../../../client/components/shared';
import { hpRequestTypeValues, HpTypes, hpTypeValues } from '../../constants/constants';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getLocalDatetime } from '../../../shared/utils/datesHelper';
import { HypothecationService } from '../../../services';
import { showToastMessages } from '../../../shared/utils/helper';
import { ROLES, checkForUserRoles } from '../../../shared/utils/constants';

const INITIAL_STATE = {
  id: '',
  type: '',
  loanId: '',
  appointmentId: '',
  chassisNo: '',
  engineNo: '',
  registrationNo: '',
  hpaFromDate: null,
  hpaToDate: null,
  hpcFromDate: null,
  hpcToDate: null,
  hptDate: null,
  status: ''
};

const RTO_REQUEST_TYPE = {
  request: 'request-to-rto',
  reject: 'reject',
  approve: 'approve'
};

const HpManagementAddEdit = (props) => {
  const [hpData, setHpData] = useState(INITIAL_STATE);
  const [id] = useState(props.match.params.id === 'add' ? '' :  props.match.params.id);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [validation, setValidation] = useState({});
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  useEffect(()=>{
    !!id && HypothecationService.fetchHypothecation(id).then((resp)=>{
      let currentHpData = {...hpData, ...resp.data};
      if ([hpRequestTypeValues.REQUESTED, hpRequestTypeValues.APPROVED, hpRequestTypeValues.REJECTED].includes(resp.data.status)) {
        setIsFormDisabled(true);
      }
      setHpData(currentHpData);
    }).catch((err)=>{
      console.log({err});
    });
  },[id]);

  const onChange = (e) => {
    const hp = {...hpData, [e.target.name]: e.target.value}
    setHpData(hp);
  };
  const onDateChange = (value, name) => {
    let hp = {...hpData, [name]: getLocalDatetime(value, true)};
    if (hpData.type === hpTypeValues.HPT) {
      hp = {...hp, hpaFromDate: null,
        hpaToDate: null,
        hpcFromDate: null,
        hpcToDate: null};
    } else if (hpData.type === hpTypeValues.HPC) {
      hp = {...hp, hpaFromDate: null,
        hpaToDate: null, hptDate: null};
    } else if (hpData.type === hpTypeValues.HPA) {
      hp = {...hp, hpcToDate: null,
        hpcFromDate: null, hptDate: null};
    }
    setHpData(hp);
  }
  const handleSubmit =(e) => {
    if (!hpData.type) {
      showToastMessages('Please select Hypothecation type', false);
      return;
    } else if (!hpData.loanId) {
      showToastMessages('Please input Loan ID', false);
      return;
    }
    HypothecationService.postHypothecation(hpData).then((resp)=>{
      setTimeout(()=>{
        props.history.push('/hp-management');
      }, 2000);
    }).catch((err)=>{
      let validationObject = {};
      (err.errors || []).map((item)=>{
        validationObject = {...validationObject, [item.field]: item.message}
      });
      setValidation(validationObject);
    });
  };

  const onRTOReuest = (action) => {
    setIsButtonClicked(true);
    !!hpData.id && !!action && HypothecationService.updateRTOAction(hpData.id, action).then((resp)=>{
      if (action === RTO_REQUEST_TYPE.request) {
        const {responseCode='', responseMessage=''} = resp.data ||{}
        showToastMessages(responseMessage, responseCode === "1");
      } else {
        showToastMessages(resp.message);
      }
    }).catch((err)=>{
      console.log({err})
    }).finally(()=>{
      setIsButtonClicked(false);
    });
  };
  return (
    <>
      <div className='row mt10'>
        <div className='col-md-4'>
          <label>Hypothecation Type</label>
          <SelectDropdown
            name='type'
            optionsList={Object.keys(HpTypes).map((key)=>  {return {
              label: key, value: HpTypes[key].value
            }})}
            value={hpData.type}
            onChange={onChange}
            isDisabled={!!hpData.id || isFormDisabled}
          />
          <span className='error'>
            {validation && !!validation.type && "Please selct Hypothecation Type"}
          </span>
        </div>

        <div className='col-md-4'>
          <label>Loan ID</label>
          <input
            className='form-control'
            type='text'
            name='loanId'
            value={hpData.loanId}
            onChange={onChange}
            disabled={!!hpData.id || isFormDisabled}
            maxLength={12}
          />
          <span className='error'>
            {validation && validation.loanId}
          </span>
        </div>

        <div className='col-md-4'>
          <label>App ID</label>
          <input
            className='form-control'
            type='text'
            name='appointmentId'
            value={hpData.appointmentId}
            onChange={onChange}
            disabled={isFormDisabled}
            maxLength={12}
          />
        </div>
      </div>
      <div className='row mt10'>
        <div className='col-md-4'>
          <label>Chassis No</label>
          <input
            className='form-control'
            type='text'
            name='chassisNo'
            value={hpData.chassisNo}
            onChange={onChange}
            disabled={isFormDisabled}
            maxLength={20}
          />
          <span className='error'>
            {validation && validation.chassisNo}
          </span>
        </div>
        {[hpTypeValues.HPC, hpTypeValues.HPT].includes(hpData.type) && <div className='col-md-4'>
          <label>Registration No</label>
          <input
            className='form-control'
            type='text'
            name='registrationNo'
            value={hpData.registrationNo}
            onChange={onChange}
            disabled={isFormDisabled}
            maxLength={10}
          />
          <span className='error'>
            {validation && validation.registrationNo}
          </span>
        </div>}
        {hpData.type ===  hpTypeValues.HPA && <div className='col-md-4'>
          <label>Engine No</label>
          <input
            className='form-control'
            type='text'
            name='engineNo'
            value={hpData.engineNo}
            onChange={onChange}
            disabled={isFormDisabled}
            maxLength={20}
          />
          <span className='error'>
            {validation && validation.engineNo}
          </span>
        </div>}
      </div>
      {hpData.type ===  hpTypeValues.HPA && <div className='row mt10'>
        <div className='col-4 datepicker_custom'>
          <label>HPA From</label>
          <DatePicker
            selected={
              hpData.hpaFromDate
                ? new Date(hpData.hpaFromDate)
                : null
            }
            onChange={(date) => onDateChange(date,'hpaFromDate')}
            dateFormat='dd/MM/yyyy'
            disabled={isFormDisabled}
          />
          <span className='error'>
            {validation && validation.hpaFromDate}
          </span>
        </div>

        <div className='col-4 datepicker_custom'>
          <label>HPA To</label>
          <DatePicker
            selected={
              hpData.hpaToDate
                ? new Date(hpData.hpaToDate)
                : null
            }
            onChange={(date) => onDateChange(date,'hpaToDate')}
            dateFormat='dd/MM/yyyy'
            disabled={isFormDisabled}
          />
          <span className='error'>
            {validation && validation.hpaToDate}
          </span>
        </div>
      </div>} 
      {hpData.type ===  hpTypeValues.HPC && <div className='row mt10'>
        <div className='col-md-4 datepicker_custom'>
          <label>HPC From</label>
          <DatePicker
            selected={
              hpData.hpcFromDate
                ? new Date(hpData.hpcFromDate)
                : null
            }
            onChange={(date) => onDateChange(date,'hpcFromDate')}
            dateFormat='dd/MM/yyyy'
            disabled={isFormDisabled}
          />
          <span className='error'>
            {validation && validation.hpcFromDate}
          </span>
        </div>

        <div className='col-md-4 datepicker_custom'>
          <label>HPC To</label>
          <DatePicker
            selected={
              hpData.hpcToDate
                ? new Date(hpData.hpcToDate)
                : null
            }
            onChange={(date) => onDateChange(date,'hpcToDate')}
            dateFormat='dd/MM/yyyy'
            disabled={isFormDisabled}
          />
          <span className='error'>
            {validation && validation.hpcToDate}
          </span>
        </div>
      </div>}
      {hpData.type ===  hpTypeValues.HPT && <div className='row mt10'>
        <div className='col-md-4 datepicker_custom'>
          <label>Termination Date</label>
          <DatePicker
            selected={
              hpData.hptDate
                ? new Date(hpData.hptDate)
                : null
            }
            onChange={(date) => onDateChange(date,'hptDate')}
            dateFormat='dd/MM/yyyy'
            disabled={isFormDisabled}
          />
          <span className='error'>
            {validation && validation.hptDate}
          </span>
        </div>
      </div>}
      {!!hpData.rtoMessage && <div className='row mt10'>
        <div className='col-md-4'>
          <label>Response</label>
          <textarea
            className='form-control'
            name='rtoMessage'
            value={hpData.rtoMessage}
            disabled
          />
        </div>
        {[hpRequestTypeValues.APPROVED, hpRequestTypeValues.REJECTED].includes(hpData.status) && <div className='col-md-4'>
          <label>Status</label>
          <textarea
            className='form-control'
            name='status'
            value={hpData.status}
            disabled
          />
        </div>}
      </div>}
      <div className='row mt20'>
        <div className='col-md-6'>
          {[hpRequestTypeValues.REQUESTED].includes(hpData.status) &&
            <>
              <button onClick={()=> onRTOReuest(RTO_REQUEST_TYPE.approve)} className='btn btn-primary' disabled={isButtonClicked}>
                Approved by RTO
              </button>
              <button onClick={()=> onRTOReuest(RTO_REQUEST_TYPE.reject)} className='btn btn-primary ml10' disabled={isButtonClicked}>
                Rejected by RTO
              </button>
            </>}
          { <>
              {(!hpData.status || hpRequestTypeValues.CREATED === hpData.status) && <button type='submit' className='btn btn-primary' onClick={handleSubmit} disabled={isButtonClicked}>
                {!!id ? 'Update' : 'Save'}
              </button>}
              {hpData.status === hpRequestTypeValues.CREATED && !!checkForUserRoles([ROLES.HYPOTHECATION_RC_OPS]) && <button onClick={()=> onRTOReuest(RTO_REQUEST_TYPE.request)} className='btn btn-primary ml10' disabled={isButtonClicked}>
                Request to RTO
              </button>}
            </>}
        </div>
      </div>
    </>
  );
};

export default withRouter(HpManagementAddEdit);
