import Types from './types';
import { financeService } from '../../../services';

// Global actions
import {
  handleHttpStatusError,
  dispatchResetLoaderConnected,
  dispatchSetLoaderConnected
} from '../../../services/helper';
import {
  showToastMessages,
  console_in_dev,
  catchErrorMessage
} from '../../../shared/utils/helper';

//Fetch order details

const fetchServiceChargeConnected = (deal_id) => (dispatch) => {
  dispatchSetLoaderConnected();
  //dispatch(fetchPaymentInfoInit());
  return new Promise((resolve, reject) => {
    financeService
      .fetchServiceCharge(deal_id)
      .then((response) => {
        console_in_dev({ fetchPaymentInfo: response });
        dispatchResetLoaderConnected();
        if (response && response.data && response.data.data) {
          resolve(response.data.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        handleHttpStatusError(error);
        reject(error);
      });
  });
};

const submitServiceChargesConnected = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    financeService
      .submitServiceCharges(data)
      .then((resp) => {
        resolve(resp);
        showToastMessages(resp.data.message);
      })
      .catch((error) => {
        catchErrorMessage(error);
        reject(error);
      });
  });
};

// const fetchPaymentInfoInit = () => ({
//   type: Types.FETCH_PAYMENT_INFO_INIT
// });

// const fetchPaymentInfoSuccess = (data) => ({
//   type: Types.FETCH_PAYMENT_INFO_SUCCESS,
//   payload: data
// });

// const fetchPaymentInfoFailure = (error) => ({
//   type: Types.FETCH_PAYMENT_INFO_FAILURE,
//   payload: error
// });

export { fetchServiceChargeConnected, submitServiceChargesConnected };
