import React from 'react';
import Select, { components } from 'react-select';
import './styles.css';

const SelectComponent = ({
  name,
  isRequired = false,
  value,
  placeholder = '',
  defaultValue = '',
  hasError = false,
  isDisabled = false,
  isMulti = false,
  className = '',
  onChange,
  optionsList = [],
  hideSelectedOptions = true,
  showCustomContainer = false,
  isClearable = true,
  errorMessage = '',
  errorContainer = false,
  isSearchable=true,
  ...restProps
}) => {
  const onChangeSelect = (option) => {
    if (restProps.version && restProps.version === 'v2') {
      return onChange(option, name);
    } else {
      return onChange(option.value);
    }
  };
  return (
    <>
      <Select
        key={name}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        isMulti={isMulti}
        options={optionsList}
        classNamePrefix='react-select'
        className={'react-select-container' + (hasError ? ' error' : '')}
        onChange={onChangeSelect}
        hideSelectedOptions={hideSelectedOptions}
        isClearable={isClearable}
        isSearchable={isSearchable}
        components={showCustomContainer ? { ValueContainer } : null}
      />
      {isRequired && (
        <input
          id='select-error-input-hidden'
          type={errorContainer ? 'hidden' : 'text'}
          tabIndex={-1}
          autoComplete='off'
          style={{ opacity: 0, height: 0 }}
          value={value && value}
          required='required'
        />
      )}
      {errorMessage && <small className='selectError'>{errorMessage}</small>}
    </>
  );
};

const ValueContainer = ({ children, getValue, ...props }) => {
  const selectedValue = getValue();
  var length = selectedValue.length;
  let displayText;
  if (length === 1) {
    displayText = selectedValue[0]['label'];
  } else if (length > 1) {
    displayText = `${length} Item${length !== 1 ? 's' : ''} selected`;
  } else {
    displayText = '';
  }

  return (
    <components.ValueContainer {...props}>
      {!props.selectProps.inputValue && displayText}
      {React.Children.map(children, (child) => {
        return child.type === components.Input ? child : null;
      })}
    </components.ValueContainer>
  );
};

export default SelectComponent;
