import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DocumentsConfig from "./component";
import { fetchAttributesData } from "./actions";
import { fetchRegionList } from "../documents-region-list/actions";

const mapStateToProps = ({
  documentsRegionList: regionList,
  documentsConfig: {
    isAttributesFetched
  }
}) => ({
  isAttributesFetched,
  regionList
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchRegionListConnect: fetchRegionList,
  fetchAttributesDataConnect: fetchAttributesData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsConfig);
