import { config } from './../config/local';
import { IS_ENV_DEV, IS_MOCK, PAGE_SIZE } from '../../shared/utils/constants';
import qs from 'querystring';
import { buildQuery } from '../../services/helper';

export default (api) => {
  const fetchOrderDetail = (appointmentId) => {
    const path = config.api.c2c.order.path;
    const url = `${path}/${appointmentId}`;
    return api.get(url);
  };

  const fetchOrders = (params = { page: 0, size: 10 }) => {
    const path = config.api.c2c.orders.path;
    const queryStr = params ? `?${buildQuery(params)}` : '';
    const url = path + queryStr;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };

  const fetchCarDetail = (appointmentId) => {
    const path = config.api.c2c.orders.path;
    const url = `${path}/${appointmentId}`;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };

  const fetchDepositionsList = (orderState) => {
    const path = config.api.c2c.despositions.path;
    const url = `${path}/${orderState}/despositions`;

    return api.get(url);
  };

  const fetchSellerDetail = (appointmentId) => {
    const path = config.api.c2c.sellerInfo.path;
    const url = `${path}/${appointmentId}`;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };
  const fetchDealerDetail = (appointmentId) => {
    const path = config.api.c2c.dealerInfo.path;
    const url = `${path}/${appointmentId}`;
    return api.get(url);
  };
  const fetchImageDetail = (orderId) => {
    const path = config.api.c2c.getImage.path;
    const url = `${path}/${orderId}`;
    return api.get(url);
  };
  const submitCarDetail = (data) => {
    const path = config.api.c2c.carInfo.path;
    const url = `${path}`;
    return api.put(url, data);
  };

  const getMakeData = () => {
    const path = config.api.c2c.makeData.path;
    const url = `${path}`;
    return api.get(url);
  };
  const getModelData = (id) => {
    const path = config.api.c2c.modelData.path;
    const url = `${path}/${id}`;
    return api.get(url);
  };
  const getVariantData = (id) => {
    const path = config.api.c2c.variantdata.path;
    const url = `${path}/${id}`;
    return api.get(url);
  };

  const downloadImage = (id) => {
    const path = config.api.c2c.downloadImage.path;
    const url = `${path}/${id}`;
    return api.get(url, { responseType: 'blob' });
  };
  const replaceImage = (data) => {
    const path = config.api.c2c.getImage.path;
    const url = `${path}`;
    return api.put(url, data);
  };
  const approveImage = (data, orderId) => {
    const path = config.api.c2c.approveImage.path;
    const url = `${path}`;
    return api.put(url, data);
  };

  const fetchPublishRemarks = (
    orderId,
    params = { page: 0, size: PAGE_SIZE['20'] }
  ) => {
    const path = config.api.c2c.publishRemarks.path;
    const queryStr = qs.stringify(params);
    const url = `${path}/${orderId}?${queryStr}`;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };

  const fetchPricingLogs = (
    appointmentId
  ) => {
    const path = config.api.c2c.pricingLogs.path;
    const url = `${path}/${appointmentId}`;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };

  const updateOrderState = (state) => {
    const path = config.api.c2c.updateOrderState.path;
    const url = `${path}/${state.appointmentId}/state/${state.dispositionCode}`;
    let data = { remarks: state.remarks };
    if (state.soldPrice) {
      data['soldPrice'] = state.soldPrice;
    }
    return api.put(url, data);
  };

  const generateInvoice = (id) => {
    const path = config.api.c2c.generateInvoice.path;
    const url = `${path}/${id}`;
    return api.put(url);
  };

  /* Update Seller */

  const updateOrder = (data) => {
    const path = config.api.c2c.updateOrder.path;
    const url = path;
    return api.put(url, data);
  };

  /* Convert to GS CAR */
  const updateOnboardInventory = (data) => {
    const path = config.api.c2c.onboardInventory.path;
    const url = path;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = {
        url: url,
        baseURL: config.api.local.host,
        method: 'post',
        data: data
      };
      return api(urlConfig);
    } else {
      return api.post(url, data);
    }
  };

  const fetchInspectionDetail = (appointmentId) => {
    const path = config.api.c2c.inspection.path;
    const url = `${path}/${appointmentId}`;

    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };

  // const updateInventory = async (data, params) => {
  //   const host = config.api.c2c.host;
  //   const path = config.api.c2c.updateInventory.path;
  //   const url = host.concat(path);
  //   const response = await httpService.put(url, data, params);
  //   if (response && response.status === httpConstants.HTTP_STATUS_OK) {
  //     showToastMessages('Inventory has been updated successfully');
  //     return response.data ? response.data : [];
  //   } else {
  //     return [];
  //   }
  // };

  const updateInventory = (data, params) => {
    const path = config.api.c2c.updateInventory.path;
    const queryStr = params ? `?${qs.stringify(params)}` : '';
    const url = `${path}${queryStr}`;
    return api.put(url, data);
  };

  // const getInventoryLog = async (appointmentId) => {
  //   const host = config.api.c2c.host;
  //   const path = config.api.c2c.inventoryLog.path;
  //   const url = host
  //     .concat(path)
  //     .concat('/')
  //     .concat(appointmentId);
  //   const response = await httpService.get(url, {});
  //   if (response && response.status === httpConstants.HTTP_STATUS_OK) {
  //     return response.data ? response.data : [];
  //   } else {
  //     return [];
  //   }
  // };

  //get inventory log data
  const fetchInventoryLog = (appointmentId, params = null) => {
    const path = config.api.c2c.inventoryLog.path;
    const queryStr = params ? `?${qs.stringify(params)}` : '';
    const url = `${path}/${appointmentId}${queryStr}`;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };

  return {
    fetchOrders,
    fetchCarDetail,
    fetchOrderDetail,
    fetchSellerDetail,
    fetchDealerDetail,
    fetchImageDetail,
    replaceImage,
    approveImage,
    submitCarDetail,
    getMakeData,
    getModelData,
    getVariantData,
    downloadImage,
    fetchPublishRemarks,
    updateOrderState,
    generateInvoice,
    updateOnboardInventory,
    updateOrder,
    fetchInspectionDetail,
    updateInventory,
    fetchInventoryLog,
    fetchDepositionsList,
    fetchPricingLogs
  };
};
