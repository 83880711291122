import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    dealInfo: null,
    isLoading: false,
    error: false,
    message: null
};

export const setRcDealInfo = (state = INITIAL_STATE, action) => {
    const { data } = action
    return { ...state, dealInfo: data };
};

export const HANDLERS = {
    [Types.SET_RC_DEAL_INFO]: setRcDealInfo

};

export default createReducer(INITIAL_STATE, HANDLERS);
