
import React, {useState} from "react";
import InputText from "../../../../shared/components/input-text";
import styles from "./styles.module.css";
import { PINCODE_LENGTH } from "../Constants";
import pincodeValidator from "../Validators/pincode-validator";

const CustomerDetails = ({ buyerData, form, rules = {}, is_booking_assist_buyer, isFormDisabled }) => {
    const { phone } = buyerData;
    const [invalidPincode, setInvalidPincode] = useState(true);
    const [buyerLocation, setBuyerLocation] = useState(form.state.buyerPincode || "");
    const onChange = (e) => {
      form.dispatch({ type: e.target.name, [e.target.name]: e.target.value || "" });
    };
    const onChangePinCode = (e) => {
      const value = e.target.value;
      setInvalidPincode(true);
      setBuyerLocation(value);
      if (value && value.length === 6) {
        setInvalidPincode(false);
      }
      form.dispatch(
        {
          type: 'buyerPincode',
          buyerPincode: value
        }
      )
    };

    const fallback = {
      required: true,
      editable: true
    };
    return (
      <React.Fragment>
        <div className='row'>
          <h2 className={styles.title}>Customer Details</h2>
        </div>
        <div className='row'>
          <div className={`col-2 form-group disp-box d-flex align-items-center`}>
            <label for='name' className='lbl'>
              Name :
            </label>
          </div>
          <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
            <InputText
              type='text'
              name={'name'}
              value={form.state.name}
              onChange={onChange}
              required={(rules["name"] || {}).required}
              // disabled={isFormDisabled ? isFormDisabled : !(rules["name"] || {}).editable}
              isError={(rules["name"] || {}).required && !form.state.name}
              version={"v2"}
              errorMessage={"Required"}
            />
          </div>
          <div className='col-2 form-group disp-box d-flex  align-items-center'>
            <label for='number' className='lbl'>
              Phone Number :
            </label>
          </div>
          <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
            <InputText
                type='text'
                className='form-control'
                name={'number'}
                value={phone || form.state.buyerPhone}
                required={(rules["phone"] || {}).required}
                disabled={isFormDisabled ? isFormDisabled : !(rules["phone"] || {}).editable}
                version={"v2"}
                errorMessage={"Required"}
              />
          </div>
        </div>
        <div className="row">
          {!is_booking_assist_buyer && <div className={`col-2 form-group disp-box d-flex align-items-center`}>
            <label for='name' className='lbl'>
              Sec Phone :
            </label>
          </div>}
          {!is_booking_assist_buyer && <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
            <InputText
              type='number'
              name={'alternatePhone'}
              value={form.state.alternatePhone}
              onChange={onChange}
              maxLength={11}
              required={(rules["alternatePhone"] || {}).required}
              disabled={isFormDisabled ? isFormDisabled : !(rules["alternatePhone"] || {}).editable}
              isError={(rules["alternatePhone"] || {}).required && !form.state.alternatePhone}
              version={"v2"}
              errorMessage={"Required"}
            />
          </div>}
          <div className='col-2 form-group disp-box d-flex  align-items-center'>
            <label for='number' className='lbl'>
              Email :
            </label>
          </div>
          <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
            <InputText
                type='email'
                name={'email'}
                value={form.state.email}
                onChange={onChange}
                required={(rules["email"] || {}).required}
                // disabled={isFormDisabled ? isFormDisabled : !(rules["email"] || {}).editable}
                isError={(rules["email"] || {}).required && !form.state.email}
                version={"v2"}
                errorMessage={"Required"}
              />
          </div>
        </div>
        {!phone && <div className='row'>
          <div className={`col-2 form-group disp-box d-flex align-items-center`}>
            <label for='buyerPincode' className='lbl'>
              Buyer Pin Code :
            </label>
          </div>
          <div className={`col-4 form-group disp-box d-flex align-items-center ${styles.inputWrapper}`}>
            <InputText
              type='number'
              name={'buyerPincode'}
              onChange = {onChangePinCode}
              value={buyerLocation}
              required={true}
              isError={invalidPincode}
              maxLength={PINCODE_LENGTH}
              errorMessage="Enter Valid Pin Code"
              version={"v2"}
              />
          </div>
        </div>}
      </React.Fragment>
    );
};

export default CustomerDetails;
