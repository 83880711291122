import React from 'react';
import withAdminLayout from '../../../shared/hoc/admin-layout';
import ErrorBoundary from '../../components/common/errorBoundary';
import { withAuthorization } from '../../../shared/utils/firebase';
import VahanApiFrequency from '../../components/vahanApiFrequency';

const VahanApiFrequencyPage = () => {
  return (
    <div>
      <ErrorBoundary>
        <VahanApiFrequency />
      </ErrorBoundary>
    </div>
  );
};
export default withAdminLayout(
  withAuthorization(() => true)(VahanApiFrequencyPage)
);
