import {
  inventoryStatusConstants,
  backgroundCheckStatusConstants,
  orderStateConstants,
  carSourceConstants,
  carSourceOptions,
  ORDER_STATE_OPTIONS
} from '../../../../shared/utils/constants/inventory';

const columnDefs = [
  {
    headerName: 'Appt. ID',
    width: 120,
    field: 'appointmentId',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      // debounceMs: 0,
      caseSensitive: true,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    cellRenderer: 'LinkRenderer',
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Reg. No',
    valueFormatter: 'value.toUpperCase()',
    field: 'registrationNumber',
    width: 140,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Make',

    field: 'make',
    filter: 'agTextColumnFilter',
    width: 140,
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  {
    headerName: 'Model',
    field: 'model',
    width: 110,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  // {
  //   headerName: 'Inventory Status',
  //   field: 'inventoryStatus',
  //   width: 170,
  //   filter: 'agTextColumnFilter',
  //   filterParams: {
  //     filterOptions: ['equals'],
  //     caseSensitive: false,
  //     suppressAndOrCondition: true,
  //     applyButton: true,
  //     clearButton: true,
  //     newRowsAction: 'keep'
  //   },
  //   floatingFilterComponent: 'DropDownFilter',
  //   floatingFilterComponentParams: {
  //     suppressFilterButton: true,
  //     name: 'inventoryStatus',
  //     options: [
  //       { key: inventoryStatusConstants.NEW_CAR, value: 'New Car' },
  //       { key: inventoryStatusConstants.IN_TRANSIT, value: 'In Transit' },
  //       { key: inventoryStatusConstants.STOCK_IN, value: 'Stock In' },
  //       { key: inventoryStatusConstants.STOCK_OUT, value: 'Stock Out' },
  //       {
  //         key: inventoryStatusConstants.PICKUP_REQUEST,
  //         value: 'Pickup Request'
  //       },
  //       { key: inventoryStatusConstants.BID_WON, value: 'Bid Won' }
  //     ]
  //   },
  //   cellRenderer: 'LabelRenderer',
  //   cellRendererParams: {
  //     options: [
  //       { key: inventoryStatusConstants.NEW_CAR, value: 'New Car' },
  //       { key: inventoryStatusConstants.IN_TRANSIT, value: 'In Transit' },
  //       { key: inventoryStatusConstants.STOCK_IN, value: 'Stock In' },
  //       { key: inventoryStatusConstants.STOCK_OUT, value: 'Stock Out' },
  //       {
  //         key: inventoryStatusConstants.PICKUP_REQUEST,
  //         value: 'Pickup Request'
  //       },
  //       { key: inventoryStatusConstants.BID_WON, value: 'Bid Won' }
  //     ]
  //   },
  //   suppressMenu: true
  // },
  {
    headerName: 'Store',
    width: 140,
    field: 'storeName',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    cellRenderer: 'LabelRenderer',
    floatingFilterComponentParams: { suppressFilterButton: true }
  },
  // {
  //   headerName: 'BG Status',
  //   width: 140,
  //   field: 'backgroundCheckStatus',
  //   filter: 'agTextColumnFilter',
  //   filterParams: {
  //     filterOptions: ['equals'],

  //     caseSensitive: false,
  //     suppressAndOrCondition: true,
  //     applyButton: true,
  //     clearButton: true,
  //     newRowsAction: 'keep'
  //   },
  //   floatingFilterComponent: 'DropDownFilter',
  //   floatingFilterComponentParams: {
  //     suppressFilterButton: true,
  //     name: 'backgroundCheckStatus',
  //     options: [
  //       { key: backgroundCheckStatusConstants.PENDING, value: 'Pending' },
  //       { key: backgroundCheckStatusConstants.ASSURED, value: 'Assured' },
  //       {
  //         key: backgroundCheckStatusConstants.NON_ASSURED,
  //         value: 'Non Assured'
  //       },
  //       { key: backgroundCheckStatusConstants.DELIST, value: 'Delist' }
  //     ]
  //   },
  //   cellRenderer: 'LabelRenderer',
  //   cellRendererParams: {
  //     options: [
  //       { key: backgroundCheckStatusConstants.PENDING, value: 'Pending' },
  //       { key: backgroundCheckStatusConstants.ASSURED, value: 'Assured' },
  //       {
  //         key: backgroundCheckStatusConstants.NON_ASSURED,
  //         value: 'Non Assured'
  //       },
  //       { key: backgroundCheckStatusConstants.DELIST, value: 'Delist' }
  //     ]
  //   },
  //   suppressMenu: true
  // },
  {
    headerName: 'Order State',
    width: 100,
    field: 'orderState',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'orderState',
      options: Object.values(ORDER_STATE_OPTIONS)
    },
    cellRenderer: 'LabelRenderer',
    cellRendererParams: {
      options: Object.values(ORDER_STATE_OPTIONS)
    },
    suppressMenu: true
  },
  {
    headerName: 'Car Source',
    width: 200,
    field: 'carSource',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equals'],
      caseSensitive: false,
      suppressAndOrCondition: true,
      applyButton: true,
      clearButton: true,
      newRowsAction: 'keep'
    },
    floatingFilterComponent: 'DropDownFilter',
    floatingFilterComponentParams: {
      suppressFilterButton: true,
      name: 'displayCarSource',
      options: Object.values(carSourceOptions)
    },
    // cellRenderer: 'LabelRenderer',
    // cellRendererParams: {
    //   options: Object.values(carSourceOptions)
    // },
    suppressMenu: true,
    valueFormatter: function(params) {
      return params.data.displayCarSource;
    },
  }
];

export default columnDefs;
