import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import { console_in_dev } from '../../../shared/utils/helper';
import FinancePaymentForm from '../finance-payment-form';
import LogsWithAccordian from '../logs-finance';
import { TRX_HEADERS } from './constants';
import { withRouter } from 'react-router-dom';
import FinanceRefundModal from '../finance-refund-modal';
import FinanceSwitchProof from '../finance-proof-switch-modal';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';
import './styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ZoomWrapper from '../../../shared/zoom-wrapper';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const FinancePaymentTab = (props) => {
  const { user_type } = props;
  const [dealId] = useState(props.match.params.id);
  const [paymentInfo, setPayInfo] = useState({});
  const [activeImageId, setActiveImageId] = useState(0);
  const sliderRef = useRef();
  const zoomRef = useRef();
  useEffect(() => {
    props.fetchPaymentInfoConnected(user_type, dealId);
    props.fetchTransactionLogsConnected(user_type, dealId);
    props.fetchPaymentModeConnected();
  }, []);

  useEffect(() => {
    setPayInfo(props.paymentInfo);
  }, [props && props.paymentInfo]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    afterChange: (current) => {
      console_in_dev({ current }, 'afterChange');
      setActiveImageId(current);
    },
    onInit: (current = 0) => {
      console_in_dev({ onInit: current });
      setActiveImageId(current);
    }
  };
  const onAddMore = (id, data, newId) => {
    const imageProofData = paymentInfo[user_type]['image_proofs'];
    props.addMorePaymentInfoConnected(data).then((res) => {
      props.fetchFinanceDealInfoByIdConnected(dealId);
      let payload = {
        image_url: imageProofData[id].image_url,
        bank_name: '',
        payment_date: '',
        payment_mode_id: '',
        utr: '',
        id: res,
        amount: '',
        user_type: ''
      };

      setPayInfo({
        ...paymentInfo,
        [user_type]: {
          ...paymentInfo[user_type],
          image_proofs: [payload, ...paymentInfo[user_type].image_proofs]
        }
      });
      setTimeout(() => {
        sliderRef.current.slickGoTo(0);
      }, 250);
    });
  };
  const onDiscard = (id, imageId, payload) => {
    props.discardPaymentInfoConnected(id, payload).then(() => {
      let payinfo = { ...paymentInfo };
      props.fetchFinanceDealInfoByIdConnected(dealId);
      if (payload.is_discard) {
        // discard flow
        payinfo[user_type]['image_proofs'][imageId].is_discarded =
          payload.is_discard;
        payinfo[user_type]['image_proofs'][imageId].reason = payload.reason;
        payinfo[user_type]['image_proofs'][imageId].remarks = payload.remarks;
        setPayInfo(payinfo);
      } else {
        // validate flow
        payinfo[user_type]['image_proofs'][imageId].is_discarded =
          payload.is_discard;
        payinfo[user_type]['image_proofs'][imageId].reason = null;
        payinfo[user_type]['image_proofs'][imageId].remarks = null;
        setPayInfo(payinfo);
      }
    });
  };
  const showAddMoreButton = (imageId) => {
    let payinfo = { ...paymentInfo };

    payinfo[user_type]['image_proofs'][imageId].is_validated = true;
    setPayInfo(payinfo);
  };

  return (
    <LoaderWithAccessControl>
      <div className='seller-payment'>
        <div className='row'>
          <div className='col-md-5'>
            <FinancePaymentForm
              paymentInfo={paymentInfo[user_type]}
              activeImageId={activeImageId}
              onAddMore={onAddMore}
              onDiscard={onDiscard}
              paymentModes={paymentInfo && paymentInfo.paymentModes}
              userType={user_type}
              dealId={dealId}
              showAddMoreButton={showAddMoreButton}
            />
          </div>
          <div className='col-md-7'>
            <div className='slider'>
              <Slider ref={sliderRef} {...settings}>
                {paymentInfo &&
                  paymentInfo[user_type] &&
                  paymentInfo[user_type]['image_proofs'] &&
                  paymentInfo[user_type]['image_proofs'].map((item, index) => {
                    return (
                      <TransformWrapper>
                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                          <>
                            <ZoomWrapper zoomIn={zoomIn} zoomOut={zoomOut} />
                            <TransformComponent>
                              <div
                                key={`${user_type}_${index}`}
                                onDoubleClick={resetTransform}
                              >
                                <img src={item.image_url} alt='none' />
                              </div>
                            </TransformComponent>
                          </>
                        )}
                      </TransformWrapper>
                    );
                  })}
              </Slider>
            </div>
          </div>
          <div className='col-12 '>
            <div className='wrapperAgentDetails p15 mt30 d-flex'>
              <div className='col-4 text-center'>
                <FinanceRefundModal
                  paymentModes={paymentInfo && paymentInfo.paymentModes}
                  userType={user_type}
                  dealId={dealId}
                />
              </div>
              <div className='col-4 text-center'>
                <FinanceSwitchProof
                  paymentModes={paymentInfo && paymentInfo.paymentModes}
                  userType={user_type}
                  dealId={dealId}
                />
              </div>
            </div>
            <div className='wrapperAgentDetails p15 mt30'>
              <LogsWithAccordian
                title='Transaction Log'
                headers={TRX_HEADERS}
                userType={user_type}
                dealId={dealId}
                data={paymentInfo.transactionLogs || []}
              />
            </div>
          </div>
        </div>
      </div>
    </LoaderWithAccessControl>
  );
};

export default withRouter(FinancePaymentTab);
