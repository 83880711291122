import React, { useEffect } from 'react'
import { Descope, useSession, useUser } from '@descope/react-sdk'
import { removeDataFromLocalStorage, saveToLocalStorage } from '../../utils/helper';
import { EMAIL, IS_LOGGED_IN, USER_ID, USER_ROLES } from '../../utils/constants';
import { descopeConfig } from '../../../config/descope';

const Login = (props) => {
  const { isAuthenticated, isSessionLoading } = useSession();
  const { user, isUserLoading } = useUser();

  useEffect(() => {
    if(!isUserLoading && isAuthenticated) {
      handleLoginSuccess(user);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoading, isAuthenticated]);

  const handleLoginSuccess = (user) => {
    const { userId, email, roleNames } = user;

    saveToLocalStorage(EMAIL, email);
    saveToLocalStorage(IS_LOGGED_IN, true);
    saveToLocalStorage(USER_ID, userId);
    saveToLocalStorage(USER_ROLES, roleNames);

    if (window.location.search) {
      const returnUrl = new URLSearchParams(window.location.search).get("return_url");
      props.history.push(returnUrl);
    } else {
      props.history.push('/');
    }
  }

  const handleLoginFailure = (e) => {
    removeDataFromLocalStorage();
    console.error(e);
  }
 
  if(isSessionLoading || isUserLoading) return <p>Loading...</p>

  return (
    <>
      {!isAuthenticated &&
        (
          <Descope
            flowId={descopeConfig.flowId}
            onSuccess={(e) => handleLoginSuccess(e.detail.user)}
            onError={handleLoginFailure}
          />
        )
      }
    </>
  )
}

export default Login;