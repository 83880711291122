import React, { useState, useEffect } from 'react';
import AgentDetail from './rc-others-agent-detail';
import './style.css';
import { rcManagementService } from '../../../../services';
import { withRouter } from 'react-router-dom';
import Loader from '../../common/loader';
import AgentRemarks from './rc-others-agent-remarks/component';
import { httpConstants } from '../../../constants/httpConstants';
import LoaderWithAccessControl from '../../../../shared/components/loader-with-access-control';
import DelayReasons from './rc-others-delay-reasons';
import RcCorrections from './rc-others-corrections';
import RcOthersTrackerForm from './rc-others-tracker-form';
import { RC_OTHERS_TRACKER_METADATA } from './rc-others-tracker-form/meta-data';
import { AGENT_STATUS } from '../../AgentDetail/constants';

const RcOthersTracker = ({ match, dealInfo = {}, delayReasons }) => {
  const dealId = match.params.id;
  const { rcTrackerId = null} = dealInfo || {};
  const [trackerData, setTrackerData] = useState({
    deal_id: dealId,
    rcApplicationId: dealId
  });
  const [originalTrackerData, setOriginalTrackerData] = useState({
    deal_id: dealId,
    rcApplicationId: dealId
  });
  const [error, setError] = useState();
  const [updatedAgentHistory, setUpdatedAgentHistory] = useState([]);
  const [delayReasonsData, setDelayReasonsData] = useState([]);
  const [corrections, setCorrections] = useState();
  
  useEffect(() => {
    setTrackerData({ ...trackerData, id: rcTrackerId });
    rcTrackerId && fetchTrackerData(rcTrackerId);
    rcTrackerId && fetchAgentLog(rcTrackerId);
    fetchDelayReasonsData();
    fetchRcCorrectionsData();
  }, [dealInfo]);

  const fetchDelayReasonsData = () => {
    rcManagementService.fetchOthersRcDelayReasons(dealId)
      .then((response) => {
        setDelayReasonsData(response);
      });
  };

  const fetchRcCorrectionsData = () => {
    rcManagementService.getRcCorrectionsById(dealId, true).then((resp)=>{
      setCorrections(resp.data);
    });
  };

  const fetchTrackerData = (rcTrackerId, withLoader = true, cb = () => {}) => {
    rcManagementService["fetchRcOthersTrackerData"](rcTrackerId, withLoader)
      .then((response) => {
        if (response) {
          setTrackerData({
            ...response,
            deal_id: dealId,
            rcApplicationId: dealId,
            baseAgentId: response.agentId,
            baseAgentStatus: response.agentStatus,
            baseAgentCost: response.agentCost || 0,
            agentCost: response.agentCost || 0
          });
          setOriginalTrackerData({
            ...response,
            deal_id: dealId,
            rcApplicationId: dealId,
            baseAgentId: response.agentId,
            baseAgentStatus: response.agentStatus,
            baseAgentCost: response.agentCost || 0,
            agentCost: response.agentCost || 0
          });
          cb(response);
        }
      });
  };

  const fetchAgentLog = (rcTrackerId) => {
    rcManagementService["fetchRcOthersAgentHistory"](rcTrackerId).then((response) => {
      setUpdatedAgentHistory(response);
    });
  };

  const updateTrackerData = (revisedTrackerData) => {
    revisedTrackerData && setTrackerData(revisedTrackerData);
  };

  const updateRcTracker = (payload) => {
    return rcManagementService.updateOthersRcTrackerData(payload);
  }

  const saveStatus = () => {
    setError("");

    const payload = {
      ...trackerData,
      rcApplicationId: dealId,
      ...(!trackerData['id'] && {
        agentCost: trackerData.agentCost || 0,
        agentStatus: trackerData['agentId']
          ? AGENT_STATUS.ACTIVE['value']
          : null,
      }),
      rcCorrectionReasons: corrections,
    };

    // validations for subcategory
    let isValid = true;

    const fieldsArr = RC_OTHERS_TRACKER_METADATA({
      serviceDetail: dealInfo.serviceDetail,
    });

    fieldsArr.forEach((fieldData) => {
      if (fieldData && fieldData.hasOwnProperty('fieldType') && fieldData.fieldType === 'delay_dropdown') {
        const { key, label } = fieldData;
        if (trackerData && trackerData[key] && trackerData[key].category && !trackerData[key].subCategory) {
          setError(`Sub Delay Reason for: ${label}`);
          isValid = false;
        }
      }
    });
    
    if (!isValid) return false;

    updateRcTracker(payload)
      .then((response) => {
        if (response.status === httpConstants.HTTP_STATUS_OK) {
          fetchTrackerData(response.data, false);
          fetchAgentLog(response.data);
          fetchDelayReasonsData();
          fetchRcCorrectionsData();
        }
      });
  };

  return (
    <LoaderWithAccessControl>
      <>
        {trackerData ? (
          <>
            <RcOthersTrackerForm
              serviceDetail={dealInfo && dealInfo.serviceDetail}
              delayReasons={delayReasons}
              originalTrackerData={originalTrackerData}
              trackerData={trackerData}
              updateTrackerData={updateTrackerData}
              fetchTrackerData={() => fetchTrackerData(trackerData['id'], true)}
            />
            <div className='row mt-20'>
              <div className='wrapperAgentDetails p15'>
                <AgentDetail
                  trackerData={trackerData}
                  updateTrackerData={updateTrackerData}
                  rcTrackerId={trackerData['id']}
                  updateError={setError}
                  updatedAgentHistory={updatedAgentHistory}
                />
              </div>
            </div>
            <div className='row'>
              <div className='wrapperAgentDetails p15'>
                <DelayReasons 
                  trackerData={trackerData}
                  updateTrackerData={updateTrackerData}
                  delayReasonsData={delayReasonsData}
                  setDelayReasonsData={setDelayReasonsData}
                />
              </div>
            </div>
            <div className='row'>
              <div className='wrapperAgentDetails p15'>
                <RcCorrections 
                  rcTrackerId={rcTrackerId}
                  corrections={corrections}
                  setCorrections={setCorrections}
                />
              </div>
            </div>
            <div className='align-item-baseline'>
              <button
                type='button'
                onClick={saveStatus}
                className='btn btn-primary mr20'
              >
                Save Status
              </button>
              {!!error && <font color='red'>Required field: {error}</font>}
            </div>
            {trackerData['id'] ? (
              <div className='row'>
                <div className='wrapperAgentDetails mt-20'>
                  <AgentRemarks rcTrackerId={trackerData['id']} />
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <Loader />
        )}
      </>
    </LoaderWithAccessControl>
  );
};
export default withRouter(RcOthersTracker);