import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  FETCH_ATTRIBUTES_DATA_SUCCESS
  UPDATE_ADD_CONFIG_DATA
  FETCH_CASE_TYPE_CONFIG_SUCCESS
  RESET_CONFIG_LIST
  UPDATE_CASE_TYPE
`,
  {
    prefix: 'caseTypeRuleEngine/'
  }
);
