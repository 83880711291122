import { IS_ENV_DEV, IS_MOCK } from '../../shared/utils/constants';
import { buildQuery } from '../../services/helper';
import { config } from './../config/local';

export default (api) => {

  const fetchTaskManagementGridInfo = (params) => {
    const path = config.api.c2c.taskManagement.fetchGridInfo.path;
    const queryStr = params ? `?${buildQuery(params)}` : '';
    const url = path + queryStr;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };

  const updateAssignTo = (params, body) => {
    return new Promise((resolve, reject) => {
      const url = config.api.c2c.taskManagement.updateAssignTo.path;
      api.put(url, body)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        })
    });
  };

  const fetchAgents = (params) => {
    return new Promise((resolve, reject) => {
      let url = config.api.c2c.taskManagement.fetchAgents.path;
      if (params) {
        url = `${url}?${buildQuery(params)}`;
      }
      api.get(url)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          reject(error);
        })
    });
  }

  return { 
    fetchTaskManagementGridInfo,
    updateAssignTo,
    fetchAgents,
  };
};
