import React, { useState, useEffect } from 'react';
import AgentDetail from '../AgentDetail';
import RcTrackerFileStatus from '../rc-Tracker-FileStatus';
import './style.css';
import { HANDOVER_METHODS_BUYER_VALUES, RC_FILE_STATUS } from '../rc-Tracker-FileStatus/constant';
import { rcManagementService } from '../../../services';
import { withRouter } from 'react-router-dom';
import Loader from '../common/loader';
import AgentRemarks from '../AgentRemarks/component';
import { httpConstants } from '../../constants/httpConstants';
import { AGENT_STATUS } from '../AgentDetail/constants';
import CONSTANTS from './constants';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';
import DelayReasons from '../DelayReasons';
import { useIsRcOthers } from '../../../shared/hooks';
import RcCorrections from '../rc-corrections';
import { useRcTracker } from '../../hooks/custom-hooks/rc-tracker-hook';

const DEFAULT_PAYLOAD = {
  id: null,
  deal_id: null,
  file_rc_team_date: null,
  rc_transfer_status: null,
  expected_rc_completion_date: null,
  handover_to_first_agent: null,
  e_receipt_issue_date: null,
  rc_transfer_completion_date: null,
  rc_handover_buyer_method: null,
  speedpost_delivery_status: null,
  speedpost_tracking_id: null,
  speedpost_dispatch_date: null,
  speedpost_dispatch_link: null,
  agent_status: null,
  agent_cost: 0,
  agent_id: null,
  new_agent_id: null,
  new_agent_cost: 0,
  previous_agent_payment: 0,
  base_agent_id: null,
  base_agent_status: null,
  base_agent_cost: 0,
  rc_application_id: null
};

const RcTracker = ({ match, dealInfo = {}, delayData }) => {
  const isOtherRcView = useIsRcOthers();
  const { config } = useRcTracker();
  const dealId = match.params.id;
  const { rcTrackerId = null} = dealInfo || {};
  const [trackerData, setTrackerData] = useState({
    ...DEFAULT_PAYLOAD,
    deal_id: dealId,
    rc_application_id: dealId
  });
  const [originalTrackerData, setOriginalTrackerData] = useState({
    ...DEFAULT_PAYLOAD,
    deal_id: dealId,
    rc_application_id: dealId
  });
  const [error, setError] = useState();
  const [updatedAgentHistory, setUpdatedAgentHistory] = useState([]);
  const [delayReasonsData, setDelayReasonsData] = useState([]);
  const [corrections, setCorrections] = useState();
  
  useEffect(() => {
    setTrackerData({ ...trackerData, id: rcTrackerId });
    rcTrackerId && fetchTrackerData(rcTrackerId);
    rcTrackerId && fetchAgentLog(rcTrackerId);
    fetchDelayReasonsData();
    fetchRcCorrectionsData();
  }, [dealInfo]);
  
  const fetchDelayReasons = (id) => {
    return isOtherRcView ? rcManagementService.fetchOthersRcDelayReasons(id) : rcManagementService.fetchDelayReasons(id);
  }

  const fetchDelayReasonsData = () => {
    fetchDelayReasons(dealId).then((response) => {
      setDelayReasonsData(response);
    });
  };

  const fetchRcCorrectionsData = () => {
    rcManagementService.getRcCorrectionsById(dealId, isOtherRcView).then((resp)=>{
      setCorrections(resp.data);
    });
  };

  const fetchTrackerData = (rcTrackerId, withLoader = true, cb = () => {}) => {
    rcManagementService[isOtherRcView ? "fetchRcOthersTrackerData": "fetchRcTrackerData"](rcTrackerId, withLoader)
      .then((response) => {
        if (response) {
          setTrackerData({
            ...response,
            deal_id: dealId,
            base_agent_id: response.agent_id,
            base_agent_status: response.agent_status,
            base_agent_cost: response.agent_cost || 0,
            agent_cost: response.agent_cost || 0
          });
          setOriginalTrackerData({
            ...response,
            deal_id: dealId,
            base_agent_id: response.agent_id,
            base_agent_status: response.agent_status,
            base_agent_cost: response.agent_cost || 0,
            agent_cost: response.agent_cost || 0
          });
          cb(response);
        }
      });
  };

  const fetchAgentLog = (rcTrackerId) => {
    rcManagementService[isOtherRcView ? "fetchRcOthersAgentHistory": "fetchAgentHistory"](rcTrackerId).then((response) => {
      setUpdatedAgentHistory(response);
    });
  };

  const updateTrackerData = (revisedTrackerData) => {
    revisedTrackerData && setTrackerData(revisedTrackerData);
  };

  const validateRcStatus = (rc_transfer_status) => {
    // if (rc_transfer_status) {
      let validationObj = null;
      CONSTANTS.statusDependencyList.every((item, index) => {
        if (
          item.rc_transfer_status ===
            CONSTANTS.file_status.handed_over_to_agent.value &&
          trackerData.handover_to_first_agent !== null
        ) {
          validationObj = null;
        } else if (item.rc_transfer_status === rc_transfer_status) {
          validationObj = trackerData[item.dependentKey] ? null : item;
        } else {
          validationObj = null;
        }
        if (validationObj) return false;
        return true;
      });
      if (validationObj) {
        setError(validationObj.dependent);
        return true;
      } else {
        setError(null);
        return false;
      }
    // } else {
    //   //sprint-8 Todo:-Chnage to constant
    //   setError('Rc Transfer Status');
    //   return true;
    // }
  };

  const validateTransfer = () => {
    const transferKeys = CONSTANTS.transferRequiredFields;
    let inValidKey = null;
    for (const item of transferKeys) {
      if (item.type === 'cost') {
        inValidKey =
          trackerData[item.key] === 0 || trackerData[item.key] > 0
            ? null
            : item.key;
      } else if (item.type !== 'cost') {
        inValidKey = trackerData[item.key] ? null : item.key;
      }

      if (inValidKey) {
        setError(item.label);
        return false;
      }
    }
    return true;
  };

  const saveRcTracker = (payload) => {
    return isOtherRcView ? rcManagementService.updateOthersRcTrackerData(payload) : rcManagementService.addRcTrackerData(payload)
  }

  const updateRcTracker = (payload) => {
    return isOtherRcView ? rcManagementService.updateOthersRcTrackerData(payload) : rcManagementService.updateRcTrackerData(payload)
  }

  const saveStatus = () => {
    if (
      !validateTransfer() &&
      trackerData['agent_status'] === AGENT_STATUS.TRANSFERRED['value']
    ) {
      return false;
    }

    const isInvalid = validateRcStatus(trackerData['rc_transfer_status']);
    if (isInvalid) return;

    if (trackerData['e_receipt_issue_date'] && !trackerData['e_receipt_id']) {
      setError('E-receipt');
      return false;
    }

    if(!!trackerData.speedpost_tracking_id || !!trackerData.speedpost_dispatch_link || (trackerData.rc_handover_buyer_method === HANDOVER_METHODS_BUYER_VALUES.SPEED_POST)) {
      if(!trackerData.speedpost_tracking_id) {
        setError('Tracking Id');
        return;
      }
      if(!trackerData.speedpost_dispatch_link) {
        setError('Dispatch Link');
        return;
      }
    }

    if (
      trackerData['rc_transfer_completion_date'] &&
      !trackerData['buyer_rc_id']
    ) {
      setError('Buyer RC');
      return false;
    }

    if (
      trackerData['rto_noc_issue_date'] &&
      !trackerData['rto_noc_id']
    ) {
      setError('RTO NOC');
      return false;
    }

    // make compulsory for below fields for Sales
    if (trackerData['rc_handover_buyer_method'] === HANDOVER_METHODS_BUYER_VALUES.SALES) {
      if (!trackerData['customerNameSales']) {
        setError('Customer Name');
        return false;
      }
      if (!trackerData['customerMobileSales']) {
        setError('Customer Mobile Number');
        return false;
      }
      if (!trackerData['receiving_proof_sales_id']) {
        setError('Customer Receiving Proof');
        return false;
      }
    }

    // validations for subcategory
    let isValid = true;
    RC_FILE_STATUS.forEach((fieldData) => {
      if (fieldData && fieldData.hasOwnProperty('type') && fieldData.type === 'delay_dropdown') {
        const { apiKey, label } = fieldData;
        if (trackerData && trackerData[apiKey] && trackerData[apiKey].category && !trackerData[apiKey].subCategory) {
          setError(`Sub Delay Reason for: ${label}`);
          isValid = false;
        }
      }
    });
    if (!isValid) return false;

    if (trackerData['id']) {
      const payload = {
        ...trackerData,
        rc_application_id: dealId,
        rcCorrectionReasons: corrections,
        // To be used when agent_id is not null and agent_status is null (Currently handled by Backend)
        // agent_status: trackerData['agent_id'] && trackerData['agent_status'] ? trackerData['agent_status'] : AGENT_STATUS.ACTIVE['value']
      };
      updateRcTracker(payload)
        .then((response) => {
          //PUT
          if (response.status === httpConstants.HTTP_STATUS_OK) {
            fetchTrackerData(trackerData['id'], false);
            fetchAgentLog(trackerData['id']);
            fetchDelayReasonsData();
            fetchRcCorrectionsData();
          }
        });
    } else {
      const payload = {
        ...trackerData,
        agent_cost: trackerData.agent_cost || 0,
        agent_status: trackerData['agent_id']
          ? AGENT_STATUS.ACTIVE['value']
          : null,
        rc_application_id: dealId,
        rcCorrectionReasons: corrections,
      };
      saveRcTracker(payload).then((response) => {
        //POST
        if (response.status === httpConstants.HTTP_STATUS_OK) {
          fetchTrackerData(response.data.data);
          fetchDelayReasonsData();
          fetchRcCorrectionsData();
        }
      });
    }
  };
  return (
    <LoaderWithAccessControl>
      <>
        {trackerData ? (
          <>
            <RcTrackerFileStatus
              trackerData={trackerData}
              delayData={delayData}
              file={RC_FILE_STATUS}
              updateTrackerData={updateTrackerData}
              fileCompletionDate={dealInfo && dealInfo.fileCompletionDate}
              updateError={setError}
              refetchRCTrackerData={trackerData['id'] ? (cb) => fetchTrackerData(trackerData['id'], true, cb) : () => {}}
              originalTrackerData={originalTrackerData}
            />
            <div className='row'>
              <div className='wrapperAgentDetails p15'>
                <AgentDetail
                  trackerData={trackerData}
                  updateTrackerData={updateTrackerData}
                  rcTrackerId={trackerData['id']}
                  updateError={setError}
                  updatedAgentHistory={updatedAgentHistory}
                />
              </div>
            </div>
            <div className='row'>
              <div className='wrapperAgentDetails p15'>
                <DelayReasons 
                  config={config}
                  trackerData={trackerData}
                  updateTrackerData={updateTrackerData}
                  delayReasonsData={delayReasonsData}
                  setDelayReasonsData={setDelayReasonsData}
                />
              </div>
            </div>
            <div className='row'>
              <div className='wrapperAgentDetails p15'>
                <RcCorrections 
                  rcTrackerId={rcTrackerId}
                  corrections={corrections}
                  setCorrections={setCorrections}
                />
              </div>
            </div>
            <div className='align-item-baseline'>
              <button
                type='button'
                onClick={saveStatus}
                className='btn btn-primary mr20'
              >
                Save Status
              </button>
              {!!error && <font color='red'>Required field: {error}</font>}
            </div>
            {trackerData['id'] ? (
              <div className='row'>
                <div className='wrapperAgentDetails mt-20'>
                  <AgentRemarks rcTrackerId={trackerData['id']} />
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <Loader />
        )}
      </>
    </LoaderWithAccessControl>
  );
};
export default withRouter(RcTracker);