import React from 'react';
import Users from '../../components/user-grid';
import withAdminLayout from '../../../shared/hoc/admin-layout';
import ErrorBoundary from '../../components/common/errorBoundary';
import { withAuthorization } from '../../../shared/utils/firebase';
import withUsersViewAuthorization from '../../../shared/hoc/users-view-authorization';

const StoresPage = () => {
  return (
    <div>
      <ErrorBoundary>
        <Users />
      </ErrorBoundary>
    </div>
  );
};
export default withAdminLayout(withUsersViewAuthorization(withAuthorization(() => true)(StoresPage)));
