import { useEffect, useState } from 'react';
import { documentRuleEngineService, rtoApi } from '../../services';

export const useRtoDetails = () => {
  const fetchStatesList = rtoApi.fetchStatesList;
  const fetchRegionList = documentRuleEngineService.fetchRegionList;
  const fetchDistrictsList = rtoApi.fetchDistrictsList;
  const fetchMandatoryDocsList = rtoApi.fetchMandatoryDocsList;
  const [stateDropdownOptions, setStateDropdownOptions] = useState([]);
  const [regionsDropdownOptions, setRegionDropdownOptions] = useState([]);
  const [districtDropdownOptions, setDistrictDropdownOptions] = useState([]);
  const [mandatoryDocsOptions, setMandatoryDocsOptions] = useState([]);

  useEffect(() => {
    fetchStatesList()
      .then((data) => {
        setStateDropdownOptions(data.map(({ stateCode, stateName }) => ({ value: stateCode, label: stateName })));
      })

    fetchRegionList()
      .then((data) => {
        setRegionDropdownOptions(data.map(({ regionName }) => ({ value: regionName, label: regionName })));
      })

    fetchDistrictsList()
      .then((data) => {
        setDistrictDropdownOptions(data.map(({ code, name }) => ({ value: code, label: name })));
      })

    fetchMandatoryDocsList()
      .then((data) => {
        setMandatoryDocsOptions(data.map(({ id, label }) => ({ value: id, label })));
      })
  }, []);

  return {
    stateDropdownOptions,
    regionsDropdownOptions,
    districtDropdownOptions,
    mandatoryDocsOptions
  };
}