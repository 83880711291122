import { createReducer } from 'reduxsauce';
import Types from './types';

const INITIAL_STATE = {
  isLoading: false,
  isForbidden: false,
  isError: false,
  message: null
};

const resetLoader = (state = INITIAL_STATE) => {
  return INITIAL_STATE;
};

const setLoader = (state = INITIAL_STATE, action) => {
  return { ...state, ...action.payload, isError: false };
};

const setError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    isError: true,
    message: action.payload.message
  };
};

export const HANDLERS = {
  [Types.RESET_LOADER]: resetLoader,
  [Types.SET_LOADER]: setLoader,
  [Types.SET_LOADER_ERROR]: setError
};

export default createReducer(INITIAL_STATE, HANDLERS);
