import React, { useState } from 'react';
import InputText from '../../../../shared/components/input-text';

const InputRenderer = ({
  data,
  attributeKey,
  context: {
    handleInputChange
  }
}) => {
  const [value, setValue] = useState(data[attributeKey]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = () => {
    handleInputChange(attributeKey, value, data.id);
  };

  return (
    <InputText
      key={attributeKey}
      id={attributeKey}
      name={attributeKey}
      value={value}
      onChange={handleChange}
      onBlurCallback={handleBlur}
    />
  );
};

export default InputRenderer;
