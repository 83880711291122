import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  message: null,
  remarks: []
};

export const fetchRemarksRequested = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    isError: false,
    message: null,
    remarks: []
  };
};
export const fetchRemarksSuccess = (state = INITIAL_STATE, action) => {
  const {
    payload: { data },
    withOps
  } = action;
  const remarksType = withOps ? 'remarksOps' : 'remarks';
  return {
    ...state,
    isLoading: false,
    isError: false,
    message: null,
    [remarksType]: data
  };
};

export const fetchRemarksFailure = (state = INITIAL_STATE, action) => {
  const {
    payload: { message }
  } = action;
  return { ...state, isLoading: false, isError: true, message: message };
};

export const HANDLERS = {
  [Types.FETCH_REMARKS_REQUESTED]: fetchRemarksRequested,
  [Types.FETCH_REMARKS_SUCCESS]: fetchRemarksSuccess,
  [Types.FETCH_REMARKS_FAILURE]: fetchRemarksFailure,
  [Types.FETCH_OTHERS_RC_REMARKS_REQUESTED]: fetchRemarksRequested,
  [Types.FETCH_OTHERS_RC_REMARKS_SUCCESS]: fetchRemarksSuccess,
  [Types.FETCH_OTHERS_RC_REMARKS_FAILURE]: fetchRemarksFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
