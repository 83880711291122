import React from "react";
import styles from  './styles.module.css';

const BuyerProfiles = ({customerDetails}) => {
    const {profileFlags} = customerDetails || {};
    return (
        <div className={styles.dataList}>
            <ul>
                {!!profileFlags && Object.keys(profileFlags).map((key)=> {
                    return (
                        <li>
                            <p>{key}</p>
                            <span>{profileFlags[key]}</span>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default BuyerProfiles;
