import Types from '../constants/actionTypes';
import { rcManagementService } from '../../services';
import { console_in_dev } from '../../shared/utils/helper';

const fetchRcDocumentDetail = (RcId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    rcManagementService
      .getRcDocumentsById(RcId)
      .then((resp) => {
        resolve(resp);
        //dispatch(fetchDealerDetailSuccess(resp));
      })
      .catch((error) => {
        // dispatch(fetchDealerDetailFail(error));
        reject(error);
      });
  });
};
const fetchOthersRcDocument = (RcId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    rcManagementService
      .getOthersRcDocuments(RcId)
      .then((resp) => {
        resolve(resp);
        //dispatch(fetchDealerDetailSuccess(resp));
      })
      .catch((error) => {
        // dispatch(fetchDealerDetailFail(error));
        reject(error);
      });
  });
};
const addRc = (data, isOtherRcView) => (dispatch) => {
  return new Promise((resolve, reject) => {
    rcManagementService
      .addRcDocumnet(data, isOtherRcView)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const editRc = (data, isOtherRcView) => (dispatch) => {
  return new Promise((resolve, reject) => {
    rcManagementService
      .editRcDocument(data, isOtherRcView)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const updateOthersRcStatus = (id, params) => () => {
  return new Promise((resolve, reject) => {
    rcManagementService
      .updateOthersRcStatus(id, params)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { fetchRcDocumentDetail, addRc, editRc, fetchOthersRcDocument, updateOthersRcStatus };
