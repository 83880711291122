import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import StoreForm from './component';
import { StateActions, CityActions, StoreActions } from '../../actions';
import {
  fetchStoreInfoByIdConnected,
  addStoreConnected,
  removeStoreDetailConnected
} from './actions';

const mapStateToProps = ({ c2cStoreInfo: { detail } }) => {
  return {
    detail
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeStoreDetailConnected,
      fetchStoreInfoByIdConnected,
      addStoreConnected,

      fetchStates: StateActions.fetchStates,
      fetchCities: CityActions.fetchCities,
      fetchZones: StoreActions.fetchAllZones
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StoreForm);
