import React, { useState, useEffect, useMemo } from 'react';
import getColumns from './columnConfig';
import { Link, withRouter } from 'react-router-dom';

import GridView from '../../common/grid-view';
import CustomPagination from '../../custom-pagination';
import { DEFAULT_PAGE_SIZE } from '../../../constants/constants';
import './style.css';
import Filter from '../../filter';
import LoaderWithAccessControl from '../../../../shared/components/loader-with-access-control';

const RcManagementOthersGrid = function(props) {
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [recordsOnPage, setRecordsOnPage] = useState(0);
  const [totalRecords, SetTotalRecords] = useState(0);
  const [rowData, setRowData] = useState();
  const [queryParams, setQueryParams] = useState({});
  const [resetFilter, setResetFilter] = useState(false);
  const [loaderDisabled, setLoaderDisabled] = useState(false);
  const columnDefs = useMemo(() => getColumns());

  useEffect(()=>{
    let query = {
      page: props.pageNumber || 0,
      size: DEFAULT_PAGE_SIZE,
      ...queryParams
    };
    query = {...query, rc_type_others: true};
    getData(query);
  }, [props, queryParams]);
  
  const getData = (query) => {
    props
      .fetchOthersRcListConnect(query)
      .then((response) => {
        setRowData(response.response);
        setPage(response.page);
        props.setPageNumberConnect(response.page);
        setTotalPages(response.totalPages);
        SetTotalRecords(response.totalRecords);
        setRecordsOnPage(response.response ? response.response.length : 0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onRouteId = (data) => {
    const route = `/rc-tracker-others/${data.deal_id}`;
    props.history.push(route);
  };

  const handlePageChange = (pageNumber, params) => {
    setLoaderDisabled(true);
    const qs = params ? params : queryParams;
    let query = { ...qs };
    query.page = pageNumber;
    query.size = DEFAULT_PAGE_SIZE;
    setQueryParams(query);
  };

  const onFilterChanged = (params = {}) => {
    setQueryParams(params);
    handlePageChange(0, params);
  };

  return (
    <LoaderWithAccessControl disabled={loaderDisabled}>
      <div className='d-flex float-right'>
        <div className="col">
          <Link to='/rc-tracker-others-details/add'>
            <button className='btn btn-success' type='button'>
              Create
            </button>
          </Link>
        </div>
        <div className={"col"}>
          <Filter
            getData={handlePageChange}
            query={queryParams}
            resetGridFilter={setResetFilter}
            isRCMgmtOthers={true}
          />
        </div>
      </div>
      {rowData && (
        <div className="rc-grid">
          <GridView
            columnDefs={columnDefs}
            rowData={rowData && rowData}
            onRedirect={onRouteId}
            uniqueIdName={'uid'}
            onFilterChanged={onFilterChanged}
            columnsToFit={false}
            resetFilter={resetFilter}
            uniqueGridId={'rcManagementOthers'}
          />
          <CustomPagination
            page={page}
            totalRecords={totalRecords}
            handlePageChange={handlePageChange}
            recordsOnPage={recordsOnPage}
            totalPages={totalPages}
          />
        </div>
      )}
    </LoaderWithAccessControl>
  );
};

export default withRouter(RcManagementOthersGrid);
