import { taskManagementService } from '../../../services';

export const fetchTaskManagementGridInfo = (params) => (dispatch) => {
  return taskManagementService
    .fetchTaskManagementGridInfo(params)
    .then((resp) => {
      if (resp.data && resp.data.data) {
        return resp.data.data;
      } else {
        return {
          page: 0,
          totalPages: 0,
          totalRecords: 0,
          response: []
        };
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const updateAssignTo = (params, body) => () => {
  return taskManagementService
    .updateAssignTo(params, body)
};

export const fetchAgents = (params) => () => {
  return taskManagementService
    .fetchAgents(params);
};
