import { config } from './../config/local';
import { IS_ENV_DEV, IS_MOCK } from '../../shared/utils/constants';
import { buildQuery } from '../../services/helper';

import {
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected,
  handleHttpStatusError
} from '../../services/helper';

export default (api) => {
  const fetchFinanceGridInfo = (params = { page: 0, size: 10 }) => {
    const path = config.api.c2c.financeManagement.path;
    const queryStr = params ? `?${buildQuery(params)}` : '';
    const url = path + queryStr;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };
  const fetchFinanceDealInfoById = (dealId) => {
    const path = config.api.c2c.financeManagementDealInfo.path;
    const url = `${path}/${dealId}`;
    return api.get(url);
  };

  const fetchPaymentInfo = (user_type, deal_id) => {
    const path = config.api.c2c.paymentInfo.path;
    const url = `${path}?user_type=${user_type}&deal_id=${deal_id}`;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };

  const fetchServiceCharge = (deal_id) => {
    const path = config.api.c2c.serviceCharges.path;
    const url = `${path}/${deal_id}?source=Konnect`;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };

  const fetchFieldServiceCharge = (dealId, userType, serviceType) => {
    const url = `${config.api.c2c.serviceCharges.path}/${dealId}/${userType}/${serviceType}`;
    return api.get(url);
  };

  const submitServiceCharges = (payload) => {
    const url = config.api.c2c.serviceChargesSave.path;
    return api.put(url, payload);
  };

  const uploadUtrDocument = (fileObject) => {
    const url = config.api.c2c.uploadUtr.path;
    return api.post(url, fileObject);
  };

  const fetchTransactionLogs = (user_type, deal_id) => {
    const path = config.api.c2c.trxLogs.path;
    const url = `${path}?user_type=${user_type}&deal_id=${deal_id}`;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };
  const fetchPaymentMode = () => {
    const path = config.api.c2c.paymentMode.path;
    const url = `${path}`;
    return api.get(url);
  };

  const submitPaymentProof = (data) => {
    const path = config.api.c2c.transaction.path;
    const url = `${path}`;
    return api.put(url, data);
  };
  const addMorePaymentInfo = (data) => {
    const path = config.api.c2c.paymentProof.path;
    const url = `${path}`;
    return api.post(url, data);
  };

  const discardPaymentInfo = (id, payload) => {
    const path = config.api.c2c.discardPaymentProof.path;
    const url = `${path}/${id}`;
    return api.put(url, payload);
  };
  const refundPayment = (data) => {
    const path = config.api.c2c.refund.path;
    const url = `${path}`;
    return api.post(url, data);
  };
  const switchProof = (data) => {
    const path = config.api.c2c.proofSwitch.path;
    const url = `${path}`;
    return api.post(url, data);
  };
  const refundCancelled = (data) => {
    const path = config.api.c2c.cancelRefund.path;
    const url = `${path}`;
    return api.post(url, data);
  };

  const fetchAllServiceCharges = () => {
    const path = config.api.c2c.allServiceCharges.path;
    const url = `${path}`;
    return api.get(url);
  };

  const submitServiceChargeSlab = (data) => {
    const path = config.api.c2c.submitServiceChargeSlab.path;
    const url = `${path}`;
    return api.put(url, data);
  };

  const fetchDealRefundsCharges = (dealId, withLoader=true) => {
    withLoader && dispatchSetLoaderConnected();
    return new Promise((resolve, reject) => {
      api.get(`refund/v2/${dealId}`)
          .then(response => {
              dispatchResetLoaderConnected();
              resolve(response.data);
          })
          .catch(error => {
              handleHttpStatusError(error);
              reject(error);
          }).finally(()=>{
            dispatchResetLoaderConnected();
          });
    });
  };

  const fetchRefundLogs = (dealId, withLoader=true) => {
    withLoader && dispatchSetLoaderConnected();
    return new Promise((resolve, reject) => {
      api.get(`refund/${dealId}/payout/logs`)
          .then(response => {
              dispatchResetLoaderConnected();
              resolve(response.data);
          })
          .catch(error => {
              handleHttpStatusError(error);
              reject(error);
          }).finally(()=>{
            dispatchResetLoaderConnected();
          });
    });
  };

  const fetchRefundPayouts = (params, withLoader=true) => {
    withLoader && dispatchSetLoaderConnected();
    const queryStr = params ? `?${buildQuery(params)}` : '';
    return new Promise((resolve, reject) => {
      api.get(`refund` + queryStr)
          .then(response => {
              dispatchResetLoaderConnected();
              resolve(response.data);
          })
          .catch(error => {
              handleHttpStatusError(error);
              reject(error);
          }).finally(()=>{
            dispatchResetLoaderConnected();
          });
    });
  };

  const initiatePostDeliveryRefunds = (dealId, payload, withLoader=true) => {
    withLoader && dispatchSetLoaderConnected();
    return new Promise((resolve, reject) => {
      api.post(`refund/${dealId}/post-delivery`, payload)
          .then(response => {
              dispatchResetLoaderConnected();
              resolve(response);
          })
          .catch(error => {
              handleHttpStatusError(error);
              reject(error);
          }).finally(()=>{
            dispatchResetLoaderConnected();
          });
    });
  };

  const initiateBuyback = (dealId, payload, withLoader=true) => {
    withLoader && dispatchSetLoaderConnected();
    return new Promise((resolve, reject) => {
      api.post(`refund/${dealId}/buyback`, payload)
          .then(response => {
              dispatchResetLoaderConnected();
              resolve(response);
          })
          .catch(error => {
              handleHttpStatusError(error);
              reject(error);
          }).finally(()=>{
            dispatchResetLoaderConnected();
          });
    });
  };

  const initiateRefundPayout = (dealId, refundId, amount, withLoader=true) => {
    withLoader && dispatchSetLoaderConnected();
    return new Promise((resolve, reject) => {
      api.post(`refund/${dealId}/refund/${refundId}?amount=${amount}`, {})
          .then(response => {
              dispatchResetLoaderConnected();
              resolve(response);
          })
          .catch(error => {
              handleHttpStatusError(error);
              reject(error);
          }).finally(()=>{
            dispatchResetLoaderConnected();
          });
    });
  };

  const takeActionOnRefundPayout = (dealId, payoutId, action, withLoader=true) => {
    withLoader && dispatchSetLoaderConnected();
    return new Promise((resolve, reject) => {
      api.post(`refund/${dealId}/payout/${payoutId}/action/${action}`, {})
          .then(response => {
              dispatchResetLoaderConnected();
              resolve(response);
          })
          .catch(error => {
              handleHttpStatusError(error);
              reject(error);
          }).finally(()=>{
            dispatchResetLoaderConnected();
          });
    });
  };

  const getUiRulesForDeal = (dealId) => {
    return new Promise((resolve, reject) => {
      api.get(`ui-rules/` + dealId)
          .then(response => {
              dispatchResetLoaderConnected();
              resolve(response.data);
          })
          .catch(error => {
              handleHttpStatusError(error);
              reject(error);
          }).finally(()=>{
            dispatchResetLoaderConnected();
          });
    });
  };

  const onRefreshFinanceData = (Id) => {
    return new Promise((resolve, reject) => {
      api.post(`/delivery-card/cf-sync?dealId=${Id}`)
          .then(response => {
              resolve(response.data);
          })
          .catch(error => {
              reject(error);
          });
    });
  };

  const fetchReturnFeeInvoiceDetails = (dealId, withLoader=true) => {
    withLoader && dispatchSetLoaderConnected();
    return new Promise((resolve, reject) => {
      api.get(`fc/return/invoice/${dealId}`)
          .then(response => {
              dispatchResetLoaderConnected();
              resolve(response.data.data);
          })
          .catch(error => {
              handleHttpStatusError(error);
              reject(error);
          }).finally(()=>{
            dispatchResetLoaderConnected();
          });
    });
  };

  const fetchPostDeliveryData = (dealId, withLoader=true) => {
    withLoader && dispatchSetLoaderConnected();
    return new Promise((resolve, reject) => {
      api.get(`${config.api.c2c.postDeliverySales.path}/${dealId}?source=Konnect`)
          .then(response => {
              dispatchResetLoaderConnected();
              resolve(response.data.data);
          })
          .catch(error => {
              handleHttpStatusError(error);
              reject(error);
          }).finally(()=>{
            dispatchResetLoaderConnected();
          });
    });
  };

  return {
    onRefreshFinanceData,
    fetchDealRefundsCharges,
    fetchFinanceGridInfo,
    fetchPaymentInfo,
    uploadUtrDocument,
    fetchTransactionLogs,
    fetchFinanceDealInfoById,
    fetchPaymentMode,
    submitPaymentProof,
    addMorePaymentInfo,
    discardPaymentInfo,
    fetchServiceCharge,
    submitServiceCharges,
    refundPayment,
    switchProof,
    refundCancelled,
    fetchAllServiceCharges,
    submitServiceChargeSlab,
    fetchRefundLogs,
    fetchRefundPayouts,
    initiatePostDeliveryRefunds,
    initiateBuyback,
    initiateRefundPayout,
    takeActionOnRefundPayout,
    getUiRulesForDeal,
    fetchFieldServiceCharge,
    fetchReturnFeeInvoiceDetails,
    fetchPostDeliveryData,
  };
};
