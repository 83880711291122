import React, { useEffect } from 'react'
import CaseTypesConfigAdd from '../case-type-config-add';
import CaseTypesConfigList from '../case-type-config-list';

const CaseTypesConfig = ({
  isAttributesFetched,
  fetchAttributesDataConnect
}) => {
  useEffect(() => {
    if(!isAttributesFetched) {
      fetchAttributesDataConnect();
    }
  }, []);

  return (
    <>
      <CaseTypesConfigAdd />
      <CaseTypesConfigList />
    </>
  )
}

export default CaseTypesConfig;