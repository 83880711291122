import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import './style.css';
import InputText from '../../../shared/components/input-text';
import LoaderWithAccessControl from '../../../shared/components/loader-with-access-control';
import { CAR_SOURCE } from '../../../shared/utils/constants';
import { FIELDS } from './constants';
import CustomButton from '../../../shared/components/button';
import CTextArea from '../../../shared/components/custom-textarea';
import Locale from '../../../shared/utils/locale';
import PricingLogs from '../order-seller-pricing-logs';
const OrderSeller = function (props) {
  const [appointmentId] = useState(props.match.params.id);
  const [sellerData, setSellerData] = useState(props.sellerData);
  const [fields, setFields] = useState([...FIELDS]);

  useEffect(() => {
    props.fetchSellerDetailConnected(appointmentId);
  }, []);

  useEffect(() => {
    if (props.sellerData) {
      setSellerData(props.sellerData);
      const isReadOnly = [
        CAR_SOURCE.C2C_DEALER_CAR,
        CAR_SOURCE.GS_CAR
      ].includes(props.sellerData.carSource);

      const updatedFields = fields.map((item) => {
        if (item.readOnlyConditional) {
          return { ...item, readOnly: isReadOnly };
        } else {
          return item;
        }
      });
      setFields(updatedFields);
    }
  }, [props.sellerData]);

  const handleSellerInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setSellerData({ ...sellerData, [name]: value });
  };

  const updateCarSource = (e) => {
    e.preventDefault();
    const data = {
      inventoryInfo: {
        appointmentId,
        carSource: CAR_SOURCE.GS_CAR
      }
    };
    props.updateCarSourceConnected(data);
  };

  /* Form submit handler */
  const handleSubmit = (e) => {
    e.preventDefault();
    props.updateSellerConnected(sellerData);
  };
  return (
    <LoaderWithAccessControl>
      {sellerData &&
      (sellerData.carSource === CAR_SOURCE.CUSTOMER_CARE ||
        sellerData.carSource === CAR_SOURCE.MANUAL_UPLOAD) ? (
        <div className='float-left form-group col-md-2 pull-right'>
          <CustomButton
            label={Locale.CONVERT_GS_CAR}
            onClick={updateCarSource}
            className='btn-warning'
          />
        </div>
      ) : null}
      {sellerData && (
        <form autoComplete='off' onSubmit={handleSubmit}>
          <div className='clearfix'></div>
          <div className='row'>
            {Array.isArray(fields) &&
              fields.map((fieldItem) => {
                const {
                  autoComplete,
                  label,
                  fieldName,
                  type,
                  valueKey,
                  readOnly
                } = fieldItem;
                switch (type) {
                  case 'number':
                  case 'text':
                    return (
                      <div
                        key={`${fieldName}_seller`}
                        className='float-left form-group col-md-3'
                      >
                        <label className='font-weight-bold'>{label}</label>
                        <InputText
                          name={fieldName}
                          type={type}
                          className='form-control'
                          autoComplete={autoComplete}
                          value={sellerData[valueKey] || ''}
                          onChange={handleSellerInputChange}
                          readOnly={readOnly}
                        />
                      </div>
                    );
                  case 'textarea':
                    return (
                      <div
                        key={`${fieldName}_seller`}
                        className='float-left form-group col-md-3'
                      >
                        <label className='font-weight-bold'>{label}</label>
                        <CTextArea
                          name={fieldName}
                          autoComplete={autoComplete}
                          className='form-control'
                          value={sellerData[valueKey] || ''}
                          onChange={handleSellerInputChange}
                          readOnly={readOnly}
                        />
                      </div>
                    );
                  default:
                    return (
                      <div
                        key={`${fieldName}_seller`}
                        className='float-left form-group col-md-3 '
                      >
                        <label className='font-weight-bold'>{label}</label>
                        <InputText
                          name={fieldName}
                          type={type}
                          className='form-control'
                          autoComplete={autoComplete}
                          value={sellerData[valueKey] || ''}
                          onChange={handleSellerInputChange}
                          readOnly={readOnly}
                        />
                      </div>
                    );
                }
              })}
            <div className='float-left form-group col-md-12 col-sm-12'>
              <CustomButton label={Locale.SAVE} type='submit' />
            </div>
            {/* pricing log */}
            <div className='wrapperAgentDetails mt-20 p15'>
              <PricingLogs
                appointmentId={props.match.params.id}
              />
            </div>
          </div>
        </form>
      )}
    </LoaderWithAccessControl>
  );
};

export default withRouter(OrderSeller);
