import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  detail: {},
  isLoading: false
};

//Set state for store fetch init
export const fetchStoreDetailInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

//Set state for store fetch success
export const fetchStoreDetailSuccess = (
  state = INITIAL_STATE,
  { data: detail }
) => {
  return {
    ...state,
    detail,
    isLoading: false
  };
};

export const fetchStoreDetailFail = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

export const addStoreInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const addStoreSuccess = (state = INITIAL_STATE, { data: detail }) => {
  return {
    ...state,
    detail,
    isLoading: false
  };
};
export const removeStoreData = (state = INITIAL_STATE) => {
  return { ...state, detail: {} };
};
export const addStoreFail = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

export const HANDLERS = {
  [Types.FETCH_STORE_INIT]: fetchStoreDetailInit,
  [Types.FETCH_STORE_SUCCESS]: fetchStoreDetailSuccess,
  [Types.FETCH_STORE_FAIL]: fetchStoreDetailFail,
  [Types.ADD_STORE_INIT]: addStoreInit,
  [Types.ADD_STORE_SUCCESS]: addStoreSuccess,
  [Types.ADD_STORE_FAIL]: addStoreFail,
  [Types.REMOVE_STORE_DATA]: removeStoreData
};

export default createReducer(INITIAL_STATE, HANDLERS);
