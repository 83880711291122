import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FinanceDealInfo from './component';
import { fetchFinanceDealInfoByIdConnected } from './actions';

const mapStateToProps = ({ financeDealInfo: { dealInfo } }) => {
  return {
    dealInfo
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchFinanceDealInfoByIdConnected
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FinanceDealInfo);
