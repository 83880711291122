import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  UPDATE_ORDER_STATE_INIT
  UPDATE_ORDER_STATE_SUCCESS
  UPDATE_ORDER_STATE_FAIL
`,
  {
    prefix: 'order-publish-tab/'
  }
);
