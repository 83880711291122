import { useState } from "react";
import { financeService } from "../../services";

export const usePostDeliverySales = () => {
  const [data, setData] = useState([]);

  const fetchPostDeliveryData = (dealId) => {
    financeService.fetchPostDeliveryData(dealId)
      .then((data) => {
        setData(data);
      })
  };
       
  return { data, fetchPostDeliveryData };
}