import Types from './types';
import { dealerService } from '../../../services';
import { showToastMessages } from '../../../shared/utils/helper';
import {
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected,
  handleHttpStatusError
} from '../../../services/helper';

//init dealerApi
const fetchDealerDetailInit = () => {
  return {
    type: Types.FETCH_DEALER_INIT
  };
};

//dealerDetails fetch success
const fetchDealerDetailSuccess = (data) => {
  return {
    type: Types.FETCH_DEALER_SUCCESS,
    data: data
  };
};

//dealerDetails fetch error
const fetchDealerDetailFail = (error) => {
  return {
    type: Types.FETCH_DEALER_FAIL,
    error: error
  };
};
const removeDealerDetailConnected = () => {
  return {
    type: Types.REMOVE_DEALER_DATA
  };
};

//fetch dealerDetaisl
const fetchDealerDetailConnected = (dealerId) => (dispatch) => {
  dispatchSetLoaderConnected();
  dispatch(fetchDealerDetailInit());
  return new Promise((resolve, reject) => {
    dealerService
      .getDealerDetailById(dealerId)
      .then((resp) => {
        resolve(resp);
        dispatch(fetchDealerDetailSuccess(resp.data.data));
        dispatchResetLoaderConnected();
      })
      .catch((error) => {
        dispatch(fetchDealerDetailFail(error));
        handleHttpStatusError(error);
        reject(error);
      });
  });
};

//init add dealer
const addDealerInit = () => {
  return {
    type: Types.ADD_DEALER_INIT
  };
};

//addDealer success
const addDealerSuccess = (data) => {
  return {
    type: Types.ADD_DEALER_SUCCESS,
    data: data
  };
};

//addDealer error
const addDealerFail = (error) => {
  return {
    type: Types.ADD_DEALER_FAIL,
    error: error
  };
};

const addDealerConnected = (data) => (dispatch) => {
  dispatch(addDealerInit());
  return dealerService
    .addDealer(data)
    .then((resp) => {
      dispatch(addDealerSuccess(resp.data.data));
      showToastMessages(resp.data.message);
      return resp.data;
    })
    .catch((error) => {
      //handleHttpStatusError(error.errors);
      dispatch(addDealerFail(error));
    });
};

const fetchDealerFormInit = () => {
  return {
    type: Types.FETCH_DEALER_FORM_INIT
  };
};

const fetchDealerFormSuccess = (data) => {
  return {
    type: Types.FETCH_DEALER_FORM_SUCCESS,
    data: data
  };
};

const fetchDealerFormFail = (error) => {
  return {
    type: Types.FETCH_DEALER_FORM_FAIL,
    error: error
  };
};

const fetchDealerFormConnected = (data) => (dispatch) => {
  dispatchSetLoaderConnected();
  dispatch(fetchDealerFormInit());
  return dealerService
    .getDealerFormData(data)
    .then((resp) => {
      dispatchResetLoaderConnected();
      dispatch(fetchDealerFormSuccess(resp.data.data));
      return resp.data;
    })
    .catch((error) => {
      handleHttpStatusError(error);
      dispatch(fetchDealerFormFail(error));
    });
};

export {
  fetchDealerDetailConnected,
  addDealerConnected,
  fetchDealerFormConnected,
  removeDealerDetailConnected
};
