import React from 'react';
import withAdminLayout from '../../../shared/hoc/admin-layout';
import ErrorBoundary from '../../components/common/errorBoundary';
import { withAuthorization } from '../../../shared/utils/firebase';
import HpGrid from '../../components/hp-Management-grid'

const HpManagement = () => {
  return (
    <div>
      <ErrorBoundary>
        <HpGrid />
      </ErrorBoundary>
    </div>
  );
};
export default withAdminLayout(withAuthorization(() => true)(HpManagement));
