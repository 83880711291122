import React from 'react';
import { Redirect } from 'react-router-dom';
import ErrorImg from '../../../images/error.svg';
import { getIsLoggedInFromLocalStorage, removeDataFromLocalStorage } from '../../../../shared/utils/helper';

const ErrorPage = () => {
  const isLoggedIn = getIsLoggedInFromLocalStorage();

  if (isLoggedIn) {
    return (
      <div className="container text-center errorpage">
        <img src={ErrorImg} alt="Error!"></img>
        <h2>Some Error Occurred!</h2>
      </div>
    );
  } else {
    removeDataFromLocalStorage();
    return <Redirect to='/login' />
  }
}

export default ErrorPage;