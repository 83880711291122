const parseByIdV2 = (data = [], key) => {
  const byId = {};
  const allIds = [];

  data.forEach(item => {
    allIds.push(item[key]);
    byId[item[key]] = item;
  });

  return { byId, allIds };
};

export default parseByIdV2;
