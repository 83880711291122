import Types from './types';
import { caseTypeRuleEngineService } from '../../../services';
import { parseById } from '../../helper/parsers';

export const fetchAttributesData = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    caseTypeRuleEngineService
      .fetchAttributesData(params)
      .then((data) => {
        dispatch(fetchAttributesDataSuccess(data));
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addCaseTypeConfig = (params, body) => (dispatch) => {
  return new Promise((resolve, reject) => {
    caseTypeRuleEngineService
      .addCaseTypeConfig(params, body)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      })
  });
};

export const fetchCaseTypeConfig = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    caseTypeRuleEngineService
      .fetchCaseTypeConfig(params)
      .then((resp) => {
        dispatch(fetchCaseTypeConfigSuccess(parseById(resp.data)));
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      })
  });
};

export const updateCaseTypeConfig = (params, body) => (dispatch) => {
  return new Promise((resolve, reject) => {
    caseTypeRuleEngineService
      .updateCaseTypeConfig(params, body)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      })
  });
};

export const deleteCaseTypeConfig = (id) => () => {
  return new Promise((resolve, reject) => {
    caseTypeRuleEngineService
      .deleteCaseTypeConfig(id)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      })
  });
};

const fetchAttributesDataSuccess = (data) => ({
  type: Types.FETCH_ATTRIBUTES_DATA_SUCCESS,
  data
});

export const updateAddConfigData = (data) => ({
  type: Types.UPDATE_ADD_CONFIG_DATA,
  data
})

const fetchCaseTypeConfigSuccess = (data) => ({
  type: Types.FETCH_CASE_TYPE_CONFIG_SUCCESS,
  data
});

export const updateCaseTypeData = (data) => ({
  type: Types.UPDATE_CASE_TYPE,
  data
});

export const resetConfigList = () => ({
  type: Types.RESET_CONFIG_LIST
});
