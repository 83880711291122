import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { constants } from '../../../constants/constants';
import { SelectComponent } from '../../../../client/components/shared';
import store from '../../../../store';

class MultiSelectFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentValue: '',
      selected: []
    };
  }
  //Filter space and underscore from given string
  filterValue = (str) => {
    return str.replace(/[_-]/g, ' ');
  };
  //Convert string to title case
  titleCase = (str) => {
    if (str && str.length > 0) {
      let filteredValue = this.filterValue(str);
      const titleCase = filteredValue
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
      return titleCase;
    }
    return '';
  };

  //Auto Called on parent model changed
  onParentModelChanged(parentModel) {
    if (parentModel) {
      this.setState({
        currentValue: !parentModel ? '' : parentModel.filter
      });
    }
  }

  invokeParentMethod = (data) => {
    const parentContext = this.props.agGridReact.props.context;
    return parentContext.onFilterChanged(data);
  };

  setFilter = (filterData, filterRaw) => {
    const {
      name: filterName,
      agGridReact: {
        props: {
          context: { uniqueGridId, setGridFilterConnected }
        }
      }
    } = this.props;
    const model = {
      [filterName]: { filterType: 'text', type: 'equals', filter: '' }
    };
    const dataSet = {
      uniqueGridId,
      filterName,
      filterData,
      filterRaw,
      model
    };
    setGridFilterConnected(dataSet);
  };
  componentDidMount() {
    const selected = this.getFilterFromProps(this.props);
    this.setState({ selected });
  }

  getFilterFromProps = (cProps) => {
    const {
      gridInfo,
      name: filterName,
      agGridReact: {
        props: {
          context: { uniqueGridId }
        }
      }
    } = cProps;
    // const parentContext = cProps.agGridReact.props.context;
    // const { uniqueGridId, gridInfo } = parentContext;
    // const { name: filterName } = cProps;
    const selected = gridInfo[uniqueGridId]['raw']
      ? gridInfo[uniqueGridId]['raw'][filterName] || []
      : [];
    return selected;
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      gridInfo,
      name: filterName,
      agGridReact: {
        props: {
          context: { uniqueGridId }
        }
      }
    } = nextProps;

    const nextSelected = gridInfo[uniqueGridId]['raw']
      ? gridInfo[uniqueGridId]['raw'][filterName] || []
      : [];

    if (nextSelected) {
      return { selected: nextSelected };
    } else return null;
  }
  // Handle value change
  valueChanged = (event) => {
    const {
      name: filterName,
      agGridReact: {
        props: {
          context: { uniqueGridId }
        }
      }
    } = this.props;
    const valueToUse = event ? event.map((item) => item.key) : '';
    const query = { [filterName]: valueToUse };
    const queryRaw = { [filterName]: event };

    this.setFilter(query, queryRaw);
    const newFilters = store.getState().gridInfo[uniqueGridId]['filter'];

    this.invokeParentMethod(newFilters);
  };

  render() {
    const { options } = this.props;

    const optionsList = options.map((item) => {
      item['label'] = item.value;
      return item;
    });

    return (
      <SelectComponent
        key={this.state.selected.length}
        id='test_select'
        name='test_select'
        optionsList={optionsList}
        value={this.state.selected}
        defaultValue={this.state.selected}
        onChange={this.valueChanged}
        // isDisabled={!!trackerData['base_agent_id']}
        classNames='gridSelectFilter'
        isMulti={true}
        hideSelectedOptions={false}
        showCustomContainer={true}
        isClearable={false}
        version='v2'
        //style={customStyle}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { gridInfo } = state;
  return { gridInfo };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(MultiSelectFilter);
