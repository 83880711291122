import React, { useState, useEffect } from 'react';
import useModal from 'react-hooks-use-modal';
import { useFormik } from 'formik';
import InputText from '../../../shared/components/input-text';
import { FIELDS, getInitValues, DISCARD_REASONS } from './constants';
import { SelectComponent } from '../../../client/components/shared';
import { Wrapper } from '../form-element';
import CustomButton from '../../../shared/components/button';
import { validationSchema } from './validations';
import CTextArea from '../../../shared/components/custom-textarea';
import styles from './style.module.css';
const FinanceDiscardProofModal = ({
  onDiscard,
  activeImageId,
  imageData: { is_discarded: is_discard, id, reason = '' }
}) => {
  const [Modal, open, close] = useModal();
  const [options, setOptions] = useState({});
  const [formError, setFormError] = useState(null);

  const formikDiscard = useFormik({
    initialValues: getInitValues(),
    validationSchema: !is_discard ? validationSchema : null,
    onSubmit: () => {
      handleSubmit();
    }
  });

  useEffect(() => {
    setOptions({
      ...options,
      [FIELDS.reason.keyName]: Object.values(DISCARD_REASONS)
    });
    // const defaultReason = reason ? { label: reason, value: reason } : null;
    formikDiscard.setFieldValue('reason', null);
    formikDiscard.resetForm();
  }, [activeImageId, is_discard]);

  const handleSubmit = () => {
    const payload = {
      reason: formikDiscard.values.reason.value || null,
      remarks: formikDiscard.values.remarks || '',
      is_discard: !is_discard
    };
    onDiscard(id, activeImageId, payload);
    close();
  };
  const onChangeSelect = (selected, param) => {
    if (selected) {
      formikDiscard.setFieldValue(param, selected, true);
    } else {
      formikDiscard.setFieldValue(param, {}, true);
    }
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    formikDiscard.setFieldValue(name, value, true);
  };
  const onBlurCallback = (e, keyName) => {
    e.preventDefault();
    const { name } = e.target;
    formikDiscard.setFieldTouched(name, true, true);
  };

  const onOpen = (e) => {
    e.preventDefault();
    open();
  };

  return (
    <>
      {is_discard ? (
        <button
          onClick={formikDiscard.handleSubmit}
          className='btn btn-primary'
        >
          Validate
        </button>
      ) : (
        <button onClick={onOpen} className='btn btn-primary'>
          Discard
        </button>
      )}

      <Modal>
        <div
          className={`Filter_popUP ${styles.discardProof} col-md-12`}
          style={{ width: '35vw' }}
        >
          <h4> {is_discard ? 'Validate Proof' : 'Discard Proof'}</h4>
          <div className='d-flex'>
            <form>
              {Object.values(FIELDS).map((fieldItem) => {
                const {
                  subType,
                  label,
                  keyName,
                  required,
                  elementType,
                  isMulti,
                  maxLength,
                  ...restProps
                } = fieldItem;

                switch (elementType) {
                  case 'input':
                    return (
                      <Wrapper
                        key={keyName}
                        item={fieldItem}
                        containerClass='row justify-content-between col-12 mb20'
                      >
                        <InputText
                          id={keyName}
                          name={keyName}
                          type={subType}
                          onChange={onChangeInput}
                          value={formikDiscard.values[keyName] || ''}
                          onBlurCallback={(e, name) =>
                            onBlurCallback(e, keyName)
                          }
                          isError={
                            formikDiscard.touched[keyName] &&
                            formikDiscard.errors[keyName]
                              ? true
                              : false
                          }
                          errorMessage={
                            formikDiscard.touched[keyName] &&
                            formikDiscard.errors[keyName]
                              ? formikDiscard.errors[keyName]
                              : null
                          }
                          version='v2'
                          {...restProps}
                        />
                      </Wrapper>
                    );

                  case 'select':
                    return (
                      <Wrapper
                        key={keyName}
                        item={fieldItem}
                        containerClass='row justify-content-between mb20'
                      >
                        <SelectComponent
                          key={formikDiscard.values[keyName] || keyName}
                          id={keyName}
                          name={keyName}
                          optionsList={options[keyName]}
                          placeholder={label}
                          defaultValue={formikDiscard.values[keyName] || null}
                          value={formikDiscard.values[keyName] || null}
                          onChange={onChangeSelect}
                          className='form-control'
                          isMulti={isMulti || false}
                          version='v2'
                          errorMessage={
                            formikDiscard.touched[keyName] &&
                            formikDiscard.errors[keyName]
                              ? formikDiscard.errors[keyName]
                              : null
                          }
                          isDisabled={is_discard}
                        />
                      </Wrapper>
                    );
                  case 'textarea':
                    return (
                      <Wrapper
                        key={keyName}
                        item={fieldItem}
                        containerClass='row justify-content-between'
                      >
                        <CTextArea
                          name={keyName}
                          autoComplete={false}
                          className='form-control'
                          value={formikDiscard.values[keyName] || ''}
                          onChange={onChangeInput}
                          disabled={is_discard}
                          readOnly={is_discard}
                        />
                      </Wrapper>
                    );

                  default:
                    return null;
                }
              })}
              {formError && (
                <div className={styles.proofError}>{formError}</div>
              )}
              <CustomButton
                type='button'
                label={'Save'}
                onClick={formikDiscard.handleSubmit}
              />
              <CustomButton
                type='button'
                label={'Cancel'}
                className={styles.ml8}
                onClick={() => close()}
              />
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FinanceDiscardProofModal;
