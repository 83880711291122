export const TAB_LIST = [
  {
    label: 'Deal Information',
    visible: true, // default value, this can change as module logic
    disabled: false,
    key: 'dealInfo',
    id: 0
  },
  {
    label: 'Service Charge',
    visible: true,
    disabled: false,
    key: 'serviceCharge',
    id: 1
  },
  {
    label: 'Buyer Payment',
    visible: true,
    disabled: false,
    key: 'buyerPayment',
    withStatus: true,
    id: 2
  },
  {
    label: 'Seller Payment',
    visible: true,
    disabled: false,
    key: 'sellerPayment',
    withStatus: true,
    id: 3
  }, 
  {
    label: 'Refunds',
    visible: true,
    disabled: false,
    key: 'refunds',
    id: 4
  },
  {
    label: 'Post Delivery Sales',
    visible: true,
    disabled: false,
    key: 'postDeliverySales',
    id: 5
  }
];
