import { config } from '../config/local';
import { IS_ENV_DEV, IS_MOCK } from '../../shared/utils/constants';
import { buildQuery } from '../../services/helper';

export default (api) => {
  const fetchHpManagementGridInfo = (query = { page: 0, size: 10 }, params={}) => {
    const path = config.api.c2c.hpManagement.path;
    const queryStr = query ? `?${buildQuery(query)}` : '';
    const url = path + queryStr;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return new Promise((resolve, reject) => {
        api.post(url, params).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      });
    }
  };
  const fetchHypothecation = (id) => {
    const path = `${config.api.c2c.hypothecation.path}/${id}`;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: path, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return new Promise((resolve, reject) => {
        api.get(path).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      });
    }
  };
  const postHypothecation = (params={}) => {
    const path = config.api.c2c.hypothecation.path;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: path, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return new Promise((resolve, reject) => {
        api.post(path, params).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      });
    }
  };
  const updateRTOAction = (id, action) => {
    return new Promise((resolve, reject) => {
      api.put(`${config.api.c2c.hypothecation.path}/${id}/${action}`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      })
    });
  };

  return {
    fetchHpManagementGridInfo,
    fetchHypothecation,
    postHypothecation,
    updateRTOAction
  };
};
