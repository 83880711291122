import React from "react";
import ZonesDetailConfig from "../../components/zones-detail-config";
import withAdminLayout from "../../../shared/hoc/admin-layout";
import ErrorBoundary from "../../components/common/errorBoundary"
import { withAuthorization } from "../../../shared/utils/firebase";
const ZonesDetails = (props) => {
    return (
        <div>
            <ErrorBoundary>
                <ZonesDetailConfig {...props} />
            </ErrorBoundary>
        </div>
    );
};
// Add custom for authorisation here defaults to true
const condition = (props) => {
    return true
}

export default withAdminLayout(withAuthorization(condition)(ZonesDetails));
