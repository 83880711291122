import React from 'react';

const GridRecordView = ({ recordsOnPage, totalRecords, page, totalPages }) => {
  return (
    <div className='text-right'>
      <span className='ml8'>
        showing {recordsOnPage} of {totalRecords || 0} &nbsp;
      </span>
      |
      <span className='msl8'>
        &nbsp;&nbsp;Page {page} of {totalPages || 1}
      </span>
    </div>
  );
};

export default GridRecordView;
