import React from "react";
import RtoDetail from "../../components/rto-details";
import withAdminLayout from "../../../shared/hoc/admin-layout";
import ErrorBoundary from "../../components/common/errorBoundary"
import { withAuthorization } from "../../../shared/utils/firebase";
const RtoDetails = (props) => {
    return (
        <div>
            <ErrorBoundary>
                <RtoDetail {...props}/>
            </ErrorBoundary>
        </div>
    );
};
export default withAdminLayout(withAuthorization(()=>true)(RtoDetails));
