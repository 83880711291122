import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  docketData: {
    diyDocumentList: {
      docketDocsUpdated: [],
      byId: {},
      allIds: []
    },
    adminDocumentList: {
      docketDocsUpdated: [],
      byId: {},
      allIds: []
    },
  }
};

const fetchDocketManagementFormDataSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    docketData: {
      ...state.docketData,
      ...data,
    }
  };
};

const fetchDocketManagementDocsSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    docketData: {
      ...state.docketData,
      diyDocumentList: {
        ...state.docketData.diyDocumentList,
        docketDocsUpdated: [],
        byId: data.diyDocumentList.byId,
        allIds: data.diyDocumentList.allIds
      },
      adminDocumentList: {
        ...state.docketData.adminDocumentList,
        docketDocsUpdated: [],
        byId: data.adminDocumentList.byId,
        allIds: data.adminDocumentList.allIds
      }
    }
  };
};

const updateDocketManagementDiyDocData = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    docketData: {
      ...state.docketData,
      diyDocumentList: {
        ...state.docketData.diyDocumentList,
        byId: {
          ...state.docketData.diyDocumentList.byId,
          [data.id]: {
            ...state.docketData.diyDocumentList.byId[data.id],
            [data.key]: data.value
          }
        },
        docketDocsUpdated: [
          ...state.docketData.diyDocumentList.docketDocsUpdated,
          ...(state.docketData.diyDocumentList.docketDocsUpdated.indexOf(data.id) === -1 ? [data.id]: [])
        ]
      },
    }
  };
};

const updateDocketManagementAdminDocData = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    docketData: {
      ...state.docketData,
      adminDocumentList: {
        ...state.docketData.adminDocumentList,
        byId: {
          ...state.docketData.adminDocumentList.byId,
          [data.id]: {
            ...state.docketData.adminDocumentList.byId[data.id],
            [data.key]: data.value
          }
        },
        docketDocsUpdated: [
          ...state.docketData.adminDocumentList.docketDocsUpdated,
          ...(state.docketData.adminDocumentList.docketDocsUpdated.indexOf(data.id) === -1 ? [data.id]: [])
        ]
      },
    }
  };
};

const updateDocketManagementFieldData = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    docketData: {
      ...state.docketData,
      [data.key]: data.value,
    }
  };
};

const resetDocketManagementData = (state = INITIAL_STATE) => {
  return {
    ...state,
    docketData: INITIAL_STATE.docketData,
  };
};

export const HANDLERS = {
  [Types.FETCH_DOCKET_FORM_DATA_SUCCESS]: fetchDocketManagementFormDataSuccess,
  [Types.FETCH_DOCKET_DOCS_SUCCESS]: fetchDocketManagementDocsSuccess,
  [Types.RESET_DOCKET_DATA]: resetDocketManagementData,
  [Types.UPDATE_DOCKET_DIY_DOC_DATA]: updateDocketManagementDiyDocData,
  [Types.UPDATE_DOCKET_ADMIN_DOC_DATA]: updateDocketManagementAdminDocData,
  [Types.UPDATE_DOCKET_FIELD_DATA]: updateDocketManagementFieldData,
};

export default createReducer(INITIAL_STATE, HANDLERS);
