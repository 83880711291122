import { useEffect, useState } from 'react';
import { documentRuleEngineService } from '../../services';

export const useRegions = () => {
  const fetchRegionList = documentRuleEngineService.fetchRegionList;
  const [regions, setRegions] = useState([]);
  const [isRegionsFetched, setIsRegionsFetched] = useState(false);
  
  useEffect(() => {
    fetchRegionList()
      .then((data) => {
        setRegions(data);
        setIsRegionsFetched(true);
      })
  }, []);

  return { regions, isRegionsFetched };
}