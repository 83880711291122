import React, { useEffect } from 'react'
import DocumentsConfigAdd from '../documents-config-add';
import DocumentsConfigList from '../documents-config-list';

const DocumentsConfig = ({
  regionList,
  isAttributesFetched,
  fetchRegionListConnect,
  fetchAttributesDataConnect
}) => {
  useEffect(() => {
    if(!regionList.allIds.length) {
      fetchRegionListConnect();
    }

    if(!isAttributesFetched) {
      fetchAttributesDataConnect();
    }
  }, []);

  return (
    <>
      <DocumentsConfigAdd />
      <DocumentsConfigList />
    </>
  )
}

export default DocumentsConfig;