export const INPUT_BOX_DETAIL = [
  {
    label: 'Yard Name',
    value: 'storeName',
    disabled: true,
    type: 'text',
    required: true
  },
  {
    label: 'Phone',
    value: 'storePhoneNumber',
    disabled: true,
    type: 'number',
    required: true,
    maxLength: 10
  },
  {
    label: 'State',
    value: 'stateId',
    disabled: true,
    type: 'text',
    // required: true,
    // listName: 'stateId'
  },
  {
    label: 'City',
    value: 'cityId',
    disabled: true,
    type: 'text',
    // required: true,
    // listName: 'cityId'
  },
  {
    label: 'Zone',
    value: 'zoneId',
    disabled: true,
    type: 'dropDown',
    required: true,
    listName: 'zoneId'
  },
  {
    label: 'GSTIN',
    value: 'gstinNumber',
    disabled: true,
    type: 'text',
    required: true,
    maxLength: 21
  },
  {
    label: 'Latitude',
    value: 'latitude',
    disabled: true,
    type: 'text',
    required: true
  },
  {
    label: 'Longitude',
    value: 'longitude',
    disabled: true,
    type: 'text',
    required: true
  },
  {
    label: 'Google Map Link',
    value: 'shareLink',
    disabled: true,
    type: 'textArea',
    required: true
  },
  {
    label: 'Address',
    value: 'address',
    disabled: true,
    type: 'textArea',
    required: true
  },
  {
    label: 'Active',
    value: 'status',
    disabled: true,
    type: 'checkBox',
    required: false
  },
  {
    label: 'Default',
    value: 'isDefault',
    disabled: true,
    type: 'checkBox',
    required: false
  },
  {
    label: 'Dealer ID',
    value: 'dealerId',
    disabled: true,
    type: 'text',
    required: true
  },
  {
    label: 'Location Type',
    value: 'locationType',
    disabled: true,
    type: 'dropDown',
    required: true,
    listName: 'locationType'
  },
];
