import Types from './types';
import { documentRuleEngineService } from '../../../services';
import { parseById } from '../../helper/parsers';

export const addDocumentRejectionReasonsConfig = (params, body) => (dispatch) => {
  return new Promise((resolve, reject) => {
    documentRuleEngineService
      .addDocumentRejectionReasonsConfig(params, body)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      })
  });
};

export const fetchDocumentRejectionReasonsConfig = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    documentRuleEngineService
      .fetchDocumentRejectionReasonsConfig(params)
      .then((resp) => {
        dispatch(fetchDocumentRejectionReasonsConfigSuccess(parseById(resp.data)));
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      })
  });
};

export const updateDocumentRejectionReasonsConfig = (params, body) => (dispatch) => {
  return new Promise((resolve, reject) => {
    documentRuleEngineService
      .updateDocumentRejectionReasonsConfig(params, body)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      })
  });
};

export const deleteDocumentRejectionReasonsConfig = (id) => () => {
  return new Promise((resolve, reject) => {
    documentRuleEngineService
      .deleteDocumentRejectionReasonsConfig(id)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      })
  });
};

export const updateAddRejectionReasonsConfigData = (data) => ({
  type: Types.UPDATE_ADD_REJECTION_REASONS_CONFIG_DATA,
  data
})

const fetchDocumentRejectionReasonsConfigSuccess = (data) => ({
  type: Types.FETCH_DOCUMENT_REJECTION_REASONS_CONFIG_SUCCESS,
  data
});

export const updateDocumentRejectionsReasonData = (data) => ({
  type: Types.UPDATE_DOCUMENT_REJECTION_REASONS,
  data
});

export const resetRejectionReasonsConfigList = () => ({
  type: Types.RESET_REJECTION_REASONS_CONFIG_LIST
});
