import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';

import './style.css';
import InputText from '../../../shared/components/input-text';
import Locale from '../../../shared/utils/locale';

const NoData = () => {
  return (
    <div className='box-body ml20 col-md-12'>
      <h6>{Locale.NO_DATA}</h6>
    </div>
  );
};

const CInspectionSection = function({ label, data, config }) {
  return (
    <>
      <div className='box-header'>
        <h3 className='box-title'>{label}</h3>
      </div>
      {/* Rendering data directly frm response (config as false)*/}

      {!config && data && data.length > 0 ? (
        <div className='box-body col-md-12'>
          {data.map((item, index) => {
            return (
              <div
                key={`item_${index}`}
                className='float-left form-group col-md-3'
              >
                <label className='font-weight-bold'>{item.title}</label>
                <InputText
                  id={`item_${index}`}
                  type='text'
                  className='form-control'
                  value={item.title || ''}
                  readOnly={true}
                />
              </div>
            );
          })}
        </div>
      ) : null}

      {/* Rendering data from config file */}

      {config && data && Object.keys(data).length > 0 ? (
        <div className='box-body col-md-12'>
          {config.map((item) => {
            return (
              <div
                key={item.dataKey}
                className='float-left form-group col-md-3'
              >
                <label className='font-weight-bold'>{item.label}</label>
                <InputText
                  id={item.dataKey}
                  type='text'
                  className='form-control'
                  value={data && data[item.dataKey]}
                  readOnly={true}
                />
              </div>
            );
          })}
        </div>
      ) : null}

      {(!data || Object.keys(data).length === 0) && <NoData />}
    </>
  );
};

export default withRouter(CInspectionSection);
