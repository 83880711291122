import { useEffect, useState } from "react"
import { agentAvailabilityService } from "../../services";

export const useAgentAvailability = () => {
  const [isAgentAvailable, setIsAgentAvailable] = useState(false);

  const fetchAgentAvailability = () => {
    agentAvailabilityService.fetchAgentAvailability()
      .then((resp) => {
        setIsAgentAvailable(resp.isActive);
      })
  };

  const updateAgentAvailability = (isAvailable) => {
    if (isAvailable === isAgentAvailable) return;
    agentAvailabilityService.updateAgentAvailability({ isActive: isAvailable })
      .then(() => {
        setIsAgentAvailable(isAvailable);
      })
  };

  useEffect(() => {
    fetchAgentAvailability();
  }, []);

  return { isAgentAvailable, updateAgentAvailability };
}