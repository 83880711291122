import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  inventoryLogs: {},
  isLoading: false,
  isError: false
};

//Update inventory reducer starts here
export const updateInventoryInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true, isError: false };
};

export const updateInventorySuccess = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false, isError: false };
};

export const updateInventoryFail = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false, isError: true };
};

//Inventory Log fetch reducer starts here
export const fetchInventoryLogDetailInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true, isError: false, inventoryLogs: [] };
};

export const fetchInventoryLogDetailSuccess = (
  state = INITIAL_STATE,
  action
) => {
  const {
    data: { data }
  } = action.payload;
  return {
    ...state,
    inventoryLogs: data,
    isLoading: false,
    isError: false
  };
};

export const fetchInventoryLogDetailFail = (state = INITIAL_STATE, action) => {
  const { error } = action.payload;

  return {
    ...state,
    isError: true,
    error,
    isLoading: true,
    inventoryLogs: []
  };
};

//define handlers
export const HANDLERS = {
  [Types.INVENTORY_UPDATE_INIT]: updateInventoryInit,
  [Types.INVENTORY_UPDATE_SUCCESS]: updateInventorySuccess,
  [Types.INVENTORY_UPDATE_FAIL]: updateInventoryFail,
  [Types.FETCH_INVENTORY_LOG_INIT]: fetchInventoryLogDetailInit,
  [Types.FETCH_INVENTORY_LOG_SUCCESS]: fetchInventoryLogDetailSuccess,
  [Types.FETCH_INVENTORY_LOG_FAIL]: fetchInventoryLogDetailFail
};

export default createReducer(INITIAL_STATE, HANDLERS);
