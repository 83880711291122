const validate = inputObject => {
    const errorObject = {};
    Object.keys(inputObject).forEach(input => {
        if ((inputObject[input].toString().trim()).length === 0) {
            errorObject[input] = {
                valid: false,
                message: `Please fill  ${input}  field`
            }
        }
        else{
           
        }
    });

    return errorObject;
}
export default validate