import { createReducer } from 'reduxsauce';
import Types from './types';

export const INITIAL_STATE = {
  allIds: [],
  byId: {},
};

export const fetchDocumentsListSuccess = (state = INITIAL_STATE, { data }) => {
  return { ...state, ...data };
};

export const HANDLERS = {
  [Types.FETCH_DOCUMENTS_LIST_SUCCESS]: fetchDocumentsListSuccess,
};

export default createReducer(INITIAL_STATE, HANDLERS);
