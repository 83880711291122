import React, { useMemo } from 'react'
import { withRouter } from 'react-router-dom';
import { columns } from '../../config/case-type-configs-columns';
import GridView from '../common/grid-view';
import { CASE_TYPES_GRID_ID } from '../../constants/constants';
import { showToastMessages } from '../../../shared/utils/helper';

const CaseTypesConfigAdd = ({
  isAttributesFetched,
  attributes,
  addConfigData,
  addCaseTypeConfigConnect,
  updateAddConfigDataConnect,
  fetchCaseTypeConfigConnect
}) => {
  let columnDefs = useMemo(() => columns(attributes), [isAttributesFetched]);
  columnDefs = columnDefs.slice(0, columnDefs.length - 1);

  const handleAddConfig = () => {
    let data = {
      ...addConfigData
    };
    addCaseTypeConfigConnect({}, data)
    .then((res) => {
      fetchCaseTypeConfigConnect();
      showToastMessages(res.message);
    });
  }

  const handleMultiSelectChange = (attributeKey, data) => {
    let value = Array.isArray(data) ? data.map(val => val.value) : data.value;
    updateAddConfigDataConnect({ key: attributeKey, value });
  }

  return (
    <>
      <h5>Add new Case Type Rule</h5>
      <GridView
        className={CASE_TYPES_GRID_ID.CONFIG_ADD}
        uniqueGridId={CASE_TYPES_GRID_ID.CONFIG_ADD}
        columnDefs={columnDefs}
        rowData={[addConfigData]}
        context={{ handleMultiSelectChange }}
        columnsToFit={true}
        floatingFilter={false}
        getRowHeight={() => 55}
      />
      <div className="text-right">
        <button
          className='btn btn-sm btn-success mt-neg-lg'
          onClick={handleAddConfig}
        >
          Add Rule
        </button>
      </div>
    </>
  )
}

export default withRouter(CaseTypesConfigAdd);