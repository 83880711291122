import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CaseTypesConfigAdd from "./component";
import { addCaseTypeConfig, fetchCaseTypeConfig, updateAddConfigData } from "../case-types-config/actions";

const mapStateToProps = ({
  caseTypeConfig: {
    attributes,
    isAttributesFetched,
    addConfig: addConfigData
  }
}) => ({
  isAttributesFetched,
  attributes,
  addConfigData
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
  addCaseTypeConfigConnect: addCaseTypeConfig,
  updateAddConfigDataConnect: updateAddConfigData,
  fetchCaseTypeConfigConnect: fetchCaseTypeConfig
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CaseTypesConfigAdd);
