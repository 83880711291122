import React, { Fragment, useState, useEffect } from 'react';
import styles from './style.module.css';
import useModal from 'react-hooks-use-modal';
import { useFormik } from 'formik';
import InputText from '../../../shared/components/input-text';
import { FIELDS, getInitValues, REFUND_REASON } from './constants';
import { SelectComponent } from '../../../client/components/shared';
import { getParsedSelectOptions } from '../../../shared/utils/helper';
import { Wrapper } from '../form-element';
import CustomButton from '../../../shared/components/button';
import CustomDatePicker from '../../../shared/components/date-picker/component';
import { validationSchema } from './validations';

const CFinanceRefundModal = ({
  clickHandler,
  paymentModes,
  userType,
  dealId,
  refundPaymentInfoConnected,
  fetchTransactionLogsConnected
}) => {
  const [Modal, open, close] = useModal();
  const [options, setOptions] = useState({});
  const handleSubmit = (values) => {
    const payload = {
      refund_reason: values.refund_reason.value,
      refund_mode: values.refund_mode.value,
      refund_date: values.refund_date,
      amount: values.amount,
      utr: values.utr,
      user_type: userType,
      deal_id: dealId,
      id: null
    };
    refundPaymentInfoConnected(payload).then(() => {
      close();
      fetchTransactionLogsConnected(userType, dealId, false);
      formik.resetForm();
    });
  };

  const formik = useFormik({
    initialValues: getInitValues(),
    validationSchema: validationSchema,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    const paymentModeOptions = getParsedSelectOptions(paymentModes, {
      labelKey: 'display_text',
      valueKey: 'id'
    });
    setOptions({
      ...options,
      [FIELDS.refund_mode.keyName]: paymentModeOptions,
      [FIELDS.refund_reason.keyName]: Object.values(REFUND_REASON)
    });
  }, [paymentModes]);
  const onChangeSelect = (selected, param) => {
    if (selected) {
      formik.setFieldValue(param, selected, true);
    } else {
      formik.setFieldValue(param, {}, true);
    }
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    formik.setFieldValue(name, value, true);
  };
  const onBlurCallback = (e) => {
    e.preventDefault();
    const { name } = e.target;
    formik.setFieldTouched(name, true, true);
  };
  const handleDateChange = (date, keyname) => {
    formik.setFieldValue(keyname, date);
  };
  return (
    <>
      <button onClick={open} className='btn btn-primary'>
        Refund
      </button>
      {
        <Modal>
          <div
            className={`Filter_popUP ${styles.refundPopup} col-md-12`}
            style={{ minWidth: '35vw' }}
          >
            <h4>REFUND</h4>
            <div className='d-flex'>
              <form onSubmit={formik.handleSubmit}>
                {Object.values(FIELDS).map((fieldItem) => {
                  const {
                    subType,
                    label,
                    keyName,
                    required,
                    elementType,
                    isMulti,
                    maxLength,
                    ...restProps
                  } = fieldItem;

                  switch (elementType) {
                    case 'input':
                      return (
                        <Wrapper
                          key={keyName}
                          item={fieldItem}
                          containerClass='row justify-content-between mb20'
                        >
                          <InputText
                            id={keyName}
                            name={keyName}
                            type={subType}
                            onChange={onChangeInput}
                            value={formik.values[keyName] || ''}
                            onBlurCallback={onBlurCallback}
                            isError={
                              formik.touched[keyName] && formik.errors[keyName]
                                ? true
                                : false
                            }
                            errorMessage={
                              formik.touched[keyName] && formik.errors[keyName]
                                ? formik.errors[keyName]
                                : null
                            }
                            version='v2'
                            {...restProps}
                          />
                        </Wrapper>
                      );

                    case 'select':
                      return (
                        <Wrapper key={keyName} item={fieldItem} containerClass="row justify-content-between mb20">
                          <SelectComponent
                            key={formik.values[keyName] || keyName}
                            id={keyName}
                            name={keyName}
                            optionsList={options[keyName]}
                            placeholder={label}
                            defaultValue={formik.values[keyName] || null}
                            value={formik.values[keyName] || null}
                            onChange={onChangeSelect}
                            className='form-control'
                            isMulti={isMulti || false}
                            version='v2'
                            errorMessage={
                              formik.touched[keyName] && formik.errors[keyName]
                                ? formik.errors[keyName]
                                : null
                            }
                          />
                        </Wrapper>
                      );

                    case 'date':
                      return (
                        <Wrapper key={keyName} item={fieldItem} containerClass="row justify-content-between mb20">
                          <div className='datepicker_custom modalDate'>
                            <CustomDatePicker
                              name={keyName}
                              selected={formik.values[keyName]}
                              onDateChange={handleDateChange}
                            />
                          </div>
                        </Wrapper>
                      );
                    default:
                      return null;
                  }
                })}
                <CustomButton type='submit' label='Submit' />
              </form>
            </div>
          </div>
        </Modal>
      }
    </>
  );
};

export default CFinanceRefundModal;
