import React from 'react';
import { CheckBox } from '../../../client/components/shared';
import ArrowIcon from '../../../shared/arrow';
import Accordion from '../../../shared/accordion';
import { serviceTableHeading } from './constant';
import './style.css';

const PricingServices = ({
  serviceDetails,
  onChangeAmount,
  onChangeChechbox
}) => {
  return (
    <Accordion totalItems={1}>
      {({ onClick, currentVisibleStates }) => {
        return (
          <div className="borderWrapper">
          <Accordion.Content>
            <div>
              <Accordion.Heading>
                <h2
                  className='font-weight-bold systemHeader p0'
                  onClick={() => onClick({ index: 0 })}
                >
                  Service
                  <ArrowIcon
                    grey={'GreyArrow'}
                    rotateBy={currentVisibleStates[0] ? 180 : 90}
                  />
                </h2>
              </Accordion.Heading>
              <Accordion.Body>
                <div
                  className={
                    currentVisibleStates[0]
                      ? 'accordionShow table mt20'
                      : 'accordionHide'
                  }
                >
                  <table className='table'>
                    <thead>
                      <tr>
                        {serviceTableHeading.map((heading, index) => {
                          return <th key={index}>{heading}</th>;
                        })}
                      </tr>
                    </thead>
                    {serviceDetails &&
                      serviceDetails.map((data, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tbody>
                              <tr>
                                <td>
                                  <input
                                    disabled
                                    className='form-control'
                                    type='text'
                                    name='weight'
                                    value={data.serviceName || ''}
                                  />
                                </td>
                                <td>
                                  <input
                                    disabled
                                    className='form-control'
                                    type='text'
                                    name='weight'
                                    value={data.serviceType || ''}
                                  />
                                </td>
                                <td>
                                  <input
                                    disabled={
                                      data.amount != null ? false : true
                                    }
                                    className='form-control'
                                    type='number'
                                    name='amount'
                                    value={data.amount || ''}
                                    onChange={e => onChangeAmount(e, index)}
                                  />
                                </td>
                                <td>
                                  <CheckBox
                                    name='isActive'
                                    value={data.isActive || ''}
                                    onClickCallback={e =>
                                      onChangeChechbox(e, index)
                                    }
                                    checkedStatus={data.isActive}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </React.Fragment>
                        );
                      })}
                  </table>
                </div>
              </Accordion.Body>
            </div>
          </Accordion.Content>
          </div>
        );
      }}
    </Accordion>
  );
};
export default PricingServices;
