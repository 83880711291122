import React from 'react';
import Dealers from '../../components/dealer-grid';
import withAdminLayout from '../../../shared/hoc/admin-layout';
import ErrorBoundary from '../../components/common/errorBoundary';
import { withAuthorization } from '../../../shared/utils/firebase';

const DealerPage = () => {
  return (
    <div>
      <ErrorBoundary>
        <Dealers />
      </ErrorBoundary>
    </div>
  );
};
export default withAdminLayout(withAuthorization(() => true)(DealerPage));
