import axios from "axios";

let BASE_API_URL = 'https://consumer-finance-engine.qac24svc.dev/';
if (typeof window !== 'undefined') {
  switch (process.env.REACT_APP_ENV) {
    case 'local':
      BASE_API_URL = 'https://consumer-finance-engine.qac24svc.dev/';
      break;
    case 'qa':
      BASE_API_URL = 'https://consumer-finance-engine.qac24svc.dev/';
    case 'stage':
      BASE_API_URL = 'https://consumer-finance-engine.qac24svc.dev/'
      break;
    case 'prod':
      BASE_API_URL = 'https://simpler-engine.cars24.team/';
      break;
    default:
      BASE_API_URL = 'https://consumer-finance-engine.qac24svc.dev/';
      break;
  }
}
// console.log("baseURL: ", BASE_API_URL, ", env:", process.env.REACT_APP_ENV);

const api = axios.create({
    baseURL: BASE_API_URL
});

export default () => {
    const fetchFinanceDetail = (vehiclePrice) => {
        return api.get(`/app/offer/emi-calculation?vehiclePrice=${vehiclePrice}`, {});
    };

    return {
        fetchFinanceDetail
    };
};
