export const BUYER = 'Buyer';
export const SELLER = 'Seller';

export const doctype = {
  BUYER_DOCUMENT: 'buyerDocument',
  SELLER_DOCUMENT: 'sellerDocument'
};

export const TABS = {
  dealInfo: {
    label: 'Deal Information'
  },
  rcCaseType: {
    label: 'Case Type'
  },
  rcDocument: {
    label: 'Document'
  },
  rcTracker: {
    label: 'Service Tracker'
  },
  rcVerification: {
    label: 'Verification'
  }
};
export const SIZE = 5000000;
export const ALLOWED_DOC_TYPES = ['png', 'jpeg', 'jpg', 'pdf'];

export const YES = 'Yes';
export const NO = 'No';
export const NA = 'N/A';
