let BASE_API_URL = 'https://c2c-admin.qac24svc.dev';
let LOCAL_API_URL = 'http://localhost:9090/api';
switch (process.env.REACT_APP_ENV) {
  case 'qa':
    BASE_API_URL = 'https://c2c-admin.qac24svc.dev';
    break;
  case 'lnod':
    BASE_API_URL = 'http://c2c-admin-panel-lnod.ninja24.in';
    break;
  case 'stage':
    // BASE_API_URL = ' https://c2c-admin-panel-stage.cars24.team';
    BASE_API_URL = 'https://stage-c2c-admin.qac24svc.dev';
    break;
  case 'prod':
    BASE_API_URL = 'https://c2c-admin-panel.cars24.team';
    break;
  default:
    BASE_API_URL = 'https://c2c-admin.qac24svc.dev';
    break;
}

export const config = {
  api: {
    local: {
      host: LOCAL_API_URL,
      orders: {
        path: '/orders'
      }
    },
    c2c: {
      host: BASE_API_URL,
      agent: {
        path: '/rc/agent'
      },
      allAgents: {
        path: '/rc/agent-grid'
      },
      agentEdit: {
        path: '/rc/agent'
      },
      agentAdd: {
        path: '/rc/agent'
      },
      user: {
        path: '/secure/users'
      },
      addUser: {
        path: '/users'
      },
      allUser: {
        path: '/secure/all-users'
      },
      userEdit: {
        path: 'secure/users'
      },
      orders: {
        path: '/orders'
      },
      carInfo: {
        path: '/orders/car-details/'
      },
      sellerInfo: {
        path: '/orders/seller-details'
      },
      dealerInfo: {
        path: '/orders/dealer-details'
      },
      makeData: {
        path: '/meta-data/make'
      },
      modelData: {
        path: '/meta-data/model'
      },
      variantdata: {
        path: '/meta-data/variant'
      },
      getImage: {
        path: '/image'
      },
      approveImage: {
        path: '/image/approve'
      },
      downloadImage: {
        path: 'image/download'
      },

      despositions: {
        path: '/orders/state'
      },
      rcDealInfo: {
        path: '/rc/deal-info'
      },
      rcTracker: {
        path: '/rc/rc-tracker',
        rcTrackerConfig: {
          path: 'rc/rc-tracker/tracker-configs'
        },
        delayReason: {
          path: 'rc/rc-tracker/delay-reason'
        },
        rcVerificationConfigs: {
          path: 'rc/rc-tracker/rc-verification-configs'
        },
        rcCorrectionReason: {
          path: 'rc/rc-tracker/rc-correction-reason'
        },
        rcCorrectionReasons: {
          path: 'rc/rc-tracker/rc-correction-reasons'
        },
        rcCorrectionLogs: {
          logs: {
            path: '/rc/rc-correction-logs'
          }
        },
        rcOthersCorrectionLogs: {
          logs: {
            path: '/rc/others/rc-correction-logs'
          }
        },
      },
      rcOthersTracker: {
        path: '/rc/v3/rc-tracker'
      },
      rcManagement: {
        path: '/rc/v2/rc-management-grid'
      },
      rcOthersList: {
        path: '/rc-grid-view/getAll'
      },
      rcAddOthers: {
        path: "/rc/others"
      },
      hpManagement: {
        path: '/hypothesis/list'
      },
      hypothecation: {
        path: '/hypothesis'
      },
      financeManagement: {
        path: '/fc/v2/fc-management-grid'
      },
      financeManagementDealInfo: {
        path: '/fc/deal-info'
      },
      rcDocument: {
        path: '/rc/rc-documents'
      },
      rcOthersDocumentDelete: {
        path: '/rc/v3/rc-documents'
      },
      rcOthersDocuments: {
        path: '/rc/others/rc-documents'
      },
      rcDocumentFile: {
        path: '/rc/rc-documents/file'
      },
      rcOthersDocumentView: {
        path: '/rc/v3/rc-documents/file'
      },
      rcTrackerdetail: {
        path: '/rc/rc-documents'
      },
      rcQcPanel: {
        path: '/rc/rc-case-type-qc-details'
      },
      qcBuyerInsurance : {
        path: '/buyer-insurance'
      },
      rcQuestion: {
        path: '/rc/rc-case-type'
      },
      rcOthersQuestion: {
        path: '/rc/others/rc-case-type'
      },
      submitOtherRcCaseType: {
        path: '/rc/v3/rc-case-type'
      },
      rcReleaseBlockHoldback: {
        path: '/rc/holdback-toggle'
      },
      rcCasetypeRemarks: {
        path: '/rc/rc-tracker/remarks'
      },
      rcTrackerAgent: {
        path: '/rc/rc-tracker/agents/'
      },
      rcOthersTrackerAgent: {
        path: '/rc/v3/rc-tracker/agents'
      },
      rcTrackerAgentHistory: {
        path: '/rc/rc-tracker/agentHistory'
      },
      rcOthersTrackerAgentHistory: {
        path: '/rc/v3/rc-tracker/agentHistory'
      },
      rcTrackerDelayReasons: {
        path: '/rc/rc-tracker/delay-reasons'
      },
      rcOthersDelayReasons: {
        path: '/rc/v3/rc-tracker/delay-reasons'
      },
      rcTrackerDelayReasonResolved: {
        path: '/rc/rc-tracker/delay-reason/resolved'
      },
      rcOthersDelayReasonResolved: {
        path: '/rc/v3/rc-tracker/delay-reason/resolved'
      },
      rcTrackerRemarks: {
        path: '/rc/rc-tracker/remarks'
      },
      rcRemarks: {
        path: '/rc/rc-remarks'
      },
      rcOthersRemarks: {
        path: '/rc/v3/rc-remarks'
      },
      rcTrackerUpload: {
        path: '/rc/rc-tracker/file'
      },
      rcOthersTrackerUpload: {
        path: '/rc/v3/rc-tracker/file'
      },
      rcOthersTrackerUpload: {
        path: '/rc/v3/rc-tracker/file'
      },
      caseTypeRemarks: {
        path: 'rc/remarks-rc-case-type'
      },
      rcOthersCaseTypeRemarks: {
        path: '/rc/v3/remarks-rc-case-type'
      },
      rcOthersUpdateStatus: {
        path: '/rc/others/update-rc-status'
      },
      rcDocumentsRemarks: {
        path: 'rc/remarks-rc-docs'
      },
      saveOthersRcDocumentRemarks: {
        path: '/rc/v3/remarks-rc-docs'
      },
      trackerRemarks: {
        path: 'rc/remarks-rc-tracker'
      },
      rcOthersTrackerRemarks: {
        path: 'rc/v3/remarks-rc-tracker'
      },
      pricingLogs: {
        path: '/orders/pricing-history'
      },
      publishRemarks: {
        path: '/order-state/history'
      },
      inventory: {
        path: '/onboard/inventory'
      },
      updateOrderState: {
        path: '/update/orders'
      },
      generateInvoice: {
        path: '/orders/invoice'
      },
      updateOrder: {
        path: '/orders/update'
      },
      inspection: {
        path: '/orders/inspection'
      },
      stores: {
        path: '/stores'
      },
      storesAll: {
        path: '/stores-all'
      },
      city: {
        path: '/cities'
      },
      state: {
        path: '/states'
      },
      dealers: {
        path: '/dealers'
      },
      dealerForm: {
        path: '/dealerForm'
      },
      agents: {
        path: '/agents'
      },
      agentStatusUpdate: {
        path: '/agent/status'
      },
      onboardInventory: {
        path: '/onboard/inventory'
      },
      updateInventory: {
        path: '/update/inventory'
      },
      inventoryLog: {
        path: '/inventory/track'
      },
      c2bOrders: {
        path: '/c2bOrders'
      },
      addC2bOrder: {
        path: '/c2borders/add'
      },
      sellerInspectionDetail: {
        path: '/sellerInspectionDetail'
      },
      arDealer: {
        path: '/dealers/authorizedRepresentative'
      },
      arDealerGrid: {
        path: '/dealers/authorized-representatives'
      },
      rto: {
        fetchRtoGridInfo: {
          path: '/rto'
        },
        getAllRto: {
          path: "/rto-all"
        },
        getRtoById: {
          path: "/rto-details"
        },
        submitRtoData: {
          path: "/rto-details-service"
        },
        getStates: {
          path: "/rto/states"
        },
        getDistricts: {
          path: "rto/all-district"
        },
        getMandatoryDocs: {
          path: "rto/all-documents"
        }
      },
      paymentInfo: {
        path: '/fc/payment-proofs'
      },
      uploadUtr: {
        path: '/fc/upload-bank-statements'
      },
      trxLogs: {
        path: '/fc/trx-logs'
      },
      cityConfig: {
        path: 'cities-grid'
      },
      language: {
        path: '/city/languages'
      },
      inspectionStore: {
        path: '/inspection-store'
      },
      userRcAccess: {
        path: '/user/rc-access'
      },
      paymentMode: {
        path: '/fc/payment-mode'
      },
      transaction: {
        path: '/fc/transaction'
      },
      paymentProof: {
        path: '/fc/payment-proofs'
      },
      discardPaymentProof: {
        path: '/fc/payment-proofs/discard'
      },
      serviceCharges: {
        path: '/fc/service-data'
      },
      serviceChargesSave: {
        path: '/fc/services-data'
      },
      refund: {
        path: '/fc/transaction/refund'
      },
      proofSwitch: {
        path: '/fc/transaction/proof-switch'
      },
      cancelRefund: {
        path: '/fc/transaction/cancel-refund'
      },
      updateSellerToCC: {
        path: '/city'
      },
      updateUsers: {
        path: '/secure/users'
      },
      allServiceCharges: {
        path: '/service-charge-slabs'
      },
      submitServiceChargeSlab: {
        path: '/service-charge-slabs'
      },
      postDeliverySales: {
        path: '/fc/post-delivery-data'
      },
      documentRuleEngine: {
        fetchRegionList: {
          path: '/dc/region-details/list'
        },
        fetchAttributesData: {
          path: '/dc/filter-configs'
        },
        addDocumentConfig: {
          path: '/dc/add/document-rules'
        },
        updateDocumentConfig: {
          path: '/dc/update/document-rules'
        },
        fetchDocumentConfig: {
          path: '/dc/filter/document-rules'
        },
        downloadConfigRules: {
          path: '/dc/download/document-rules'
        },
        uploadConfigRules: {
          path: '/dc/upload/document-rules'
        },
        fetchDocumentsList: {
          path: '/dc/document'
        },
        deleteDocumentConfig: {
          path: '/dc/document-rule'
        },
        addDocumentRrConfig: {
          path: '/dc/rejection-reason'
        },
        updateDocumentRrConfig: {
          path: '/dc/rejection-reason/list'
        },
        fetchDocumentRrConfig: {
          path: '/dc/rejection-reason/list'
        },
        deleteDocumentRrConfig: {
          path: '/dc/rejection-reason'
        },
        getDocumentRejectionReasons: {
          path: '/dc/rejection-reason/config'
        }
      },
      caseTypeRuleEngine: {
        fetchAttributesData: {
          path: '/case-type-rule/config'
        },
        addCaseTypeConfig: {
          path: '/case-type-rule'
        },
        updateCaseTypeConfig: {
          path: '/case-type-rule/list'
        },
        fetchCaseTypeConfig: {
          path: '/case-type-rule/list'
        },
        deleteCaseTypeConfig: {
          path: '/case-type-rule'
        }
      },
      docketManagement: {
        fetchDocuments: {
          path: '/diy/dre'
        },
        fetchFormData: {
          path: '/diy/documents-form'
        },
        updateData: {
          path: '/diy/documents-form'
        },
        uploadDiyDoc: {
          path: '/diy/document/file'
        },
        updateDocketDocVisibility: {
          path: '/rc/rc-documents/diy-toggle'
        },
        getDiyDocument: {
          path: '/diy/document-details/file'
        },
        getDocumentConfig: {
          path: '/diy/config'
        }
      },
      taskManagement: {
        fetchGridInfo: {
          path: '/document-task'
        },
        updateAssignTo: {
          path: '/document-task/agent/assign'
        },
        fetchAgents: {
          path: '/document-task/agents'
        }
      },
      agentAvailability: {
        fetchAvailability: {
          path: '/document-task/agent/availability'
        },
        updateAvailability: {
          path: '/document-task/agent/availability'
        }
      },
      rtoDelayReasons: {
        fetchStates: {
          path: '/rto/states'
        },
        fetchRtos: {
          path: '/rto-data'
        },
        fetchRcDelayStages: {
          path: 'rto-delay/stages-reasons'
        },
        uploadDelayProof: {
          path: '/rto-delay/upload-proof'
        },
        saveRtoDelays: {
          path: '/rto-delay'
        },
        fetchRtoDelayLogs: {
          path: '/rto-delay/logs'
        },
        updateRtoDelay: {
          path: '/rto-delay'
        }
      },
      serviceTracker: {
        getServiceConfig: {
          path: '/rc/others/service-tracker/service-config'
        },
        getCancelReasons: {
          path: '/rc/others/service-tracker/cancel/reasons'
        },
        getDelayReasons: {
          path: '/rc/others/delay-reasons'
        },
        getTrackerConfig: {
          path: '/rc/others/service-tracker/config'
        }
      },
      regionAllocation: {
        updateAgentRegions: {
          path: '/agent-region/assign'
        }
      },
      ulip: {
        getConfig: {
          path: "/ulip/config"
        },
        getUlipData: {
          path: "/ulip"
        },
        updateUlipData: {
          path: "/ulip"
        }
      }
    },
    //need to remove here
    oms: {
      host: "",
      authentication: {
        path: '/users/authentication'
      }
    }
  }
};
