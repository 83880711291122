import React, { Fragment } from 'react';
import './style.css';
import useModal from 'react-hooks-use-modal';

const Modal = ({ clickHandler }) => {
  const [Modal, open, close] = useModal();
  return (
    <Fragment>
      <button onClick={open} className='btn btn-primary'>
        Save
      </button>
      {
        <Modal>
          <div className='wrap text-center col-md-12'>
            <h1>Apply new pricing logic on all procured cars?</h1>
            <div className='d-flex'>
              <div className='col-6'>
                <button
                  onClick={(e) => clickHandler(e, close)}
                  className='btn btn-success col-offset-3'
                >
                  Yes
                </button>
              </div>
              <div className='col-6'>
                <button onClick={close} className='btn btn-danger'>
                  No
                </button>
              </div>
            </div>
          </div>
        </Modal>
      }
    </Fragment>
  );
};

export default Modal;
