const HOLDBACK_SERVICES = [
  'CHALLAN_HOLDBACK',
  'PARTY_PESHI_BUYER',
  'RC_TRANSFER_HOLDBACK',
  'INCOMPLETE_DOCUMENTATION',

  'BANK_NOC_AND_FORM_35',
  'CHALLAN_BLACKLISTING',
  'PARTY_PESHI_SELLER',
  'DRC_HOLDBACK',
  'OTHER_DOCUMENTATION_HOLDBACK'
];

export { HOLDBACK_SERVICES };
