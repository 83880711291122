import { createReducer } from 'reduxsauce';
import Types from './types';
import { getAddConfigState } from './utils';

export const INITIAL_STATE = {
  attributes: {
    documents: [],
    ownershipType: [],
    category: [],
    necessity: [],
    documentType: [],
    uploadType: [],
    alternativeDocs: [],
    isSignatureRequired: [],
    isApplicableForCustomer: []
  },
  isAttributesFetched: false,
  addConfig: {},
  regionConfigs: {
    configsUpdated: [],
    byId: {},
    allIds: []
  }
};

export const fetchAttributesDataSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    attributes: {
      ...state.attributes,
      ...data,
      alternativeDocs: data.documents
    },
    isAttributesFetched: true,
    addConfig: getAddConfigState(data)
  };
};

export const updateAddConfigData = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    addConfig: {
      ...state.addConfig,
      [data.key]: data.value
    }
  };
};

const fetchDocumentConfigSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    regionConfigs: {
      ...state.regionConfigs,
      byId: data.byId,
      allIds: data.allIds
    }
  };
};

const updateDocumentData = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    regionConfigs: {
      ...state.regionConfigs,
      byId: {
        ...state.regionConfigs.byId,
        [data.id]: {
          ...state.regionConfigs.byId[data.id],
          [data.key]: data.value
        }
      },
      configsUpdated: [
        ...state.regionConfigs.configsUpdated,
        ...(state.regionConfigs.configsUpdated.indexOf(data.id) === -1 ? [data.id]: [])
      ]
    }
  };
};

const resetConfigList = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    regionConfigs: {
      configsUpdated: [],
      byId: {},
      allIds: []
    }
  };
};

export const HANDLERS = {
  [Types.FETCH_ATTRIBUTES_DATA_SUCCESS]: fetchAttributesDataSuccess,
  [Types.UPDATE_ADD_CONFIG_DATA]: updateAddConfigData,
  [Types.FETCH_DOCUMENT_CONFIG_SUCCESS]: fetchDocumentConfigSuccess,
  [Types.UPDATE_DOCUMENT]: updateDocumentData,
  [Types.RESET_CONFIG_LIST]: resetConfigList
};

export default createReducer(INITIAL_STATE, HANDLERS);
