const getCategory = (props) => {
    const {fieldKey, delayData} = props;
    const keyCategory = delayData[fieldKey];
    if (keyCategory) {
      const categories = Object.values(keyCategory).map((item)=> {
            return {
            label: item.category,
            value: item.category
            }
        });
      return categories;
    }
  };

const getSubCategory = (props) => {
    const {fieldKey, delayData, trackerData} = props;
    const keyCategory = delayData[fieldKey];
    const selectedCategory = trackerData && trackerData[fieldKey] && trackerData[fieldKey].category;
    if (keyCategory && selectedCategory) {
        const subCategories = Object.values(keyCategory).find((item)=> item.category === selectedCategory);
        if (subCategories) {
            return subCategories.sub_category.map(item => {
                return {
                label: item,
                value: item
                }
            })
        }
    }
};

const onChangeDelayCategory = (props) => {
  const {key, data, trackerData, updateTrackerData } = props;
  let fileState = { ...trackerData };
  if (data.target.value) {
    fileState = {
      ...fileState,
      [key] : {
          category: data.target.value
        }
      }
  } else {
    if (fileState[key])
      delete fileState[key];
  }
  updateTrackerData(fileState);
};

const onChangeDelaySubCategory = (props) => {
  const {key, data, trackerData, updateTrackerData } = props;
  let fileState = { ...trackerData };
  if (fileState[key]) {
    fileState = {
      ...fileState,
      [key]: {
        ...fileState[key],
        subCategory: data.target.value
      }
    }
  }
  updateTrackerData(fileState);
};

  export {
    getCategory,
    getSubCategory,
    onChangeDelayCategory,
    onChangeDelaySubCategory,
  };