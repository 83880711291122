import Types from './types';
import {
  dispatchSetLoaderConnected,
  dispatchResetLoaderConnected,
  handleHttpStatusError
} from '../../../services/helper';
import { HypothecationService } from '../../../services';
const setPageNumberConnected = (pageNo) => ({
  type: Types.SET_PAGE_NUMBER,
  payload: pageNo
});
const fetchHpManagementGridInfoConnected = (query, params) => (dispatch) => {
  dispatchSetLoaderConnected();
  return HypothecationService
    .fetchHpManagementGridInfo(query, params)
    .then((resp) => {
      dispatchResetLoaderConnected();
      return resp.data;
    })
    .catch((error) => {
      handleHttpStatusError(error, true);
      throw error;
    });
};
export { setPageNumberConnected, fetchHpManagementGridInfoConnected };
