import Types from './types';
import { documentRuleEngineService } from '../../../services';
import { parseById } from '../../helper/parsers';

export const fetchDocumentsList = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    documentRuleEngineService
      .fetchDocumentsList()
      .then((data) => {
        dispatch(fetchDocumentsListSuccess(parseById(data)));
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fetchDocumentsListSuccess = (data) => ({
  type: Types.FETCH_DOCUMENTS_LIST_SUCCESS,
  data
});
