import React, { useState, useEffect } from 'react';
import Accordion from '../../../../../shared/accordion';
import ArrowIcon from '../../../../../shared/arrow';
import { rcManagementService } from '../../../../../services';
import { SelectComponent, SelectDropdown } from '../../../../../client/components/shared';
import styles from './styles.module.css';
import { formatDate } from '../../../../../shared/utils/helper';
import {RC_CONFIG_KEYS} from './constants';

const RcOthersCorrections = (props) => {
  const {
    rcTrackerId,
    corrections,
    setCorrections,
  } = props;
  const [statuses, setStatuses] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(()=>{
    rcManagementService.getRcVerificationConfigs(true).then((resp)=>{
      const stats = resp.data[RC_CONFIG_KEYS.STATUS] && resp.data[RC_CONFIG_KEYS.STATUS].map((item) => {
        return {
          value: item.key, 
          label: item.value 
        }
      });
      const cats = resp.data[RC_CONFIG_KEYS.CATEGORY] && resp.data[RC_CONFIG_KEYS.CATEGORY].map((item) => {
        return {
          value: item.key, 
          label: item.value 
        }
      });
      setStatuses(stats || []);
      setCategories(cats || []);
    })
  },[]);

  const onAddRcCorrection = () => {
    const correction = {};
    const updateRcCorrectionsData = [...corrections, correction];
    setCorrections(updateRcCorrectionsData);
  };

  const onSelectCorrectionStatus = (e, index, item) => {
    const updateRow = {...item, status: e.target.value};
    if (!!item.id) {
      rcManagementService.saveRcCorrectionReason(updateRow, true).then((resp)=>{
        console.log({resp});
      }).catch((err)=> {
        console.log({err});
      });
    }
    const updateCorrections = [...corrections];
    updateCorrections[index] = updateRow;
    setCorrections(updateCorrections);
  };

  const onChangeCategory = (index, value) => {
    const updateCorrections = [...corrections];
    const row = updateCorrections[index];
    const newRowData = {...row, 
      rcTrackerId: rcTrackerId,
      category: value,
    };
    updateCorrections[index] = newRowData;
    setCorrections(updateCorrections);
  };
  return (
    <Accordion totalItems={1} key={'rc-1'}>
      {({ onClick, currentVisibleStates }) => {
        return (
          <Accordion.Content key='ac-1'>
            <Accordion.Heading key='ah-1'>
              <h2
                className='d-flex fwb justify-content-between m0'
                onClick={() => onClick({ index: 0 })}
              >
                RC Corrections
                <ArrowIcon
                  grey={'GreyArrow'}
                  rotateBy={currentVisibleStates[0] ? 180 : 90}
                />
              </h2>
            </Accordion.Heading>
            <Accordion.Body key='ab-1'>
              <div
                className={
                  currentVisibleStates[0] ? 'accordionShow mt10 top-border' : 'accordionHide'
                }
              >
               <div className={`row ${styles.rcTable}`}>
                 <table cellPadding="0" cellSpacing="0" width="100%">
                   <thead>
                     <tr>
                       <th>Category</th>
                       <th>Created By</th>
                       <th>Created Date</th>
                       <th>Correction Status</th>
                     </tr>
                   </thead>
                   <tbody>
                     {corrections && corrections.length > 0 && corrections.map((item, index)=> {
                      return (
                        <tr>
                          <td>
                            {!!item.id ? <span>{item.category}</span> : 
                            <SelectComponent
                                placeholder="Select Category"
                                key={`rc_correction_category_${index}_${rcTrackerId}`}
                                name={`rc_correction_category_${index}_${rcTrackerId}`}
                                optionsList={categories}
                                defaultValue={!!item.category ? {label: item.category, value: item.category} : ''}
                                onChange={(value)=> onChangeCategory(index, value)}
                                isClearable={false}
                            />}
                          </td>
                          <td>{item.createdBy || ''}</td>
                          <td>{!!item.createdAt ? formatDate(item.createdAt) : ''}</td>
                          <td>
                            {!!item.id && <SelectDropdown
                              name={`rc_correction_Status_dd${index}_${rcTrackerId}`}
                              optionsList={statuses}
                              value={item.status}
                              onChange={e=> onSelectCorrectionStatus(e, index, item)}
                            />}
                          </td>
                        </tr>
                      );
                     })
                    }
                   </tbody>
                 </table>
                 <button className='btn btn-primary mt20 ml20' onClick={onAddRcCorrection}>Add Correction</button>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Content>
        );
      }}
    </Accordion>
  );
};

export default RcOthersCorrections;
