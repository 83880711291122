export const RC_CASETYPE = [
  {
    label: 'Hypothecation',
    apiKey: 'hypothecation'
  },
  {
    label: 'HP Bank Name',
    apiKey: 'hp_bank_name'
  },
  {
    label: 'Reg. Authority',
    apiKey: 'reg_authority'
  },
  {
    label: 'Reg. Date',
    apiKey: 'reg_date',
    dataType: 'date'
  },
  {
    label: 'Rc. Avaliability',
    apiKey: 'rc_availability'
  },
  {
    label: 'Rc. Condition',
    apiKey: 'rc_condition'
  },
  {
    label: 'Road Tax Expairy date',
    apiKey: 'road_tax_expiry_date',
    dataType: 'date'
  },
  {
    label: 'Fuel type',
    apiKey: 'fuel_type'
  },
  {
    label: 'Motor insurance type',
    apiKey: 'motor_insurance_type'
  },
  {
    label: 'Seller insurance validity',
    apiKey: 'insurance_validity_date',
    dataType: 'date'
  },
  {
    label: 'Challan Applicable',
    apiKey: 'challan_applicable'
  },
  {
    label: 'Challan Cost',
    apiKey: 'challan_cost'
  },
  {
    label: 'Chassis No.',
    apiKey: 'chassis_number'
  }
];

export const QC_BUYER_INSURANCE = {
  EXPIRY_DATE: 'insuranceExpiryDate',
  FILE_URL: 'buyerInsuranceFileUrl',
  FILE_NAME: 'buyerInsuranceFileName',
  FILE_ID: 'fileId',
  INSURANCE_PROVIDER: 'insuranceProvider',
};

export const QC_BUYER_INSURANCE_PROVIDERS = {
  GO_DIGIT: 'Go DIGIT',
  ICICI_LOMBARD: 'ICICI Lombard',
  TATA_AIG: 'TATA AIG',
  KOTAK: 'KOTAK',
  OTHERS: 'Others'
};

export const insuranceProviders = [
  {label: QC_BUYER_INSURANCE_PROVIDERS.GO_DIGIT, value: QC_BUYER_INSURANCE_PROVIDERS.GO_DIGIT}
  ,{label: QC_BUYER_INSURANCE_PROVIDERS.ICICI_LOMBARD, value: QC_BUYER_INSURANCE_PROVIDERS.ICICI_LOMBARD}
  ,{label: QC_BUYER_INSURANCE_PROVIDERS.TATA_AIG, value: QC_BUYER_INSURANCE_PROVIDERS.TATA_AIG},
  {label: QC_BUYER_INSURANCE_PROVIDERS.KOTAK, value: QC_BUYER_INSURANCE_PROVIDERS.KOTAK}
  ,{label: QC_BUYER_INSURANCE_PROVIDERS.OTHERS, value: QC_BUYER_INSURANCE_PROVIDERS.OTHERS}
];
