import { showToastMessages } from '../../shared/utils/helper';
import { IS_ENV_DEV, IS_MOCK, PAGE_SIZE } from '../../shared/utils/constants';
import { buildQuery } from '../../services/helper';
import { config } from './../config/local';
import { BASE_API_URL } from '../../services/adminApi';

export default (api) => {

  const fetchRtoGridInfo = (params = { page: 0, size: 10 }) => {
    const path = config.api.c2c.rto.fetchRtoGridInfo.path;
    const queryStr = params ? `?${buildQuery(params)}` : '';
    const url = path + queryStr;
    if (IS_ENV_DEV && IS_MOCK) {
      const urlConfig = { url: url, baseURL: config.api.local.host };
      return api(urlConfig);
    } else {
      return api.get(url);
    }
  };

  const getAllRto = () => {
    const url = config.api.c2c.rto.getAllRto.path;
    return api
      .get(url)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        showToastMessages(error, false);
        return Promise.reject(error);
      });
  };

  const getRtoById = (id) => {
    const url = config.api.c2c.rto.getRtoById.path;
    return api
      .get(`${url}/${id}/`)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        showToastMessages(error, false);
        return Promise.reject(error);
      });
  };

  const submitRtoData = (id, data) => {
    const url = config.api.c2c.rto.submitRtoData.path;
    return api
      .put(url, data)
      .then((resp) => {
        showToastMessages(resp.data.message);
        return resp.data;
      })
      .catch((error) => {
        showToastMessages(error);
        return Promise.reject(error);
      });
  };

  const fetchStatesList = () => {
    const url = config.api.c2c.rto.getStates.path;
    return api
      .get(url)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        throw error;
      });
  };

  const fetchDistrictsList = () => {
    const url = config.api.c2c.rto.getDistricts.path;
    return api
      .get(url)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        throw error;
      });
  };

  const fetchMandatoryDocsList = () => {
    const url = config.api.c2c.rto.getMandatoryDocs.path;
    return api
      .get(url)
      .then((resp) => {
        const {
          data: { data }
        } = resp;
        return data;
      })
      .catch((error) => {
        throw error;
      });
  };

  return { 
    fetchRtoGridInfo, 
    submitRtoData, 
    getRtoById, 
    getAllRto,
    fetchStatesList,
    fetchDistrictsList,
    fetchMandatoryDocsList
  };
};
