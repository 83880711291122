import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CCOBPostBooking from './component';

const mapStateToProps = ({}) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CCOBPostBooking);
