import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    RESET_LOADER
    SET_LOADER
    SET_LOADER_ERROR
`,
  {
    prefix: 'header/'
  }
);
