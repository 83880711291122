import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  UPLOAD_UTR_INIT
  UPLOAD_UTR_SUCCESS
  UPLOAD_UTR_FAIL   
`,
  {
    prefix: 'finance-bank-upload/'
  }
);
