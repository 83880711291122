import React, { useEffect, useState } from "react"
import { SelectDropdown } from "../../../../client/components/shared"

export const DealCancelModal = ({
  dealCancelReasonsOptions,
  fetchDealCancelReasons,
  onSubmit
}) => {
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    const { value } = e.target;
    setValue(value);
  };

  const handleSubmit = () => {
    onSubmit(value);
  };

  useEffect(() => {
    fetchDealCancelReasons();
  }, [])

  return (
    <div className="bg-white p-4 relative" style={{ width: '400px', height: '170px'}}>
      <label className='font-weight-bold'>{"Please select reason"}</label>
      <SelectDropdown
        name={"cancelReason"}
        value={value}
        optionsList={dealCancelReasonsOptions}
        onChange={handleChange}
      />
      <button className="btn btn-sm btn-primary mt-4 absolute right-0" onClick={handleSubmit} disabled={!value}>Cancel Deal</button>
    </div>
  )
}